export const getEmailAcls = (data) => {
  const acls = [
    ...data.readShare.map((r) => ({
      access: "read",
      email: r.email,
      expires_at: r.expiresAt,
    })),
    ...data.writeShare.map((w) => ({
      access: "write",
      email: w.email,
      expires_at: w.expiresAt,
    })),
  ];
  return sanitizeFolderAclsList(acls);
};

const sanitizeFolderAclsList = (emailsAcl) => {
  // Get list of duplicates
  const duplicates = emailsAcl
    .map((e) => e.email)
    .map((e, i, final) => final.indexOf(e) !== i && i)
    .filter((obj) => emailsAcl[obj])
    .map((e) => emailsAcl[e]["email"]);

  // Get the final list of ACLs
  return emailsAcl
    .filter((e) => duplicates.includes(e.email) && e.access === "write")
    .concat(emailsAcl.filter((e) => !duplicates.includes(e.email)));
};
