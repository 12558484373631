import {damsFetch} from "./app/damsFetch";

/**
 * Method used to get the current conversion status for the specified dmsId.
 */
export const getObjectStatus = (collectionId, dmsId, callback, errorCallback) => {
    damsFetch('/dms/object-status/',
        {
            method: 'POST',
            body: JSON.stringify({
                collectionId: collectionId,
                mediaId: dmsId
            })
        })
        .then(data => {
            if (Array.isArray(dmsId)) {
                callback(data);
            } else {
                callback(data[0]);
            }
        })
        .catch(error => errorCallback(error));
};