import {Dialog, DialogActions, DialogContent, Stack} from "@mui/material";
import {forwardRef, useImperativeHandle, useState} from "react";
import LinearProgress from "@mui/material/LinearProgress";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import {damsFetch} from "../app/damsFetch";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {
    loadNumAddedFiles,
    loadNumCreatedObjects,
    loadNumFailedObjects,
    loadNumUpdatedKeywords,
    loadNumUploadedFiles
} from "./importHelpers";

/**
 * Displays details about the job, based on the following JSON:
 *
 *     {
 *         "jobId": "b8581704-9de0-4395-bc41-a260e5153f5f",
 *         "createdAt": "29.02.2024 13:29:20",
 *         "phases": [
 *             {
 *                 "name": "created",
 *                 "priority": 0
 *             },
 *             {
 *                 "name": "initializing",
 *                 "priority": 1
 *             },
 *             {
 *                 "name": "running",
 *                 "priority": 2
 *             },
 *             {
 *                 "name": "done",
 *                 "priority": 4
 *             }
 *         ],
 *         "sourceFolder": "/app/server/tests/static_assets",
 *         "initiator": {
 *             "appId": "bfb1b23a-97a9-483e-99e2-fa61e4b5b7c6",
 *             "museum": {
 *                 "id": 20,
 *                 "name": "KulturIT",
 *                 "ekulturId": "KIT"
 *             },
 *             "museumId": 20,
 *             "userEmail": "jonas.olstad@gmail.com",
 *             "collectionId": 2
 *         }
 *     }
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
export const JobDetailsDialog = forwardRef((props, ref) => {

    const {t} = props;
    const [currentJob, setCurrentJob] = useState({jobId: null});
    const [stats, setStats] = useState({
        addedFiles: 0,
        uploadedFiles: 0,
        createdObjects: 0,
        updatedKeywords: 0,
        failedObjects: 0,
        lastKnownPhase: {
            name: null,
            priority: null
        }
    });
    const [loading, setLoading] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);

    const getCount = data => {
        if (!data || data?.length === 0) {
            return 0;
        }

        if (!data[0]['filesCount']) {
            return 0;
        }

        return data[0]['filesCount'];
    };


    const _showDetails = async (job) => {
        setCurrentJob(job);

        const {jobId, phases} = job;
        const lastKnownPhase = phases[phases.length - 1];

        const jobStats = {
            addedFiles: 0,
            uploadedFiles: 0
        };

        const addedFilesRes = await loadNumAddedFiles(jobId);
        const uploadedFiles = await loadNumUploadedFiles(jobId);
        const createdObjects = await loadNumCreatedObjects(jobId);
        const updatedKeywords = await loadNumUpdatedKeywords(jobId);
        const failedObjects = await loadNumFailedObjects(jobId);

        jobStats.addedFiles = getCount(addedFilesRes);
        jobStats.uploadedFiles = getCount(uploadedFiles);
        jobStats.createdObjects = getCount(createdObjects);
        jobStats.updatedKeywords = getCount(updatedKeywords);
        jobStats.failedObjects = getCount(failedObjects);
        jobStats.lastKnownPhase = lastKnownPhase;

        setStats(jobStats);
    };

    const closeDialog = () => {
        setLoading(true);
        setOpenDialog(false);
    };

    const downloadJobLog = () => {
        const body = JSON.stringify({
            'job_type': 'IMAGE_IMPORT',
            'job_id': currentJob.jobId
        });

        const options = {
            method: 'POST',
            body: body
        };

        damsFetch('/jobs/log', options).then(data => {
            let element = document.createElement('a');
            element.setAttribute('href',
                'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data.models)));
            element.setAttribute('download', `${currentJob.jobId}_log.json`);

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        });
    };

    const getInitiator = () => {
        const {initiator, sourceFolder, createdAt} = currentJob;
        const {museum, userEmail} = initiator;
        const {name, ekulturId} = museum;

        return <Stack direction={"column"}>
            <Box key={"initiator-institution"}>{name} ({ekulturId})</Box>
            <Box key={"initiator-user"}>{userEmail}</Box>
            <Box key={"initiator-source"}>{sourceFolder}</Box>
            <Box key={"initiator-created"}>
                <Typography variant={"caption"}>
                    {createdAt}
                </Typography>
            </Box>
        </Stack>;
    };

    useImperativeHandle(ref, () => ({
        showDetails(jobId) {
            setOpenDialog(true);
            setLoading(true);

            _showDetails(jobId).then(() => {
                setLoading(false);
            });
        }
    }));

    return <Dialog open={openDialog}
                   disableEscapeKeyDown={true}
                   PaperProps={{
                       style: {
                           minWidth: '80%',
                           minHeight: '80%',
                           overflow: 'hidden',
                           overflowY: 'auto'
                       }
                   }}>
        <DialogTitle>Jobb - {currentJob.jobId}</DialogTitle>
        <DialogContent>
            {loading && <LinearProgress/>}
            <Stack direction={"row"}
                   sx={{minWidth: '100%', width: '100%'}}
                   justifyContent={"space-between"}>
                <Stack direction={"column"} sx={{width: '100%', minWidth: '100%'}}>
                    <Stack direction={"row"} sx={{width: '100%', minWidth: '100%'}}
                           justifyContent={"space-between"}>
                        <Box>
                            {!loading && currentJob && getInitiator()}
                            <Typography sx={{marginTop: '16px'}}>
                                {t('imageImportPhase', 'Fase: {{lastKnownPhase}}',
                                    {lastKnownPhase: stats.lastKnownPhase.name})}
                            </Typography>
                        </Box>
                        <Box>
                            <Button startIcon={<CloudDownloadIcon/>} onClick={downloadJobLog}>
                                {t('imageImportBtnDownload', 'Last ned logg')}
                            </Button>
                        </Box>
                    </Stack>
                    <Divider orientation={"horizontal"}
                             sx={{
                                 width: '100%',
                                 minWidth: '100%',
                                 marginTop: '16px',
                                 marginBottom: '16px'
                             }}/>
                    <Typography variant={"h5"}>
                        {t('imageImportStatistics', 'Statistikk')}
                    </Typography>
                    <Typography>
                        {t('imageImportNumFiles', 'Antall filer: {{addedFiles}}',
                            {addedFiles: stats.addedFiles})}
                    </Typography>
                    <Typography>
                        {t('imageImportNumUploaded', 'Antall opplastet: {{uploadedFiles}}',
                            {uploadedFiles: stats.uploadedFiles})}
                    </Typography>
                    <Typography>
                        {t('imageImportNumObjects', 'Antall DAMS-objekter: {{createdObjects}}',
                            {createdObjects: stats.createdObjects})}
                    </Typography>
                    <Typography>
                        {t('imageImportNumSubjects', 'Antall DAMS-objekter oppdatert med emneord: {{updatedKeywords}}',
                            {updatedKeywords: stats.updatedKeywords})}
                    </Typography>
                    <Typography>
                        {t('imageImportNumErrors', 'Antall feilende objekter: {{failedObjects}}',
                            {failedObjects: stats.failedObjects})}
                    </Typography>
                </Stack>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={closeDialog} key={`btn-close-${currentJob.jobId}`}>
                {t('imageImportBtnClose', 'Lukk')}
            </Button>
        </DialogActions>
    </Dialog>;
});