import {buildGetUrl} from "./dms/dmsSecurity";

/**
 * Method used to get the thumbnail URL for the specified dmsId.
 */
export const getThumbnailUrl = (collectionId, dmsId) => {
    return new Promise((resolve, reject) => {
        buildGetUrl({
            collectionId: collectionId,
            dmsId: dmsId
        }).then(data => {
            resolve(`${data.url}&mediaType=image/webp&dimension=250x250`);
        }).catch(err => reject(err));
    });
};