import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../../../app/ProgressIndicator";

export const PROJECT_LOADED = 'projectViewContext/projectLoaded';

export const PROJECT_SHOW_DETAILS_OVERLAY =
    "projectViewContext/showDetailsOverlay";

export const PROJECT_SHOW_ADMIN_OVERLAY =
    "projectViewContext/projectShowAdminOverlay";

export const PROJECT_UPDATED = "projectViewContext/projectUpdated";

export const PROJECT_CLOSE_DETAILS_OVERLAY =
    "projectViewContext/projectCloseDetailsOverlay";

export const PROJECT_CLOSE_ADMIN_OVERLAY =
    "projectViewContext/projectCloseAdminDetailsOverlay";

export const SET_PROJECT_VIEW_FACETS = 'projectViewContext/setProjectViewFacets';

const ProjectViewStateContext = createContext(undefined);
const ProjectViewDispatchContext = createContext(undefined);
const ProjectViewTranslationContext = createContext(undefined);

const initialState = {
    project: undefined,
    showDetails: false,
    showAdmin: false,
    facets: {}
};

const projectReducer = (state, action) => {
    if (action.type === SET_PROJECT_VIEW_FACETS) {
        return {...state, facets: action.facets};
    } else if (action.type === PROJECT_SHOW_DETAILS_OVERLAY) {
        return {
            ...state,
            project: action.project,
            showDetails: !state.showDetails,
            showAdmin: false,
        };
    } else if (action.type === PROJECT_SHOW_ADMIN_OVERLAY || action.type === PROJECT_UPDATED) {
        return {
            ...state,
            project: action.project,
            showAdmin: !state.showAdmin,
            showDetails: false,
        };
    } else if (action.type === PROJECT_LOADED) {
        return {...state, project: action.project};
    } else if (action.type === PROJECT_CLOSE_DETAILS_OVERLAY) {
        return {...state, showDetails: false};
    } else if (action.type === PROJECT_CLOSE_ADMIN_OVERLAY) {
        return {...state, showAdmin: false};
    } else {
        throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const ProjectViewProvider = ({children}) => {
    const [state, dispatch] = useReducer(projectReducer, initialState, undefined);
    const {t, ready} = useTranslation("dams.projectview", {
        useSuspense: false,
    });

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <ProjectViewTranslationContext.Provider value={t}>
            <ProjectViewStateContext.Provider value={state}>
                <ProjectViewDispatchContext.Provider value={dispatch}>
                    {children}
                </ProjectViewDispatchContext.Provider>
            </ProjectViewStateContext.Provider>
        </ProjectViewTranslationContext.Provider>
    );
};

export const useProjectViewState = () => {
    const context = useContext(ProjectViewStateContext);
    if (undefined === context) {
        throw new Error(
            "useProjectViewState must be used within a ProjectViewProvider."
        );
    } else {
        return context;
    }
};

export const useProjectViewDispatch = () => {
    const context = useContext(ProjectViewDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useProjectViewDispatch must be used within a ProjectViewProvider."
        );
    } else {
        return context;
    }
};

export const useProjectViewTranslation = () => {
    const context = useContext(ProjectViewTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useProjectViewTranslation must be used within a ProjectViewProvider"
        );
    } else {
        return context;
    }
};
