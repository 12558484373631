import {useEffect, useState} from "react";
import {damsFetch, damsSearch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";

export const useMetadataMapping = ({collectionId}) => {
    const [metdataMapping, setMetadataMapping] = useState([]);

    useEffect(() => {
        if (Boolean(collectionId)) {
            const searchParams = new URLSearchParams(
                decamelizeKeysDeep({
                    q: `*`,
                    sort: "title asc",
                    fq: "title:*",
                    expand: true,
                    documentType: `("field_config")`,
                    collectionId: collectionId,
                })
            );
            damsSearch(searchParams).then((json) => {
                setMetadataMapping(json['models']);
            });
        } else {
            setMetadataMapping([]);
        }
    }, [collectionId]);

    const saveMetadataMapping = (documentType, field, values) => {
        if (metdataMapping.find((mm) => mm.title === field)) {
            const existingMM = metdataMapping.find((mm) => mm.title === field);
            return damsFetch(`/documents`, {
                method: "POST",
                body: JSON.stringify(
                    decamelizeKeysDeep({
                        ...existingMM,
                        content: {
                            ...existingMM.content,
                            [documentType]: {[field]: values},
                        },
                    })
                ),
            }).then((json) => setMetadataMapping((mm) => [...mm, json]));
        } else {
            const newMapping = {
                collectionId: collectionId,
                schemaId: 1,
                locale: "no",
                _action: "put",
                title: field,
                status: "published",
                documentType: "field_config",
                content: {
                    [documentType]: {[field]: values},
                },
            };

            return damsFetch(`/documents`, {
                method: "POST",
                body: JSON.stringify(decamelizeKeysDeep(newMapping)),
            }).then((json) => {
                setMetadataMapping((mm) => [...mm, json]);
            });
        }
    };

    const getExistingValues = () => {
        let mapped = metdataMapping.map(m => {
            return m.content;
        });
        let output = {};
        mapped.forEach(m => {
            const key = Object.keys(m);
            if (Array.isArray(key)) {
                key.forEach(k => {
                    if (Object.keys(output).includes(k)) {
                        if (!Number.isInteger(k)) {
                            output[k] = {...output[k], ...m[k]};
                        }
                    } else {
                        output[k] = m[k];
                    }
                })
            } else {
                if (Object.keys(output).includes(key)) {
                    output[key] = {...output[key], ...m[key]};
                } else {
                    output[key] = m[key];
                }
            }
        });
        return output;
    };

    const existingValues = metdataMapping.length ? getExistingValues() : {};

    return [existingValues, saveMetadataMapping];
};
