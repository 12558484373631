import React from "react";
import {damsSearch} from "../app/damsFetch";
import {
    concatWithExisting,
    excludeThoseAlreadyListedAsKulturnav,
    fieldValuesWithCollectionIds,
    inputWithCollectionIds,
    sourceToUniqueId,
} from "../metadata/inputMapper";
import {kulturnavApiCoreFetch} from "../app/kulturnavFetch";
import {useAddLicense} from "./useAddLicense";
import {useField, useFormikContext} from "formik";
import {useDocumentState} from "../documents/documentContext";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {useAuthsState} from "../auths/authsContext";
import {KulturNavLicenseSearch} from "./KulturNavLicenseSearch";
import CopyrightIcon from "@mui/icons-material/Copyright";
import {mapNotMissing} from "../inpututils";
import {apiCoreObjectToDAMSInput, getBrowserLocale} from "../utility";
import {HIDE_ADD_LICENSE_FIELD, useCopyrightDispatch} from "../copyright/copyrightContext";
import {clientLog} from "../clientLog";

/**
 * Renders a FormikSelectLicense component.
 *
 * @param {string} formikKey - The key for the Formik field.
 * @param {string} label - The label for the component.
 * @param {number|null} [ix=null] - The index for the license.
 * @param {boolean} [disabled=false] - Whether the component is disabled.
 * @param {string|null} [size=null] - The size of the component.
 * @param {string|null} [helperText=null] - The helper text for the component.
 * @return {JSX.Element} The rendered FormikSelectLicense component.
 */
export const FormikSelectLicense = ({
                                        formikKey,
                                        label,
                                        ix = null,  // NOSONAR
                                        disabled = false,
                                        size = null,
                                        helperText = null,
                                    }) => {
    const [field] = useField(formikKey);
    const {setFieldValue, values} = useFormikContext(); // NOSONAR
    const locale = getBrowserLocale();
    const postLicenses = useAddLicense();
    const {collectionIds} = useDocumentState();

    const {museumCollections} = useAuthsState();

    const copyrightDispatch = useCopyrightDispatch();

    const museums = museumCollections
        .filter((museumCollection) =>
            collectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    const multipleSelect = false;

    /**
     * Fetches licenses stored in KulturNav.
     * @param params
     * @returns {Promise<unknown | *[]>}
     */
    const fetchKulturNavValues = (params) => {
        const datasetUUID = `d5bff00e-44dc-409c-a6e4-cf601a7ac6b1`;
        const url = `entity.dataset:${datasetUUID},entityType:Concept,nativeCompoundName:${params}?properties=entity.dataset,entity.fullCaption&displayValues=true&lang=${locale}`;
        return kulturnavApiCoreFetch(url).then(json => {
            const mapped = json['entities'].map(d => {
                return apiCoreObjectToDAMSInput(d);
            });
            return Promise.resolve(mapped);
        }).catch(error => {
            clientLog('error', error, 'FormikSelectLicense');
            return Promise.resolve([]);
        });
    };

    /**
     * Fetches a list of licenses stored in the DAMS database.
     * @param params
     * @returns {Promise<unknown>}
     */
    const fetchDamsValues = (params) => {
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: `_title_:${params}`,
                fl: "title,id",
                sort: "title asc",
                expand: true,
                documentType: `("license")`,
                museums: museums.join(","),
            })
        );

        return damsSearch(searchParams)
            .then((json) => {
                return Promise.resolve(inputWithCollectionIds(json['models']));
            });
    };

    /**
     * Searchhandler, used to fetch data from both KulturNav and the DAMS database,
     * and merge these values into a single list of options.
     * @param params
     * @param callback
     */
    const licenseSearchHandler = (params, callback) => {
        Promise.all([
            fetchKulturNavValues(params),
            fetchDamsValues(params)
        ]).then(([kulturnavResponse, damsResponse]) => {
            // Remove the item "Falt i det fri"
            const REMOVE_ID = '3c2002ff-79be-4a23-a7ea-656150f50143'
            damsResponse = damsResponse.filter(f => f.sourceId !== REMOVE_ID);

            let damsLookup = sourceToUniqueId(damsResponse);

            // Concatenate values from both sources.
            let kulturnavMapped = concatWithExisting(kulturnavResponse, damsLookup);
            kulturnavMapped = kulturnavMapped.filter(f => f.sourceId !== REMOVE_ID);

            callback([
                ...kulturnavMapped,
                ...excludeThoseAlreadyListedAsKulturnav(damsResponse, kulturnavMapped),
            ]);
        });
    };

    /**
     * Updates the 'licences' field in the Formik context with the provided licenses.
     *
     * @param {Array} licenses - An array of license objects.
     * @return {Promise} A promise that resolves when the 'licenses' field is updated.
     */
    const onChange = (licenses) => {
        // Updates the 'licences' field in the Formik context.
        if (ix !== null) {
            let newLicenses = [...values.licenses];
            newLicenses[ix] = licenses[0];

            if (newLicenses[ix]) {

                // Since we're only changing the license type, patch the data with the responsible persons as well,
                // from the originating license object.
                if (values.licenses.length > 0 && typeof (values.licenses[ix]?.responsible) !== 'undefined') {
                    newLicenses[ix]['responsible'] = values.licenses[ix].responsible;
                }
            } else {
                newLicenses[ix] = null;
            }

            // Sanitize newLicenses, removing empty objects.
            newLicenses = newLicenses.filter((license) => license);

            setFieldValue('licenses', newLicenses).then();
        }

        // Make sure the "add license" field is hidden, to avoid duplicates of this field.
        copyrightDispatch({
            type: HIDE_ADD_LICENSE_FIELD
        });
    };

    const onAdd = (licenses) => {
        if (Array.isArray(field.value)) {
            onChange([...field.value, ...licenses]);
        } else if (!field.value) {
            onChange(licenses);
        } else {
            onChange([field.value, ...licenses]);
        }
    }

    const postMissing = (value) => {
        const licenses = collectionIds
            .filter((collectionId) => !value.collectionId.includes(collectionId))
            .map((collectionId) => ({
                ...value,
                collectionId: collectionId,
            }));
        return postLicenses({licenses: licenses});
    };

    const changeHandler = (values) => {
        if (!multipleSelect && !Array.isArray(values)) {
            // Convert selected value to an array. Array is used to support multiple select in other cases.
            values = [values];
        }

        const createExternalLicense = values.find((license) => !license.uniqueId);
        if (createExternalLicense) {
            const licenses = collectionIds.map((collectionId) => ({
                ...createExternalLicense,
                collectionId: collectionId,
            }));
            postLicenses({
                licenses: licenses,
            }).then(onAdd);
        } else {
            const existingLicenses = values
                .map((v) => mapNotMissing(collectionIds, v, "license"))
                .flat();
            Promise.all(values.map(postMissing)).then((newLicenses) =>
                onChange([...existingLicenses, ...newLicenses.flat()])
            );
        }
    };

    /**
     * Retrieves the value from the licenses[ix] properties of the values object,
     * which corresponds to the license information at index (ix), of the total number of registered
     * licenses, hold in the values object (Formik Context).
     * If the value exists, it is processed using the fieldValuesWithCollectionIds function.
     * If multipleSelect is false, the first value is returned, otherwise all values are returned.
     *
     * @return {Array} The processed value(s) from the values object.
     */
    const getValue = () => {
        let val = [];
        if (values[formikKey][ix]) {
            const licenceValues = fieldValuesWithCollectionIds(values[formikKey][ix]);
            val = !multipleSelect ? licenceValues[0] : licenceValues;
        }
        return val;
    };

    return (
        <KulturNavLicenseSearch
            key={formikKey}
            id={"search-knav-license"}
            searchHandler={licenseSearchHandler}
            onChange={changeHandler}
            onClear={() => onChange([])}
            disabled={disabled}
            inputLabel={label}
            Icon={CopyrightIcon}
            multiple={multipleSelect}
            value={getValue()}
            size={size}
            helperText={helperText}
        />
    );
};
