import React from "react";
import styled from "styled-components";
import { CardHeader } from "@mui/material";

export const EllipsisCardHeader = styled(({ ...other }) => (
  <CardHeader {...other} />
))`
  & .MuiCardHeader-content {
    flex: 1 1 auto;
    width: 85%;
  }
`;
