/**
 * Utility-method used to log information to the client / developer console.
 * @param logType str   info | debug | error | warn
 * @param message str   The message to be logged
 * @param component str The name of the DAMS-component related to the logged message. (optional)
 */
export const clientLog = (logType, message, component = null) => {
    const prefix = '[DAMS]';
    const componentPrefix = component !== null ? `[${component}]` : '';
    const logMessage = `${prefix}${componentPrefix} - ${message}`;
    console[logType](logMessage);
};