import {DamsFileIcon} from "../../files/DamsFileIcon";
import React, {useEffect, useState} from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import {useStyles} from "./resultCardStyles";
import PropTypes from "prop-types";


const ArcheologyProjectFileIcon = ({mimeType, model, icon = true}) => {
    const classes = useStyles();

    const [docIcon, setDocIcon] = useState(undefined);
    useEffect(() => {
        if (model.documentType === "Geodata") {
            setDocIcon(
                <LocationOnOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>
            );
        }

        if (model.documentType === "Tabell") {
            setDocIcon(
                <TableChartOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>
            );
        }

        if (model.documentType === "StillImage") {
            setDocIcon(
                <PhotoCameraOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>
            );
        }

        if (model.documentType === "Video") {
            setDocIcon(<VideocamOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>);
        }


    }, [model]);

    return (
        <>
            <div className={classes.fileThumbContainer}>{docIcon}</div>
            <div className={classes.fileTypeContainer}>
                {icon &&
                    <DamsFileIcon
                        mimeType={mimeType}
                        size={36}
                        filename={model.content.mediae[0].reference.title}
                    />}
            </div>
        </>
    );
};

ArcheologyProjectFileIcon.propTypes = {
    "mimeType": PropTypes.string.isRequired,
    "model": PropTypes.object.isRequired,
    "icon": PropTypes.bool
};

export {ArcheologyProjectFileIcon};

