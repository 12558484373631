import React, {useRef, useState} from "react";
import {DamsFileIcon} from "../files/DamsFileIcon";
import Typography from "@mui/material/Typography";
import {format} from "../app/dateFormat";
import {Stack} from "@mui/material";
import {useObserveWindowResize} from "../app/useObserveWindowResize";
import {useSearchResultDetailsTranslation} from "../search-result-details/SearchResultDetailsContext";
import useDeepCompareEffect from "use-deep-compare-effect";

/**
 * Component holding information for the model's title and when it was created.
 * @param title string  The model's title.
 * @param filename str
 * @param created   str The model's created date.
 * @param createdBy str  The name of the person who uploaded the model.
 * @param mimeType  str  The model's mime-type.
 * @returns {JSX.Element}
 * @constructor
 */
export const TitleAndCreated = ({title, filename, created, createdBy, mimeType}) => {
    const t = useSearchResultDetailsTranslation();
    const containerRef = useRef();
    const [panelWidth, setPanelWidth] = useState(0);

    const windowDimensions = useObserveWindowResize();

    useDeepCompareEffect(() => {
        if (!containerRef.current) {
            return;
        }
        setPanelWidth(containerRef.current['clientWidth'] - 72);
    }, [containerRef, windowDimensions]);

    return (
        <Stack
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={1}
            ref={containerRef}
        >
            <DamsFileIcon filename={filename} mimeType={mimeType} size={48} show={true}/>
            <Stack
                direction={"column"}
                justifyContent={"center"}
                sx={{
                    width: panelWidth + "px",
                    maxWidth: panelWidth + "px",
                }}
            >
                <Typography variant={"body1"} noWrap sx={{maxWidth: '80%', width: '80%'}}>
                    {title}
                </Typography>
                <Typography variant={"caption"} color={"secondary"} noWrap>
                    {t("titleCreated", "Opprettet")} {format(created, "P")}{" "}
                    {t("titleBy", "av")} {createdBy}
                </Typography>
            </Stack>
        </Stack>
    );
};
