/**
 * Returns the table translations.
 * @returns {{all: string, sortByColumnAsc: string, sortByColumnDesc: string, resetFilter: string, edit: string, columns: string, rowsPerPage: string, resetSort: string, resetOrder: string, resetAll: string, filter: string, search: string, showHideSearch: string, of: string, noResults: string, showHideColumns: string, sortBy: string, actions: string}}
 * @param t
 */
export const getTableTranslations = (t) => {
    if (!t) {
        throw new Error('t is null or undefined');
    }

    return {
        noResults: t('tableNoResults', 'Ingen resultater'),
        rowsPerPage: t('tableRowsPerPage', 'Rader per side'),
        actions: t('tableActions', 'Handlinger'),
        sortBy: t('tableSortBy', 'Sorter etter'),
        all: t('tableAll', 'Alle'),
        columns: t('tableColumns', 'Kolonner'),
        filter: t('tableFilter', 'Filter'),
        search: t('tableSearch', 'Søk'),
        sortByColumnAsc: t('tableSortByColumnAsc', 'Sorter stigende'),
        sortByColumnDesc: t('tableSortByColumnDesc', 'Sorter synkende'),
        resetSort: t('tableResetSort', 'Tilbakestill sortering'),
        resetOrder: t('tableResetOrder', 'Tilbakestill rekkefølge'),
        resetFilter: t('tableResetFilter', 'Tilbakestill filter'),
        resetAll: t('tableResetAll', 'Tilbakestill alle'),
        edit: t('tableEdit', 'Rediger'),
        showHideSearch: t('tableShowHideSearch', 'Vis/skjul søk'),
        showHideColumns: t('tableShowHideColumns', 'Vis/skjul kolonner'),
        hideAll: t('tableHideAll', 'Skjul alle'),
        showAll: t('tableShowAll', 'Vis alle'),
        of: t('tableOf', 'av'),
        sortedBy: t('tableSortedBy', 'Sortert etter'),
        sortedDesc: t('tableSortedDesc', 'Sortert synkende'),
        sortedAsc: t('tableSortedAsc', 'Sortert stigende'),
        noRecordsToDisplay: t('tableNoRecordsToDisplay', 'Ingen resultater tilgjengelig'),
        clearSelection: t('tableClearSelection', 'Nullstill'),
        selectedCountOfRowCountRowsSelected: t('tableSelectedCountOfRowCountRowsSelected', '{selectedCount} av {rowCount} rader valgt'),
        select: t('tableSelect', 'Velg')
    };
};