import mime from "react-native-mime-types";

/**
 * This array holds a list of file/data-type definitions, and what type of DAMS object they correspond to.
 *
 * NOTE:
 * this list is used to set up a mapping to the correct DAMS object type for files that we're unaable to get
 * the correct mime-type for via the react-mime-type component, or in order to override the specific document type.
 * @type {[{extension: string, application: string, documentType: string, iconType: string, description: string, mimeType: string, objectType: string}]}
 */
export const damsFileObjectDefinitions = [{
    application: "Microsoft Word",
    description: "",
    documentType: "document",
    extension: "doc",
    iconType: "document",
    mimeType: "application/msword",
    objectType: "Dokument"
}, {
    application: "Microsoft Word",
    description: "",
    documentType: "document",
    extension: "docx",
    iconType: "document",
    mimeType: "application/msword",
    objectType: "Dokument"
}, {
    application: "Adobe Portable Document Format (PDF)",
    description: "",
    documentType: "document",
    extension: "pdf",
    iconType: "acrobat",
    mimeType: "application/pdf",
    objectType: "Dokument"
}, {
    application: "Microsoft Excel",
    description: "Comma-separated values (CSV)",
    documentType: "table",
    extension: "csv",
    iconType: "spreadsheet",
    mimeType: "text/csv",
    objectType: "Tabell"
}, {
    application: "Microsoft Excel",
    description: "",
    documentType: "table",
    extension: "xls",
    iconType: "spreadsheet",
    mimeType: "application/vnd.ms-excel",
    objectType: "Tabell"
}, {
    application: "Microsoft Excel (OpenXML)",
    description: "",
    documentType: "table",
    extension: "xlsx",
    iconType: "spreadsheet",
    mimeType: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    objectType: "Tabell"
}, {
    application: "dBASE Database File or FoxPro Table",
    description: "",
    documentType: "table",
    extension: "dbf",
    iconType: "binary",
    mimeType: "application/x-dbf",
    objectType: "Tabell"
}, {
    application: "Microsoft Office Access Database",
    description: "",
    documentType: "table",
    extension: "mdb",
    iconType: "binary",
    mimeType: "application/msaccess",
    objectType: "Tabell"
}, {
    application: "Microsoft Access JET Database",
    description: "",
    documentType: "table",
    extension: "mdbx",
    iconType: "binary",
    mimeType: "application/msaccess",
    objectType: "Tabell"
}, {
    application: "Microsoft Access 2007 Database",
    description: "",
    documentType: "table",
    extension: "accdb",
    iconType: "binary",
    mimeType: "application/msaccess",
    objectType: "Tabell"
},
    {
        application: "GPX Viewer",
        description: "GPX",
        documentType: "geodata",
        extension: "gpx",
        iconType: "binary",
        mimeType: "application/gpx+xml",
        objectType: "Geodata"
    },
    {
        application: "ESRI ArcMap project file - Map Exchange Document",
        description: "GIS",
        documentType: "geodata",
        extension: "mxb",
        iconType: "binary",
        mimeType: "application/octet-stream",
        objectType: "Geodata"
    }, {
        application: "ESRI ArcMap document file",
        description: "GIS",
        documentType: "geodata",
        extension: "mxd",
        iconType: "binary",
        mimeType: "application/vnd.esri.mxd=mxd",
        objectType: "Geodata"
    }, {
        application: "ESRI ArcGis Map Package file",
        description: "",
        documentType: "geodata",
        extension: "mpk",
        iconType: "compressed",
        mimeType: "application/x-intertrust-mpk",
        objectType: "Geodata"
    }, {
        application: "ESRI ArcView project file",
        description: "",
        documentType: "geodata",
        extension: "aprx",
        iconType: "binary",
        mimeType: "application/octet-stream",
        objectType: "Geodata"
    }, {
        application: "Zippade shapefiler/geodata",
        description: "",
        documentType: "geodata",
        extension: "zip",
        iconType: "compressed",
        mimeType: "application/zip",
        objectType: "Geodata"
    }, {
        application: "Qgis project file ",
        description: "",
        documentType: "geodata",
        extension: "qgs",
        iconType: "binary",
        mimeType: "application/x-qgis",
        objectType: "Geodata"
    }, {
        application: "ESRI Geodatabase",
        description: "",
        documentType: "geodata",
        extension: "gdb",
        iconType: "binary",
        mimeType: "application/octet-stream",
        objectType: "Geodata"
    }, {
        application: "Google Earth Keyhole Markup Language",
        description: "",
        documentType: "geodata",
        extension: "kml",
        iconType: "code",
        mimeType: "application/vnd.google-earth.kml+xml",
        objectType: "Geodata"
    }, {
        application: "Google Earth Keyhole Markup Language ZIP",
        description: "",
        documentType: "geodata",
        extension: "kmz",
        iconType: "compressed",
        mimeType: "application/vnd.google-earth.kmz",
        objectType: "Geodata"
    }, {
        application: "Geography Markup Language (GML)",
        description: "",
        documentType: "geodata",
        extension: "gml",
        iconType: "code",
        mimeType: "application/gml+xml",
        objectType: "Geodata"
    }, {
        application: "GeoJSON-fil",
        description: "",
        documentType: "geodata",
        extension: "json",
        iconType: "image",
        mimeType: "application/json",
        objectType: "Geodata"
    },
    {
        application: "Lydfil",
        description: "",
        documentType: "audio",
        extension: "m4a",
        iconType: "audio",
        mimeType: "audio/mp4",
        objectType: "Audio"
    },
    {
        application: "Lydfil",
        description: "mp2 audio file",
        documentType: "audio",
        extension: "mp2",
        iconType: "audio",
        mimeType: "audio/mpeg",
        objectType: "Audio"
    },
    {
        application: "Lydfil",
        description: "",
        documentType: "audio",
        extension: "aiff",
        iconType: "audio",
        mimeType: "audio/aiff",
        objectType: "Audio"
    }, {
        application: "Lydfil",
        description: "",
        documentType: "audio",
        extension: "mp3",
        iconType: "audio",
        mimeType: "audio/mpeg",
        objectType: "Audio"
    }, {
        application: "Lydfil",
        description: "",
        documentType: "audio",
        extension: "ac3",
        iconType: "audio",
        mimeType: "audio/ac3",
        objectType: "Audio"
    },
    {
        application: "Lydfil",
        description: "",
        documentType: "audio",
        extension: "wav",
        iconType: "audio",
        mimeType: "audio/x-wav",
        objectType: "Audio"
    },
    {
        application: "JPG",
        description: "",
        documentType: "photography",
        extension: "jpg",
        iconType: "image",
        mimeType: "image/jpeg",
        objectType: "StillImage"
    },
    {
        application: "WEBP",
        description: "",
        documentType: "photography",
        extension: "webp",
        iconType: "image",
        mimeType: "image/webp",
        objectType: "StillImage"
    },
    {
        application: "JPG",
        description: "",
        documentType: "photography",
        extension: "jpeg",
        iconType: "image",
        mimeType: "image/jpeg",
        objectType: "StillImage"
    }, {
        application: "TIFF",
        description: "",
        documentType: "photography",
        extension: "tif",
        iconType: "image",
        mimeType: "image/tiff",
        objectType: "StillImage"
    }, {
        application: "TIFF",
        description: "",
        documentType: "photography",
        extension: "tiff",
        iconType: "image",
        mimeType: "image/tiff",
        objectType: "StillImage"
    }, {
        application: "Nikon RAW",
        description: "",
        documentType: "photography",
        extension: "nef",
        iconType: "image",
        mimeType: "image/x-nikon-nef",
        objectType: "StillImage"
    }, {
        application: "Canon RAW",
        description: "",
        documentType: "photography",
        extension: "ra2",
        iconType: "image",
        mimeType: "image/x-canon-ra2",
        objectType: "StillImage"
    }, {
        application: "Canon RAW",
        description: "",
        documentType: "photography",
        extension: "ra3",
        iconType: "image",
        mimeType: "image/x-canon-ra3",
        objectType: "StillImage"
    }, {
        application: "PNG",
        description: "",
        documentType: "photography",
        extension: "png",
        iconType: "image",
        mimeType: "image/png",
        objectType: "StillImage"
    }, {
        application: "DNG",
        description: "",
        documentType: "photography",
        extension: "dng",
        iconType: "image",
        mimeType: "image/x-adobe-dng",
        objectType: "StillImage"
    }, {
        application: "BMP",
        description: "",
        documentType: "photography",
        extension: "bmp",
        iconType: "image",
        mimeType: "image/bmp",
        objectType: "StillImage"
    }, {
        application: "CR2",
        description: "Canon RAW",
        documentType: "photography",
        extension: "cr2",
        iconType: "image",
        mimeType: "image/x-canon-cr2",
        objectType: "StillImage"
    }, {
        application: "MP4",
        description: "Video",
        documentType: "video",
        extension: "mp4",
        iconType: "video",
        mimeType: "video/mp4",
        objectType: "Video"
    }, {
        application: "MOV",
        description: "",
        documentType: "video",
        extension: "mov",
        iconType: "video",
        mimeType: "video/quicktime",
        objectType: "Video"
    }, {
        application: "AVI",
        description: "",
        documentType: "video",
        extension: "avi",
        iconType: "video",
        mimeType: "video/x-msvideo",
        objectType: "Video"
    }, {
        application: "WMV",
        description: "",
        documentType: "video",
        extension: "wmv",
        iconType: "video",
        mimeType: "video/x-ms-wmv",
        objectType: "Video"
    },
    {
        application: "3D Model",
        description: "3D Model",
        extension: "glb",
        iconType: "3d",
        mimeType: "model/gltf-binary",
        objectType: "Modell"
    }, {
        application: "XTF",
        description: "Sonar",
        documentType: "video",
        extension: "xtf",
        iconType: "3d",
        mimeType: "application/octet-stream",
        objectType: "Modell"
    }, {
        application: "SQLite 2.0 Database",
        description: "",
        documentType: "video",
        extension: "sl2",
        iconType: "3d",
        mimeType: "application/octet-stream",
        objectType: "Modell"
    }, {
        application: "Son",
        description: "",
        documentType: "model",
        extension: "son",
        iconType: "3d",
        mimeType: "application/octet-stream",
        objectType: "Modell"
    }, {
        application: "Dat",
        description: "",
        documentType: "model",
        extension: "dat",
        iconType: "3d",
        mimeType: "application/octet-stream",
        objectType: "Modell"
    }, {
        application: "Rhino (dokulab)",
        description: "3D",
        documentType: "model",
        extension: "3dm",
        iconType: "3d",
        mimeType: "model/3dm",
        objectType: "Modell"
    }, {
        application: "Stl",
        description: "",
        documentType: "model",
        extension: "stl",
        iconType: "3d",
        mimeType: "model/stl",
        objectType: "Modell"
    }, {
        application: "Obj",
        description: "",
        documentType: "model",
        extension: "obj",
        iconType: "3d",
        mimeType: "model/obj",
        objectType: "Modell"
    }, {
        application: "Terrengmodeller DEM",
        description: "",
        documentType: "model",
        extension: "dem",
        iconType: "3d",
        mimeType: "model/dem",
        objectType: "Modell"
    }, {
        application: "AutoCAD Drawing",
        description: "",
        documentType: "model",
        extension: "dwg",
        iconType: "3d",
        mimeType: "image/x-dwg",
        objectType: "Modell"
    }, {
        application: "Enterprise Vault DVS",
        description: "",
        documentType: "model",
        extension: "dvs",
        iconType: "3d",
        mimeType: "application/octet-stream",
        objectType: "Modell"
    }, {
        application: "AutoCAD Drawing Exchange File",
        description: "",
        documentType: "model",
        extension: "dxf",
        iconType: "3d",
        mimeType: "image/x-dxf",
        objectType: "Modell"
    },
    {
        application: "3D Model",
        description: "3D Model",
        extension: "glb",
        iconType: "3d",
        mimeType: "model/gltf-binary",
        objectType: "Modell"
    },
    {
        application: "VLC",
        description: "",
        documentType: "video",
        extension: "ogv",
        iconType: "video",
        mimeType: "video/ogg",
        objectType: "Video"
    }, {
        application: "VLC",
        description: "",
        documentType: "video",
        extension: "mkv",
        iconType: "video",
        mimeType: "video/x-matroska",
        objectType: "Video"
    }, {
        application: "VLD",
        description: "",
        documentType: "video",
        extension: "m4v",
        iconType: "video",
        mimeType: "video/x-m4v",
        objectType: "Video"
    }, {
        application: "VLC",
        description: "",
        documentType: "video",
        extension: "mpeg",
        iconType: "video",
        mimeType: "video/mpeg",
        objectType: "Video"
    }, {
        application: "ImageViewer",
        description: "",
        documentType: "photography",
        extension: "dng",
        iconType: "image",
        mimeType: "image/x-adobe-dng",
        objectType: "StillImage"
    }, {
        application: "VLC",
        description: "Flash Video File",
        documentType: "video",
        extension: "flv",
        iconType: "video",
        mimeType: "video/x-flv",
        objectType: "Video"
    }, {
        application: "VLC",
        description: "OGG video file",
        documentType: "video",
        extension: "ogg",
        iconType: "video",
        mimeType: "application/ogg",
        objectType: "Video"
    }];

/**
 * Returns the color used on the "file-icon" visible in the search-results for the specified object type.
 * @param objectType str    The type of object.
 * @returns {string}    The name of the color
 */
export const getLabelColor = (objectType) => {
    switch (objectType) {
        case "audio":
            return "tomato";
        case "image":
            return "deepskyblue";
        case "video":
            return "orchid";
        case "acrobat":
            return "orange";
        case "document":
            return "orange";
        case "spreadsheet":
            return "green";
        case "presentation":
            return "green";
        case "compressed":
            return "gold";
        case "geodata":
            return "gold";
        default:
            return "deepskyblue";
    }
};

/**
 * Returns the index-number for the object with the property "key", with the matching "value" from the
 * DAMS fileobject definitions.
 * @param key   str The name of the property/key to use as search argument.
 * @param value str The value to match.
 * @returns {number}
 */
const getObjectDefinitionIndex = (key, value) => {
    return damsFileObjectDefinitions.findIndex(o => o[key] === value);
};

/**
 * Returns the extension part of the filename, without the dot.
 * @param filename  str Filename
 * @returns {string}
 */
export const getFileExtensionFromFilename = filename => {
    return filename.substr(filename.lastIndexOf(".") + 1).toLowerCase();
};

/**
 * Returns the extension that corresponds to the specified mime-type.
 * @param mimeType  str The mime-type to find the extension for.
 * @returns {*|null}
 */
export const getFileExtensionFromMimeType = (mimeType) => {
    let ext = mime.extension(mimeType);
    if (!ext) {
        const ix = getObjectDefinitionIndex('mimeType', mimeType);
        ext = ix > -1 ? damsFileObjectDefinitions[ix].extension : null;
    }
    return ext;
};

/**
 * Returns the mime-type that corresponds to the specified file extension.
 * @param extension str The file extension to find the mime-type for.
 * @returns {string|*|null}
 */
export const getMimeTypeFromFileExtension = (extension) => {
    let mimeType = mime.lookup(extension);
    if (!mimeType) {
        const ix = getObjectDefinitionIndex('extension', extension);
        mimeType = ix > -1 ? damsFileObjectDefinitions[ix].mimeType : null;
    }
    return mimeType;
};

/**
 * Returns the mime-type that corresponds to the specified filename.
 * @param filename str  The filename to find the mime-type for.
 * @returns {*}
 */
export const getMimeTypeFromFilename = filename => {
    const ext = getFileExtensionFromFilename(filename);
    return getMimeTypeFromFileExtension(ext);
};

/**
 * Gets the icon type that corresponds with the specified filename.
 * @param filename  str Filename
 * @returns {string|*|null}
 */
export const getIconFromFilename = filename => {
    const extension = getFileExtensionFromFilename(filename);
    const ix = getObjectDefinitionIndex('extension', extension);
    return ix > -1 ? damsFileObjectDefinitions[ix].iconType : null;
};

/**
 * Gets the object type that corresponds with the specified filename.
 * @param filename str  Filename
 * @returns {string|*|null}
 */
export const getObjectTypeFromFilename = filename => {
    const extension = getFileExtensionFromFilename(filename);
    const ix = getObjectDefinitionIndex('extension', extension);

    // Default to object-type Misc if unresolved.
    return ix > -1 ? damsFileObjectDefinitions[ix].objectType : 'Misc';
};
