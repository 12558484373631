import {makeStyles} from "@mui/styles";

/**
 * Styles used by:
 * - ResultCardAudioIcon.js
 * - ResultCardDocumentIcon.js
 * - ResultCardThreeDIcon.js
 * - ArcheologyProjectFileIcon.js
 *
 * NOTE:
 *  ResultCardImage.js uses a different set of styles.
 * @type object
 */
export const useStyles = makeStyles(() => ({
    fileTypeContainer: {
        position: "absolute",
        bottom: -15,
        right: 8,
    },
    fileThumbContainer: {
        backgroundColor: "#e9e9e9",
        textAlign: "center ",
        width: "100%",
        height: 140,
    },
}));