import React, {useRef} from "react";
import {HIDE, useSearchResultDetailsDispatch, useSearchResultDetailsState,} from "./SearchResultDetailsContext";
import {SearchResultDetailsModal} from "./SearchResultDetailsModal";
import {DocumentProvider} from "../documents/documentContext";
import {useMediaQuery, useTheme} from "@mui/material";
import {ObjectView} from "../objectview/ObjectView";
import {ObjectConversionProvider} from "../objectview/ObjectConversionContext";
import {ObjectViewSmallScreen} from "../objectview/smallscreen/ObjectViewSmallScreen";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export const SearchResultDetails = () => {
    const theme = useTheme();
    const {selectedId} = useSearchResultDetailsState();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();
    const modalRef = useRef(null);
    const searchResultDetailsModalRef = useRef(null);

    // Resolutions lower than 1365px wide is considered "small screens".
    const smallScreen = useMediaQuery("(max-width: 1365px)");

    const onHide = () => {
        searchResultDetailsDispatch({type: HIDE});
    };

    const open = selectedId != null;
    return (
        <Modal
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            open={open}
            onClose={onHide}
            closeAfterTransition
            ref={modalRef}
        >
            <Box
                sx={{
                    outline: "none",
                    [theme.breakpoints.down("md")]: {
                        width: "100%",
                        height: "100%",
                    },
                    [theme.breakpoints.up("sm")]: {
                        width: (props) => (props['isFullscreen'] ? "100%" : "70%"),
                        height: (props) => (props['isFullscreen'] ? "100%" : "90%"),
                    },
                }}
            >
                <DocumentProvider>
                    <SearchResultDetailsModal ref={searchResultDetailsModalRef}>
                        <ObjectConversionProvider>
                            {!smallScreen && <ObjectView open={open}/>}
                            {smallScreen && <ObjectViewSmallScreen open={open}/>}
                        </ObjectConversionProvider>
                    </SearchResultDetailsModal>
                </DocumentProvider>
            </Box>
        </Modal>
    );
};
