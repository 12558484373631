import React, {useEffect, useImperativeHandle, useRef, useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {damsFetch} from "../app/damsFetch";
import {getHrDateAndTime} from "../utility";
import {clientLog} from "../clientLog";


const IndexingStats = React.forwardRef(
    ({statsType, pollInterval, stopIndexingCallback, total}, ref) => {

        // eslint-disable-next-line no-unused-vars
        const [jobId, setJobId] = useState(); // NOSONAR
        const [docs, setDocs] = useState();
        const [lastUpdated, setLastUpdated] = useState();
        const [getIndexedStatus, setGetIndexedStatus] = useState(false);

        let pollingId = useRef();

        const _stopPolling = (_) => {
            if (pollingId) {
                clearInterval(pollingId);
                pollingId = undefined;
            }
        };

        const _getIndexed = () => {
            let url =
                "/statistics/solr/" +
                (statsType === "documents" ? "docs" : "folders") +
                "/total/";
            damsFetch(url)
                .then((res) => {
                    const numIndexed = res["numFound"];
                    const hr = getHrDateAndTime(new Date());

                    setDocs(numIndexed);
                    setLastUpdated(hr);

                    if (numIndexed > 0 && numIndexed >= total) {
                        _stopPolling();
                        stopIndexingCallback(statsType, numIndexed);
                    }

                    const msg = `indexed ${statsType}: ${numIndexed} / ${total} - ${hr}`;
                    clientLog('debug', msg, 'IndexingStats');
                })
                .catch((_) => {
                    if (pollingId) {
                        _stopPolling();
                        stopIndexingCallback(statsType, docs);
                    }
                });
        };

        // Exposes the startPolling() method to the parent element.
        useImperativeHandle(ref, () => ({
            startPolling(jId) {
                if (!jId) {
                    return;
                }
                setJobId(jId);
                setGetIndexedStatus(true);
                const msg = `${jId} - ${statsType} - starting polling - ${pollInterval}`;
                clientLog('debug', msg, 'IndexingStats');
            },
        })); // end useImperativeHandle

        useEffect(() => {
            _getIndexed();
            if (!pollingId) {
                pollingId = setInterval(_getIndexed, pollInterval);
            }
        }, [getIndexedStatus === true]);

        return (
            <Box sx={{marginBottom: 2}}>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <Typography variant={"body1"}>Antall indeksert:</Typography>
                    <Typography variant={"body1"} marginLeft={1}>
                        {docs}
                    </Typography>
                </Box>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <Typography variant={"caption"} color={"secondary"}>
                        Oppdateres hvert 30. sekund - sist oppdatert:
                    </Typography>
                    <Typography variant={"caption"} color={"secondary"} marginLeft={1}>
                        {lastUpdated}
                    </Typography>
                </Box>
            </Box>
        );
    }
);

export default IndexingStats;
