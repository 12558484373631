import React, {createContext, useContext} from 'react';
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

const NewFilesTranslationContext = createContext(undefined);

export const NewFilesTranslationProvider = ({children}) => {
    const {t, ready} = useTranslation('dams.newFiles', {useSuspense: false})

    if (ready) {
        return (
            <NewFilesTranslationContext.Provider value={t}>
                {children}
            </NewFilesTranslationContext.Provider>
        )
    } else if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>
    }
}

export const useNewFilesTranslation = () => {
    const context = useContext(NewFilesTranslationContext);
    if (undefined === context) {
        throw new Error('useNewFilesTranslation must be used within an NewFilesTranslationProvider.');
    }
    return context;
}