import Box from "@mui/material/Box";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {useMediaQuery} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import React, {useEffect, useState} from "react";
import {
    HIDE,
    MEDIA_PLAYING_NAVIGATE,
    SHOW,
    useSearchResultDetailsDispatch
} from "../search-result-details/SearchResultDetailsContext";
import {CONVERSION_UNKNOWN, useObjectConversionDispatch} from "./ObjectConversionContext";
import useDeepCompareEffect from "use-deep-compare-effect";

const navigateArrow = {
    position: "absolute",
    cursor: "pointer",
    zIndex: 10,
};

const navigateIcon = {
    color: "white",
    backgroundColor: "rgba(0,0,0,0.4)"
};


/**
 * Hook used when navigating between the objects in object view.
 * @param model
 * @param results
 * @param smallScreen
 * @param openDialogCallback
 * @returns {[((function(): (*|string))|*),((function(): (*|string))|*),((value: (((prevState: boolean) => boolean) | boolean)) => void)]}
 */
export const useObjectViewPreviousNext = ({model, results, smallScreen, openDialogCallback}) => {
    const objectConversionDispatch = useObjectConversionDispatch();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();

    const [previous, setPrevious] = useState("");
    const [next, setNext] = useState("");
    const [showNavigation, setShowNavigation] = useState(true);

    // Detects portrait mode on smaller screens.
    const isPortrait = useMediaQuery("(orientation:portrait)");

    // Position navigation arrows in the vertical direction,
    // based on the current screen orientation on smaller screens.
    let navigateArrowTopPosition = isPortrait ? "12%" : "50%";

    /**
     * Navigate to previous/next handler.
     * @param idx
     */
    const handleOnNavigate = (idx) => {
        searchResultDetailsDispatch({
            type: MEDIA_PLAYING_NAVIGATE,
            playing: false
        });
        objectConversionDispatch({type: CONVERSION_UNKNOWN});
        searchResultDetailsDispatch({
            type: SHOW,
            model: results.models.find((m) => m.uniqueId === idx),
        });
    };

    /**
     * The previous button (left arrow).
     * @returns {JSX.Element|string}
     */
    const getPreviousButton = () => {
        if (showNavigation && previous) {
            if (!smallScreen) {
                return (<Box sx={{position: "fixed", left: 0, cursor: "pointer"}}>
                    <NavigateBeforeIcon
                        sx={{
                            fontSize: 120,
                            ...navigateIcon
                        }}
                        onClick={() => handleOnNavigate(previous)}
                    />
                </Box>);
            } else if (smallScreen) {
                return <Box
                    sx={{
                        ...navigateArrow,
                        top: navigateArrowTopPosition,
                    }}
                >
                    <NavigateBeforeIcon
                        sx={{fontSize: "40px", ...navigateIcon}}
                        onClick={() => handleOnNavigate(previous)}
                    />
                </Box>;
            }
        } else {
            return '';
        }
    };

    /**
     * The next button (right arrow).
     * @returns {JSX.Element|string}
     */
    const getNextButton = () => {
        if (showNavigation && next) {
            if (!smallScreen) {
                return (<Box sx={{position: "fixed", right: 0, cursor: "pointer"}}>
                    <NavigateNextIcon
                        sx={{
                            fontSize: 120,
                            ...navigateIcon
                        }}
                        onClick={() => handleOnNavigate(next)}
                    />
                </Box>);
            } else if (smallScreen) {
                return <Box
                    sx={{
                        ...navigateArrow,
                        top: navigateArrowTopPosition,
                        right: isPortrait ? 0 : "70%",
                    }}
                >
                    <NavigateNextIcon
                        sx={{fontSize: "40px", ...navigateIcon}}
                        onClick={() => handleOnNavigate(next)}
                    />
                </Box>;
            }
        } else {
            return '';
        }
    };

    const setPdfOpen = open => {
        if (open) {
            setShowNavigation(false);
        } else {
            setShowNavigation(true);
        }
    };

    /**
     * Handle used to support navigating back/forth with the left- and right arrow keys.
     * @param event
     */
    const keyDownHandler = event => {
        if (event.key === 'ArrowLeft' && showNavigation && previous) {
            handleOnNavigate(previous);
        }

        if (event.key === 'ArrowRight' && showNavigation && next) {
            handleOnNavigate(next);
        }
    }

    /**
     * Hook adding the event listener used to listen to keystrokes on the left- and right arrow keys.
     */
    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler, false);
        return () => {
            document.removeEventListener('keydown', keyDownHandler, false);
        }
    });

    /**
     * Hook used to handle the previous/next navigation.
     */
    useDeepCompareEffect(() => {
        const {uniqueId} = model;
        if (results.count === 0) {
            openDialogCallback(false);
            searchResultDetailsDispatch({type: HIDE});
        } else if (uniqueId && results.count > 0) {
            const objectExists = Object.entries(results.modelsOrder).find(e => e[1] === uniqueId);
            let currentIdx = objectExists === undefined ? 0 : parseInt(objectExists[0]);
            setPrevious(
                results.modelsOrder[currentIdx - 1]
                    ? results.modelsOrder[currentIdx - 1]
                    : ""
            );
            setNext(
                results.modelsOrder[currentIdx + 1]
                    ? results.modelsOrder[currentIdx + 1]
                    : ""
            );
        }
    }, [model.uniqueId, results.modelsOrder]);


    return [getPreviousButton, getNextButton, setShowNavigation, setPdfOpen];

};