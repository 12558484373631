import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import PropTypes from "prop-types";

const Message = React.forwardRef((props, ref) => {
    return (
        <Alert
            ref={ref}
            severity={props.type}
            variant={"filled"}
            onClose={props.closeHandler}
        >
            <AlertTitle>{props.title}</AlertTitle>
            {props.message}
        </Alert>
    );
});

Message.propTypes = {
    type: PropTypes.any.isRequired,
    closeHandler: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

export {Message};
