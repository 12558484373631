import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";
import {format} from "../app/dateFormat";
import {DamsModal} from "../app/DamsModal";
import {RelatedObjectForm} from "./RelatedObjectForm";
import {If} from "../app/If";
import {useSearchResultDetailsTranslation} from "../search-result-details/SearchResultDetailsContext";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Edit from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import {RelationTypes} from "./RelationTypes";

const PREFIX = "ListRelations";

const classes = {
    root: `${PREFIX}-root`,
    label: `${PREFIX}-label`,
    item: `${PREFIX}-item`,
    itemContainer: `${PREFIX}-itemContainer`,
    textField: `${PREFIX}-textField`,
    buttonContainer: `${PREFIX}-buttonContainer`,
    cardsList: `${PREFIX}-cardsList`,
    actionButtons: `${PREFIX}-actionButtons`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.root}`]: {
        marginTop: 8,
    },

    [`& .${classes.label}`]: {
        display: "inline-block",
        marginRight: 8,
    },

    [`& .${classes.item}`]: {
        display: "inline-block",
    },

    [`& .${classes.itemContainer}`]: {
        marginTop: 8,
    },

    [`& .${classes.textField}`]: {
        marginTop: 4,
        marginBottom: 4,
    },

    [`& .${classes.buttonContainer}`]: {
        marginTop: 8,
    },

    [`& .${classes.cardsList}`]: {
        width: "50vw",
        height: "60vh",
        maxHeight: "75vh",
        overflowY: "auto",
        padding: theme.spacing(2),
    },

    [`& .${classes.actionButtons}`]: {
        textAlign: "right",
    },
}));

export const ListRelations = ({relations, callback}) => {
    const [data, setData] = useState([]);
    const [openDialog, setOpenDialog] = useState();
    const [deleteDialog, setDeleteDialog] = useState("");
    const t = useSearchResultDetailsTranslation();
    const relationTypes = RelationTypes().getList();

    const getRelatedModels = (uniqueIds) => {
        const query = "unique_id: (" + uniqueIds.join(" OR ") + ")";
        const sort = "updated_at desc";
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: query,
                sort: sort,
                fl: "*",
                start: 0,
                rows: 100,
                documentType: `(StillImage OR Audio OR Video OR Misc)`,
                museums: [],
                onlyMine: false,
                onlyOthers: false,
            })
        );
        return damsFetch(`/search?${searchParams.toString()}`);
    };

    const getRelations = (uniqueIds) => {
        if (uniqueIds.length === 0) {
            return Promise.resolve();
        }

        const query = "unique_id: (" + uniqueIds.join(" OR ") + ")";
        const sort = "updated_at desc";
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: query,
                sort: sort,
                fl: "*",
                start: 0,
                rows: 100,
                documentType: `(relation)`,
                museums: [],
                onlyMine: false,
                onlyOthers: false,
            })
        );
        return damsFetch(`/search?${searchParams.toString()}`);
    };

    const loadingCallback = (existingRelations, relatedModels) => {
        const rels = existingRelations.map((r) => {
            return {
                uniqueId: r.uniqueId,
                title: r.title,
                createdByName: r.createdByName,
                createdAt: r.createdAt,
                relationType: r.content.relationType || null,
                relationTypeId: () => {
                    const rt = relationTypes.find((f) => {
                        return (
                            f.title.toLowerCase() === rt["content"].relationType.toLowerCase()
                        );
                    });
                    return rt.id ? rt.id : null;
                },
                relationDescription: r.content.relationDescription || null,
                savedState: true,
                create: false,
                sourceId: r.sourceId,
            };
        });

        const newRelations = relatedModels.map((m) => {
            return {
                uniqueId: null,
                title: m.title,
                createdByName: m.createdByName,
                createdAt: m.createdAt,
                relationType: null,
                relationTypeId: null,
                relationDescription: "",
                savedState: false,
                create: true,
                sourceId: m.uniqueId,
            };
        });

        let d = null;
        if (!rels && newRelations) {
            d = newRelations;
        } else if (rels && newRelations) {
            d = rels.concat(newRelations);
        }
        if (d) {
            d = d.sort((a, b) => {
                return a.savedState - b.savedState;
            });
            setData(d);
        }

        const model = data?.find((m) => !m.uniqueId);
        setOpenDialog(model);
    };

    useEffect(() => {
        // Load data
        let relatedModels = null;
        let existingRelations = null;

        // Handle the last selected relations.
        const relatedModelsUniqueId = relations
            .filter((r) => {
                return r.documentType;
            })
            .map((d) => d.uniqueId);

        // Loads the complete model for the last selected related object.
        getRelatedModels(relatedModelsUniqueId).then((m) => {
            relatedModels = m.models;

            // Verify if the selected document is already related to one or more documents.
            const referenceIds = relations
                .filter((r) => {
                    return r.reference;
                })
                .map((r) => r.referenceId)
                .filter((r) => r);

            if (referenceIds?.length > 0) {
                getRelations(referenceIds).then((r) => {
                    existingRelations = r.models;
                    loadingCallback(existingRelations, relatedModels);
                });
            } else {
                loadingCallback([], relatedModels);
            }
        });
    }, [relations]);

    const relatedObjectFormCallback = (model) => {
        if (!model) {
            setOpenDialog(null);
            return;
        }

        model.uniqueId = model.uniqueId || null;

        const models = Array.from(data);
        const ix = models.findIndex((d) => {
            return model.uniqueId === d.uniqueId;
        });

        model.title = models[ix].title;
        model.sourceId = models[ix].sourceId;
        model.relationType = relationTypes.find((f) => {
            return f.id === model.relationTypeId;
        }).title;

        models[ix] = model;

        setData(models);
        setOpenDialog(null);
    };

    const confirmDelete = () => {
        const id = deleteDialog;
        const models = data;
        const ix = models.findIndex((f) => {
            return f.uniqueId === id;
        });
        models.splice(ix, 1);
        setData(models);
        setDeleteDialog("");
    };

    const onDeleteClick = (id) => {
        setDeleteDialog(id);
    };

    const onEditClick = (model) => {
        setOpenDialog(model);
    };

    const createCards = () => {
        if (!data) {
            return null;
        }
        return data.map((d) => {
            return createCard(d);
        });
    };

    const createCard = (cardData) => {
        return (
            <Card
                elevation={2}
                className={classes.root}
                key={crypto.randomUUID()}
            >
                <CardContent>
                    <div className={classes.itemContainer}>
                        <Typography variant={"subtitle2"} className={classes.label}>
                            {t("txtDocument", "Dokument")}
                        </Typography>
                        <Typography variant={"body2"} className={classes.item}>
                            {cardData.title}
                        </Typography>
                    </div>

                    {/* Metadata */}
                    <div className={classes.itemContainer}>
                        <Typography variant={"subtitle2"} className={classes.label}>
                            {t("txtType", "Type")}
                        </Typography>
                        <Typography variant={"body2"} className={classes.item}>
                            {cardData.relationType}
                        </Typography>
                    </div>
                    <div className={classes.itemContainer}>
                        <Typography variant={"subtitle2"} className={classes.label}>
                            {t("txtDescription", "Beskrivelse")}
                        </Typography>
                        <Typography variant={"body2"}>
                            {cardData.relationDescription}
                        </Typography>
                    </div>
                    <div className={classes.itemContainer}>
                        <If
                            boolean={
                                cardData.uniqueId &&
                                cardData.createdByName &&
                                cardData.createdAt
                            }
                        >
                            <Typography variant={"caption"}>
                                {t("txtCreated", "Opprettet")}: {cardData.createdByName} -{" "}
                                {format(cardData.createdAt, "P")}
                            </Typography>
                        </If>
                    </div>

                    <div className={classes.buttonContainer}>
                        {/* Action button pairs */}
                        <IconButton size="small" onClick={() => onEditClick(cardData)}>
                            <Edit/>
                        </IconButton>
                        <IconButton
                            size="small"
                            onClick={() => onDeleteClick(cardData.uniqueId)}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </div>
                </CardContent>
            </Card>
        );
    };

    return (
        <Root>
            <Box sx={{maxHeight: "75vh"}}>
                <Typography variant="h3">
                    {t("txtRelatedObjects", "Relaterte Objekter")}
                </Typography>
                <div className={classes.cardsList}>{createCards()}</div>
                <div className={classes.actionButtons}>
                    <Button onClick={() => callback()}>{t("btnCancel", "Avbryt")}</Button>
                    <Button onClick={() => callback(data)} color={"primary"}>
                        {t("btnSave", "Lagre")}
                    </Button>
                </div>
            </Box>
            {/* New/Edit relation dialog */}
            <DamsModal open={openDialog !== null}>
                <RelatedObjectForm
                    model={openDialog}
                    callback={relatedObjectFormCallback}
                />
            </DamsModal>
            {/* Delete dialog */}
            <Dialog open={deleteDialog !== ""} keepMounted>
                <DialogTitle>
                    {t("txtDeleteObjectRelation", "Slette objektrelasjon?")}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t(
                            "txtAreYouSure",
                            "Er du sikker på at du ønsker å slette valgte objektrelasjon?"
                        )}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialog(null)}>
                        {t("btnCancel", "Avbryt")}
                    </Button>
                    <Button
                        onClick={() => confirmDelete()}
                        variant={"contained"}
                        color={"primary"}
                    >
                        {t("btnOk", "Ok")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Root>
    );
};
