import React from "react";
import {styled} from "@mui/material/styles";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PropTypes from "prop-types";

const PREFIX = "AccordionFilter";

const classes = {
    root: `${PREFIX}-root`,
    removePadding: `${PREFIX}-removePadding`,
};

const StyledAccordion = styled(Accordion)(() => ({
    [`&.${classes.root}`]: {
        border: "none",
    },

    [`& .${classes.removePadding}`]: {
        padding: 0,
    },

}));

const AccordionFilter = ({title, count, children}) => {
    const [expanded, setExpanded] = React.useState(count ? count < 4 : true);

    return (
        <StyledAccordion
            className={classes.root}
            expanded={expanded}
            elevation={0}
            onChange={(e) => {
                const {target} = e;
                if (target['id'].indexOf('search') > -1) {
                    // Expand if searching.
                    setExpanded(target['id'].value !== '');
                } else if (target['id'].indexOf('clear') > -1) {
                    // Minimize if searchfield is cleared.
                    setExpanded(false);
                } else {
                    setExpanded((e) => !e)
                }
            }}
            disablegutters={"true"}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography variant={"subtitle2"} component="h2" sx={{minWidth: '100%'}}>
                    {title}
                </Typography>
            </AccordionSummary>
            <AccordionDetails
                disablegutters={"true"}
                sx={{paddingBottom: 0}}>
                {children}
            </AccordionDetails>
        </StyledAccordion>
    );
};

AccordionFilter.propTypes = {
    "title": PropTypes.any.isRequired,
    "count": PropTypes.number,
    "children": PropTypes.any.isRequired
};

export {AccordionFilter};
