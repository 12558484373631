import {FileGridItemBase} from "../FileGridItemBase";
import {TOGGLE_ALL_SELECTED, TOGGLE_SELECTED, useFileUploadDispatch, useFileUploadState} from "../fileUploadContext";
import React, {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import {Checkbox} from "@mui/material";

/**
 * HOC used when presenting an uploaded file as a checkable item (small screen), ready to be added to a folder.
 * @param WrappedComponent
 * @returns {function(*): *}
 */
function withCheckBoxSupport(WrappedComponent) {
    return function WithCheckBoxSupport(props) {

        const {allSelected, selected} = useFileUploadState();
        const fileUploadDispatch = useFileUploadDispatch();

        const [isRaised, setIsRaised] = useState(true);
        const [locallySelected, setLocallySelected] = useState(false);

        const {model} = props;

        const isSelected = () => {
            if (allSelected) {
                return true;
            } else {
                return selected.includes(model.uniqueId);
            }
        };

        /**
         * When clicked, store the model ID for future reference.
         * NOTE: When handling drag & drop actions, model.id is used instead of model.uniqueId.
         */
        const onClickHandler = () => {
            fileUploadDispatch({
                type: TOGGLE_SELECTED,
                uniqueId: model.id
            });
        };

        const handleCheck = (_event) => {
            setLocallySelected(!locallySelected);
            if (allSelected) {
                fileUploadDispatch({
                    type: TOGGLE_ALL_SELECTED
                });
            }
            fileUploadDispatch({
                type: TOGGLE_SELECTED,
                uniqueId: model.uniqueId
            });
        };

        /**
         * Hook used to lower/rise the card, based on whether the current object is selected or not.
         */
        useEffect(() => {
            setIsRaised(!selected.includes(model.id));
        }, [selected, model.id]);

        return <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
                disableRipple
                checked={isSelected()}
                onClick={handleCheck}
                onChange={(event) => !event.target.checked}
                value={model.uniqueId}
                color="secondary"
                sx={{
                    placeSelf: 'top left',
                    zIndex: 20,
                    backgroundColor: "rgba(255,255,255, 0.6)",
                }}
            />
            <WrappedComponent {...props}
                              onClick={onClickHandler}
                              raised={isRaised}
            />
        </Stack>;
    };
};

export const CheckableFileGridItem = withCheckBoxSupport(FileGridItemBase);