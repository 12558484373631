import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Modal from "@mui/material/Modal";
import Grow from "@mui/material/Grow";
import {WithSaveCircle} from "./WithSaveCircle";
import {styled} from "@mui/material/styles";
import {grey} from "@mui/material/colors";
import PropTypes from "prop-types";

const PREFIX = "DamsModal";

const classes = {
    modal: `${PREFIX}-modal`,
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
};

const StyledModal = styled(Modal)(({theme}) => ({
    [`&.${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.content}`]: {
        outline: "none",
        [theme.breakpoints.down("sm")]: {
            height: "100%",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            height: (props) => (props.isFullscreen ? "100%" : "90%"),
            width: (props) => (props.isFullscreen ? "100%" : "70%"),
        },
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(4),
        height: "100%",
        maxHeight: "98vh",
        backgroundColor: grey[100],
        overflowY: "auto"
    },
}));

const handleOnClose = (event, reason, callback) => {
    if (reason === 'backdropClick') {
        return;
    }
    callback();
};

const DamsModal = ({open, onHide, children}) => {
    return (
        <StyledModal
            className={classes.modal}
            open={open}
            onClose={(event, reason) => {
                handleOnClose(event, reason, onHide)
            }}
            closeAfterTransition
            disableEscapeKeyDown={true}
        >
            <Box className={classes.content}>
                <WithSaveCircle saving={false}>
                    <Grow in={open}>
                        <Paper className={classes.paper}>{children}</Paper>
                    </Grow>
                </WithSaveCircle>
            </Box>
        </StyledModal>
    );
};

DamsModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onHide: PropTypes.func
};

export {DamsModal};