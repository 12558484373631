import React from "react";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined, IndeterminateCheckBox,} from "@mui/icons-material";
import Box from "@mui/material/Box";

export const AddToFolderToolbar = ({
                                       files,
                                       checkAll,
                                       clearAll,
                                       numberOfChecked,
                                       children,
                                   }) => {
    const t = useNewFilesTranslation();

    const getCheckIcon = (_) => {
        const numChecked = numberOfChecked().length;
        if (numChecked === 0) {
            return <CheckBoxOutlineBlankOutlined onClick={checkAll}/>;
        } else if (numChecked > 0 && numChecked < files.length) {
            return <IndeterminateCheckBox/>;
        } else {
            return <CheckBoxOutlined onClick={clearAll}/>;
        }
    };

    return (
        <Toolbar sx={{width: '100%'}}>
            {getCheckIcon()}
            <Typography variant={"h6"} component={"h2"} marginLeft={1}>
                {t("markAndDrag", "Marker og dra filer over i ønsket mappe")}
            </Typography>
            <Box flexGrow={1} sx={{display: 'flex'}}/>
            {children}
        </Toolbar>
    );
};
