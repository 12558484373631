import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {FilterCheckbox} from "./FilterCheckbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export const FilterCopyrightType = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const copyrightTypeKey = "copyright_type";

    const [selected, setSelected] = useState([]);

    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === copyrightTypeKey
                    )
                ) {
                    selectedFilters.push({key: copyrightTypeKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find((s) => s.value === value && s.key === copyrightTypeKey)
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === copyrightTypeKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: copyrightTypeKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(copyrightTypeKey, selectedFilters);
        },
        [callback, selected]
    );

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(copyrightTypeKey, []);
        }
    }, [clearFilters]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === copyrightTypeKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
        }
    }, [fq]);

    const copyrightTypes = results.facets.copyrightTypes;
    const count = Object.keys(copyrightTypes).length;
    if (!count) {
        return null;
    }

    const getLabel = value => {
        if (value === 'copyright') {
            return t('copyrightFilterLabelCopyright', 'Opphavsrett');
        } else if (value === 'unknown') {
            return t('copyrightFilterLabelUnknown', 'Falt i det fri');
        } else {
            return '';
        }
    }

    const renderCheckboxes = (subjects) => {
        return Object.keys(subjects).map((value) => (
            <ListItem
                key={value}
                dense={true}
                disablegutters={"true"}
                onClick={changeHandler(value)}
            >
                <ListItemIcon>
                    <FilterCheckbox
                        selected={selected}
                        subjectsKey={copyrightTypeKey}
                        value={value}
                    />
                </ListItemIcon>
                <ListItemText id={`label-${value}`}>
                    {getLabel(value)} ({copyrightTypes[value].count})
                </ListItemText>
            </ListItem>
        ));
    };

    return (
        <>
            <Typography variant={"body2"}>{t('headingCopyright', 'Opphavsrett')}</Typography>
            <List>{renderCheckboxes(copyrightTypes)}</List>
        </>
    );
};
