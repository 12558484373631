import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import {MetadataRow} from "./MetadataRow";
import PropTypes from "prop-types";

/**
 * Compoment representing a license item.
 * @param license
 * @returns {JSX.Element}
 * @constructor
 */
const LicenseItem = ({license}) => {

    const {reference, responsible} = license;
    const {title} = reference;

    return <MetadataRow label={"Lisens"}>
        <Typography variant={"caption"}>{title}</Typography>
        {responsible?.map(r => {
            return <Stack key={r.reference.title} direction={"row"} sx={{marginBottom: 2}}>
                <Chip label={r.reference.title} size={"small"}/>
            </Stack>
        })
        }
    </MetadataRow>;
};

LicenseItem.propTypes = {
    license: PropTypes.object.isRequired
};

export {LicenseItem};