import {FoldersCheckboxFilter} from "./FoldersCheckboxFilter";
import {useTranslation} from "react-i18next";


/**
 * Method used to display the folder tags filter, used to fetch folders, based on specified tags.
 * @returns {JSX.Element}
 * @constructor
 */
export const FilterTags = ({callback}) => {
    const {t} = useTranslation('dams.folder', {useSuspense: false});
    return <FoldersCheckboxFilter title={t("tags", "Tags")}
                                  fqFacetKey="tags.reference.title"
                                  resultFacetKey={'tags'}
                                  callback={callback}/>;
};