import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsSearch, solrEscape} from "../app/damsFetch";
import {useAddPerson} from "../person/useAddPerson";


/**
 * Hook containing metadata mapping utility functions.
 * @returns {[(function(*, *): Promise<*>|Promise<Response>),(function(*, *): Promise<*>|Promise<Response>)]}
 */
export const useMetadataMappingUtility = () => {

    const postPersons = useAddPerson();

    /**
     * Creates a new person of the specified type in DAMS, and associate it with the specified file.
     * @param collectionId
     * @param personName
     * @param personType
     * @param filename
     * @param mappedFile
     * @returns {Promise<unknown>}
     */
    const createPersonInDams = ({collectionId, personName, personType, filename, mappedFile}) => {
        return postPersons({
            persons: [
                {
                    name: personName,
                    collectionId: collectionId,
                    yearOfBirth: "",
                    yearOfDeath: "",
                },
            ],
        }).then((persons) => {
            return Promise.resolve({
                ...mappedFile,
                [filename]: {
                    ...mappedFile[filename],
                    [personType]: persons,
                },
            });
        });
    };


    const damsSearchSuccessCallback = ({collectionId, filename, personName, personType, mappedFile, json}) => {
        if (json.count) {
            return Promise.resolve({
                ...mappedFile,
                [personType]: [
                    {
                        referenceType: "person",
                        _action: "put",
                        collectionId: collectionId,
                        reference: {
                            uniqueId: json['models'][0].uniqueId,
                            locale: "no",
                            _action: "put",
                            title: json['models'][0].title,
                            status: "published",
                            source: json['models'][0].source,
                            sourceId: json['models'][0].sourceId,
                            content: json['models'][0].content,
                        },
                    },
                ],
            });
        } else {
            // If the person was not found among the data in DAMS, create it!
            createPersonInDams({
                collectionId,
                personName,
                personType,
                filename,
                mappedFile
            }).then();
        }
    };

    const findOrCreatePerson = (collectionId, mappedFile, personType) => {
        if (!mappedFile) {
            return;
        }

        const filename = mappedFile.file.name;
        const name = mappedFile[personType] || undefined;

        if (!name || !name.length) {
            return Promise.resolve(mappedFile);
        }
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: solrEscape(`title:"${name}"`),
                fl: "title,id",
                sort: "title asc",
                expand: true,
                documentType: `("person")`,
            })
        );

        return damsSearch(searchParams).then(json =>
            damsSearchSuccessCallback({
                collectionId: collectionId,
                filename: filename,
                personName: name,
                personType: personType,
                mappedFile: mappedFile,
                json: json
            }));
    };


    const createPerson = (collectionId, mappedFile) => {
        return findOrCreatePerson(collectionId, mappedFile, 'persons');
    };

    const createProducer = (collectionId, mappedFile) => {
        return findOrCreatePerson(collectionId, mappedFile, 'producer')
    };

    return [createPerson, createProducer];
};