import React, {useEffect, useState} from "react";
import {usePostFolderDocument} from "../../folder/usePostFolderDocument";
import {FolderCardBase} from "./FolderCardBase";
import {
    CLEAR_ADDING_TO_FOLDER,
    CLEAR_SELECTED,
    SET_ADDING_TO_FOLDER,
    useFileUploadDispatch,
    useFileUploadState
} from "../fileUploadContext";
import Badge from "@mui/material/Badge";
import {clientLog} from "../../clientLog";


/**
 * Folder card that acts as a drop-area, used when adding files to a folder on small screens.
 * @param WrappedComponent
 * @returns {function(*): *}
 */
function withDragDropSupport(WrappedComponent) {
    return function WithDragDropSupport(props) {

        const {folder} = props;

        const {selected, addingToFolder} = useFileUploadState();
        const fileUploadDispatch = useFileUploadDispatch();

        const [postFolderDocumentResponse, postFolderDocument, clearAll] = usePostFolderDocument(folder.id);

        const [filesAdded, setFilesAdded] = useState(undefined);

        const {pending, success, error} = postFolderDocumentResponse;

        const onDroppedHandler = (event) => {
            fileUploadDispatch({
                type: SET_ADDING_TO_FOLDER,
                folderId: folder.id
            });
            const droppedTarget = JSON.parse(event.dataTransfer.getData("application/json"));

            droppedTarget
                .filter(t => typeof (t) === 'string')
                .forEach((documentId) => {
                        postFolderDocument({documentId: documentId});
                    }
                );
        };

        const onDragOverHandler = (e) => {
            e.preventDefault();
        };

        useEffect(() => {
            if (!addingToFolder || pending > 0) {
                return;
            }

            if (pending === 0 && success >= selected.filter(s => typeof (s) === 'string').length) {
                setTimeout(() => {
                    fileUploadDispatch({type: CLEAR_ADDING_TO_FOLDER});
                    fileUploadDispatch({type: CLEAR_SELECTED});
                    clearAll();
                }, 1500);
            }

            if (pending === 0 && error > 0 && addingToFolder) {
                const msg = `failed documents: ${error}`;
                clientLog('error', msg, 'DroppableFolderCard');
                fileUploadDispatch({type: CLEAR_ADDING_TO_FOLDER});
            }
        }, [addingToFolder, pending, success, error])

        /**
         * Hook used to keep track of the final result when adding files to a folder.
         * Used to keep the badge-state correct.
         */
        useEffect(() => {
            if (postFolderDocumentResponse.success === 0) {
                return;
            }
            setFilesAdded(postFolderDocumentResponse.success);
        }, [postFolderDocumentResponse.success]);

        return <Badge
            badgeContent={postFolderDocumentResponse.success > 0 ? postFolderDocumentResponse.success : filesAdded}
            max={10000}
            color={"primary"}>
            <WrappedComponent {...props}
                              onDroppedHandler={onDroppedHandler}
                              onDragOverHandler={onDragOverHandler}/>
        </Badge>
    };
};

export const DroppableFolderCard = withDragDropSupport(FolderCardBase);
