import React, {useEffect, useState} from "react";
import Hls from "hls.js";
import {orderPlaybackLink} from "../dms/dmsSecurity";
import {ADD_MESSAGE, useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {useSearchResultDetailsDispatch, VIEW_FULLSCREEN,} from "../search-result-details/SearchResultDetailsContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {getDmsIdFromModel} from "../utility";
import PropTypes from "prop-types";

/**
 * Renders a video player component with the ability to play HLS videos.
 *
 * @param {Object} props - The props object containing the following properties:
 * @param {Object} model - The model object containing the video data.
 * @param {string} width - The width of the video player.
 * @param {boolean} [mediaPlaying=false] - Indicates if the video is currently playing.
 * @param {boolean} [smallScreen=false] - Indicates if the screen is small.
 * @return {JSX.Element} The rendered video player component.
 */
const VideoPlayer = ({
                         model,
                         width,
                         mediaPlaying = false,
                         smallScreen = false,
                     }) => {
    const snackbarDispatch = useSnackbarDispatch();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();
    const t = useSnackbarTranslation();

    const [playing, setPlaying] = useState(false);
    const [myPlayer, setMyPlayer] = useState(null);
    const [playlist, setPlaylist] = useState(null);

    const showError = (_) => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("videoPlaybackFailedTitle", "Videoavspilling feilet"),
                body: t(
                    "videoPlaybackFailedMessage",
                    "Det oppstod en ukjent feil - forsøk igjen senere."
                ),
                type: "error",
            },
        });
    };

    useDeepCompareEffect(() => {
        const collectionId = model.collectionId;
        const dmsId = getDmsIdFromModel(model);
        orderPlaybackLink(collectionId, dmsId).then((json) => {
            if (!json) {
                showError();
            } else {
                setPlaylist(json.urls);
            }
        });

        if (smallScreen && playing) {
            searchResultDetailsDispatch({type: VIEW_FULLSCREEN});
        }
    }, [model]);

    useEffect(() => {
        class VideoLoader extends Hls.DefaultConfig.loader {
            constructor(config) {
                super(config);
                const load = this.load.bind(this);
                this.load = (context, cfg, callbacks) => {
                    if ("manifest" !== context['type']) {
                        load(
                            {
                                ...context,
                                url: context.url,
                            },
                            cfg,
                            callbacks
                        );
                    } else {
                        load(context, cfg, callbacks);
                    }
                };
            }
        }

        if (null !== myPlayer && playlist && Hls.isSupported()) {
            const video = myPlayer;
            const hls = new Hls({
                loader: VideoLoader,
                debug: false,
            });
            hls.loadSource(playlist.hls);
            hls.attachMedia(video);
            video.controls = true;
            hls.on(Hls.Events.MANIFEST_PARSED, () => video.play());
        }
    }, [playlist, myPlayer]);

    useEffect(() => {
        if (!mediaPlaying) {
            setPlaylist(null);
            setMyPlayer(null);
        }
        setPlaying(mediaPlaying);
    }, [mediaPlaying]);

    const getPlayerWidth = () => {
        const w = parseInt(width);
        const cw = (pWidth) => {
            if (pWidth < 640) return 480;
            if (pWidth < 960) return 640;
            if (pWidth < 1280) return 960;
            if (pWidth < 1920) return 1280;
            if (pWidth >= 1920) return 1920;
        };
        return cw(w);
    };

    return (
        <div style={{width: "100%"}}>
            <video
                width={getPlayerWidth() + "px"}
                ref={(player) => setMyPlayer(player)}
                autoPlay={true}
            />
        </div>
    );
};

VideoPlayer.propTypes = {
    model: PropTypes.object.isRequired,
    width: PropTypes.number.isRequired,
    mediaPlaying: PropTypes.bool,
    smallScreen: PropTypes.bool,
};

export {VideoPlayer};