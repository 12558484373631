import {MetadataAccordion} from "./MetadataAccordion";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {usePostDocuments} from "../documents/usePostDocuments";
import {
    useSearchResultDetailsState,
    useSearchResultDetailsTranslation
} from "../search-result-details/SearchResultDetailsContext";
import "../app/extensions";
import {getFileExtensionFromFilename} from "../damsFileObjectDefinitions";
import useDeepCompareEffect from "use-deep-compare-effect";
import {SET_RESULT, useSearchDispatch, useSearchState} from "../search/SearchContext";
import {extractMediaMeta} from "../dms/dmsSecurity";
import {clientLog} from "../clientLog";
import {dateFormatter} from "../utility";

const DataBox = ({children}) => {
    return <Box sx={{marginBottom: 2}}>{children}</Box>;
};

const DataTitle = ({text}) => {
    return (
        <Typography variant={"caption"} color={"secondary"}>
            {text}
        </Typography>
    );
};

export const SummaryAccordion = ({isProjectObject}) => {
    const {results} = useSearchState();
    const {model} = useSearchResultDetailsState();

    const searchDispatch = useSearchDispatch();

    const t = useSearchResultDetailsTranslation();

    // eslint-disable-next-line no-unused-vars
    const [postDocumentsResponse, postDocuments] = usePostDocuments(); // NOSONAR

    const {uniqueId, collectionId, content, documentType} = model;
    const {mediae, coordinates, documentationType} = content;
    const {
        title,
        sourceId,
        durationSeconds = undefined,
        fileSize = undefined,
        resolution = undefined
    } = mediae[0].reference;

    // The object's metadata is stored in this variable when read.
    let fileMeta;

    const getDurationInHrFormat = () => {
        const seconds = parseFloat(durationSeconds);
        if (seconds) {
            return new Date(seconds * 1000).toISOString().substring(11, 19);
        } else {
            return t("summaryAccordionNotAvailable", "Ikke tilgjengelig");
        }
    };

    const getFileType = () => {
        const f = title;
        return f.substr(f.lastIndexOf(".") + 1);
    };

    const getFileSize = () => {
        return fileSize?.formatBytes();
    };

    const getResolutionInHrFormat = () => {
        if (!resolution || resolution === "") {
            return t("summaryAccordionNotAvailable", "Ikke tilgjengelig");
        }
        if (documentType === "Audio" && !isNaN(resolution)) {
            return resolution.formatHertz();
        }
        return resolution;
    };

    const getKeyValue = (keys, metadata) => {
        for (let i = 0, max = keys.length; i < max; i++) {
            const key = keys[i];
            if (Object.keys(metadata).includes(key)) {
                return metadata[key];
            }
        }
    };

    const getWidth = metadata => {
        const resolutionTags = ['file:ImageWidth', 'eXIF:ImageWidth', 'quickTime:ImageWidth',
            'rIFF:ImageWidth', 'aSF:ImageWidth', 'xMP:VideoFrameSizeH'];
        return getKeyValue(resolutionTags, metadata);
    };

    const getHeight = metadata => {
        const resolutionTags = ['file:ImageHeight', 'eXIF:ImageHeight', 'quickTime:ImageHeight',
            'rIFF:ImageHeight', 'aSF:ImageHeight', 'xMP:VideoFrameSizeW'];
        return getKeyValue(resolutionTags, metadata);
    };

    const getImageOrVideoResolution = metadata => {
        if (metadata['composite:ImageSize']) {
            let separator = (metadata['composite:ImageSize'].indexOf('x') > -1) ? 'x' : ' ';
            const parts = metadata['composite:ImageSize'].split(separator);
            return `${parts[0]}x${parts[1]}`;
        } else {
            let width = getWidth(metadata);
            let height = getHeight(metadata);

            if (width && height) {
                return `${width}x${height}`;
            }
        }
    };

    function getResolution() {
        let res = getImageOrVideoResolution(fileMeta);

        if (Object.keys(fileMeta).includes("mPEG:AudioBitrate")) {
            res = fileMeta["mPEG:AudioBitrate"];
        }
        return res;
    }

    /**
     * Extracts the metadata from the fileMeta object, and saves it along with the object.
     */
    function updateMetadata(fileMeta) {
        let res = getResolution();
        const fs = fileMeta["file:FileSize"];
        const durationInSecs = fileMeta["quickTime:Duration"]
            || fileMeta['composite:Duration']
            || fileMeta['aSF:Duration']
            || fileMeta['flash:Duration']
            || fileMeta['matroska:Duration']
            || fileMeta['quickTime:TrackDuration']
            || fileMeta['quickTime:MediaDuration']
            || 0;
        const fileExtension = getFileExtensionFromFilename(model.content.mediae[0].reference.title);

        const updatedReference = (model.content.mediae[0].reference = {
            ...model.content.mediae[0].reference,
            resolution: res,
            fileSize: fs,
            fileExtension: fileExtension,
            durationSeconds: durationInSecs,
        });
        const updatedModel = model;

        updatedModel.content.mediae[0].reference = updatedReference;
        postDocuments([updatedModel]);

        // Update the search results, to keep in sync!
        const searchResults = {...results};
        const existingIx = searchResults.models.findIndex(m => m.uniqueId === updatedModel.uniqueId);
        if (existingIx > -1) {
            searchResults.models[existingIx] = updatedModel;
        }

        // Replaces this model's entry in the search-results, in order to keep the results in sync.
        // when browsing back- and forward in the result set.
        searchDispatch({
            type: SET_RESULT,
            count: searchResults.count,
            facets: searchResults.facets,
            models: searchResults.models,
            stats: searchResults.stats
        });
    }

    const shouldUpdateMetadata = (fileMeta) => {
        let shouldUpdate = false;

        const shouldUpdateResolution =
            ['Video', 'StillImage'].includes(documentType) && typeof (resolution) === 'undefined';

        const shouldUpdateFileSize =
            ['Audio', 'Video', 'StillImage', 'Misc'].includes(documentType) && typeof (fileSize) === 'undefined';

        const shouldUpdateDuration =
            ['Audio', 'Video'].includes(documentType) && typeof (durationSeconds) === 'undefined';

        if (['Audio', 'Video'].includes(documentType)) {
            shouldUpdate = Object.keys(fileMeta).length > 0 && (shouldUpdateDuration || shouldUpdateFileSize || shouldUpdateResolution);
        } else if ('StillImage' === documentType) {
            shouldUpdate = Object.keys(fileMeta).length > 0 && (shouldUpdateFileSize || shouldUpdateResolution);
        } else if ('Misc' === documentType) {
            shouldUpdate = Object.keys(fileMeta).length > 0 && (shouldUpdateFileSize);
        }

        return shouldUpdate;
    };

    /**
     * Hook used to update metadata, if necessary.
     */
    useDeepCompareEffect(() => {
        async function handleMetadata() {
            try {
                const json = await extractMediaMeta(collectionId, sourceId);
                fileMeta = dateFormatter(json[0]);

                if (shouldUpdateMetadata(fileMeta)) {
                    updateMetadata(fileMeta);
                }
            } catch (e) {
                clientLog('error', `failed to load metadata: ${sourceId}`, 'objectview');
            }
        }

        if (!['Audio', 'Video', 'StillImage', 'Misc'].includes(documentType)) {
            return;
        }

        handleMetadata()
            .then()
            .catch(() => clientLog('warn', `failed to extract metadata for model: ${model.uniqueId}`));
    }, [documentType, model]);

    return (
        <MetadataAccordion
            title={t("summaryAccordionSummary", "Oppsummering")}
            defaultExpanded={true}
        >
            <Grid
                container
                direction={"row"}
                spacing={2}
                justifyContent={"space-between"}
                key={`metadata-${uniqueId}`}
            >
                <Grid>
                    <DataBox>
                        <DataTitle text={t("summaryAccordionFileType", "Filtype")}/>
                        <Typography sx={{textTransform: "uppercase"}}>
                            {getFileType()}
                        </Typography>
                    </DataBox>
                    <DataBox>
                        <DataTitle text={t("summaryAccordionResolution", "Oppløsning")}/>
                        <Typography>{getResolutionInHrFormat()}</Typography>
                    </DataBox>
                </Grid>
                <Grid>
                    <DataBox>
                        <DataTitle text={t("summaryAccordionFileSize", "Filstørrelse")}/>
                        <Typography>{getFileSize()}</Typography>
                    </DataBox>
                    <DataBox>
                        <DataTitle text={t("summaryAccordionDuration", "Lengde")}/>
                        <Typography>{getDurationInHrFormat()}</Typography>
                    </DataBox>
                </Grid>

                {isProjectObject && <Grid>
                    <DataBox>
                        <DataTitle text={t('summaryAccordionDocumentationType', 'Dokumentasjonstype')}/>
                        <Typography>{documentationType?.title || t('summaryAccordionDocumentationTypeNotAvailable', 'Ikke tilgjengelig')}</Typography>
                    </DataBox>
                    <DataBox>
                        <DataTitle text={t('summaryAccordionCoordinates', 'Koordinater')}/>
                        <Typography>{coordinates || t('summaryAccordionCoordinatesNotAvailable', 'Ikke tilgjengelig')}</Typography>
                    </DataBox>
                </Grid>}
            </Grid>

        </MetadataAccordion>
    );
};
