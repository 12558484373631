import React, {useRef} from "react";
import {SHOW_ADD_MORE_FILES, useDocumentDispatch, useDocumentState,} from "../../documents/documentContext";
import {UploadMoreFiles} from "../UploadMoreFiles";
import {useDmsState} from "../../dms/dmsContext";
import {useNewFilesTranslation} from "../NewFilesTranslationContext";
import {useUploadFiles} from "../useUploadFiles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

import {FileCardSmallScreen} from "./FileCardSmallScreen";
import {useTheme} from "@emotion/react";

export const AddedFilesGridSmallScreen = ({hide = false}) => {
    const t = useNewFilesTranslation();
    const theme = useTheme();
    const imageDispatch = useDocumentDispatch();

    const {files, showAddMoreFiles, formData} = useDocumentState();
    const {progressByFile} = useDmsState();

    const gridRef = useRef();

    useUploadFiles(formData.collectionId);

    const openAddMoreFiles = () => {
        imageDispatch({
            type: SHOW_ADD_MORE_FILES,
        });
    };

    const getUploadProgressText = () => {
        const numberOfUploaded = Object.values(progressByFile).filter(
            (value) => 100 === value
        ).length;
        const numberTotal = files.length;
        return t("uploadProgressText", "{{uploaded}} av {{total}} er lastet opp", {
            uploaded: numberOfUploaded,
            total: numberTotal,
        });
    };

    return (
        <Box
            sx={{
                padding: theme.spacing(4),
                display: hide ? "none" : "inherit",
            }}
        >
            <Box>
                <Box>
                    <Typography variant={"caption"} sx={{marginLeft: 0.5}}>
                        {getUploadProgressText()}
                    </Typography>
                </Box>
                <Button
                    startIcon={<CloudUploadIcon color={"disabled"}/>}
                    onClick={openAddMoreFiles}
                    aria-label={"upload-more"}
                    size={"medium"}
                >
                    {t("uploadMore", "Last opp flere")}
                </Button>
            </Box>
            <UploadMoreFiles open={showAddMoreFiles}/>
            <Grid
                container
                direction={"row"}
                spacing={1}
                sx={{
                    paddingTop: 1,
                    paddingBottom: 1,
                }}
                ref={gridRef}
            >
                {files
                    .sort((a, b) => a.title.localeCompare(b.title))
                    .map((file) => (
                        <Grid key={file.title}>
                            <FileCardSmallScreen
                                collectionId={formData.collectionId}
                                file={file}
                                progress={progressByFile[file.title] || 0}
                            />
                        </Grid>
                    ))}
            </Grid>
        </Box>
    );
};
