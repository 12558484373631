import React, {useState} from "react";
import {useAddPerson} from "./useAddPerson";
import {useField, useFormikContext} from "formik";
import {useDocumentState} from "../documents/documentContext";
import {useAuthsState} from "../auths/authsContext";
import {KulturNavSearch} from "../kulturnav/KulturNavSearch";
import {
    concatWithExisting,
    excludeThoseAlreadyListedAsKulturnav,
    fieldValuesWithCollectionIds,
    sourceToUniqueId
} from "../metadata/inputMapper";
import PersonIcon from "@mui/icons-material/Person";
import {ModalCreateResource} from "../metadata/ModalCreateResource";
import {ModalCreateResourceHeader} from "../metadata/ModalCreateResourceHeader";
import {FormNewPerson} from "./FormNewPerson";
import {getBrowserLocale} from "../utility";
import {fetchPersonsFromDamsDb} from "./localDbHelper";
import {changeHandler, fetchKulturNavData} from "./personhelper";

/**
 * Renders an input component for selecting persons.
 *
 * NOTE:
 * For an explanation of the currentFieldRef,
 * see the component FormikSelectAssociated.
 *
 * @param {string} formikKey - The key for the Formik field.
 * @param {string} label - The label for the input.
 * @param {string} createLabel - The label for the create button.
 * @param {boolean} [disabled=false] - Whether the input is disabled.
 * @param {boolean} [alwaysActive=false] - Whether the input is always active.
 * @param {string|null} helperText=null] - The helper text for the input.
 * @param {string} [size="large"] - The size of the input.
 * @param {string|null} [projectCollectionId=null] - The project collection ID.
 * @param {Object|null} [currentFieldRef=null] - The current field reference.
 * @param {string} [inputVariant="outlined"] - The variant of the input.
 * @return {JSX.Element} The rendered input component.
 */
export const InputPersons = ({
                                 formikKey,
                                 label,
                                 createLabel,
                                 disabled = false,
                                 alwaysActive = false,
                                 helperText = null,
                                 size = "large",
                                 projectCollectionId = null,
                                 currentFieldRef = null,
                                 inputVariant = 'outlined',
                             }) => {
    const [field, meta] = useField(formikKey);
    const {setFieldValue, values} = useFormikContext();
    const locale = getBrowserLocale();
    const [newPerson, setNewPerson] = useState(null);
    const postPersons = useAddPerson();
    const {collectionIds} = useDocumentState();
    const {museumCollections} = useAuthsState();

    // NOTE: When editing data for a project, only one collectionID is selected, and is sent directly to this component.
    const currentCollectionIds = collectionIds.length === 0 ? [projectCollectionId] : collectionIds;

    const museums = museumCollections
        .filter((museumCollection) =>
            currentCollectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    /**
     * Search handler used to fetch data from both KulturNav and the DAMS database, and consolidate the data
     * to a list without duplicates, and necessary data present for each object in the list.
     * @param params    object  The search parameters
     * @param callback  function    Callback function
     * @returns {Promise<void>}
     */
    const personSearchHandler = async (params, callback) => {
        // Get the persons added to the local DAMS database.
        const damsPersons = await fetchPersonsFromDamsDb(params, museums);

        // Get the persons from KulturNav.
        const kulturNavPersons = await fetchKulturNavData('Person', params, locale);

        // Make sure each person fetched from the DAMS database has a unique ID.
        const damsLookupIds = sourceToUniqueId(damsPersons);

        // Match the resultset from KulturNav with KulturNav persons already
        // existing in the DAMS database.
        const kulturnavMapped = concatWithExisting(kulturNavPersons, damsLookupIds);

        // Return the "persons" array, containing persons that already exists in the DAMS database,
        // and who is fetched from KulturNav. The array is stripped from duplicates, as there may be persons
        // that is contained within both the DAMS- and the KulturNav search-results,
        // that has already been added to the DAMS database.
        callback([...kulturnavMapped, ...excludeThoseAlreadyListedAsKulturnav(damsPersons, kulturnavMapped)]);
    };

    const onChange = (persons) => {
        if (currentFieldRef) {
            // Updating the Formik field via a ref.
            currentFieldRef.responsible = persons;

            const fieldUniqueId = currentFieldRef.reference.uniqueId;
            const existingFieldValues = [...values[field.name]];

            // Find the field that matches the fieldUniqueId in the list of existingFields.
            const existingField = existingFieldValues.find((field) => field.reference.uniqueId === fieldUniqueId);
            if (existingField) {
                existingField.responsible = persons;
            }
            setFieldValue(field.name, existingFieldValues).then();
        } else {
            // Updating the Formik field directly.
            setFieldValue(field.name, persons).then();
        }
    };

    const onAdd = (persons) => {
        if (currentFieldRef !== null) {
            // Addressing the Formik field via a ref.
            const {responsible} = currentFieldRef;
            if (Array.isArray(responsible)) {
                currentFieldRef.responsible = [...responsible, ...persons];
            } else {
                currentFieldRef.responsible = [...persons];
            }

            // Update the values[field.name] item where reference.uniqueId === currentFieldRef.reference.uniqueId
            const fieldUniqueId = currentFieldRef.reference.uniqueId;
            const existingFieldValues = [...values[field.name]];
            const existingField = existingFieldValues.find((field) => field.reference.uniqueId === fieldUniqueId);
            if (existingField) {
                existingField.responsible = [...responsible, ...persons];
            }
            setFieldValue(field.name, existingFieldValues).then();
        } else {
            // Addressing the Formik field directly.
            if (Array.isArray(field.value)) {
                setFieldValue(field.name, [...field.value, ...persons]).then();
            } else {
                setFieldValue(field.name, [...persons]).then();
            }
        }
    };

    const clearHandler = () => {
        onChange([]);
    };

    /**
     * Gets the set value for the current field.
     *
     * NOTE:
     * [
     *  {
     *      collectionId: [4],
     *      content: {name: 'Bjørgaas, Knut', yearOfBirth: 1883, yearOfDeath: 1946},
     *      id: 7,
     *      source: "Personregister i SAMLA (Universitetet i Bergen)",
     *      sourceId: "3cbe2921-7ece-4366-b261-ed1325ea6d9e",
     *      title: "Bjørgaas, Knut (1883 - 1946)",
     *      uniqueId: "5d0214b9-cf4c-4079-b46c-d9488dbc82ed"
     *  },
     *  ...
     * ]
     *
     * @returns {*}
     */
    const getValue = () => {
        let value = [];
        if (currentFieldRef !== null) {
            // Get the responsible array from the Formik field via a ref.
            // Get the responsible persons from values['licenses'] where reference.uniqueId === currentFieldRef.reference.uniqueId
            const existingField = values[field.name].find((f) => f.reference.uniqueId === currentFieldRef.reference.uniqueId);
            if (existingField) {
                const responsible = existingField['responsible'];
                value = fieldValuesWithCollectionIds(responsible);
            }
        } else if (field.value) {
            value = fieldValuesWithCollectionIds(field.value);
        }
        return value;
    };

    if (!alwaysActive && (undefined === meta.initialValue || !currentCollectionIds.length)) {
        return null;
    }

    return (
        <>
            <KulturNavSearch
                id={"search-knav-persons"}
                searchHandler={personSearchHandler}
                onChange={v => changeHandler(postPersons, currentCollectionIds, v, setNewPerson, onAdd, onChange)}
                onClear={clearHandler}
                disabled={disabled}
                inputLabel={label}
                Icon={PersonIcon}
                multiple={true}
                helperText={helperText}
                size={size}
                value={getValue()}
                inputVariant={inputVariant}
            />
            <ModalCreateResource
                open={null !== newPerson}
                onHide={() => setNewPerson(null)}
            >
                <ModalCreateResourceHeader title={createLabel}/>
                <FormNewPerson
                    person={newPerson}
                    onClose={() => setNewPerson(null)}
                    addHandler={onAdd}
                    collectionIds={currentCollectionIds}
                />
            </ModalCreateResource>
        </>
    );
};
