import React, {useEffect} from "react";
import {refreshToken} from "@ekultur/authentication";
import {useAuthsDispatch, USER_LOGGED_IN} from "./authsContext";
import {useLocation} from "react-router-dom";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    root: {
        height: "100vh",
        overflowY: "hidden",
        overflowX: "hidden",
    },
}));

export const WithAuthentication = ({children}) => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useAuthsDispatch();

    useEffect(() => {
        if (!location.pathname.startsWith("/oauth2/callback")) {
            refreshToken({
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: () => dispatch({type: USER_LOGGED_IN}),
            });
        }
    });

    return <div className={classes.root}>{children}</div>;
};
