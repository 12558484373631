import React, {useEffect, useState} from "react";
import {Stack} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import {useMyMuseums} from "../museum/useMyMuseums";
import {useAuthsState} from "../auths/authsContext";
import {damsFetch} from "../app/damsFetch";
import Typography from "@mui/material/Typography";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MovieIcon from '@mui/icons-material/Movie';
import ImageIcon from '@mui/icons-material/Image';
import DescriptionIcon from '@mui/icons-material/Description';
import TableRowsIcon from '@mui/icons-material/TableRows';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ThreeDRotationIcon from '@mui/icons-material/ThreeDRotation';
import MuseumIcon from '@mui/icons-material/Museum';

import {blue, grey, lightGreen, orange, purple, teal, yellow} from '@mui/material/colors';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Box from "@mui/material/Box";


const useStyles = makeStyles(() => ({
    iconBig: {
        width: "200px",
        height: "200px",
        minWidth: '200px',
        minHeight: '200px',
    },
    icon: {
        width: "100px",
        height: "100px",
        minWidth: '100px',
        minHeight: '100px',
    }
}));


export const StatItem = ({children}) => {
    return <Stack alignItems={'center'}>{children}</Stack>
}


export const DAMSStats = () => {
    const {museumCollections} = useAuthsState();
    const myMuseums = useMyMuseums({});
    const [museumsAndCollections, setMuseumsAndCollections] = useState([]);
    const [objectTypes, setObjectTypes] = useState([]);

    const classes = useStyles();

    useEffect(() => {
        if (myMuseums.length === 0 || museumCollections.length === 0) {
            return;
        }
        const mappedMyMuseums = myMuseums.map(m => {
            const museumCollection = museumCollections.find(mc => mc.museumId === m.id);
            return {
                ...m,
                collectionId: museumCollection.collectionId
            };
        });
        setMuseumsAndCollections(mappedMyMuseums);
    }, [museumCollections, myMuseums]);

    useEffect(() => {
        let promises = [];
        promises.push(
            damsFetch('/statistics/solr/stats/').then(json => {
                const objTypes = statsToObjArray(json);
                return {collectionId: -1, museumName: 'Alle', objectTypes: objTypes};
            })
        );

        // Fetch data for each museum
        for (const m in museumsAndCollections) {
            let url = '/statistics/solr/stats/' + m.collectionId;
            promises.push(damsFetch(url).then(json => {
                const objTypes = statsToObjArray(json);
                return {...m, objectTypes: objTypes}
            }));
        }

        Promise.all(promises).then(data => {
            setObjectTypes(data);
        });

    }, [myMuseums]);

    const statsToObjArray = stats => {
        let out = [];
        let tmp;
        let c = 0;
        stats.forEach(o => {
            if (c % 2 === 0) {
                tmp = o;
            } else {
                out.push({name: tmp, value: o});
            }
            c++;
        });
        return out;
    }

    const renderObjectTypesStats = (oTypes, iconSize) => {
        return oTypes.map(ot => {
            if (ot.name === 'StillImage') {
                return <StatItem key={`item_${ot.name}`}>
                    <ImageIcon className={iconSize}
                               sx={{color: blue[400]}}/>
                    {ot.value} bilder
                </StatItem>;
            } else if (ot.name === 'Video') {
                return <StatItem key={`item_${ot.name}`}>
                    <MovieIcon className={iconSize}
                               sx={{color: purple[400]}}/>
                    {ot.value} videoklipp
                </StatItem>;
            } else if (ot.name === 'Audio') {
                return <StatItem key={`item_${ot.name}`}>
                    <VolumeUpIcon className={iconSize}
                                  sx={{color: lightGreen[400]}}/>
                    {ot.value} lydklipp
                </StatItem>;
            } else if (ot.name === 'Misc' || ot.name === 'Dokument') {
                return <StatItem key={`item_${ot.name}`}>
                    <DescriptionIcon className={iconSize}
                                     sx={{color: teal[400]}}/>
                    {ot.value} dokumenter
                </StatItem>
            } else if (ot.name === 'Tabell') {
                return <StatItem key={`item_${ot.name}`}>
                    <TableRowsIcon className={iconSize}
                                   sx={{color: yellow[400]}}/>
                    {ot.value} tabeller
                </StatItem>
            } else if (ot.name === 'Geodata') {
                return <StatItem key={`item_${ot.name}`}>
                    <LocationOnIcon className={iconSize}
                                    sx={{color: orange[400]}}/>
                    {ot.value} geodata
                </StatItem>
            } else if (ot.name === 'Modell') {
                return <StatItem key={`item_${ot.name}`}>
                    <ThreeDRotationIcon className={iconSize}
                                        sx={{color: grey[400]}}/>
                    {ot.value} objekter
                </StatItem>
            } else {
                return <></>;
            }
        });

    }

    const renderMainStats = () => {
        if (objectTypes.length === 0) {
            return <></>;
        }

        const filteredOt = objectTypes.filter(ot => ot.collectionId === -1);
        return <Stack direction={'row'}
                      spacing={2}>{renderObjectTypesStats(filteredOt[0]['objectTypes'], classes.iconBig)}</Stack>
    };

    const renderMuseumStats = () => {
        if (objectTypes.length === 0) {
            return <></>;
        }

        const oTypes = objectTypes.filter(ot => ot.collectionId > -1);
        return <>
            {oTypes.map(o => {
                return <Card variant={"outlined"} sx={{marginBottom: 2}} key={o.name}>
                    <CardContent>
                        <Stack direction={"row"} alignContent={'center'}>
                            <MuseumIcon/>
                            <Typography variant={"h6"} mb={1} ml={1}>{o.name}</Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={2}>
                            {renderObjectTypesStats(o['objectTypes'], classes.icon)}
                        </Stack>
                    </CardContent>
                </Card>
            })}
        </>;
    };

    return <Stack direction={"column"} alignItems={'center'} mt={4}>
        <Typography variant={'h4'}>Statistikk</Typography>
        {renderMainStats()}
        <Box sx={{marginTop: 4}}> </Box>
        {renderMuseumStats()}
    </Stack>
}