import Typography from "@mui/material/Typography";
import {ToolbarContinueOnly} from "./ToolbarContinueOnly";
import React from "react";
import PropTypes from "prop-types";

const OneOrMoreFailedContent = ({classes, t, clickCallback}) => {
    return <>
        <Typography mt={2} mb={1}>
            {t('metadataExtractionComplete', 'Uthenting av metadata fullført.')}
        </Typography>
        <Typography mb={2}>
            {t('metadataExtractionCompleteHelp', 'MERK: Det kan være at ikke alle metadata har blitt hentet ut. ' +
                'Dette kan skyldes formattering, encoding eller ugyldige tegn.')}
        </Typography>
        <ToolbarContinueOnly
            classes={classes}
            t={t}
            clickCallback={clickCallback}
        />
    </>;
};

OneOrMoreFailedContent.propTypes = {
    t: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    clickCallback: PropTypes.func.isRequired
}

export {OneOrMoreFailedContent};