import React from "react";
import { styled } from "@mui/material/styles";
const PREFIX = "Scroller";

const classes = {
  scroller: `${PREFIX}-scroller`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.scroller}`]: {
    scrollbarWidth: "thin",
    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.grey[300]}`,
    overflowY: "auto",
    overflowX: "hidden",
    paddingRight: theme.spacing(3),
    "&::-webkit-scrollbar": {
      width: 8,
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.grey[300],
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.secondary.main,
      borderRadius: 20,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme.palette.secondary.dark,
    },
  },
}));

export const Scroller = ({
  children,
  subtractFromHeight = 0,
  initialHeight = "100%",
}) => {
  return (
    <Root
      className={classes.scroller}
      sx={{
        height: (theme) => {
          const ts = theme.spacing(4);
          return (
            "calc(" +
            initialHeight +
            " - " +
            ts +
            " - " +
            subtractFromHeight +
            "px) !important"
          );
        },
      }}
    >
      {children}
    </Root>
  );
};
