import React from "react";
import {TitleAndCreated} from "./TitleAndCreated";
import {
    useSearchResultDetailsState,
    useSearchResultDetailsTranslation,
} from "../search-result-details/SearchResultDetailsContext";
import {format} from "../app/dateFormat";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import EditIcon from "@mui/icons-material/Edit";
import Divider from "@mui/material/Divider";

/**
 * Component holding the right column header with details.
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectViewRightColumnHeader = () => {
    const t = useSearchResultDetailsTranslation();
    const {model, editMode} = useSearchResultDetailsState();

    const {title, content, createdByName} = model;
    const {reference} = content.mediae[0];
    const {mimeType, createdAt} = reference;

    const filename = reference.title;

    return (
        <>
            <Box sx={{paddingTop: 2, paddingLeft: 2, paddingBottom: 1}}>
                {editMode && (
                    <Grid container alignItems={"center"} spacing={2}>
                        <Grid>
                            <EditIcon/>
                        </Grid>
                        <Grid sx={{maxWidth: '80%'}}>
                            <Typography variant={"h6"} noWrap>
                                {t("objectViewColumnHeaderEdit", "Rediger")}: {title}
                            </Typography>
                            <Typography variant={"caption"} color={"secondary"}>
                                {t("objectViewColumnHeaderCreated", "Opprettet")}{" "}
                                {format(createdAt, "P")} {t("objectViewColumnHeaderBy", "av")}{" "}
                                {createdByName}
                            </Typography>
                        </Grid>
                    </Grid>
                )}
                {!editMode && (
                    <TitleAndCreated
                        title={title}
                        filename={filename}
                        created={createdAt}
                        createdBy={createdByName}
                        mimeType={mimeType}
                    />
                )}
            </Box>
            <Divider/>
        </>
    );
};
