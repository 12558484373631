import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import {getUserEmails} from "../../share-model/ekulturFetch";
import {useAuthsState} from "../../auths/authsContext";
import {useTheme} from "@emotion/react";
import {useGetFolder} from "../../folder/useGetFolder";
import {clientLog} from "../../clientLog";

export const ArcheologyProjectAdminUsers = ({
                                                projectId,
                                                collectionId,
                                                updatedUsersCallback,
                                            }) => {
    const theme = useTheme();

    const {userData} = useAuthsState();
    const {museumCollections} = useAuthsState();

    const [project, setProject] = useState();
    const [projectLoading, setProjectLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [users, setUsers] = useState([]);

    const museumId = museumCollections.find((mc) => {
        return mc.collectionId === collectionId;
    })?.museumId;

    const projectReq = useGetFolder(projectId);

    /**
     * Handler, triggered when user check/unchecks a user,
     * checked users are lated allowed access to upload data
     * to the current project.
     * @param usr Object
     */
    const handleUserToggle = (usr) => {
        const updatedUsers = users.map((u) => {
            if (u.email !== usr.email) {
                return u;
            } else {
                return {
                    ...u,
                    selected: !u.selected,
                };
            }
        });
        setUsers(updatedUsers);
        updatedUsersCallback(updatedUsers);
    };

    // Hook used to load the project.
    useEffect(() => {
        if (!projectLoading) {
            return;
        }
        if (!projectReq.pending && projectReq.success && projectReq.data) {
            setProject(projectReq.data);
            setProjectLoading(false);
        } else if (projectReq.error || !projectReq.data) {
            clientLog('error', JSON.stringify(projectReq.error), 'ArcheologyProjectAdminUsers');
            setProject(undefined);
            setProjectLoading(false);
        }
    }, [JSON.stringify(projectReq)]);

    // Hook used get the list of users for the current museum.
    useEffect(() => {
        if (!museumId || !project) {
            return;
        }

        getUserEmails(museumId).then(museumUsers => {
            let usrs;
            const existingAcls = project['content'].acls;
            if (!existingAcls || existingAcls.length === 0) {
                usrs = museumUsers.map((m) => ({...m, checked: false}));
            } else {
                usrs = museumUsers.map((m) => ({
                    ...m,
                    selected: existingAcls.find((e) => e.email === m.email) !== undefined,
                }));
            }
            usrs.sort((u1, u2) => {
                return u1.name.localeCompare(u2.name);
            });
            setUsers(usrs);
            setLoading(false);
        });
    }, [project, userData]);

    return (
        <Box>
            {loading && <LinearProgress/>}

            <Box
                sx={{
                    height: "100%",
                    maxHeight: "100%",
                    overflowY: "auto",
                    scrollbarWidth: "thin",
                    scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.grey[300]}`,
                    overflowX: "hidden",
                    paddingRight: 3,
                    "&::-webkit-scrollbar": {
                        width: 8,
                    },
                    "&::-webkit-scrollbar-track": {
                        background: theme.palette.grey[300],
                    },
                    "&::-webkit-scrollbar-thumb": {
                        background: theme.palette.secondary.main,
                        borderRadius: 20,
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                        background: theme.palette.secondary.dark,
                    },
                }}
            >
                <List>
                    {users.map((u) => {
                        const labelId =
                            "checkbox-list-label-" + crypto.randomUUID();
                        return (
                            <ListItem
                                key={crypto.randomUUID()}
                                disablePadding={true}
                            >
                                <ListItemButton
                                    role={undefined}
                                    dense
                                    onClick={() => handleUserToggle(u)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            checked={u.selected}
                                            disableRipple
                                            tabIndex={-1}
                                            inputProps={{"aria-labelledby": labelId}}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={u.name}
                                        secondary={u.email}
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Box>
        </Box>
    );
};
