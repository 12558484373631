import {useEffect, useState} from "react";
import {damsFetch} from "../app/damsFetch";
import {clientLog} from "../clientLog";

export const useGetFolder = folderId => {
    const initialState = {
        error: false,
        pending: false,
        success: false,
        data: {}
    }
    const [response, setResponse] = useState(initialState)

    useEffect(() => {
        if (folderId) {
            setResponse(r => ({...r, pending: true}));

            damsFetch(`/folders/${folderId}`)
                .then(json => setResponse({
                    error: false,
                    pending: false,
                    success: true,
                    data: json
                }))
                .catch(error => {
                    clientLog('error', error, 'useGetFolder');
                    setResponse({
                        error: true,
                        pending: false,
                        success: false,
                        data: {}
                    })
                })
        }
    }, [folderId, setResponse])

    return response
}