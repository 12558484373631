import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import React from "react";
import {useCopyrightDispatch} from "../copyrightContext";

/**
 * Button used when adding a license or a copyright clause.
 * @param label
 * @param dispatchValue
 * @returns {JSX.Element}
 * @constructor
 */
export const BtnAdd = ({label, dispatchValue}) => {
    const copyrightDispatch = useCopyrightDispatch();
    return <Button
        sx={{
            marginBottom: 2
        }}
        startIcon={<AddIcon size={"small"}/>}
        variant={'text'}
        color={"secondary"}
        onClick={() => {
            copyrightDispatch({
                type: dispatchValue
            });
        }
        }
    >
        {label}
    </Button>;
};