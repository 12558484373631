import {Box, Button, Paper, Typography} from "@mui/material";
import {AutoCompleteArcheologyProjectSearch} from "./AutoCompleteArcheologyProjectSearch";
import {If} from "../../../app/If";
import {damsFetch} from "../../../app/damsFetch";
import {ArcheologyProjectExists} from "./ArcheologyProjectExists";
import {
    ARCHEOLOGY_PROJECT_READ,
    CANCEL_CREATE_PROJECT,
    PROJECT_SET_COLLECTION_ID,
    useProjectDispatch,
    useProjectState,
    useProjectTranslation,
} from "../../projectContext";
import {ArcheologyProjectChosenProject} from "./ArcheologyProjectChosenProject";
import {SelectMuseumCollection} from "../../SelectMuseumCollection";
import React from "react";
import {getBrowserLocale} from "../../../utility";
import {
    getDescription,
    getLatLng,
    getMonumentPeriod,
    getMonumentType,
    getProjectDataset,
    getSystemIdAndName,
    parseInvestigationId,
    parseInvestigationType,
    parseSuperConceptBegin,
    parseSuperConceptEnd,
    parseSuperConceptStatus
} from "./projectDataParser";

/**
 * Component used to search for an existing archeology project on data.arkeologi.no.
 * @returns {JSX.Element}
 * @constructor
 */
export const ArcheologyRemoteSearchProject = () => {
    const t = useProjectTranslation();
    const projectDispatch = useProjectDispatch();
    const language = getBrowserLocale(true);

    const {projectData, damsProject, collectionId} = useProjectState();

    /**
     * Checks if a project already exists in DAMS or not.
     * @param fetched
     */
    const projectExists = (fetched) => {
        damsFetch("/projects/archeology/exists/", {
            method: "POST",
            body: JSON.stringify({unique_id: fetched.externalProjectUUID}),
        }).then((res) => {
            projectDispatch({
                type: ARCHEOLOGY_PROJECT_READ,
                project: res,
                projectData: {
                    ...projectData,
                    archeologyRemote: fetched,
                },
            });
        });
    };

    /**
     * Callback that handles the results from a search for a project
     * on arkeologi.org.
     * @param data JSON
     */
    const searchCallback = (data) => {
        const fetched = data.entities.map((m) => {
            const {properties, uuid, caption, updatedAt} = m;
            const {
                investigationId,
                investigationType,
                superconceptBegin,
                superconceptEnd,
                superconceptStatus
            } = properties;

            let obj = {
                externalProjectUUID: uuid,
                name: caption[language] || caption['*'],
                projectId: parseInvestigationId(investigationId),
                projectType: parseInvestigationType(investigationType, language),
                projectStart: parseSuperConceptBegin(superconceptBegin),
                projectEnd: parseSuperConceptEnd(superconceptEnd),
                projectStatus: parseSuperConceptStatus(superconceptStatus, language),
                updatedAt: updatedAt,
                projectDescription: getDescription(properties, language),
                projectMonumentType: getMonumentType(properties, language),
                projectMonumentPeriod: getMonumentPeriod(properties, language),
                projectDataset: getProjectDataset(m),
                projectLatLng: getLatLng(m),
            };

            const sysIdAndName = getSystemIdAndName(properties);
            if (sysIdAndName) {
                obj["projectSystemId"] = sysIdAndName.id;
                obj["projectSystemName"] = sysIdAndName.name;
            }

            return obj;
        })[0];
        projectExists(fetched);
    };

    /**
     * Callback used when the user has selected the museum the project will be associated with.
     * @param mId int  Museum ID
     * @param cId int  Collection ID
     */
    const museumCollectionCallback = (mId, cId) => {
        // CollectionID is used when saving the project, in order to associate it with the correct museum.
        projectDispatch({
            type: PROJECT_SET_COLLECTION_ID,
            collectionId: cId,
            museumId: mId,
        });
    };

    return (
        <Paper
            sx={{
                width: "100%",
                minWidth: '100%',
                padding: '32px',
                display: "flex",
                flexDirection: "column",
            }}
        >
            <If boolean={!projectData.archeologyRemote}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "left",
                    }}
                >
                    <Typography variant={"h6"} sx={{marginBottom: '16px'}}>
                        {t('headingArcheologyProject', 'Arkeologiprosjekt')}
                    </Typography>
                    <Typography
                        variant={"h5"}
                        sx={{display: "block", marginBottom: '16px'}}
                    >
                        {t('chooseMuseumAndProject', 'Velg museum og prosjekt')}
                    </Typography>

                    {/* Select museum */}
                    <Box>
                        <SelectMuseumCollection callback={museumCollectionCallback}/>
                    </Box>
                    <AutoCompleteArcheologyProjectSearch
                        formikKey={"projectUUID"}
                        language={language}
                        metadataCallback={searchCallback}
                        disabled={!collectionId}
                    />
                </Box>
            </If>
            <If
                boolean={
                    Object.keys(damsProject).length === 0 && projectData.archeologyRemote
                }
            >
                <ArcheologyProjectChosenProject/>
            </If>

            <If
                boolean={
                    Object.keys(damsProject).includes("id") &&
                    projectData.archeologyRemote
                }
            >
                <ArcheologyProjectExists/>
            </If>

            <If boolean={!projectData || !projectData.archeologyRemote}>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "right",
                    }}
                >
                    <Button
                        color={"secondary"}
                        onClick={() => {
                            projectDispatch({
                                type: CANCEL_CREATE_PROJECT,
                            });
                        }}
                    >
                        {t('btnCancel', 'Avbryt')}
                    </Button>
                </Box>
            </If>
        </Paper>
    );
};
