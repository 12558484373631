import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import React, {useEffect, useState} from "react";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";
import {useObjectConversionState} from "./ObjectConversionContext";

export const ObjectMediaPlayIcon = ({onClick, smallScreen}) => {

    const [style, setStyle] = useState({
        zIndex: 10,
        color: "#eee",
        position: 'absolute',
        cursor: "pointer",
        width: "6vw",
        height: "6vw",
    });

    useEffect(() => {
        if (smallScreen) {
            setStyle(state => (
                    {
                        ...state,
                        width: "30%",
                        height: "30%",
                    }
                )
            );
        }
    }, [smallScreen]);


    const {model, mediaPlaying} = useSearchResultDetailsState();
    const {converting, error, stateUnknown} = useObjectConversionState();
    return (
        <>
            {!mediaPlaying &&
                !error &&
                !converting &&
                !stateUnknown &&
                ["Video"].includes(model.documentType) && (
                    <PlayCircleOutlineIcon
                        sx={style}
                        onClick={onClick}
                    />
                )}
        </>
    );
};
