import {useState} from "react";
import {useAuthsState} from "../auths/authsContext";
import {kitFetch} from "@ekultur/fetch";
import useDeepCompareEffect from "use-deep-compare-effect";
import {clientLog} from "../clientLog";

export const useMyMuseums = ({onlyAdministrativeMuseums = false}) => {
    const [museums, setMuseums] = useState([]);

    const {userData} = useAuthsState();

    const findDamsApp = (application) =>
        application.id === window._env_.REACT_APP_ID &&
        (!onlyAdministrativeMuseums || application.role.name === "Admin");

    useDeepCompareEffect(() => {
        if (userData['appAccess']) {
            const myMuseumIds = userData['appAccess'].museums
                .filter((museum) => museum.applications.find(findDamsApp))
                .map((museum) => museum.id);

            kitFetch(`/museum-api/museums?fields[]=id,name,ekultur_id`)
                .then((json) => {
                    setMuseums(
                        json.museums.filter((museum) => myMuseumIds.includes(museum.id))
                    );
                })
                .catch((error) => {
                    clientLog('error', error, 'useMyMuseums');
                });
        }
    }, [JSON.stringify(userData), setMuseums]);

    return museums;
};
