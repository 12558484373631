import React from "react";
import { styled } from "@mui/material/styles";
import { UploadFiles } from "./UploadFiles";
import {
  HIDE_ADD_MORE_FILES,
  useDocumentDispatch,
  useDocumentState,
} from "../documents/documentContext";
import Grow from "@mui/material/Grow";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";

const PREFIX = "UploadMoreFiles";

const classes = {
  modal: `${PREFIX}-modal`,
  paper: `${PREFIX}-paper`,
};

const StyledModal = styled(Modal)(({ theme }) => ({
  [`&.${classes.modal}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  [`& .${classes.paper}`]: {
    minWidth: "60%",
    outline: "none",
    padding: theme.spacing(4),
  },
}));

export const UploadMoreFiles = ({ open }) => {
  const { showAddMoreFiles } = useDocumentState();
  const imageDispatch = useDocumentDispatch();

  const hideAddMoreFiles = () => {
    imageDispatch({
      type: HIDE_ADD_MORE_FILES,
    });
  };

  return (
    <StyledModal
      className={classes.modal}
      open={showAddMoreFiles}
      onClose={hideAddMoreFiles}
      closeAfterTransition
    >
      <Grow in={open}>
        <Paper className={classes.paper}>
          <UploadFiles />
        </Paper>
      </Grow>
    </StyledModal>
  );
};
