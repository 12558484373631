import React from "react";
import {useField} from "formik";
import TextField from "@mui/material/TextField";

export const FormikWGS84Field = ({formikKey, ...props}) => {
    const [field, meta] = useField(formikKey);

    if (undefined === meta.initialValue) {
        return null;
    } else {
        return (
            <TextField
                id={field.name}
                name={field.name}
                variant={"outlined"}
                helperText={meta.touched ? meta.error : "WGS84 - lat,lng"}
                error={meta.touched && Boolean(meta.error)}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                {...props}
            />
        );
    }
};
