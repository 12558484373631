import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";

/**
 * Component displaying the copyright responsible person.
 * @param t
 * @param models
 * @returns {JSX.Element|string}
 * @constructor
 */
export const CopyrightTypeResponsiblePersons = ({t, models}) => {

    if (!models || models.length === 0) {
        return '';
    }

    return <>
        <Typography
            data-testid={"DAMSCopyrightTypeResponsiblePersons"}
            variant={"caption"}>{t('lblOriginatorResponsiblePerson', 'Forvalter')}:</Typography>
        {models.map(r => (
            <Stack direction={"row"} key={r.reference.title}>
                <Chip label={r.reference.title}/>
            </Stack>
        ))
        }
    </>;
}