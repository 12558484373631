import React from "react";
import {useDocumentState} from "../documents/documentContext";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";

export const UploadedFilesTable = ({checked, checkFile, onDrag = null}) => {
    const t = useNewFilesTranslation();
    const {files} = useDocumentState();

    const dragEnabled = (fileTitle) => null !== onDrag && checked[fileTitle];

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography color={"secondary"}>{t('cellHeadingFilename', 'Filnavn')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color={"secondary"}>{t('cellHeadingType', 'Type')}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography color={"secondary"}>{t('cellHeadingSize', 'Størrelse')}</Typography>
                    </TableCell>
                    <TableCell/>
                </TableRow>
            </TableHead>
            <TableBody>
                {files.map((file) => (
                    <TableRow
                        key={file.title}
                        hover={true}
                        draggable={dragEnabled(file.title)}
                        onDragStart={
                            dragEnabled(file.title) ? onDrag(file.title) : () => null
                        }
                        selected={checked[file.title]}
                    >
                        <TableCell>{file.title}</TableCell>
                        <TableCell>{file.mimeType}</TableCell>
                        <TableCell>{file.filesize}</TableCell>
                        <TableCell>
                            <IconButton
                                onClick={checkFile(file.title)}
                                color={"secondary"}
                                size="large"
                            >
                                {checked[file.title] ? (
                                    <CheckBoxIcon/>
                                ) : (
                                    <CheckBoxOutlineBlankIcon/>
                                )}
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};
