import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {useSearchResultDetailsTranslation} from "../search-result-details/SearchResultDetailsContext";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Slider from "@mui/material/Slider";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";

const PREFIX = "ZoomToolbar";

const classes = {
    slider: `${PREFIX}-slider`,
    zoomIn: `${PREFIX}-zoomIn`,
    zoomOut: `${PREFIX}-zoomOut`,
};

const StyledGrid = styled(Grid)(() => ({
    [`& .${classes.slider}`]: {
        width: "100%",
        paddingTop: "20px",
    },

    [`& .${classes.zoomIn}`]: {
        float: "left",
    },

    [`& .${classes.zoomOut}`]: {
        float: "right",
    },
}));

export const ZoomToolbar = ({callback}) => {
    const maxZoom = 8.0;
    const minZoom = 1.0;
    const zoomIncrement = 0.5;

    const [zoomLevel, setZoomLevel] = useState(5.0);
    const t = useSearchResultDetailsTranslation();

    const onZoomChange = (_event, newValue) => {
        if (newValue >= minZoom && newValue <= maxZoom) {
            setZoomLevel(newValue);
            callback(newValue);
        }
    };

    const onZoomOut = () => {
        let v = zoomLevel - zoomIncrement;
        if (v < minZoom) {
            v = minZoom;
        }
        setZoomLevel(v);
        callback(v);
    };

    const onZoomIn = () => {
        let v = zoomLevel + zoomIncrement;
        if (v > maxZoom) {
            v = maxZoom;
        }
        setZoomLevel(v);
        callback(v);
    };

    return (
        <StyledGrid container spacing={2} alignContent={"center"}>
            <Grid size={{
                md: 5,
                xs: 4
            }}>
                <IconButton
                    disabled={zoomLevel === minZoom}
                    className={classes.zoomOut}
                    color="secondary"
                    aria-label={t("btnZoomOut", "Zoom ut")}
                    component="span"
                    onClick={() => onZoomOut()}
                    size="large"
                >
                    <ZoomOutIcon/>
                </IconButton>
            </Grid>

            <Grid size={{
                md: 2,
                xs: 4
            }}>
                <Slider
                    className={classes.slider}
                    valueLabelDisplay="off"
                    value={zoomLevel}
                    step={zoomIncrement}
                    min={minZoom}
                    max={maxZoom}
                    onChange={onZoomChange}
                />
            </Grid>

            <Grid size={{
                md: 5,
                xs: 4
            }}>
                <IconButton
                    disabled={zoomLevel === maxZoom}
                    className={classes.zoomIn}
                    color="secondary"
                    aria-label={t("btnZoomIn", "Zoom inn")}
                    component="span"
                    onClick={() => onZoomIn()}
                    size="large"
                >
                    <ZoomInIcon/>
                </IconButton>
            </Grid>
        </StyledGrid>
    );
};
