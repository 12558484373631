import React, {useEffect, useState} from "react";
import {InputPersons} from "../person/InputPersons";
import {useFormikContext} from "formik";

/**
 * Input field used in combination with a license, in order to set the associated person/company.
 * @param t     method  Translation method
 * @param ix    int The field's index number
 * @param size  str Input field size large | medium | small
 * @returns {JSX.Element}
 * @constructor
 */
export const FormikSelectAssociated = ({t, ix, size}) => {
    const {values} = useFormikContext();

    const [fieldRef, setFieldRef] = useState();

    const k = 'licenses';

    // NOTE: When handling responsible for a license, the responsible field is an array under the "license" key,
    // in the Formik context's values object. The responsible array will have to be manipulated in parallel with
    // the "license" array in the Formik context, thus referenced via "currentFieldRef".

    useEffect(() => {
        if (values.licenses[ix]) {
            if (!values.licenses[ix].responsible) {
                values.licenses[ix].responsible = [];
            }
            setFieldRef(values.licenses[ix]);
        }
    }, [ix]);

    return fieldRef && <InputPersons disabled={false}
                                     label={t('lblSelectAssociated', 'Tilknyttede personer/firma')}
                                     formikKey={k}
                                     createLabel={t('lblAddPersonCompany', 'Legg til person/firma')}
                                     alwaysActive={true}
                                     fullWidth={true}
                                     size={size}
                                     currentFieldRef={fieldRef}/>;
};