import React from "react";
import { styled } from "@mui/material/styles";
import { useFolderTranslation } from "./MyFoldersContext";
import { useFormikContext } from "formik";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import FolderIcon from "@mui/icons-material/Folder";
import LoadingButton from "@mui/lab/LoadingButton";

const PREFIX = "ToolbarCreateFolder";

const classes = {
  saveButton: `${PREFIX}-saveButton`,
};

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  [`& .${classes.saveButton}`]: {
    marginLeft: theme.spacing(2),
  },
}));

export const ToolbarCreateFolder = ({ onHide, disabled = false }) => {
  const t = useFolderTranslation();

  const { submitForm, isSubmitting, isValid, dirty } = useFormikContext();

  return (
    <StyledToolbar>
      <Box flexGrow={1} />
      <Button onClick={onHide}>{t("cancel", "Avbryt")}</Button>

      <LoadingButton
        sx={{ marginBottom: 2, marginTop: 2, marginLeft: 1 }}
        variant={"contained"}
        color={"primary"}
        onClick={submitForm}
        loadingPosition="start"
        startIcon={<FolderIcon />}
        loading={isSubmitting}
        disabled={isSubmitting || !isValid || disabled || !dirty}
      >
        {t("create", "Opprett")}
      </LoadingButton>
    </StyledToolbar>
  );
};
