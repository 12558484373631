import {MetadataRow} from "./MetadataRow";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import {format} from "../app/dateFormat";

/**
 * Component rendering a copyright clause item.
 * @param t
 * @param models
 * @returns {JSX.Element|string}
 * @constructor
 */
export const CopyrightClausesItem = ({t, models}) => {

    if (!models || models.length === 0) {
        return '';
    }

    return <>
        {models.map(c => {
            const {sourceId, title, responsible, fromDate, toDate} = c;
            return <MetadataRow label={t('lblCopyrightClause', 'Klausul')} key={sourceId}>
                <Stack>
                    <Typography variant={"caption"}
                                sx={{marginBottom: 0.5}}>
                        {title}
                    </Typography>
                    {responsible?.map(r => {
                        return <Stack key={r.reference.title} direction={"row"}>
                            <Chip label={r.reference.title} size={"small"}/>
                        </Stack>
                    })}
                    <Typography
                        variant={"caption"}>
                        {t('copyrightClauseValid', 'Gyldighet')} {format(fromDate, "P")} - {format(toDate, "P")}
                    </Typography>
                </Stack>
            </MetadataRow>
        })}
    </>;
};