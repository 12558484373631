import {useEffect, useState} from "react";
import {damsFetch} from "../app/damsFetch";
import {clientLog} from "../clientLog";

export const useDocumentCount = () => {
    const [count, setCount] = useState(-1);

    useEffect(() => {
        damsFetch(`/statistics/count/documents/`)
            .then(json => {
                setCount(json.count);
            })
            .catch(error => {
                clientLog('error', error, 'useDocumentCount');
            })
    }, [setCount])

    return count
}