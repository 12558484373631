/**
 * Checks if a file has a title by comparing its sourceId with the sourceId of a saved media.
 *
 * @param {Object} f - The file object to check.
 * @param {Array} saved - An array of saved media objects.
 * @return {boolean} Returns true if the file has a title, false otherwise.
 */
const hasTitle = (f, saved) => undefined !== saved.find((s) => s.content.mediae[0].reference.sourceId === f.dmsId);

/**
 * Calculates the number of files without a title that have been saved.
 *
 * @param {Array} files - An array of file objects.
 * @param {Array} saved - An array of saved file objects.
 * @return {number} The number of files without a title.
 */
export const getNumMissingTitle = (files, saved) => {
    let numMissingTitle = files.length;
    if (saved && saved.length > 0) {
        const hasTitles = files.filter((f) => hasTitle(f, saved)).length;
        numMissingTitle = files.length - hasTitles;
    }
    return numMissingTitle;
};