import React from "react";
import {Formik} from "formik";
import {FormikTextField} from "../form/FormikTextField";
import {FormActions} from "../metadata/FormActions";
import {useDocumentTranslation} from "../documents/documentContext";
import * as Yup from "yup";
import {useAddPerson} from "./useAddPerson";
import Box from "@mui/material/Box";

const schema = (t) => {
    const mustBeYearOrFulldateFeedback = t(
        "mustBeYearOrFulldateFeedback",
        "Må være på formatet YYYY eller DD.MM.YYYY"
    );
    const datemask = /^\d{4}$|\d{2}.\d{2}.\d{4}/;
    return Yup.object().shape({
        name: Yup.string().required(t("requiredFeedback", "Påkrevd")),
        yearOfBirth: Yup.string().matches(datemask, {
            message: mustBeYearOrFulldateFeedback,
        }),
        yearOfDeath: Yup.string().matches(datemask, {
            message: mustBeYearOrFulldateFeedback,
        }),
    });
};

export const FormNewPerson = ({
                                  person,
                                  onClose,
                                  collectionIds,
                                  addHandler = null,
                              }) => {
    const t = useDocumentTranslation();
    const postPersons = useAddPerson();

    const onSubmit = (values) => {
        const newPersons = collectionIds.map((collectionId) => ({
            ...values,
            collectionId: collectionId,
        }));
        postPersons({persons: newPersons}).then((persons) => {
            if (typeof addHandler !== 'undefined') {
                addHandler(persons);
            }
            onClose();
        });
    };

    return (
        <Formik
            initialValues={{
                name: person.title,
                yearOfBirth: "",
                yearOfDeath: "",
            }}
            onSubmit={(values) => onSubmit(values)}
            validationSchema={schema(t)}
        >
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    maxWidth: "640px",
                }}
            >
                <FormikTextField
                    formikKey={"name"}
                    label={t("personName", "Navn")}
                    required={true}
                    tiptext={t("personNameTipText", "Etternavn, Fornavn")}
                />
                <FormikTextField
                    formikKey={"yearOfBirth"}
                    label={t("personYearOfBirth", "Født")}
                    tiptext={t("personBirthTipText", "YYYY eller DD.MM.YYYY")}
                />
                <FormikTextField
                    formikKey={"yearOfDeath"}
                    label={t("personYearOfDeath", "Død")}
                    tiptext={t("personDeathTipText", "YYYY eller DD.MM.YYYY")}
                />
                <FormActions onCancel={onClose}/>
            </Box>
        </Formik>
    );
};
