import {Dialog, DialogContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useEffect, useState} from "react";
import {damsFetch} from "./damsFetch";
import {useAuthsState} from "../auths/authsContext";
import FolderIcon from "@mui/icons-material/Folder";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import {useSearchTranslation} from "../search/SearchContext";
import {clientLog} from "../clientLog";

export const InfoDialog = ({show}) => {
    const navigate = useNavigate();

    const t = useSearchTranslation();
    const {userData} = useAuthsState();

    const [showDialog, setShowDialog] = useState(show);
    const [loading, setLoading] = useState(true);
    const [folders, setFolders] = useState();

    const getFolderList = () => {
        // noinspection JSUnresolvedFunction
        if (!folders) {
            return <></>;
        }

        return (
            <>
                <Typography mt={1}>Du har tilgang til følgende mapper:</Typography>
                <Stack
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    {folders?.map((f) => {
                        return (
                            <IconButton key={f.id} onClick={() => handleFolderClick(f.id)}>
                                <FolderIcon sx={{fontSize: 150, color: "#aaa"}}/>
                                <Typography
                                    color={"primary"}
                                    sx={{position: "absolute", maxWidth: 100}}
                                    noWrap
                                >
                                    {f.title}
                                </Typography>
                            </IconButton>
                        );
                    })}
                </Stack>
                <Typography component={"p"} color={"#aaa"}>
                    {t(
                        "infoDialogClickFolder",
                        "Klikk på mappen du ønsker å se innholdet i."
                    )}
                </Typography>
            </>
        );
    };

    const handleClose = (_event, reason) => {
        if (reason !== "backdropClick") {
            setShowDialog(false);
        }
    };

    const handleFolderClick = (id) => {
        navigate("/folders/" + id + "/");
        handleClose();
    };

    useEffect(() => {
        damsFetch("/folders/shared-user/")
            .then((json) => {
                setFolders(json);
                setLoading(false);
            })
            .catch((err) => {
                clientLog('error', err, 'InfoDialog');
                setLoading(false);
            });
    }, []);

    return (
        <>
            {!loading && (
                <Dialog
                    open={showDialog}
                    onClose={handleClose}
                    disableEscapeKeyDown={true}
                    fullWidth={true}
                    maxWidth={"sm"}
                >
                    <DialogContent>
                        <Typography variant={"h6"} gutterBottom={true}>
                            {userData.name} -{" "}
                            {t("infoDialogWelcome", " Velkommen til eKultur DAMS")}
                        </Typography>

                        {/* Show available folders */}
                        {!loading && folders && folders.length > 0 && getFolderList()}
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};
