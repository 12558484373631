import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {AccordionFilter} from "../AccordionFilter";
import Slider from "@mui/material/Slider";
import Tooltip from "@mui/material/Tooltip";


const ValueLabelComponent = (props) => {
    const {children, open, value} = props;
    const tooltipValue = new Date(parseFloat(value) * 1000)
        .toISOString()
        .substring(11, 19);

    return (
        <Tooltip
            open={open}
            enterTouchDelay={0}
            placement="bottom"
            title={tooltipValue}
        >
            {children}
        </Tooltip>
    );
};

export const FilterDuration = ({results, callback}) => {
    const {fq, clearFilters} = useSearchState();
    const t = useSearchTranslation();
    const key = "mediae.reference.duration_seconds";
    const [minMax, setMinMax] = useState([]);
    const [value, setValue] = useState([0, 0]);

    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState([]); // NOSONAR

    /**
     * Method used to convert an argument of type "[0 to 255]" to an array of integers.
     * @param val
     * @returns {*}
     */
    const valueStringToArray = (val) => {
        val = val.replace('[', '');
        val = val.replace(']', '')
        val = val.replaceAll(' ', '');
        return val.split('TO').map(v => (parseInt(v)));
    }

    const modifySelectedFilter = useCallback(
        (val, shouldCallback) => {
            let selectedFilters;
            if (Array.isArray(val)) {
                val = {from: val[0], to: val[1]};
            }
            selectedFilters = [{key: key, value: val}];
            setSelected(selectedFilters);
            if (shouldCallback) {
                callback(key, selectedFilters);
            } else {
                let values = valueStringToArray(val);
                setValue([values[0], values[1]]);
            }
        },
        [callback]
    );

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(key, []);
        }
    }, [clearFilters]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === key).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), false);
            });
        }
    }, [fq]);

    /**
     * Hook used to get the absolute min. and max. value from the results.
     */
    useEffect(() => {
        function getAbsMinMax() {
            const durationSecondsArr = Object.keys(
                results.facets.durationSeconds
            ).map((d) => parseInt(d, 10));
            if (durationSecondsArr.length === 0) {
                return;
            }
            const min = Math.min.apply(Math, durationSecondsArr);
            const max = Math.max.apply(Math, durationSecondsArr);
            setMinMax([min, max]);
            setValue([min, max]);
        }

        if (results.facets.durationSeconds && Object.keys(results.facets.durationSeconds).length > 1) {
            getAbsMinMax();
        }
    }, [results.facets.durationSeconds]);

    const handleSetValue = (_event, v) => {
        modifySelectedFilter(v, true);
    };

    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    if (minMax[0] === minMax[1]) {
        return <></>;
    }

    return (
        <>
            <AccordionFilter
                title={`${t("duration", "Avspillingslengde (audio/video)")}`}
            >
                <Slider
                    slots={{
                        ValueLabel: ValueLabelComponent,
                    }}
                    value={value}
                    onChange={handleChange}
                    onChangeCommitted={handleSetValue}
                    aria-labelledby="discrete-slider-small-steps"
                    defaultValue={minMax}
                    valueLabelDisplay={"auto"}
                    min={minMax[0]}
                    max={minMax[1]}
                />
            </AccordionFilter>
        </>
    );
};
