import React from "react";
import {styled} from "@mui/material/styles";
import {format} from "../../app/dateFormat";
import {
    SHOW,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
} from "../../search-result-details/SearchResultDetailsContext";
import {SELECT, UNSELECT, useSearchDispatch, useSearchState} from "../SearchContext";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import EventIcon from "@mui/icons-material/Event";
import PersonIcon from "@mui/icons-material/Person";
import {EllipsisCardHeader} from "../EllipsisCardHeader";
import {ResultCardThumb} from "./ResultCardThumb";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import HandymanOutlinedIcon from "@mui/icons-material/HandymanOutlined";
import PropTypes from "prop-types";

const PREFIX = "ResultCard";

const classes = {
    root: `${PREFIX}-root`,
    detailIcon: `${PREFIX}-detailIcon`,
    imageContainer: `${PREFIX}-imageContainer`,
    cardHeader: `${PREFIX}-cardHeader`,
};

const StyledCard = styled(Card)(({theme}) => ({

    [`&.${classes.root}`]: {
        border: 'solid 1px #ddd'
    },

    [`& .${classes.detailIcon}`]: {
        fontSize: 16,
        marginRight: 4,
        verticalAlign: "middle",
    },
    [`& .${classes.imageContainer}`]: {
        width: "100%",
        height: 140,
        textAlign: "center",
    },
    [`& .${classes.cardHeader}`]: {
        padding: '.5rem .75rem',
        '& .MuiCardHeader-content': {
            overflow: 'hidden',
        },
    },
    '&.MuiPaper-elevation8': {
        boxShadow: theme.shadows[3], //Using a softer shadow from the theme
    },
}));

const ResultCard = ({model, selected = false}) => {

    const {showInstantly} = useSearchState();
    const {selectedId} = useSearchResultDetailsState();
    const dispatch = useSearchResultDetailsDispatch();
    const searchDispatch = useSearchDispatch();
    const navigate = useNavigate();

    const {folderType: projectType, uniqueId, id, title, documentType, createdAt, createdByName, status} = model;

    const clickHandler = () => {
        if (documentType === "Folder") {
            navigate("/project/" + id);
        } else {
            dispatch({
                type: SHOW,
                model: model,
            });
        }
    };

    const checkHandler = () => {
        searchDispatch({
            type: selected ? UNSELECT : SELECT,
            model: model,
        });
    };

    return (
        <StyledCard
            className={classes.root}
            raised={selectedId === uniqueId || selectedId === showInstantly}
        >
            <EllipsisCardHeader
                className={classes.cardHeader}
                title={title}
                titleTypographyProps={{noWrap: true, variant: "subtitle2"}}
                action={
                    status !== "project" && (
                        <Checkbox
                            color={"secondary"}
                            checked={selected}
                            onChange={checkHandler}
                        />
                    )
                }
            />

            <CardActionArea onClick={clickHandler}>
                <ResultCardThumb
                    model={model}
                    documentType={documentType}
                    showInstantly={showInstantly}
                    clickHandler={clickHandler}
                />
                {
                    projectType === 'ProjectArcheology' &&
                    <Box sx={{position: 'relative', width: 0, height: 0}}>
                        <HandymanOutlinedIcon sx={{
                            position: 'absolute',
                            top: '-30px',
                            left: '5px',
                            color: '#bbb',
                            width: '25px',
                            height: '25px'
                        }}/>
                    </Box>
                }
                <CardContent>
                    <Box>
                        <div style={{whiteSpace: "nowrap"}}>
                            <EventIcon color={"secondary"} className={classes.detailIcon}/>
                            <Typography variant={"caption"} color={"secondary"}>
                                {format(createdAt, "P")}
                            </Typography>
                        </div>
                        <div
                            style={{
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "95%",
                                maxWidth: "95%",
                            }}
                        >
                            <PersonIcon color={"secondary"} className={classes.detailIcon}/>
                            <Typography variant={"caption"} color={"secondary"} noWrap={true}>
                                {createdByName}
                            </Typography>
                        </div>
                    </Box>
                </CardContent>
            </CardActionArea>
        </StyledCard>
    );
};

ResultCard.propTypes = {
    "model": PropTypes.object.isRequired,
    "selected": PropTypes.bool
};

export {ResultCard};