import {FileIcon} from "react-file-icon";
import {getFileExtensionFromMimeType, getIconFromFilename, getLabelColor} from "../damsFileObjectDefinitions";
import {clientLog} from "../clientLog";

export const DamsFileIcon = ({filename, mimeType, size, show = true}) => {
    const radius = 3;

    const getFileIconType = () => {
        if (!filename) {
            return;
        }
        const icon = getIconFromFilename(filename);
        if (!icon) {
            const msg = `icon type not found for: ${mimeType} ${filename}`;
            clientLog('warn', msg, 'DamsFileIcon');
        }
        return icon;
    };

    const fileType = getFileIconType();
    const labelColor = getLabelColor(fileType);

    let fileExtension = getFileExtensionFromMimeType(mimeType);
    fileExtension = fileExtension === "jpeg" ? "jpg" : fileExtension;

    return (
        <div
            data-testid={"DAMSFileIcon"}
            style={{
                WebkitFontSmoothing: "antialiased",
                width: size + "px",
                minWidth: size + "px",
                zIndex: 999,
            }}
        >
            {show && <FileIcon
                size={size}
                type={fileType}
                extension={fileExtension}
                radius={radius}
                key={`${filename}-${size}`}
                labelColor={labelColor}
            />}
        </div>
    );
};
