import {useAuthsState} from "./authsContext";

export const useIsAdminOfMuseum = museumId => {
    const {userData} = useAuthsState();

    if (Array.isArray(museumId)) {
        // HACK: in some situations the value passed along to this hook is an array of museum ids,
        // If this is the case, and the number of items are more than one, return false, as we're unable
        // to determine admin-status, otherwise proceed, and, evaluate the single value provided.
        if (museumId.length > 1) {
            return false;
        } else {
            museumId = museumId[0];
        }
    }

    const getMuseum = _ => {
        return userData['appAccess']
            && userData['appAccess'].museums.find(m => {
                return m.id === museumId;
            });
    }

    const museum = getMuseum();
    if (!museum) {
        return false;
    }

    const admin = museum['applications']
        .find(app => {
            return window._env_.REACT_APP_ID === app.id
                && app['role']['name'] === 'Admin';
        });

    return typeof (admin) !== 'undefined';
};
