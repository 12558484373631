import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";
import {useCanEditInCollections} from "../../auths/useCanEditInCollections";

/**
 * Renders an Edit button for a folder with the ability to handle button clicks.
 *
 * @param {Object} myFolder - The folder object to be edited
 * @param {number} collectionId - The ID of the collection the folder belongs to
 * @param {function} callback - The function to be called on button click
 * @return {JSX.Element} The Edit button component
 */
export const EditFolderButton = ({myFolder, collectionId, callback}) => {
    const canEditCollection = useCanEditInCollections([collectionId]);

    const handleButtonClick = () => {
        callback(true);
    };

    const editFolder = myFolder || canEditCollection;

    return (
        <IconButton
            disabled={!editFolder}
            onClick={handleButtonClick}
            size="large"
        >
            <EditIcon/>
        </IconButton>
    );
};