import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useNewFilesTranslation} from "../files/NewFilesTranslationContext";
import {CHANGE_STEP, HIDE_PRE_CHANGE, useNewFilesDispatch, useNewFilesState,} from "../files/NewFilesContext";
import {useDocumentState} from "../documents/documentContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

const PREFIX = "ModalUnsavedDialog";

const classes = {
    modal: `${PREFIX}-modal`,
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
    progress: `${PREFIX}-progress`,
};

const StyledModal = styled(Modal)(({theme}) => ({
    [`&.${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.content}`]: {
        outline: "none",
    },

    [`& .${classes.paper}`]: {
        height: "100%",
        padding: theme.spacing(4),
    },

    [`& .${classes.progress}`]: {
        width: "100%",
    },
}));

export const ModalUnsavedDialog = () => {
    const t = useNewFilesTranslation();
    const {unsaved} = useDocumentState();
    const {showPreStep, activeStepIndex} = useNewFilesState();
    const newFilesDispatch = useNewFilesDispatch();
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (showPreStep) {
            if (unsaved.length > 0) {
                setOpen(true);
            } else {
                newFilesDispatch({
                    type: CHANGE_STEP,
                    stepIndex: 1 + activeStepIndex,
                });
            }
        }
    }, [showPreStep, activeStepIndex, unsaved.length, newFilesDispatch]);

    const onClose = () => {
        setOpen(false);
        newFilesDispatch({type: HIDE_PRE_CHANGE});
    };

    return (
        <StyledModal
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
        >
            <div className={classes.content}>
                <Grow in={open}>
                    <Paper className={classes.paper}>
                        <Typography component={"h2"} variant={"h6"}>
                            {t("unsavedDialogTitle", "Du har ikke lagret alle dokumentene.")}
                        </Typography>
                        <Toolbar>
                            <Box flexGrow={1}/>
                            <Button onClick={onClose} color={"secondary"}>
                                {t('btnBack', 'Tilbake')}
                            </Button>
                        </Toolbar>
                    </Paper>
                </Grow>
            </div>
        </StyledModal>
    );
};
