import Stack from "@mui/material/Stack";
import FolderIcon from "@mui/icons-material/Folder";
import React from "react";
import Typography from "@mui/material/Typography";

/**
 * A MUI component that displays a folder icon next to the folder name.
 *
 * Props:
 *  - folderName: The name of the folder to display.
 *
 * Returns:
 *  - A MUI Stack component with a direction of "row" and alignItems of "center".
 *    The first child is a MUI FolderIcon with a color of "secondary" and a
 *    marginRight of 8px. The second child is the folder name.
 */
export const ColumnFolderNameWithIcon = ({folderName}) => {
    return <Stack direction={"row"} alignItems={"center"}>
        <FolderIcon color={"secondary"}
                    sx={{marginRight: '8px'}}
        />
        <Typography sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }}>
            {folderName}
        </Typography>
    </Stack>;
};