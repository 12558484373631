import React, {useEffect} from 'react';
import {SearchLandingPage} from "./SearchLandingPage";
import {CLEAR_FILTERS, SEARCH_ONLY_MINE, SET_SEARCHING, useSearchDispatch, useSearchState} from "./SearchContext";


export const UserDocuments = () => {
    const dispatch = useSearchDispatch();
    const {query} = useSearchState();

    useEffect(() => {
        dispatch({type: CLEAR_FILTERS});
        dispatch({type: SEARCH_ONLY_MINE});
        dispatch({type: SET_SEARCHING})
    }, [dispatch])

    if ("" === query) {
        return null;
    } else {
        return (
            <SearchLandingPage/>
        )
    }
}