import React, {useEffect, useState} from "react";
import {useMyMuseums} from "../museum/useMyMuseums";
import {damsFetch} from "../app/damsFetch";
import {FormControl, FormHelperText, MenuItem, Select,} from "@mui/material";
import {useAuthsState} from "../auths/authsContext";
import ReportIcon from "@mui/icons-material/Report";
import {useProjectState, useProjectTranslation} from "./projectContext";
import {useDmsStatusState} from "../dmsstatus/DmsStatusContext";
import {clientLog} from "../clientLog";

/**
 * Renders the list of museums when attempting to create a new archeology project.
 *
 * NOTE:
 * 1. Only museums that has the DAMS archeology module enabled are displayed in the list.
 * 2. Museums where the DMS config is either missing or corrupt is marked with an exclamation mark.
 * @param callback
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectMuseumCollection = ({callback}) => {
    const t = useProjectTranslation();
    const {museumFeatures} = useAuthsState();
    const myMuseums = useMyMuseums({});
    const [museumId, setMuseumId] = useState("");
    const {dmsStatus} = useDmsStatusState();

    const [museumsWithFeatures, setMuseumsWithFeatures] = useState();
    const {hasArcheologyModule} = useProjectState();

    /**
     * Method used to decide whether the specified museum has the archeology module activated.
     * @param mId
     * @returns {boolean}
     */
    const museumHasArcheologyModule = mId => {
        return Boolean(museumsWithFeatures?.find(m => m.id === mId && m.features.archeology === true));
    };

    /**
     * Hook used to determine which of the museums that has the archeology module enabled.
     */
    useEffect(() => {
        if (!myMuseums || !museumFeatures || museumFeatures.length === 0) {
            return;
        }
        const mWithFeatures = myMuseums.map(m => {
            let featureObj;

            if (m.id === 20 && hasArcheologyModule) {
                // If an override of the archeology module has been set,
                // enable this module for at least the "internal museum" KulturIT.
                clientLog('info', 'KulturIT has archeology module enabled - overriding');
                featureObj = {
                    features: {
                        archeology: true
                    }
                };
            } else {
                featureObj = museumFeatures.find(mf => mf.id === m.id);
            }

            return {
                ...m,
                features: featureObj.features
            };
        })
        setMuseumsWithFeatures(mWithFeatures);

        // If the user has access to only one museum, select it automatically!
        if (!museumId && myMuseums.length === 1) {
            fetchCollection(myMuseums[0].id);
        }
    }, [myMuseums, museumFeatures]);

    const fetchCollection = (mId) => {
        if (!museumHasArcheologyModule(mId)) {
            return;
        }
        const searchParams = new URLSearchParams({
            id: mId,
        });
        damsFetch(`/collections/?${searchParams.toString()}`).then((json) => {
            callback(mId, json.length ? json[0].id : 0);
        });
    };

    const renderOptions = () => {
        return museumsWithFeatures?.filter(m => m.features.archeology === true).map((m) => (
            <MenuItem key={m.id} value={m.id}>
                {m.name}
                {dmsStatus?.missingConfigs?.includes(m.ekultur_id) && (
                    <ReportIcon
                        sx={{
                            marginRight: 1,
                            color: "red",
                        }}
                    />
                )}
            </MenuItem>
        ));
    };

    const handleChange = (event) => {
        const id = event.target.value;
        setMuseumId(id);
        fetchCollection(id);
    };

    return (
        <FormControl>
            <Select
                variant={"standard"}
                onChange={handleChange}
                value={museumId}
                color={"secondary"}
            >
                {renderOptions()}
            </Select>
            <FormHelperText>{t('chooseMuseum', 'Velg museum')}</FormHelperText>
        </FormControl>
    );
};
