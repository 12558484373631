import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";

export const SET_DMS_STATUS = 'dmsStatusContext/setDmsStatus';

const DmsStatusContext = createContext(undefined);
const DmsStatusDispatchContext = createContext(undefined);
const DmsStatusTranslationContext = createContext(undefined);

const initialState = {
    dmsStatus: {},
};

const dmsStatusReducer = (state, action) => {
    if (action.type === SET_DMS_STATUS) {
        return {
            ...state,
            dmsStatus: action.dmsStatus,
        };
    }
};

export const DmsStatusProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        dmsStatusReducer,
        {...initialState},
        undefined
    );
    const {t} = useTranslation("dams.dmsStatus", {useSuspense: false});

    return (
        <DmsStatusTranslationContext.Provider value={t}>
            <DmsStatusContext.Provider value={state}>
                <DmsStatusDispatchContext.Provider value={dispatch}>
                    {children}
                </DmsStatusDispatchContext.Provider>
            </DmsStatusContext.Provider>
        </DmsStatusTranslationContext.Provider>
    );
};

export const useDmsStatusState = () => {
    const context = useContext(DmsStatusContext);
    if (undefined === context) {
        throw new Error(
            "useDmsStatusState must be used within a DmsStatusProvider."
        );
    } else {
        return context;
    }
};

export const useDmsStatusDispatch = () => {
    const context = useContext(DmsStatusDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useDmsStatusDispatch must be used within a DmsStatusProvider."
        );
    } else {
        return context;
    }
};

export const useDmsTranslation = () => {
    const context = useContext(DmsStatusTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useDmsStatusTranslation must be used within a DmsStatusProvider."
        );
    } else {
        return context;
    }
};
