import {usePostFolder} from "../../folder/usePostFolder";

export const useSaveGeneralProject = () => {
    const [postFolderResponse, postFolder] = usePostFolder();

    /**
     * Returns the state of the operation.
     * @returns {{success: boolean, pending: boolean, newFolder: {}, error: boolean}}
     */
    const saveState = () => {
        return postFolderResponse;
    };

    /**
     * Maps the specified responsible and contributors to an array of ACL-objects.
     * @param responsible
     * @param contributors
     * @returns {{access: string, email: *}[]}
     */
    const mapAcls = (responsible, contributors) => {
        // Get distinct email addresses
        const emails = [
            ...new Set([
                ...responsible.map(r => r.email),
                ...contributors.map(c => c.email)
            ])
        ];

        // Map to ACL-objects
        return emails.map(email => {
            return {
                "email": email,
                "access": "write"
            };
        });
    };

    /**
     * Saves/updates information related to a "general" project.
     * @param collectionId int
     * @param uniqueId str
     * @param data  JSON  Project data
     */
    const save = (collectionId, uniqueId, data) => {
        const {
            id,
            name,
            description,
            type,
            startedAt,
            endedAt,
            active,
            contributors,
            responsible,
            relatedProjects,
            remarks,
            extReferences
        } = data;

        const folder = {
            uniqueId: uniqueId || undefined,
            collectionId: collectionId,
            schemaId: 1,
            documentType: "Folder",
            locale: "no",
            status: "project",
            title: name,
            description: "Project folder",
            folder_type: "ProjectGeneral",
            content: {
                generalprojectref: {
                    id: id,
                    name: name,
                    description: description,
                    type: type,
                    startedAt: startedAt,
                    endedAt: endedAt,
                    active: active,
                    contributors: contributors,
                    responsible: responsible,
                    relatedProjects: relatedProjects,
                    remarks: remarks,
                    extReferences: extReferences
                },
                acls: mapAcls(responsible, contributors)
            },
            acls: [], // In use by folders only, not projects!
        };
        postFolder(folder);
    };

    return {
        save: save,
        saveState: saveState
    };
};
