import {TextField} from "@mui/material";
import React from "react";
import {SET_QUERY, SET_SEARCHING} from "./FoldersContext";

/**
 * TextField used to search for a folder by name.
 * @param t function    Translation function
 * @param foldersDispatch   function    Folders Dispatch function
 * @returns {JSX.Element}
 * @constructor
 */
export const TextFieldSearchForFolder = ({t, foldersDispatch}) => {

    function debounce(fn, delay) {
        let timer;
        return function (...args) {
            clearTimeout(timer);
            timer = setTimeout(() => fn(...args), delay);
        }
    }

    const handleSearchChange = (event) => {
        const query = `${event.target.value}*`;

        foldersDispatch({
            type: SET_QUERY,
            query: query
        });
        foldersDispatch({
            type: SET_SEARCHING
        });
    };

    return <TextField label={t('searchForFolder', 'Søk etter mappe')}
                      onChange={debounce(handleSearchChange, 300)}
                      variant={"standard"}
                      size={"small"}
                      sx={{marginRight: '1rem'}}
    />;
};