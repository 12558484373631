import Box from "@mui/material/Box";
import {AccordionFilter} from "../../search/AccordionFilter";
import {FilterDate} from "../../search/filters/FilterDate";
import {useFoldersState} from "../FoldersContext";
import {useEffect, useState} from "react";
import {dateToEndOfDay, dateToStartOfDay} from "../../utility";


export const FilterCreatedAt = ({
                                    callback,
                                    columnName,
                                    accordionTitle,
                                }) => {

    const {results} = useFoldersState();

    const [period, setPeriod] = useState({min: '', max: ''});

    const [ready, setReady] = useState(false);

    const changeHandler = ({from = "*", to = "*"}) => {
        callback([{
            key: 'created_at',
            value: {
                from: from,
                to: to
            }
        }]);
    };

    /**
     * Hook used to fetch the min- and max date range based on the results/stats data,
     * for the specified column name.
     */
    useEffect(() => {
        if (!results || !results?.stats) {
            return;
        }

        if (results?.stats) {
            const periodData = results?.stats[columnName];
            if (!periodData) {
                return;
            }
            const min = dateToStartOfDay(new Date(periodData.min));
            const max = dateToEndOfDay(new Date(periodData.max));
            setPeriod({min: min, max: max});
        }

        setReady(true);
    }, [results]);

    return ready && <Box sx={{marginBottom: '.5rem'}}>
        <AccordionFilter title={accordionTitle}>
            <FilterDate
                min={period.min}
                max={period.max}
                onFilter={changeHandler}
            />
        </AccordionFilter>
    </Box>;
};