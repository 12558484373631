import {damsFetch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import camelcaseKeysDeep from "camelcase-keys-deep";

/**
 * Fetches the assets of given type from the local DAMS database.
 * @param {Object} params Object containing the search parameters.
 * @param {Object} museum  The museum to fetch data for.
 * @param {String} assetType The type of asset to fetch, must be one of 'person', 'subject', 'place'.
 * @returns {Promise<Array>} A list of assets with the given search parameters.
 */
export const fetchLocalAsset = async (params, museum, assetType) => {
    const assetTypes = ['person', 'subject', 'place'];
    if (!assetTypes.includes(assetType)) {
        throw Error('invalid asset type');
    }

    if (!museum) {
        throw Error('museum is not specified');
    }

    const {id: museumId, collectionId} = museum;

    if (!museumId) {
        throw Error('museum is not specified');
    }

    if (!collectionId) {
        throw Error('collection is not specified');
    }

    const assets = await damsFetch('/local-assets/all', {
        method: 'POST',
        body: JSON.stringify(decamelizeKeysDeep({
            assetType: assetType,
            collectionId: collectionId
        }))
    });

    if (!assets || !assets.models) {
        return [];
    }

    return camelcaseKeysDeep(assets.models);
};
