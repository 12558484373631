import {useParams} from "react-router-dom";
import {useGetFolder} from "../folder/useGetFolder";
import {useState} from "react";
import {ArcheologyProjectView} from "./archeology/projectview/ArcheologyProjectView";
import {PROJECT_LOADED, useProjectViewDispatch} from "./archeology/projectview/projectViewContext";
import {GeneralProjectView} from "./general/projectview/GeneralProjectView";
import {ProjectFailedLoading} from "./ProjectFailedLoading";
import useDeepCompareEffect from "use-deep-compare-effect";

export const ProjectView = () => {
    const {projectId} = useParams();
    const projectReq = useGetFolder(projectId);

    const [failed, setFailed] = useState(false);
    const [projectLoading, setProjectLoading] = useState(true);
    const [projectType, setProjectType] = useState();

    const projectViewDispatch = useProjectViewDispatch();

    useDeepCompareEffect(() => {
        if (!projectLoading) {
            return;
        }

        const {
            pending,
            success,
            data,
            error
        } = projectReq;

        if (!pending && success && data) {
            // Project loaded!
            const {folderType: projectType} = data;
            setProjectType(projectType);
            setProjectLoading(false);
            projectViewDispatch({type: PROJECT_LOADED, project: projectReq.data})
        } else if (error || !data) {
            // Project failed to load - warn user!
            setProjectLoading(false);
            setFailed(true);
        }
    }, [projectLoading, projectReq, projectViewDispatch]);

    return <>
        {!projectLoading && projectType === 'ProjectGeneral' && <GeneralProjectView/>}
        {!projectLoading && projectType === 'ProjectArcheology' && <ArcheologyProjectView/>}
        {!projectLoading && failed && <ProjectFailedLoading/>}
    </>;
};