import React from "react";
import {styled} from "@mui/material/styles";
import {usePostFolderDocument} from "./usePostFolderDocument";
import {grey} from "@mui/material/colors";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import FolderIcon from "@mui/icons-material/Folder";

const PREFIX = "DroppableFolderCard";

const classes = {
    myFolderActionArea: `${PREFIX}-myFolderActionArea`,
    folderIcon: `${PREFIX}-folderIcon`,
    badge: `${PREFIX}-badge`,
};

const Root = styled("div")(() => ({
    [`& .${classes.myFolderActionArea}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: 140,
        maxHeight: 140,
        width: 160,
        maxWidth: 160,
        backgroundColor: grey[200],
        textAlign: "center",
    },

    [`& .${classes.folderIcon}`]: {
        fontSize: 70,
        color: grey[500],
    },

    [`& .${classes.badge}`]: {
        top: 0,
        right: 0,
    },
}));

export const DroppableFolderCard = ({folder}) => {
    const [postFolderDocumentResponse, postFolderDocument] =
        usePostFolderDocument(folder.id);

    const dropped = (event) => {
        const droppedTarget = JSON.parse(
            event.dataTransfer.getData("application/json")
        );
        droppedTarget.forEach((documentId) =>
            postFolderDocument({documentId: documentId})
        );
    };

    return (
        <Root>
            <Card elevation={0}>
                <CardActionArea
                    onDrop={dropped}
                    onDragOver={(ev) => ev.preventDefault()}
                    className={classes.myFolderActionArea}
                >
                    <CardContent>
                        <Badge
                            badgeContent={postFolderDocumentResponse.success}
                            color={"primary"}
                        >
                            <FolderIcon className={classes.folderIcon}/>
                        </Badge>
                        <Typography noWrap sx={{maxWidth: 150}}>
                            {folder.name}
                        </Typography>

                        <Typography variant={"caption"} noWrap sx={{maxWidth: 150}}>
                            {folder.museumName}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </Root>
    );
};
