import {useFormikContext} from "formik";
import Divider from "@mui/material/Divider";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

/**
 * Button component used for deleting a copyright type.
 *
 * @param {boolean} fileUploadMode - Flag indicating if the file is in upload mode
 * @return {JSX.Element} The button component for deleting a copyright type
 */
export const BtnDeleteCopyrightType = ({fileUploadMode}) => {
    const {setFieldValue} = useFormikContext();

    /**
     * Method used to delete the copyright type.
     */
    const deleteCopyrightType = () => {
        // Update formik fields
        setFieldValue('copyrightType', '').then();
        setFieldValue('copyrightTypeOriginator', []).then();
        setFieldValue('copyrightTypeResponsible', []).then();
        setFieldValue('copyrightTypeDateUntil', '').then();
    };

    return !fileUploadMode
        && <>
            <Divider orientation={"vertical"} flexItem={true}
                     sx={{width: '3px', marginLeft: 1, marginRight: 1}}/>
            <IconButton
                key={`btnDeleteCopyrightType`}
                size={"large"}
                variant={"contained"}
                onClick={() => {
                    deleteCopyrightType();
                }}
            >
                <DeleteIcon/>
            </IconButton>
        </>;
};