import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "ColumnForm";

const classes = {
  content: `${PREFIX}-content`,
  form: `${PREFIX}-form`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.content}`]: {
    overflowY: "auto",
    overflowX: "hidden",
    paddingTop: 8,
  },

  [`& .${classes.form}`]: {
    display: "flex",
    flexDirection: "column",
    "& .MuiFormControl-root": {
      marginTop: theme.spacing(0),
      marginBottom: theme.spacing(2),
    },
  },
}));

export const ColumnForm = ({ children }) => {
  return (
    <Root className={classes.content}>
      <div className={classes.form}>{children}</div>
    </Root>
  );
};
