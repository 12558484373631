// eslint-disable-next-line no-extend-native
Number.prototype.formatBytes = function () {
    let units = ["B", "KB", "MB", "GB", "TB"],
        bytes = this,
        i;

    for (i = 0; bytes >= 1024 && i < 4; i++) {
        bytes /= 1024;
    }

    return bytes.toFixed(2) + units[i];
};

// eslint-disable-next-line no-extend-native
Number.prototype.formatHertz = function () {
    let units = ["hz", "Khz", "Mhz", "GHz"],
        hertz = this,
        i;
    for (i = 0; hertz >= 1000 && i < 4; i++) {
        hertz /= 1000;
    }
    return hertz.toFixed(2) + units[i];
};
