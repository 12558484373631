import {useFileUploadState} from "./fileUploadContext";
import {
    appendCopyrights,
    appendLicenses,
    setCopyrightClauseDates,
    setCopyrightResponsibleValues
} from "../form/formHelper";

// ==================================== UNTOUCHED FILE OBJECT ==========================================================
// {
//     "uniqueId": "f7c20931-d322-41b5-8b1e-930b62c7cbc6",
//     "createdAt": "2023-06-23T12:42:11",
//     "updatedAt": "2023-06-23T12:42:11",
//     "id": 356,
//     "schemaId": 1,
//     "documentType": "StillImage",
//     "title": "monkey.jpg (1) (2)_4227aa63-71eb-4522-8348-ef97e2502d75 (13).jpg",
//     "locale": "no",
//     "content": {
//     "mediae": [
//         {
//             "reference": {
//                 "id": 354,
//                 "title": "monkey.jpg (1) (2)_4227aa63-71eb-4522-8348-ef97e2502d75 (13).jpg",
//                 "locale": "no",
//                 "source": "dms",
//                 "status": "published",
//                 "fileSize": 70588,
//                 "mimeType": "image/jpeg",
//                 "schemaId": 1,
//                 "sourceId": "0136MRrbjJVh",
//                 "uniqueId": "5ea20ec7-f8eb-461d-8601-dda05f511f2b",
//                 "createdAt": "2023-06-23T12:42:11",
//                 "updatedAt": "2023-06-23T12:42:11",
//                 "documentType": "media",
//                 "fileExtension": "jpg"
//             },
//             "referenceType": "media"
//         }
//     ],
//         "remarks": "",
//         "subjects": null,
//         "languages": null
// },
//     "status": "published",
//     "collectionId": 3,
//     "createdById": 2,
//     "referenceCount": 0,
//     "parent": null,
//     "createdByName": null,
//     "updatedByName": null,
//     "thumbnail": "blob:http://localhost:5000/112defbc-3a6a-4a83-a0b8-23203a42d710"
// }
// =====================================================================================================================


export const useFormHelper = () => {
    const {saved, uploadedFiles, selected} = useFileUploadState();

    const getInitialValuesFromSavedFile = savedFile => {
        const {title, description} = savedFile;
        const {
            customIdentifier,
            productionDate,
            producer,
            persons,
            places,
            subjects,
            documentationType,
            languages,
            remarks,
            copyrightTerms,
            copyrightInfo,
            copyrightType,
            copyrightTypeOriginator,
            copyrightTypeDateUntil,
            copyrightTypeResponsible,
            licenses
        } = savedFile.content;

        // Append license information
        appendLicenses(savedFile, savedFile);
        appendCopyrights(savedFile, savedFile);

        setCopyrightResponsibleValues(copyrightInfo, savedFile);
        setCopyrightClauseDates(copyrightInfo, savedFile);

        return {
            ...savedFile,
            title: title,
            description: description || '',
            customIdentifier: customIdentifier || '',
            remarks: remarks || '',
            productionDate: productionDate || '',
            producer: producer || [],
            persons: persons || [],
            places: places || [],
            subjects: subjects || [],
            documentationType: documentationType || '',
            languages: languages || [],
            licenses: licenses || [],
            licensesAssociated: [],
            copyrightType: copyrightType || '',
            copyrightTerms: copyrightTerms || '',
            copyrightTypeResponsible: copyrightTypeResponsible || [],
            copyrightInfo: copyrightInfo || [],
            copyrightTypeDateUntil: copyrightTypeDateUntil || '',
            copyrightTypeOriginator: copyrightTypeOriginator || [],
        };
    };

    const getInitialValues = (numSelected) => {
        let initialValues = {
            title: "",
            description: "",
            customIdentifier: "",
            remarks: "",
            productionDate: "",
            producer: [],
            persons: [],
            places: [],
            subjects: [],
            licenses: [],
            licensesAssociated: [],
            copyrightType: '',
            copyrightTerms: '',
            copyrightTypeResponsible: [],
            copyrightInfo: [],
            copyrightTypeDateUntil: '',
            copyrightTypeOriginator: [],
            languages: [],
        };

        if (numSelected === 0) {
            // None selected, clear all fields.
            return initialValues;
        } else if (numSelected === 1) {
            // One selected, return all data.
            const file = uploadedFiles.find(f => f.uniqueId === selected[0]);

            // If the selected file already exists among the saved files, return this file object instead.
            const savedFile = saved.find(f => f.uniqueId === file.uniqueId);

            if (savedFile) {
                return getInitialValuesFromSavedFile(savedFile);
            } else {
                // If the file has not already been saved, only the title is relevant.
                return {
                    ...initialValues,
                    title: file.title,
                }
            }
        } else {
            // More than one selected, clear all fields.
            return initialValues;
        }
    };

    return [getInitialValues]
};

