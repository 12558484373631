import React, {useState} from "react";
import {ResultActions} from "./ResultActions";
import {ResultAction} from "./ResultAction";
import {
    ResultActionProvider,
    TOGGLE_ADD_TO_FOLDER,
    TOGGLE_BATCH_EDIT,
    TOGGLE_CREATE_FOLDER,
    TOGGLE_DELETE_FILES,
    TOGGLE_REMOVE_FILES,
} from "./ResultActionContext";
import {
    CLEAR_DELETING,
    MODELS_SAVED,
    SELECT_ALL,
    SET_SEARCHING,
    UNSELECT_ALL,
    useSearchDispatch,
    useSearchState,
    useSearchTranslation,
} from "./SearchContext";
import {AddToFolderModal} from "./addtofolder/AddToFolderModal";
import {FoldersProvider} from "../folder/FoldersContext";
import {SearchResultBatchEdit} from "../search-result-details/SearchResultBatchEdit";
import {useAuthsState} from "../auths/authsContext";
import {useCanEditInCollections} from "../auths/useCanEditInCollections";
import {RemoveFilesModal} from "./delete/RemoveFilesModal";
import {DocumentProvider} from "../documents/documentContext";
import {DownloadMultipleFilesModal} from "../search-result-details/DownloadMultipleFilesModal";
import {DownloadMultipleFilesProvider} from "../search-result-details/DownloadMultipleFilesContext";
import {DeleteFilesModal} from "./delete/DeleteFilesModal";
import {If} from "../app/If";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PermMediaOutlinedIcon from "@mui/icons-material/PermMediaOutlined";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CheckBoxOutlineBlankOutlinedIcon from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {useParams} from "react-router-dom";
import {CircularProgress} from "@mui/material";
import {CreateFolderModalNew} from "./createfolder/CreateFolderModalNew";
import {CreateFolderProvider} from "./createfolder/CreateFolderContext";
import PropTypes from "prop-types";

const SearchResultsActions = ({project, folder}) => {
    const t = useSearchTranslation();
    const {selected, onlyMine, folderContext, deleting} = useSearchState();
    const {damsId} = useAuthsState();
    const dispatch = useSearchDispatch();
    const params = useParams();

    const adminOfAllSelected = useCanEditInCollections({
        collectionIds: selected.map((s) => s.collectionId),
    });

    const [showDownloadDialog, setShowDownloadDialog] = useState(false);

    const isAllMine = Boolean(!selected.find((s) => s.createdById !== damsId));

    const isMyProject = project?.createdById === damsId;

    const isMyFolder = folder?.createdById === damsId;

    const hasProjectDeletePrivileges = adminOfAllSelected || isAllMine || isMyProject;

    const checkAll = () => {
        dispatch({type: SELECT_ALL});
    };

    const uncheckAll = () => {
        dispatch({type: UNSELECT_ALL});
    };

    const saveAndUncheck = models => {
        dispatch({
            type: MODELS_SAVED,
            models: models,
        });
        dispatch({type: CLEAR_DELETING});
        dispatch({type: SET_SEARCHING});
        uncheckAll();
    }

    const onRemovedFilesSuccess = (models) => {
        saveAndUncheck(models);
    };

    const onDeletedFilesSuccess = (models) => {
        saveAndUncheck(models);
    };

    const getActionDeleteDocuments = () => {
        if (folderContext && !params.projectId) {
            return;
        } else {
            // This option is only available inside a project.
            return (
                <ResultAction
                    disabled={
                        0 === selected.length || !hasProjectDeletePrivileges
                    }
                    displayValue={t("deleteFiles", "Slett {{count}} filer", {
                        count: selected.length,
                    })}
                    actionType={TOGGLE_DELETE_FILES}
                    icon={<DeleteOutlineIcon fontSize={"small"}/>}
                />
            );
        }
    };

    const getActionRemoveDocumentsFromFolder = () => {
        if (folderContext && !params.projectId && !onlyMine) {
            // This option should not be available inside a project!
            return <ResultAction
                disabled={0 === selected.length || (!adminOfAllSelected && !isAllMine && !isMyFolder)}
                displayValue={t("removeFiles", "Fjern {{count}} fra mappe", {
                    count: selected.length,
                })}
                actionType={TOGGLE_REMOVE_FILES}
                icon={<DeleteOutlineIcon fontSize="small"/>}
            />;
        }
    };

    const handleDownloadFiles = () => {
        setShowDownloadDialog(true);
    };

    const downloadMultipleFilesCallback = () => {
        setShowDownloadDialog(false);
        uncheckAll();
    };

    return (
        <>
            <ResultActionProvider>
                <ResultActions t={t} numSelected={selected.length}>
                    <ResultAction
                        disabled={0 === selected.length}
                        displayValue={t("addToFolder", "Legg i mappe")}
                        actionType={TOGGLE_ADD_TO_FOLDER}
                        icon={<PermMediaOutlinedIcon fontSize="small"/>}
                    />
                    <ResultAction
                        disabled={0 === selected.length}
                        displayValue={t("createFolder", "Legg i ny mappe")}
                        actionType={TOGGLE_CREATE_FOLDER}
                        icon={<CreateNewFolderOutlinedIcon fontSize="small"/>}
                    />
                    <MenuItem
                        disabled={0 === selected.length || showDownloadDialog}
                        onClick={() => handleDownloadFiles()}
                    >
                        <ListItemIcon>
                            <FileDownloadIcon fontSize="small"/>
                        </ListItemIcon>
                        {t("download", "Last ned")}
                    </MenuItem>
                    {getActionRemoveDocumentsFromFolder()}
                    {getActionDeleteDocuments()}
                    <If boolean={selected.length === 0}>
                        <MenuItem onClick={checkAll}>
                            <ListItemIcon>
                                <CheckBoxOutlinedIcon fontSize="small"/>
                            </ListItemIcon>
                            {t("checkAll", "Merk alle")}
                        </MenuItem>
                    </If>
                    <If boolean={selected.length > 0}>
                        <MenuItem disabled={0 === selected.length} onClick={uncheckAll}>
                            <ListItemIcon>
                                <CheckBoxOutlineBlankOutlinedIcon fontSize="small"/>
                            </ListItemIcon>
                            {t("uncheckAll", "Avmerk alle")}
                        </MenuItem>
                    </If>
                    <ResultAction
                        displayValue={t("batchEdit", "Rediger {{count}} dokumenter", {
                            count: selected.length,
                        })}
                        actionType={TOGGLE_BATCH_EDIT}
                        disabled={
                            2 > selected.length || (!adminOfAllSelected && !isAllMine)
                        }
                        icon={<EditOutlinedIcon fontSize="small"/>}
                    />
                </ResultActions>
                <FoldersProvider>
                    <AddToFolderModal selectedDocuments={selected}/>
                    <CreateFolderProvider>
                        <CreateFolderModalNew t={t} selectedDocuments={[...selected]} onHideCallback={uncheckAll}/>
                    </CreateFolderProvider>
                    <DocumentProvider>
                        {deleting && <CircularProgress size={160} disableShrink={true}/>}
                        <DeleteFilesModal
                            selectedDocuments={selected}
                            onSuccess={onDeletedFilesSuccess}
                        />
                        <RemoveFilesModal
                            selectedDocuments={selected}
                            onSuccess={onRemovedFilesSuccess}
                        />
                    </DocumentProvider>
                </FoldersProvider>
                <SearchResultBatchEdit/>
                <DownloadMultipleFilesProvider>
                    <DownloadMultipleFilesModal
                        documents={selected}
                        openDialog={showDownloadDialog && selected.length > 0}
                        callback={downloadMultipleFilesCallback}
                    />
                </DownloadMultipleFilesProvider>
            </ResultActionProvider>
        </>
    );
};

SearchResultsActions.propTypes = {
    "project": PropTypes.object,
    "folder": PropTypes.object
};

export {SearchResultsActions};