export const createMedia = ({title, dmsId, filesize, fileExtension, mimeType, locale = "no"}) => {
    return {
        referenceType: 'media',
        _action: "put",
        reference: {
            status: "published",
            title: title,
            _action: "put",
            locale: locale,
            source: 'dms',
            sourceId: dmsId,
            content: {
                filesize: filesize,
                fileExtension: fileExtension,
                mimeType: mimeType,
            }
        }
    }
}