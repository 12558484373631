import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

export const UPDATE_FORM_DATA = "documentContext/updateFormData";
export const ADD_FILES = "documentContext/addFiles";
export const UPDATE_FILES = "documentContext/updateFiles";
export const SHOW_ADD_MORE_FILES = "documentContext/showAddMoreFiles";
export const HIDE_ADD_MORE_FILES = "documentContext/hideAddMoreFiles";
export const DOCUMENT_SAVED = "documentContext/documentSaved";
export const DOCUMENT_SAVE_ERROR = "documentContext/saveDocumentFailed";
export const SET_DMS_ID = "documentContext/setDmsId";
export const REMOVE_FILE = "documentContext/removeFile";
export const QUEUE_FILE = "documentContext/queueFile";
export const RETRY_FILE = "documentContext/retryFile";
export const CHOOSE_COLLECTIONS = "documentContext/chooseCollections";
export const CLEAR = "documentContext/clear";
export const CLEAR_SAVED = "documentContext/clearSaved";
export const EDITED_METADATA = 'documentContext/editedMetadata';
export const ENABLE_NEXT_BUTTON = 'documentContext/enableNextButton';

export const SET_METADATA_MAPPING_PROGRESS = 'documentContext/setMetadataMappingProgress';

export const SET_FILES_FAILED_MAPPING = 'documentContext/setFilesFailedMapping';


const DocumentStateContext = createContext(undefined);
const DocumentDispatchContext = createContext(undefined);
const DocumentTranslationContext = createContext(undefined);

const initialState = {
    formData: {},
    files: [],
    enableNextButton: false,
    showAddMoreFiles: false,
    editedMetadata: [],
    saved: [],
    unsaved: [],
    failed: [],
    progress: {},
    collectionIds: [],
    metadataMappingProgress: 0,
    filesFailedMapping: []
};

const documentReducer = (state, action) => {
    switch (action.type) {
        case SET_FILES_FAILED_MAPPING:
            return {
                ...state,
                filesFailedMapping: action.files
            };
        case SET_METADATA_MAPPING_PROGRESS:
            return {
                ...state,
                metadataMappingProgress: action.value
            };
        case UPDATE_FORM_DATA:
            return {
                ...state,
                formData: action.formData,
            };
        case ADD_FILES:
            return {
                ...state,
                files: [...state.files, ...action.files],
                unsaved: [...state.unsaved, ...action.files],
                showAddMoreFiles: false,
            };

        case UPDATE_FILES:
            return {
                ...state,
                files: action.files,
                unsaved: action.files,
                showAddMoreFiles: false,
            };
        case QUEUE_FILE:
            return {
                ...state,
                files: state.files.map((item) =>
                    item.title === action.title ? {...item, queued: true} : item
                ),
            };
        case RETRY_FILE:
            return {
                ...state,
                files: state.files.map((item) =>
                    item.title === action.title ? {...item, queued: false} : item
                ),
            };
        case SET_DMS_ID:
            return {
                ...state,
                files: [
                    ...state.files.filter((file) => file.title !== action.name),
                    ...state.files
                        .filter((file) => file.title === action.name)
                        .map((file) => ({
                            ...file,
                            dmsId: action.dmsId,
                        })),
                ],
            };
        case SHOW_ADD_MORE_FILES:
            return {
                ...state,
                showAddMoreFiles: true,
            };
        case HIDE_ADD_MORE_FILES:
            return {
                ...state,
                showAddMoreFiles: false,
            };
        case DOCUMENT_SAVED:
            const alreadySaved = [...state.saved];
            const savedExistsIx = alreadySaved.findIndex(
                (s) => s.uniqueId === action.document.uniqueId
            );
            if (savedExistsIx !== -1) {
                alreadySaved.splice(savedExistsIx, 1);
            }
            return {
                ...state,
                saved: [...alreadySaved, action.document],
                unsaved: state.unsaved.filter(
                    (unsavedDocument) =>
                        unsavedDocument.title !==
                        action.document.content.mediae[0].reference.title
                ),
                failed: state.failed.filter(
                    (failedDocument) =>
                        failedDocument !== action.document.content.mediae[0].reference.title
                ),
            };
        case EDITED_METADATA:
            return {
                ...state,
                editedMetadata: [...state.editedMetadata, ...action.files]
            };
        case DOCUMENT_SAVE_ERROR: {
            return {
                ...state,
                saved: [...state.saved.filter(s => s !== action.fileName)],
                failed: [
                    ...state.failed.filter(
                        (failedDocument) => failedDocument !== action.fileName
                    ),
                    action.fileName,
                ],
            };
        }
        case REMOVE_FILE:
            return {
                ...state,
                files: state.files.filter((file) => file.title !== action.title),
                unsaved: state.unsaved.filter((file) => file.title !== action.title),
            };
        case CHOOSE_COLLECTIONS:
            return {
                ...state,
                collectionIds: action.collectionIds,
            };
        case CLEAR:
            return {
                ...initialState,
            };
        case CLEAR_SAVED:
            return {
                ...state,
                saved: []
            };
        case ENABLE_NEXT_BUTTON:
            return {
                ...state,
                enableNextButton: true
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const DocumentProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        documentReducer,
        initialState,
        undefined
    );
    const {t, ready} = useTranslation("dams.document", {useSuspense: false});

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <DocumentTranslationContext.Provider value={t}>
            <DocumentStateContext.Provider value={state}>
                <DocumentDispatchContext.Provider value={dispatch}>
                    {children}
                </DocumentDispatchContext.Provider>
            </DocumentStateContext.Provider>
        </DocumentTranslationContext.Provider>
    );
};

export const useDocumentState = () => {
    const context = useContext(DocumentStateContext);
    if (undefined === context) {
        throw new Error(
            "useDocumentState must be used within an DocumentProvider."
        );
    } else {
        return context;
    }
};

export const useDocumentDispatch = () => {
    const context = useContext(DocumentDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useDocumentDispatch must be used within an DocumentProvider."
        );
    } else {
        return context;
    }
};

export const useDocumentTranslation = () => {
    const context = useContext(DocumentTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useDocumentTranslation must be used within an DocumentProvider"
        );
    } else {
        return context;
    }
};
