import React, {useEffect} from "react";
import {makeStyles} from "@mui/styles";

// noinspection ES6CheckImport
import {LazyLoadImage} from "react-lazy-load-image-component";

import {DamsFileIcon} from "../../files/DamsFileIcon";
import Box from "@mui/material/Box";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import {clientLog} from "../../clientLog";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
    image: {
        height: 140,
        width: '100%',
        objectFit: "contain",
        backgroundColor: '#e9e9e9',
    },
    fileTypeContainer: {
        position: "absolute",
        bottom: -15,
        right: 8,
    },
}));

/**
 * Renders an image component for a result card.
 *
 * @param {Object} props - The properties for the component.
 * @param {Object} props.model - The model containing imageUrl, title, content, and documentType.
 * @param {Function} props.clickHandler - The click handler function.
 * @param {boolean} props.showInstantly - Whether to show the image instantly.
 * @param {boolean} [props.icon=true] - Whether to show the file type icon.
 * @return {JSX.Element} The rendered image component.
 */
const ResultCardImage = ({model, clickHandler, showInstantly, icon = true}) => {
    const classes = useStyles();

    const {imageUrl, title, content, documentType} = model;

    const noImageUrl = "/no-thumbnail.jpg";

    const verifyParams = () => {
        if (showInstantly && !clickHandler) {
            clientLog('warn', 'the prop showInstantly = true, require a clickHandler to be set.');
        }
    };

    useEffect(() => {
        if (!showInstantly || !clickHandler) {
            return;
        }
        clickHandler();
    }, [showInstantly]);

    verifyParams();

    return (
        <Box data-testid={"DAMSResultCardImage"}>
            <LazyLoadImage
                alt={title}
                title={title}
                effect={"black-and-white"}
                height={140}
                width={"100%"}
                src={imageUrl}
                className={classes.image}
                placeholderSrc={noImageUrl}
            />
            {icon && (
                <div className={classes.fileTypeContainer}>
                    <DamsFileIcon
                        filename={content.mediae[0].reference.title}
                        mimeType={
                            content.mediae[0].reference.mimeType || documentType
                        }
                        size={32}
                    />
                </div>
            )}
        </Box>
    );
};

ResultCardImage.propTypes = {
    "model": PropTypes.object.isRequired,
    "clickHandler": PropTypes.func.isRequired,
    "showInstantly": PropTypes.bool
};

export {ResultCardImage};