import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import LibraryBooksOutlinedIcon from "@mui/icons-material/LibraryBooksOutlined";

export const useGetDocumentTypeIcon = (mimeType, iconStyle) => {
    const objectTypes = {
        document: {
            icon: <DescriptionOutlinedIcon sx={iconStyle}/>,
            mimeTypes: [
                "opendocument.text",
                "text",
                "word",
                "msword",
                "wordprocessingml",
            ],
        },
        spreadsheet: {
            icon: <CalculateOutlinedIcon sx={iconStyle}/>,
            mimeTypes: [
                "excel",
                "spreadsheetml",
                "opendocument.spreadsheet",
                "opendocument.chart",
                "opendocument.formula",
            ],
        },
        presentation: {
            icon: <SlideshowOutlinedIcon sx={iconStyle}/>,
            mimeTypes: ["powerpoint", "presentationml", "opendocument.presentation"],
        },
        compressed: {
            icon: <LibraryBooksOutlinedIcon sx={iconStyle}/>,
            mimeTypes: [
                "tar",
                "gzip",
                "gtar",
                "tgz",
                "bzip",
                "lzip",
                "7z",
                "ms-cab",
                "rar",
                "zip",
            ],
        },
    };

    if (!mimeType) {
        return <DescriptionOutlinedIcon sx={iconStyle}/>;
    }

    let icon = <DescriptionOutlinedIcon sx={iconStyle}/>;
    Object.keys(objectTypes).forEach((k) => {
        objectTypes[k]["mimeTypes"].forEach((mt) => {
            if (mimeType.indexOf(mt) > -1) {
                icon = objectTypes[k]["icon"];
            }
        });
    });
    return icon;
};
