import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

/**
 * Component used when rendering a single row in the metadataaccordion displayed in the object view.
 * @param label
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const MetadataRow = ({label, children}) => {
    return (
        <Grid
            key={`row${crypto.randomUUID()}`}
            container
            direction={"row"}
            spacing={2}
            alignItems={"flex-start"}
            justifyContent={"flex-start"}
            sx={{marginBottom: 1}}
        >
            <Grid
                size={{
                    lg: 3,
                    xl: 3
                }}>
                <Typography variant={"caption"} color={"secondary"}>
                    {label}:
                </Typography>
            </Grid>
            <Grid size={{
                lg: 9,
                xl: 9
            }}>
                {children}
            </Grid>
        </Grid>
    );
};

MetadataRow.propTypes = {
    label: PropTypes.string.isRequired
};

export {MetadataRow};