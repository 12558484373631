import React from "react";
import Typography from "@mui/material/Typography";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Stack } from "@mui/material";
import { SquareThumbWithDocTypeOverlay } from "../app/SquareThumbWithDocTypeOverlay";

export const InputRelatedOption = (data) => {
  return (
    <Stack direction={"row"} sx={{ minWidth: "100%" }} spacing={1}>
      <SquareThumbWithDocTypeOverlay model={data} />
      <Stack direction={"column"} sx={{ maxWidth: "300px" }}>
        <Typography variant="body1" color="textPrimary" noWrap>
          {data.title}
        </Typography>
        <Typography variant="body2" color="textSecondary" noWrap>
          {data.description}
        </Typography>
        <Typography variant="caption" color="textSecondary" mt={1}>
          DAMS ID: {data.uniqueId}
        </Typography>
      </Stack>
    </Stack>
  );
};
