import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Renders the number edited information.
 *
 * @param {string} text - The text to display.
 * @param {function} onClick - The function to call on click event.
 * @return {JSX.Element} Rendered JSX element.
 */
export const NumberEditedInfo = ({text, onClick}) => {
    return <>
        <Typography variant={"h6"}>
            {text}
        </Typography>
        <Box flexGrow={1}/>
        <CloseIcon
            onClick={onClick}
            sx={{cursor: "pointer", color: "GrayText"}}
        />
    </>;
};