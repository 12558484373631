import {If} from "../app/If";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import Box from "@mui/material/Box";
import React from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

export const getIconOrThumbnail = (file, showAudioThumb, showUnknownThumb, topmostOverlay) => {
    return <>
        <If boolean={showAudioThumb}>
            <div className={'fileThumbContainer'} key={file.title}>
                <VolumeUpOutlinedIcon className={'fileThumbIcon'}/>
                {topmostOverlay}
            </div>
        </If>
        <If boolean={showUnknownThumb}>
            <div className={'fileThumbContainer'} key={file.title}>
                <DescriptionOutlinedIcon className={'fileThumbIcon'}/>
                {topmostOverlay}
            </div>
        </If>
        <If boolean={!showAudioThumb && !showUnknownThumb}>
            <Box key={file.title}>
                <img
                    key={file.title}
                    src={_getImageUrl(file)}
                    alt={file.title}
                    className={'imageFullHeight'}
                />
                {topmostOverlay}
            </Box>
        </If>
    </>;
};

export const getCheckIcon = (checked, onCheckCallback, iconColor) => {
    if (!checked) {
        return (
            <CheckBoxOutlineBlankIcon
                className={'infoIcon'}
                sx={{left: 3, color: iconColor}}
                onClick={onCheckCallback}
            />
        );
    } else {
        return (
            <CheckBoxIcon
                className={'infoIcon'}
                sx={{left: 3, color: iconColor}}
                onClick={onCheckCallback}
            />
        );
    }
};

const _getImageUrl = (file) => {
    return file.thumbnailUrl || '/no-thumbnail.jpg';
};