import React from "react";
import { styled } from "@mui/material/styles";

const PREFIX = "AdminContainer";

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
};

const Root = styled("div")(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },

  [`& .${classes.container}`]: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
    width: "80%",
    display: "flex",
    flexDirection: "column",
  },
}));

export const AdminContainer = ({ children }) => {
  return (
    <Root className={classes.root}>
      <div className={classes.container}>{children}</div>
    </Root>
  );
};
