import React, {useEffect, useState} from "react";
import {useDocumentState} from "../../documents/documentContext";
import {usePostFolderDocument} from "../../folder/usePostFolderDocument";
import FolderIcon from "@mui/icons-material/Folder";
import Badge from "@mui/material/Badge";
import LoadingButton from "@mui/lab/LoadingButton";

export const ButtonAddToFolder = ({
                                      folder,
                                      checkedFilenames,
                                      closeDialogCallback,
                                  }) => {
    const {saved} = useDocumentState();
    const [postFolderDocumentResponse, postFolderDocument] =
        usePostFolderDocument(folder.id);
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        if (postFolderDocumentResponse.success === checkedFilenames.length) {
            setProcessing(false);
            setTimeout(closeDialogCallback, 1500);
        }
    }, [
        postFolderDocumentResponse.success,
        checkedFilenames.length,
        closeDialogCallback,
    ]);

    const fileNameToDocumentId = (fileNames) => {
        return saved
            .filter((document) =>
                fileNames.includes(document.content.mediae[0].reference.title)
            )
            .map((document) => document.id);
    };

    const handleAddToFolder = (_) => {
        setProcessing(true);
        const documentIds = fileNameToDocumentId(checkedFilenames);
        documentIds.forEach((id) =>
            postFolderDocument({documentId: id.toString()})
        );
    };
    return (
        <LoadingButton
            loading={processing}
            variant={"contained"}
            color={"primary"}
            startIcon={<FolderIcon/>}
            loadingPosition={"start"}
            endIcon={
                <Badge
                    badgeContent={postFolderDocumentResponse.success}
                    color={"primary"}
                />
            }
            onClick={handleAddToFolder}
        >
            {folder.title}
        </LoadingButton>
    );
};
