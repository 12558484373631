import {CLEAR_JOB, useDownloadWorkerDispatch, useDownloadWorkerState} from "./workerStatusContext";
import Snackbar from "@mui/material/Snackbar";
import {CircularProgress, SnackbarContent} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import Stack from "@mui/material/Stack";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

export const WorkerStatusSnackBar = () => {

    const downloadWorkerDispatch = useDownloadWorkerDispatch();

    const {jobs} = useDownloadWorkerState();

    const activeJobs = jobs.filter(j => j.active && !j.complete);

    const clearJob = jobId => {
        downloadWorkerDispatch({
            type: CLEAR_JOB,
            jobId: jobId
        });
    };

    const getStages = job => {
        const stages = job.stages.filter(s => s.active && s.label);

        // Skip rendering updates if no stages have been defined, or if handling only one file.
        if (!stages || job.files.length === 1) {
            return <></>;
        }

        return stages.map(s => {
            const {label, percentage} = s;
            return <Box key={s.id} mb={1} ml={4}>
                <Typography>{label}: {percentage}%</Typography>
            </Box>;
        });
    };

    const getMessage = () => {
        return activeJobs.map(j => {
            const {jobId, name} = j;
            const singleFile = j.files.length === 1;

            return <Accordion key={jobId} TransitionProps={{unmountOnExit: true}}
                              sx={{width: '100%', minWidth: '100%'}}>
                <AccordionSummary
                    expandIcon={!singleFile ? <ExpandMoreIcon/> : <></>}
                    aria-controls={`panel-${jobId}-content`}
                    id={`panel-${jobId}`}
                >
                    <Stack direction={"row"} alignItems={"center"}>
                        <CircularProgress size={20} color={"success"}/>
                        <Typography ml={2}>
                            {name}
                        </Typography>
                        {/*<Typography variant={"caption"} ml={2}>({jobId})</Typography>*/}
                        <Box flexGrow={1}/>
                        <IconButton color={"primary"} onClick={() => clearJob(jobId)}>
                            <CloseIcon/>
                        </IconButton>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    {getStages(j)}
                </AccordionDetails>
            </Accordion>
        })
    };

    return <Snackbar open={activeJobs.length > 0}
                     anchorOrigin={{
                         vertical: "top",
                         horizontal: "center"
                     }}
                     sx={{
                         cursor: 'pointer',
                         maxHeight: '400px',
                         overflowY: 'auto',
                     }}
    >
        <SnackbarContent
            sx={{
                background: 'transparent',
                boxShadow: 'none'
            }}
            message={getMessage()}
        >
        </SnackbarContent>
    </Snackbar>;
};