import camelcaseKeysDeep from "camelcase-keys-deep";

export const inputWithCollectionIds = (models) => {
    const groupedByTitle = models.reduce((acc, cur) => {
        if (acc[cur.title]) {
            return {
                ...acc,
                [cur.title]: {
                    ...mapModelToInput(cur),
                    collectionId: [...acc[cur.title].collectionId, cur.collectionId],
                },
            };
        } else {
            return {
                ...acc,
                [cur.title]: {
                    ...mapModelToInput(cur),
                    collectionId: [cur.collectionId],
                },
            };
        }
    }, {});
    return Object.values(groupedByTitle);
};

export const fieldValuesWithCollectionIds = (models) => {
    if (!Array.isArray(models)) {
        models = [models];
    }
    const groupedByTitle = camelcaseKeysDeep(models).reduce((acc, cur) => {
        const isString = Object.prototype.toString.call(cur) === "[object String]";
        if (!cur || isString) {
            return {};
        }
        if (Object.keys(acc).includes(cur.reference.title)) {
            return {
                ...acc,
                [cur.reference.title]: {
                    ...fieldValueToInput(cur),
                    collectionId: [
                        ...acc[cur.reference.title].collectionId,
                        cur.collectionId,
                    ],
                },
            };
        } else {
            return {
                ...acc,
                [cur.reference.title]: {
                    ...fieldValueToInput(cur),
                    collectionId: [cur.collectionId],
                },
            };
        }

    }, {});
    return Object.values(groupedByTitle);
};

const fieldValueToInput = (model) => ({
    id: model.reference.id,
    sourceId: model.reference.sourceId,
    uniqueId: model.reference.uniqueId,
    title: model.reference.title,
    source: model.reference.source,
    content: model.reference.content,
});

export const mapModelToInput = (model) => ({
    id: model.id,
    uniqueId: model.uniqueId,
    title: model.title,
    sourceId: model.sourceId,
    source: model.source,
    content: model.content,
    orphan: model?.orphan || false
});

export const kulturnavToInput = (locale) => (hit) => ({
    sourceId: hit.uuid,
    title: hit.caption[locale],
    source: hit.dataset.displayValue[locale],
});

export const sourceToUniqueId = (damsResponse) => {
    const externalDamsData = damsResponse.filter(
        (model) => undefined !== model.sourceId
    );
    if (0 < externalDamsData.length) {
        return externalDamsData.reduce(
            (acc, cur) => ({
                ...acc,
                [cur.sourceId]: cur,
            }),
            {}
        );
    } else {
        return {};
    }
};

export const concatWithExisting = (kulturnavResponse, damsLookup) => {
    return kulturnavResponse.map((knav) => ({
        ...knav,
        id: damsLookup[knav.sourceId] ? damsLookup[knav.sourceId].id : null,
        uniqueId: damsLookup[knav.sourceId]
            ? damsLookup[knav.sourceId].uniqueId
            : null,
        collectionId: damsLookup[knav.sourceId]
            ? damsLookup[knav.sourceId].collectionId
            : [],
    }));
};

export const excludeThoseAlreadyListedAsKulturnav = (
    damsResponse,
    kulturnavMapped
) => {
    const kulturnavDamsIds = kulturnavMapped
        .filter((knav) => null !== knav.uniqueId)
        .map((knav) => knav.uniqueId);
    return damsResponse.filter(
        (model) => !kulturnavDamsIds.includes(model.uniqueId)
    );
};
