import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";

export const DetailsRow = ({label, value}) => {
    return (
        <Stack direction={"row"}>
            <Typography
                variant={"caption"}
                noWrap
                sx={{width: "20vw", maxWidth: "20vw"}}
            >
                {label}
            </Typography>
            <Typography variant={"caption"}>{value}</Typography>
        </Stack>
    );
};
