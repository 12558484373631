/**
 * Object used to create the styles used by GeneralProjectViewDetails and ArcheologyProjectViewDetails
 * @type {{gridContentColumnRight: {color: string, paddingRight: string, width: string, paddingLeft: string}, buttonToolbar: {alignItems: string, display: string, marginBottom: string, justifyContent: string, marginTop: string}, boxProjectIcon: {border: string, backgroundColor: string, alignItems: string, color: string, display: string, width: number, marginBottom: string, justifyContent: string, height: number}, gridContentColumnLeft: {color: string, width: string, paddingLeft: string}, gridFactColumn: {width: string}, gridContentColumnCenter: {borderLeft: string, color: string, borderRight: string, paddingRight: string, width: string, paddingLeft: string}}}
 */
export const projectViewStyles = {
    boxProjectIcon: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: 56,
        height: 76,
        border: "solid 1px #ddd",
        color: "white",
        backgroundColor: "white",
        marginBottom: '16px',
    },
    gridContentColumnLeft: {
        width: "20%",
        paddingLeft: '8px',
        color: "white",
    },
    gridContentColumnCenter: {
        width: "50%",
        paddingLeft: '16px',
        paddingRight: '16px',
        color: "white",
        borderLeft: "solid 1px white",
        borderRight: "solid 1px white",
    },
    gridContentColumnRight: {
        width: "25%",
        paddingLeft: '16px',
        paddingRight: '8px',
        color: "white",
    },
    gridFactColumn: {
        width: "50%",
    },
    buttonToolbar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "right",
        marginTop: '16px',
        marginBottom: '24px',
    },
};