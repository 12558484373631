/**
 * This file contains utility methods used when editing/saving metadata.
 */


/**
 * Deletes copyright clauses marked for deletion, before saving.
 * @param formData
 * @param values
 */
export const deleteMarkedClauses = (formData, values) => {
    if (!formData) {
        return;
    }
    if (!values) {
        return;
    }
    formData.copyrightInfo?.map(c => {
        if (c.delete === true) {
            const i = values.copyrightInfo.findIndex(v => v.uniqueId === c.uniqueId);
            delete values[`copyrightResponsible${i}`];
            delete values[`copyrightFromDate${i}`];
            delete values[`copyrightToDate${i}`];
            values.copyrightInfo.splice(i, 1);
        }
    });
};

/**
 * Deletes the licenses marked for deletion, before saving.
 * @param formData
 * @param values
 */
export const deleteMarkedLicenses = (formData, values) => {
    if (!formData) {
        return;
    }
    if (!values) {
        return;
    }
    formData.licenses?.map(l => {
        if (l.delete === true) {
            const j = values.licenses.findIndex(v => v.uniqueId === l.uniqueId);
            delete values[`license${j}`];
            delete values[`licensesAssociated${j}`];
            values.licenses.splice(j, 1);
        }
    });
};

/**
 * Gets the number of fields with the specific prefix.
 * Used when fetching values for copyright clauses and licenses.
 * @param fieldPrefix   str The field prefix.
 * @param values    object  The values from the form.
 * @returns {number}
 */
const getNumberOfDynamicField = (fieldPrefix, values) => {
    let fieldCount = 1;
    for (const k of Object.keys(values)) {
        if (k.indexOf(fieldPrefix) === 0) {
            let controlInt = parseInt(k.substring(fieldPrefix.length));
            if (!isNaN(controlInt)) {
                fieldCount = controlInt
            }
        }
    }
    return isNaN(fieldCount) ? 0 : fieldCount;
}

/**
 * Merges copyright information into the values object.
 * @param values
 */
export const mergeCopyrightInfo = (values) => {
    mergeCopyrightResponsible(values);
}

/**
 * Merges the copyright dates into the copyrightInfo object.
 * @param values
 */
export const mergeCopyrightDates = values => {
    const numberOfFromDates = getNumberOfDynamicField('copyrightFromDate', values);
    const numberOfToDates = getNumberOfDynamicField('copyrightToDate', values);
    mergeCopyrightDate('copyrightFromDate', values, numberOfFromDates);
    mergeCopyrightDate('copyrightToDate', values, numberOfToDates);
};

/**
 * Merges the specific date into the copyrightInfo object.
 * @param fieldPrefix
 * @param values
 * @param numOf
 */
export const mergeCopyrightDate = (fieldPrefix, values, numOf) => {
    for (let a = 0; a <= numOf; a++) {
        const v = values[`${fieldPrefix}${a}`];
        if (!v) {
            continue;
        }

        let fieldName = fieldPrefix === 'copyrightFromDate' ? 'fromDate' : 'toDate';
        const copyrightInfoObj = values['copyrightInfo'][a];
        if (copyrightInfoObj) {
            copyrightInfoObj[fieldName] = v;
        }
    }
};

/**
 * Merge each entry of responsible persons into the correct copyrightInfo object in values.
 * @param values
 */
export const mergeCopyrightResponsible = values => {
    // Get the number of copyright clauses to be merged
    const numberOfCopyrightClauses = getNumberOfDynamicField('copyrightClause', values);

    for (let j = 0; j <= numberOfCopyrightClauses; j++) {
        let copyrightClause = values[`copyrightClause${j}`];

        // Get the responsible field matching the current copyright clause, and add it.
        const responsible = values[`copyrightResponsible${j}`];
        if (copyrightClause && responsible) {
            copyrightClause['responsible'] = responsible;
        } else if (responsible && values.copyrightInfo[j]) {
            values.copyrightInfo[j]['responsible'] = responsible;
        }

        // Merge the copyright clause into the values.copyrightInfo array.
        if (values.copyrightInfo && values.copyrightInfo.length > 0 && copyrightClause) {
            const existsAtIndex = values.copyrightInfo.findIndex(c => c.uniqueId === copyrightClause.uniqueId);
            if (existsAtIndex > -1) {
                values.copyrightInfo[existsAtIndex] = copyrightClause;
            } else {
                values.copyrightInfo.push(copyrightClause);
            }
        } else if (copyrightClause) {
            if (!values.copyrightInfo) {
                values.copyrightInfo = [];
            }
            values.copyrightInfo.push(copyrightClause);
        }
    }
};

/**
 * Merges license information and -associate/-responsible into the values object.
 * @param values
 */
export const mergeLicenseInfo = (values) => {
    const numberOfSelectedLicenses = getNumberOfDynamicField('license', values);

    function replaceOrAddLicense(license) {
        if (!values.licenses) {
            values.licenses = []
        }

        values.licenses = values.licenses.filter(l => {
            return (typeof (l) !== 'undefined' && !l.delete);
        });

        if (license && values.licenses.length > 0 && typeof (values.licenses[0]) !== 'undefined') {
            const existsAtIndex = values.licenses.findIndex(l => l.reference?.uniqueId === license.reference.uniqueId || l?.uniqueId === license.reference.uniqueId);
            if (existsAtIndex > -1 && !license.delete) {
                values.licenses[existsAtIndex] = license;
            } else if (!license.delete) {
                values.licenses.push(license);
            }
        } else if (typeof (license) !== 'undefined' && !license?.delete) {
            values.licenses.push(license);
        }
    }

    for (let i = 0; i <= numberOfSelectedLicenses; i++) {
        const licenceField = values[`license${i}`];
        const license = Array.isArray(licenceField) ? licenceField[0] : licenceField;
        if (license && values[`licensesAssociated${i}`]) {
            license['responsible'] = values[`licensesAssociated${i}`];
        }

        // Replace or add license.
        replaceOrAddLicense(license);
    }
};