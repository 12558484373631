import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import PropTypes from "prop-types";

const ToolbarContinueOnly = ({classes, t, clickCallback}) => {
    return <Toolbar className={classes.toolbar}>
        <Box flexGrow={1}/>
        <Button variant={"contained"}
                color={"secondary"}
                onClick={clickCallback}>
            {t('btnContinue', 'Fortsett')}
        </Button>
    </Toolbar>
};


ToolbarContinueOnly.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    clickCallback: PropTypes.func.isRequired
};

export {ToolbarContinueOnly};