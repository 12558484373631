import React, {createContext, useContext, useReducer} from "react";

const ResultActionStateContext = createContext(undefined);
const ResultActionDispatchContext = createContext(undefined);

export const OPEN = 'resultActionContext/open';
export const CLOSE = 'resultActionContext/close';
export const TOGGLE_ADD_TO_FOLDER = 'resultActionContext/showAddToFolder';
export const TOGGLE_DOWNLOAD = 'resultActionContext/toggleDownload';
export const TOGGLE_CREATE_FOLDER = 'resultActionContext/toggleCreateFolder';
export const TOGGLE_BATCH_EDIT = 'resultActionContext/toggleBatchEdit';
export const TOGGLE_REMOVE_FILES = 'resultActionContext/toggleRemoveFiles';
export const TOGGLE_DELETE_FILES = 'resultActionContext/toggleDeleteFiles';
export const RESET_CREATE_FOLDER = 'resultActionContext/resetCreateFolder';

const initialState = {
    anchorEl: null,
    showAddToFolder: false,
    showDownload: false,
    showCreateFolder: false,
    showBatchEdit: false,
    showRemoveFiles: false,
};

const resultActionReducer = (state, action) => {
    switch (action.type) {
        case OPEN:
            return {
                ...state,
                anchorEl: action.anchorEl
            }
        case CLOSE:
            return {
                ...state,
                anchorEl: null
            }
        case TOGGLE_ADD_TO_FOLDER:
            return {
                ...state,
                showAddToFolder: !state.showAddToFolder
            }
        case TOGGLE_DOWNLOAD:
            return {
                ...state,
                showDownload: !state.showDownload
            }
        case TOGGLE_CREATE_FOLDER:
            return {
                ...state,
                showCreateFolder: !state.showCreateFolder
            }
        case TOGGLE_BATCH_EDIT:
            return {
                ...state,
                showBatchEdit: !state.showBatchEdit
            }
        case TOGGLE_REMOVE_FILES:
            return {
                ...state,
                showRemoveFiles: !state.showRemoveFiles
            }
        case TOGGLE_DELETE_FILES:
            return {
                ...state,
                showDeleteFiles: !state.showDeleteFiles
            }
        case RESET_CREATE_FOLDER:
            return {
                ...state,
                showCreateFolder: false
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const ResultActionProvider = ({children}) => {
    const [state, dispatch] = useReducer(resultActionReducer, initialState, undefined);

    return (
        <ResultActionStateContext.Provider value={state}>
            <ResultActionDispatchContext.Provider value={dispatch}>
                {children}
            </ResultActionDispatchContext.Provider>
        </ResultActionStateContext.Provider>
    )
}

export const useResultActionState = () => {
    const context = useContext(ResultActionStateContext);
    if (undefined === context) {
        throw new Error('useResultActionState must be used within a ResultActionProvider');
    } else {
        return context;
    }
}

export const useResultActionDispatch = () => {
    const context = useContext(ResultActionDispatchContext);
    if (undefined === context) {
        throw new Error('useResultActionDispatch must be used within a ResultActionProvider');
    } else {
        return context;
    }
}
