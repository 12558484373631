import {useTranslation} from "react-i18next";
import {FoldersCheckboxFilter} from "./FoldersCheckboxFilter";

/**
 * Renders the FilterCreatedBy component with CheckboxFilter.
 *
 * @param {function} callback - The callback function to handle filter changes.
 * @return {JSX.Element} CheckboxFilter component with specified props.
 */
export const FilterCreatedBy = ({callback}) => {

    const {t} = useTranslation('dams.folder', {useSuspense: false});
    return <FoldersCheckboxFilter title={t("createdBy", "Opprettet av")}
                                  fqFacetKey={'created_by_name'}
                                  resultFacetKey={'createdByName'}
                                  callback={callback}/>;
};