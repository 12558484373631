import {makeStyles} from "@mui/styles";

/**
 * Styles used by ResultCardProjectIcon.js and ArcheologyProjectFileIcon.js
 * @type object
 */
export const useStyles = makeStyles(() => ({
    fileTypeContainer: {
        position: "absolute",
        bottom: 60,
        right: 5,
    },
    fileThumbContainer: {
        backgroundColor: "#efefef",
        textAlign: "center ",
        width: '100%',
        height: 140,
    },
}));