/**
 * List of project types available in DAMS.
 *
 * NOTE:
 * - ProjectArcheology: uploaded objects are joined to a specific set of metadata for this project type.
 * - ProjectGeneral: uploaded objects uses the "default" set of metadata found in DAMS.
 *
 * ProjectArcheology and ProjectGeneral has a different set of metadata at the project level.
 * @type {string[]}
 */
export const damsProjectTypes = [
    'ProjectArcheology',
    'ProjectGeneral'
];