import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";

export const loadPhases = async () => {
    return await _loadData({
        'data_type': 'phases'
    });
};

export const loadSourceFolders = async () => {
    return await _loadData({'data_type': 'sourcefolder'});
};

export const loadInitiators = async () => {
    return await _loadData({'data_type': 'initiator'});
};

export const loadNumAddedFiles = async (jobId = null) => {
    return await _loadData({'data_type': 'numaddedfiles'}, jobId);
};

export const loadNumUploadedFiles = async (jobId = null) => {
    return await _loadData({'data_type': 'numuploadedfiles'}, jobId);
};

export const loadNumCreatedObjects = async (jobId = null) => {
    return await _loadData({'data_type': 'numcreatedobjects'}, jobId);
};

export const loadNumUpdatedKeywords = async (jobId = null) => {
    return await _loadData({'data_type': 'numupdatedkeywords'});
};

export const loadNumFailedObjects = async () => {
    return await _loadData({'data_type': 'numfailedobjects'});
};

export const loadScheduledJobs = async () => {
    return await damsFetch('/scheduler/all');
};

const _loadData = async (criterias, jobId) => {
    if (typeof (jobId) !== 'undefined') {
        criterias['job_id'] = jobId;
    }
    const options = {
        method: 'POST',
        body: JSON.stringify(decamelizeKeysDeep(criterias)),
    };
    return damsFetch('/jobs/all/', options);
};