import React, {useEffect, useState} from "react";
import {MuseumSelector} from "../museum/MuseumSelector";
import {SearchResultDetailsProvider} from "../search-result-details/SearchResultDetailsContext";
import {
    ADD_FQ,
    CLEAR_FQ,
    SET_QUERY,
    SET_ROWS,
    SET_SEARCHING,
    SET_START,
    UPDATE_FQ,
    useSearchDispatch,
    useSearchState,
} from "../search/SearchContext";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {useProjectTranslation} from "./projectContext";
import Box from "@mui/material/Box";
import {ProjectsSearchResults} from "./ProjectsSearchResults";
import {projectsSearch} from "./projectsSearch";
import {FolderSearchSettings} from "../search/FolderSearchSettings";
import {SearchSettings} from "../search/SearchSettings";
import {useFoldersState} from "../folder/FoldersContext";

export const ProjectsLandingPage = () => {
    const t = useProjectTranslation();
    const searchDispatch = useSearchDispatch();
    const {fq} = useSearchState();
    const {sort} = useFoldersState();

    const [inputValue, setInputValue] = useState("");
    const [data, setData] = useState();

    // Fetching the current museum from saved search config.
    const searchConfig = JSON.parse(localStorage.getItem('dams.searchResults.config'));
    const selectedMuseum = searchConfig?.selectedMuseum || [];

    const searchSettings = SearchSettings('dams.searchResults.config');
    const folderSearchSettings = FolderSearchSettings('dams.folderSearchResults.config');

    const [rows, setRows] = useState(folderSearchSettings.getNumberOfRows());
    const [startRow, setStartRow] = useState(0);
    const [folderCount, setFolderCount] = useState(0);
    const [page, setPage] = useState(0);

    /**
     * Method used to fetch the list of Projects/folders.
     */
    const searchForProjects = () => {
        projectsSearch({
            query: "*",
            start: startRow,
            rows: rows,
            sort: sort,
            folderType: "(ProjectArcheology ProjectGeneral)",
            museums: selectedMuseum,
            searching: true
        }).then(json => {
            setData({
                count: json['count'],
                models: json['models']
            });
        })
    };

    /**
     * Method used to add parameters required to fetch detailed data about each project.
     */
    const setProjectSearchParams = () => {
        searchDispatch({type: SET_SEARCHING});

        const folderIds = data?.models.map(m => m.id);
        if (!folderIds || folderIds.length === 0) {
            // A "search" has still been run, even though there are no folder specified.
            return;
        }

        searchDispatch({
            type: SET_START,
            start: startRow,
        });

        if (!Array.isArray(fq) && Object.keys(fq).length === 0) {
            // Only clear FQ if no filters have been selected.
            searchDispatch({type: CLEAR_FQ});
        }

        if (!Array.isArray(fq) && Object.keys(fq).length > 0) {
            // Keep selected filter values, and add them to FQ.
            searchDispatch({
                type: UPDATE_FQ,
                fq: fq
            });
        } else {
            // Add initial FQ-value.
            searchDispatch({
                type: ADD_FQ,
                fq: {key: "folder_ids", value: folderIds.join(" ")},
            });
        }

        searchDispatch({
            type: SET_QUERY,
            query: "*",
        });
    };

    const searchOnEnter = (event) => {
        if ("Enter" === event.key) {
            let query = inputValue;
            if (query === "") {
                query = "*";
            }
            searchDispatch({
                type: SET_START,
                start: 0,
            });
            searchDispatch({type: CLEAR_FQ});
            searchDispatch({
                type: SET_QUERY,
                query: query,
                folderContext: false,
            });
            searchDispatch({
                type: SET_SEARCHING
            });
        }
    };

    // Needed by pagination in ProjectsSearchResults:
    const handlePageChange = (_event, newPage, projects = false) => {
        const value = newPage * rows;
        setPage(newPage);

        if (projects) {
            setStartRow(value);
        } else {
            searchDispatch({
                type: SET_START,
                start: value,
            });
        }
    };

    const handleChangeRowsPerPage = (event, projects = false) => {
        const value = event.target.value;

        if (projects) {
            folderSearchSettings.setNumberOfRows(value);
            setRows(value);
        } else {
            searchSettings.setNumberOfRows(value);
            searchDispatch({
                type: SET_ROWS,
                rows: value,
            });
        }
    };

    /**
     * Hook that initiates the search for projects.
     */
    useEffect(() => {
        searchForProjects();
    }, [rows, startRow]);

    /**
     * Hook that initiates the search for project details.
     */
    useEffect(() => {
        if (!data) {
            return;
        }
        setFolderCount(data['count']);
        setProjectSearchParams();
    }, [data]);

    return <Box sx={{
        flexGrow: 1,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
    }}>


        <Box sx={{
            display: "flex",
            flexDirection: {
                xs: "column-reverse",
                sm: "row",
            },
            alignItems: "center",
            justifyContent: "center",
            gap: {
                xs: '.5rem',
                md: '1rem',
            },
            marginBlock: {
                xs: '.25rem',
                sm: '.5rem',
                md: '1rem',
            },
            marginInline: '1rem',
            paddingBlock: 2,

        }}>
            <TextField
                id="dams-search"
                label={t('searchProjects', 'Søk i prosjekter')}
                type="search"
                variant="filled"
                onKeyDown={searchOnEnter}
                value={inputValue}
                onChange={(event) => setInputValue(event.target.value)}
                fullWidth={true}
                sx={{
                    flexGrow: 1,
                    maxWidth: {
                        md: '60%',
                        lg: '50%',
                        xl: '38rem',

                    },
                }}

            />
            <Box sx={{
                display: "flex",
                alignItems: "center",
            }}>
                <Typography variant={"body2"} noWrap sx={{marginRight: {xs: ".5rem", sm: "0"}}}>
                    {t("for", "For:")}
                </Typography>
                <MuseumSelector showWithProjectAccessOnly={true}/>
            </Box>
        </Box>


        <SearchResultDetailsProvider>
            {data?.count >= 0 && <ProjectsSearchResults handlePageChange={handlePageChange}
                                                        handleChangeRowsPerPage={handleChangeRowsPerPage}
                                                        folderCount={folderCount} page={page}/>}
        </SearchResultDetailsProvider>
    </Box>
};