import React, {useEffect, useRef, useState} from "react";
import {alpha, styled} from "@mui/material/styles";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import {Scroller} from "../app/Scroller";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import {InputBase, useTheme} from "@mui/material";
import {SET_QUERY, SET_SEARCHING, useFoldersDispatch,} from "../folder/FoldersContext";
import {useGetUploadFolders} from "../folder/useGetUploadFolders";

const PREFIX = "AvailableFolders";

const classes = {
    uploadedFiles: `${PREFIX}-uploadedFiles`,
    droppableFoldersDivider: `${PREFIX}-droppableFoldersDivider`,
    droppableFoldersToolbar: `${PREFIX}-droppableFoldersToolbar`,
};

const StyledPaper = styled(Paper)(({theme}) => ({
    [`&.${classes.uploadedFiles}`]: {
        padding: theme.spacing(4),
        height: "100%",
    },

    [`& .${classes.droppableFoldersDivider}`]: {
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.droppableFoldersToolbar}`]: {
        paddingLeft: 0,
    },
}));

export const AvailableFolders = ({children}) => {
    const headerRef = useRef();

    const [headerHeight, setHeaderHeight] = useState(0);
    const [inputValue, setInputValue] = useState("");

    const t = useNewFilesTranslation();
    const theme = useTheme();
    const foldersDispatch = useFoldersDispatch();

    useGetUploadFolders({fileUploaderV2: false});

    useEffect(() => {
        if (headerRef.current) {
            setHeaderHeight(headerRef.current['clientHeight']);
        }
    }, [setHeaderHeight]);

    const searchOnEnter = (event) => {
        if ("Enter" === event.key) {
            let q = "*" + inputValue + "*";
            if (!inputValue || inputValue === "") {
                q = "*";
            }
            foldersDispatch({
                type: SET_QUERY,
                query: q,
                folderContext: true,
                onlyMine: true,
            });
            foldersDispatch({type: SET_SEARCHING});
        }
    };

    return (
        <StyledPaper className={classes.uploadedFiles}>
            <div ref={headerRef}>
                <Toolbar className={classes.droppableFoldersToolbar}>
                    <Typography variant={"h6"} component={"h2"}>
                        {t("folders", "Mapper")}
                    </Typography>
                    <Box flexGrow={1}/>
                    <Box
                        sx={{
                            position: "relative",
                            borderRadius: theme.shape.borderRadius,
                            backgroundColor: alpha(theme.palette.common.black, 0.05),
                            "&:hover": {
                                backgroundColor: alpha(theme.palette.common.black, 0.1),
                            },
                            marginRight: theme.spacing(2),
                            marginLeft: 0,
                            width: "100%",
                            [theme.breakpoints.up("sm")]: {
                                marginLeft: theme.spacing(3),
                                width: "auto",
                            },
                        }}
                    >
                        <Box
                            sx={{
                                padding: theme.spacing(0, 2),
                                height: "100%",
                                position: "absolute",
                                pointerEvents: "none",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <SearchIcon/>
                        </Box>
                        <InputBase
                            sx={{
                                color: "inherit",
                                "& .MuiInputBase-input": {
                                    padding: theme.spacing(1, 1, 1, 0),
                                    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
                                    transition: theme.transitions.create("width"),
                                    width: "100%",
                                    [theme.breakpoints.up("md")]: {
                                        width: "20ch",
                                    },
                                },
                            }}
                            placeholder={t('placeholderSearch', 'Søk') + '...'}
                            inputProps={{"aria-label": "søk"}}
                            onKeyDown={searchOnEnter}
                            value={inputValue}
                            onChange={(event) => setInputValue(event.target.value)}
                        />
                    </Box>
                </Toolbar>
                <Divider className={classes.droppableFoldersDivider}/>
            </div>
            <Scroller subtractFromHeight={headerHeight}>{children}</Scroller>
        </StyledPaper>
    );
};
