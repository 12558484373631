import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {UploadProgress} from "./UploadProgress";
import {LoadThumbProgress} from "./LoadThumbProgress";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import {FileCardActionMenu} from "./FileCardActionMenu";
import {FileCardPreview} from "./FileCardPreview";
import {REMOVE_FILE, RETRY_FILE, useDocumentDispatch,} from "../documents/documentContext";
import {REMOVE_FILE_PROGRESS, useDmsDispatch, useDmsState} from "../dms/dmsContext";
import {FileCardErrorIcon} from "./FileCardErrorIcon";
import {If} from "../app/If";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import {getFileSizeInMB} from "../utility";
import useDeepCompareEffect from "use-deep-compare-effect";
import {clientLog} from "../clientLog";

const PREFIX = "FileCard";

const classes = {
    root: `${PREFIX}-root`,
    media: `${PREFIX}-media`,
    fileUploadProgress: `${PREFIX}-fileUploadProgress`,
    fileThumbContainer: `${PREFIX}-fileThumbContainer`,
    fileThumbIcon: `${PREFIX}-fileThumbIcon`,
};

const StyledCard = styled(Card)(({theme}) => ({
    [`&.${classes.root}`]: {
        backgroundColor: theme.palette.grey[100],
        minWidth: "200px",
        maxWidth: "200px",
    },

    [`& .${classes.media}`]: {
        height: 140,
    },

    [`& .${classes.fileUploadProgress}`]: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },

    [`& .${classes.fileThumbContainer}`]: {
        textAlign: "center",
    },

    [`& .${classes.fileThumbIcon}`]: {
        fontSize: 160,
        color: "#bbbbbb",
    },
}));

export const FileCard = ({file, progress}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const documentDispatch = useDocumentDispatch();
    const {progressByFile, urls} = useDmsState();
    const dmsDispatch = useDmsDispatch();
    const t = useNewFilesTranslation();

    const [showMediaCard, setShowMediaCard] = useState(false);
    const [showAudioThumb, setShowAudioThumb] = useState(false);

    useEffect(() => {
        setShowAudioThumb(file.mimeType.indexOf("audio") > -1);

        if (
            file.mimeType.indexOf("image") > -1 ||
            file.mimeType.indexOf("pdf") > -1 ||
            file.mimeType.indexOf("video") > -1
        ) {
            setShowMediaCard(true);
        } else {
            setShowMediaCard(false);
            setImageLoaded(true);
        }
    }, [file.mimeType]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShowPreview = () => {
        handleClose();
        setShowPreview(true);
    };

    const handleRemove = () => {
        handleClose();
        documentDispatch({
            type: REMOVE_FILE,
            title: file.title,
        });
        dmsDispatch({
            type: REMOVE_FILE_PROGRESS,
            title: file.title,
            dmsId: file.dmsId,
        });
    };

    const handleRetry = () => {
        handleClose();
        documentDispatch({
            type: RETRY_FILE,
            title: file.title,
        });
    };

    const uploadFailed = () => -1 === progress;

    /**
     * Returns a boolean value, that is used to block the retry-option
     * until all files currently being uploaded are complete in order to avoid duplicate uploads.
     *
     * See: #DE-584 for details
     * @returns {boolean}
     */
    const isUploadComplete = () => {
        const numFiles = Object.keys(progressByFile).length;
        let failed = 0;
        let successful = 0;

        for (const [k, v] of Object.entries(progressByFile)) { // NOSONAR
            if (v === -1) {
                failed++;
            } else {
                successful++;
            }
        }
        return numFiles === (failed + successful);
    };

    const previewOrRetry = () => {
        switch (progress) {
            case -1:
                return (
                    <MenuItem onClick={handleRetry}>
                        {t("retryUpload", "Last opp på ny")}
                    </MenuItem>
                );
            case 100:
                return (
                    <MenuItem onClick={handleShowPreview}>
                        {t("preview", "Forhåndsvisning")}
                    </MenuItem>
                );
        }
    };

    const getImageUrl = () => {
        return file.thumbnailUrl;
    };

    const hasFailed = () => {
        return urls[file.dmsId]?.failed;
    }

    const loadImageWithRetry = async (retries = 100, delay = 5000) => {
        const url = getImageUrl();

        if (!url) {
            return;
        }

        try {
            return await new Promise((resolve, reject) => {
                const img = new Image();

                img.onerror = () => {
                    if (retries > 0) {
                        // Retry loading the image after a delay.
                        setTimeout(() => loadImageWithRetry(retries - 1, delay), delay);
                    } else {
                        // All retries have failed.
                        reject(new Error(`Failed to load image: ${url}`));
                    }
                };

                img.onload = () => {
                    setThumbnail(img.src);
                    setImageLoaded(true);
                    resolve();
                };

                img.src = url;
            });
        } catch (error) {
            clientLog('error', error, 'FileCard');
            return null;
        }
    };

    const [thumbnail, setThumbnail] = useState('/no-thumbnail.jpg');

    useDeepCompareEffect(() => {
        if (!file.thumbnailUrl) {
            return;
        }
        loadImageWithRetry().then();
    }, [file]);


    return (
        <StyledCard className={classes.root} elevation={3} key={file.dmsId}>
            <CardActionArea onClick={handleClick}>
                <If boolean={showMediaCard && isUploadComplete() && thumbnail}>
                    <CardMedia
                        component={"img"}
                        className={classes.media}
                        image={thumbnail}
                        title={file.title}
                        alt={file.dmsId}
                    />
                </If>
                <If boolean={!showMediaCard && showAudioThumb}>
                    <div className={classes.fileThumbContainer}>
                        <VolumeUpOutlinedIcon className={classes.fileThumbIcon}/>
                    </div>
                </If>
                <If boolean={!showMediaCard && !showAudioThumb}>
                    <div className={classes.fileThumbContainer}>
                        <DescriptionOutlinedIcon className={classes.fileThumbIcon}/>
                    </div>
                </If>
                <LoadThumbProgress hide={imageLoaded || progress < 100 || hasFailed()}/>
                <UploadProgress progress={progress}/>
                <FileCardErrorIcon show={uploadFailed()}/>
                <CardContent>
                    <Typography gutterBottom variant="caption" noWrap>
                        {file.title}
                    </Typography>
                    <Typography variant="caption" color="textSecondary" component="p">
                        {getFileSizeInMB(file)} MB
                    </Typography>
                </CardContent>
            </CardActionArea>
            {isUploadComplete() && (
                <FileCardActionMenu anchorEl={anchorEl} handleClose={handleClose}>
                    {previewOrRetry()}
                    <MenuItem onClick={handleRemove}>{t("remove", "Fjern")}</MenuItem>
                </FileCardActionMenu>
            )}
            <FileCardPreview
                showPreview={showPreview}
                setShowPreview={setShowPreview}
            >
                <img src={getImageUrl()} alt={file.title} className={classes.img}/>
            </FileCardPreview>
        </StyledCard>
    );
};
