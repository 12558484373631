import {useProjectViewState} from "../archeology/projectview/projectViewContext";
import {useAuthsState} from "../../auths/authsContext";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {useCanEditInCollections} from "../../auths/useCanEditInCollections";
import {isProjectActive} from "../projectUtility";

export const ButtonGeneralProjectAdmin = () => {
    const navigate = useNavigate();
    const data = useProjectViewState();

    const {project} = data;
    const {collectionId, createdById, id} = project;

    // The user's ID in DAMS
    const {damsId} = useAuthsState();

    const isMyProject = createdById === damsId;

    const museumAdmin = useCanEditInCollections({collectionIds: [collectionId]});

    const projectActive = isProjectActive(project);

    const handleButtonClick = () => {
        navigate(`/project/admin/${id}`);
    };

    return <IconButton disabled={!projectActive || (!isMyProject && !museumAdmin)}
                       onClick={handleButtonClick}
                       sx={{
                           color: "white",
                           "&:hover": {color: "orange"},
                       }}>
        <EditIcon/>
    </IconButton>;
};