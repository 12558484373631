import React from "react";
import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material//Paper";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

const PREFIX = "ModalProgressIndicator";

const classes = {
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
    progress: `${PREFIX}-progress`,
};

export const ModalProgressIndicator = ({pending, total}) => {
    const progress = () => ((total - pending) / total) * 100;

    return (
        <Modal
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
            open={pending > 0}
            onClose={() => null}
            closeAfterTransition
        >
            <Fade
                in={pending > 0}
                style={{
                    transitionDelay: "300ms",
                }}
                unmountOnExit={true}
            >
                <Paper className={classes.paper}
                       sx={{
                           minWidth: "60%",
                           minHeight: 300,
                           outline: "none",
                           display: "flex",
                           flexDirection: "column",
                           alignItems: "center",
                           justifyContent: "center",
                           padding: '32px'
                       }}>
                    <Typography>{pending} dokumenter gjenstår</Typography>
                    <LinearProgress
                        variant="determinate"
                        value={parseInt(progress())}
                        sx={{
                            width: '100%'
                        }}
                        className={classes.progress}
                    />
                </Paper>
            </Fade>
        </Modal>
    );
};
