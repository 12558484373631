import React, {useEffect, useState} from "react";
import {useAuthsState} from "../auths/authsContext";
import {kitFetch} from "@ekultur/fetch";
import camelcaseKeysDeep from "camelcase-keys-deep";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {useField, useFormikContext} from "formik";
import Checkbox from "@mui/material/Checkbox";

const ekulturFetch = (url, options) => {
    const ekulturUrl = window._env_.REACT_APP_API_GATEWAY
    return kitFetch(`${ekulturUrl}${url}`, options)
        .then(json => Promise.resolve(camelcaseKeysDeep(json)))
};

async function getUsers(museumId) {
    const url = `/auths/cura/museums/${museumId}/applications/${window._env_.REACT_APP_ID}/`;
    const users = await ekulturFetch(url);
    return users.map(u => {
        return {
            id: u.uuid,
            name: `${u.name} (${u.username})`,
            fullName: u.name,
            email: u.username
        };
    });
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

/**
 * Autocomplete with multiple-select functionality, that displays a list of users related to the current museum.
 * @returns {JSX.Element}
 * @constructor
 */
export const FormikSelectDamsUsers = ({formikKey, label, placeholder, museumId}) => {
    const [field] = useField(formikKey);

    const {setFieldValue} = useFormikContext();
    const {userData} = useAuthsState();

    const [users, setUsers] = useState([]);

    /**
     * Hook used to download a list of users related to the current museum.
     */
    useEffect(() => {
        if (!museumId) {
            return;
        }

        getUsers(museumId)
            .then((users) => {
                const sortedUsers = users.sort((s, ss) => {
                    if (s.name > ss.name) {
                        return 1;
                    } else if (s.name < ss.name) {
                        return -1;
                    }
                    return 0;
                });
                setUsers(sortedUsers);
            });

    }, [museumId, userData]);

    return (
        <Autocomplete
            multiple
            id="project-responsible"
            options={users}
            defaultValue={field.value}
            disableCloseOnSelect
            onChange={(event, newOpts) => {
                setFieldValue(field.name, newOpts).then();
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, {selected}) => {
                const liProps = {...props, key: option.id};
                return <li {...liProps} >
                    <Checkbox
                        key={option.uuid}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.name}
                </li>;
            }}
            style={{width: 500}}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} variant={"standard"}/>
            )}
        />
    );
};