import {MetadataRow} from "../MetadataRow";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {copyrightTypeValueToHr} from "../../copyright/FormikSelectCopyrightType";
import {format} from "../../app/dateFormat";
import {CopyrightTypeOriginators} from "./CopyrightTypeOriginators";
import {CopyrightTypeResponsiblePersons} from "./CopyrightTypeResponsiblePersons";

/**
 * Component that displays the copyright type information.
 * @param copyrightType str copyright
 * @param copyrightTypeOriginator object [
 *     {
 *         "reference": {
 *             "id": 9,
 *             "title": "Andersson, Benny, 1946-.... (1946 - )",
 *             "locale": "no",
 *             "source": "Personer VIAF mm",
 *             "status": "published",
 *             "schemaId": 1,
 *             "sourceId": "7e7e35c7-c1bd-44e3-ad03-4ab63613b18f",
 *             "uniqueId": "503105e4-77fb-49b7-8a44-d404ec70e770",
 *             "createdAt": null,
 *             "updatedAt": null,
 *             "documentType": "person",
 *             "nameAndTitle": "Andersson, Benny, 1946-.... (1946 - )"
 *         },
 *         "collectionId": 3,
 *         "referenceType": "person"
 *     }
 * ]
 * @param copyrightTypeResponsible object
 * [
 *     {
 *         "reference": {
 *             "id": 10,
 *             "title": "Birkenes, Knut Eriksen (1878 - 1967)",
 *             "locale": "no",
 *             "source": "Personregister i SAMLA (Universitetet i Bergen)",
 *             "status": "published",
 *             "schemaId": 1,
 *             "sourceId": "a9b59d11-04d6-4e00-ad1b-fdfaa3494e7c",
 *             "uniqueId": "c217df41-56ad-4867-adfe-f5378009c3da",
 *             "createdAt": null,
 *             "updatedAt": null,
 *             "documentType": "person",
 *             "nameAndTitle": "Birkenes, Knut Eriksen (1878 - 1967) Samler "
 *         },
 *         "collectionId": 3,
 *         "referenceType": "person"
 *     }
 * ]
 * @param copyrightTypeDateUntil str 2025-03-20T00:00:00.000Z
 * @param t
 * @returns {JSX.Element}
 * @constructor
 */
export const CopyrightTypeItem = ({
                                      copyrightType,
                                      copyrightTypeOriginator,
                                      copyrightTypeResponsible,
                                      copyrightTypeDateUntil,
                                      t
                                  }) => {
    return <MetadataRow label={t('lblOriginator', 'Opphavsrett')} key={crypto.randomUUID()}>
        <Stack>
            <Typography
                variant={"caption"}>{copyrightTypeValueToHr(t, copyrightType)}</Typography>
            {copyrightTypeOriginator && <CopyrightTypeOriginators t={t} models={copyrightTypeOriginator}/>}
            {copyrightTypeResponsible && <CopyrightTypeResponsiblePersons t={t} models={copyrightTypeResponsible}/>}
            <Typography variant={"caption"}>
                {t('lblValidUntil', 'Gyldig til')}: {format(copyrightTypeDateUntil, 'P') || t('lblNotSelected', ' - Ikke oppgitt')}
            </Typography>
        </Stack>
    </MetadataRow>;
};