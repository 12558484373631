import {useEffect, useState} from "react";
import {useAuthsState,} from "../auths/authsContext";
import {ADD_MESSAGE, useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {useMyMuseums} from "../museum/useMyMuseums";
import {useDmsStatusState} from "./DmsStatusContext";
import {kickOffDmsStatusWorker} from "../webworkers/kickOffDmsStatusWorker";
import {clientLog} from "../clientLog";

export const DmsStatus = () => {

    const componentName = 'DmsStatus';

    const t = useSnackbarTranslation();

    const {userIsAuthenticated} = useAuthsState();
    const {dmsStatus} = useDmsStatusState();

    const snackbarDispatch = useSnackbarDispatch();
    const myMuseums = useMyMuseums({});

    const [dmsStatusDisplayed, setDmsStatusDisplayed] = useState({
        warning: false,
        errors: false,
    });

    const showError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("snackbarDamsErrorTitle", "DAMS feil - kontakt support"),
                body: t(
                    "snackbarDamsErrorMessage",
                    "DAMS lagringsområde ikke tilgjengelig - kontakt support."
                ),
                type: "error",
            },
        });
        setDmsStatusDisplayed({
            ...dmsStatusDisplayed,
            errors: true,
        });
    };

    const showWarning = (museumIdentifiers) => {
        const message =
            t(
                "damsWarningMessage",
                "DAMS lagringsområde kan ikke nås for disse museene: "
            ) +
            museumIdentifiers?.map((m) => getMuseumNameFromIdentifier(m)).join(", ");
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("snackbarDamsErrorTitle", "DAMS feil - kontakt support"),
                body: message,
                type: "warning",
            },
        });
        setDmsStatusDisplayed({
            ...dmsStatusDisplayed,
            warning: true,
        });
    };

    const getMuseumNameFromIdentifier = (identifier) => {
        if (!myMuseums || myMuseums.length === 0) {
            return;
        }
        return myMuseums.find((m) => m['ekultur_id'] === identifier)?.name;
    };

    useEffect(() => {
        if (userIsAuthenticated && myMuseums.length > 0) {
            kickOffDmsStatusWorker();
        }
    }, [userIsAuthenticated, myMuseums]);

    useEffect(() => {
        if (!userIsAuthenticated || !myMuseums || myMuseums.length === 0) {
            return;
        }

        // If requesting configs. fails, log the error and alert the user.
        if (dmsStatus.errorCount > 0 && !dmsStatusDisplayed.errors) {
            dmsStatus.errors.forEach(error => {
                clientLog('error', error, componentName);
            });
            clientLog('error', 'DAMS storage is unavailable - contact support', componentName);
            showError();
        }

        // If missing museum configs. nag user and log to console.
        if (dmsStatus['missingConfigs']?.length > 0 && !dmsStatusDisplayed.warning) {
            for (let i = 0, max = dmsStatus['missingConfigs'].length; i < max; i++) {
                clientLog('warn',
                    'missing config for: ' + dmsStatus['missingConfigs'][i],
                    componentName);
            }
            showWarning(dmsStatus['missingConfigs']);
        }
    });
};
