import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {DialogWrapper} from "../../app/DialogWrapper";
import Typography from "@mui/material/Typography";
import React from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

/**
 * Renders a dialog component for when the user has no access to any museum.
 *
 * @param {Object} t - Translation function
 * @param {boolean} openDialog - Flag to indicate if the dialog is open
 * @return {JSX.Element} Dialog component for displaying the no access message
 */
const NoAccessDialog = ({t, openDialog}) => {
    const navigate = useNavigate();

    const dialogActions = () => {
        return <DialogActions>
            <Button onClick={() => {
                navigate('/');
            }}>
                {t('selectMuseumDialogBtnClose', 'Lukk')}
            </Button>
        </DialogActions>;
    };

    return <DialogWrapper openDialog={openDialog}
                          closeHandler={() => {
                              navigate('/');
                          }}
                          dialogActions={dialogActions}>
        <Typography>
            {t('selectMuseumDialogUnknownError', 'Beklager - det har oppstått en ukjent feil.')}
        </Typography>
        <Typography>
            {t('selectMuseumDialogRedirectFrontpage', 'Oppgaven kan ikke gjennomføres, vi tar deg til DAMS forside.')}
        </Typography>
    </DialogWrapper>;
};

NoAccessDialog.propTypes = {
    t: PropTypes.func.isRequired,
    openDialog: PropTypes.bool.isRequired
};

export {NoAccessDialog};