import React from "react";
import {Header, WithHeader} from "@ekultur/header-microfrontend";
import {useAuthsDispatch, useAuthsState, USER_LOGGED_OUT,} from "../auths/authsContext";
import {login, logout} from "@ekultur/authentication";
import {useLocation, useNavigate} from "react-router-dom";
import {MENU_OPENED, RENDERED, useAppDispatch, useAppState} from "../app/AppContext";
import {themeOptions} from "../app/Theme";
import {SearchSettings} from "../search/SearchSettings";

const classes = {
    hamburger: "Header-hamburger",
};

export const AppHeader = () => {
    const {userIsAuthenticated} = useAuthsState();
    const dispatchAuths = useAuthsDispatch();
    const appDispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const {externalUser} = useAppState();

    const containerId = "header--container";

    const loginHandler = () => {
        const prevPath =
            location.state && location.state.previousPath
                ? location.state.previousPath
                : "/";
        login({
            locationPostLogin: prevPath,
            loginHost: window._env_.REACT_APP_LOGIN_HOST,
        });
    };

    const logoutHandler = () => {
        SearchSettings('dams.searchResults.config').logout();
        logout({
            onLogout: () => dispatchAuths({type: USER_LOGGED_OUT}),
        });
    };
    return (
        <WithHeader>
            <Header
                appId={window._env_.REACT_APP_ID}
                containerId={containerId}
                loggedIn={userIsAuthenticated}
                mobileOpenHandler={() => appDispatch({type: MENU_OPENED})}
                logoutHandler={() => logoutHandler()}
                loginHandler={loginHandler}
                theme={themeOptions}
                preselectMuseumId={null}
                mobileOpen={false}
                onRender={() => appDispatch({type: RENDERED})}
                className={classes}
                version={window._env_.REACT_APP_VERSION}
                search={{
                    handler: (searchString) => {
                        if (!searchString || searchString === "") {
                            navigate(`/search/`, {});
                        } else if (searchString) {
                            navigate(`/search/${searchString}`);
                        }
                    },
                }}
                showNoAccessPlaceholder={!externalUser}
            />
        </WithHeader>
    );
};
