import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";

export const UPDATE_PROGRESS = 'createFolderContext/updateProgress';
export const FOLDER_CREATED = 'createFolderContext/folderCreated';
export const CREATE_FOLDER_STATE_RESET = 'createFolderContext/createFolderStateReset';

const CreateFolderContext = createContext(undefined);
const CreateFolderDispatchContext = createContext(undefined);
const CreateFolderTranslationContext = createContext(undefined);

const initialState = {
    progress: 0,
    folderCreated: false,

};

const createFolderReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_PROGRESS:
            return {
                ...state,
                progress: action.percentage
            };
        case FOLDER_CREATED:
            return {
                ...state,
                folderCreated: action.created
            };
        case CREATE_FOLDER_STATE_RESET:
            return initialState;
        default:
            break;
    }
};

export const CreateFolderProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        createFolderReducer,
        {...initialState},
        undefined
    );
    const {t} = useTranslation("dams.createFolder", {useSuspense: false});

    return (
        <CreateFolderTranslationContext.Provider value={t}>
            <CreateFolderContext.Provider value={state}>
                <CreateFolderDispatchContext.Provider value={dispatch}>
                    {children}
                </CreateFolderDispatchContext.Provider>
            </CreateFolderContext.Provider>
        </CreateFolderTranslationContext.Provider>
    );
};

export const useCreateFolderState = () => {
    const context = useContext(CreateFolderContext);
    if (undefined === context) {
        throw new Error(
            "useCreateFolderState must be used within a CreateFolderProvider."
        );
    } else {
        return context;
    }
};

export const useCreateFolderDispatch = () => {
    const context = useContext(CreateFolderDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useCreateFolderDispatch must be used within a CreateFolderProvider."
        );
    } else {
        return context;
    }
};

export const useCreateFolderTranslation = () => {
    const context = useContext(CreateFolderTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useCreateFolderTranslation must be used within a CreateFolderProvider."
        );
    } else {
        return context;
    }
};
