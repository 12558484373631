import {useEffect, useState} from "react";
import {getMimeTypeFromFilename} from "../damsFileObjectDefinitions";

/**
 * Hook used to decide which thumbnail to display when uploading a file.
 * @param file obj  The uploaded file.
 * @returns {{showAudioThumb: boolean, showUnknownThumb: boolean}}
 */
export const useDecideThumbnailType = ({file}) => {
    const [showUnknownThumb, setShowUnknownThumb] = useState(false);
    const [showAudioThumb, setShowAudioThumb] = useState(false);

    useEffect(() => {
        if (!file) {
            return;
        }
        let mimeType = getMimeTypeFromFilename(file.name);
        setShowUnknownThumb(true);
        setShowAudioThumb(mimeType.indexOf("audio") > -1);
        if (
            mimeType.indexOf("image") > -1 ||
            mimeType.indexOf("pdf") > -1 ||
            mimeType.indexOf("video") > -1 ||
            mimeType.indexOf("audio") > -1
        ) {
            setShowUnknownThumb(false);
        }
    }, [file]);

    return {showUnknownThumb, showAudioThumb};
};