import React from "react";
import {FoldersProvider} from "../../folder/FoldersContext";
import {FolderContents} from "../../folder/FolderContents";
import {DocumentTypeProvider} from "../../document-type/documentTypeContext";
import {SearchProvider} from "../../search/SearchContext";
import {ProjectsLandingPage} from "../../project/ProjectsLandingPage";
import {DocumentProvider} from "../../documents/documentContext";

export const FolderByIdRoute = () => {
    return (
        <FoldersProvider>
            <DocumentTypeProvider>
                <FolderContents/>
            </DocumentTypeProvider>
        </FoldersProvider>
    );
};

export const AllProjectsRoute = () => {
    return (
        <FoldersProvider>
            <SearchProvider>
                <DocumentProvider>
                    <DocumentTypeProvider>
                        <ProjectsLandingPage/>
                    </DocumentTypeProvider>
                </DocumentProvider>
            </SearchProvider>
        </FoldersProvider>
    );
};
