import React, {useEffect, useState} from "react";
import {Checkbox} from "@mui/material";
import {TOGGLE_ALL_SELECTED, TOGGLE_SELECTED, useFileUploadDispatch, useFileUploadState} from "./fileUploadContext";


import {FileGridItemBase} from "./FileGridItemBase";

/**
 * HOC used when presenting an uploaded file, ready for metadata edit.
 * @param WrappedComponent
 * @returns {function(*): *}
 */
function withEditSupport(WrappedComponent) {
    return function WithEditSupport(props) {

        const {allSelected, selected} = useFileUploadState();
        const fileUploadDispatch = useFileUploadDispatch();

        const [locallySelected, setLocallySelected] = useState(false);
        const [isRaised, setIsRaised] = useState(true);

        const {model} = props;

        /**
         * If current model is a zip-file, mark it as such.
         * @returns {boolean}
         */
        const isZipFile = () => {
            return model.content.mediae[0].reference.fileExtension === 'zip';
        };

        const handleCheck = (_event) => {
            setLocallySelected(!locallySelected);
            if (allSelected) {
                fileUploadDispatch({
                    type: TOGGLE_ALL_SELECTED
                });
            }
            fileUploadDispatch({
                type: TOGGLE_SELECTED,
                uniqueId: model.uniqueId
            });
        };

        const isSelected = () => {
            if (allSelected) {
                return true;
            } else {
                return selected.includes(model.uniqueId);
            }
        };

        const getCheckbox = () => {
            let style = {
                zIndex: 20,
                backgroundColor: "rgba(255,255,255, 0.6)",
            };

            return <Checkbox
                sx={style}
                disableRipple
                checked={isSelected()}
                onClick={handleCheck}
                onChange={(event) => !event.target.checked}
                value={model.uniqueId}
                color="secondary"
            />;
        };

        /**
         * Hook used to lower/rise the card, based on whether the current object is selected or not.
         */
        useEffect(() => {
            setIsRaised(!selected.includes(model.uniqueId));
        }, [selected]);

        return <WrappedComponent {...props} raised={isRaised} checkBox={getCheckbox()} isZipFile={isZipFile()}/>;
    };
};

export const FileGridItem = withEditSupport(FileGridItemBase);
