import React from "react";
import {IconButton, Tooltip,} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import PropTypes from "prop-types";

const TableViewButton = ({onToggle, viewMode}) => {
    const color = () => ("table" === viewMode ? "primary" : "default");

    return (
        <Tooltip title="Vis liste">
            <IconButton
                aria-label={"choose-list-view"}
                onClick={() => onToggle("table")}
                color={color()}
                size="large"
            >
                <ViewListIcon/>
            </IconButton>
        </Tooltip>
    );
};

TableViewButton.propTypes = {
    "onToggle": PropTypes.func.isRequired,
    "viewMode": PropTypes.string.isRequired
};

export {TableViewButton};

