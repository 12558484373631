import React from "react";
import Box from "@mui/material/Box";

export const ResultGrid = ({children}) => {


    return (
        <Box sx={{
            padding: {
                xs: 2
            },
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
            gridAutoRows: 'max-content',
            gap: {
                xs: '.5rem',
                sm: '1rem',
            },
            minHeight: '0',
            flexShrink: 1,
            flexGrow: 1,
            flexBasis: 0,
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarColor: '#009688 #ddd',
            scrollbarWidth: 'thin'
        }}>
            {children.map((child) => (
                <Box key={child.props.model.documentType + "_" + child.key}>
                    {child}
                </Box>
            ))}
        </Box>
    );
};
