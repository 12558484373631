import React, {useMemo, useState} from "react";
import {damsFetch} from "../../../app/damsFetch";
import {Autocomplete, CircularProgress, debounce, TextField,} from "@mui/material";
import {useProjectTranslation} from "../../projectContext";

/**
 *  AutoComplete field, used to search for an existing project on arkeologi.org.
 * @param id
 * @param language
 * @param metadataCallback
 * @param disabled
 * @returns {JSX.Element}
 * @constructor
 */
export const AutoCompleteArcheologyProjectSearch = ({
                                                        id,
                                                        language,
                                                        metadataCallback,
                                                        disabled,
                                                    }) => {
    const t = useProjectTranslation();
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    const debounceMs = 300;

    const searchHandler = (params, callback) => {
        damsFetch("/projects/archeology/", {
            method: "POST",
            body: JSON.stringify(params),
        }).then(callback);
    };

    const onChangeHandler = (_event, option) => {
        if (option?.value) {
            setOpen(false);
            const params = {
                action: "get-project-metadata",
                entityId: option.value,
            };
            searchHandler(params, metadataCallback);
        }
    };

    const debouncedChangeHandler = useMemo(
        () => debounce(onChangeHandler, debounceMs),
        []
    );

    const onInputChangeHandler = (event, value) => {
        if (event.type !== "change") {
            return;
        }
        setOptions([]);
        setOpen(false);
        setLoading(true);

        const params = {
            action: "get-investigations",
            //  Support for this parameter has been removed from backend/gateway.
            //  ownerId: ownerId,
            entityName: value,
        };

        const callback = (results) => {
            const projects = results.data.map((d) => {
                const name = d.caption[language] || d.caption["*"];
                let investigationType = "";
                if (Object.keys(d.properties).includes('investigationType')) {
                    if (d.properties['investigationType'].length > 0) {
                        investigationType = d.properties["investigationType"][0]['displayValue'][language] || "";
                    }
                }
                const title = name + " " + investigationType;
                return {
                    title: title,
                    value: d.uuid,
                };
            });
            setOptions(projects);
            setLoading(false);
            setOpen(true);
        };

        searchHandler(params, callback);
    };

    const debouncedInputChangeHandler = useMemo(
        () => debounce(onInputChangeHandler, debounceMs),
        []
    );

    return (
        (<Autocomplete
            id={id}
            disabled={disabled}
            open={open}
            onOpen={() => {
                setOpen(true);
            }}
            getOptionLabel={(option) => option.title}
            noOptionsText={t('txtNoHits', '-- Ingen treff --')}
            options={options}
            loading={loading}
            loadingText={t('txtLoading', 'Laster...')}
            size={"small"}
            onChange={debouncedChangeHandler}
            onInputChange={debouncedInputChangeHandler}
            renderInput={(params) => (
                <TextField
                    id={"searchCriteria"}
                    {...params}
                    label={t('searchForProject', 'Søk etter prosjekt på navn eller nummer')}
                    helperText={t('searchForProjectHelpText', '(Kilde: arkeologi.org)')}
                    color={"secondary"}
                    variant={"standard"}
                    slotProps={{
                        input: {
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? (
                                        <CircularProgress color="inherit" size={20} disableShrink={true}/>
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }
                    }}
                />
            )}
            sx={{width: "50%", marginTop: '16px'}}
        />)
    );
};
