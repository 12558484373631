import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useDocumentTranslation} from "../documents/documentContext";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";
import PreviewIcon from '@mui/icons-material/Preview';
import PropTypes from "prop-types";
import {getMimeTypeFromModel} from "../utility";

/**
 * Button displayed inside the object viewer page, when loading a model originated from a .glb file.
 * @param model object  The model data
 * @param showViewerCallback    method  The callback called when displaying the 3D viewer.
 * @returns {JSX.Element}
 * @constructor
 */
const OpenIn3DButton = ({model, showViewerCallback}) => {
    const {editMode} = useSearchResultDetailsState();

    const [showButton, setShowButton] = useState(false);

    const t = useDocumentTranslation();

    const onClickHandler = () => {
        showViewerCallback(true);
    };

    /**
     * Hook used to determine the mime type of the current model, and whether to display the button or not.
     */
    useEffect(() => {
        const mimeType = getMimeTypeFromModel(model);

        if (!mimeType) {
            return;
        }
        const show = mimeType.indexOf('gltf') > -1;
        setShowButton(show);
    }, [model]);

    return <>
        {showButton
            && <Button
                sx={{
                    position: 'absolute'
                }}
                variant={"contained"}
                color={"primary"}
                size={"large"}
                onClick={onClickHandler}
                disabled={editMode}
                startIcon={<PreviewIcon/>}
            >
                {t('showObjectIn3D', 'Vis objekt i 3D')}
            </Button>
        }
    </>
};

OpenIn3DButton.propTypes = {
    model: PropTypes.object.isRequired,
    showViewerCallback: PropTypes.func.isRequired
};

export {OpenIn3DButton};