import {Dialog, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import {ObjectViewCloseButton} from "./ObjectViewCloseButton";
import {ThreeDViewer} from "./ThreeDViewer";
import React from "react";
import {useDocumentTranslation} from "../documents/documentContext";
import PropTypes from "prop-types";

/**
 * Dialog used when displaying the 3D viewer with the specified model.
 * @param model object  The model data
 * @param closeViewerCallback   Callback called when the user clicks the "X" at the upper right corner.
 * @param showDialog    boolean Whether to display the dialog or not.
 * @returns {JSX.Element}
 * @constructor
 */
const ThreeDViewerDialog = ({model, closeViewerCallback, showDialog}) => {

    const t = useDocumentTranslation();

    const {title} = model;

    return (
        <Dialog open={showDialog}
                PaperProps={{
                    style: {
                        height: '95vh',
                        minHeight: '95vh',
                        width: '95vw',
                        minWidth: '95vw'
                    }
                }}
        >
            <DialogTitle sx={{backgroundColor: 'rgb(103, 58, 183)', color: 'white'}}>
                <Stack direction={"row"}>
                    {t('model', 'Modell')}: {title}
                    <Box flexGrow={1}/>
                    <ObjectViewCloseButton
                        editMode={false}
                        closeDialogCallback={closeViewerCallback}
                    />
                </Stack>
            </DialogTitle>
            <DialogContent sx={{
                padding: '0 !important'
            }}>
                <ThreeDViewer model={model}/>
            </DialogContent>
        </Dialog>
    );
};

ThreeDViewerDialog.propTypes = {
    model: PropTypes.object.isRequired,
    closeViewerCallback: PropTypes.func.isRequired,
    showDialog: PropTypes.bool.isRequired
};

export {ThreeDViewerDialog};