import {damsFetch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {ADD_MESSAGE, useSnackbarDispatch, useSnackbarTranslation,} from "../../snackbar/SnackbarContext";
import {clientLog} from "../../clientLog";

/**
 * Hook used to add new documentation types to the local DAMS database, based on data fetched from data.arkeologi.org.
 * @returns {function({documentationTypes: *}): Promise<Awaited<unknown>[]>}
 */
export const useAddDocumentationType = () => {
    const componentName = 'useAddDocumentationType';

    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    /**
     * Displays an error snackbar to the user.
     */
    const showError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t(
                    "saveDocumentationTypeFailTitle",
                    "Feil ved lagring av dokumentasjonstype"
                ),
                body: t(
                    "saveDocumentationTypeFailBody",
                    "Dokumentasjonstypen ble ikke lagret."
                ),
                type: "error",
            },
        });
    };

    /**
     * Creates a documentation type object, used when saving to the local DAMS database.
     * @param collectionId  int     Collection ID
     * @param documentationType string  The name of the documentation type
     * @returns {{sourceId, documentType: string, schemaId: number, _action: string, source: string, locale: string, title, collectionId, status: string}}
     */
    const createDocumentationType = (collectionId, documentationType) => {
        return {
            collectionId: collectionId,
            schemaId: 1,
            locale: "no",
            _action: "put",
            title: documentationType.title,
            status: "published",
            source: "arkeologiserver",
            sourceId: documentationType.sourceId,
            documentType: "documentationType",
        };
    };

    /**
     * Saves the selected documentation type to the local DAMS database.
     * @param newDocumentationType  object  Documentation type object
     * @returns {*}
     */
    const saveDocumentationType = (newDocumentationType) => {
        return damsFetch(`/documents`, {
            method: "POST",
            body: JSON.stringify(decamelizeKeysDeep(newDocumentationType)),
        })
            .then((dJson) => {
                return Promise.resolve(dJson);
            })
            .catch((error) => {
                clientLog('error', error, componentName);
                showError();

                return Promise.reject(error);
            });
    }

    return ({documentationTypes}) => {
        return Promise.all(
            documentationTypes.map((documentationType) => {
                const parentDocumentationType = createDocumentationType(
                    documentationType.collectionId,
                    {
                        title: documentationType.title,
                    }
                );

                // Add documentation type parent
                return damsFetch(`/documents`, {
                    method: "POST",
                    body: JSON.stringify(decamelizeKeysDeep(parentDocumentationType)),
                })
                    .then(() => {
                        const newDocumentationType = createDocumentationType(
                            documentationType.collectionId,
                            {
                                ...documentationType,
                            }
                        );
                        return saveDocumentationType(newDocumentationType);
                    })
                    .catch((error) => {
                        clientLog('error', error, componentName);
                    });
            })
        );
    };
};
