import React, {useCallback, useState} from "react";
import {useField, useFormikContext} from "formik";
import TextField from "@mui/material/TextField";
import useDeepCompareEffect from "use-deep-compare-effect";
import {getBrowserLocale} from "../utility";
import {useAuthsState} from "../auths/authsContext";

/**
 * Component used to register the object's "production date", which may be a full date or year only.
 * @param formikKey
 * @param t
 * @param props
 * @returns {JSX.Element|null}
 * @constructor
 */
export const FormikProductionDate = ({formikKey, t, ...props}) => {
    const {userData} = useAuthsState();
    const {setFieldValue} = useFormikContext();
    const [field, meta] = useField(formikKey);

    const [productionDate, setProductionDate] = useState(field.value);
    const [error, setError] = useState(false);
    const [yearOnly, setYearOnly] = useState(false);

    // Fetch the user's locale, fallback to the browser's locale.
    const currentLocale = userData.locale || getBrowserLocale(true);
    const norwegianUser = ['no', 'nn', 'nb'].includes(currentLocale);

    const dateformatRegEx = norwegianUser
        ? /^((\d{2}.)(\d{2}.))?(\d{4})$/gi // dd.mm.yyyy
        : /^(\d{4})((-\d{2})(-\d{2}))?$/gi; // yyyy-mm-dd

    const formatDateStr = useCallback(
        (loadedDate) => {
            if (loadedDate) {
                try {
                    const d = new Date(loadedDate);
                    const month = parseInt(d.getMonth() + 1)
                        .toString()
                        .padStart(2, "0");
                    const dt = d.getDate().toString().padStart(2, "0");
                    if (norwegianUser) {
                        return dt + "." + month + "." + d.getFullYear();
                    } else {
                        return d.getFullYear() + "-" + month + "-" + dt;
                    }
                } catch {
                    const d = loadedDate.match(/^(\d{4}-\d{2}-\d{2})/gi)[0].split("-");
                    if (norwegianUser) {
                        return d[2] + "." + d[1] + "." + d[0];
                    } else {
                        return d[0] + "-" + d[1] + "-" + d[2];
                    }
                }
            } else {
                return "";
            }
        },
        [norwegianUser]
    );

    const verifyMonthThreshold = (parts) => {
        if (parts[1] > 12) {
            setError(true);
            return false;
        }
        return true;
    };

    const targetToDate = (value) => {
        const parts = norwegianUser ? value.split(".") : value.split("-");

        if (parts.length === 1) {
            // Assume year only
            setYearOnly(true);
            return new Date(Date.UTC(parts[0], 0, 1, 0, 0, 0));
        } else {
            setYearOnly(false);
            if (norwegianUser) {
                if (verifyMonthThreshold(parts)) {
                    return new Date(Date.UTC(parts[2], parts[1] - 1, parts[0], 0, 0, 0));
                } else {
                    return '';
                }
            } else {
                if (verifyMonthThreshold(parts)) {
                    return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], 0, 0, 0));
                } else {
                    return '';
                }
            }
        }
    };

    const onChange = (event) => {
        if (event.target.value === "") {
            setFieldValue(field.name, null).then();
            setError(false);
        } else if (!event.target.value.match(dateformatRegEx)) {
            setError(true);
        } else {
            const d = targetToDate(event.target.value);
            field.onChange(event.target.value);
            setFieldValue(field.name, d).then();
            setError(false);
        }
        setProductionDate(event.target.value);
    };

    useDeepCompareEffect(() => {
        if (field.value) {
            const loadedStr = field.value.toString();
            const yearOnlyMask = /^(\d{4}-01-01)T00:00:00.000Z$/gi;
            const m = loadedStr.match(yearOnlyMask);
            if (yearOnly) {
                const yr = new Date(loadedStr).getFullYear();
                setProductionDate(yr);
                field.onChange(loadedStr);
            } else if (m) {
                // Handle year-only value.
                const y = loadedStr.match(/^(\d{4})/gi)[0];
                setProductionDate(y);
                field.onChange(y);
            } else {
                // Handle full date.
                const fullDateStr = formatDateStr(loadedStr);
                setProductionDate(fullDateStr); // Sets the field value!
                field.onChange(fullDateStr);
            }
        } else if (!field.value) {
            // Resets the field's value when the form/field is reloaded/re-enabled.
            setProductionDate('');
        }
    }, [field, formatDateStr]);

    const getHelperTextAndPlaceholder = () => {
        if (norwegianUser) {
            return t("dateformatPlaceholder", "dd.mm.yyyy | yyyy");
        } else {
            return t('dateFormatPlaceHolderNonNorwegian', 'yyyy-mm-dd | yyyy');
        }
    };

    if (undefined === meta.initialValue) {
        return null;
    } else {
        return (
            (<TextField
                id={field.name}
                name={field.name}
                variant={"outlined"}
                error={error}
                value={productionDate}
                onChange={onChange}
                onBlur={field.onBlur}
                helperText={getHelperTextAndPlaceholder()}
                placeholder={getHelperTextAndPlaceholder()}
                {...props}
                slotProps={{
                    htmlInput: {pattern: "\\d{0,2}.?\\d{0,2}.?\\d{4}"}
                }}/>)
        );
    }
};
