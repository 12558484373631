import React from "react";
import {KulturNavSearch} from "../kulturnav/KulturNavSearch";
import {fieldValuesWithCollectionIds,} from "../metadata/inputMapper";
import {useAddSubject} from "./useAddSubject";
import {useField, useFormikContext} from "formik";
import {useDocumentState} from "../documents/documentContext";
import {useAuthsState} from "../auths/authsContext";
import SubjectIcon from "@mui/icons-material/Subject";
import {mapNotMissing} from "../inpututils";
import {subjectsSearchHandler} from "./subjectsSearchHandler";
import {getBrowserLocale} from "../utility";
import PropTypes from "prop-types";

const InputSubjects = ({
                           t,
                           formikKey,
                           label,
                           disabled = false,
                           size = null,
                           projectCollectionId = null
                       }) => {
    const [field, meta] = useField(formikKey);
    const {setFieldValue} = useFormikContext();
    const locale = getBrowserLocale();
    const postSubjects = useAddSubject();
    const {collectionIds} = useDocumentState();
    const {museumCollections} = useAuthsState();

    // NOTE: When editing data for a project, only one collectionID is selected, and is sent directly to this component.
    const currentCollectionIds = collectionIds.length === 0 ? [projectCollectionId] : collectionIds;

    const museums = museumCollections
        .filter((museumCollection) =>
            currentCollectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    const onChange = (subjects) => setFieldValue(field.name, subjects);

    const onAdd = (subjects) => {
        onChange([...field.value, ...subjects]);
    };

    const postMissing = (value) => {
        const subjects = currentCollectionIds
            .filter((collectionId) => !value.collectionId.includes(collectionId))
            .map((collectionId) => ({
                ...value,
                collectionId: collectionId,
            }));
        return postSubjects({subjects: subjects});
    };

    const changeHandler = (values) => {
        const createExternalSubject = values.find((subject) => !subject.uniqueId);
        if (createExternalSubject) {
            const newSubjects = currentCollectionIds.map((collectionId) => ({
                ...createExternalSubject,
                collectionId: collectionId,
            }));
            postSubjects({subjects: newSubjects}).then(onAdd);
        } else {
            const existingSubjects = values
                .map((v) => mapNotMissing(currentCollectionIds, v, "subject"))
                .flat();
            Promise.all(values.map(postMissing)).then((newSubjects) =>
                onChange([...existingSubjects, ...newSubjects.flat()])
            );
        }
    };

    const getValue = () => {
        if (field.value) {
            if (!Array.isArray(field.value)) {
                return [];
            } else {
                return fieldValuesWithCollectionIds(field.value);
            }
        } else {
            return [];
        }
    };

    if (undefined === meta.initialValue || !currentCollectionIds.length) {
        return null;
    }

    return (
        <KulturNavSearch
            id={"search-knav-subjects"}
            searchHandler={(value, callback) => {
                subjectsSearchHandler(value, museums, locale, callback)
            }}
            onChange={changeHandler}
            onClear={() => onChange([])}
            disabled={disabled}
            inputLabel={label}
            helperText={t('subjectsHelp', 'Bruk flertall. Eks.: hester')}
            Icon={SubjectIcon}
            value={getValue()}
            size={size}
        />
    );
};

InputSubjects.propTypes = {
    "t": PropTypes.func.isRequired,
    "formikKey": PropTypes.string.isRequired,
    "label": PropTypes.string.isRequired,
    "disabled": PropTypes.bool,
    "size": PropTypes.string,
    "projectCollectionId": PropTypes.number
};

export {InputSubjects};