import React, {useEffect, useRef, useState} from "react";
import {CHOOSE_COLLECTIONS, useDocumentDispatch} from "./documentContext";
import {BatchEditStepper} from "./BatchEditStepper";
import {useBatchEditState} from "./batchEditContext";
import {BatchEditSelectFields} from "./BatchEditSelectFields";
import {BatchEditForm} from "./BatchEditForm";
import {FormMetadata} from "../metadata/FormMetadata";
import {BatchEditActions} from "./BatchEditActions";
import {Scroller} from "../app/Scroller";
import useDeepCompareEffect from "use-deep-compare-effect";

export const BatchEdit = ({models, onHide, onComplete}) => {
    const [toolbarHeight, setToolbarHeight] = useState(0);
    const {activeStepIndex} = useBatchEditState();
    const dispatch = useDocumentDispatch();
    const toolbarRef = useRef();
    const toolbarTopRef = useRef();

    useEffect(() => {
        let topBarHeight = 0;
        let bottomBarHeight = 0;
        if (toolbarTopRef.current) {
            topBarHeight = toolbarTopRef.current['clientHeight'];
        }

        if (toolbarRef.current) {
            bottomBarHeight = toolbarRef.current['clientHeight'];
        }

        if (toolbarTopRef.current && toolbarRef.current) {
            setToolbarHeight(topBarHeight + bottomBarHeight);
        }
    }, [activeStepIndex]);

    useDeepCompareEffect(() => {
        if (0 < models.length) {
            dispatch({
                type: CHOOSE_COLLECTIONS,
                collectionIds: [...new Set(models.map((model) => model.collectionId))],
            });
        }
    }, [models]);

    const step =
        0 === activeStepIndex ? (
            <BatchEditSelectFields onCancel={onHide}/>
        ) : (
            <BatchEditForm models={models} onComplete={onComplete}>
                <Scroller subtractFromHeight={toolbarHeight}>
                    <FormMetadata batchEdit={true}/>
                </Scroller>

                <div ref={toolbarRef}>
                    <BatchEditActions
                        onCancel={onHide}
                        numberOfDocuments={models.length}
                    />
                </div>
            </BatchEditForm>
        );

    return (
        <>
            <div ref={toolbarTopRef}>
                <BatchEditStepper/>
            </div>
            {step}
        </>
    );
};
