import {useState} from "react";
import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {clientLog} from "../clientLog";

/**
 * Hook used to add a document to a folder, by updating the list of folderIds on the document object itself.
 * @param folderId  int The ID of the folder that the document will be added to.
 * @returns {[{success: number, pending: number, error: number, failingIds: *[]},post,clearAll]}
 */
export const usePostFolderDocument = (folderId) => {

    /**
     * State object, used to keep track of pending, added and failed documents,
     * when adding multiple documents to a folder.
     */
    const [response, setResponse] = useState({
        error: 0,
        pending: 0,
        success: 0,
        failingIds: [],
    });

    /**
     * Updates the specified document with the new folder information.
     * @param documentId    str The ID of the document being updates.
     */
    const post = ({documentId}) => {
        _updatePendingDocuments();
        return _getDocument(documentId);
    };

    /**
     * Clears the state object.
     */
    const clearAll = () => {
        setResponse({
            error: 0,
            pending: 0,
            success: 0,
            failingIds: []
        });
    }

    /**
     * Updates the list of folder IDs that is assigned to the document object.
     * @param folderIds
     * @param fId
     * @returns {[...*,undefined]|[undefined]}
     */
    const _updatedFolderIds = (folderIds, fId) => {
        return folderIds ? [...folderIds, fId] : [fId];
    };

    /**
     * Updates the number of pending documents.
     */
    const _updatePendingDocuments = () => {
        setResponse((r) => {
            return {
                ...r,
                pending: r.pending + 1,
            };
        });
    };

    /**
     * Updates the number of successfully added documents.
     */
    const _updateSuccessfulDocuments = () => {
        setResponse((r) => {
            return {
                ...r,
                pending: r.pending - 1,
                success: r.success + 1,
                error: 0,
                failingIds: [],
            };
        });
    };

    /**
     * Updates the number of failed documents.
     * @param documentId int
     * @private
     */
    const _updateFailedDocuments = (documentId) => {
        setResponse((r) => {
            return {
                ...r,
                pending: r.pending - 1,
                error: r.error + 1,
                failingIds: [...r.failingIds, documentId],
            }
        });
    };

    /**
     * Adds the updated folder information to the document.
     * NOTE: This is the part that specifies that the document is part of a new folder.
     * @param json  object  The JSON-data that represents the document.
     * @returns {Promise<Response>}
     */
    const _saveUpdatedFolder = async (json) => {
        const body = JSON.stringify(
            decamelizeKeysDeep({
                ...json,
                content: {
                    ...json['content'],
                },
                folderIds: _updatedFolderIds(json['folderIds'], folderId),
            })
        );
        return damsFetch(`/documents`, {
            method: "POST",
            body: body
        });
    };

    const _getDocument = async (documentId) => {
        try {
            const doc = await damsFetch(`/documents/${documentId}`);
            await _saveUpdatedFolder(doc);
            _updateSuccessfulDocuments();
            return {'uniqueId': documentId, 'success': true};
        } catch (error) {
            clientLog('error', error, 'usePostFolderDocument');
            _updateFailedDocuments(documentId);
            return {'uniqueId': documentId, 'success': false};
        }
    };

    return [response, post, clearAll];
};
