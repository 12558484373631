/**
 * Method used to "remodel" data from the DAMS-database to data usable by the GUI-components
 * that are dynamically rendered.
 * @param copyrightInfo
 * @param initialValues
 */
export const setCopyrightResponsibleValues = (copyrightInfo, initialValues) => {
    if (!copyrightInfo) {
        return;
    }
    const numCopyrights = copyrightInfo.length;
    for (let i = 0; i < numCopyrights; i++) {
        const fieldName = `copyrightResponsible${i}`;
        initialValues[fieldName] = copyrightInfo[i].responsible;
    }
};


/**
 * Method used to "remodel" data from the DAMS-database to the data usable by the GUI-components
 * that are dynamically rendered.
 * @param copyrightInfo
 * @param initialValues
 */
export const setCopyrightClauseDates = (copyrightInfo, initialValues) => {
    if (!copyrightInfo) {
        return;
    }

    const numCopyrightInfo = copyrightInfo.length;

    for (let i = 0; i < numCopyrightInfo; i++) {
        initialValues[`copyrightFromDate${i}`] = copyrightInfo[i]['fromDate'];
        initialValues[`copyrightToDate${i}`] = copyrightInfo[i]['toDate'];
    }
};


/**
 * Appends copyright information to the document object.
 * @param document
 * @param obj
 */
export const appendCopyrights = (document, obj) => {
    if (!document.content.copyrightInfo) {
        return;
    }

    // Append copyright clause to the correct nodes on the initialValues object.
    const numCopyrights = document.content.copyrightInfo.length;
    for (let a = 0; a < numCopyrights; a++) {
        obj[`copyrightClause${a}`] = document.content.copyrightInfo[a];
        obj[`copyrightFromDate${a}`] = document.content.copyrightInfo[a]['fromDate'];
        obj[`copyrightToDate${a}`] = document.content.copyrightInfo[a]['toDate'];
        obj[`copyrightResponsible${a}`] = document.content.copyrightInfo[a]['responsible'];
    }
};


/**
 * Appends license information to the document object.
 * @param document
 * @param obj
 */
export const appendLicenses = (document, obj) => {
    if (!document.content.licenses) {
        return;
    }

    const {content} = document;
    const {licenses} = content;

    // Append license information to the correct nodes on the initialValues object.
    const numLicenses = licenses[0] !== undefined && licenses.length
        ? licenses.length : 0;
    for (let i = 0; i < numLicenses; i++) {
        obj[`license${i}`] = licenses[i];
        obj[`licensesAssociated${i}`] = licenses[i].responsible;
    }
};