import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsSearch} from "../../app/damsFetch";
import {clientLog} from "../../clientLog";

/**
 * Method used to search for tags saved in the DAMS database.
 * @param museums   Array[int]  List of the museums.
 * @param tagTitle  string  The name of the tag to search for.
 * @param tagType   string  The type of tag, "foldertag" is the only one supported for now.
 * @returns {Promise<*|*[]>}
 */
export const tagsSearchHandler = async (museums, tagTitle = '*', tagType = 'foldertag') => {
    const searchParams = new URLSearchParams(
        decamelizeKeysDeep({
            q: `_title_:${tagTitle}`,
            fl: "title,id",
            sort: "title asc",
            expand: true,
            documentType: `(${tagType})`,
            museums: museums.join(","),
        })
    );

    try {
        const res = await damsSearch(searchParams);
        if (res.count > 0) {
            return res.models;
        } else {
            return [];
        }
    } catch (e) {
        clientLog('error', 'failed to load saved tags', 'tagsearchhandler');
        return [];
    }
};
