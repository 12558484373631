import {format} from '../app/dateFormat';
import Chip from "@mui/material/Chip";

export const getTableColumns = () => [
    {
        id: 'title',
        disablePadding: true,
        getLabel: t => t('title', 'Navn'),
        disableSorting: false,
        cellProps: {component: "th", scope: "row", padding: "none"},
        getValue: row => row.title,
        defaultHidden: false
    },
    {
        id: 'museum',
        disablePadding: false,
        getLabel: t => t('museumName', 'Museum'),
        disableSorting: true,
        getValue: row => row.collectionId
    },
    {
        id: 'status',
        disablePadding: false,
        getLabel: t => t('status', 'Privat'),
        disableSorting: false,
        getValue: (row, t) => row.status === 'private' ? t('yes', 'Ja') : t('no', 'Nei'),
        defaultHidden: false
    },
    {
        id: 'createdByName',
        disablePadding: false,
        getLabel: t => t('createdBy', 'Opprettet av'),
        disableSorting: false,
        getValue: row => row.createdByName,
        defaultHidden: false
    },
    {
        id: 'createdAt',
        disablePadding: false,
        getLabel: t => t('uplodadedAt', 'Opplastet dato'),
        disableSorting: false,
        getValue: row => format(row.createdAt, 'P'),
        defaultHidden: false
    },
    {
        id: 'updatedAt',
        disablePadding: false,
        getLabel: t => t('modifiedAt', 'Dato endret'),
        disableSorting: false,
        getValue: row => format(row.updatedAt, 'P'),
        defaultHidden: false
    },
    {
        id: 'uniqueId',
        disablePadding: false,
        getLabel: t => t('damsId', 'DAMSID'),
        disableSorting: false,
        getValue: row => row.uniqueId,
        defaultHidden: true
    },
    {
        id: 'tags',
        disablePadding: false,
        getLabel: t => t('tags', 'Tags'),
        disableSorting: true,
        getValue: row => {
            if (!row.content.tags) {
                return;
            }
            return row.content.tags.map(tag => (<Chip key={`chip-${row.uniqueId}-${tag.reference.uniqueId}`}
                                                      sx={{marginLeft: '8px'}}
                                                      label={tag?.reference?.title}
                                                      color={"secondary"}/>));
        },
        defaultHidden: false
    }
]
