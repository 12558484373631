import React, {useState} from "react";
import {KulturNavSearch} from "../kulturnav/KulturNavSearch";
import {
    concatWithExisting,
    excludeThoseAlreadyListedAsKulturnav,
    fieldValuesWithCollectionIds,
    sourceToUniqueId,
} from "../metadata/inputMapper";
import {FormNewPerson} from "./FormNewPerson";
import {useAddPerson} from "./useAddPerson";
import {useField, useFormikContext} from "formik";
import {ModalCreateResource} from "../metadata/ModalCreateResource";
import {ModalCreateResourceHeader} from "../metadata/ModalCreateResourceHeader";
import {useDocumentState} from "../documents/documentContext";
import {useAuthsState} from "../auths/authsContext";
import PersonIcon from "@mui/icons-material/Person";
import {getBrowserLocale} from "../utility";
import {fetchPersonsFromDamsDb} from "./localDbHelper";
import {changeHandler, fetchKulturNavData} from "./personhelper";

export const InputProducer = ({
                                  formikKey,
                                  label,
                                  createLabel,
                                  disabled = false,
                                  size = null,
                                  helperText = null,
                                  projectCollectionId = null,
                              }) => {
    const [field, meta] = useField(formikKey);
    const {setFieldValue} = useFormikContext();
    const locale = getBrowserLocale();
    const [newPerson, setNewPerson] = useState(null);
    const postPersons = useAddPerson();
    const {collectionIds} = useDocumentState();
    const {museumCollections} = useAuthsState();

    // NOTE: When editing data for a project, only one collectionID is selected, and is sent directly to this component.
    const currentCollectionIds = collectionIds.length === 0 ? [projectCollectionId] : collectionIds;

    const museums = museumCollections
        .filter((museumCollection) =>
            currentCollectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    const personSearchHandler = async (params, callback) => {
        // Get the persons added to the local DAMS database.
        const damsPersons = await fetchPersonsFromDamsDb(params, museums);

        // Get the persons from KulturNav.
        const kulturNavPersons = await fetchKulturNavData('Agent', params, locale);

        // Make sure each person fetched from the DAMS database has a unique ID.
        const damsLookupIds = sourceToUniqueId(damsPersons);

        // Match the resultset from KulturNav with KulturNav persons already
        // existing in the DAMS database.
        const kulturnavMapped = concatWithExisting(kulturNavPersons, damsLookupIds);

        // Return the "persons" array, containing persons that already exists in the DAMS database,
        // and who is fetched from KulturNav. The array is stripped from duplicates, as there may be persons
        // that is contained within both the DAMS- and the KulturNav search-results,
        // that has already been added to the DAMS database.
        callback([...kulturnavMapped, ...excludeThoseAlreadyListedAsKulturnav(damsPersons, kulturnavMapped)]);
    };

    const onChange = (persons) => {
        setFieldValue(field.name, persons).then();
    };

    const onAdd = (persons) => {
        setFieldValue(field.name, [...field.value, ...persons]).then();
    };

    const clearHandler = () => {
        onChange([]);
    };

    const getValue = () => {
        if (field.value) {
            return fieldValuesWithCollectionIds(field.value);
        } else {
            return [];
        }
    };

    if (undefined === meta.initialValue || !currentCollectionIds.length) {
        return null;
    } else {
        return (
            <>
                <KulturNavSearch
                    id={"search-knav-producers"}
                    searchHandler={personSearchHandler}
                    onChange={v => changeHandler(postPersons, currentCollectionIds, v, setNewPerson, onAdd, onChange)}
                    onClear={clearHandler}
                    disabled={disabled}
                    inputLabel={label}
                    Icon={PersonIcon}
                    multiple={true}
                    value={getValue()}
                    size={size}
                    helperText={helperText}
                />
                <ModalCreateResource
                    open={null !== newPerson}
                    onHide={() => setNewPerson(null)}
                >
                    <ModalCreateResourceHeader title={createLabel}/>
                    <FormNewPerson
                        person={newPerson}
                        onClose={() => setNewPerson(null)}
                        addHandler={onAdd}
                        collectionIds={currentCollectionIds}
                    />
                </ModalCreateResource>
            </>
        );
    }
};
