import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Button from "@mui/material/Button";
import {Dialog, DialogContent, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import {useState} from "react";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import {PROJECT_LOADED, useProjectViewDispatch, useProjectViewState} from "./archeology/projectview/projectViewContext";
import {useAuthsState} from "../auths/authsContext";
import {useCanEditInCollections} from "../auths/useCanEditInCollections";
import {usePostFolder} from "../folder/usePostFolder";
import {useProjectTranslation} from "./projectContext";
import {getContentSelector, isProjectActive} from "./projectUtility";


export const ButtonProjectActiveStateAdmin = () => {
    const t = useProjectTranslation();
    const {project} = useProjectViewState();
    const projectViewDispatch = useProjectViewDispatch();

    // The user's ID in DAMS
    const {damsId} = useAuthsState();


    const [open, setOpen] = useState(false);
    
    // eslint-disable-next-line no-unused-vars
    const [postFolderResponse, postFolder] = usePostFolder(); // NOSONAR

    const selector = getContentSelector(project);

    const projectActive = isProjectActive(project);

    const isMyProject = project['createdById'] === damsId;

    const museumAdmin = useCanEditInCollections({collectionIds: [project.collectionId]});

    const isButtonDisabled = () => {
        if (museumAdmin) {
            return false;
        }
        return !(isMyProject);
    };

    /**
     * Toggles, and saves the project's active state.
     * @param data  json  The project data.
     * @param isActive  boolean The current active state.
     */
    const toggleActiveState = (data, isActive) => {
        let folder = {...data};
        folder.content[selector].active = !isActive;
        folder.acls = folder.acls || [];
        postFolder(folder);
        projectViewDispatch({type: PROJECT_LOADED, project: folder});
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const handleOpenClick = () => {
        setOpen(true);
    };

    const handleCloseClick = () => {
        closeDialog();
    };

    const handleToggleClick = () => {
        toggleActiveState(project, projectActive);
        closeDialog();
    };

    return <>
        <IconButton
            disabled={isButtonDisabled()}
            onClick={handleOpenClick}
            sx={{
                color: "white",
                "&:hover": {color: "orange"},
            }}
        >
            {projectActive ? <LockOpenIcon/> : <LockIcon/>}
        </IconButton>

        <Dialog open={open}>
            <DialogContent>
                {projectActive && (<>
                    <Stack direction={"row"} marginBottom={2} alignItems={"center"}>
                        <LockOpenIcon/>
                        <Typography variant={"h6"} marginLeft={1}>{t('projectStateActive', 'Aktivt')}</Typography>
                    </Stack>
                    <Typography>{t('projectStateContribute', 'Prosjektets deltagere kan bidra med data.')}</Typography></>)
                }
                {!projectActive && (<>
                    <Stack direction={"row"} marginBottom={2}>
                        <LockIcon/>
                        <Typography variant={"h6"} marginLeft={1}>{t('projectStateInactive', 'Inaktivt')}</Typography>
                    </Stack>

                    <Typography>{t('projectStateNotContribute', 'Prosjektets deltagere har ikke mulighet til å bidra med data.')}</Typography></>)
                }
            </DialogContent>
            <DialogActions>
                <Button startIcon={projectActive ? <LockIcon/> : <LockOpenIcon/>} variant={"contained"}
                        size={"small"}
                        onClick={handleToggleClick}>{projectActive ? t('btnDeactivate', 'Deaktiver') : t('btnActivate', 'Aktiver')}</Button>
                <Button color={"secondary"} onClick={handleCloseClick}>{t('btnClose', 'Lukk')}</Button>
            </DialogActions>
        </Dialog>
    </>;
};