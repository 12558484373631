import {
    useSearchResultDetailsState,
    useSearchResultDetailsTranslation,
} from "../../search-result-details/SearchResultDetailsContext";
import React, {useEffect, useRef, useState} from "react";
import {useSearchState} from "../../search/SearchContext";
import {ObjectViewCloseButton} from "../ObjectViewCloseButton";
import {Dialog, DialogContent, Stack, useMediaQuery,} from "@mui/material";
import {ObjectViewMetaSectionWrapper} from "../ObjectViewMetaSectionWrapper";
import {ObjectViewMediaSectionWrapper} from "../ObjectViewMediaSectionWrapper";
import {useObjectViewPreviousNext} from "../useObjectViewPreviousNext";
import {useObjectViewHandlers} from "../useObjectViewHandlers";


export const ObjectViewSmallScreen = (open) => {
    const isPortrait = useMediaQuery("(orientation:portrait)");
    const t = useSearchResultDetailsTranslation();

    const {results} = useSearchState();
    const {model, editMode} = useSearchResultDetailsState();

    // The number of columns to use in "preview mode", altered to 8/5 when in editing mode.
    const [columnDefs, setColumnDefs] = useState([9, 3]);

    const dialogRef = useRef();
    const gridRef = useRef();

    const [openDialogCallback, handleOnClose, handleMediaPlayClick, openDialog] =
        useObjectViewHandlers({dialogOpen: Boolean(open)});

    const [getPreviousButton, getNextButton, setShowNavigation, setPdfOpen] =
        useObjectViewPreviousNext({
            model: model,
            results: results,
            smallScreen: true,
            openDialogCallback: openDialogCallback
        });

    const toggleEditModeOn = () => {
        setShowNavigation(false);
        setColumnDefs([7, 5]);
    };

    const toggleEditModeOff = () => {
        setColumnDefs([9, 3]);
        setShowNavigation(true);
    };

    const pdfOpenedCallback = open => {
        setPdfOpen(open);
    };

    /**
     * Hook used to toggle between edit/preview mode.
     */
    useEffect(() => {
        if (!isPortrait) {
            return;
        }

        if (!editMode) {
            toggleEditModeOff();
        } else if (editMode) {
            toggleEditModeOn();
        }

    }, [editMode, isPortrait]);

    return (
        <Dialog
            open={openDialog}
            onClose={handleOnClose}
            fullScreen={true}
            hideBackdrop={true}
            PaperProps={{
                style: {borderRadius: 5, backgroundColor: "#ddd"},
            }}
            ref={dialogRef}
        >
            <DialogContent
                sx={{
                    overflow: "hidden",
                    padding: 0,
                }}
                ref={gridRef}
            >
                <ObjectViewCloseButton
                    editMode={editMode}
                    closeDialogCallback={handleOnClose}
                />

                <Stack direction={isPortrait ? "column" : "row"}>
                    {getPreviousButton()}
                    {getNextButton()}
                    <ObjectViewMediaSectionWrapper
                        smallScreen={true}
                        columnDefs={columnDefs}
                        gridRef={gridRef}
                        t={t}
                        mediaPlayCallback={handleMediaPlayClick}
                        pdfOpenedCallback={pdfOpenedCallback}
                    />

                    <ObjectViewMetaSectionWrapper
                        dialogRef={dialogRef}
                        columnDefs={columnDefs}
                        smallScreen={true}
                        t={t}
                    />
                </Stack>
            </DialogContent>
        </Dialog>
    );
};
