import React from "react";
import {DamsForm} from "../form/DamsForm";
import {usePostDocuments} from "./usePostDocuments";
import {useBatchEditState} from "./batchEditContext";
import camelcaseKeysDeep from "camelcase-keys-deep";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useDocumentState, useDocumentTranslation} from "./documentContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import {mergeCopyrightDates, mergeCopyrightInfo, mergeLicenseInfo} from "../app/metadataUtils";

const getUniqueId = (model) => {
    return model.reference ? model.reference.uniqueId : model.uniqueId;
};

const withoutDuplicates = (models) => {
    const uniqueIds = Array.from(
        new Set(models.map((model) => getUniqueId(model)))
    );
    return uniqueIds.map((uniqueId) =>
        models.find((model) => getUniqueId(model) === uniqueId)
    );
};

const filterRootKeys = (values) => {
    const rootKeys = ["title", "description"];
    return Object.keys(values).filter((value) => rootKeys.includes(value));
};

const filterContentKeys = (values) => {
    const contentKeys = [
        "productionDate",
        "producer",
        "persons",
        "places",
        "subjects",
        "licenses",
        "remarks",
        "languages",
        "relations",
        "copyrightType"
    ];
    return Object.keys(values).filter((value) => contentKeys.includes(value));
};

const filterValueByCollection = (collectionId) => (value) => {
    if (Array.isArray(value)) {
        if (!value.some((v) => Object.keys(v).includes("collectionId"))) {
            return value;
        } else {
            return value.filter((v) => {
                return (
                    v.collectionId === collectionId ||
                    v.reference.collection_id === collectionId
                );
            });
        }
    } else {
        return value;
    }
};

const filterValues = (keysFilter, values, valueFilterer) =>
    keysFilter.reduce(
        (acc, key) => ({
            ...acc,
            [key]: valueFilterer ? valueFilterer(values[key]) : values[key],
        }),
        {}
    );

/**
 * Renders a form for batch editing of documents.
 *
 * @param {Object} props - The component props.
 * @param {Array<Object>} props.models - The original models to be edited.
 * @param {Function} props.onComplete - Callback function to be called when all documents are finished.
 * @param {ReactNode} props.children - The child components to be rendered inside the form.
 * @return {JSX.Element} The rendered form component.
 */
export const BatchEditForm = ({models, onComplete, children}) => {
    const t = useDocumentTranslation();
    // eslint-disable-next-line no-unused-vars
    const [postDocumentsResponse, postDocuments, postDocumentsInChunks] = usePostDocuments(); // NOSONAR
    const {selectedFields} = useBatchEditState();
    const {failed, saved} = useDocumentState();

    const snackbarDispatch = useSnackbarDispatch();

    const showErrorSnackbar = (message) => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("snackbarBatchEditError", "Lagring feilet"),
                body: message,
                type: "error",
            },
        });
    };

    const initialValues = {
        title: "",
        description: "",
        remarks: "",
        productionDate: null,
        producer: withoutDuplicates(
            models
                .filter((model) => model.content?.producer)
                .map((model) => model.content?.producer)
                .flat()
        ),
        persons: withoutDuplicates(
            models
                .filter((model) => model.content?.persons)
                .map((model) => model.content?.persons)
                .flat()
        ),
        places: withoutDuplicates(
            models
                .filter((model) => model.content?.places)
                .map((model) => model.content?.places)
                .flat()
        ),
        subjects: withoutDuplicates(
            models
                .filter((model) => model.content?.subjects)
                .map((model) => model.content?.subjects)
                .flat()
        ),
        licenses: withoutDuplicates(
            models
                .filter(model => model.licenses)
                .map(model => model.licenses)
                .flat()),
        relations: withoutDuplicates(
            models
                .filter((model) => model.content?.relations)
                .map((model) => model.content?.relations)
                .flat()
        ),
        languages: withoutDuplicates(
            models
                .filter((model) => model.content?.languages)
                .map((model) => model.content?.languages)
                .flat()
        ),
        copyrightInfo: withoutDuplicates(
            models
                .filter(model => model.content?.copyrightInfo)
                .map(model => model.content?.copyrightInfo)
                .flat()
        ),
        copyrightType: withoutDuplicates(
            models
                .filter(model => model.content?.copyrightType)
                .map(model => model.content?.copyrightType)
                .flat(),
        ),
        copyrightTypeDateUntil: withoutDuplicates(
            models
                .filter(model => model.content?.copyrightTypeDateUntil)
                .map(model => model.content?.copyrightTypeDateUntil)
                .flat()
        ),
        copyrightTypeOriginator: withoutDuplicates(
            models
                .filter(model => model.content?.copyrightTypeOriginator)
                .map(model => model.content?.copyrightTypeOriginator)
                .flat()
        ),
        copyrightTypeResponsible: withoutDuplicates(
            models
                .filter(model => model.content?.copyrightTypeResponsible)
                .map(model => model.content?.copyrightTypeResponsible)
                .flat()
        ),
        copyrightTerms: withoutDuplicates(
            models
                .filter(model => model.content?.copyrightTerms)
                .map(model => model.content?.copyrightTerms)
                .flat()
        ),
    };

    /**
     * Retrieves the updated model content object, based on the provided model and updated/edited values.
     *
     * @param {Object} model - The original model object.
     * @param {Object} values - The updated values object.
     * @return {Object} The updated content model.
     */
    const getUpdatedModelContent = (model, values) => {
        let content = {
            ...model.content,
            ...filterValues(
                filterContentKeys(values),
                values,
                filterValueByCollection(model.collectionId)
            ),
        };

        if (!values?.licenses && model.content?.licenses?.length > 0) {
            // NOTE: If license is not selected for update, merge existing value(s).
            content.licenses = model.content?.licenses;
        }

        if (selectedFields.includes('copyrightAndLicensing')) {
            content.copyrightTypeDateUntil = values.copyrightTypeDateUntil;
            content.copyrightTypeOriginator = values.copyrightTypeOriginator;
            content.copyrightTypeResponsible = values.copyrightTypeResponsible;
            content.copyrightInfo = values.copyrightInfo
        }

        return content;
    };

    const onSubmit = (newValues) => {
        const values = camelcaseKeysDeep(newValues);

        if (values.licenses) {
            // NOTE: Only attempt to merge license information, if it is selected for update!
            mergeLicenseInfo(values);
        }
        mergeCopyrightInfo(values);
        mergeCopyrightDates(values);

        const updatedModels = models.map((model) => {
            return {
                ...model,
                ...filterValues(filterRootKeys(values), values),
                content: getUpdatedModelContent(model, values),
            };
        });

        postDocumentsInChunks(updatedModels).then(() => {
            if (postDocumentsResponse.failedDocuments["length"] > 0) {
                showErrorSnackbar(
                    t(
                        "snackbarBatchEditErrorMsg",
                        "Lagring feilet for en eller flere dokumenter. Disse vil fortsatt være avkrysset/merket."
                    )
                );
            }
        });
    };

    useDeepCompareEffect(() => {
        if (failed.length === 0 && saved.length === 0) {
            return;
        }
        if (failed.length + saved.length === models.length) {
            // Await complete-state until all documents are finished
            onComplete(saved, failed);
        }
    }, [saved, failed]);

    const filteredInitialValues = selectedFields.reduce(
        (acc, field) => ({
            ...acc,
            [field]: initialValues[field],
        }),
        {}
    );

    // If "copyrightAndLicensing" is selected, add all files related to copyright/licencing,
    // as these are not the result of selecting an individual field, but a group of fields.
    // NOTE: Each field is added as an empty array, as fields are "cleared" when batch editing objects.
    if (selectedFields.includes('copyrightAndLicensing')) {
        filteredInitialValues.licenses = [];
        filteredInitialValues.copyrightType = [];
        filteredInitialValues.copyrightTypeDateUntil = [];
        filteredInitialValues.copyrightTypeResponsible = [];
        filteredInitialValues.copyrightTypeOriginator = [];
    }

    return <DamsForm initialValues={filteredInitialValues} onSubmit={onSubmit}>
        {children}
    </DamsForm>;

};
