import {useDocumentState, useDocumentTranslation} from "../documents/documentContext";
import React, {useEffect, useState} from "react";
import {format} from "../app/dateFormat";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";


/**
 * Tooltip content displayed when the user hovers over the "i" icon when a file has been uploaded.
 * @param file  obj The uploaded file.
 * @returns {JSX.Element}
 * @constructor
 */
export const UploadedFileInfoTooltipContent = ({file}) => {

    const t = useDocumentTranslation();
    const {saved, formData} = useDocumentState();
    const [filename, setFilename] = useState();
    const [tableData, setTableData] = useState();

    /**
     * An object holding the properties that will to be displayed within this tooltip.
     * @type {[{id: string, label: *},{id: string, label: *},{id: string, label: *},{id: string, label: *},{id: string, label: *},null,null,null,null,null,null,null]}
     */
    const props = [
        {id: "title", label: t("title", "Tittel")},
        {id: "description", label: t("description", "Beskrivelse")},
        {id: "customIdentifier", label: t("customIdentifier", "Museumsnummer")},
        {id: "productionDate", label: t("productionDate", "Produksjonsdato")},
        {
            id: "producer",
            label: t("producer", {context: formData.documentType}),
        },
        {id: "persons", label: t("persons", "Personer")},
        {id: "places", label: t("places", "Steder")},
        {id: "subjects", label: t("subjects", "Emneord")},
        {id: "languages", label: t("languages", "Språk")},
        {id: "licenses", label: t("licenses", "Lisenser")},
        {id: "relations", label: t("relations", "Relaterte objekter")},
        {id: "remarks", label: t("remarks", "Kommentar")},
    ];

    /**
     * Hook used to get then filename of the specified file.
     */
    useEffect(() => {
        if (!file) {
            return;
        }

        setFilename(file.name);
    }, [file]);

    /**
     * Hook used to fetch the file-object that matches the specified filename.
     */
    useEffect(() => {
        if (!filename) {
            return;
        }

        const file = saved.find(
            (s) => s.content.mediae[0].reference.title === filename
        );

        if (!file) {
            return;
        }

        getFileData(file);
    }, [filename]);

    /**
     * Returns values for a property that contains array-values.
     * @param fileObj   obj The file object.
     * @param key   str The name of the property (key).
     * @returns {undefined|*}
     */
    const getArrayOfValues = (fileObj, key) => {
        const d = fileObj.content[key];
        if (!d || !Array.isArray(d) || typeof (d[0]) === "undefined") {
            return undefined;
        }
        return d.map((p) => p.reference.title);
    };

    /**
     * Gets the metadata from the file object, and stores it as "label: value" in an array for use when
     * rendering the table that displays the values.
     * @param fileObj obj   The uploaded file.
     */
    const getFileData = fileObj => {
        const data = [];
        for (let i = 0, max = props.length; i < max; i++) {
            const {id, label} = props[i];

            let value = undefined;

            if (['persons', 'places', 'subjects', 'licenses', 'producer'].includes(id)) {
                value = getArrayOfValues(fileObj, id);
            } else {
                value = fileObj[id];
                if (!value) {
                    value = fileObj.content[id];
                } else if (value) {
                    if (id === "productionDate") {
                        value = format(value, "P");
                    } else if (id === "languages") {
                        value = value.map((v) => v.title);
                    }
                }
                value = Array.isArray(value) ? value.join(" ") : value;
            }

            if (value) {
                data.push(label + ": " + value);
            } else {
                data.push(label + ":");
            }
        }
        setTableData(data);
    };

    /**
     * Returns the property label element.
     * @param label str The Label
     * @returns {JSX.Element}
     */
    const getPropertyLabel = label => {
        return <Typography noWrap>
            {label + ":"}
        </Typography>;
    };

    /**
     * Returns the property value element.
     * @param value str The value.
     * @returns {JSX.Element}
     */
    const getValueAsText = (value) => {
        if (value === '') {
            return <Typography variant={"caption"} color={"secondary"}>
                {t("noData", "Ingen data")}
            </Typography>
        } else {
            return <Typography variant={"caption"}>{value}</Typography>;
        }
    };

    return <>
        <Typography
            sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
            }}
        >
            Metadata for {filename}
        </Typography>
        <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexFlow: "column",
            }}
        >
            {tableData?.map((d) => {
                const parts = d.split(":");
                const label = getPropertyLabel(parts[0]);
                let value = getValueAsText(parts[1]);
                return (
                    <Box
                        sx={{display: "flex", justifyContent: "center"}}
                        key={`m_${crypto.randomUUID()}`}
                    >
                        <Box sx={{textAlign: "right"}}>{label}</Box>
                        <Box sx={{marginLeft: 1}}>{value}</Box>
                    </Box>
                );
            })}
        </Box>
    </>;
};