import IconButton from "@mui/material/IconButton";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Typography from "@mui/material/Typography";
import React from "react";
import {
    EXIT_FULLSCREEN,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
    useSearchResultDetailsTranslation,
    VIEW_FULLSCREEN
} from "../search-result-details/SearchResultDetailsContext";

/**
 * Button used to toggle the fullscreen on/off on the object view page.
 * @param showButtonLabel
 * @returns {JSX.Element}
 * @constructor
 */
export const ToggleFullscreenButton = ({showButtonLabel}) => {
    const {fullscreen} = useSearchResultDetailsState();
    const t = useSearchResultDetailsTranslation();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();

    const toggleFullScreen = () => {
        fullscreen
            ? searchResultDetailsDispatch({type: EXIT_FULLSCREEN})
            : searchResultDetailsDispatch({type: VIEW_FULLSCREEN});
    };

    return (
        <IconButton color={"secondary"} onClick={toggleFullScreen} size="small">
            {fullscreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
            {showButtonLabel && (
                <Typography variant={"caption"} sx={{marginLeft: 1}}>
                    {fullscreen
                        ? t("bottomActionBarDialog", "Dialog")
                        : t("bottomActionBarFullscreen", "Fullskjerm")}
                </Typography>
            )}
        </IconButton>
    );

};