import Box from "@mui/material/Box";
import {FullscreenViewer} from "../image/FullscreenViewer";
import React from "react";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";

/**
 * Displays the deep zoom image in full screen on "normally sized screens".
 * @param panelDimensions
 * @returns {JSX.Element}
 * @constructor
 */
export const DialogFullscreenViewer = ({panelDimensions}) => {
    const {model} = useSearchResultDetailsState();
    return (
        <Box
            sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: panelDimensions.width + "px",
                height: panelDimensions.height + "px",
            }}
        >
            <FullscreenViewer model={model}/>
        </Box>
    );
};