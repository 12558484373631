import React, {useEffect, useMemo, useState} from "react";
import throttle from "lodash/throttle";
import {useDocumentTranslation} from "../documents/documentContext";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import {useTheme} from "@emotion/react";
import {If} from "../app/If";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import AddIcon from '@mui/icons-material/Add';

export const KulturNavSearch = ({
                                    id,
                                    searchHandler,
                                    onChange,
                                    onClear,
                                    disabled,
                                    inputLabel,
                                    Icon,
                                    value,
                                    multiple = true,
                                    helperText = null,
                                    size = null,
                                    inputVariant = 'outlined'
                                }) => {
    const theme = useTheme();
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState([]);
    const [searching, setSearching] = useState(false);
    const t = useDocumentTranslation();

    const fetchOptions = useMemo(
        () =>
            throttle((request, callback) => {
                const params = request
                    .split(" ")
                    .map((word) => `${word}*`)
                    .join(" ");
                setOptions([]);
                setSearching(true);
                searchHandler(params, (data) => callback(data));
            }, 400),
        [searchHandler]
    );

    const populateOptions = (results) => {
        const newOptions = results || [];
        setOptions(newOptions);
        setSearching(false);
    };

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions([]);
            return undefined;
        }

        fetchOptions(inputValue, (results) => {
            if (active) {
                populateOptions(results);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue]); // NO SONAR

    const changeHandler = (_event, val, reason) => {
        if ("selectOption" === reason || "removeOption" === reason) {
            onChange(val);
        } else if ("clear" === reason) {
            onClear();
        }
    };

    return (
        <Autocomplete
            id={id}
            freeSolo={true}
            handleHomeEndKeys={true}
            disabled={disabled}
            multiple={multiple}
            loading={Boolean(searching && inputValue)}
            loadingText={t("searchingPlaceholder", "Søker...")}
            getOptionLabel={(option) => option.title || ""}
            filterOptions={(opts, params) => {
                const withAdd = opts;
                if (
                    "" !== params.inputValue &&
                    !searching &&
                    !opts.map((option) => option.title).includes(params.inputValue)
                ) {
                    withAdd.push({
                        uniqueId: "",
                        title: `${params.inputValue}`,
                        create: true,
                    });
                }
                return withAdd;
            }}
            options={options}
            includeInputInList={true}
            filterSelectedOptions={true}
            isOptionEqualToValue={(option, val) => {
                return (
                    option.uniqueId !== undefined && option.uniqueId === val.uniqueId
                );
            }}
            ChipProps={{color: "secondary"}}
            value={value}
            onChange={changeHandler}
            onInputChange={(_event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    variant={inputVariant}
                    {...params}
                    label={inputLabel}
                    helperText={helperText}
                    size={size}
                />
            )}
            renderOption={(props, option) => {
                return (
                    <Grid {...props}
                          container direction={"row"}
                          key={option.sourceId || crypto.randomUUID()}
                    >
                        <If boolean={option.create}>
                            <Grid>
                                <AddIcon
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        marginRight: theme.spacing(2),
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <Typography variant="body2" color="textSecondary">
                                    {option.create ? t("createNew", "Opprett") : option.source}
                                </Typography>
                            </Grid>
                        </If>
                        <If boolean={!option.create}>
                            <Grid>
                                <Icon
                                    sx={{
                                        color: theme.palette.text.secondary,
                                        marginRight: theme.spacing(2),
                                    }}
                                />
                            </Grid>
                            <Grid>
                                <div>
                                    <Typography variant={"subtitle1"}>{option.title}</Typography>
                                </div>
                                <Box
                                    sx={{
                                        maxWidth: "400px",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        color: "secondary.main",
                                    }}
                                >
                                    <Typography
                                        variant={"caption"}
                                        color={"secondary"}
                                        noWrap
                                        component={"span"}
                                    >
                                        {option.source}
                                    </Typography>
                                </Box>
                            </Grid>
                        </If>
                    </Grid>
                );
            }}
        />
    );
};
