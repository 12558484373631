import React from "react";
import { useField } from "formik";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";

export const FormikRadioGroup = ({
  formikKey,
  label,
  disabled = false,
  children,
  ...props
}) => {
  const [field] = useField(formikKey);

  return (
    <FormControl component="fieldset" disabled={disabled}>
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup
        id={field.name}
        row
        aria-label={field.name}
        name={field.name}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        {...props}
      >
        {children}
      </RadioGroup>
    </FormControl>
  );
};
