import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import React, {useEffect, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {loadInitiators, loadPhases, loadScheduledJobs, loadSourceFolders} from "./importHelpers";
import LinearProgress from "@mui/material/LinearProgress";
import {DataTable} from "./DataTable";
import {JobDetailsDialog} from "./JobDetailsDialog";
import {getHrDateAndTime} from "../utility";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import Typography from "@mui/material/Typography";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {CreateJobDialog} from "./CreateJobDialog";
import {useDocumentTranslation} from "../documents/documentContext";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <Box
            role="tabpanel"
            hidden={value !== index}
            id={`import-tabpanel-${index}`}
            aria-labelledby={`import-tab-${index}`}
            sx={{
                padding: '16px'
            }}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </Box>
    );
}

/**
 * Renders the ImportData component, which displays a list of import jobs and allows the user to create new jobs.
 *
 * @return {JSX.Element} The rendered ImportData component.
 */
export const ImportData = () => {
    const t = useDocumentTranslation();

    const [initCreateJobDialog, setInitCreateJobDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [jobs, setJobs] = useState(null);
    const [lastUpdated, setLastUpdated] = useState(null);

    const [selectedTab, setSelectedTab] = useState(0);

    const detailsDialogRef = useRef();

    const loadJobs = async () => {
        setLoading(true);

        const jobsAndPhases = await loadPhases();
        const sourceFolders = await loadSourceFolders();
        const initiators = await loadInitiators();
        const scheduled = await loadScheduledJobs();

        // Merge data for each job into a single representation:
        const jobs = jobsAndPhases.map(j => {
            return {
                'jobId': j.jobId,
                'createdAt': j.createdAt,
                'phases': j.phases,
                'sourceFolder': sourceFolders.find(s => s.jobId === j.jobId)?.details,
                'initiator': initiators.find(i => i.jobId === j.jobId)?.details,
                'scheduleDetails': scheduled?.find(s => s.jobId === j.jobId) || {}
            }
        });

        setJobs(jobs);
        setLastUpdated(getHrDateAndTime(new Date()));
        setLoading(false);

    };

    const handleRefreshClick = () => {
        loadJobs().then();
    }

    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    const previewJob = job => {
        if (detailsDialogRef.current) {
            detailsDialogRef.current.showDetails(job);
        }
    };

    const createJob = () => {
        setInitCreateJobDialog(true);
    };

    /**
     * Hook used to, initially, load the list of jobs.
     */
    useEffect(() => {
        loadJobs().then();
    }, []);

    return <Paper sx={{width: '100%', height: '100%', marginTop: '24px', padding: '16px'}}>
        <Tabs value={selectedTab} onChange={handleTabChange} aria-label="import tabs" sx={{marginBottom: '16px'}}>
            <Tab aria-label={"import tabs"}
                 label={t('imageImportHeading', 'Bildeimport')}
            />
        </Tabs>

        {/*Tab: Image Import*/}
        <TabPanel value={selectedTab} key={"tab-panel-0"} index={0}>
            <Stack direction={"row"} sx={{marginBottom: '32px'}}>
                <Button
                    variant="contained"
                    onClick={createJob}>
                    {t('imageImportCreateJob', 'Opprett jobb')}
                </Button>
            </Stack>
            {loading && <LinearProgress color={"primary"}/>}
            {!loading && jobs && jobs.length === 0 &&
                <Box>
                    {t('imageImportNoJobsInfo', 'Ingen jobber (En jobb vises først når den er aktiv, eller har kjørt ferdig.)')}</Box>}
            {!loading && jobs && jobs.length > 0 &&
                <>
                    <DataTable t={t} jobs={jobs} previewCallback={previewJob}/>
                    <Stack direction={"row"} sx={{marginTop: '8px'}} alignItems={"center"}>
                        <Typography variant={"caption"} sx={{color: '#aaa'}}>
                            {t('imageImportLastUpdated', 'Sist oppdatert: {{lastUpdated}}',
                                {lastUpdated: lastUpdated})}
                        </Typography>
                        <IconButton onClick={handleRefreshClick}>
                            <RefreshIcon color={"secondary"}/>
                        </IconButton>
                    </Stack>
                </>
            }
        </TabPanel>
        <JobDetailsDialog t={t} ref={detailsDialogRef}/>
        {
            initCreateJobDialog
            && <CreateJobDialog
                t={t}
                callback={
                    () => {
                        loadJobs().then(() => {
                            setInitCreateJobDialog(false);
                        });
                    }
                }
            />
        }
    </Paper>;
};