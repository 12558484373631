import {ButtonDownload} from "../search-result-details/ButtonDownload";
import {ButtonShare} from "../share-model/ButtonShare";
import React from "react";
import {useSearchResultDetailsState,} from "../search-result-details/SearchResultDetailsContext";
import {Stack} from "@mui/material";
import {useObserveWindowResize} from "../app/useObserveWindowResize";
import Divider from "@mui/material/Divider";
import {ToggleFullscreenButton} from "./ToggleFullscreenButton";
import {EditButton} from "./EditButton";

/**
 * Component holding the buttons available to the bottom right, when the object view is in preview mode.
 * @returns {JSX.Element}
 * @constructor
 */
export const BottomActionBar = ({smallScreen, t}) => {
    const {model} = useSearchResultDetailsState();
    const windowDimensions = useObserveWindowResize(null);
    const showButtonLabel = windowDimensions.width > 1860; // Button labels are hidden on smaller screens

    return (
        <>
            <Divider/>
            <Stack
                spacing={1}
                direction={"row"}
                justifyContent={"space-evenly"}
                alignItems={"center"}
                pt={smallScreen ? 0 : 1}
            >
                {!smallScreen && <ToggleFullscreenButton showButtonLabel={showButtonLabel}/>}
                <EditButton showButtonLabel={showButtonLabel}/>
                <ButtonDownload model={model} showLabel={showButtonLabel}/>
                <ButtonShare t={t} model={model} showLabel={showButtonLabel}/>
            </Stack>
        </>
    );
};
