/**
 * Used to convert an input-field (array) to the structure expected when posting the data.
 * @param collectionIds
 * @param value
 * @param valueType str person|producer|place|subject|license
 */
export const mapNotMissing = (collectionIds, value, valueType) => {
    if (!Array.isArray(collectionIds) || collectionIds.length === 0) {
        throw new Error('no collectionIds specified, must be an array with at least one value');
    }

    if (!valueType) {
        throw new Error('valueType is not specified');
    }

    const {collectionId, id, uniqueId, sourceId, source, title, nameAndTitle, content} = value;

    if (!id) {
        throw new Error('argument: value.id is missing')
    }

    if (!Array.isArray(collectionId)) {
        throw new Error('argument: value.collectionId, array expected');
    }

    return collectionIds
        .filter((cId) => collectionId.includes(cId))
        .map((collectionId) => ({
            referenceType: valueType,
            collectionId: collectionId,
            _action: "put",
            reference: {
                id: id,
                uniqueId: uniqueId,
                sourceId: sourceId,
                source: source,
                title: title,
                nameAndTitle: nameAndTitle || '',
                content: content,
                status: "published",
                locale: "no",
                _action: "put",
            },
        }));
};
