import {FilterTags} from "./FilterTags";
import {SET_FQ, useFoldersDispatch, useFoldersState} from "../FoldersContext";
import Box from "@mui/material/Box";
import {FilterCreatedAt} from "./FilterCreatedAt";
import {FilterPrivatePublic} from "./FilterPrivatePublic";
import {FilterCreatedBy} from "./FilterCreatedBy";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";
import {useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";


export const FolderFilters = () => {

    const {t} = useTranslation('dams.folder', {useSuspense: false});
    const foldersDispatch = useFoldersDispatch();
    const {fq} = useFoldersState();

    const location = useLocation();

    const [hideIfUserFolders, setHideIfUserFolders] = useState(false);

    /**
     * Method used to keep the facet-filter (FQ) updated, with the selected values.
     * @param values Array [{ "key" : "filtername", "value": filtervalue}, ...]
     */
    const filterCallback = (values) => {
        // Add the new value, by first removing the value from the existing ones,
        // and merging the new one in with the rest of the filters.
        let fqUpdated;
        for (let i = 0, max = values.length; i < max; i++) {
            let value = values[i];
            const filterKey = value.key;
            const filterValue = value.value;
            const fqValues = [...fq || []];

            let existingValuesForFilterKey;
            const otherFilterValueKeys = fqValues.filter(f => (f.key !== filterKey));

            if (filterValue?.from || filterValue?.to) {
                const filterDateValue = `[${filterValue?.from} TO ${filterValue?.to}]`
                existingValuesForFilterKey = fqValues.filter(f => (f.key === filterKey && f.value !== filterDateValue));

                // Handle date periods:
                fqUpdated = [...existingValuesForFilterKey, ...otherFilterValueKeys, {
                    key: filterKey,
                    value: filterDateValue
                }];
            } else if (filterKey === 'privateOnly') {
                return;
            } else {
                // Handle "plain" filter values.
                existingValuesForFilterKey = fqValues.filter(f => (f.key === filterKey && f.value !== filterValue));
                fqUpdated = [...existingValuesForFilterKey, ...otherFilterValueKeys, value];
            }
        }

        foldersDispatch({
            type: SET_FQ,
            fq: fqUpdated
        });
    };


    /**
     * Hook used to display the filters for:
     * - created by
     * - show only private folders
     * ... only when displaying "all folders" or "shared/public folders".
     */
    useDeepCompareEffect(() => {
        setHideIfUserFolders(location.pathname === '/my-folders/');
    }, [location]);

    return <Box sx={{overflowY: "scroll", flexGrow: 1, paddingLeft: '16px'}}>
        <FilterTags callback={filterCallback}/>

        {!hideIfUserFolders &&
            <FilterCreatedBy callback={filterCallback} columnName={'createdBy'}
                             accordionTitle={t('folderFilterCreatedBy', 'Opprettet av')}/>
        }

        <FilterCreatedAt callback={filterCallback} columnName={'createdAt'}
                         accordionTitle={t('folderFilterCreated', 'Opprettet')}/>

        {!hideIfUserFolders &&
            <FilterPrivatePublic columnName={'private'}
                                 accordionTitle={t('folderFilterPrivateOnly', 'Vis kun private')}
                                 callback={filterCallback}/>
        }
    </Box>;
};