import decamelizeKeysDeep from "decamelize-keys-deep";
import {addFqParams} from "./addFqParams";


/**
 * Generates the search parameters for a folder search.
 *
 * @param {string} folderType - The type of folder to search for.
 * @param {object} params - The search parameters.
 * @param {Array} fq - The filters to apply to the search.
 * @return {URLSearchParams} The generated search parameters.
 */
export const getFolderSearchParams = (folderType, params, fq) => {
    if (folderType !== "") {
        params.folderType = folderType;
    }

    const searchParams = new URLSearchParams(decamelizeKeysDeep(params));
    addFqParams(fq, searchParams);
    return searchParams;
};