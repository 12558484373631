import React, { useEffect, useRef, useState } from "react";
import { useMyMuseums } from "./useMyMuseums";
import { SELECT_MUSEUMS, useMuseumDispatch, useMuseumState, useMuseumTranslation, } from "./MuseumContext";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ReportIcon from "@mui/icons-material/Report";
import { useDmsStatusState } from "../dmsstatus/DmsStatusContext";
import useDeepCompareEffect from "use-deep-compare-effect";
import { useAuthsState } from "../auths/authsContext";
import { SET_SEARCHING, useSearchDispatch } from "../search/SearchContext";

export const MuseumSelector = ({ disableSelectAll = false, showWithProjectAccessOnly = false }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const { museums } = useMuseumState();
    const museumDispatch = useMuseumDispatch();
    const myMuseums = useMyMuseums({});
    const t = useMuseumTranslation();
    const { dmsStatus } = useDmsStatusState();
    const { museumFeatures } = useAuthsState();

    const [currentMuseumLabel, setCurrentMuseumLabel] = useState();

    const storageKey = 'dams.searchResults.config';

    const disableSelectAllRef = useRef(disableSelectAll);

    /**
     * Returns the current search configuration from localStorage.
     * @returns {any}
     */
    const getSearchConfig = () => {
        return JSON.parse(localStorage.getItem(storageKey));
    };

    /**
     * Appends information about the selected museum to the search configuration in localStorage.
     * @param value Array   Array of IDs
     */
    const saveSearchConfig = (value) => {
        const searchConfig = getSearchConfig();
        const config = {
            ...searchConfig,
            selectedMuseum: value
        };
        localStorage.setItem(storageKey, JSON.stringify(config));
    };

    /**
     * Sets the chosen museum(s).
     * @param value
     */
    const setMuseums = (value) => {
        museumDispatch({
            type: SELECT_MUSEUMS,
            museums: value,
        });

        // Store information about the selected museum.
        if (value.length > 0) {
            saveSearchConfig(value);
        }
    };


    /**
     * Triggered when the user has selected a museum.
     * @param value
     */
    const handleSelect = (value) => {
        if (!value) {
            setMuseums(myMuseums.map((m) => m.id));
            disableSelectAllRef.current = true;
        } else {
            setMuseums(value);
            disableSelectAllRef.current = false;
        }
        handleClose();
    };

    const searchDispatch = useSearchDispatch();

    /**
     * Triggered when the user has selected a museum, and the menu closes.
     */
    const handleClose = () => {
        setAnchorEl(null);

        // Run search, when a new option is selected.
        searchDispatch({ type: SET_SEARCHING });
    };


    /**
     * Hook used to set the current choice.
     * 1. If the user has access to only one museum, this one is set.
     * 2. If a user has previously made a selection, this is retrieved and set.
     * 3. If none of the above: all museums are set.
     */
    useEffect(() => {
        function setActiveMuseum() {
            if (1 === myMuseums.length) {
                handleSelect(myMuseums.map((museum) => museum.id));
            } else {
                const searchConfig = getSearchConfig();
                const alreadySelectedMuseumId = searchConfig?.selectedMuseum;
                if (!alreadySelectedMuseumId) {
                    setMuseums(myMuseums.map((m) => m.id));
                } else {
                    setMuseums(alreadySelectedMuseumId);
                }
            }
        }

        setActiveMuseum();
    }, [myMuseums]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getAllMuseumsOption = () => {
        if (!showWithProjectAccessOnly && !disableSelectAllRef.current && myMuseums.length > 1) {
            return (
                <MenuItem onClick={() => handleSelect(null)}>
                    {t("allMyMuseums", "Alle mine museer")}
                </MenuItem>
            );
        } else {
            return null;
        }
    };

    /**
     * If "showWithProjectsAccessOnly" is activated, get a list of the museums, which has projects enabled,
     * otherwise return all the museums the user has access to.
     * @returns {*}
     */
    const getMuseumsWithProjectsEnabled = () => {
        if (showWithProjectAccessOnly) {
            return myMuseums.filter(m =>
                museumFeatures.find(mf => (mf.id === m.id && mf.features.archeology === true))
            );
        } else {
            return myMuseums;
        }
    };

    const renderMenuItems = () => {
        return getMuseumsWithProjectsEnabled().map((museum) => {
            const { id, name } = museum;
            return <MenuItem
                value={id}
                key={id}
                onClick={() => handleSelect([id])}
            >
                {name}
                {dmsStatus?.missingConfigs?.includes(museum['ekultur_id']) && (
                    <ReportIcon
                        sx={{
                            marginRight: 1,
                            color: "red",
                        }}
                    />
                )}
            </MenuItem>;
        });
    };

    /**
     * Hook used to get the current museum label.
     */
    useDeepCompareEffect(() => {
        let label;
        if (museums.length === myMuseums.length) {
            label = t('allMyMuseums', 'Alle mine museer');
        } else if (museums.length === 0) {
            label = t('chooseMuseum', 'Velg et museum');
        } else {
            label = myMuseums.find(museum => parseInt(museum.id) === parseInt(museums[0]))?.name;
        }

        setCurrentMuseumLabel(label);
    }, [museums, myMuseums, t]);


    if (!myMuseums.length) {
        return null;
    }

    return (
        museums.length > 0
        && myMuseums.length > 0
        && <>
            <Button
                aria-controls="museum-selector"
                aria-haspopup="true"
                onClick={handleClick}
                color={"secondary"}
                sx={{
                    "&.MuiButton-root": {
                        xs: {
                            padding: 0,
                        },
                        sm: {
                            padding: '.375rem .5rem',
                        },
                    },
                }}
            >
                {currentMuseumLabel}
            </Button >
            <Menu
                id="museum-selector"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {getAllMuseumsOption()}
                {dmsStatus && renderMenuItems()}
            </Menu>
        </>
    );
};
