import React, {createContext} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {DmsProvider} from "../dms/dmsContext";
import camelcaseKeysDeep from "camelcase-keys-deep";

export const SHOW = "searchResultDetailsContext/show";
export const HIDE = "searchResultDetailsContext/hide";
export const VIEW_FULLSCREEN = "searchResultDetailsContext/viewFullScreen";
export const EXIT_FULLSCREEN = "searchResultDetailsContext/exitFullScreen";
export const EDIT_MODE = "searchResultDetailsContext/editMode";
export const PREVIEW_MODE = "searchResultDetailsContext/previewModel";
export const MODEL_SAVED = "searchResultDetailsContext/modelSaved";
export const MEDIA_PLAYING = "searchResultDetailsContext/mediaPlaying";
export const MEDIA_PLAYING_NAVIGATE = 'searchResultDetailsContext/navigating';
export const SET_RELATED_OBJECTS =
    "searchResultDetailsContext/setRelatedObjects";

const SearchResultDetailsStateContext = React.createContext(undefined);
const SearchResultDetailsDispatchContext = React.createContext(undefined);
const SearchResultTranslationContext = createContext(undefined);

const initialState = {
    display: false,
    model: null,
    selectedId: null,
    fullscreen: false,
    editMode: false,
    mediaPlaying: false,
    navigating: false,
    objectRelations: [],
};

const searchResultDetailsReducer = (state, action) => {
    switch (action.type) {
        case SHOW:
            return {
                ...state,
                display: true,
                model: camelcaseKeysDeep(action.model),
                selectedId: action.model.uniqueId,
            };
        case MODEL_SAVED:
            return {
                ...state,
                model: action.model,
                selectedId: action.model.uniqueId,
                editMode: false,
            };
        case VIEW_FULLSCREEN:
            return {
                ...state,
                fullscreen: true,
            };
        case EXIT_FULLSCREEN:
            return {
                ...state,
                fullscreen: false,
            };
        case HIDE:
            return initialState;
        case EDIT_MODE:
            return {
                ...state,
                editMode: true,
            };
        case PREVIEW_MODE:
            return {
                ...state,
                editMode: false,
            };
        case MEDIA_PLAYING:
            return {
                ...state,
                mediaPlaying: action.playing,
            };
        case MEDIA_PLAYING_NAVIGATE:
            return {
                ...state,
                navigating: true,
                mediaPlaying: action.playing
            };
        case SET_RELATED_OBJECTS:
            return {
                ...state,
                objectRelations: action.objectRelations,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const SearchResultDetailsProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(
        searchResultDetailsReducer,
        {...initialState},
        undefined
    );
    const {t, ready} = useTranslation("dams.results", {useSuspense: false});

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <SearchResultTranslationContext.Provider value={t}>
            <SearchResultDetailsStateContext.Provider value={state}>
                <SearchResultDetailsDispatchContext.Provider value={dispatch}>
                    <DmsProvider>{children}</DmsProvider>
                </SearchResultDetailsDispatchContext.Provider>
            </SearchResultDetailsStateContext.Provider>
        </SearchResultTranslationContext.Provider>
    );
};

export const useSearchResultDetailsState = () => {
    const context = React.useContext(SearchResultDetailsStateContext);
    if (undefined === context) {
        throw new Error(
            "useSearchResultDetailsState must be used within a SearchResultDetailsProvider"
        );
    } else {
        return context;
    }
};

export const useSearchResultDetailsDispatch = () => {
    const context = React.useContext(SearchResultDetailsDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useSearchResultDetailsDispatch must be used within a SearchResultDetailsProvider"
        );
    } else {
        return context;
    }
};

export const useSearchResultDetailsTranslation = () => {
    const context = React.useContext(SearchResultTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useSearchResultTranslation must be used within a SearchResultDetailsProvider"
        );
    } else {
        return context;
    }
};
