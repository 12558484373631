import {
    PROJECT_SHOW_ADMIN_OVERLAY,
    useProjectViewDispatch,
    useProjectViewState
} from "./archeology/projectview/projectViewContext";
import PeopleIcon from "@mui/icons-material/People";
import IconButton from "@mui/material/IconButton";
import {useCanEditInCollections} from "../auths/useCanEditInCollections";
import {useAuthsState} from "../auths/authsContext";
import {isProjectActive} from "./projectUtility";

export const ButtonProjectMembersAdmin = () => {
    const projectViewDispatch = useProjectViewDispatch();

    const {project} = useProjectViewState();
    const {collectionId, createdById} = project;

    // The user's ID in DAMS
    const {damsId} = useAuthsState();

    const museumAdmin = useCanEditInCollections({collectionIds: [collectionId]});

    const isMyProject = createdById === damsId;

    const projectActive = isProjectActive(project);

    const handleButtonClick = () => {
        projectViewDispatch({
            type: PROJECT_SHOW_ADMIN_OVERLAY,
            project: project,
        });
    };

    return <IconButton
        disabled={!projectActive || (!isMyProject && !museumAdmin)}
        onClick={handleButtonClick}
        sx={{
            color: "white",
            "&:hover": {color: "orange"},
        }}
    >
        <PeopleIcon/>
    </IconButton>
}