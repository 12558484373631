import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {AccordionFilter} from "../AccordionFilter";
import {FilterCheckbox} from "./FilterCheckbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {AccordionFilterTitle} from "./AccordionFilterTitle";

export const FilterCreator = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const creatorKey = "created_by_name";

    // Value entered by the user in order to search for a filter-/facet value.
    const [searchValue, setSearchValue] = useState('');

    // Holds the list of options, searched for by the user.
    const [options, setOptions] = useState({});

    // Holds the selected filter-/facet values.
    const [selected, setSelected] = useState([]);

    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === creatorKey
                    )
                ) {
                    selectedFilters.push({key: creatorKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find((s) => s.value === value && s.key === creatorKey)
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === creatorKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: creatorKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(creatorKey, selectedFilters);
        },
        [callback, selected]
    );

    /**
     * Callback used when the user types the search criteria.
     * Used to filter the list of available filter-values/-options.
     * @param event
     */
    const searchForFilters = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    /**
     * Sets the list of options, based on the search-criteria entered by the user.
     * @type {(function(*): void)|*}
     */
    const getOptions = useCallback((data) => {
        const matchingKeys = (searchValue !== '' && searchValue.length >= 2)
            ? Object.keys(data)
                .filter(k => k.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1)
            : Object.keys(data);

        // Get the subjects that matches the search criteria.
        const filteredCreators = Object.fromEntries(matchingKeys.map(k => [k, data[k]]));

        // Add selected/checked filter values to the list.
        const selectedKeys = selected.map(s => (s.value));
        const selectedCreators = Object.fromEntries(selectedKeys.map(k => [k, data[k]]));

        const opts = {...filteredCreators, ...selectedCreators};
        setOptions(opts);
    }, [searchValue, selected]);


    useEffect(() => {
        if (clearFilters) {
            setSearchValue('');
            setSelected([]);
            callback(creatorKey, []);
        }
    }, [clearFilters, callback]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === creatorKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
            setSearchValue('');
        }
    }, [fq]);


    const renderCheckboxes = () => {
        return Object.keys(options).map((value) => (
            <ListItem
                key={value}
                dense={true}
                disablegutters={"true"}
                onClick={changeHandler(value)}
            >
                <ListItemIcon>
                    <FilterCheckbox
                        selected={selected}
                        subjectsKey={creatorKey}
                        value={value}
                    />
                </ListItemIcon>
                <ListItemText id={`label-${value}`}>
                    {value} ({options[value]?.count})
                </ListItemText>
            </ListItem>
        ));
    };

    const creators = results.facets.creators;
    const count = Object.keys(creators).length;

    /**
     * Hook used to display a list of subjects based on the search-criteria entered by the user.
     */
    useEffect(() => {
        getOptions(creators);
    }, [getOptions, creators]);


    if (!count) {
        return <></>;
    } else {
        return (
            <AccordionFilter
                title={
                    <AccordionFilterTitle
                        title={`${t("creators", "Opplastet av")} (${count})`}
                        value={searchValue}
                        label={t("creatorsSearch", "Søk...")}
                        setSearchValueCallback={() => {
                            setSearchValue('');
                        }}
                        onChangeCallback={searchForFilters}/>
                }
                count={count}
            >
                <List>{renderCheckboxes()}</List>
            </AccordionFilter>
        );
    }
};
