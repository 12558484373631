import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {FileDownloadsWorkerListener} from "./filedownloads/FileDownloadsWorkerListener";
import {WorkerStatusSnackBar} from "./WorkerStatusSnackBar";

export const DOWNLOADS_START = 'downloadWorker/downloadsStart';
export const DOWNLOADS_COMPLETE = 'downloadWorker/downloadsComplete';
export const STAGE_UPDATE = 'downloadWorker/stageUpdate';
export const CLEAR_JOB = 'downloadWorker/clearJob';

const DownloadWorkerState = createContext(undefined);
const DownloadWorkerDispatchContext = createContext(undefined);
const DownloadWorkerTranslationContext = createContext(undefined);

const initialState = {
    jobs: [],
};

const downloadWorkerReducer = (state, action) => {
    switch (action.type) {
        case DOWNLOADS_START:
            return {
                ...state,
                jobs: [...state.jobs, action.job],
            };
        case DOWNLOADS_COMPLETE:
            const ix = state.jobs.findIndex(j => j.jobId === action.job.jobId);
            const remainingJobs = state.jobs.toSpliced(ix, 1);
            return {
                ...state,
                jobs: [...remainingJobs, action.job]
            };
        case CLEAR_JOB:
            const jobIx = state.jobs.findIndex(j => j.jobId === action.jobId);
            const activeJobs = state.jobs.toSpliced(jobIx, 1);
            return {...state, jobs: activeJobs};
        case STAGE_UPDATE:
            const {job, stageId} = action;
            const jobs = [...state.jobs];
            const currentJobIx = jobs.findIndex(j => j.jobId === job.jobId);

            // Job exists, update specified stage.
            const currentJob = jobs[currentJobIx];
            const existingStageIndex = currentJob?.stages.findIndex(s => s.id === stageId);
            const updatedStage = job.stages.find(st => st.id === stageId);

            if (!existingStageIndex || existingStageIndex === -1) {
                // Add newly created stage
                currentJob.stages.push(updatedStage);
            } else {
                // Update existing stage
                const existingStage = currentJob.stages[existingStageIndex];
                currentJob.stages[existingStageIndex] = {
                    ...existingStage,
                    ...updatedStage
                };
            }
            jobs[currentJobIx] = currentJob;
            return {
                ...state,
                jobs: [
                    ...jobs,
                ]
            };

        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const DownloadWorkerProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        downloadWorkerReducer,
        initialState,
        undefined
    );
    const {t} = useTranslation("dams.filedownload", {useSuspense: false});

    return (
        <DownloadWorkerTranslationContext.Provider value={t}>
            <DownloadWorkerState.Provider value={state}>
                <DownloadWorkerDispatchContext.Provider value={dispatch}>
                    <FileDownloadsWorkerListener/>
                    <WorkerStatusSnackBar/>
                    {children}
                </DownloadWorkerDispatchContext.Provider>
            </DownloadWorkerState.Provider>
        </DownloadWorkerTranslationContext.Provider>
    );
};

export const useDownloadWorkerState = () => {
    const context = useContext(DownloadWorkerState);
    if (undefined === context) {
        throw new Error(
            "useDownloadWorkerState must be used within an DownloadWorkerProvider."
        );
    } else {
        return context;
    }
};

export const useDownloadWorkerDispatch = () => {
    const context = useContext(DownloadWorkerDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useDownloadWorkerDispatch must be used within an DownloadWorkerProvider."
        );
    } else {
        return context;
    }
};

export const useDownloadWorkerTranslation = () => {
    const context = useContext(DownloadWorkerTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useDownloadWorkerTranslation must be used within an DownloadWorkerProvider"
        );
    } else {
        return context;
    }
};
