import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import {tagsSearchHandler} from "./tagsSearchHandler";
import {useMyMuseums} from "../../museum/useMyMuseums";
import {useTranslation} from "react-i18next";
import {getMuseumIdFromCollectionId} from "../../utility";
import {useAuthsState} from "../../auths/authsContext";
import AddIcon from "@mui/icons-material/Add";
import {Stack} from "@mui/material";

const filter = createFilterOptions();

/**
 * Autocomplete field, used to add new tags.
 * @param formikKey
 * @param existingTags
 * @returns {JSX.Element}
 * @constructor
 */
export const InputTags = ({formikKey, existingTags = undefined}) => {

    const {t} = useTranslation('dams.folder', {useSuspense: false});
    const {museumCollections} = useAuthsState();
    const {setFieldValue, values} = useFormikContext();

    const myMuseums = useMyMuseums({});

    const [field] = useField(formikKey);

    const [tags, setTags] = useState([]);
    const [collectionId, setCollectionId] = useState(null);

    /**
     * Hook used to pick up the collection ID value.
     */
    useEffect(() => {
        setCollectionId(values.collectionId);
    }, [values]);

    /**
     * Hook used to fetch all tags saved in DAMS, for the current museum.
     */
    useEffect(() => {
        if (!myMuseums || myMuseums.length === 0 || !collectionId) {
            return;
        }

        let museums;
        if (collectionId) {
            // If a collection id has been specified, only retrieve tags related to that museum.
            museums = getMuseumIdFromCollectionId({
                museums: myMuseums,
                museumCollections: museumCollections,
                collectionId: collectionId
            });
            if (!Array.isArray(museums)) {
                museums = [museums];
            }
        } else {
            // ... otherwise, fetch tags related to the museums the user is allowed access to.
            museums = myMuseums.map(m => m.id);
        }

        tagsSearchHandler(museums)
            .then(data => {
                setTags(data);
            });
    }, [collectionId, myMuseums]);

    /**
     * Hook used to add existing tags to the list of already selected tags.
     */
    useEffect(() => {
        if (!existingTags) {
            return;
        }
        const mappedExistingTags =
            existingTags
                .filter(e => typeof (e) !== 'undefined')
                .map(t => ({
                    inputValue: t.reference.title
                }));

        setFieldValue(field.name, mappedExistingTags);
    }, [existingTags]);

    return (collectionId !== null && collectionId !== 0) && <Autocomplete
        multiple
        freeSolo
        value={field.value}
        id={'folder-tags'}
        options={tags}
        onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
                setFieldValue(field.name, newValue);
            } else if (newValue && newValue.inputValue) {
                setFieldValue(field.name, newValue.inputValue);
            } else {
                setFieldValue(field.name, newValue);
            }
        }}
        getOptionLabel={(option) => {
            if (typeof option === 'string') {
                return option;
            }
            if (option.inputValue) {
                return option.inputValue;
            }
            return option.title;
        }}
        filterOptions={(options, params) => {
            let filtered = filter(options, params);
            const {inputValue} = params;

            const exists = options.some(option => inputValue === option.title);
            if (inputValue === '') {
                // Do not display any values before the user has entered any text.
                filtered = [];
            } else if (inputValue !== '' && !exists) {
                filtered.push({
                    inputValue,
                    title: <Stack direction={"row"} alignContent={"center"}>
                        <AddIcon sx={{marginRight: '8px'}} color={"secondary"}/>
                        {inputValue}
                    </Stack>
                });
            }
            return filtered;
        }}
        renderOption={(props, option) => <li {...props}>{option.title}</li>}
        renderInput={(params) => (
            <TextField {...params}
                       variant={"standard"}
                       label={t('tags', 'Tags')}
            />
        )}
    />;
};