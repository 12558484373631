import {ModelViewer, ModelviewerProvider, WithModelviewerMicroFrontend} from "@ekultur/modelviewer-microfrontend";
import {useCallback, useEffect, useState} from "react";
import {damsFetch} from "../app/damsFetch";
import {clientLog} from "../clientLog";


export const ThreeDViewer = ({model}) => {

    const componentName = 'ThreeDViewer';

    window._env_ = {
        ...window._env_,
        MICROFRONTEND_MODELVIEWER_URL: "https://beta-modelviewer.ekultur.org"
    };

    const [modelConfig, setModelConfig] = useState();

    const {collectionId, content} = model;
    const dmsId = content.mediae[0].reference.sourceId;

    /**
     * Returns a config. object used by the model viewer.
     * NOTE:
     *    {
     *       model: {
     *          type: 'Object',
     *          url: .../file/....
     *          rotation: { x: -0.4, y: Math.PI, z: 0 },
     *       }
     *    }
     * @param url
     * @returns {{}|{model: {rotation: {x: number, y: number, z: number}, type: string, url, info: {description, title, initiallyClosed: boolean}}}}
     */
    const getModelData = useCallback((url) => {
        if (!url || !dmsId) {
            return {};
        }

        const {title, description} = model;

        return {
            model: {
                type: 'Object',
                url: url,
                rotation: {x: 0, y: 0, z: 0},
                info: {
                    title: title,
                    description: description,
                    closedInitially: true
                },
            },
        };
    }, [dmsId, model]);

    /**
     * Hook used to get the "file-url" for the selected object,
     * and create the modelviewer config.
     */
    useEffect(() => {
        if (!model || !dmsId || !collectionId) {
            return;
        }

        damsFetch('/dms/build-model-url/', {
            method: 'POST',
            body: JSON.stringify({
                multimediaId: dmsId,
                collectionId: collectionId
            })
        }).then(res => {
            if (res.url) {
                setModelConfig(getModelData(res.url));
            } else {
                clientLog('error', `failed to get model URL for: ${dmsId}`, componentName);
            }
        }).catch(error => {
            clientLog('error', error, componentName);
        });
    }, [collectionId, dmsId, getModelData, model]);

    return (modelConfig && <ModelviewerProvider>
            <WithModelviewerMicroFrontend>
                <div style={{height: "100%", minWidth: '100%', width: '100%'}}>
                    <ModelViewer
                        data={modelConfig}
                        showGizmo={false}
                        showToolbar={false}
                        enablePan={true}
                        enableZoom={true}
                        adminMode={false}
                        showAnnotations={true}
                    />
                </div>
            </WithModelviewerMicroFrontend>
        </ModelviewerProvider>
    );

};