import React from "react";
import {styled} from "@mui/material/styles";
import Paper from "@mui/material/Paper";

const PREFIX = "FolderSearch";

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    myFolders: `${PREFIX}-myFolders`,
};

const Root = styled("div")(({theme}) => ({
    [`&.${classes.root}`]: {
        flexGrow: 1,
        display: 'flex',
        alignItems: 'stretch',
        justifyItems: 'stretch',
        overflow: 'hidden',
    },

    [`& .${classes.paper}`]: {
        overflow: 'hidden',
        flexGrow: 1,
        display: 'flex',
        [theme.breakpoints.up("xs")]: {
            margin: 0,
        },
        [theme.breakpoints.up("md")]: {
            margin: theme.spacing(3),
        },
        [theme.breakpoints.up("xl")]: {
            margin: theme.spacing(5),
        },
    },

    [`& .${classes.myFolders}`]: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },
}));

export const FolderSearch = ({children}) => {
    return (
        <Root className={classes.root}>
            <Paper className={classes.paper}>
                <div className={classes.myFolders}>{children}</div>
            </Paper>
        </Root>
    );
};
