import React from "react";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import {useGetDocumentTypeIcon} from "../useGetDocumentTypeIcon";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ThreeDRotationOutlinedIcon from "@mui/icons-material/ThreeDRotationOutlined";
import {VolumeUpOutlined} from "@mui/icons-material";

const useStyles = makeStyles((smallScreen) => ({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        paddingLeft: 3,
        paddingRight: 3,
        minHeight: () => {
            return smallScreen ? "30vh" : null;
        },
    },
}));

export const ObjectMediaDocument = ({
                                        smallScreen,
                                        documentType,
                                        mimeType,
                                    }) => {
    const classes = useStyles();

    const iconStyles = () => {
        return {
            width: smallScreen ? "20vw" : "30vw",
            height: smallScreen ? "20vw" : "30vw",
            color: "#aaa",
        };
    };

    const documentTypeIcon = useGetDocumentTypeIcon(
        mimeType || undefined,
        iconStyles()
    );

    const getObjectTypeIcon = (objectType) => {
        switch (objectType) {
            case "Geodata":
                return <LocationOnOutlinedIcon sx={iconStyles()}/>;
            case "Tabell":
                return <TableChartOutlinedIcon sx={iconStyles()}/>;
            case "StillImage":
                return <PhotoCameraOutlinedIcon sx={iconStyles()}/>;
            case "Video":
                return <VideocamOutlinedIcon sx={iconStyles()}/>;
            case "Modell":
                return <ThreeDRotationOutlinedIcon sx={iconStyles()}/>;
            case "Audio":
                return <VolumeUpOutlined sx={iconStyles()}/>;
            default:
                // Return DAMS default document type icon.
                return documentTypeIcon;
        }
    };

    return (
        <Box className={classes.container}>
            {!smallScreen && getObjectTypeIcon(documentType)}
            {smallScreen && getObjectTypeIcon(documentType)}
        </Box>
    );
};
