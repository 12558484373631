import {Accordion, AccordionDetails} from "@mui/material";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";

/***
 * Component holding the accordion with metadata.
 * @param title string  The accordion title
 * @param children  string  Child elements.
 * @param defaultExpanded bool  If true, the accordion is expanded by default.
 * @param props object
 * @returns {JSX.Element}
 * @constructor
 */
export const MetadataAccordion = ({title, children, defaultExpanded, ...props}) => {
    return (
        <Accordion
            key={props.key}
            elevation={0}
            disablegutters={"true"}
            defaultExpanded={defaultExpanded}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon/>}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography sx={{fontWeight: 600}}>{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>{children}</AccordionDetails>
        </Accordion>
    );
};
