import React from "react";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import Toolbar from "@mui/material/Toolbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const FileCardPreview = ({ showPreview, setShowPreview, children }) => {
  return (
    <Modal open={showPreview} onClose={() => setShowPreview(false)}>
      <Box
        sx={{
          backgroundColor: "black",
          height: "100%",
        }}
      >
        <Toolbar>
          <IconButton onClick={() => setShowPreview(false)} size="large">
            <ArrowBackIcon htmlColor={"white"} />
          </IconButton>
        </Toolbar>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "calc(100% - 64px)",
          }}
        >
          {children}
        </Box>
      </Box>
    </Modal>
  );
};
