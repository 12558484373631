import {useCallback, useEffect} from "react";
import {getFileExtensionFromFilename, getMimeTypeFromFilename} from "../../damsFileObjectDefinitions";
import {DOWNLOADS_COMPLETE, STAGE_UPDATE, useDownloadWorkerDispatch} from "../workerStatusContext";

/**
 * Listener used to listen for, and parse, messages sent by the download web worker.
 * @returns {JSX.Element}
 * @constructor
 */
export const FileDownloadsWorkerListener = () => {

    const downloadWorkerDispatch = useDownloadWorkerDispatch();

    /**
     * Saves the downloaded data to the client, as a file with the specified filename and mimetype.
     * @param name  str The name of the saved file.
     * @param uniqueId  str The object's unique ID.
     * @param fileType  str Used to differentiate between custom sized images and other file types.
     * @param buffers   object  The data to be saved.
     */
    const saveFile = ({name, uniqueId, fileType, buffers}) => {
        if (uniqueId) {
            const namePart = name.substring(0, name.lastIndexOf('.'));
            const extension = getFileExtensionFromFilename(name);
            name = `${namePart}_${uniqueId}.${extension}`;
        }
        const mimeType = getMimeTypeFromFilename(name);
        let blob;
        if (fileType === 'customSizedImage') {
            blob = buffers;
        } else {
            blob = new Blob(buffers, {type: mimeType});
        }
        const blobUrl = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.download = name || Math.random().toString()       // NOSONAR
        a.href = blobUrl;
        a.click();
        URL.revokeObjectURL(blob);
    };

    const isValidMessage = event => {
        if (!event.data) {
            return false;
        }
        const {channel, action} = event.data;
        return !(!channel || channel !== 'client' || !action || action === '');
    };

    /**
     * Method used to parse messages received from the download worker.
     * Listening to "channel" => "client", as messages tagged with this
     * information is used to trigger client-code, outside the web worker.
     * @param event
     */
    const workerMessageParser = useCallback(event => {
        if (!isValidMessage(event)) {
            return;
        }

        const data = event.data;
        const {action} = data;

        if (action === 'saveRaw') {
            // Saving large files directly using the browser's built-in logic.
            const url = data.data.url;
            const iframe = document.createElement("iframe");
            iframe.style.display = "none";
            iframe.src = url;
            iframe.setAttribute('data-created', new Date().getTime().toString());
            document.body.appendChild(iframe);
        } else if (action === 'saveFile') {
            // Save uploaded file on the client.
            const {data: actionData} = data;
            const {name, uniqueId, buffers, fileType} = actionData;
            saveFile({
                name: name,
                uniqueId: uniqueId,
                fileType: fileType,
                buffers: buffers,
            });
        } else if (action === 'stageUpdate') {
            // Update stage
            const {job, stageId} = data;

            if (job.complete) {
                downloadWorkerDispatch({
                    type: DOWNLOADS_COMPLETE,
                    job: job
                });
            } else {
                downloadWorkerDispatch({
                    type: STAGE_UPDATE,
                    job: job,
                    stageId: stageId
                });
            }
        }
    }, [downloadWorkerDispatch]);

    /**
     * Method used to remove iframes older than 8 hours, assuming the downloads executed within these
     * are finished.
     */
    const iframeCleanup = () => {
        const iframes = document.getElementsByTagName('iframe');
        const eightHoursMs = 8 * 60 * 60 * 1000;
        const oldIFrames = [];
        for (const f of iframes) {
            const now = new Date().getTime();
            const delta = now - parseInt(f.getAttribute('data-created'));
            if (delta > eightHoursMs) {
                oldIFrames.push(f);
            }
        }

        for (let i = oldIFrames.length; i >= 0; i--) {
            const f = oldIFrames[i]
            if (f) {
                f.parentNode.removeChild(f);
            }
        }
    };


    /**
     * Hook used to add an event listener that listens for messages from the download web worker.
     */
    useEffect(() => {
        window._downloadWorkerInstance.onmessage = workerMessageParser;
        setInterval(iframeCleanup, 30000);
    }, [workerMessageParser]);

    return <></>;
};