import {refreshToken} from '@ekultur/authentication'
import {clientLog} from "../clientLog";

/**
 * Method used to "keep alive" access tokens.
 * NOTE: This should only be called from the web workers.
 */
export const refreshCredentials = () => {
    refreshToken({
        apiGateway: window._env_.REACT_APP_API_GATEWAY,
        onSuccess: () => {
            clientLog('info', 'refreshed access token');
        },
    });
}