import React, {useEffect, useState} from "react";
import {
    EXIT_FULLSCREEN,
    MEDIA_PLAYING,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
} from "../search-result-details/SearchResultDetailsContext";
import {VideoPlayer} from "../video/VideoPlayer";
import {useObserveWindowResize} from "../app/useObserveWindowResize";
import {Dialog, DialogContent} from "@mui/material";
import {AudioVideoDialogActions} from "./smallscreen/AudioVideoDialogActions";
import Box from "@mui/material/Box";
import useDeepCompareEffect from "use-deep-compare-effect";

/**
 * Component used when displaying the video player.
 * @param smallScreen
 * @param parentRef
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectMediaVideo = ({smallScreen, parentRef}) => {
    const observeWindowResize = useObserveWindowResize();
    const {model, mediaPlaying} = useSearchResultDetailsState();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();

    const [width, setWidth] = useState(0);
    const [isPortrait, setIsPortrait] = useState(true);
    const [scaleFactor, setScaleFactor] = useState(0.7);

    /**
     * Converts vw-value (%) to pixels.
     * @param vw integer
     * @returns {number}
     */
    const vwToPx = (vw) => {
        return (window.innerWidth * vw) / 100;
    };

    const handleCloseFullscreenDialog = () => {
        searchResultDetailsDispatch({type: MEDIA_PLAYING, playing: false});
        searchResultDetailsDispatch({type: EXIT_FULLSCREEN});
    };

    /**
     * Observes the content width, in order to correctly resize the player area.
     */
    const observeContentWidth = () => {
        const observer = new ResizeObserver((entries) => {
            const observedEl = entries[0];
            const w = observedEl.contentRect.width * scaleFactor;
            setWidth(w);
        })
        observer.observe(parentRef.current);
        return observer;
    };

    /**
     * Hook used to see if the application is running in portrait mode or not.
     */
    useEffect(() => {
        setIsPortrait(observeWindowResize.width < observeWindowResize.height);
    }, [setIsPortrait, observeWindowResize.width, observeWindowResize.height]);

    /**
     * Hook used to set the scale-factor used for correctly resizing the video player.
     */
    useEffect(() => {
        if (smallScreen && isPortrait) {
            setScaleFactor(1);
        } else if (smallScreen && !isPortrait) {
            setScaleFactor(0.3);
        }
    }, [smallScreen, isPortrait]);

    /**
     * Hook used to initialize/uninitialize the content width observer.
     */
    useDeepCompareEffect(() => {
        let observer = observeContentWidth();
        return () => {
            if (observer) {
                observer.disconnect();
                observer = null;
            }
        };
    }, [isPortrait, parentRef, smallScreen]);

    if (smallScreen && !isPortrait) {
        return (
            <Dialog open={true} fullScreen={true}>
                <DialogContent sx={{alignSelf: "center", overflowY: "hidden"}}>
                    <VideoPlayer
                        model={model}
                        width={vwToPx(76)}
                        smallScreen={smallScreen}
                        mediaPlaying={mediaPlaying}
                    />
                </DialogContent>
                <AudioVideoDialogActions
                    callbackCloseFullScreenDialog={handleCloseFullscreenDialog}
                />
            </Dialog>
        );
    } else {
        return <Box sx={{marginLeft: '50%', marginRight: '50%'}}><VideoPlayer model={model} width={width}/></Box>;
    }
};
