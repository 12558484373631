import React from "react";
import {styled} from "@mui/material/styles";
import {ADD_FOLDER, useMyFoldersDispatch} from "./MyFoldersContext";
import {usePostFolder} from "./usePostFolder";
import {useDocumentState} from "../documents/documentContext";
import {useAuthsState} from "../auths/authsContext";
import {FormCreateFolder} from "./FormCreateFolder";
import {useFolderShareEmail} from "./useFolderShareEmail";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import Grow from "@mui/material/Grow";
import {SET_QUERY, SET_SEARCHING, SET_START, useFoldersDispatch} from "./FoldersContext";
import useDeepCompareEffect from "use-deep-compare-effect";

const PREFIX = "CreateFolderModal";

const classes = {
    modal: `${PREFIX}-modal`,
    paper: `${PREFIX}-paper`,
};

const StyledModal = styled(Modal)(({theme}) => ({
    [`&.${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.paper}`]: {
        minWidth: "60%",
        outline: "none",
        padding: theme.spacing(4),
    },
}));

/**
 * Renders a modal component for creating a folder.
 *
 * @param {Object} props - The props object.
 * @param {boolean} props.show - Indicates whether the modal is shown or not.
 * @param {function} props.onHide - The function to call when the modal is hidden.
 * @return {JSX.Element} The rendered modal component.
 */
export const CreateFolderModal = ({show, onHide}) => {
    const [postResponse, postFolder] = usePostFolder();
    const foldersDispatch = useFoldersDispatch();
    const dispatch = useMyFoldersDispatch();
    const {formData} = useDocumentState();
    const {userData} = useAuthsState();
    const [setEmailRecipients, sendEmailNotifications] = useFolderShareEmail();

    useDeepCompareEffect(() => {
        if (postResponse.success) {
            dispatch({
                type: ADD_FOLDER,
                folder: postResponse.newFolder,
            });
            sendEmailNotifications(postResponse.newFolder.id);

            // Update the list of folders
            foldersDispatch({
                type: SET_QUERY,
                query: "*",
                folderContext: true,
                onlyMine: true,
            });
            foldersDispatch({type: SET_START});
            foldersDispatch({type: SET_SEARCHING});

            onHide();
        }
    }, [postResponse.success, postResponse.newFolder]);

    const save = (newFolder) => {
        const emailsAcl = [
            ...newFolder.readShare.map((readAcl) => ({
                access: "read",
                email: readAcl.email,
                expires_at: readAcl.expiresAt,
            })),
            ...newFolder.writeShare.map((writeAcl) => ({
                access: "write",
                email: writeAcl.email,
                expires_at: writeAcl.expiresAt,
            })),
        ];

        const folder = {
            collection_id: newFolder.collectionId,
            schemaId: 1,
            documentType: "Folder",
            locale: "no",
            status: newFolder.folderAvailability,
            title: newFolder.folderName,
            description: "",
            content: {
                usersAcl: [
                    {
                        referenceType: "user_acl",
                        _action: "put",
                        reference: {
                            locale: "no",
                            _action: "put",
                            title: userData.uniqueId,
                            status: "published",
                        },
                    },
                ],
            },
            acls: emailsAcl,
        };
        setEmailRecipients(null, emailsAcl);
        postFolder(folder);
    };

    return (
        <StyledModal
            className={classes.modal}
            open={show}
            onClose={onHide}
            closeAfterTransition
        >
            <Grow in={show}>
                <Paper className={classes.paper}>
                    <FormCreateFolder
                        onSave={save}
                        onHide={onHide}
                        collectionId={formData.collectionId}
                    />
                </Paper>
            </Grow>
        </StyledModal>
    );
};
