import React, { createContext } from "react";
import { useTranslation } from "react-i18next";
export const CONVERSION_ERROR = "objectConversionContext/error";
export const CONVERSION_QUEUED = "objectConversionContext/queued";
export const CONVERSION_CONVERTING = "objectConversionContext/converting";
export const CONVERSION_DONE = "objectConversionContext/done";
export const CONVERSION_UNKNOWN = "objectConversion/unknown";

const ObjectConversionStateContext = React.createContext(undefined);
const ObjectConversionDispatchContext = React.createContext(undefined);
const ObjectConversionTranslationContext = createContext(undefined);

const initialState = {
  progress: 0,
  converting: false,
  error: false,
  queued: false,
  stateUnknown: true,
};

const objectConversionReducer = (state, action) => {
  switch (action.type) {
    case CONVERSION_ERROR:
      return {
        ...state,
        converting: false,
        progress: 0,
        error: true,
        queued: false,
        stateUnknown: false,
      };
    case CONVERSION_QUEUED:
      return {
        ...state,
        converting: false,
        progress: 0,
        error: false,
        queued: true,
        stateUnknown: false,
      };
    case CONVERSION_CONVERTING:
      return {
        ...state,
        converting: true,
        queued: false,
        progress: action.progress,
      };
    case CONVERSION_DONE:
      return {
        ...state,
        converting: false,
        progress: 100,
        stateUnknown: false,
      };
    case CONVERSION_UNKNOWN:
      return {
        ...initialState,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const ObjectConversionProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    objectConversionReducer,
    { ...initialState },
    undefined
  );
  const { t } = useTranslation("dams.objectconversion", {
    useSuspense: false,
  });

  return (
    <ObjectConversionTranslationContext.Provider value={t}>
      <ObjectConversionStateContext.Provider value={state}>
        <ObjectConversionDispatchContext.Provider value={dispatch}>
          {children}
        </ObjectConversionDispatchContext.Provider>
      </ObjectConversionStateContext.Provider>
    </ObjectConversionTranslationContext.Provider>
  );
};

export const useObjectConversionState = () => {
  const context = React.useContext(ObjectConversionStateContext);
  if (undefined === context) {
    throw new Error(
      "useObjectConversionState must be used within a ObjectConversionProvider"
    );
  } else {
    return context;
  }
};

export const useObjectConversionDispatch = () => {
  const context = React.useContext(ObjectConversionDispatchContext);
  if (undefined === context) {
    throw new Error(
      "useObjectConversionDispatch must be used within a ObjectConversionProvider"
    );
  } else {
    return context;
  }
};

export const useObjectConversionTranslation = () => {
  const context = React.useContext(ObjectConversionTranslationContext);
  if (undefined === context) {
    throw new Error(
      "useObjectConversionTranslation must be used within a ObjectConversionProvider"
    );
  } else {
    return context;
  }
};
