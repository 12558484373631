import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

const DetailsDialogCloseButton = ({tp, closeCallback, iconButton}) => {

    const buttonAsIcon = <IconButton
        onClick={closeCallback}
        sx={{
            position: "fixed",
            top: 0,
            right: 0,
        }}
    >
        <CloseIcon/>
    </IconButton>;

    const buttonAsButton = <Button
        variant={"contained"}
        onClick={closeCallback}
        sx={{position: "fixed", bottom: 16, right: 16}}
    >
        {tp('btnClose', 'Lukk')}
    </Button>;

    return iconButton ? buttonAsIcon : buttonAsButton;
};

DetailsDialogCloseButton.propTypes = {
    tp: PropTypes.func.isRequired,
    closeCallback: PropTypes.func.isRequired,
    iconButton: PropTypes.bool.isRequired
};

export {DetailsDialogCloseButton};