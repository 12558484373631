import React, {useRef} from "react";
import {HIDE, REMOVE_FIRST_MESSAGE, useSnackbarDispatch, useSnackbarState,} from "./SnackbarContext";
import {Message} from "./Message";
import Snackbar from "@mui/material/Snackbar";

export const AppSnackbar = () => {
    const {open, messages} = useSnackbarState();
    const dispatch = useSnackbarDispatch();
    const snackbarRef = useRef();

    const closeHandler = () => {
        if (1 < messages.length) {
            dispatch({type: REMOVE_FIRST_MESSAGE});
        } else {
            dispatch({type: HIDE});
        }
    };

    const getFirstMessage = (r) => {
        if (!messages.length) {
            return null;
        }
        
        const {title, body, type} = messages[0];
        return (
            <Message
                title={title}
                message={body}
                type={type}
                closeHandler={closeHandler}
                snackbarRef={r}
            />
        );
    };

    return (
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            open={open}
            onClose={closeHandler}
            autoHideDuration={6000}
            ref={snackbarRef}
        >
            {getFirstMessage(snackbarRef)}
        </Snackbar>
    );
};
