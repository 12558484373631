import {decamelize} from "../app/decamelize";

/**
 * Function to manage search settings including number of rows, display type, sort criteria, and table columns.
 *
 * @param {string} storageKey - The key under which the settings are stored in localStorage.
 * @return {Object} An object containing functions to get and set search settings.
 */
export const SearchSettings = (storageKey) => {
    const defaultConfig = {
        numRows: 10,
        displayType: "grid",
        sort: {
            column: "createdAt",
            order: "desc",
        },
    };

    const _getConfig = () => {
        const configStr = localStorage.getItem(storageKey);
        const config = JSON.parse(configStr);
        return {...defaultConfig, ...config};
    };

    function setNumberOfRows(value) {
        let c = {..._getConfig(), numRows: value};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function setDisplayType(value) {
        let c = {..._getConfig(), displayType: value};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function setSortColumnAndOrder(column, order) {
        let c = {..._getConfig(), sort: {column: column, order: order}};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function setTableCols(columns) {
        let c = {..._getConfig(), columns: columns};
        localStorage.setItem(storageKey, JSON.stringify(c));
    }

    function getTableCols() {
        return _getConfig().columns;
    }

    function getSortCriteria() {
        const c = _getConfig().sort;
        return decamelize(c.column) + " " + c.order;
    }

    function getNumberOfRows() {
        return _getConfig().numRows;
    }

    function getDisplayType() {
        return _getConfig().displayType;
    }

    function logout() {
        try {
            const data = JSON.parse(localStorage.getItem(storageKey));
            if (!data || data === '') {
                return;
            }
            data.selectedMuseum = null;
            localStorage.setItem(storageKey, JSON.stringify(data));
        } catch {
            return;
        }
    }

    return {
        getNumberOfRows,
        getDisplayType,
        getSortCriteria,
        getTableCols,
        setDisplayType,
        setNumberOfRows,
        setSortColumnAndOrder,
        setTableCols,
        logout
    };
};
