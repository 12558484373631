import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

export const CREATE_NEW_PROJECT = "projectContext/createNewProject";
export const PROJECT_SET_COLLECTION_ID =
    "projectContext/projectSetCollectionId";
export const PROJECT_ADD_ACLS = "projectContext/addAcls";
export const PROJECT_SAVE = "projectContext/projectSave";

export const ARCHEOLOGY_PROJECT_READ = "projectContext/archeologyProjectRead";
export const ARCHEOLOGY_PROJECT_SHOW_METADATA =
    "projectContext/archeologyProjectShowMetadata";
export const ARCHEOLOGY_PROJECT_ADD_USERS =
    "projectContext/archeologyProjectAddUsers";
export const CLEAR_ARCHEOLOGY_PROJECT_READ =
    "projectContext/archeologyClearProjectRead";
export const CANCEL_CREATE_PROJECT = "projectContext/cancelCreateProject";
export const HAS_ARCHEOLOGY_MODULE = 'projectContext/hasArcheologyModule';

const ProjectStateContext = createContext(undefined);
const ProjectDispatchContext = createContext(undefined);
const ProjectTranslationContext = createContext(undefined);

const initialState = {
    hasArcheologyModule: false,
    activeStep: 0,
    projectType: "",
    damsProject: {},
    collectionId: undefined,
    museumId: undefined,
    projectData: {
        archeologyRemote: undefined,
    },
    cancel: false,
    save: false,
    acls: undefined,
};

const projectReducer = (state, action) => {
    switch (action.type) {
        case CREATE_NEW_PROJECT:
            return {
                ...initialState,
                activeStep: 0,
                projectType: action.projectType,
            };
        case PROJECT_SET_COLLECTION_ID:
            return {
                ...state,
                collectionId: action.collectionId,
                museumId: action.museumId,
            };
        case ARCHEOLOGY_PROJECT_READ:
            return {
                ...state,
                activeStep: 0,
                damsProject: action.project,
                projectData: action.projectData,
            };
        case ARCHEOLOGY_PROJECT_SHOW_METADATA:
            return {
                ...state,
                activeStep: 1,
            };
        case ARCHEOLOGY_PROJECT_ADD_USERS:
            return {
                ...state,
                activeStep: 2,
                projectData: {
                    ...state.projectData,
                },
            };
        case PROJECT_ADD_ACLS:
            return {
                ...state,
                acls: action.acls,
            };
        case PROJECT_SAVE:
            return {
                ...state,
                save: true,
                acls: action.acls,
            };
        case CLEAR_ARCHEOLOGY_PROJECT_READ:
            return {
                ...state,
                activeStep: 0,
                damsProject: {},
                collectionId: undefined,
                projectData: {archeologyRemote: undefined},
            };
        case CANCEL_CREATE_PROJECT:
            return {
                ...initialState,
                cancel: true,
                damsProject: {},
            };
        case HAS_ARCHEOLOGY_MODULE:
            return {
                ...initialState,
                hasArcheologyModule: action.hasArcheologyModule
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const ProjectProvider = ({children}) => {
    const [state, dispatch] = useReducer(projectReducer, initialState, undefined);
    const {t, ready} = useTranslation("dams.project", {useSuspense: false});

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <ProjectTranslationContext.Provider value={t}>
            <ProjectStateContext.Provider value={state}>
                <ProjectDispatchContext.Provider value={dispatch}>
                    {children}
                </ProjectDispatchContext.Provider>
            </ProjectStateContext.Provider>
        </ProjectTranslationContext.Provider>
    );
};

export const useProjectState = () => {
    const context = useContext(ProjectStateContext);
    if (undefined === context) {
        throw new Error("useProjectState must be used within a ProjectProvider.");
    } else {
        return context;
    }
};

export const useProjectDispatch = () => {
    const context = useContext(ProjectDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useProjectDispatch must be used within a ProjectProvider."
        );
    } else {
        return context;
    }
};

export const useProjectTranslation = () => {
    const context = useContext(ProjectTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useProjectTranslation must be used within a ProjectProvider"
        );
    } else {
        return context;
    }
};
