import React, {useEffect, useState} from "react";
import {ObjectMediaImage} from "./ObjectMediaImage";
import {ObjectMediaPdf} from "./ObjectMediaPdf";
import {ObjectMediaDocument} from "./ObjectMediaDocument";
import {ObjectMediaVideo} from "./ObjectMediaVideo";
import {ObjectMediaAudio} from "./ObjectMediaAudio";
import {ObjectMediaPlayIcon} from "./ObjectMediaPlayIcon";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";
import {ObjectConversionStatus} from "./ObjectConversionStatus";
import {CONVERSION_DONE, useObjectConversionDispatch,} from "./ObjectConversionContext";
import Stack from "@mui/material/Stack";
import {ObjectViewCopyrightIconsOverlay} from "./ObjectViewCopyrightIconsOverlay";
import {OpenIn3DButton} from "./OpenIn3DButton";
import {ThreeDViewerDialog} from "./ThreeDViewerDialog";
import {getMimeTypeFromModel} from "../utility";

/**
 * Component that holds the contents of the left column inside the object view dialog.
 * @param columnDefs    Array   The number of columns the left-column should span.
 * @param gridRef   ref     A ref to the parent Grid definition.
 * @param mediaPlayCallback function    Callback used when the user clicks "play".
 * @param smallScreen bool  True if running on a smaller screen.
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectViewMediaSection = ({
                                           t,
                                           gridRef,
                                           mediaPlayCallback,
                                           smallScreen,
                                           pdfOpenedCallback
                                       }) => {
    const {model, fullscreen, mediaPlaying} = useSearchResultDetailsState();
    const dispatch = useObjectConversionDispatch();

    const [playing, setPlaying] = useState(false);

    const {collectionId, documentType} = model;

    const mimeType = getMimeTypeFromModel(model);

    const documentIconModelTypes = [
        "Geodata",
        "Modell",
        "Tabell",
        "Misc",
        "Dokument",
    ];

    const [showViewer, setShowViewer] = useState(false);

    const showViewerCallback = show => {
        setShowViewer(show);
    };

    const closeViewerCallback = () => {
        setShowViewer(false);
    };

    useEffect(() => {
        setPlaying(mediaPlaying);
    }, [mediaPlaying]);

    return (mimeType && <>
            <Stack
                sx={{
                    display: "flex",
                    flexFlow: 'row nowrap',
                    justifyContent: "center",
                    alignItems: 'center',
                    width: '100%',
                }}
            >
                {/* Show copyright icons - if any... */}
                <ObjectViewCopyrightIconsOverlay fullscreen={fullscreen} model={model}/>

                {/* Image/video/PDF thumbnail */}
                {!playing &&
                    !documentIconModelTypes.includes(documentType) && documentType !== "Audio" && (
                        <ObjectMediaImage parentRef={gridRef} smallScreen={smallScreen}/>
                    )}

                {!playing && mimeType.indexOf("pdf") !== -1 && (
                    <ObjectMediaPdf parentRef={gridRef} smallScreen={smallScreen}
                                    pdfOpenedCallback={pdfOpenedCallback}/>
                )}

                {/* Document icon */}
                {!playing &&
                    // Hide document icon for .pdf files
                    mimeType.indexOf("pdf") === -1 &&
                    documentIconModelTypes.includes(documentType) && (
                        <ObjectMediaDocument
                            smallScreen={smallScreen}
                            documentType={documentType}
                        />
                    )}

                {/* Videoplayer */}
                {playing && documentType === "Video" && (
                    <ObjectMediaVideo smallScreen={smallScreen} parentRef={gridRef}/>
                )}

                {/* Audioplayer */}
                {documentType === "Audio" && (
                    <ObjectMediaAudio
                        smallScreen={smallScreen}
                    />
                )}

                {/* Play overlay icon for audio/video */}
                <ObjectMediaPlayIcon
                    onClick={mediaPlayCallback}
                    smallScreen={smallScreen}
                />

                {/* 3D "open in viewer" button and 3D viewer */}
                <OpenIn3DButton model={model} showViewerCallback={showViewerCallback}/>
                <ThreeDViewerDialog model={model} closeViewerCallback={closeViewerCallback} showDialog={showViewer}/>

                {/* Media object conversion status */}
                {["Video", "Audio"].includes(documentType) && (
                    <ObjectConversionStatus
                        t={t}
                        collectionId={collectionId}
                        smallScreen={smallScreen}
                        callback={() =>
                            dispatch({
                                type: CONVERSION_DONE,
                            })
                        }
                    />
                )}
            </Stack>

        </>
    );
};
