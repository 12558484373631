import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";

export const fetchRelationDocs = docUniqueIds => {
    const callback = (resolve, json) => {
        const mappedModels = json.models.map(m => {
            return {
                uniqueId: m.uniqueId,
                relationType: m.content.relationType,
                relationDescription: m.content.relationDescription
            }
        })
        resolve(mappedModels);
    };

    return new Promise((resolve, reject) => {
        const searchString = `unique_id: (${docUniqueIds.join(' OR ')})`;
        const searchParams = new URLSearchParams(decamelizeKeysDeep({
            q: searchString,
            sort: 'created_at desc',
            expand: true,
            start: 0,
            rows: 100,
            documentType: '(relation)',
            onlyMine: false
        }));
        damsFetch(`/search?${searchParams.toString()}`)
            .then(json => callback(resolve, json))
            .catch(e => reject(e));
    });
};

export default {
    fetchRelationDocs
}
