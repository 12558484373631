import {Switch} from "@mui/material";
import React from "react";
import {useField, useFormikContext} from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";

export const FormikSwitch = ({
                                 formikKey,
                                 label,
                                 ...props
                             }) => {

    const [field] = useField(formikKey);
    const {setFieldValue} = useFormikContext();

    const handleChange = () => {
        setFieldValue(formikKey, !field.value).then();
    };

    return <FormGroup>
        <FormControlLabel control={
            <Switch
                checked={field.value}
                onChange={handleChange}
                inputProps={{'aria-label': 'controlled'}}
                color={"primary"}
                {...props}
            />
        } label={label}/>
    </FormGroup>;
};
