import React, {useCallback, useEffect} from "react";
import {SET_NUM_STEPS, useNewFilesDispatch, useNewFilesState,} from "../files/NewFilesContext";
import {useNewFilesTranslation} from "../files/NewFilesTranslationContext";
import {useTheme} from "@emotion/react";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import {useLocation} from "react-router-dom";

export const NewFilesStepper = () => {
    const theme = useTheme();
    const {activeStepIndex} = useNewFilesState();
    const t = useNewFilesTranslation();
    const location = useLocation();

    const getStepLabel = () => {
        if (location.pathname === '/new-images/') {
            return t('uploadImages', 'Last opp bilder');
        } else if (location.pathname === '/new-documents/') {
            return t('uploadDocuments', 'Last opp dokumenter');
        } else if (location.pathname === '/new-sounds/') {
            return t('uploadSounds', 'Last opp lydfiler');
        } else if (location.pathname === '/new-videos/') {
            return t('uploadVideos', 'Last opp filmer');
        } else {
            return t("uploadFiles", "Last opp filer")
        }
    };

    const getSteps = useCallback(
        () => [
            {
                // label: t("uploadFiles", "Last opp filer"),
                label: getStepLabel()
            },
            {
                label: t("addMetadata", "Legg til metadata"),
            },
            {
                label: t("addToFolder", "Legg til i mappe"),
                optional: true,
            },
        ],
        [t]
    );

    const newFilesDispatch = useNewFilesDispatch();

    useEffect(() => {
        newFilesDispatch({
            type: SET_NUM_STEPS,
            numSteps: getSteps().length,
        });
    }, [getSteps, newFilesDispatch]);

    /**
     * Hook used to determine which type of files being uploaded.
     */
    useEffect(() => {

    }, [location]);

    return (
        <Stepper
            activeStep={activeStepIndex}
            alternativeLabel={true}
            sx={{
                backgroundColor: theme.palette.grey[100],
                marginTop: theme.spacing(4),
                marginLeft: theme.spacing(4),
                marginRight: theme.spacing(4),
            }}
        >
            {getSteps().map((step) => {
                const labelProps = {};
                return (
                    <Step key={step.label}>
                        <StepLabel {...labelProps}>{step.label}</StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};
