import {useEmail} from "./useEmail";
import {useAuthsState} from "../auths/authsContext";
import {useTranslation} from "react-i18next";
import {PromisePool} from "@supercharge/promise-pool";


export const useFolderShareEmail = () => {
    const {t} = useTranslation("dams.email", {useSuspense: false});
    const {userData} = useAuthsState();
    // eslint-disable-next-line no-unused-vars
    const [sendEmail, sendSingleMail] = useEmail(); // NOSONAR

    let _emailRecipients;

    const setEmailRecipients = (existingAcls, unsavedAcls) => {
        let recipients = [];
        if (existingAcls === null) {
            recipients = unsavedAcls;
        } else {
            for (let i = 0, max = unsavedAcls.length; i < max; i++) {
                const acl = unsavedAcls[i];
                const newAcl = _getNewAcl(existingAcls, acl);
                if (newAcl !== null) {
                    recipients.push(newAcl);
                }
            }
        }
        _emailRecipients = recipients;
    };

    const sendEmailNotifications = async (folderId, recipients = null) => {
        if (recipients && recipients.length > 0) {
            _emailRecipients = recipients;
        }

        const {results, errors} = await PromisePool
            .withConcurrency(4)
            .for(_emailRecipients)
            .process(async (recipient) => {
                const {email, access, expires_at: expires} = recipient;
                const mailBody = _createEmailBody(folderId, access, expires.toString());
                return await sendSingleMail(t("shareFolderMailSubject", "DAMS delt mappe"), mailBody, email); // NOSONAR
            });
        _emailRecipients = [];

        const successful = results.map(r => {
            const {recipient, success} = r[0];
            return {
                recipient: recipient,
                success: success
            };
        });

        return {
            results: successful,
            errors: errors
        };
    };

    const _createEmailBody = (folderId, aclType, expires) => {
        return (
            t("txtFolderShareEmail1", "Hei\n\n") +
            userData.name +
            " " +
            t("txtFolderShareEmail2", "har delt en DAMS-mappe med deg") +
            ":\n\n" +
            _getShareUrl(folderId) +
            "\n\n" +
            t("txtFolderShareEmail3", "Du har") +
            " " +
            (aclType === "read"
                ? t("txtFolderShareReadAccess", "leserettigheter")
                : t("txtFolderShareWriteAccess", "skriverettigheter")) +
            " " +
            t("txtFolderShareExpiryDate", "frem til ") +
            expires +
            "\n\n" +
            t(
                "txtFolderShareEmail4",
                "For å nå denne mappen kreves en eKultur bruker"
            ) +
            ".\n\n" +
            t(
                "txtFolderShareEmail5",
                "Dersom du ikke har en eKultur bruker, må denne opprettes"
            ) +
            ":\n" +
            t(
                "txtFolderShareEmail6",
                "1. Gå til {{ekultur_url}}\n" +
                '2. Opprett ny bruker ved å klikke på "Ny her? Opprett en konto" dersom du ønsker å benytte e-mail/passord kombinasjon,' +
                " alternativt klikk på Google eller Facebook dersom du ønsker å opprette bruker via en av disse kontoene.\n" +
                "3. Når din eKultur bruker er opprettet, vil du motta en e-mail med beskjed om å verifisere din eKultur bruker.\n" +
                "4. Gå til {{dams_url}} og logg inn med din nyopprettede eKultur bruker. " +
                "Klikk på linken/adressen du mottok i denne e-mailen og du vil komme til den delte DAMS-mappen.",
                {
                    ekultur_url: window._env_.REACT_APP_LOGIN_HOST,
                    dams_url: window.location.origin,
                }
            ) +
            "\n\n" +
            t(
                "txtFolderShareEmail7",
                "Har du en eKultur bruker, trykk på linken/adressen du mottok i denne e-mailen og du vil komme direkte til den delte DAMS-mappen."
            )
        );
    };

    const _getShareUrl = (folderId) => {
        const l = window.location;
        return l.protocol + "//" + l.host + "/folders/" + folderId + "/";
    };

    const _getNewAcl = (existingAcls, acl) => {
        if (!existingAcls) {
            return null;
        }
        const exists = existingAcls.find((a) => a.email === acl.email);
        return !exists ? acl : null;
    };

    return [setEmailRecipients, sendEmailNotifications];
};
