import React, { useState } from "react";
import { useFolderTranslation } from "../../folder/MyFoldersContext";
import { CreateFolderModal } from "../../folder/CreateFolderModal";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Button from "@mui/material/Button";
import { FoldersProvider } from "../../folder/FoldersContext";

export const CreateFolderButton = () => {
  const t = useFolderTranslation();
  const [showCreateFolder, setShowCreateFolder] = useState(false);

  return (
    <>
      <Button
        variant={"contained"}
        color={"secondary"}
        startIcon={<AddCircleIcon />}
        onClick={() => setShowCreateFolder(true)}
      >
        {t("createFolder", "Opprett mappe")}
      </Button>
      <FoldersProvider>
        <CreateFolderModal
          show={showCreateFolder}
          onHide={() => setShowCreateFolder(false)}
        />
      </FoldersProvider>
    </>
  );
};
