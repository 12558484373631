import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";


export const TOGGLE_COLUMN = 'resultTableContext/toggleColumn';
export const SET_AVAILABLE_COLUMNS = 'resultTableContext/setAvailableColumns'


const ResultTableContext = createContext(undefined);
const ResultTableDispatchContext = createContext(undefined);
const ResultTableTranslationContext = createContext(undefined);

const initialState = {
    availableColumns: [],
    columnsToRender: [],
    contextKey: 'search',
};

/**
 * Compiles a list of columns to be rendered.
 * @param cols
 * @returns {*[]|*}
 */
const getColumnsToRender = (cols) => {
    if (!cols) {
        return [];
    }

    return cols.filter(a => {
        let render;

        if (typeof (a.checked) !== 'undefined') {
            render = a.checked;
        } else {
            render = a.defaultHidden
        }

        return render;
    });

};

/**
 * Saves columns to be rendered to localStorage.
 * @param state
 * @param cols
 */
const saveColumnsToRender = (state, cols) => {
    const savingCols = cols.filter(c => c.checked === true).map(cc => cc.id);
    localStorage.setItem('dams.' + state.contextKey + '.columns', JSON.stringify(savingCols));
    return savingCols;
};

const resultTableReducer = (state, action) => {
    switch (action.type) {
        case SET_AVAILABLE_COLUMNS:
            return {
                ...state,
                availableColumns: action.availableColumns,
                columnsToRender: getColumnsToRender(action.availableColumns),
                contextKey: action.contextKey
            };
        case TOGGLE_COLUMN:
            const cols = state.availableColumns.map(a => {
                if (a.id === action.id) {
                    a.checked = a.checked ? !a.checked : true;
                }
                return a;
            });

            saveColumnsToRender(state, cols);

            return {
                ...state,
                availableColumns: cols,
                columnsToRender: getColumnsToRender(cols)
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const ResultTableProvider = ({children}) => {
    const [state, dispatch] = useReducer(resultTableReducer, initialState, undefined);
    const {t} = useTranslation("dams.resulttable", {useSuspense: false});

    return (
        <ResultTableTranslationContext.Provider value={t}>
            <ResultTableContext.Provider value={state}>
                <ResultTableDispatchContext.Provider value={dispatch}>
                    {children}
                </ResultTableDispatchContext.Provider>
            </ResultTableContext.Provider>
        </ResultTableTranslationContext.Provider>
    );
};

export const useResultTableState = () => {
    const context = useContext(ResultTableContext);
    if (undefined === context) {
        throw new Error("useResultTableState must be used within a ResultTableProvider");
    } else {
        return context;
    }
};

export const useResultTableDispatch = () => {
    const context = useContext(ResultTableDispatchContext);
    if (undefined === context) {
        throw new Error("useSearchDispatch must be used withing a ResultTableProvider");
    } else {
        return context;
    }
};

export const useResultTableTranslation = () => {
    const context = useContext(ResultTableTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useResultTableTranslation must be used within an ResultTableProvider."
        );
    } else {
        return context;
    }
};
