import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import React from "react";
import {useDocumentState} from "../../documents/documentContext";
import PropTypes from "prop-types";

const ProgressDialogContent = ({t}) => {
    const {metadataMappingProgress} = useDocumentState();
    return <Stack direction={"column"} alignItems={"center"} justifyContent={"center"}>
        <Typography mt={2} mb={2}>
            {t('mappingFiles', 'Henter ut metadata...')}
        </Typography>
        <LinearProgress value={metadataMappingProgress}
                        variant={"determinate"}
                        sx={{width: '90%'}}/>
        <Typography mt={2}>
            {metadataMappingProgress}%
        </Typography>
    </Stack>;
};

ProgressDialogContent.propTypes = {
    t: PropTypes.func.isRequired,
};

export {ProgressDialogContent};