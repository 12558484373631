import React from "react";
import { styled } from "@mui/material/styles";
import { useDocumentTranslation } from "./documentContext";
import { useBatchEditState } from "./batchEditContext";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const PREFIX = "BatchEditStepper";

const classes = {
  stepper: `${PREFIX}-stepper`,
};

const StyledStepper = styled(Stepper)(({ theme }) => ({
  [`&.${classes.stepper}`]: {
    backgroundColor: theme.palette.grey[100],
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
}));

export const BatchEditStepper = () => {
  const t = useDocumentTranslation();
  const { activeStepIndex } = useBatchEditState();

  const getSteps = () => [
    {
      label: t("chooseFields", "Velg felter du vil redigere"),
    },
    {
      label: t("edit", "Rediger"),
    },
  ];

  return (
    <StyledStepper
      activeStep={activeStepIndex}
      alternativeLabel={true}
      className={classes.stepper}
    >
      {getSteps().map((step) => {
        const labelProps = {};
        return (
          <Step key={step.label}>
            <StepLabel {...labelProps}>{step.label}</StepLabel>
          </Step>
        );
      })}
    </StyledStepper>
  );
};
