import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {ADD_FQ, useSearchDispatch} from "../search/SearchContext";
import {HIDE, useSearchResultDetailsDispatch} from "../search-result-details/SearchResultDetailsContext";

/**
 * Component used to render the chips displayed on the objectview page.
 * @param refTitle
 * @param smallScreen
 * @param values
 * @returns {JSX.Element}
 * @constructor
 */
export const ChipList = ({refTitle, smallScreen, values}) => {

    const searchDispatch = useSearchDispatch();
    const dispatch = useSearchResultDetailsDispatch();

    /**
     * Sets the chip's value as a search filter value and executes a search, when a chip is clicked.
     * @param key
     * @param value
     */
    const onChipClick = (key, value) => {
        if (smallScreen) {
            return;
        }

        searchDispatch({
            type: ADD_FQ,
            fq: {
                key: key,
                value: `"${value}"`,
            },
        });
        dispatch({type: HIDE});
    };


    if (!values || !Array.isArray(values)) {
        return <></>;
    }

    return (
        <Box>
            {values.length > 0 &&
                values.map((l) => {
                    const {value, title, nameAndTitle} = l;
                    return (
                        <Chip
                            key={value || l.reference.uniqueId}
                            label={title || l.reference.title}
                            title={nameAndTitle || ''}
                            color={"secondary"}
                            onClick={() =>
                                refTitle != null
                                    ? onChipClick(refTitle, l.reference.title)
                                    : void 0
                            }
                            sx={{
                                margin: 1,
                            }}
                        />
                    );
                })}
        </Box>
    );
};