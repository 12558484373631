import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";


/**
 * Renders an error icon in a file card if the `show` prop is true.
 *
 * @param {Object} props - The component props.
 * @param {boolean} props.show - Determines whether to show the error icon or not.
 * @return {JSX.Element|null} The error icon component or null if `show` is false.
 */
export const FileCardErrorIcon = ({show}) => {
    if (show) {
        return (
            <Box sx={{
                position: "absolute",
                top: '1rem',
                right: '1rem',
            }}>
                <ErrorOutlineIcon color={"error"}/>
            </Box>
        );
    } else {
        return null;
    }
};
