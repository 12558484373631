import {getBrowserLocale} from "../utility";

export const kulturnavAutoComplete = path => {
    const url = `https://kulturnav.org/api/autocomplete?${path}`;
    return fetch(url, {
        headers: {
            'Accept-Language': 'no'
        }
    }).then(response => response.json);
};

export const kulturnavApiCoreFetch = (params) => {
    const locale = getBrowserLocale(true);
    const url = `https://kulturnav.org/api/core/${params}&displayValues=true&lang=${locale}`;
    return fetch(url, {
        headers: {
            'Accept-Language': locale
        }
    }).then(response => response.json());
}

export const kulturnavFetch = (path) => {
    const kulturNavSummary = `https://kulturnav.org/api/summary`;
    return fetch(`${kulturNavSummary}${path}`, {
        headers: {
            "Accept-Language": "no",
        },
    }).then((response) => response.json());
};

export const arkeologiServerFetch = (path) => {
    const arkeologiApi = `https://arkeologi.org/api/`;
    return fetch(`${arkeologiApi}${path}`, {
        headers: {
            "Accept-Language": "no",
        },
    }).then((response) => response.json());
};

export const dataArcheologyServerFetch = (path) => {
    const dataArcheologyApi = `https://data.arkeologi.org/api/`;
    return fetch(`${dataArcheologyApi}${path}`, {
        headers: {
            "Accept-Language": "no",
        },
    }).then((response) => response.json());
};
