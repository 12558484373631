import {damsFetch} from "../app/damsFetch";
import {useState} from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {getFileExtensionFromFilename} from "../damsFileObjectDefinitions";
import {usePostDocuments} from "../documents/usePostDocuments";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

export const UpdateFileSizeProperty = () => {

    // eslint-disable-next-line no-unused-vars
    const [postDocumentsResponse, postDocuments] = usePostDocuments(); // NOSONAR
    const [currentRows, setCurrentRows] = useState('');
    const [totalRows, setTotalRows] = useState(0);
    const [done, setDone] = useState('false');


    const setFileExtension = model => {
        const fileExtension = getFileExtensionFromFilename(model.content.mediae[0].reference.title);
        model.content.mediae[0].reference = {...model.content.mediae[0].reference, "fileExtension": fileExtension};
        return postDocuments([model]);
    };

    const search = startRow => {
        const url = '/search?q=*&sort=title+asc&fl=null&expand=true' +
            '&start=' + startRow +
            '&rows=10' +
            '&document_type=%28StillImage+OR+Audio+OR+Video+OR+Misc+OR+Dokument+OR+Modell+OR+Geodata+OR+Tabell%29' +
            '&facet_field=persons.reference.title%2Cproducer.reference.title%2Cplaces.reference.title%2Clicenses.reference.title%2Csubjects.reference.title%2Ccreated_by_name%2Clanguages.title%2Cdocument_type%2Cmediae.reference.mime_type%2Cmediae.reference.duration_seconds%2Carcheologyprojectref.type%2Carcheologyprojectref.active%2Ccopyright_type%2Ccopyright_info.title' +
            '&facet_limit=100' +
            '&stats_field=created_at%2Cupdated_at%2Cproduction_date%2Cmediae.reference.duration_seconds' +
            '&museums=20%2C4%2C1' +
            '&only_mine=false';

        setCurrentRows(`${startRow} - ${startRow + 10}`);

        damsFetch(url).then(json => {
            if (totalRows === 0) {
                setTotalRows(json.count);
            }
            const promises = json.models.map(m => setFileExtension(m));
            Promise.allSettled(promises).then(() => {
                const total = parseInt(json.count);
                const sr = startRow + parseInt(json.rows);
                if (sr <= total) {
                    search(sr);
                } else {
                    setDone('true');
                }
            });
        });
    };

    const handleRunClick = () => {
        search(0);
    };

    return (
        <>
            <Divider sx={{marginBottom: '16px', marginTop: '16px'}}/>
            <Box>

                <Typography>Legge til data om "file extension" på eksisterende modeller:</Typography>
                <Typography>Antall objekter: {totalRows}</Typography>
                <Typography>Gjeldende: {currentRows}</Typography>
                <Typography>Ferdig: {done}</Typography>
                <Button variant={"contained"} onClick={handleRunClick}>Run</Button>
            </Box></>);

};