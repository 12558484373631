import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import {FilterCheckbox} from "../../search/filters/FilterCheckbox";
import ListItemText from "@mui/material/ListItemText";


/**
 * Renders a list of checkboxes based on the provided options, facets, and event handlers.
 *
 * @param {Object} options - The options object containing the available checkbox values.
 * @param {Object} facets - The facets object containing the counts for each checkbox value.
 * @param {function} handleCheckboxClick - The event handler for checkbox clicks.
 * @param {Array} selected - The array of currently selected checkbox values.
 * @param {string} facetKey - The key used to identify the facet in the facets object.
 * @return {Array} An array of JSX elements representing the checkboxes.
 */
export const renderCheckboxes = (options, facets, handleCheckboxClick, selected, facetKey) => {
    return Object.keys(options).map(o => {
        let items;
        if (facets[o]) {
            let displayValue = o;
            if (facetKey === 'created_by_name') {
                displayValue = o.replace(/"/g, '');
            }
            items = <ListItem
                key={o}
                dense={true}
                disablegutters={"true"}
                onClick={handleCheckboxClick}
            >
                <ListItemIcon>
                    <FilterCheckbox
                        selected={selected}
                        subjectsKey={facetKey}
                        value={o}
                    />
                </ListItemIcon>
                <ListItemText id={`label-${o}`}>
                    {displayValue} ({facets[o]?.count || 0})
                </ListItemText>
            </ListItem>;
        }
        return items;
    });
};