import {DamsFileIcon} from "../../files/DamsFileIcon";
import React, {useEffect, useState} from "react";
import ThreeDRotationOutlinedIcon from "@mui/icons-material/ThreeDRotationOutlined";
import {useStyles} from "./resultCardStyles";
import PropTypes from "prop-types";

const ResultCardThreeDIcon = ({mimeType, model, icon = true}) => {
    const [filename, setFilename] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        if (!model || !model.content.mediae) {
            return;
        }
        const fName = model.content.mediae[0].reference.title;
        setFilename(fName);
    }, [model]);

    return (
        <>
            <div className={classes.fileThumbContainer}>
                <ThreeDRotationOutlinedIcon sx={{fontSize: 135, color: "#bbb"}}/>
            </div>
            <div className={classes.fileTypeContainer}>
                {icon && <DamsFileIcon mimeType={mimeType} filename={filename} size={36}/>}
            </div>
        </>
    );
};

ResultCardThreeDIcon.propTypes = {
    "mimeType": PropTypes.string.isRequired,
    "model": PropTypes.object.isRequired,
    "icon": PropTypes.bool
};

export {ResultCardThreeDIcon};