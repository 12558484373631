import React, { createContext, useContext, useReducer } from "react";
export const CHANGE_STEP = "newFilesContext/changeStep";
export const SHOW_PRE_CHANGE = "newFilesContext/showPreChange";
export const HIDE_PRE_CHANGE = "newFilesContext/hidePreChange";
export const SET_NUM_STEPS = "newFilesContext/setNumSteps";

const NewFilesStateContext = createContext(undefined);
const NewFilesDispatchContext = createContext(undefined);

const initialState = {
  previousStepIndex: 0,
  activeStepIndex: 0,
  nextActiveStepIndex: 0,
  showPreStep: false,
  stepsWithPreCheck: [1],
  numSteps: 0,
};

const newFilesReducer = (state, action) => {
  switch (action.type) {
    case SHOW_PRE_CHANGE:
      return {
        ...state,
        showPreStep: true,
      };
    case HIDE_PRE_CHANGE:
      return {
        ...state,
        showPreStep: false,
      };
    case CHANGE_STEP:
      return {
        ...state,
        previousStepIndex: state.activeStepIndex,
        activeStepIndex: action.stepIndex,
      };
    case SET_NUM_STEPS:
      return {
        ...state,
        numSteps: action.numSteps,
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const NewFilesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    newFilesReducer,
    initialState,
    undefined
  );

  return (
    <NewFilesStateContext.Provider value={state}>
      <NewFilesDispatchContext.Provider value={dispatch}>
        {children}
      </NewFilesDispatchContext.Provider>
    </NewFilesStateContext.Provider>
  );
};

export const useNewFilesState = () => {
  const context = useContext(NewFilesStateContext);
  if (undefined === context) {
    throw new Error(
      "useNewFilesState must be used within an NewFilesProvider."
    );
  } else {
    return context;
  }
};

export const useNewFilesDispatch = () => {
  const context = useContext(NewFilesDispatchContext);
  if (undefined === context) {
    throw new Error(
      "useNewFilesDispatch must be used within an NewFilesProvider."
    );
  } else {
    return context;
  }
};
