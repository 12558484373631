import Typography from "@mui/material/Typography";
import React from "react";
import {useNewFilesTranslation} from "../../files/NewFilesTranslationContext";

/**
 * Renders the text for adding metadata information.
 *
 * @return {JSX.Element} The JSX elements for displaying metadata information.
 */
export const AddMetadataInfoText = () => {
    const t = useNewFilesTranslation();
    return <>
        <Typography variant={"h6"}>
            {t("addMetadata", "Legg til metadata")}
        </Typography>
        <Typography variant={"body1"}>
            {t(
                "addMetadataHelp",
                "Velg bilder for å redigere metadata. Velg flere bilder samtidig for å legge til den " +
                "samme informasjonen på alle de valgte bildene. Bilder må ha minimum " +
                "tittelfeltet utfylt for å kunne lagres."
            )}
        </Typography>
    </>;
};