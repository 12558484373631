import React from "react";
import {styled} from "@mui/material/styles";
import {WithSaveCircle} from "../app/WithSaveCircle";
import Grow from "@mui/material/Grow";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";

const PREFIX = "ModalCreateResource";

const classes = {
    modal: `${PREFIX}-modal`,
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
};

const StyledModal = styled(Modal)(({theme}) => ({
    [`&.${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.content}`]: {
        outline: "none",
        minWidth: 500,
        minHeight: 400,
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(4),
        height: "100%",
    },
}));

export const ModalCreateResource = ({open, onHide, children}) => {
    return (
        <StyledModal
            className={classes.modal}
            open={open}
            onClose={onHide}
            closeAfterTransition
        >
            <div className={classes.content}>
                <WithSaveCircle saving={false}>
                    <Grow in={open}>
                        <Paper className={classes.paper}>{children}</Paper>
                    </Grow>
                </WithSaveCircle>
            </div>
        </StyledModal>
    );
};
