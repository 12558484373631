import Stack from "@mui/material/Stack";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import React from "react";
import {
    TOGGLE_ALL_SELECTED,
    useFileUploadDispatch,
    useFileUploadState,
    useFileUploadTranslation
} from "./fileUploadContext";

/**
 * Header displayed above the list of uploaded objects.
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectedObjectsHeader = ({children}) => {
    const t = useFileUploadTranslation();
    const {allSelected, selected, uploadedFiles} = useFileUploadState();
    const fileUploadDispatch = useFileUploadDispatch();

    const handleSelectAllChange = () => {
        fileUploadDispatch({
            type: TOGGLE_ALL_SELECTED,
            uniqueIds: uploadedFiles.map(f => (f.uniqueId))
        });
    };
    const numSelected = selected.length;
    return <Stack direction={"row"}
                  alignItems={'center'}
                  justifyContent={"space-between"}
                  sx={{marginBottom: '16px'}}>
        <Stack direction={"row"} alignItems={'center'}>
            <Checkbox
                checked={allSelected}
                indeterminate={numSelected > 0 && !allSelected}
                onChange={handleSelectAllChange}
                color={"secondary"}
            />
            {!allSelected && numSelected === 0 && <Typography>
                {t('selectedObjectsHeaderMarkAll', 'Merk alle')}
            </Typography>}
            {!allSelected && numSelected > 0 && <Typography>
                {t('selectedObjectsHeaderNumSelected', '{{numSelected}} av {{numUploadedFiles}} merket', {
                        numSelected: numSelected,
                        numUploadedFiles: uploadedFiles.length
                    }
                )}
            </Typography>}
            {allSelected && <Typography>
                {t('selectedObjectsHeaderUnmarkAll', 'Avmerk alle')}
            </Typography>}
        </Stack>
        {children}
    </Stack>;
}