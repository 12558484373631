import {useEffect} from "react";
import {damsFetch} from "../app/damsFetch";
import {SET_MUSEUM_COLLECTIONS, useAuthsDispatch, useAuthsState} from "../auths/authsContext";
import {clientLog} from "../clientLog";
import {useFolderSearch} from "./useFolderSearch";
import {SET_SEARCHING, useFoldersDispatch, useFoldersState} from "./FoldersContext";
import {useDocumentState} from "../documents/documentContext";
import {useFileUploadState} from "../damsfileupload/fileUploadContext";

/**
 * Hook used to get the list of folders available, when uploading data.
 */
export const useGetUploadFolders = ({fileUploaderV2}) => {
    const authsDispatch = useAuthsDispatch();
    const {query, start, rows, fq} = useFoldersState();
    const {userData, museumCollections} = useAuthsState();
    const {formData} = useDocumentState();
    const foldersDispatch = useFoldersDispatch(); // Used by fileuploader v1
    const {collectionId} = useFileUploadState(); // Used by filuploader v2

    useEffect(() => {
        if (!museumCollections || museumCollections.length === 0) {
            const username = userData.name;
            damsFetch("/current-user/", {
                method: "POST",
                body: JSON.stringify({name: username}),
            }).then((_) => {
                damsFetch(`/collections/my-museum-collections/`)
                    .then((json) => {
                        authsDispatch({
                            type: SET_MUSEUM_COLLECTIONS,
                            museumCollections: json,
                        });
                    })
                    .catch(error => {
                        clientLog('error', error, 'AvailableFolders');
                    });
            });
        }
    }, [authsDispatch, museumCollections, userData.name]);

    let cid = formData.collectionId;

    if (fileUploaderV2) {
        cid = collectionId;
    }

    const selectedMuseumId = museumCollections?.find(
        (mc) => mc.collectionId === cid
    )?.museumId;

    useFolderSearch({
        query: query,
        sort: "title asc",
        start: start,
        rows: rows,
        documentType: '(Folder)',
        folderType: '(!ProjectArcheology !ProjectGeneral)',
        fq: fq,
        museums: selectedMuseumId,
        status: '(-trash)',
        onlyMine: false,
        only_writeable: true,
    });

    /**
     * Hook used to fetch the list of folders when the GUI is initially loaded.
     */
    useEffect(() => {
        if (!selectedMuseumId) {
            return;
        }
        foldersDispatch({type: SET_SEARCHING});
    }, [])
};