import {useEffect, useState} from "react";

export const useMarkFiles = (files) => {
    const [checked, setChecked] = useState({});
    const [lastChecked, setLastChecked] = useState("");

    const checkFile = (fileTitle) => (e) => {
        if (e.shiftKey && "" !== lastChecked) {
            if (0 > fileTitle.localeCompare(lastChecked)) {
                setChecked((c) => ({
                    ...c,
                    ...Object.keys(c)
                        .filter(
                            (file) =>
                                0 > file.localeCompare(lastChecked) &&
                                0 <= file.localeCompare(fileTitle)
                        )
                        .reduce(
                            (acc, current) => ({
                                ...acc,
                                [current]: true,
                            }),
                            {}
                        ),
                }));
            } else {
                setChecked((c) => ({
                    ...c,
                    ...Object.keys(c)
                        .filter(
                            (file) =>
                                0 < file.localeCompare(lastChecked) &&
                                0 >= file.localeCompare(fileTitle)
                        )
                        .reduce(
                            (acc, current) => ({
                                ...acc,
                                [current]: true,
                            }),
                            {}
                        ),
                }));
            }
        } else {
            setLastChecked((lc) =>
                lc === fileTitle && checked[fileTitle] ? "" : fileTitle
            );
            setChecked((c) => ({
                ...c,
                [fileTitle]: !c[fileTitle],
            }));
        }
    };

    const checkAll = () => {
        setLastChecked("");
        setChecked(
            files.reduce(
                (acc, current) => ({
                    ...acc,
                    [current.title]: true,
                }),
                {}
            )
        );
    };

    const clearAll = () => {
        setLastChecked("");
        setChecked(
            files.reduce(
                (acc, current) => ({
                    ...acc,
                    [current.title]: false,
                }),
                {}
            )
        );
    };

    const cleanUp = () => {
        const availableFileTitles = files.map((file) => file.title);
        setChecked((c) =>
            Object.keys(c)
                .filter((checkedFileName) =>
                    availableFileTitles.includes(checkedFileName)
                )
                .reduce(
                    (acc, current) => ({
                        ...acc,
                        [current]: checked[current],
                    }),
                    {}
                )
        );
    };

    const getCheckedFilenames = () =>
        Object.keys(checked).filter((fileName) => checked[fileName]);

    const numberOfChecked = () => getCheckedFilenames().length;

    useEffect(() => {
        cleanUp();
    }, [files]);

    return [
        checked,
        checkFile,
        checkAll,
        clearAll,
        getCheckedFilenames,
        numberOfChecked,
    ];
};
