import {useSearchTranslation} from "../search/SearchContext";
import {useEffect, useState} from "react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Switch} from "@mui/material";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import PropTypes from "prop-types";

/**
 * Used to decide whether to activate the multiple choice panel information or not.
 * If multiple files are specified, the panel will be displayed.
 *
 * @param {object} objectType - the type of object (e.g., 'video' or 'audio')
 * @param {boolean} multiple - flag indicating whether multiple files are selected
 * @param {function} formatCallback - callback function for handling format changes
 * @return {JSX.Element} The JSX element containing the multiple choice panel
 */
const SetDownloadFormat = ({objectType, multiple, formatCallback}) => {
    const t = useSearchTranslation();

    const [checked, setChecked] = useState(false);
    const [radioOption, setRadioOption] = useState('original');

    /**
     * Handle changes to the switch that turns custom audio format on/off.
     * @param event
     */
    const handleSwitchToggle = (event) => {
        const format = event.target.checked
        setChecked(format);
        formatCallback({format: format, selected: checked});
    };

    /**
     * Handle changes to the radio buttons for selecting the download format.
     * @param event
     */
    const handleRadioChange = event => {
        const format = event.target.value;
        setRadioOption(format);
        formatCallback({format: format, selected: checked});
    };

    /**
     * Give the user a choice of different video formats.
     * NOTE: This is only displayed if multiple files have been chosen.
     * @returns {JSX.Element}
     */
    const getMultipleChoicePanel = () => {
        if (!multiple) {
            return <></>;
        } else {
            return <>
                <Divider orientation={"horizontal"} sx={{width: '100%', marginTop: '8px', marginBottom: '8px'}}/>
                <Typography variant={"subtitle1"} mt={2} mb={1}>
                    {
                        objectType === 'video'
                            ? t('oneOrMoreIsVideo', 'Vi ser at en eller flere av de merkede filene er video-filer')
                            : t('oneOrMoreIsAudio', 'Vi ser at en eller flere av de merkede filene er lydfiler')
                    }
                </Typography>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={checked}
                                         onChange={handleSwitchToggle}/>}
                        label={
                            objectType === 'video'
                                ? t('useCustomVideoFormat', 'Ønsker å benytte et annet video-format')
                                : t('useCustomAudioFormat', 'Ønsker å benytte et annet lydformat')
                        }
                    />
                </FormGroup>
            </>;
        }
    };

    /**
     * Returns JSX elements representing format options based on the object type.
     *
     * @return {JSX.Element} JSX elements representing format options
     */
    const getFormatOptions = () => {
        if (objectType === 'video') {
            return <>
                <FormControlLabel value="original" control={<Radio/>}
                                  label={t('videoFormatOptionOriginal', 'Original')}/>
                <FormControlLabel value="mp4" control={<Radio/>}
                                  label={t('videoFormatOptionMp3', 'mp4')}/>
            </>;
        } else {
            return <>
                <FormControlLabel value="original" control={<Radio/>}
                                  label={t('audioFormatOptionOriginal', 'Original')}/>
                <FormControlLabel value="mp3" control={<Radio/>}
                                  label={t('audioFormatOptionMp3', 'mp3')}/>
            </>
        }
    };

    /**
     * Hook used to decide whether to activate the multiple choice panel information or not.
     * If multiple files are specified, the panel will be displayed.
     */
    useEffect(() => {
        setChecked(!multiple);
    }, [multiple]);

    return <Box>
        {getMultipleChoicePanel()}
        <Box sx={{
            visibility: !checked ? 'hidden' : 'visible'
        }}>
            <FormControl>
                <RadioGroup
                    aria-labelledby="custom-format-group"
                    defaultValue={radioOption}
                    name="radio-custom-format-group"
                    value={radioOption}
                    onChange={handleRadioChange}>
                    {getFormatOptions()}

                </RadioGroup>
            </FormControl>
        </Box>
    </Box>;
};

SetDownloadFormat.propTypes = {
    objectType: PropTypes.string.isRequired,
    multiple: PropTypes.bool.isRequired,
    formatCallback: PropTypes.func.isRequired
};

export {SetDownloadFormat};