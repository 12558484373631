import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import React from "react";
import PropTypes from "prop-types";

const ButtonInfo = ({clickCallback}) => {
    return <IconButton
        onClick={clickCallback}
        sx={{
            color: "white",
            "&:hover": {color: "orange"},
        }}
    >
        <InfoIcon/>
    </IconButton>;
};

ButtonInfo.propTypes = {
    clickCallback: PropTypes.func.isRequired
};

export {ButtonInfo};