import Snackbar from "@mui/material/Snackbar";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";
import {useCreateFolderState} from "./CreateFolderContext";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import AlertTitle from "@mui/material/AlertTitle";
import PropTypes from "prop-types";

/**
 * Renders a progress snackbar component for creating a new folder.
 *
 * @param {function} t - Translation function for language localization
 * @return {JSX.Element} The rendered progress snackbar component
 */
const ProgressSnackbar = ({t}) => {
    const {progress} = useCreateFolderState();
    return <Snackbar open={true}
                     anchorOrigin={{
                         vertical: "top",
                         horizontal: "right"
                     }}
                     key={"create-folder-snackbar"}
    >

        <Alert severity={"success"}
               variant={"filled"}
               key={"create-folder-snackbar-alert"}>
            <AlertTitle>
                {t('progressSnackbarCreateFolder', 'Lag ny mappe')}
            </AlertTitle>
            <Box key={"create-folder-snackbar-alert-folder-status"}>
                <Typography>{t('progressSnackbarConfirmCreated', 'Mappe opprettet')}</Typography>
                <Box>
                    <Typography
                        sx={{marginBottom: '8px'}}>{t('progressSnackbarAddingDocuments', 'Legger dokumenter i mappen')}</Typography>
                    <LinearProgress variant={"determinate"}
                                    value={progress} color={"secondary"}/>
                </Box>
            </Box>
        </Alert>
    </Snackbar>;
};


ProgressSnackbar.propTypes = {
    "t": PropTypes.func.isRequired
};

export {ProgressSnackbar};