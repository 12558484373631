import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {DocumentTypeProvider,} from "../document-type/documentTypeContext";
import {SearchResultDetailsProvider} from "../search-result-details/SearchResultDetailsContext";
import {
    CLEAR_RESULTS,
    SET_QUERY,
    SET_SEARCHING,
    UNSELECT_ALL,
    UPDATE_FQ,
    useSearchDispatch,
    useSearchState,
} from "../search/SearchContext";
import {ALL_MUSEUMS, useMuseumDispatch} from "../museum/MuseumContext";
import {SETTINGS_SAVED, useFoldersDispatch, useFoldersState, useFoldersTranslation,} from "./FoldersContext";
import {useGetFolder} from "./useGetFolder";
import {useAuthsState} from "../auths/authsContext";
import {usePostFolder} from "./usePostFolder";
import {FormFolderSettings} from "./admin/FormFolderSettings";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import {useFolderShareEmail} from "./useFolderShareEmail";
import {getEmailAcls} from "./FolderUtilities";
import Box from "@mui/material/Box";
import {FolderTitleToolbar} from "./FolderTitleToolbar";
import useDeepCompareEffect from "use-deep-compare-effect";
import {clientLog} from "../clientLog";
import {EditFolderButton} from './admin/EditFolderButton'
import {saveNewTags} from "./tags/saveNewTags";
import {SearchResults} from "../search/SearchResults";

/**
 * Renders the contents of a folder, including its metadata, search results, and form for editing metadata.
 *
 * @return {JSX.Element} The folder contents component.
 */
export const FolderContents = () => {
    const t = useFoldersTranslation();

    const searchDispatch = useSearchDispatch();
    const museumDispatch = useMuseumDispatch();
    const snackbarDispatch = useSnackbarDispatch();
    const foldersDispatch = useFoldersDispatch();

    const params = useParams();

    const folder = useGetFolder(params.folderId);

    const {fq} = useSearchState();
    const {settingsSaved, newFolder} = useFoldersState();
    const {damsId} = useAuthsState();

    const [inputValue, setInputValue] = useState("");
    const [openMetadataDialog, setOpenMetadataDialog] = useState(false);
    const [editedFolderResponse, postEditedFolder] = usePostFolder();
    const [tags, setTags] = useState([]);
    // eslint-disable-next-line no-unused-vars
    const [setEmailRecipients, sendEmailNotifications] = useFolderShareEmail(); // NOSONAR
    const [newEmailRecipients, setNewEmailRecipients] = useState(null);
    const [showResults, setShowResults] = useState(false);

    const isMyFolder = folder?.data?.createdById === damsId;

    /**
     *  Saves new tags to the DAMS database, if required, and appending them to the folderData object.
     * @param {string} collectionId - The ID of thee collection.
     * @param {Array<Object>} tagsArr - The aray of tags.
     * @returns {Promise<Array<Object>>} - The updated array of tags.
     */
    const saveNewTagsToDams = async (collectionId, tagsArr) => {
        if (!tagsArr || tagsArr.length === 0) {
            return [];
        }
        const createNewTags = tagsArr.filter(t => typeof (t.title) !== 'undefined');
        const res = await saveNewTags(collectionId, createNewTags);

        const mappedTags = [...createNewTags]
            .filter(t => t.id)
            .map(t => {
                return {
                    collectionId: t.collectionId,
                    referenceType: t.documentType,
                    reference: {
                        id: t.id,
                        title: t.inputValue || t.title,
                        locale: t.locale,
                        source: t.source,
                        status: t.status,
                        schema_id: t.schema_id,
                        unique_id: t.uniqueId,
                        created_at: t.createdAt,
                        updated_at: t.updatedAt,
                    }
                }
            });
        if (createNewTags && res) {
            return [...res.results, ...mappedTags];
        } else {
            return createNewTags;
        }
    };

    /**
     * Gets, and sets the list of recipients that will receive an e-mail, when given access to the folder.
     * @param emailsAcl
     * @private
     */
    function _setEmailRecipients(emailsAcl) {
        if (folder.data.acls?.length > 0) {
            const newEmails = emailsAcl.filter(acl => {
                if (!folder.data.acls.map(eacl => (eacl.email)).includes(acl.email)) {
                    return acl;
                }
            });
            setNewEmailRecipients(newEmails);
        } else {
            setNewEmailRecipients(emailsAcl);
        }
    }

    /**
     * Adds tags to the folder.
     *
     * @param {Object} data - The data containing the tags to be added.
     * @param {string} data.collectionId - The ID of the collection.
     * @param {Array} data.tags - The list of tags to be added.
     * @param {string} data.tags[].inputValue - The value of the tag.
     * @return {Promise<void>} - A promise that resolves when the tags are added.
     */
    async function addTags(data) {
        const {collectionId} = folder.data;

        // Sanitize the list of existing tags, by removing the one removed from the user when
        // administrating the folder's metadata.
        const existingTags = [];
        for (let i = 0, max = data.tags.length; i < max; i++) {
            const t = data.tags[i];
            const ix = tags.findIndex(tt => tt.reference.title === t.inputValue);
            if (ix >= 0) {
                existingTags.push(tags[ix]);
            }
        }

        const newTags = await saveNewTagsToDams(collectionId, data.tags);
        if (newTags && newTags?.length > 0) {
            folder.data.content.tags = [...existingTags, ...newTags];
        } else {
            folder.data.content.tags = existingTags;
        }
    }

    /**
     * Saves the metadata for a folder.
     *
     * @param {Object} data - The metadata to be saved.
     * @return {Promise<void>} - A promise that resolves when the metadata is saved.
     */
    const saveMetadata = async (data) => {
        if (!data) {
            setOpenMetadataDialog(false);
            return;
        }

        await addTags(data);

        const emailsAcl = getEmailAcls(data);   // Updated ACLs
        _setEmailRecipients(emailsAcl);

        // Save updated metadata.
        folder.data.title = data.folderName;
        folder.data.status = data.folderAvailability;
        folder.data.acls = emailsAcl;
        postEditedFolder(folder.data);
    };

    /**
     * Handles the Enter key press event and performs a search operation.
     *
     * @param {Event} event - The keydown event object.
     * @return {void} This function does not return anything.
     */
    const searchOnEnter = (event) => {
        if ("Enter" === event.key) {
            searchDispatch({
                type: SET_QUERY,
                query: inputValue,
                folderContext: true,
            });

            searchDispatch({
                type: SET_SEARCHING
            });
        }
    };

    function getFolderTitle() {
        let folderTitle = null;

        if (settingsSaved) {
            folderTitle = newFolder.name;
        }
        if (!folderTitle) {
            folderTitle = folder?.data?.title;
        }
        return folderTitle;
    }

    const getFolderToolbar = () => {
        if (params.folderId) {
            return (
                <FolderTitleToolbar
                    tags={tags}
                    folderTitle={getFolderTitle()}
                    editFolderButton={<EditFolderButton myFolder={isMyFolder}
                                                        collectionId={folder?.data?.collectionId}
                                                        callback={handleEditFolder}/>}
                    searchOnEnterCallback={searchOnEnter}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                />
            );
        } else {
            return null;
        }
    };

    const getForm = () => {
        if (!folder.data) {
            return;
        }
        return <FormFolderSettings
            open={openMetadataDialog}
            model={folder.data}
            callback={(values) => saveMetadata(values).then()}
        />;
    };

    const getSearchResults = () => {
        if (
            fq.find(
                (field) => "folder_ids" === field.key && params.folderId === field.value
            )
        ) {
            return <SearchResults folder={folder.data}/>;
        } else {
            return null;
        }
    };

    const handleEditFolder = () => {
        setOpenMetadataDialog(true);
    };

    /**
     * Hook used to get the folder's contents.
     */
    useEffect(() => {
        if (params.folderId && !showResults) {
            searchDispatch({
                type: UPDATE_FQ,
                fq: {
                    key: "folder_ids",
                    value: params.folderId,
                },
            });
            museumDispatch({type: ALL_MUSEUMS});
            searchDispatch({type: CLEAR_RESULTS});
            searchDispatch({type: UNSELECT_ALL});

            searchDispatch({
                type: SET_QUERY,
                query: "*:*",
                folderContext: true,
            });
        }
    }, [params.folderId]);


    /**
     * Hook used to extract the folder's tags from the folder data.
     */
    useEffect(() => {
        if (!folder?.data?.content?.tags) {
            return;
        }
        setTags(folder?.data?.content?.tags);
    }, [folder]);

    /**
     * Hook used to inform the user that metadata has been saved.
     */
    useDeepCompareEffect(() => {
        if (editedFolderResponse.error) {
            snackbarDispatch({
                type: ADD_MESSAGE,
                message: {
                    title: t("saveFolderMetadataFailedTitle", "Feil ved lagring"),
                    body: t(
                        "saveFolderMetadataFailedMessage",
                        "Det oppstod en feil ved lagring av metadata for folderen"
                    ),
                    type: "error",
                },
            });
        } else if (editedFolderResponse.success) {
            foldersDispatch({
                type: SETTINGS_SAVED,
                newFolder: editedFolderResponse.newFolder,
            });

            setOpenMetadataDialog(false);
            setTags(editedFolderResponse.newFolder.tags);

            if (Array.isArray(newEmailRecipients) && newEmailRecipients.length > 0) {
                sendEmailNotifications(params.folderId, newEmailRecipients)
                    .then((res) => {
                        const sent =
                            [...res.results].filter(r => r.success)?.length;
                        const failed =
                            [...res.results].filter(r => !r.success)?.length;

                        clientLog('info', `e-mails sent: ${sent}`, 'foldercontents');
                        clientLog('warn', `e-mails failed: ${failed}`, 'foldercontents');
                    });
            }
        }
    }, [editedFolderResponse, editedFolderResponse.newFolder]);

    /**
     * Hook used to prevent displaying the search results until the folder-data and -contents are completely loaded.
     */
    useEffect(() => {
        if (!showResults && Object.keys(folder?.data).length > 0) {
            setShowResults(true);
        } else if (!showResults) {
            searchDispatch({type: SET_SEARCHING});
        }
    }, [folder]);


    return (showResults && (
            <Box sx={{flexGrow: '1', display: 'flex', flexDirection: 'column', overflow: 'hidden',}}
                 id="folder-contents-box">
                <SearchResultDetailsProvider>
                    <DocumentTypeProvider>
                        {getFolderToolbar()}
                        {getSearchResults()}
                    </DocumentTypeProvider>
                </SearchResultDetailsProvider>
                {getForm()}
            </Box>)
    );
};
