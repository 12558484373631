import React, {useRef} from "react";
import {Fade, Tooltip, useMediaQuery, useTheme} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import {useProjectTranslation} from "./projectContext";
import {
    PROJECT_SHOW_DETAILS_OVERLAY,
    useProjectViewDispatch,
    useProjectViewState
} from "./archeology/projectview/projectViewContext";
import {ArcheologyProjectViewDetails} from "./archeology/projectview/ArcheologyProjectViewDetails";
import {GeneralProjectViewDetails} from "./general/projectview/GeneralProjectViewDetails";
import {ButtonProjectActiveStateAdmin} from "./ButtonProjectActiveStateAdmin";
import {ButtonProjectMembersAdmin} from "./ButtonProjectMembersAdmin";
import {ButtonInfo} from "./archeology/ButtonInfo";
import {ProjectViewDetailsSmallScreen} from "./general/projectview/smallscreen/ProjectViewDetailsSmallScreen";
import {ButtonGeneralProjectAdmin} from "./general/ButtonGeneralProjectAdmin";
import {ArrowBack} from "@mui/icons-material";
import {ArcheologyProjectUserAdmin} from "./archeology/ArcheologyProjectUserAdmin";
import {
    ArcheologyProjectViewDetailsSmallScreen
} from "./archeology/projectview/smallscreen/ArcheologyProjectViewDetailsSmallScreen";


/**
 * Renders the header for the project view with project metadata and admin functionalities.
 *
 * @return {Element} The JSX element representing the project view header.
 */
export const ProjectViewHeader = () => {
    const {project, showDetails, showAdmin} = useProjectViewState();
    const projectViewDispatch = useProjectViewDispatch();
    const headerRef = useRef();
    const navigate = useNavigate();
    const t = useProjectTranslation();

    const theme = useTheme();

    const smallScreen = useMediaQuery("(max-width: 768px)");
    const smUp = useMediaQuery(theme.breakpoints.up('sm')); //600px up

    // Holds the project type: ProjectArcheology | ProjectGeneral
    const {folderType: projectType} = project;

    const handleInfoClick = () => {
        projectViewDispatch({
            type: PROJECT_SHOW_DETAILS_OVERLAY,
            project: project,
        });
    };

    /**
     * Displays the project's metadata in an overlay.
     * @param bgColor
     * @param overlayContent
     * @returns {Element}
     */
    const showOverlay = (bgColor, overlayContent) => {
        const headerHeight = headerRef?.current?.getBoundingClientRect().height;
        return (
            <Box
                sx={{
                    position: "absolute",
                    top: headerHeight + "px",
                    left: 0,
                    width: "100%",
                    height: "500px",
                    backgroundColor: bgColor,
                    borderTop: "solid 1px #6f6f6f",
                    zIndex: 1200,
                }}
            >
                {overlayContent}
            </Box>
        );
    };

    /**
     * Method used to display the project's metadata, based on the current project type.
     * @returns {*}
     */
    const getProjectDetails = () => {
        return showOverlay('#757575',
            projectType === 'ProjectArcheology'
                ? <ArcheologyProjectViewDetails model={project}/>
                : <GeneralProjectViewDetails model={project}/>
        );
    };

    // Admin users
    const getArcheologyProjectUserAdmin = () => {
        return showOverlay("white", <ArcheologyProjectUserAdmin/>);
    };

    return (
        <Box
            ref={headerRef}
            sx={{
                position: "relative",
                display: "flex",
                alignItems: "center",
                color: "white",
                backgroundColor: "#757575",
                paddingBlock: 1,
                paddingInline: {
                    xs: 1,
                    sm: 2,
                },
                marginInline: {
                    xs: 0,
                    md: 3,
                },
                borderRadius: {
                    xs: 0,
                    md: 1,
                },
            }}
        >
            <Tooltip title={t('tooltipTitle', 'Tilbake til prosjekter')}>
                <Avatar sx={{color: "white", height: '2rem', width: '2rem',}}>
                    <IconButton
                        onClick={() => navigate("/all-projects/")}
                        aria-label={t('lblToProjects', 'Til prosjekter')}
                    >
                        <ArrowBack/>
                    </IconButton>
                </Avatar>
            </Tooltip>

            {/* Project title */}
            <Typography
                variant={"h6"}
                fontWeight={"medium"}
                marginLeft={smallScreen ? 1 : 2}
                noWrap
                sx={{
                    flexShrink: 1,
                    flexBasis: 'auto',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}
            >
                {smUp && t('headingProject', 'Prosjekt') + ': '}
                {project.title}
            </Typography>

            {/* Adm. icons to the right */}
            <Box
                sx={{
                    marginLeft: 'auto',
                    display: 'flex',
                }}
            >
                <ButtonProjectActiveStateAdmin/>
                {projectType === 'ProjectArcheology' && <ButtonProjectMembersAdmin/>}
                {projectType === 'ProjectGeneral' && <ButtonGeneralProjectAdmin/>}
                <ButtonInfo clickCallback={handleInfoClick}/>
            </Box>
            {/*Show project details */}
            {smallScreen && (
                <>
                    {projectType === 'ProjectGeneral' && <ProjectViewDetailsSmallScreen show={showDetails}/>}
                    {projectType === 'ProjectArcheology' &&
                        <ArcheologyProjectViewDetailsSmallScreen show={showDetails}/>}
                </>
            )}
            {!smallScreen && (
                <>
                    <Fade in={showDetails}>{getProjectDetails()}</Fade>
                    <Fade in={showAdmin}>{getArcheologyProjectUserAdmin()}</Fade>
                </>
            )}
        </Box>
    );
};
