import {PromisePool} from "@supercharge/promise-pool";
import {extractMediaMeta} from "./dmsSecurity";
import {SET_METADATA_MAPPING_PROGRESS} from "../documents/documentContext";
import {clientLog} from "../clientLog";

export const getFilesMetadata = ({docDispatch, collectionId, dmsIds}) => {

    const componentName = 'getFilesMetadata';
    let retryCounter = 5;

    const getMetadata = async (dmsId) => {
        try {
            const data = await extractMediaMeta(collectionId, dmsId)
            return {
                dmsId: dmsId,
                data: data[0] // For some reason the data is returned as an array.
            };
        } catch (e) {
            if (e === 500) {
                throw e;
            } else {
                return retry(dmsId);
            }
        }
    };

    const retry = (dmsId) => {
        retryCounter--;
        if (retryCounter > 0) {
            clientLog('warn',
                `metadata fetching failed for ${dmsId} - remaining retries: ${retryCounter}`,
                componentName);
            setTimeout(() => {
                return getMetadata(dmsId);
            }, 200);
        } else {
            clientLog('error',
                `metadata fetching failed for ${dmsId} - even after retrying`,
                componentName);
            throw new Error();
        }
    };

    const run = async () => {
        if (!collectionId || dmsIds.length === 0) {
            return [];
        }
        return await PromisePool
            .withConcurrency(4)
            .for(dmsIds)
            .onTaskFinished((user, pool) => {
                // Update current stage progress
                const percentage = parseInt(pool.processedPercentage());
                docDispatch({type: SET_METADATA_MAPPING_PROGRESS, value: percentage});
            })
            .process(async (dmsId) => {
                return await getMetadata(dmsId);
            });
    };

    return {
        run: run
    };
};