import React from "react";
import {CLOSE, useResultActionDispatch} from "./ResultActionContext";
import {ListItemIcon, MenuItem} from "@mui/material";
import PropTypes from "prop-types";

const ResultAction = ({
                          displayValue,
                          actionType,
                          disabled = false,
                          icon = null,
                      }) => {
    const dispatch = useResultActionDispatch();

    const handleClick = () => {
        dispatch({type: actionType});
        dispatch({type: CLOSE});
    };

    const renderIcon = (_) => {
        if (!icon) {
            return <></>;
        }

        return <ListItemIcon>{icon}</ListItemIcon>;
    };

    return (
        <MenuItem disabled={disabled} onClick={handleClick}>
            {renderIcon()}
            {displayValue}
        </MenuItem>
    );
};

ResultAction.propTypes = {
    displayValue: PropTypes.any.isRequired,
    actionType: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    icon: PropTypes.object
};

export {ResultAction};