import React, {useCallback} from "react";
import {useDropzone} from "react-dropzone";
import {ADD_FILES, useDocumentDispatch, useDocumentState,} from "../documents/documentContext";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {useTheme} from "@emotion/react";
import {getFileExtensionFromFilename, getMimeTypeFromFilename} from "../damsFileObjectDefinitions";

export const UploadFiles = ({hide = false}) => {
    const theme = useTheme();
    const imageDispatch = useDocumentDispatch();
    const {formData} = useDocumentState();
    const t = useNewFilesTranslation();

    const onDrop = useCallback(
        (acceptedFiles) => {

            const filesArr = acceptedFiles.map((file) => ({
                title: file.name,
                filesize: file.size,
                fileextension: getFileExtensionFromFilename(file.name),
                mimeType: getMimeTypeFromFilename(file.name),
                file: file,
                queued: false,
            })).filter(f => f.mimeType !== null);

            imageDispatch({
                type: ADD_FILES,
                files: filesArr,
            });
        },
        [imageDispatch]
    );

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const dragDropText = () =>
        isDragActive
            ? t("releaseFiles", "Slipp filene for å legge de til")
            : t("dragOrClick", "Dra filer over hit eller klikk for å velge");

    const getAcceptFiles = () => {
        switch (formData.documentType) {
            case "StillImage":
                return "image/*";
            case "Video":
                return "video/*";
            case "Audio":
                return "audio/*";
            case "Misc":
                return "misc/*";
            default:
                throw new Error(
                    `No accept files implemented for ${formData.documentType}`
                );
        }
    };

    return (
        <Box
            {...getRootProps()}
            sx={{
                outline: "none",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexGrow: 1,
                display: hide ? "none" : "flex",
                padding: theme.spacing(8),
            }}
        >
            <CloudUploadIcon
                color={"disabled"}
                sx={{height: "10em", width: "10em"}}
            />

            <input {...getInputProps()} accept={getAcceptFiles()}/>
            <Typography align="center" variant={"h5"}>
                {dragDropText()}
            </Typography>
        </Box>
    );
};
