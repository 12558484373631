import {DialogWrapper} from "../../app/DialogWrapper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ListItemButton} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import MuseumIcon from "@mui/icons-material/Museum";
import ListItemText from "@mui/material/ListItemText";
import {useAuthsState} from "../../auths/authsContext";
import PropTypes from "prop-types";

/**
 * Renders a dialog that allows the user to select a museum from the list of museums he/she has access to.
 * @param {String} title - The title of the dialog.
 * @param {int} collectionId - The collection ID of the currently selected collection/museum (project context).
 * @param {function} t - The translation function.
 * @param {boolean} projectContext - Flag indicating if the dialog is displayed in project context.
 * @param {boolean} openDialog - Flag indicating if the dialog is open.
 * @param {Array} accessibleMuseums - The list of museums the user has access to.
 * @param {function} selectedMuseumCallback - Callback function when a museum item is clicked.
 * @param {function} closeDialogCallback - Callback function to close the dialog.
 * @param {function} dialogActionsCallback - Callback function for dialog actions.
 * @returns {JSX.Element}
 */
const MultipleMuseumsDialog = ({
                                   title,
                                   collectionId,
                                   t,
                                   projectContext,
                                   openDialog,
                                   accessibleMuseums,
                                   selectedMuseumCallback,
                                   closeDialogCallback,
                                   dialogActionsCallback,
                               }) => {

    const {museumFeatures} = useAuthsState();

    /**
     * Returns a List of museums, the user has access to.
     * @param museums Array The list of museums the user has access to.
     * @returns {JSX.Element}
     */
    const getMuseumList = museums => {
        let showMuseums = museums;
        if (projectContext) {
            showMuseums = museums.filter((m) => {
                return museumFeatures.find(mf => (mf.id === m.id && mf.features['archeology'] === true));
            });
        }
        return <List>
            {showMuseums.map(museum => {
                const {id, incorrectConfig, name} = museum;
                return <ListItem key={id}>
                    <ListItemButton disabled={incorrectConfig}
                                    selected={museum.collectionId === collectionId}
                                    onClick={() => selectedMuseumCallback(museum)}>
                        <ListItemIcon>
                            <MuseumIcon/>
                        </ListItemIcon>
                        <ListItemText primary={name}
                                      secondary={incorrectConfig ? t('selectMuseumDialogNotAvailable', 'Feil: Når ikke lagringsløsningen') : ''}/>
                    </ListItemButton>
                </ListItem>
            })}
        </List>;
    };

    return <DialogWrapper openDialog={openDialog}
                          dialogTitle={title}
                          closeHandler={closeDialogCallback}
                          dialogActions={dialogActionsCallback}>
        <Stack direction={"column"}>
            <Typography>
                {t('selectMuseumDialogMultipleMuseums', 'Vi ser du har tilgang til flere enn ett museum, velg museet du ønsker å utføre oppgaven mot:')}
            </Typography>
            {getMuseumList(accessibleMuseums)}
        </Stack>
    </DialogWrapper>;
};

MultipleMuseumsDialog.propTypes = {
    title: PropTypes.string.isRequired,
    collectionId: PropTypes.number,
    t: PropTypes.func.isRequired,
    projectContext: PropTypes.bool.isRequired,
    openDialog: PropTypes.bool.isRequired,
    accessibleMuseums: PropTypes.array.isRequired,
    selectedMuseumCallback: PropTypes.func.isRequired,
    closeDialogCallback: PropTypes.func.isRequired,
    dialogActionsCallback: PropTypes.func.isRequired
};


export {MultipleMuseumsDialog};