import React, {useEffect, useState} from "react";
import {useDocumentState, useDocumentTranslation,} from "../documents/documentContext";
import Box from "@mui/material/Box";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import {DraggableFileItemInfoTooltip} from "./DraggableFileItemInfoTooltip";
import InfoIcon from "@mui/icons-material/Info";
import {getCheckIcon, getIconOrThumbnail} from "./filesHelper";

import './uploadedfilegriditem.css';


export const DraggableFileItem = ({file, checked, onCheck}) => {
    const t = useDocumentTranslation();
    const {saved, failed, formData} = useDocumentState();
    const [showAudioThumb, setShowAudioThumb] = useState(false);
    const [showUnknownThumb, setShowUnknownThumb] = useState(false);

    useEffect(() => {
        setShowAudioThumb(file.mimeType.indexOf("audio") > -1);
        if (
            file.mimeType.indexOf("image") > -1 ||
            file.mimeType.indexOf("pdf") > -1 ||
            file.mimeType.indexOf("video") > -1 ||
            file.mimeType.indexOf("audio") > -1
        ) {
            setShowUnknownThumb(false);
        } else {
            setShowUnknownThumb(true);
        }
    }, [file.mimeType]);

    const getSaved = (filename) => {
        return saved.filter(
            (s) => s.content.mediae[0].reference.title === filename
        );
    };

    const getInfoTooltip = (f, triggerElement) => {
        let fileObj = getSaved(f.title);
        if (Array.isArray(fileObj)) {
            fileObj = fileObj[0];
        }
        return <DraggableFileItemInfoTooltip t={t}
                                             file={fileObj}
                                             formData={formData}
                                             triggerElement={triggerElement}/>
    };

    const error = failed.includes(file.title);
    const success = saved.find(
        (document) => document.content.mediae[0].reference.title === file.title
    );


    /**
     * Sets the color of the checkbox- and info icons, based on the current file's "save-state".
     * @returns {string}
     */
    const getIconColor = (success, error) => {
        let c = "rgba(255, 255, 255, 0.6)";
        if (success) {
            c = "rgba(60, 179, 113, 0.8)";
        } else if (!success && error) {
            c = "rgba(255,0,0, 0.8)";
        }
        return c;
    };

    const getTopmostOverlay = (success, error) => {
        const iconColor = getIconColor(success, error);
        return (
            <Box className={'infoContainer'}>
                {getCheckIcon(checked, onCheck, iconColor)}
                {getInfoTooltip(
                    file,
                    <InfoIcon
                        className={'infoIcon'}
                        sx={{
                            right: 3,
                            color: iconColor,
                        }}
                    />
                )}
            </Box>
        );
    };

    return (
        <Box>
            {getIconOrThumbnail(file, showAudioThumb, showUnknownThumb, getTopmostOverlay(success, error))}
            <ImageListItemBar
                position={"bottom"}
                title={file.title}
                subtitle={file.file.name}
                sx={{
                    maxWidth: '160px',
                    "& .MuiImageListItemBar-titleWrap": {
                        background:
                            "linear-gradient(0deg, rgba(51,51,51,0) 0%, rgba(255,255,255, 0.1) 100%)",
                        padding: 1,
                    },
                    "& .MuiImageListItemBar-title": {
                        fontSize: "12px",
                    },
                    "& .MuiImageListItemBar-subtitle": {
                        fontSize: "9px",
                    },
                }}
            />
        </Box>
    );
};
