import React from "react";
import {PreviewRelated} from "../related/PreviewRelated";
import {damsFetch} from "../app/damsFetch";
import {
    SET_RELATED_OBJECTS,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
} from "../search-result-details/SearchResultDetailsContext";
import useDeepCompareEffect from "use-deep-compare-effect";

export const RelatedObjectsList = () => {
    const {model} = useSearchResultDetailsState();
    const searchResultsDetailsDispatch = useSearchResultDetailsDispatch();

    const createParentObject = m => {
        const {createdAt, description, content, locale, uniqueId, status, title, updatedAt} = m;
        const {mediae} = content;
        const {durationSeconds, reference} = mediae[0];

        return {
            reference: {
                createdAt: createdAt,
                description: description,
                documentType: "relation",
                durationSeconds: durationSeconds,
                id: reference.id,
                imageUrl: null,
                locale: locale,
                mimeType: "",
                schemaId: 1,
                source: "dams",
                sourceId: uniqueId,
                status: status,
                title: title,
                updatedAt: updatedAt,
            },
            referenceType: "relation",
        };
    };

    const getParentObjectsCallback = json => {

        if (json === true) {
            return;
        }

        const parentData = json.models?.map((m) => {
            return createParentObject(m);
        });

        const {relations} = model.content;
        if (parentData && relations) {
            searchResultsDetailsDispatch({
                type: SET_RELATED_OBJECTS,
                objectRelations: parentData.concat(relations),
            });
        } else if (parentData && !relations) {
            searchResultsDetailsDispatch({
                type: SET_RELATED_OBJECTS,
                objectRelations: parentData,
            });
        } else if (!parentData && relations) {
            searchResultsDetailsDispatch({
                type: SET_RELATED_OBJECTS,
                objectRelations: relations,
            });
        }
    };

    const getParentObjects = uniqueId => {
        damsFetch(`/documents/parent`, {
            method: "POST",
            body: JSON.stringify({modelId: uniqueId}),
        }).then(getParentObjectsCallback);
    }

    /**
     * Hook used to fetch the list of related objects that has this object as their parent.
     */
    useDeepCompareEffect(() => {
        getParentObjects(model.uniqueId);
    }, [model]);

    return (
        <>
            <PreviewRelated/>
        </>
    );
};
