import React from "react";
import {DamsModal} from "../app/DamsModal";
import {TOGGLE_BATCH_EDIT, useResultActionDispatch, useResultActionState,} from "../search/ResultActionContext";
import {
    MODELS_SAVED,
    SELECT_MODELS,
    SET_SEARCHING,
    UNSELECT_ALL,
    useSearchDispatch,
    useSearchState,
} from "../search/SearchContext";
import {BatchEdit} from "../documents/BatchEdit";
import {BatchEditProvider} from "../documents/batchEditContext";

export const SearchResultBatchEdit = () => {
    const {showBatchEdit} = useResultActionState();
    const dispatch = useResultActionDispatch();
    const {selected} = useSearchState();
    const searchDispatch = useSearchDispatch();

    const onHide = () => {
        dispatch({type: TOGGLE_BATCH_EDIT});
    };

    const onComplete = (succeeded, failed) => {
        searchDispatch({
            type: MODELS_SAVED,
            models: succeeded,
        });

        searchDispatch({
            type: UNSELECT_ALL,
        });

        searchDispatch({
            type: SELECT_MODELS,
            models: failed,
        });

        searchDispatch({type: SET_SEARCHING});

        onHide();
    };

    return (
        <DamsModal open={showBatchEdit} onHide={onHide}>
            <BatchEditProvider>
                <BatchEdit models={selected} onHide={onHide} onComplete={onComplete}/>
            </BatchEditProvider>
        </DamsModal>
    );
};
