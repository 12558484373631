import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {FormikSubmitButton} from "../form/FormikSubmitButton";
import {useDocumentTranslation} from "./documentContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Toolbar from "@mui/material/Toolbar";

const PREFIX = "BatchEditActions";

const classes = {
    saveButton: `${PREFIX}-saveButton`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.saveButton}`]: {
        marginLeft: theme.spacing(1),
    },
}));

export const BatchEditActions = ({onCancel, numberOfDocuments}) => {
    const t = useDocumentTranslation();
    const [confirmSave, setConfirmSave] = useState(false);

    const handleChange = () => setConfirmSave((cs) => !cs);

    return (
        <Root>
            <Toolbar>
                <Box flexGrow={1}/>
                <FormControlLabel
                    disabled={confirmSave}
                    labelPlacement={"start"}
                    control={
                        <Checkbox
                            checked={confirmSave}
                            onChange={handleChange}
                            color={"secondary"}
                        />
                    }
                    label={t(
                        "batchEditConfirm",
                        "Du er i ferd med å endre {{count}} objekter. Er du sikker på dette?",
                        {
                            count: numberOfDocuments,
                        }
                    )}
                />
            </Toolbar>
            <Toolbar>
                <Box flexGrow={1}/>
                <Button onClick={onCancel}>{t("cancel", "Avbryt")}</Button>
                <FormikSubmitButton
                    color={"secondary"}
                    variant={"contained"}
                    className={classes.saveButton}
                    disabled={!confirmSave}
                >
                    {t("save", "Lagre")}
                </FormikSubmitButton>
            </Toolbar>
        </Root>
    );
};
