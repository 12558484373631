import React, { useEffect, useState } from "react";
import { UploadedFilesTable } from "./UploadedFilesTable";
import { useDocumentState } from "../documents/documentContext";

export const DraggableUploadedFilesTable = ({ checked, checkFile }) => {
  const [dragImage, setDragImage] = useState();
  const { saved } = useDocumentState();

  useEffect(() => {
    let img = new Image();
    img.src = "/perm_media-24px.svg";
    img.onload = () => setDragImage(img);
  }, []);

  const fileNameToDocumentId = (fileNames) => {
    return saved
      .filter((document) =>
        fileNames.includes(document.content.mediae[0].reference.title)
      )
      .map((document) => document.id);
  };

  const drag = (fileTittle) => (event) => {
    const checkedFiles = Object.keys(checked).filter((file) => checked[file]);
    if (checkedFiles.length) {
      event.dataTransfer.setData(
        "application/json",
        JSON.stringify(fileNameToDocumentId(checkedFiles))
      );
      event.dataTransfer.setDragImage(dragImage, 24, 24);
    } else {
      event.dataTransfer.setData(
        "application/json",
        JSON.stringify(fileNameToDocumentId([fileTittle]))
      );
    }
    event.dataTransfer.effectAllowed = "link";
  };

  return (
    <>
      <UploadedFilesTable
        checked={checked}
        checkFile={checkFile}
        onDrag={drag}
      />
    </>
  );
};
