import {DialogActions} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {useSearchResultDetailsState} from "../../search-result-details/SearchResultDetailsContext";

/**
 * Component used withing the dialog that opens in full screen on mobile devices, when playing audio/video.
 * @param callbackCloseFullScreenDialog
 * @returns {JSX.Element}
 * @constructor
 */
export const AudioVideoDialogActions = ({callbackCloseFullScreenDialog}) => {
    const {model} = useSearchResultDetailsState();
    const {reference} = model.content.mediae[0];
    const {title, fileSize} = reference;
    return (
        <DialogActions>
            <Typography ml={1} noWrap>
                {title}
            </Typography>
            <Typography ml={1}>
                ({fileSize.formatBytes()})
            </Typography>
            <Box flex flexGrow={1}/>
            <IconButton onClick={callbackCloseFullScreenDialog}>
                <CloseIcon/>
            </IconButton>
        </DialogActions>
    );
};
