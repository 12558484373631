import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {CreateFolderCard} from "./CreateFolderCard";
import {DroppableFolderCard} from "./DroppableFolderCard";
import {useAuthsState} from "../auths/authsContext";
import {If} from "../app/If";
import {useFoldersState} from "./FoldersContext";
import Grid from "@mui/material/Grid2";
import {useMyMuseums} from "../museum/useMyMuseums";
import useDeepCompareEffect from "use-deep-compare-effect";

const PREFIX = "MyFoldersGrid";

const classes = {
    myFoldersGrid: `${PREFIX}-myFoldersGrid`,
    folderGridItem: `${PREFIX}-folderGridItem`,
};

const StyledGrid = styled(Grid)(() => ({
    [`&.${classes.myFoldersGrid}`]: {},
    [`& .${classes.folderGridItem}`]: {},
}));

export const MyFoldersGrid = () => {
    const {userData} = useAuthsState();
    const {results} = useFoldersState();
    const {museumCollections} = useAuthsState();

    const onlyAdministrativeMuseums = true;
    const myMuseums = useMyMuseums({onlyAdministrativeMuseums});

    const [showCard, setShowCard] = useState(false);

    const getMuseumName = (collectionId) => {
        const museumsAndCollections = myMuseums.map((m) => {
            return {
                ...m,
                collectionId: museumCollections.find((mc) => mc.museumId === m.id)
                    ?.collectionId,
            };
        });
        const museum = museumsAndCollections?.find(
            (m) => m.collectionId === collectionId
        );
        return museum ? museum.name : "";
    };

    useDeepCompareEffect(() => {
        if (!userData['contributor'] && userData['appAccess']?.museums?.length > 0) {
            setShowCard(true);
        }
    }, [userData]);

    return (
        <StyledGrid container spacing={1} className={classes.myFoldersGrid}>
            <If boolean={showCard}>
                <Grid size={{xs: 3}} className={classes.folderGridItem}>
                    <CreateFolderCard/>
                </Grid>
            </If>

            {results.models.map((f) => {
                const folder = {
                    id: f.id,
                    uniqueId: f.uniqueId,
                    name: f.title,
                    museumName: getMuseumName(f.collectionId),
                };

                return (
                    <Grid
                        size={{
                            xs: 3
                        }}
                        key={f.id}
                        className={classes.folderGridItem}>
                        <DroppableFolderCard folder={folder}/>
                    </Grid>
                );
            })}
        </StyledGrid>
    );
};
