import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import AccountTreeSharpIcon from "@mui/icons-material/AccountTreeSharp";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React from "react";

export const DialogDetailsTitle = ({title, openInNewTabCallback = null, projectDataset = null}) => {
    return <DialogTitle>
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"center"}
        >
            {/* Project icon */}
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 56,
                    height: 76,
                    backgroundColor: "white",
                    marginRight: 1,
                }}
            >
                <AccountTreeSharpIcon
                    sx={{
                        width: 40,
                        height: 40,
                    }}
                    color={"secondary"}
                />
            </Box>
            <Typography variant={"h5"} noWrap>
                {title}
            </Typography>
            {
                openInNewTabCallback !== null
                && (
                    <IconButton onClick={openInNewTabCallback}>
                        <OpenInNewIcon/>
                    </IconButton>
                )
            }
        </Stack>
        {
            projectDataset
            && projectDataset === true
            && (
                <Typography variant={"caption"} sx={{color: "GrayText"}}>
                    {projectDataset}
                </Typography>
            )
        }
    </DialogTitle>;
}