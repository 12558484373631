import React from 'react';

export const If = ({boolean, children}) => {
    if(boolean) {
        return (
            <>
                {children}
            </>
        )
    }     else {
        return null
    }
}