import React, {useEffect, useState} from "react";

// noinspection ES6CheckImport
import {
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
    VIEW_FULLSCREEN,
} from "../search-result-details/SearchResultDetailsContext";
import {makeStyles} from "@mui/styles";
import {useObserveWindowResize} from "../app/useObserveWindowResize";
import "../app/extensions";
import {SmallscreenDialogFullscreenViewer} from "./smallscreen/SmallscreenDialogFullscreenViewer";
import {SmallScreenImageViewer} from "./smallscreen/SmallScreenImageViewer";
import {ImageViewer} from "./ImageViewer";
import {DialogFullscreenViewer} from "./DialogFullscreenViewer";
import {ThumbnailViewer} from "./ThumbnailViewer";

const useStyles = makeStyles(() => ({
    imgSmallFullScreen: {
        width: "100vw",
        height: "100vh",
        objectFit: "scale-down",
    },
    imgPortrait: {
        maxWidth: "100%",
        maxHeight: "30vh",
        minHeight: "30vh",
        objectFit: "scale-down",
    },
    imgLandscape: {
        maxWidth: "28vw",
        objectFit: "scale-down",
    },
}));

export const ObjectMediaImage = ({parentRef, smallScreen, onClick}) => {
    const observeWindowResize = useObserveWindowResize();
    const classes = useStyles();
    const [isPortrait, setIsPortrait] = useState(true);
    const {model, fullscreen} = useSearchResultDetailsState();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();
    const [imageHeight, setImageHeight] = useState(0);
    const [image, setImage] = useState(null);
    const [imgClass, setImgClass] = useState(undefined);
    const [panelDimensions, setPanelDimensions] = useState({
        width: 0,
        height: 0,
    });

    const handleImageClick = () => {
        searchResultDetailsDispatch({type: VIEW_FULLSCREEN});
    };

    /**
     * Hook used to see if the application is running in portrait mode or not.
     */
    useEffect(() => {
        setIsPortrait(observeWindowResize.width < observeWindowResize.height);
    }, [setIsPortrait, observeWindowResize.width, observeWindowResize.height]);

    /**
     * Hook used to set the correct CSS-class for the image, based on screen orientation and size.
     */
    useEffect(() => {
        if (smallScreen && !fullscreen) {
            if (!isPortrait) {
                setImgClass(classes.imgLandscape);
            } else {
                setImgClass(classes.imgPortrait);
            }
        }
        if (!smallScreen && !fullscreen) {
            setImgClass(classes.imgPortrait);
        }
    }, [
        isPortrait,
        fullscreen,
        classes.imgLandscape,
        classes.imgPortrait,
        smallScreen,
    ]);

    /**
     * Hook used to correctly resize the image.
     */
    useEffect(() => {
        let observer = new ResizeObserver((elements) => {
            const observedElement = elements[0];
            const height = smallScreen
                ? window.innerHeight * 0.3
                : observedElement.contentRect.height * 0.95;
            setImageHeight(height);

            setPanelDimensions({
                width: observedElement.contentRect.width * 0.75,
                height: observedElement.contentRect.height,
            });
        });
        observer.observe(parentRef.current);
        return () => {
            observer.disconnect();
            observer = null;
        };
    }, [parentRef, smallScreen]);

    useEffect(() => {
        if (!model.imageUrl) {
            return;
        }
        const imageUrl = model.imageUrl.replace(
            "&dimension=250x250",
            "&dimension=1200x1200&failhard=true"
        );
        setImage(imageUrl);
    }, [model.imageUrl]);

    /**
     * Handle small screens or fullscreen/dialog modes.
     */
    if (smallScreen) {
        if (!fullscreen) {
            return <SmallScreenImageViewer image={image} cssClass={imgClass} imageClickCallback={() => {
                if (onClick) {
                    onClick();
                } else {
                    handleImageClick();
                }
            }}/>;
        } else {
            return <SmallscreenDialogFullscreenViewer smallScreen={smallScreen}/>;
        }
    } else if (!smallScreen) {
        const {documentType, content} = model;
        if (!fullscreen) {
            return <ImageViewer image={image}
                                imageHeight={imageHeight}
                                imageClickCallback={handleImageClick}
                                onClickCallback={onClick}/>;
        } else if (fullscreen && documentType === "StillImage") {
            return <DialogFullscreenViewer panelDimensions={panelDimensions}/>;
        } else if (
            fullscreen &&
            (content.mediae[0].reference.mimeType.indexOf("pdf") !== -1 ||
                ["Audio", "Video"].includes(documentType))
        ) {
            return <ThumbnailViewer image={image} imageHeight={imageHeight} onClickCallback={onClick}/>;
        } else {
            return <></>;
        }
    }
};
