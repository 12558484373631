/**
 * Returns a base configuration object for a Material React Table (MRT) with
 * support for editing. The configuration is suitable for displaying a table of
 * DAMS objects, such as subjects, places, or local assets.
 *
 * @param {object[]} columns - An array of column descriptors. Each column
 * descriptor is an object with the following properties:
 * - `accessorKey`: The key in the data object to access the column value.
 * - `header`: The text to display in the column header.
 * - `enableEditing`: A boolean indicating whether the cell is editable.
 * - `enableSorting`: A boolean indicating whether the column is sortable.
 * - `enableHiding`: A boolean indicating whether the column can be hidden.
 *
 * @param {object[]} data - An array of data objects to display in the table.
 * Each data object must contain an `id` property.
 *
 * @param {object} translations - An object with translations for the table.
 * See `getTableTranslations` for the expected shape of the translations object.
 *
 * @param {function} saveCallback - A callback function to call when the user
 * saves changes to a row. The function is called with the updated row data.
 *
 * @param {function} exitEditCallback - A callback function to call when the user
 * exits edit mode (e.g. clicks "Cancel" or hits "Esc"). The function is called
 * with no arguments.
 *
 * @returns {object} A configuration object for an MRT with support for editing.
 */
export const getTableBaseConfig = (columns, data, translations, saveCallback, exitEditCallback,) => {
    if (!Array.isArray(columns)) {
        throw new Error('columns must be an array');
    }
    if (!Array.isArray(data)) {
        throw new Error('data must be an array');
    }
    if (typeof translations !== 'object') {
        throw new Error('translations must be an object');
    }
    if (typeof saveCallback !== 'function') {
        throw new Error('saveCallback must be a function');
    }
    if (typeof exitEditCallback !== 'function') {
        throw new Error('exitEditCallback must be a function');
    }
    return {
        columns,
        data,
        enableStickyHeader: true,
        enableDensityToggle: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        editDisplayMode: 'modal',
        enableEditing: true,
        enableColumnActions: false,
        localization: translations,
        onEditingRowSave: saveCallback,
        onEditingRowCancel: exitEditCallback,
        globalFilterFn: 'contains',
        initialState: {
            density: 'compact',
            columnVisibility: {id: false, collectionId: false, uniqueId: false},
            columnOrder: ['id', 'collectionId', 'uniqueId', 'title', 'yearOfBirth', 'yearOfDeath', 'museum', 'orphan', 'source']
        },
        getRowId: row => row.id,
    };
};