import React from "react";
import {styled} from "@mui/material/styles";
import {ProjectViewHeader} from "../../ProjectViewHeader";
import {ProjectContentsSearchResults} from "../../ProjectContentsSearchResults";

import '../../projectview.css';
import {ProjectViewBase} from "../../ProjectViewBase";

const PREFIX = "GeneralProjectView";

const classes = {
    searchAndType: `${PREFIX}-searchAndType`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.searchAndType}`]: {
        [theme.breakpoints.up("xs")]: {
            flexGrow: 1,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: '60%'
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: '50%'
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: '38rem'
        },
    },
}));

/**
 * General project view component.
 *
 * @return {JSX.Element} The rendered General Project View component.
 */
export const GeneralProjectView = () => {
    return <ProjectViewBase Root={Root} classes={classes}>
        <ProjectViewHeader/>
        <ProjectContentsSearchResults/>
    </ProjectViewBase>;

};
