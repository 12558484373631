import {Box, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useTheme} from "@emotion/react";
import {useProjectState, useProjectTranslation,} from "../../projectContext";
import {useNavigate} from "react-router-dom";
import {ArcheologyProjectActionButtons} from "./ArcheologyProjectActionButtons";

export const ArcheologyProjectExists = () => {
    const t = useProjectTranslation();
    const theme = useTheme();
    const navigate = useNavigate();

    const {projectData, damsProject} = useProjectState();

    const project = projectData.archeologyRemote;
    const {name, projectStart, projectEnd} = project;

    const handleGotoProject = () => {
        navigate(`/project/${damsProject.id}`);
    };

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    marginBottom: theme.spacing(2),
                }}
            >
                <Typography variant={"h5"}>
                    {t('headingProjectAlreadyCreated', 'Det er allerede opprettet et DAMS-prosjekt knyttet til')}
                </Typography>
                <Typography
                    variant={"h5"}
                    color={"secondary"}
                    sx={{marginLeft: theme.spacing(1)}}
                >
                    {projectData.name}
                </Typography>
            </Box>
            <Box
                sx={{
                    border: "solid 1px #ddd",
                    borderRadius: "4px",
                    padding: theme.spacing(2),
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box>
                    <Typography variant={"h6"}>
                        {name}({projectStart} - {projectEnd})
                    </Typography>
                    <Typography variant={"subtitle1"} color={"secondary"}>
                        {project.projectType}
                    </Typography>
                </Box>
                <CheckCircleIcon
                    color={"secondary"}
                    sx={{marginLeft: theme.spacing(4), width: 40, height: 40}}
                />
            </Box>

            {/* Action buttons */}
            <ArcheologyProjectActionButtons continueCallback={handleGotoProject}
                                            continueLabel={t('btnGotoProject', 'Gå til prosjektet')}/>
        </>
    );
};
