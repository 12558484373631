import {useAuthsState} from "./authsContext";

export const useIsAdminOfAtleastOneMuseum = () => {
    const {userData} = useAuthsState();

    if (!userData || !userData.appAccess) {
        return false;
    }

    return (
        userData['appAccess'] &&
        Boolean(
            userData['appAccess'].museums?.filter((museum) =>
                museum['applications']?.find(
                    (app) =>
                        window._env_.REACT_APP_ID === app.id && app.role.name === "Admin"
                )
            ).length
        )
    );
};
