import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import {useMuseumNameFromCollectionId} from "../../../../museum/useMuseumNameFromCollectionId";
import {useProjectTranslation} from "../../../projectContext";
import {
    PROJECT_CLOSE_DETAILS_OVERLAY,
    useProjectViewDispatch,
    useProjectViewState
} from "../../../archeology/projectview/projectViewContext";
import {getHrDateTime} from "../../../projectUtility";
import {DetailsRow} from "../../../smallscreen/DetailsRow";
import {DialogDetailsTitle} from "../../../smallscreen/DetailsDialogTitle";
import {DetailsDialogCloseButton} from "../../../smallscreen/DetailsDialogCloseButton";
import {DetailsDialogStats} from "../../../smallscreen/DetailsDialogStats";

export const ProjectViewDetailsSmallScreen = ({show}) => {

    const language = "no";

    const {project} = useProjectViewState();

    const tp = useProjectTranslation();

    const projectViewDispatch = useProjectViewDispatch();

    const [getMuseumName] = useMuseumNameFromCollectionId();

    const {collectionId, content, title, createdAt} = project;

    const {generalprojectref} = content;

    const {
        id: projectId,
        startedAt = '',
        endedAt = '',
        description,
        type,
        remarks,
        responsible,
        contributors,
        relatedProjects
    } = generalprojectref;

    const museumName = getMuseumName(collectionId);

    const getResponsible = () => {
        return <Box mt={1}>
            <Typography variant={"caption"}>{tp('lblResponsible', 'Ansvarlige')}</Typography>
            <br/>
            {
                responsible.map(r => {
                    return <Box key={`resp${r.id}`}><Typography variant={"caption"}>- {r.name}</Typography></Box>;
                })
            }
        </Box>;
    };

    const getContributors = () => {
        return <Box mt={1}>
            <Typography variant={"caption"}>{tp('lblContributors', 'Deltagere')}</Typography>
            <br/>
            {
                contributors.map(r => {
                    return <Box key={`contr${r.id}`}><Typography variant={"caption"}>- {r.name}</Typography></Box>;
                })
            }
        </Box>;
    };

    const getRelatedProjects = () => {
        if (!relatedProjects || relatedProjects?.length === 0) {
            return <></>;
        } else {
            return <Box mt={1}>
                <Typography variant={"caption"}>{tp('lblRelatedProjects')}</Typography>
                <br/>
                {
                    relatedProjects.map(r => {
                        return <Box key={`related${r.id}`}>
                            <Typography variant={"caption"}>
                                - {r.title}
                            </Typography>
                        </Box>;
                    })
                }
            </Box>
        }
    };

    const handleClose = () => {
        projectViewDispatch({type: PROJECT_CLOSE_DETAILS_OVERLAY});
    };

    if (!museumName) {
        return <></>;
    }

    return (
        <Dialog fullScreen={true} open={show}>
            <DialogDetailsTitle title={title}/>
            <DialogContent>
                <DetailsDialogCloseButton tp={tp} closeCallback={handleClose} iconButton={true}/>

                {/* Project metadata */}
                <DetailsRow label={tp('lblProjectId', 'Prosjekt ID') + ':'} value={projectId}/>
                <DetailsRow label={tp('lblMuseum', 'Museum') + ':'} value={museumName}/>
                <DetailsRow
                    label={tp('lblCreated', 'Opprettet')}
                    value={getHrDateTime(createdAt, language) || ''}
                />

                <Divider sx={{marginTop: 1, marginBottom: 1}}/>

                {/* Project facts */}
                <DetailsRow label={tp('lblProjectPeriod', 'Periode:') + ':'}
                            value={`${getHrDateTime(startedAt, language)} - ${getHrDateTime(endedAt, language)}`}/>
                <DetailsRow label={tp('lblProjectType', 'Prosjekttype') + ':'} value={type?.name}/>

                {getResponsible()}
                {getContributors()}

                <Box mt={1}>
                    <Typography variant={"caption"}>{tp('lblDescription', 'Beskrivelse')}</Typography>
                    <br/>
                    <Typography variant={"caption"}>{description}</Typography>
                </Box>

                <Box mt={1}>
                    <Typography variant={"caption"}>{tp('lblRemarks', 'Kommentarer')}</Typography>
                    <br/>
                    <Typography variant={"caption"}>{remarks}</Typography>
                </Box>

                {getRelatedProjects()}

                <Divider sx={{marginTop: 1, marginBottom: 1}}/>

                {/* Project stats */}
                <DetailsDialogStats tp={tp}/>
            </DialogContent>
            <DialogActions>
                <DetailsDialogCloseButton tp={tp} closeCallback={handleClose} iconButton={false}/>
            </DialogActions>
        </Dialog>
    );
};
