import {FileGridItemBase} from "../FileGridItemBase";
import {TOGGLE_SELECTED, useFileUploadDispatch, useFileUploadState} from "../fileUploadContext";
import React, {useEffect, useState} from "react";
import {Checkbox} from "@mui/material";

/**
 * HOC used when presenting an uploaded file as a draggable item, ready to be added to a folder.
 * @param WrappedComponent
 * @returns {function(*): *}
 */
function withDragSupport(WrappedComponent) {
    return function WithDragSupport(props) {

        const {allSelected, selected} = useFileUploadState();
        const fileUploadDispatch = useFileUploadDispatch();

        const [isRaised, setIsRaised] = useState(true);
        const [dragImage, setDragImage] = useState();

        const {model} = props;

        /**
         * Make the file being dragged appear as selected.
         */
        const selectFile = () => {
            fileUploadDispatch({
                type: TOGGLE_SELECTED,
                uniqueId: model.id
            });
        };

        /**
         * When clicked, store the model ID for future reference.
         * NOTE: When handling drag & drop actions, model.id is used instead of model.uniqueId.
         */
        const onClickHandler = () => {
            selectFile();
        };

        /**
         * Sets the item as "draggable".
         * @returns {boolean}
         */
        const dragEnabled = () => {
            return true;
        };

        /**
         * Handler triggered when dragging starts.
         * @returns {(function(*): void)|*}
         */
        const dragStartHandler = () => (event) => {
            const {id} = model;

            const alreadySelected = [...selected];
            if (!selected.find(s => s === model.id)) {
                alreadySelected.push(id);
            }

            const header = 'application/json'
            const dragData = alreadySelected.length > 0 ? JSON.stringify(alreadySelected) : JSON.stringify([id]);
            event.dataTransfer.setData(header, dragData);
            event.dataTransfer.setDragImage(dragImage, 24, 24);
            event.dataTransfer.effectAllowed = "link";

            selectFile();
        };

        /**
         * Hook used to lower/rise the card, based on whether the current object is selected or not.
         */
        useEffect(() => {
            const shouldRaise = !selected.includes(model.id) && !allSelected;
            setIsRaised(shouldRaise);
        }, [selected, model.id]);

        /**
         * Hook used to set the mouse-cursor when dragging.
         */
        useEffect(() => {
            let img = new Image();
            img.src = "/perm_media-24px.svg";
            img.onload = () => setDragImage(img);
        }, []);

        return <WrappedComponent {...props}
                                 onClick={onClickHandler}
                                 checkBox={<Checkbox
                                     disableRipple
                                     checked={!isRaised}
                                     onChange={(event) => !event.target.checked}
                                     value={model.uniqueId}
                                     color="secondary"
                                     sx={{
                                         placeSelf: 'top left',
                                         zIndex: 20,
                                         backgroundColor: "rgba(255,255,255, 0.6)",
                                     }}
                                 />}
                                 raised={isRaised}
                                 draggable={dragEnabled()}
                                 onDragStart={dragStartHandler()}/>;
    };
};

export const DraggableFileGridItem = withDragSupport(FileGridItemBase);