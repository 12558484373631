import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";
import {useTheme} from "@emotion/react";

export const WithProgressCircle = ({searching, children}) => {
    const theme = useTheme();
    const [status, setStatus] = useState("init");

    useEffect(() => {
        if (searching) {
            setStatus("timeout");
            window.setTimeout(() => {
                setStatus((s) => (s === "close" ? s : "open"));
            }, 300);
        } else {
            setStatus((s) => ("init" === s ? s : "close"));
        }
    }, [searching, setStatus]);

    return (
        <Box>
            {children}
            <Modal
                sx={{
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                }}
                open={"open" === status}
                onClose={() => null}
                closeAfterTransition
            >
                <Box
                    sx={{
                        padding: theme.spacing(1),
                        outline: "none",
                    }}
                >
                    <Fade in={true} unmountOnExit={true}>
                        <CircularProgress size={160}/>
                    </Fade>
                </Box>
            </Modal>
        </Box>
    );
};
