import {useEffect, useState} from "react";
import {usePostFolder} from "../../folder/usePostFolder";

export const useSaveProject = () => {
    const [postFolderResponse, postFolder] = usePostFolder();
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        setError(postFolderResponse.error);
        setSuccess(postFolderResponse.success);
    });

    /**
     * Saves/updates information related to an archeology project.
     * @param collectionId int
     * @param data  JSON  Project data
     * @param acls  Array of e-mail addresses
     */
    const archeology = (collectionId, data, acls) => {

        const {
            uniqueId, title, projectId, externalProjectUUID, updatedAt, projectType, projectStart, projectEnd,
            projectStatus, projectDescription, projectMonumentType, projectMonumentPeriod, projectDataset,
            projectLatLng, active, projectSystemId, projectSystemName
        } = data;

        const folder = {
            uniqueId: uniqueId || undefined,
            collectionId: collectionId,
            schemaId: 1,
            documentType: "Folder",
            locale: "no",
            status: "project",
            title: title,
            description: "Project folder",
            folder_type: "ProjectArcheology",
            content: {
                archeologyprojectref: {
                    id: projectId,
                    uuid: externalProjectUUID,
                    lastUpdated: updatedAt,
                    type: projectType,
                    startedAt: projectStart,
                    endedAt: projectEnd,
                    status: projectStatus,
                    description: projectDescription,
                    monumentType: projectMonumentType,
                    monumentPeriod: projectMonumentPeriod,
                    dataset: projectDataset,
                    latLng: projectLatLng,
                    active: active,
                    systemId: projectSystemId || '',
                    systemName: projectSystemName || ''
                },
                acls: acls?.map((a) => ({email: a, access: "write"})) || [],
            },
            acls: [], // In use by folders only, not projects!
        };
        postFolder(folder);
    };

    return [archeology, success, error];
};
