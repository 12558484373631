import React from "react";
import {UploadFiles} from "./UploadFiles";
import {useDocumentState} from "../documents/documentContext";
import {AddedFilesGrid} from "./AddedFilesGrid";
import Paper from "@mui/material/Paper";
import {useMediaQuery} from "@mui/material";
import {AddedFilesGridSmallScreen} from "./smallscreen/AddedFilesGridSmallScreen";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";

export const Mediae = () => {
    const t = useNewFilesTranslation();
    const {files} = useDocumentState();

    const smallScreen = useMediaQuery("(max-width: 768px)");

    return (
        <Paper elevation={3} sx={{height: '100%', marginBottom: '16px', overflowY: 'auto', padding: '16px'}}>
            {!smallScreen && <AddedFilesGrid hide={!files.length}/>}
            {smallScreen && <AddedFilesGridSmallScreen hide={!files.length}/>}
            <UploadFiles hide={files.length}/>
            <Box sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
            }}>
                <Typography variant={"body2"} mb={8} mt={1}>
                    {t('uploadLimitationsInfo', 'Opplasting er begrenset til maks. 300 filer. Maks. størrelse pr. fil er 2GB.')}
                </Typography>
            </Box>
        </Paper>
    );
};
