import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";
import React from "react";
import Box from "@mui/material/Box";
import "../app/extensions";
import {DAMSAudioPlayer} from "../audio/DAMSAudioPlayer";
import {useObjectConversionState} from "./ObjectConversionContext";
import {VolumeUpOutlined} from "@mui/icons-material";

export const ObjectMediaAudio = ({smallScreen}) => {
    const {model} = useSearchResultDetailsState();
    const {error, stateUnknown} = useObjectConversionState();

    const getAudioPlayer = () => {
        return (<>
            {!smallScreen && <VolumeUpOutlined
                sx={{
                    width: "20vw",
                    height: "20vw",
                    color: "#aaa",
                }}
            />}
            {!error && !stateUnknown && (
                <DAMSAudioPlayer model={model}/>)}
        </>);
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                paddingLeft: 3,
                paddingRight: 3,
                minHeight: () => {
                    return smallScreen ? "30vh" : null;
                },
            }}
        >

            {getAudioPlayer()}

        </Box>
    );
};
