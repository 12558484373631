import React from "react";
import {useFormikContext} from "formik";
import {styled} from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import {useDocumentTranslation} from "../documents/documentContext";

const PREFIX = "FormActions";

const classes = {
    cancelButton: `${PREFIX}-cancelButton`,
};

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    [`& .${classes.cancelButton}`]: {
        marginRight: theme.spacing(2),
    },
}));

export const FormActions = ({onCancel}) => {
    const t = useDocumentTranslation();
    const {submitForm, isSubmitting, isValid} = useFormikContext();

    return (
        <StyledToolbar>
            <Box flexGrow={1}/>
            <Button className={classes.cancelButton} onClick={onCancel}>
                {t('btnCancel', 'Avbryt')}
            </Button>
            <Button
                type="submit"
                color={"secondary"}
                variant={"outlined"}
                onClick={submitForm}
                disabled={isSubmitting || !isValid}
            >
                {t('btnAdd', 'Legg til')}
            </Button>
        </StyledToolbar>
    );
};
