import {format} from "../app/dateFormat";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import {styled} from "@mui/material/styles";
import Tooltip from "@mui/material/Tooltip";
import {tooltipClasses} from "@mui/material";


const HtmlTooltip = styled(({className}) => (
    <Tooltip classes={{popper: className}} title={""}>
        <></>
    </Tooltip>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

export const DraggableFileItemInfoTooltip = ({t, file, formData, triggerElement}) => {
    function getArrayOfValues(key) {
        const d = file[0].content[key];
        if (!d) {
            return undefined;
        }
        return d.map((p) => p.reference.title);
    }

    function setPropValue(id, fileData, i, data, label) {
        let value;

        if (arrayProps.includes(id)) {
            value = getArrayOfValues(id);
        } else {
            value = fileData[props[i].id];
            if (!value) {
                value = fileData.content[props[i].id];
            }
            if (value && id === "productionDate") {
                value = format(value, "P");
            } else if (value && id === "languages") {
                value = value.map((v) => v.title);
            }
            value = Array.isArray(value) ? value.join(" ") : value;
        }

        if (value) {
            data.push(label + ": " + value);
        } else {
            data.push(label + ":");
        }
    }

    const buildPropsData = (fileData) => {
        let data = [];
        for (let i = 0, max = props.length; i < max; i++) {
            const id = props[i].id;
            const label = props[i].label;
            setPropValue(id, fileData, i, data, label);
        }
        return data;
    };

    const getPropsTable = (propsData) => {
        return <Box
            sx={{
                display: "flex",
                justifyContent: "center",
                flexFlow: "column",
            }}
        >
            {propsData.map((d) => {
                const parts = d.split(":");
                const label = (
                    <Typography noWrap variant={"body1"}>
                        {parts[0] + ":"}
                    </Typography>
                );

                let value = <Typography variant={"caption"}>{parts[1]}</Typography>;
                if (parts[1] === "") {
                    value = (
                        <Typography variant={"caption"} color={"secondary"}>
                            {t("noData", "Ingen data")}
                        </Typography>
                    );
                }

                return (
                    <Box sx={{display: "flex", justifyContent: "center"}}
                         key={parts[0]}>
                        <Box sx={{textAlign: "right"}}>{label}</Box>
                        <Box sx={{marginLeft: 1}}>{value}</Box>
                    </Box>
                );
            })}
        </Box>;
    }

    const props = [
        {id: "title", label: t("title", "Tittel")},
        {id: "description", label: t("description", "Beskrivelse")},
        {id: "customIdentifier", label: t("customIdentifier", "Museumsnummer")},
        {id: "productionDate", label: t("productionDate", "Produksjonsdato")},
        {
            id: "producer",
            label: t("producer", {context: formData.documentType}),
        },
        {id: "persons", label: t("persons", "Personer")},
        {id: "places", label: t("places", "Steder")},
        {id: "subjects", label: t("subjects", "Emneord")},
        {id: "languages", label: t("languages", "Språk")},
        {id: "licenses", label: t("licenses", "Lisenser")},
        {id: "relations", label: t("relations", "Relaterte objekter")},
        {id: "remarks", label: t("remarks", "Kommentar")},
    ];

    let propsTable = "";
    const arrayProps = ['persons', 'places', 'subjects', 'licenses', 'producer'];

    if (file && file.length > 0) {
        // Construct props table
        const fileData = file[0];
        propsTable = getPropsTable(buildPropsData(fileData));
    }

    const content = (
        <React.Fragment>
            <Typography
                sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                }}
            >
                Metadata for {file.name}
            </Typography>
            {propsTable}
        </React.Fragment>
    );

    return <HtmlTooltip title={content}>{triggerElement}</HtmlTooltip>;
}