import {ToolbarFolderSearch} from "../ToolbarFolderSearch";
import {FoldersProvider} from "../FoldersContext";
import {FolderSearch} from "../FolderSearch";
import React from "react";
import {AllFolders} from "../AllFolders";
import {MyFolders} from "../MyFolders";
import {SharedFolders} from "../SharedFolders";
import {HeaderFolderList} from "../HeaderFolderList";
import Divider from "@mui/material/Divider";
import {FiltersHeader} from "./FiltersHeader";
import {FolderFilters} from "./FolderFilters";
import Box from "@mui/material/Box";

export const FoldersList = ({folderType}) => {

    /**
     * Returns the appropriate component based on the folder type.
     *
     * @return {React.Component} The component to render based on the folder type.
     */
    const getContent = () => {
        switch (folderType) {
            case "sharedfolders":
                return <SharedFolders/>;
            case "myfolders":
                return <MyFolders/>;
            default:
                return <AllFolders/>;
        }
    };

    return <FoldersProvider>
        <FolderSearch>
            <Box sx={{display: 'flex', flexDirection: 'row', overflow: 'hidden', flexGrow: 1}}>
                <Box sx={{flexGrow: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
                    <ToolbarFolderSearch>
                        <HeaderFolderList/>
                    </ToolbarFolderSearch>
                    {getContent()}
                </Box>
                <Box sx={{display: {xs: 'none', md: 'block'}}}>
                    <Divider
                        orientation="vertical"
                        flexItem
                    />
                    {/* Flex child for the filters: */}
                    <Box sx={{
                        padding: 0,
                        margin: 0,
                        minWidth: {
                            sm: '25rem',
                            xl: '29rem'
                        },
                        maxWidth: {
                            sm: '25rem',
                            xl: '29rem',
                        },
                        flexBasis: {
                            sm: '25rem',
                            xl: '29rem',
                        },
                        flexShrink: 0,
                        display: 'flex'
                    }}>

                        <Box id="filterFlexWrapper" sx={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <FiltersHeader/>
                            <Divider orientation={"horizontal"}/>
                            <FolderFilters/>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </FolderSearch>
    </FoldersProvider>
};