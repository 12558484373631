import {UPDATE_URLS, useDmsDispatch, useDmsState} from "./dmsContext";
import {getDateInOneHour, isDateInPast} from "../app/dateFormat";
import useDeepCompareEffect from "use-deep-compare-effect";
import {useState} from "react";
import {clientLog} from "../clientLog";
import {getThumbnailUrl} from "../getThumbnailUrl";
import {getObjectStatus} from "../getObjectStatus";

const cacheExpired = (cacheHit) => cacheHit && isDateInPast(cacheHit.expires);

const isNotCached = (cacheHit) => {
    return !(cacheHit && cacheHit.url) || cacheExpired(cacheHit);
};

export const useDmsMediaUrl = ({
                                   collectionId,
                                   ready = true,
                                   dmsId,
                                   mimeType = "none",
                                   dimension = null,
                                   retry = false,
                               }) => {

    const componentName = 'useDmsMediaUrl';

    const {urls} = useDmsState();
    const dispatch = useDmsDispatch();

    const [loading, setLoading] = useState(false);

    const [hasFailed, setHasFailed] = useState([]);

    let intervalId;

    const fetchMedia = (url, retryFetch, mediaDimension) => {
        if (loading) {
            return;
        }

        let finalUrl = `${url}&failhard=true`;
        if (mediaDimension) {
            finalUrl += `&dimension=${mediaDimension}`;
        }

        fetch(finalUrl, {
            headers: {
                "EKULTUR-TOKEN": localStorage.getItem("kit.access_token"),
            },
        })
            .then((res) => {
                setLoading(false);
                if (res.ok) {
                    return res.blob();
                } else {
                    throw new Error('retry');
                }
            })
            .then((blob) => {
                if (blob) {
                    clearInterval(intervalId);
                    dispatch({
                        type: UPDATE_URLS,
                        dmsId: dmsId,
                        url: url,
                        dimensions: {
                            [mediaDimension ? mediaDimension : "default"]: URL.createObjectURL(blob),
                        },
                        expires: getDateInOneHour(),
                        static: false,
                    });
                }
            }).catch(e => {
            setErrorThumbnail(dmsId, '250x250');
            const msg = `failed to download the the thumbnail: ${e}`;
            clientLog('error', msg, componentName);
        });
    };

    const setErrorThumbnail = (dmsId, mediaDimension) => {
        dispatch({
            type: UPDATE_URLS,
            dmsId: dmsId,
            url: null,
            dimensions: {
                [mediaDimension ? mediaDimension : 'default']: '/no-thumbnail.jpg',
            },
            failed: true,
            expires: getDateInOneHour(),
            static: false
        })
    };


    /**
     * Method used to fetch thumbnail URL for image objects that Uppy failed to get a preview/thumbnail for.
     * The URL is created by DMSF, after the file has been completely uploaded, a status-check is run prior
     * to ordering the image URL for the thumbnail.
     * @param dmsId
     * @param collectionId
     * @returns {Promise<unknown>}
     */
    const getThumbnail = (dmsId, collectionId) => {
        return new Promise((resolve, reject) => {
            let statusIntervalId = -1;
            const pollIntervalMs = 7000;

            const errorCallback = error => {
                setHasFailed([...hasFailed, dmsId]);
                setErrorThumbnail(dmsId, dimension);
                clientLog('error', error, componentName);
                reject(error);
            };

            const getStatusCallback = data => {
                const processCompleteOrFailedStatuses = ['ok', 'done', 'failed', 'error'];
                if (!processCompleteOrFailedStatuses.includes(data.status) && statusIntervalId === -1) {
                    statusIntervalId = setInterval(getStatus, pollIntervalMs);
                } else if (data.status !== 'convert') {
                    // Conversion is done, either successfully or failed,
                    // clear polling and act upon actual state.
                    if (['ok', 'done', 'failed', 'error'].includes(data.status)) {
                        clearInterval(statusIntervalId);
                    }

                    if (['ok', 'done'].includes(data.status)) {
                        const thumbnailUrl = getThumbnailUrl(collectionId, dmsId);
                        resolve(thumbnailUrl);
                    } else if (['failed', 'error'].includes(data.status)) {
                        errorCallback(Error(`Failed to get thumbnail for: ${dmsId}`));
                    }
                }
            };

            const getStatus = () => {
                getObjectStatus(collectionId, dmsId, getStatusCallback, errorCallback);
            }

            return getStatus();
        });
    };


    useDeepCompareEffect(() => {
        function shouldLoadImage() {
            const keys = ["image", "pdf", "video"];
            for (let i = 0, max = keys.length; i < max; i++) {
                if (mimeType.indexOf(keys[i]) > -1) {
                    return true;
                }
            }
            return false;
        }

        if (ready && retry && dmsId && shouldLoadImage()) {
            if (isNotCached(urls[dmsId]) && !hasFailed.includes(dmsId)) {
                if (mimeType.indexOf('video') > -1) {
                    // #DE-660: Skip object conversion status check, when loading video thumbnails.
                    getThumbnailUrl(collectionId, dmsId).then(url => {
                        fetchMedia(url, retry, dimension);
                    });
                } else {
                    getThumbnail(dmsId, collectionId).then(thumbnailUrl => {
                        fetchMedia(thumbnailUrl, retry, dimension);
                    }).catch(e => {
                        clientLog('error', e, componentName);
                    });
                }
            } else if (!urls[dmsId].dimensions[dimension ? dimension : "default"]) {
                fetchMedia(urls[dmsId].url, retry, dimension);
            }
        }
    }, [
        ready,
        dmsId,
        dispatch,
        collectionId,
        dimension,
        urls[dmsId],
        mimeType,
        retry,
    ]);

    if (urls[dmsId] && urls[dmsId].url) {
        return urls[dmsId].dimensions[dimension ? dimension : "default"];
    }
};
