import {useCallback, useEffect} from "react";
import {SET_DMS_STATUS, useDmsStatusDispatch} from "../dmsstatus/DmsStatusContext";
import camelcaseKeysDeep from "camelcase-keys-deep";
import {refreshCredentials} from "../webworkers/refreshCredentials";
import {clientLog} from "../clientLog";
import {kickOffDmsStatusWorker} from "../webworkers/kickOffDmsStatusWorker";

/**
 * Listener used to listen for, and parse, messages sent by the DMS status web worker.
 * @returns {JSX.Element}
 * @constructor
 */
export const DMSStatusWorkerListener = () => {

    const dmsStatusDispatch = useDmsStatusDispatch();

    const isValidMessage = event => {
        if (!event.data) {
            return false;
        }
        const {channel, action} = event.data;
        return !(!channel || channel !== 'client' || !action || action === '');
    };

    /**
     * Method used to parse messages received from the DMS status worker.
     * Listening to "channel" => "client", as messages tagged with this
     * information is used to trigger client-code, outside the web worker.
     * @param event
     */
    const workerMessageParser = useCallback(event => {
        if (!isValidMessage(event)) {
            return;
        }

        const data = event.data;
        const {action} = data;

        if (action === 'start') {
            clientLog('info', 'worker started', 'DMSStatusWorkerListener');
        } else if (action === 'refresh') {
            refreshCredentials();
        } else if (action === 'statusUpdate') {
            dmsStatusDispatch({
                type: SET_DMS_STATUS,
                dmsStatus: camelcaseKeysDeep(data.data),
            });
        } else if (action === 'restartWorker') {
            console.warn('killing web worker: DMSStatusWorker');
            
            // Assuming the web-worker has failed, due to invalid credentials.
            refreshCredentials();

            setTimeout(() => {
                console.warn('restarting web worker: DMSStatusWorker');
                kickOffDmsStatusWorker();
            }, 30000);
        }
    }, [dmsStatusDispatch]);

    /**
     * Hook used to add an event listener that listens for messages from the DMS status web worker.
     */
    useEffect(() => {
        window._dmsStatusWorkerInstance.onmessage = workerMessageParser;
    }, [workerMessageParser]);

    return <></>;
};
