import React, {useCallback, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import OpenSeadragon from "openseadragon";
import ButtonGroup from "@mui/material/ButtonGroup";
import IconButton from "@mui/material/IconButton";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import HomeIcon from "@mui/icons-material/Home";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import {buildStreamUrl} from "../dms/dmsSecurity";
import {useDmsFileMetadata} from "../dms/useDmsFileMetadata";
import {LinearProgress} from "@mui/material";
import {getDmsIdFromModel} from "../utility";
import {clientLog} from "../clientLog";

const PREFIX = "FullscreenViewer";

const classes = {
    openSeaDragon: `${PREFIX}-openSeaDragon`,
    controls: `${PREFIX}-controls`,
    controlButton: `${PREFIX}-controlButton`,
};

const Root = styled("div")(() => ({
    [`&.${classes.openSeaDragon}`]: {
        height: "100%",
        width: "100%",
        backgroundColor: "black",
        zIndex: 5,
    },

    [`& .${classes.controls}`]: {
        position: "absolute",
        top: 0,
        left: 0,
    },

    [`& .${classes.controlButton}`]: {
        color: "white",
        zIndex: 1000,
    },
}));

/**
 * Component used to display the image in "fullscreen" as a deep-zoom image if qualified, or a zoomable image if the
 * resolution is below 4000px.
 *
 * NOTE:
 * In order to test this component locally, you must deactivate React.StrictMode in index.js, otherwise
 * the OpenSeaDragon container will be rendered twice, hiding the deep zoom image.
 *
 * @param model
 * @param disableFullScreen
 * @returns {JSX.Element}
 * @constructor
 */
export const FullscreenViewer = ({model, disableFullScreen = false}) => {
    const [loading, setLoading] = useState(true);
    const [viewer, setViewer] = useState(null);
    const [streamingUrl, setStreamingUrl] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);

    const collectionId = model.collectionId;
    const dmsId = getDmsIdFromModel(model);

    const fileMeta = useDmsFileMetadata({
        collectionId: collectionId,
        dmsId: dmsId,
        ready: true,
    });

    const initOpenSeaDragon = () => {
        if (viewer) {
            viewer.destroy();
        }

        const config = {
            id: "openSeaDragon",
            animationTime: 0.5,
            blendTime: 0.1,
            maxZoomPixelRatio: 8,
            prefixUrl: "osd/",
            zoomInButton: "osd-zoom-in",
            zoomOutButton: "osd-zoom-out",
            homeButton: "osd-home",
            fullPageButton: "osd-fullscreen",
            navigatorPosition: "BOTTOM_RIGHT",
            showNavigator: true,
            constrainDuringPan: true,
        };
        setViewer(OpenSeadragon(config));
    };

    /**
     * Only create a SeaDragon image-stream URL for images larger than 4000 pixels in either direction.
     * @returns {boolean}
     */
    const createImageStream = useCallback(() => {
        let capable = false;
        const resolutionKeys = ["file:ImageHeight", "file:ImageWidth"];

        resolutionKeys.forEach((rk) => {
            if (Object.keys(fileMeta).includes(rk)) {
                const pixels = parseInt(fileMeta[rk]);
                if (!capable && pixels >= 4000) {
                    capable = true;
                }
            }
        });
        return capable;
    }, [fileMeta]);

    /**
     * Hook used to get the URL for the deepzoom image.
     */
    useEffect(() => {
        if (!model.imageUrl) {
            return;
        }
        const imageUrl = model.imageUrl.replace(
            "&dimension=250x250",
            "&dimension=max&failhard=true"
        );
        setImageUrl(imageUrl);
    }, [model.imageUrl]);


    useEffect(() => {
        if (fileMeta && Object.keys(fileMeta).length > 0 && createImageStream()) {
            // Build OpenSeaDragon stream-URL
            buildStreamUrl({
                collectionId: collectionId,
                dmsId: dmsId,
            })
                .then((json) => {
                    setStreamingUrl(json.url);
                })
                .catch((error) => {
                    clientLog('error', error, 'FullscreenViewer');
                    setStreamingUrl(null);
                });
        }
    }, [fileMeta, collectionId, createImageStream, dmsId]);

    useEffect(() => {
        if (streamingUrl && viewer) {
            viewer.addTiledImage({tileSource: streamingUrl});
            setLoading(false);
        } else if (!streamingUrl && imageUrl && viewer) {
            viewer.addSimpleImage({url: imageUrl});
            setLoading(false);
        }
    }, [fileMeta, imageUrl, streamingUrl, viewer]);

    useEffect(() => {
        initOpenSeaDragon();
        return () => {
            if (viewer) {
                viewer.destroy();
                setViewer(null);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Root id={"openSeaDragon"} className={classes.openSeaDragon}>
            <ButtonGroup className={classes.controls}>
                <IconButton
                    id={"osd-zoom-in"}
                    className={classes.controlButton}
                    size="large"
                >
                    <ZoomInIcon/>
                </IconButton>
                <IconButton
                    id={"osd-zoom-out"}
                    className={classes.controlButton}
                    size="large"
                >
                    <ZoomOutIcon/>
                </IconButton>
                <IconButton
                    id={"osd-home"}
                    className={classes.controlButton}
                    size="large"
                >
                    <HomeIcon/>
                </IconButton>
                {!disableFullScreen && (
                    <IconButton
                        id={"osd-fullscreen"}
                        className={classes.controlButton}
                        size="large"
                    >
                        <FullscreenIcon/>
                    </IconButton>
                )}
            </ButtonGroup>

            {loading && (
                <LinearProgress
                    sx={{
                        width: "100%",
                        height: "5px",
                        display: "flex",
                        flowDirection: "row",
                    }}
                    className={classes.controls}
                />
            )}
        </Root>
    );
};
