import Toolbar from "@mui/material/Toolbar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React from "react";
import PropTypes from "prop-types";

const ConfirmExtractionContent = ({classes, t, cancelCallback, confirmCallback}) => {
    return <>
        {t(
            "fileMetaQuestion",
            "Ønsker du at vi skal lete etter metadata i filene for deg?"
        )}
        <Toolbar className={classes.toolbar}>
            <Box flexGrow={1}/>
            <Button variant={"outlined"}
                    color={"secondary"}
                    onClick={cancelCallback}>
                {t("fileMetaNoButton", "Nei")}
            </Button>
            <Button
                variant={"contained"}
                color={"secondary"}
                onClick={confirmCallback}
            >
                {t("fileMetaYesButton", "Ja")}
            </Button>
        </Toolbar></>;
};

ConfirmExtractionContent.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    cancelCallback: PropTypes.func.isRequired,
    confirmCallback: PropTypes.func.isRequired
};

export {ConfirmExtractionContent};