import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsSearch} from "../app/damsFetch";
import {inputWithCollectionIds} from "../metadata/inputMapper";


/**
 * Generates the search parameters for a person search.
 * @param {string} params - The search query
 * @param {Array} museums - The museums to search in
 * @param {string} objectType - The type of object to search for
 * @return {URLSearchParams} The generated search parameters
 */
export const getSearchParams = (params, museums, objectType) => {
    if (params.indexOf(',') !== -1) {
        params = params.replace(',', '');
    }
    return new URLSearchParams(
        decamelizeKeysDeep({
            q: `${params
                .split(" ")
                .map((word) => `_title_:*${word}`)
                .join(" AND ")}`,
            fl: "title,id",
            sort: "title asc",
            expand: true,
            documentType: `("${objectType}")`,
            museums: museums.join(","),
        })
    );
};


/**
 * Fetches the list of persons that matches the given search parameters
 * from the local DAMS database.
 * @param {string} params - The search query
 * @param {Array} museums - The museums to search in
 * @return {Promise<Array>} A list of persons that matches the search parameters
 */
export const fetchPersonsFromDamsDb = async (params, museums) => {
    const searchParams = getSearchParams(params, museums, 'person');
    return damsSearch(searchParams).then((json) => {
            return inputWithCollectionIds(json['models']);
        }
    );
};
