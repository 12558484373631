import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {SearchProvider, SELECT, SELECT_ALL, SET_SELECTED, UNSELECT, UNSELECT_ALL,} from "../search/SearchContext";
import {MyFoldersProvider} from "./MyFoldersContext";
import {decamelize} from "../app/decamelize";
import {FolderSearchSettings} from "../search/FolderSearchSettings";

const FoldersStateContext = createContext(undefined);
const FoldersDispatchContext = createContext(undefined);
const FoldersTranslationContext = createContext(undefined);

export const SET_SEARCHING = "foldersContext/setSearching";
export const SET_DONE_SEARCHING = "foldersContext/setDoneSearching";
export const SET_ERROR = "foldersContext/setError";
export const SET_RESULT = "foldersContext/setResult";
export const SET_SORT = "foldersContext/setSearch";
export const SET_ROWS = "foldersContext/setRows";
export const SET_START = "foldersContext/setStart";
export const TOGGLE_TABLE_VIEW = "foldersContext/toggleTableView";
export const TOGGLE_GRID_VIEW = "foldersContext/toggleGridView";
export const SET_QUERY = "foldersContext/setQuery";
export const SETTINGS_SAVED = "foldersContext/settingsSaved";
export const FOLDERS_DELETED = "foldersContext/deleted";
export const CLEAR_FILTERS = "foldersContext/clearFilters";
export const SET_FQ = "foldersContext/setFq";
export const SET_PRIVATE_ONLY = "foldersContext/setPrivateOnly";

const folderSearchSettings = FolderSearchSettings('dams.folderListSearchResults.config');
const cachedNumRows = folderSearchSettings.getNumberOfRows();
const cachedSortCriteria = folderSearchSettings.getSortCriteria();

const initialState = {
    query: "*:*",
    sort: cachedSortCriteria || "title asc",
    fl: null,
    start: 0,
    rows: cachedNumRows || 10,
    expand: true,
    museums: [],
    fq: [],
    results: {
        count: 0,
        models: [],
        error: false,
        modelsOrder: {},
        facets: {},
        stats: {}
    },
    selected: [],
    searching: false,
    viewMode: "table",
    settingsSaved: false,
    privateOnly: false,
};

const foldersReducer = (state, action) => {
    switch (action.type) {
        case SET_PRIVATE_ONLY:
            return {
                ...state,
                privateOnly: action.value
            };
        case SET_FQ:
            return {
                ...state,
                searching: false,
                fq: action.fq
            };
        case SET_QUERY:
            return {
                ...state,
                query: action.query,
            };
        case SET_SEARCHING:
            return {
                ...state,
                searching: true,
            };
        case SET_DONE_SEARCHING:
            return {
                ...state,
                searching: false
            };
        case SET_RESULT:
            return {
                ...state,
                results: {
                    count: action.count,
                    models: action.models,
                    facets: action.facets,
                    stats: action.stats,
                    error: false,
                    modelsOrder: action.models.reduce(
                        (acc, cur, idx) => ({
                            ...acc,
                            [idx]: cur.uniqueId,
                        }),
                        {}
                    ),
                },
                searching: false,
            };
        case SET_SORT:
            return {
                ...state,
                sort: decamelize(action.sort),
            };
        case SET_ROWS:
            return {
                ...state,
                start: 0,
                rows: action.rows,
            };
        case SET_START:
            return {
                ...state,
                start: action.start,
            };
        case TOGGLE_TABLE_VIEW:
            return {
                ...state,
                viewMode: "table",
            };
        case TOGGLE_GRID_VIEW:
            return {
                ...state,
                viewMode: "grid",
            };
        case SET_ERROR:
            return {
                ...state,
                searching: false,
                results: {
                    ...initialState.results,
                    error: true,
                },
            };
        case SETTINGS_SAVED:
            return {
                ...state,
                settingsSaved: true,
                newFolder: action.newFolder,
            };

        case SET_SELECTED:
            return {
                ...state,
                selected: action.selected,
            };
        case SELECT:
            return {
                ...state,
                selected: [...state.selected, action.model],
            };
        case SELECT_ALL:
            return {
                ...state,
                selected: state.results.models,
            };
        case UNSELECT_ALL:
            return {
                ...state,
                selected: [],
            };
        case UNSELECT:
            return {
                ...state,
                selected: state.selected.filter(
                    (model) => model.uniqueId !== action.model.uniqueId
                ),
            };
        case FOLDERS_DELETED:
            const filteredModels = state.results.models.filter(
                (m) => !state.selected.map((s) => s.uniqueId).includes(m.uniqueId)
            );
            return {
                ...state,
                results: {
                    count: filteredModels.length,
                    models: filteredModels,
                    error: false,
                    modelsOrder: filteredModels.reduce(
                        (acc, cur, idx) => ({
                            ...acc,
                            [idx]: cur.uniqueId,
                        }),
                        {}
                    ),
                },
                searching: false,
            };
        case CLEAR_FILTERS:
            return {
                ...state,
                privateOnly: false,
                fq: []
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const FoldersProvider = ({children}) => {
    const [state, dispatch] = useReducer(foldersReducer, initialState, undefined);
    const {t, ready} = useTranslation("dams.folders", {useSuspense: false});

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <FoldersTranslationContext.Provider value={t}>
            <FoldersStateContext.Provider value={state}>
                <FoldersDispatchContext.Provider value={dispatch}>
                    <MyFoldersProvider>
                        <SearchProvider>{children}</SearchProvider>
                    </MyFoldersProvider>
                </FoldersDispatchContext.Provider>
            </FoldersStateContext.Provider>
        </FoldersTranslationContext.Provider>
    );
};

export const useFoldersState = () => {
    const context = useContext(FoldersStateContext);
    if (undefined === context) {
        throw new Error("useFoldersState must be used within a FoldersProvider");
    } else {
        return context;
    }
};

export const useFoldersDispatch = () => {
    const context = useContext(FoldersDispatchContext);
    if (undefined === context) {
        throw new Error("useFoldersDispatch must be used within a FoldersProvider");
    } else {
        return context;
    }
};

export const useFoldersTranslation = () => {
    const context = useContext(FoldersTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useFoldersTranslation must be used within a FoldersProvider"
        );
    } else {
        return context;
    }
};
