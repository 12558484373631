import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useSearchTranslation} from "../search/SearchContext";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControl from "@mui/material/FormControl";
import PropTypes from "prop-types";

/**
 * Panel used when downloading files, allowing the user to specify custom width- and height settings for the
 * downloaded images.
 * @param multiple
 * @param sizeCallback
 * @returns {JSX.Element}
 * @constructor
 */
const SetImageSize = ({model, multiple, sizeCallback}) => {

    const t = useSearchTranslation();

    const [customSize, setCustomSize] = useState({width: 0, height: 0});
    const [radioOption, setRadioOption] = useState('custom');

    /**
     * Handles when the user enters custom size values for width- and height.
     * @param event
     * @param axis
     */
    const handleSizeChange = (event, axis) => {
        const {target} = event;
        const {value} = target;
        let w = customSize.width;
        let h = customSize.height;
        if (axis === 'width') {
            w = value
        } else {
            h = value;
        }
        sizeCallback({
            width: parseInt(w),
            height: parseInt(h),
            selected: true
        });
        setCustomSize({width: w, height: h});
    };

    /**
     * Handles change in image size type, when the user clicks a radio button.
     * @param event
     */
    const handleRadioChange = event => {
        let w;
        let h;
        const {target} = event;
        const {value: size, checked} = target;
        setRadioOption(size);
        if (size === 'original') {
            w = null;
            h = null;
        } else if (size === 'web') {
            w = 2500;
            h = 2500;
        } else if (size === 'print') {
            w = 4000;
            h = 0;
        } else {
            w = 800;
            h = 600;
        }

        sizeCallback({
            width: w,
            height: h,
            selected: checked
        });
    };

    /**
     * Hook used to set the initial custom size value,
     * and default to this setting when the dialog opens.
     */
    useEffect(() => {
        const w = 800;
        const h = 600;
        setCustomSize({width: w, height: h});
        setRadioOption("custom");
        sizeCallback({width: w, height: h, selected: true});
    }, []);

    return <Box>
        <Divider orientation={"horizontal"} sx={{width: '100%', marginTop: '8px', marginBottom: '8px'}}/>
        {multiple &&
            <Typography variant={"subtitle1"} mt={2} mb={1}>
                {t('oneOrMoreIsImage', 'Vi ser at en eller flere av de merkede filene er bilder')}
            </Typography>
        }
        <Box>
            <FormControl>
                <RadioGroup
                    aria-labelledby="custom-sizes-group"
                    defaultValue={"custom"}
                    name="radio-custom-sizes-group"
                    value={radioOption}
                    onChange={handleRadioChange}>
                    <FormControlLabel value="original" control={<Radio/>} label={t('imageSizeOriginal', 'Original')}/>
                    <FormControlLabel value="web" control={<Radio/>} label={t('imageSizeOptionWeb', 'Web')}/>
                    <FormControlLabel value="print" control={<Radio/>} label={t('imageSizeOptionPrint', 'Trykk')}/>
                    <FormControlLabel value="custom" control={<Radio/>}
                                      label={t('imageSizeOptionCustom', 'Egendefinert')}/>
                </RadioGroup>
            </FormControl>

            <Stack direction={"row"} sx={{marginTop: '8px'}}>
                <TextField id="standard-basic"
                           disabled={radioOption !== 'custom'}
                           label={t('imageWidthPx', "Bredde (px)")}
                           variant="standard"
                           value={customSize.width}
                           onChange={(e) => handleSizeChange(e, 'width')}/>
                <Box ml={2}/>
                <TextField id="standard-basic"
                           disabled={radioOption !== 'custom'}
                           label={t('imageHeightPx', "Høyde (px)")}
                           variant="standard"
                           value={customSize.height}
                           onChange={e => handleSizeChange(e, 'height')}/>
            </Stack>

            {/* DE-616 for info */}
            {/*{model &&*/}
            {/*    <Box sx={{*/}
            {/*        marginTop: '16px'*/}
            {/*    }}>*/}
            {/*        <EditImage t={t} model={model}/>*/}
            {/*    </Box>*/}
            {/*}*/}
        </Box>
    </Box>;
};

SetImageSize.propTypes = {
    model: PropTypes.object,
    multiple: PropTypes.bool.isRequired,
    sizeCallback: PropTypes.func.isRequired
};

export {SetImageSize};
