import React from "react";
import Menu from "@mui/material/Menu";

export const FileCardActionMenu = ({ anchorEl, handleClose, children }) => {
  return (
    <Menu
      id="file-action-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {children}
    </Menu>
  );
};
