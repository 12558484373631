import {SearchSettings} from "./SearchSettings";

/**
 * Functional component exposing method used to cache view settings, such as sort by column, display type etc.
 * for the different types of search results throughout the application.
 * @param storageKey str    Name of the key the values are stored under in localStorage.
 * @returns {{setDisplayType: setDisplayType, getDisplayType: (function(): string), getTableCols: (function(): *), setNumberOfRows: setNumberOfRows, getSortCriteria: (function(): string), setSortColumnAndOrder: setSortColumnAndOrder, getNumberOfRows: (function(): number), setTableCols: setTableCols}}
 * @constructor
 */
export const FolderSearchSettings = (storageKey) => {
    const settings = SearchSettings(storageKey)
    return {
        getNumberOfRows: settings.getNumberOfRows,
        getDisplayType: settings.getDisplayType,
        getSortCriteria: settings.getSortCriteria,
        getTableCols: settings.getTableCols,
        setDisplayType: settings.setDisplayType,
        setNumberOfRows: settings.setNumberOfRows,
        setSortColumnAndOrder: settings.setSortColumnAndOrder,
        setTableCols: settings.setTableCols,
    };
};
