import React, {useEffect} from "react";
import {NewFilesForm} from "./NewFilesForm";
import {
    CHOOSE_COLLECTIONS,
    UPDATE_FORM_DATA,
    useDocumentDispatch,
    useDocumentState,
} from "../documents/documentContext";
import {CollectionSelector} from "../museum/CollectionSelector";
import {useMediaQuery} from "@mui/material";
import {useTheme} from "@emotion/react";
import Box from "@mui/material/Box";

/**
 * Renders the File Form based on the document type and form data.
 *
 * @param {string} documentType - The type of the document.
 * @return {JSX.Element} The rendered File Form component.
 */
export const FileForm = ({documentType}) => {
    const dispatch = useDocumentDispatch();
    const {formData} = useDocumentState();
    const theme = useTheme();
    const smallScreen = useMediaQuery("(max-width: 768px)");

    useEffect(() => {
        dispatch({
            type: UPDATE_FORM_DATA,
            formData: {
                schemaId: 1,
                documentType: documentType,
                locale: "no",
                status: "published",
            },
        });
    }, [dispatch, documentType]);

    if (Object.keys(formData).length === 0) {
        return null;
    }

    const museumSelected = (collectionId) => {
        dispatch({
            type: UPDATE_FORM_DATA,
            formData: {
                ...formData,
                collectionId: collectionId,
            },
        });
        dispatch({
            type: CHOOSE_COLLECTIONS,
            collectionIds: [collectionId],
        });
    };

    const getFormOrMuseumSelector = () => {
        if (formData.collectionId) {
            return <NewFilesForm/>;
        } else {
            return <CollectionSelector onSelect={museumSelected} fileUpload={true}/>;
        }
    };

    return (
        <Box
            sx={{
                margin: smallScreen ? theme.spacing(1) : theme.spacing(2),
                padding: smallScreen ? theme.spacing(1) : theme.spacing(2),
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                marginBottom: '80px',
                overflow: 'auto'
            }}
        >
            {getFormOrMuseumSelector()}
        </Box>
    );
};
