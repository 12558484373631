import { useSearchTranslation } from "../search/SearchContext";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import React, { useRef, useState } from "react";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ClickAwayListener, Grow, MenuList, Paper, Popper } from "@mui/material";

/**
 * Create project button, gives the user the opportunity to create a new project of his/hers choice.
 * @param createProjectCallback method  Called when the project type has been chosen.
 * @returns {JSX.Element}
 * @constructor
 */
export const ButtonCreateNewProject = ({ createProjectCallback }) => {
    const t = useSearchTranslation();

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    const options = [
        { projectType: 'general', name: t('btnMenuGeneral', 'Generelt prosjekt') },
        { projectType: 'archeology', name: t('btnMenuArcheology', 'Arkeologiprosjekt') },
    ];

    const handleMenuItemClick = (projectType) => {
        setOpen(false);
        createProjectCallback(projectType);
    };

    const handleBtnToggle = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return <>
        <ButtonGroup variant={"contained"}
            ref={anchorRef}
            aria-label={"split button"}
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup={"menu"}
            color={"secondary"}
            onClick={handleBtnToggle}
            sx={{ marginRight: 1 }}
        >
            <Button size={"small"}>
                Opprett
            </Button>
            <Button size={"small"}>
                <ArrowDropDownIcon />
            </Button>
        </ButtonGroup>

        <Popper open={open}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            data-testid='button-create-new-project-popper'
            sx={{ zIndex: 2000 }}
        >
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
                    }}>
                    <Paper sx={{ width: '200px' }}>
                        <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                                autoFocusItem
                                id={"split-button-menu"}
                            >
                                {
                                    options.map((option) => (
                                        <MenuItem
                                            key={crypto.randomUUID()}
                                            onClick={
                                                () => {
                                                    handleMenuItemClick(option.projectType);
                                                }
                                            }>
                                            {option.name}
                                        </MenuItem>)
                                    )
                                }
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </>;
};