import React from "react";
import {styled} from "@mui/material/styles";
import {Formik} from "formik";
import {FormikSelect} from "../form/FormikSelect";
import {FormikTextField} from "../form/FormikTextField";
import {FormActions} from "../metadata/FormActions";
import {useDocumentTranslation} from "../documents/documentContext";
import * as Yup from "yup";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import {format} from "../app/dateFormat";
import {RelationTypes} from "./RelationTypes";

const PREFIX = "RelatedObjectForm";

const classes = {
    form: `${PREFIX}-form`,
    textField: `${PREFIX}-textField`,
};

const StyledFormik = styled(Formik)(({theme}) => ({
    [`& .${classes.form}`]: {
        display: "flex",
        flexDirection: "column",
    },

    [`& .${classes.textField}`]: {
        marginBottom: theme.spacing(2),
    },
}));

const schema = () => {
    return Yup.object().shape({
        relationTypeId: Yup.string().required("Påkrevd"),
        sourceId: Yup.string(),
    });
};

export const RelatedObjectForm = ({callback, model}) => {
    const t = useDocumentTranslation();
    const relationTypes = RelationTypes().getList();

    const onClose = () => {
        callback(null);
    };

    return (
        <StyledFormik
            initialValues={{
                relationTypeId: model?.relationTypeId || "",
                relationDescription: model?.relationDescription || "",
                uniqueId: model?.uniqueId,
            }}
            onSubmit={(values) => callback(values)}
            validationSchema={schema()}
        >
            <div className={classes.form}>
                <Typography variant={"h5"} gutterBottom={true}>
                    {t("txtEditObjectRelation", "Redigere relasjon")}
                </Typography>
                <Typography variant={"body1"}>
                    {(t("txtDocument", "Dokument"))}: {model?.title}
                </Typography>
                <Typography variant={"body2"} gutterBottom={true}>
                    {t("txtPublishedInfo", "Lagt ut")}: {model?.createdByName} -{" "}
                    {format(model?.createdAt, "P")}
                </Typography>
                <Typography variant="body2">
                    {t("txtDescribeObjectRelation", "Beskriv relasjon")}
                </Typography>
                <Typography variant="body2" gutterBottom={true}>
                    {t(
                        "txtDescribeObjectRelationHelp",
                        "Hvis nødvendig, beskriv relasjonen ved å fylle ut feltene nedenfor." +
                        'Klikker du "Avbryt", vil relasjonen allikevel opprettes - uten beskrivelse eller type.'
                    )}
                </Typography>
                <FormikSelect
                    formikKey={"relationTypeId"}
                    label={t("txtTypeObjectRelation", "Type relasjon")}
                    fullWidth={true}
                    className={classes.textField}
                >
                    {relationTypes.map((r) => (
                        <MenuItem key={r.id} value={r.id}>
                            {r.title}
                        </MenuItem>
                    ))}
                </FormikSelect>
                <FormikTextField
                    formikKey={"relationDescription"}
                    className={classes.textField}
                    label={t("relationDescription", "Relasjonsbeskrivelse")}
                    fullWidth={true}
                />
                <FormActions onCancel={onClose}/>
            </div>
        </StyledFormik>
    );
};
