import React, { useState } from "react";
import {
  useFoldersDispatch,
  useFoldersState,
  useFoldersTranslation,
} from "./FoldersContext";
import {
  useFolderActionDispatch,
  useFolderActionState,
  CLOSE,
  OPEN,
} from "./FolderActionContext";
import { If } from "../app/If";
import { SELECT_ALL, UNSELECT_ALL } from "../search/SearchContext";
import { DeleteFolderDialog } from "./DeleteFolderDialog";
import { useDeleteFolder } from "./useDeleteFolder";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";

export const FolderActions = () => {
  const t = useFoldersTranslation();
  const dispatch = useFolderActionDispatch();
  const { selected } = useFoldersState();
  const { anchorEl } = useFolderActionState();
  const foldersDispatch = useFoldersDispatch();
  const [deleteFolders] = useDeleteFolder(t);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const numSelected = selected.length;

  const handleClose = () => {
    dispatch({ type: CLOSE });
  };

  const handleClick = (event) => {
    if (anchorEl) {
      handleClose();
    } else {
      dispatch({
        type: OPEN,
        anchorEl: event.currentTarget,
      });
    }
  };

  const checkAll = () => {
    foldersDispatch({ type: SELECT_ALL });
  };

  const uncheckAll = () => {
    foldersDispatch({ type: UNSELECT_ALL });
  };

  const openDeleteFoldersDialog = () => {
    setOpenDeleteDialog(true);
  };

  const deleteFoldersCallback = (deleteState) => {
    if (deleteState) {
      const folderIds = selected.map((f) => f.uniqueId);
      deleteFolders(folderIds);
    }
    handleClose();
    setOpenDeleteDialog(false);
  };

  return (
    <>
      <ButtonGroup
        variant="text"
        color="secondary"
        aria-label="folders-results-action-button"
      >
        <Button
          aria-controls={Boolean(anchorEl) ? "action-menu" : undefined}
          aria-expanded={Boolean(anchorEl) ? "true" : undefined}
          aria-label="trigger action"
          aria-haspopup="menu"
          onClick={handleClick}
        >
          {t("choices", "Valg")}&nbsp;({numSelected})
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => openDeleteFoldersDialog()}
          disabled={numSelected === 0}
        >
          <ListItemIcon>
            <DeleteOutlineIcon fontSize="small" />
          </ListItemIcon>
          {t("deleteChecked", "Slett merkede")}
        </MenuItem>
        <If boolean={selected.length === 0}>
          <MenuItem onClick={checkAll}>
            <ListItemIcon>
              <CheckBoxIcon fontSize="small" />
            </ListItemIcon>
            {t("checkAll", "Merk alle")}
          </MenuItem>
        </If>
        <If boolean={selected.length > 0}>
          <MenuItem disabled={0 === selected.length} onClick={uncheckAll}>
            <ListItemIcon>
              <CheckBoxOutlineBlankIcon fontSize="small" />
            </ListItemIcon>
            {t("uncheckAll", "Avmerk alle")}
          </MenuItem>
        </If>
      </Menu>
      <DeleteFolderDialog
        t={t}
        openDialog={openDeleteDialog}
        callback={deleteFoldersCallback}
      />
    </>
  );
};
