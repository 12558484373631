import {Box, Toolbar, Typography} from "@mui/material";
import React from "react";
import PropTypes from "prop-types";

/**
 * Renders the header for the delete- or remove files modal.
 * Used by the components DeleteFilesModal and RemoveFilesModal.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {string} props.modalType - The type of the modal.
 * @param {Array} props.selectedDocuments - The selected documents.
 * @return {JSX.Element} The rendered header component.
 */
const DeleteFilesModalHeader = ({t, modalType, selectedDocuments}) => {

    const count = selectedDocuments.length;

    const heading = modalType === 'remove'
        ? t("removeDocumentsHeader", "Fjerne dokumenter")
        : t("deleteDocumentsHeader", "Slette dokumenter");

    let confirm = t(
        "removeDocumentsConfirm",
        "Fjerne {{count}} {{text}} fra mappen?",
        {
            count: count,
            text:
                selectedDocuments.length > 1
                    ? "dokumenter"
                    : "dokument",
        }
    );

    if (modalType === 'delete') {
        confirm = t(
            "deleteDocumentsConfirm",
            "Er du sikker på at du ønsker å slette {{count}} {{text}}?",
            {
                count: count,
                text:
                    count > 1
                        ? "dokumenter"
                        : "dokument",
            }
        )
    }

    return <Toolbar sx={{
        padding: 0,
        flexWrap: "wrap",
        marginBottom: "8px"
    }}>
        <Typography component={"h2"} variant={"h6"}>
            {heading}
        </Typography>
        <Box sx={{
            flexBasis: "100%",
            height: 0,
        }}/>
        <Typography component={"div"}>
            {confirm}
        </Typography>
    </Toolbar>
};

DeleteFilesModalHeader.propTypes = {
    "t": PropTypes.func.isRequired,
    "modalType": PropTypes.string.isRequired,
    "selectedDocuments": PropTypes.any.isRequired
};

export {DeleteFilesModalHeader};