import React, {useState} from "react";
import {useDocumentState, useDocumentTranslation,} from "../../documents/documentContext";
import {useDmsMediaUrl} from "../../dms/useDmsMediaUrl";
import {If} from "../../app/If";
import {format} from "../../app/dateFormat";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import {DialogContent, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";
import {useDecideThumbnailType} from "../../files/useDecideThumbnailType";

import '../../files/uploadedfilegriditem.css';


export const UploadedFileGridItemSmallScreen = ({file, checked, onCheck}) => {
    const t = useDocumentTranslation();
    const {saved, failed, formData} = useDocumentState();
    const [showMetadataSummary, setShowMetadataSummary] = useState(false);

    const imageUrl = useDmsMediaUrl({
        collectionId: formData.collectionId,
        dmsId: file.dmsId,
        mimeType: file.mimeType,
    });

    const {showUnknownThumb, showAudioThumb} = useDecideThumbnailType(file);

    const getSaved = (filename) => {
        return saved.filter(
            (s) => s.content.mediae[0].reference.title === filename
        );
    };

    const getCheckIcon = () => {
        if (!checked) {
            return (
                <CheckBoxOutlineBlankIcon
                    className={'infoIcon'}
                    sx={{left: 3, color: getIconColor()}}
                    onClick={onCheck}
                />
            );
        } else {
            return (
                <CheckBoxIcon
                    className={'infoIcon'}
                    sx={{left: 3, color: getIconColor()}}
                    onClick={onCheck}
                />
            );
        }
    };

    function _getLabelAndValue(id, value, getArrayOfValues, fileData, props, i, data, label) { // NOSONAR
        if (['persons', 'places', 'subjects', 'licenses', 'producer'].includes(id)) {
            value = getArrayOfValues(id);
        } else {
            value = fileData[props[i].id];
            if (!value) {
                value = fileData.content[props[i].id];
            }
            if (value && id === "productionDate") {
                value = format(value, "P");
            } else if (value && id === "languages") {
                value = value.map((v) => v.title);
            }
            value = Array.isArray(value) ? value.join(" ") : value;
        }

        if (value) {
            data.push(label + ": " + value);
        } else {
            data.push(label + ":");
        }
    }

    const createMetadataSummary = ({file}) => {
        const savedFile = getSaved(file.name);

        function getArrayOfValues(key) {
            const d = savedFile[0].content[key];
            if (!d) {
                return undefined;
            }
            return d.map((p) => p.reference.title);
        }

        const props = [
            {id: "title", label: t("title", "Tittel")},
            {id: "description", label: t("description", "Beskrivelse")},
            {id: "customIdentifier", label: t("customIdentifier", "Museumsnummer")},
            {id: "productionDate", label: t("productionDate", "Produksjonsdato")},
            {
                id: "producer",
                label: t("producer", {context: formData.documentType}),
            },
            {id: "persons", label: t("persons", "Personer")},
            {id: "places", label: t("places", "Steder")},
            {id: "subjects", label: t("subjects", "Emneord")},
            {id: "languages", label: t("languages", "Språk")},
            {id: "licenses", label: t("licenses", "Lisenser")},
            {id: "relations", label: t("relations", "Relaterte objekter")},
            {id: "remarks", label: t("remarks", "Kommentar")},
        ];

        let propsTable = "";
        let data = [];

        if (savedFile?.length > 0) {
            const fileData = savedFile[0];
            for (let i = 0, max = props.length; i < max; i++) {
                const id = props[i].id;
                const label = props[i].label;
                let value = undefined;
                _getLabelAndValue(id, value, getArrayOfValues, fileData, props, i, data, label);
            }
            // Construct props table
            propsTable = (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexFlow: "column",
                    }}
                >
                    {data.map((d) => {
                        const parts = d.split(":");
                        const label = (
                            <Typography noWrap variant={"body"}>
                                {parts[0] + ":"}
                            </Typography>
                        );

                        let value = <Typography variant={"caption"}>{parts[1]}</Typography>;
                        if (parts[1] === "") {
                            value = (
                                <Typography variant={"caption"} color={"secondary"}>
                                    {t("noData", "Ingen data")}
                                </Typography>
                            );
                        }

                        return (
                            <Box key={parts[0]}
                                 sx={{display: "flex", justifyItems: "flex-start"}}>
                                <Box sx={{textAlign: "right"}}>{label}</Box>
                                <Box sx={{marginLeft: 1}}>{value}</Box>
                            </Box>
                        );
                    })}
                </Box>
            );
        }

        return (
            <>
                <DialogTitle>
                    <Box sx={{display: "flex"}}>
                        <Typography
                            sx={{
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                            }}
                        >
                            Metadata for {file.name}
                        </Typography>
                        <Box flexGrow={1}/>
                        <CloseIcon
                            sx={{marginLeft: 2, color: "GrayText"}}
                            onClick={() => setShowMetadataSummary(false)}
                        />
                    </Box>
                </DialogTitle>
                <DialogContent>{propsTable}</DialogContent>
            </>
        );
    };

    /**
     * Sets the color of the checkbox- and info icons, based on the current file's "save-state".
     * @returns {string}
     */
    function getIconColor() {
        let c = "rgba(255, 255, 255, 0.6)";

        const success = saved.find(
            (document) => document.content.mediae[0].reference.title === file.title
        );

        const error = failed.includes(file.title);

        if (success) {
            c = "rgba(60, 179, 113, 0.8)";
        } else if (!success && error) {
            c = "rgba(255,0,0, 0.8)";
        }
        return c;
    }

    const getTopmostOverlay = () => {
        return (
            <Box className={'infoContainer'}>
                {getCheckIcon()}
                <InfoIcon
                    className={'infoIcon'}
                    onClick={() => setShowMetadataSummary(true)}
                    sx={{
                        right: 3,
                        color: getIconColor(),
                    }}
                />
            </Box>
        );
    };

    return (
        <Box key={file.title}>
            <If boolean={showAudioThumb}>
                <div className={'fileThumbContainer'} key={file.title}>
                    <VolumeUpOutlinedIcon className={'fileThumbIcon'}/>
                    {getTopmostOverlay()}
                </div>
            </If>
            <If boolean={showUnknownThumb}>
                <div className={'fileThumbContainer'} key={file.title}>
                    <DescriptionOutlinedIcon className={'fileThumbIcon'}/>
                </div>
            </If>
            <If boolean={!showAudioThumb && !showUnknownThumb}>
                <Box key={file.title}>
                    <img
                        key={file.title}
                        src={imageUrl}
                        alt={file.title}
                        className={'imageFullHeight'}
                    />
                    {getTopmostOverlay()}
                </Box>
            </If>
            <ImageListItemBar
                position={"bottom"}
                title={file.title}
                subtitle={file.file.name}
                sx={{
                    "& .MuiImageListItemBar-titleWrap": {
                        background:
                            "linear-gradient(0deg, rgba(51,51,51,0) 0%, rgba(255,255,255, 0.1) 100%)",
                        padding: 1,
                    },
                    "& .MuiImageListItemBar-title": {
                        fontSize: "12px",
                    },
                    "& .MuiImageListItemBar-subtitle": {
                        fontSize: "9px",
                    },
                }}
            />
            {showMetadataSummary && (
                <Dialog
                    open={showMetadataSummary}
                    onClose={() => setShowMetadataSummary(false)}
                    PaperProps={{
                        sx: {
                            width: "95vw",
                            height: "50vh",
                            position: "absolute",
                        },
                    }}
                >
                    {createMetadataSummary(file)}
                    <Box sx={{padding: 2, display: "flex", justifyContent: "flex-end"}}>
                        <Button
                            variant={"outlined"}
                            onClick={() => {
                                onCheck(file.title);
                                setShowMetadataSummary(false);
                            }}
                        >
                            {t('btnEdit', 'Rediger')}
                        </Button>
                    </Box>
                </Dialog>
            )}
        </Box>
    );
};
