import React from "react";
import {Mediae} from "./Mediae";
import {MetaStep} from "../metadata/MetaStep";
import {useNewFilesState} from "./NewFilesContext";
import {AddToFolderStep} from "./AddToFolderStep";
import {useMediaQuery} from "@mui/material";
import {AddToFolderStepSmallScreen} from "./smallscreen/AddToFolderStepSmallScreen";
import {FoldersProvider} from "../folder/FoldersContext";

export const NewFilesForm = () => {
    // eslint-disable-next-line no-unused-vars
    const {previousStepIndex, activeStepIndex} = useNewFilesState(); // NOSONAR
    const smallScreen = useMediaQuery("(max-width: 768px)");

    const getSteps = (_) => {
        let STEP_FORM = {
            0: <Mediae/>,
            1: <MetaStep/>,
            2: <AddToFolderStep/>,
        };

        if (smallScreen) {
            STEP_FORM = {
                0: <Mediae/>,
                1: <MetaStep/>,
                2: <FoldersProvider><AddToFolderStepSmallScreen/></FoldersProvider>,
            };
        }
        return STEP_FORM[activeStepIndex];
    };

    return <>{getSteps()}</>;
};
