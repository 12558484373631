import {damsFetch} from "../../../app/damsFetch";
import {clientLog} from "../../../clientLog";

/**
 * Checks to see if the specified id is used in an existing general project.
 * @param collectionId
 * @param id    str The ID to be verified.
 */
export const idExists = (collectionId, id) => {
    return new Promise((resolve) => {
        damsFetch(`/projects/general/id-exists/${collectionId}/${id}`)
            .then(data => {
                return resolve(data.exists);
            })
            .catch(e => clientLog('error', e));
    });
};
