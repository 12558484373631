/**
 * Returns a list of base table columns for use in the admin interface.
 *
 * Each column is an object with the following properties:
 * - accessorKey: The key to access the data in the row object.
 * - header: The header name to display in the table.
 * - enableEditing: A boolean indicating whether the cell is editable.
 * - enableSorting: A boolean indicating whether the column is sortable.
 * - enableHiding: A boolean indicating whether the column can be hidden.
 *
 * @returns {object[]} The list of columns.
 */
export const getTableColumnsBase = () => {
    return [
        {
            accessorKey: 'id',
            header: 'ID',
            enableEditing: false,
            Edit: () => null,
            enableHiding: false
        },
        {
            accessorKey: 'collectionId',
            header: 'Collection ID',
            enableEditing: false,
            Edit: () => null,
            enableHiding: false
        },
        {
            accessorKey: 'uniqueId',
            header: 'Unique ID',
            enableEditing: false,
            enableHiding: false
        }
    ];
};