import {styled} from "@mui/material/styles";
import React from "react";
import {format} from "../app/dateFormat";
import {
  HIDE,
  useSearchResultDetailsDispatch,
  useSearchResultDetailsTranslation,
} from "../search-result-details/SearchResultDetailsContext";
import "react-lazy-load-image-component/src/effects/blur.css";
import {CLEAR_SEARCH_AND_ADD_FQ, useSearchDispatch,} from "../search/SearchContext";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {SquareThumbWithDocTypeOverlay} from "../app/SquareThumbWithDocTypeOverlay";
import {Stack} from "@mui/material";

const PREFIX = "RelatedObjectCard";

const classes = {
    card: `${PREFIX}-card`,
    cardContent: `${PREFIX}-cardContent`,
    icon: `${PREFIX}-icon`,
    fileThumbContainer: `${PREFIX}-fileThumbContainer`,
    fileThumbIcon: `${PREFIX}-fileThumbIcon`,
    title: `${PREFIX}-title`,
    thumbnail: `${PREFIX}-thumbnail`,
};

const StyledCard = styled(Card)(() => ({
    [`&.${classes.card}`]: {
        width: "100%",
        display: "inline-block",
        cursor: "pointer",
    },

    [`& .${classes.cardContent}`]: {
        minHeight: "100%",
        background: "#eeeeee",
    },

    [`& .${classes.title}`]: {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        width: 160,
        maxWidth: 300,
    },
}));

export const RelatedObjectCard = ({model}) => {
    const searchDispatch = useSearchDispatch();
    const dispatch = useSearchResultDetailsDispatch();
    const t = useSearchResultDetailsTranslation();

    const showObject = (_) => {
        const key = "unique_id";
        const value = model.uniqueId;
        searchDispatch({
            type: CLEAR_SEARCH_AND_ADD_FQ,
            fq: {
                key: key,
                value: `"${value}"`,
            },
        });
        dispatch({type: HIDE});
    };

    const firstCaps = (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1);
    };

    const {title, relationType, relationDescription, createdByName, createdAt} = model;

    return (
        <StyledCard
            className={classes.card}
            variant={"outlined"}
            onClick={showObject}
        >
            <CardContent className={classes.cardContent}>
                <Stack direction={"row"}>
                    <SquareThumbWithDocTypeOverlay model={model}/>
                    <Stack direction={"column"} ml={1}>
                        <Typography variant={"body2"} className={classes.title} noWrap>
                            {title}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" component="p">
                            {firstCaps(relationType)}
                        </Typography>
                        <Typography
                            variant="caption"
                            color="textSecondary"
                            component="p"
                            noWrap
                        >
                            {relationDescription !== ""
                                ? relationDescription
                                : "<" + t("txtNoDescription", "ingen beskrivelse") + ">"}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" component="p">
                            {createdByName}
                        </Typography>
                        <Typography variant="caption" color="textSecondary" component="p">
                            {format(createdAt, "P")}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </StyledCard>
    );
};
