import React from "react";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import PreviewIcon from "@mui/icons-material/Preview";
import Box from "@mui/material/Box";
import {CircularProgress, Stack} from "@mui/material";

const columnStyles = {
    borderBottom: 'solid 1px #ddd',
    height: '60px',
    minHeight: '60px',
};

/**
 * DataTable component, used to display a list of jobs with various columns, based on the following JSON-structure:
 * [
 *     {
 *         "jobId": "36d069b8-4c4d-4119-8fdc-ae7cfe51c8e8",
 *         "createdAt": "28.02.2024 11:55:51",
 *         "phases": [
 *             {
 *                 "name": "created",
 *                 "priority": 0
 *             },
 *             {
 *                 "name": "initializing",
 *                 "priority": 1
 *             },
 *             {
 *                 "name": "running",
 *                 "priority": 2
 *             }
 *         ],
 *         "sourceFolder": "/app/server/tests/static_assets",
 *         "initiator": {
 *             "appId": "bfb1b23a-97a9-483e-99e2-fa61e4b5b7c6",
 *             "museum": {
 *                 "id": 20,
 *                 "name": "KulturIT",
 *                 "ekulturId": "KIT"
 *             },
 *             "museumId": 20,
 *             "userEmail": "jonas.olstad@gmail.com",
 *             "collectionId": 2
 *         }
 *     },
 *     {
 *      ....
 *     }
 * ]
 *
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Array<Object>} props.jobs - The list of jobs to display.
 * @param {Function} props.previewCallback - The callback function for previewing a job.
 * @returns {JSX.Element} The DataTable component.
 */
export const DataTable = ({t, jobs, previewCallback}) => {

    const columnWidths = [300, 400, 300, 150, 200, 300, 130];

    const getHeaderRow = () => {
        const headers = [
            {key: 'image-import-job-id', label: t('imageImportJobId', 'Jobb ID')},
            {key: 'image-import-source', label: t('imageImportSource', 'Importkilde')},
            {key: 'image-import-status', label: t('imageImportStatus', 'Status')},
            {key: 'image-import-created', label: t('imageImportCreated', 'Opprettet')},
            {key: 'image-import-museum', label: t('imageImportMuseum', 'Museum')},
            {key: 'image-import-user', label: t('imageImportUser', 'Bruker')},
            {key: 'image-import-show-details', label: t('imageImportShowDetails', 'Vis detaljer')}
        ];
        return (
            <Grid container direction={"row"} spacing={2} alignItems={"center"} key={"header-container"}>
                {headers.map((h, i) => {
                    return <Grid
                        sx={{
                            width: `${columnWidths[i]}px`,
                            color: 'white',
                            height: '60px',
                            minHeight: '60px',
                            ...columnStyles
                        }}
                        alignSelf={"center"}
                        key={h.key}
                    >
                        <Typography>{h.label}</Typography>
                    </Grid>;
                })}
            </Grid>
        );
    };

    const getColumn = (jobId, data, ix) => {
        return <Grid
            sx={{width: `${columnWidths[ix]}px`, ...columnStyles}}
            key={`${jobId}-col-${data}`}>
            <Typography variant={"caption"}>
                {data}
            </Typography>
        </Grid>;
    };

    const mapPhases = (jobId, phases) => {
        const active = phases[phases.length - 1].name !== 'done';
        return <Stack direction={"row"} alignItems={"center"} key={`phases-${jobId}`}>
            {active && <CircularProgress size={20} sx={{marginRight: '16px'}} key={`phases-progress-${jobId}`}/>}
            {!active && <Box sx={{width: '20px', marginRight: '16px'}} key={`phases-progress-done-${jobId}`}></Box>}
            {
                phases.map((p, ix) => {
                    return <Box key={`container-phases-${jobId}-phase-${p.name}`}>
                        {
                            (ix < phases.length - 1) &&
                            <Typography variant={"caption"} mr={1} key={`${jobId}-${p.name}`}>{p.name}</Typography>
                        }
                        {
                            (ix === phases.length - 1) &&
                            <Typography variant={"body1"} key={`${jobId}-${p.name}`}>{p.name}</Typography>
                        }
                    </Box>;
                })
            }
        </Stack>;
    };

    const getContents = () => {
        return jobs.map(r => {
            return <Grid container
                         direction={"row"}
                         spacing={2}
                         alignItems={"center"}
                         key={`${r.jobId}-item-container`}>
                {getColumn(r.jobId, r.jobId, 0)}
                {getColumn(r.jobId, r.sourceFolder, 1)}
                {getColumn(r.jobId, mapPhases(r.jobId, r.phases), 2)}
                {getColumn(r.jobId, r.createdAt, 3)}
                {getColumn(r.jobId, r.initiator.museum.name, 4)}
                {getColumn(r.jobId, r.initiator['userEmail'], 5)}
                <Grid
                    sx={{
                        cursor: 'pointer',
                        width: `${columnWidths[columnWidths.length - 1]}px`,
                        ...columnStyles
                    }}
                    key={`${r.jobId}-preview`}
                >
                    <PreviewIcon onClick={previewCallback ? () => previewCallback(r) : {}}
                                 key={`btn-preview-${r.jobId}`}/>
                </Grid>
            </Grid>;
        });
    };

    if (jobs.length === 0) {
        return <></>
    } else {
        return <Grid container direction={"column"} spacing={1} justifyContent={"space-around"}
                     key={"main-container"} sx={{marginLeft: '8px'}}>
            {getHeaderRow()}
            {getContents()}
        </Grid>;
    }
};