import React from "react";
import Checkbox from "@mui/material/Checkbox";

/**
 * Renders a checkbox component for a filter.
 *
 * @param {Object} props - The props object containing the following properties:
 *   - selected: An array of selected filter objects.
 *   - subjectsKey: The key of the subjects filter.
 *   - value: The value of the checkbox.
 * @return {JSX.Element} The rendered checkbox component.
 */
export const FilterCheckbox = ({selected, subjectsKey, value}) => {

    const isChecked = selected.some(item => item.key === subjectsKey && (item.value === value || item.value === `"${value}"`));

    return (
        <Checkbox
            disableRipple
            edge="start"
            tabIndex={-1}
            inputProps={{"aria-labelledby": `label-${value}`}}
            value={value}
            checked={isChecked}
        />
    );
};
