import {FolderCardBase} from "./FolderCardBase";
import {
    CLEAR_ADDING_TO_FOLDER,
    CLEAR_SELECTED,
    SET_ADDING_TO_FOLDER,
    useFileUploadDispatch,
    useFileUploadState
} from "../fileUploadContext";
import {usePostFolderDocument} from "../../folder/usePostFolderDocument";
import {useEffect} from "react";
import Box from "@mui/material/Box";


/**
 * Clickable folder card, used when adding files to a folder on small screens.
 * @param WrappedComponent
 * @returns {function(*): *}
 */
function withClickSupport(WrappedComponent) {
    return function WithClickSupport(props) {

        const {folder} = props;

        const fileUploadDispatch = useFileUploadDispatch();
        const {selected, addingToFolder} = useFileUploadState();

        const [postFolderDocumentResponse, postFolderDocument, clearAll] = usePostFolderDocument(folder.id);

        const {pending, success, error} = postFolderDocumentResponse;

        const onClickHandler = () => {
            fileUploadDispatch({
                type: SET_ADDING_TO_FOLDER,
                folderId: folder.id
            });
            selected.forEach(id => postFolderDocument({documentId: id}));
        };

        useEffect(() => {
            if (!addingToFolder || pending > 0) {
                return;
            }

            if (pending === 0 && success >= selected.length) {
                setTimeout(() => {
                    fileUploadDispatch({type: CLEAR_ADDING_TO_FOLDER});
                    fileUploadDispatch({type: CLEAR_SELECTED});
                    clearAll();
                }, 500);
            }
        }, [addingToFolder, pending, success, error])

        return <Box>
            <WrappedComponent {...props}
                              onClickHandler={onClickHandler}/>
        </Box>;
    };
};

export const ClickableFolderCard = withClickSupport(FolderCardBase);