import React from "react";
import {NewFilesStepper} from "../stepper/NewFilesStepper";
import {NewFilesProvider} from "./NewFilesContext";
import {NewFilesTranslationProvider} from "./NewFilesTranslationContext";
import {useTheme} from "@emotion/react";
import Box from "@mui/material/Box";

export const NewFilesView = ({children}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                paddingTop: theme.spacing(8),
                width: "100%",
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <NewFilesTranslationProvider>
                <NewFilesProvider>
                    <NewFilesStepper/>
                    {children}
                </NewFilesProvider>
            </NewFilesTranslationProvider>
        </Box>
    );
};
