import {UPDATE_FORM_DATA} from "../documents/documentContext";


/**
 * Handler triggered when the user clicks the delete icon,
 * used to delete copyright clauses and licenses.
 * @param docDispatch method    Dispatcher method used to "relay" messages when data is altered.
 * @param formData  object  object containing all the form fields and their values.
 * @param setFieldValue method   Formik method used to set a specific field value.
 * @param objectType    {*}     clause || license object
 * @param ix    integer     The index number in the fields array
 * @param obj   {*}     The object selected for deletion
 * @param values array  Array holding the previously set values.
 */
export const deleteHandler = (docDispatch, formData, setFieldValue, objectType, ix, obj, values) => {
    if (objectType === 'clause') {
        _deleteCopyrightClauseHandler(formData, setFieldValue, values, obj);
    } else {
        return;
    }

    docDispatch({
        type: UPDATE_FORM_DATA,
        formData: formData,
    });
};

/**
 * Cleans up the copyright information by marking the specified entry as deleted,
 * removing it from the formData object, and rebuilding the values array based on the
 * remaining copyrightInfo entries. It also removes the corresponding copyrightFromDate
 * and copyrightToDate entries from the values object.
 *
 * @param {Object} formData - The form data object containing the copyrightInfo array.
 * @param {number} clauseIx - The index of the copyright clause to be deleted.
 * @param {Object} values - The values object containing the copyright information.
 */
export const cleanupCopyrightInfo = (formData, clauseIx, values) => {
    // Mark the copyrightInfo entry as deleted - remove it from the GUI!
    formData.copyrightInfo[clauseIx].deleted = true;

    // Remove the data from the formData object.
    formData.copyrightInfo.splice(clauseIx, 1);

    //
    // Rebuild the values array, based on the data in the formData object.
    //

    // Removing copyrightFromDate- and copyrightToDate entries.
    const keysArr = Object.keys(values);
    keysArr.forEach(k => {
        if (k.indexOf('copyrightFromDate') > -1 || k.indexOf('copyrightToDate') > -1) {
            delete values[k];
        }
    });

    // Add the necessary entries to the values object - the values being saved!
    for (let i = 0, max = values.copyrightInfo.length; i < max; i++) {
        values[`copyrightFromDate${i}`] = values.copyrightInfo[i]['fromDate'];
        values[`copyrightToDate${i}`] = values.copyrightInfo[i]['toDate'];
    }
};

/**
 * Handle used to delete a copyright clause entry.
 * @param formData  object  object containing all the form fields and their values.
 * @param setFieldValue method   Formik method used to set a specific field value.
 * @param values array  Array holding the previously set values.
 * @param obj
 *
 * NOTE:
 * Object data is maintained in two parallel structures:
 * - formData - directly manipulated and updated via documentDispatch()
 * - Formik - each field is updated vid setField(key, value). Using setFieldValue(key, undefined) removes the field from
 *            the formik structure.
 */
const _deleteCopyrightClauseHandler = (formData, setFieldValue, values, obj) => {
    const clauseIx = formData.copyrightInfo.findIndex(c => c.uniqueId === obj.uniqueId);

    if (clauseIx === -1) {
        return;
    }
    cleanupCopyrightInfo(formData, clauseIx, values);
};

