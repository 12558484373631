import React from "react";
import {useTheme} from "@emotion/react";
import Paper from "@mui/material/Paper";

export const UploadedFiles = ({children}) => {
    const theme = useTheme();
    return (
        <Paper
            sx={{
                padding: theme.spacing(4),
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column'
            }}
        >
            {children}
        </Paper>
    );
};
