import { useEffect, useState } from "react";
import { debounce } from "@mui/material";

export const useObserveWindowResize = (callback) => {
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
      if (callback) {
        callback();
      }
    }, 200);
    window.addEventListener("resize", debouncedHandleResize);
    return () => {
      window.removeEventListener("resize", debouncedHandleResize, true);
    };
  });

  return dimensions;
};
