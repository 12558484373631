import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const DeleteFolderDialog = ({ t, openDialog, callback }) => {
  const handleButtonClick = (value) => {
    callback(value);
  };

  return (
    <Dialog open={openDialog}>
      <DialogTitle>
        {t("confirmFolderDeletionHeading", "Slette folder")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            "confirmFolderDeletionMessate",
            "Er du sikker på at du ønsker å slette merkede foldere?"
          )}
        </DialogContentText>
        <DialogActions>
          <Button onClick={() => handleButtonClick(false)}>
            {t("btnCancel", "Avbryt")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleButtonClick(true)}
          >
            {t("btnOk", "Ok")}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
