import {kulturnavFetch} from "../app/kulturnavFetch";
import {useEffect, useState} from "react";

const initialPlaces = ["Befolket sted", "By", "Bydel", "Forlatt sted", "Fylke", "Gate", "Historisk land/stat", "Kommune", "Land", "Navngitt sted", "Verdensdel"];

export const useKulturNavPlaceTypes = ({locale="no"}) => {
    const [placeTypes, setPlaceTypes] = useState(initialPlaces)

    useEffect(() => {
        kulturnavFetch('/36aa1e28-064b-436e-8efe-b5a2dc36c8c5')
            .then(json => setPlaceTypes(json.map(type => type.caption[locale])))
    }, [locale])

    return placeTypes;
}