import {Dialog, DialogActions} from "@mui/material";
import {FullscreenViewer} from "../../image/FullscreenViewer";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import {
    EXIT_FULLSCREEN,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState
} from "../../search-result-details/SearchResultDetailsContext";

/**
 * Displays an image in a full screen dialog on small screens.
 * @param smallScreen
 * @returns {JSX.Element}
 * @constructor
 */
export const SmallscreenDialogFullscreenViewer = ({smallScreen}) => {
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();

    const {model, fullscreen} = useSearchResultDetailsState();

    const handleCloseFullscreenDialog = () => {
        searchResultDetailsDispatch({type: EXIT_FULLSCREEN});
    };

    return (
        <Dialog open={fullscreen && smallScreen} fullScreen={true}>
            <FullscreenViewer model={model} disableFullScreen={true}/>
            <DialogActions
                sx={{
                    backgroundColor: "black",
                    color: "#ddd",
                    borderTop: "solid 1px #111",
                }}
            >
                <Typography variant={"body1"} ml={1}>
                    {model.title} (
                    {parseInt(
                        model.content.mediae[0].reference.fileSize
                    ).formatBytes()}
                    )
                </Typography>
                <Box flex flexGrow={1}/>
                <IconButton onClick={handleCloseFullscreenDialog}>
                    <CloseIcon sx={{color: "#ddd"}}/>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};