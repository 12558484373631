import enLocale from "date-fns/locale/en-US";
import nbLocale from "date-fns/locale/nb";
import nnLocale from "date-fns/locale/nn";
import svLocale from "date-fns/locale/sv";

import {add, format as dateFnsFormat, isPast} from "date-fns";
import {getBrowserLocale} from "../utility";

export const getLocale = () => {
    const selectedLanguage = getBrowserLocale(true);

    if ("sv" === selectedLanguage) {
        return svLocale;
    } else if ("no" === selectedLanguage || "nb" === selectedLanguage) {
        return nbLocale;
    } else if ("nn" === selectedLanguage) {
        return nnLocale;
    } else {
        return enLocale;
    }
};

export const format = (date, formatStr) => {
    if (Array.isArray(date)) {
        return '';
    }
    if (!date) {
        return date;
    }
    if (date instanceof Date) {
        return dateFnsFormat(date, formatStr, {locale: getLocale()});
    } else {
        const yearOnlyMask = /^(\d{4}-01-01)T00:00:00.000Z$/gi;
        const m = date.match(yearOnlyMask);
        if (m) {
            // Year only
            return date.match(/^(\d{4})/gi)[0];
        } else {
            return dateFnsFormat(new Date(date), formatStr, {locale: getLocale()});
        }
    }
};

export const getDateInOneHour = () => {
    return add(new Date(), {minutes: 59});
};

export const getDateInSevenDays = () => {
    return add(new Date(), {days: 7});
};

export const isDateInPast = (date) => isPast(date);
