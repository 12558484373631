/**
 * Adds filter query parameters to a search parameter object.
 *
 * @param {Object} fq - An object containing filter query parameters.
 * @param {URLSearchParams} searchParams - The search parameter object to append the filter query parameters to.
 * @return {void}
 */
export const addFqParams = (fq, searchParams) => {
    if (Object.keys(fq).length) {
        const fqGrouped = fq.reduce((acc, object) => {
            if (acc[object.key]) {
                acc[object.key].push(object.value);
            } else {
                acc[object.key] = [object.value];
            }
            return acc;
        }, {});

        const fqParam = Object.keys(fqGrouped)
            .map(
                (key) =>
                    `${key}:(${fqGrouped[key].map(encodeURIComponent).join(" OR ")})`
            )
            .join(",");
        searchParams.append("fq", fqParam);
    }
};