import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import {MetadataAccordions} from "./MetadataAccordions";
import {Scroller} from "../app/Scroller";
import {ExifFileData} from "./ExifFileData";
import {styled} from "@mui/material/styles";
import {RelatedObjectsList} from "./RelatedObjectsList";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`metadata-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `metadata-tabpanel-${index}`,
    };
}

/**
 * Styled tab, used to set custom background-color to each tab.
 */
const MetadataTab = styled((props) => <Tab disableRipple {...props} />)(
    ({theme}) => ({
        textTransform: "uppercase",
        backgroundColor: "#ddd",
        "&.Mui-selected": {
            color: theme.palette.primary.main,
            backgroundColor: "white",
        },
    })
);

/**
 * Component holding the contents of the tabs available above the metadata summary.
 * @param parentRef ref A reference to the parent DOM-object.
 * @param t object  Translation object
 * @param smallScreen bool
 * @param isProjectObject bool True if the current object belongs to an archeology project.
 * @returns {JSX.Element}
 * @constructor
 */
export const MetadataTabs = ({t, panelHeight, smallScreen, isProjectObject}) => {
    const {model} = useSearchResultDetailsState();
    const [value, setValue] = useState(0);

    const bottomActionButtonsHeight = 70;

    /**
     * Handles tab change
     * @param _event
     * @param newValue
     */
    const handleChange = (_event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Tabs
                value={value}
                onChange={handleChange}
                aria-label="metadata tabs"
                variant={"fullWidth"}
                scrollButtons="auto"
                sx={{
                    backgroundColor: "#ddd",
                }}
            >
                <MetadataTab
                    label={t("metadataTabInfo", "Informasjon")}
                    {...a11yProps(0)}
                />
                <MetadataTab
                    label={t("metadataTabRelated", "Relatert")}
                    {...a11yProps(1)}
                />
                <MetadataTab
                    label={t("metadataTabExit", "EXIF-data")}
                    {...a11yProps(2)}
                />
            </Tabs>
            <TabPanel value={value} index={0}>
                <Scroller
                    initialHeight={panelHeight + "px"}
                    subtractFromHeight={bottomActionButtonsHeight}
                >
                    <Box>
                        <MetadataAccordions smallScreen={smallScreen}
                                            isProjectObject={isProjectObject}
                                            t={t}/>
                    </Box>
                </Scroller>
            </TabPanel>
            <TabPanel value={value} index={1}>
                {panelHeight && (
                    <Scroller
                        initialHeight={panelHeight + "px"}
                        subtractFromHeight={bottomActionButtonsHeight}
                    >
                        <Box sx={{padding: 3}}>
                            <RelatedObjectsList t={t}/>
                        </Box>
                    </Scroller>
                )}
            </TabPanel>
            <TabPanel value={value} index={2}>
                {panelHeight && (
                    <Scroller
                        initialHeight={panelHeight + "px"}
                        subtractFromHeight={bottomActionButtonsHeight}
                    >
                        <Box sx={{padding: 3}}>
                            <ExifFileData load={true} model={model}/>
                        </Box>
                    </Scroller>
                )}
            </TabPanel>
        </>
    );
};
