import {useDocumentTranslation} from "../documents/documentContext";

export const useUppyTranslations = () => {

    const t = useDocumentTranslation();

    const dashboard = {
        // When `inline: false`, used as the screen reader label for the button that closes the modal.
        closeModal: t('uppyCloseDialog', 'Lukk dialog'),
        // Used as the screen reader label for the plus (+) button that shows the “Add more files” screen
        addMoreFiles: t('uppyAddMoreFiles', 'Legg til flere filer'),
        addingMoreFiles: t('uppyAddingMoreFiles', 'Legger til flere filer'),
        // Used as the header for import panels, e.g., “Import from Google Drive”.
        importFrom: t('uppyImportingFrom', 'Importer fra %{name}'),
        // When `inline: false`, used as the screen reader label for the dashboard modal.
        dashboardWindowTitle: t('uppyDashboardWindow', 'Uppy Dashboard Vindu (Trykk ESC for å lukke)'),
        // When `inline: true`, used as the screen reader label for the dashboard area.
        dashboardTitle: t('uppyDashboard', 'Uppy Dashboard'),
        // Shown in the Informer when a link to a file was copied to the clipboard.
        copyLinkToClipboardSuccess: t('uppyCopiedToClipboard', 'Link kopiert til utklippstavlen.'),
        // Used when a link cannot be copied automatically — the user has to select the text from the
        // input element below this string.
        copyLinkToClipboardFallback: t('uppyCopyUrlBelow', 'Kopier URLen nedenfor'),
        // Used as the hover title and screen reader label for buttons that copy a file link.
        copyLink: t('uppyCopyLink', 'Kopier link'),
        back: t('uppyBack', 'Tilbake'),
        // Used as the screen reader label for buttons that remove a file.
        removeFile: t('uppyRemoveFile', 'Fjern fil'),
        // Used as the screen reader label for buttons that open the metadata editor panel for a file.
        editFile: t('uppyEditFile', 'Rediger fil'),
        // Shown in the panel header for the metadata editor. Rendered as “Editing image.png”.
        editing: t('uppyEditingFile', 'Redigerer %{file}'),
        // Used as the screen reader label for the button that saves metadata edits and returns to the
        // file list view.
        finishEditingFile: t('uppyEndEditing', 'Avslutt redigering'),
        saveChanges: t('uppySaveChanges', 'Lagre endringer'),
        // Used as the label for the tab button that opens the system file selection dialog.
        myDevice: t('uppyMyDevice', 'Min enhet'),
        dropHint: t('uppyDropFilesHere', 'Slipp filene her'),
        // Used as the hover text and screen reader label for file progress indicators when
        // they have been fully uploaded.
        uploadComplete: t('uppyUploadComplete', 'Opplasting fullført'),
        uploadPaused: t('uppyUploadPaused', 'Opplasting pauset'),
        // Used as the hover text and screen reader label for the buttons to resume paused uploads.
        resumeUpload: t('uppyContinueUpload', 'Fortsett opplasting'),
        // Used as the hover text and screen reader label for the buttons to pause uploads.
        pauseUpload: t('uppyPauseUpload', 'Pause opplasting'),
        // Used as the hover text and screen reader label for the buttons to retry failed uploads.
        retryUpload: t('uppyTryAgain', 'Prøv igjen'),
        // Used as the hover text and screen reader label for the buttons to cancel uploads.
        cancelUpload: t('uppyCancelUpload', 'Avbryt opplasting'),
        // Used in a title, how many files are currently selected
        xFilesSelected: {
            0: t('uppyFileChosen', '%{smart_count} fil valgt'),
            1: t('uppyFilesChosen', '%{smart_count} filer valgt'),
        },
        uploadingXFiles: {
            0: t('uppyUploadingFile', 'Laster opp %{smart_count} fil'),
            1: t('uppyUploadingFiles', 'Laster opp %{smart_count} filer'),
        },
        processingXFiles: {
            0: t('uppyProcessingFile', 'Prosesserer %{smart_count} fil'),
            1: t('uppyProcessingFiles', 'Prosesserer %{smart_count} filer'),
        },
        // The "powered by Uppy" link at the bottom of the Dashboard.
        poweredBy: t('uppyPowered', 'Powered by %{uppy}'),
        addMore: t('uppyAdd', 'Legg til'),
        editFileWithFilename: t('uppyEditFile', 'Rediger fil %{file}'),
        save: t('uppySave', 'Lagre'),
        cancel: t('uppyCancel', 'Avbryt'),
        dropPasteFiles: t('uppyDropFilesHereOrBrowse', 'Slipp filer her, eller %{browseFiles}'),
        dropPasteFolders: t('uppyDropFilesHereOrBrowseFolder', 'Slipp filer her, eller %{browseFolders}'),
        dropPasteBoth: t('uppyDropFilesHereBrowseFilesOrFolder', 'Slipp filer her, %{browseFiles} eller %{browseFolders}'),
        dropPasteImportFiles: t('uppyDropFilesHereOrImportFrom', 'Slipp filer her, %{browseFiles} eller importer fra:'),
        dropPasteImportFolders: t('uppyDropFolderFilesHereOrImportFrom', 'Slipp filer her, %{browseFolders} eller importer fra:'),
        dropPasteImportBoth: t('uppyDropFilesHereBrowseFoldersOrImportFrom',
            'Slipp filer her,, %{browseFiles}, %{browseFolders} eller importer fra:'),
        importFiles: t('uppyImportFilesFrom', 'Importer filer fra:'),
        browseFiles: t('uppyBrowseFiles', 'bla i filer'),
        browseFolders: t('uppyBrowseFolders', 'bla i foldere'),
        recoveredXFiles: {
            0: t('uppyCouldNotRecreateFile', 'Vi klarte ikke å gjenopprette 1 fil. Velg den igjen, og fortsett opplastingen.'),
            1: t('uppyCouldNotRecreateFiles', 'Vi klarte ikke å gjenopprette %{smart_count} filer. Velg dem igjen, og fortsett opplastingen.'),
        },
        recoveredAllFiles: t('uppyRecreatingAllFiles', 'Vi gjenopprettet alle filene. Du kan nå fortsette opplastingen.'),
        sessionRestored: t('uppySessionRecreated', 'Sesjon gjenopprettet'),
        reSelect: t('uppyChooseAgain', 'velg på nytt'),
        missingRequiredMetaFields: {
            0: t('uppyMissingMetaField', 'Manglende meta-felt: %{fields}.'),
            1: t('uppyMissingMetaFields', 'Manglende meta-felt: %{fields}.'),
        },
    };

    const dragNDrop = {
        // Text to show on the droppable area.
        // `%{browse}` is replaced with a link that opens the system file selection dialog.
        dropHereOr: t('uppyDropHereOr', 'Slipp her, eller %{browse}'),
        // Used as the label for the link that opens the system file selection dialog.
        browse: t('uppyBrowse', 'bla'),
    };

    const imageEditor = {
        revert: t('uppyUndo', 'Angre'),
        rotate: t('uppyRotate', 'Roter'),
        zoomIn: t('uppyZoomIn', 'Zoom inn'),
        zoomOut: t('uppyZoomOut', 'Zoom ut'),
        flipHorizontal: t('uppyFlipHorizontally', 'Flip horisontalt'),
        aspectRatioSquare: t('uppyCropQuadrant', 'Kutt kvadratisk'),
        aspectRatioLandscape: t('uppyCropLandscape', 'Kutt landskap (16:9)'),
        aspectRatioPortrait: t('uppyCropPortrait', 'Kutt portrett (9:16)'),
    };

    const thumbnailGenerator = {
        generatingThumbnails: t('uppyCreatingThumbnails', 'Genererer thumbnails...'),
    };

    const statusBar = {
        // Shown in the status bar while files are being uploaded.
        uploading: t('uppyUploading', 'Laster opp'),
        // Shown in the status bar once all files have been uploaded.
        complete: t('uppyCompleted', 'Fullført'),
        // Shown in the status bar if an upload failed.
        uploadFailed: t('uppUploadFailed', 'Opplasting feilet'),
        // Shown in the status bar while the upload is paused.
        paused: t('uppyPaused', 'Pauset'),
        // Used as the label for the button that retries an upload.
        retry: t('uppyRetry', 'Prøv på nytt'),
        // Used as the label for the button that cancels an upload.
        cancel: t('uppyCancel', 'Avbryt'),
        // Used as the label for the button that pauses an upload.
        pause: t('uppyPause', 'Pause'),
        // Used as the label for the button that resumes an upload.
        resume: t('uppyRetry', 'Gjenoppta'),
        // Used as the label for the button that resets the upload state after an upload
        done: t('uppCompleted', 'Fullført'),
        // When `showProgressDetails` is set, shows the number of files that have been fully uploaded so far.
        filesUploadedOfTotal: {
            0: t('uppyPercentFileUploaded', '%{complete} av %{smart_count} fil lastet opp'),
            1: t('uppyPercentFilesUploaded', '%{complete} av %{smart_count} filer lastet opp'),
        },
        // When `showProgressDetails` is set, shows the amount of bytes that have been uploaded so far.
        dataUploadedOfTotal: t('uppyPercentOfTotal', '%{complete} av %{total}'),
        // When `showProgressDetails` is set, shows an estimation of how long the upload will take to complete.
        xTimeLeft: t('uppyRemainingTime', '%{time} gjenstående'),
        // Used as the label for the button that starts an upload.
        uploadXFiles: {
            0: t('uppyUploadPercentFile', 'Last opp %{smart_count} fil'),
            1: t('uppyUploadPercentFiles', 'Last opp %{smart_count} filer'),
        },
        // Used as the label for the button that starts an upload, if another upload has been started in the past
        // and new files were added later.
        uploadXNewFiles: {
            0: t('uppyUploadAddCountFile', 'Last opp +%{smart_count} fil'),
            1: t('uppyUploadAddCountFiles', 'Last opp +%{smart_count} filer'),
        },
        upload: 'Last opp',
        retryUpload: 'Prøv igjen',
        xMoreFilesAdded: {
            0: t('uppyAddedFile', '%{smart_count} fil lagt til'),
            1: t('uppyAddedFiles', '%{smart_count} filer lagt til'),
        },
        showErrorDetails: t('uppyShowError', 'Vis feilmelding'),
    };

    const translatedStrings = {...dashboard, ...dragNDrop, ...imageEditor, ...thumbnailGenerator, ...statusBar};

    return {strings: translatedStrings};
};