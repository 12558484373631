import React from "react";

export const USER_LOGGED_IN = "authsContext/loggedIn";
export const USER_LOGGED_OUT = "authsContext/loggedOut";
export const SET_USER_DATA = "authsContext/setUserData";
export const SET_MUSEUM_COLLECTIONS = "authsContext/setMuseumCollections";
export const SET_DAMS_ID = "authsContext/setDamsId";
export const SET_MUSEUM_FEATURES = 'authsContext/setMuseumFeatures';

const AuthsStateContext = React.createContext(undefined);
const AuthsDispatchContext = React.createContext(undefined);

const initialState = {
    userIsAuthenticated: false,
    userData: {},
    museumCollections: [],
    damsId: 0,
    museumFeatures: []
};

const authsReducer = (state, action) => {
    switch (action.type) {
        case USER_LOGGED_IN:
            return {
                ...state,
                userIsAuthenticated: true,
            };
        case USER_LOGGED_OUT:
            return {
                ...state,
                userIsAuthenticated: false,
                userData: initialState.userData,
            };
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.userData,
            };
        case SET_MUSEUM_COLLECTIONS:
            return {
                ...state,
                museumCollections: action.museumCollections,
            };
        case SET_DAMS_ID:
            return {
                ...state,
                damsId: action.damsId,
            };
        case SET_MUSEUM_FEATURES:
            return {
                ...state,
                museumFeatures: action.museumFeatures
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const AuthsProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(
        authsReducer,
        {...initialState},
        undefined
    );

    return (
        <AuthsStateContext.Provider value={state}>
            <AuthsDispatchContext.Provider value={dispatch}>
                {children}
            </AuthsDispatchContext.Provider>
        </AuthsStateContext.Provider>
    );
};

export const useAuthsState = () => {
    const context = React.useContext(AuthsStateContext);
    if (undefined === context) {
        throw new Error("useSearchResultState must be used within a AuthsProvider");
    } else {
        return context;
    }
};

export const useAuthsDispatch = () => {
    const context = React.useContext(AuthsDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useSearchResultDetailsDispatch must be used within a AuthsProvider"
        );
    } else {
        return context;
    }
};
