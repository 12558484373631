import {PromisePool} from "@supercharge/promise-pool";
import {damsFetch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {clientLog} from "../../clientLog";

export const saveNewTags = async (collectionId, tags, tagType = 'foldertag') => {

    const postDocumentsInChunks = async (documents) => {
        return await PromisePool
            .withConcurrency(2)
            .for(documents)
            .process(async (document) => {
                try {
                    const res = await damsFetch('/documents', {
                        method: 'POST',
                        body: JSON.stringify(decamelizeKeysDeep(document))
                    });

                    const {id, uniqueId, locale, title, source, content} = res;
                    return {
                        reference: {
                            id: id,
                            uniqueId: uniqueId,
                            locale: locale,
                            _action: 'put',
                            title: title,
                            source: source,
                            sourceId: null,
                            content: content
                        }
                    };
                } catch (e) {
                    clientLog('error', e, 'savenewtags');
                }
            });
    };

    function createDamsDocumentObject(u) {
        const {title} = u;
        return {
            collectionId: collectionId,
            schemaId: 1,
            locale: "no",
            _action: "put",
            title: title,
            status: "published",
            source: 'dams',
            sourceId: null,
            documentType: tagType,
            content: {
                name: title,
                title: title
            }
        };
    }

    const createDamsDocuments = (unsaved) => {
        return unsaved.map(u => {
            return createDamsDocumentObject(u);
        });
    };

    if (!collectionId || !tags || tags?.length === 0) {
        return;
    }

    // Get the list of tags that does not exist in the DAMS database.
    // NOTE: For new tags, their actual title is stored under the property "inputValue".
    const newTags = [...tags].filter(t => {
        return !t.uniqueId;
    }).map(t => ({
        title: t.inputValue
    }));

    const documents = createDamsDocuments(newTags);
    return await postDocumentsInChunks(documents);
};