import React, {createContext, useContext} from 'react';
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

const MenuTranslationContext = createContext(undefined);

export const MenuTranslationProvider = ({children}) => {
    const {t, ready} = useTranslation('dams.menu', {useSuspense: false})

    if (ready) {
        return (
            <MenuTranslationContext.Provider value={t}>
                {children}
            </MenuTranslationContext.Provider>
        )
    } else if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>
    }
}

export const useMenuTranslation = () => {
    const context = useContext(MenuTranslationContext);
    if (undefined === context) {
        throw new Error('useMenuTranslation must be used within an MenuTranslationProvider.');
    }
    return context;
}