import { useMyMuseums } from "./useMyMuseums";
import { useAuthsState } from "../auths/authsContext";

export const useMuseumNameFromCollectionId = () => {
  const myMuseums = useMyMuseums({});
  const { museumCollections } = useAuthsState();

  const getMuseumName = (collectionId) => {
    if (!collectionId) {
      return "";
    }

    const museumId = museumCollections.find((mc) => {
      return mc.collectionId === collectionId;
    })?.museumId;
    return myMuseums?.find((m) => m.id === museumId)?.name;
  };

  return [getMuseumName];
};
