import React, { createContext, useContext, useReducer } from "react";
export const FILE_DOWNLOAD_PROGRESS =
  "downloadMultipleFilesContext/fileDownloadedProgress";
export const FILE_DOWNLOAD_RESET_PROGRESS =
  "downloadMultipleFilesContext/resetProgress";
export const FILE_PREPARATION_PROGRESS =
  "downloadMultipleFilesContext/filePreparationProgress";
export const FILE_RESET_PREPARATION_PROGRESS =
  "downloadMultipleFilesContext/fileResetPreparationProgress";

const DownloadMultipleFilesStateContext = createContext(undefined);
const DownloadMultipleFilesDispatchContext = createContext(undefined);

const initialState = {
  progress: 0,
  preparationProgress: 0,
};

const downloadMultipleFilesReducer = (state, action) => {
  switch (action.type) {
    case FILE_PREPARATION_PROGRESS:
      let currentPrepProgress = state.preparationProgress + 1;
      return {
        ...state,
        preparationProgress: currentPrepProgress,
      };
    case FILE_DOWNLOAD_RESET_PROGRESS:
      return {
        ...state,
        progress: 0,
      };
    case FILE_DOWNLOAD_PROGRESS:
      let currentProgress = state.progress + 1;
      return {
        ...state,
        progress: currentProgress,
      };
    case FILE_RESET_PREPARATION_PROGRESS:
      return {
        ...state,
        preparationProgress: 0,
      };
    default:
      throw new Error(`Unhandled actionType: ${action.type}`);
  }
};

export const DownloadMultipleFilesProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    downloadMultipleFilesReducer,
    initialState,
    undefined
  );

  return (
    <DownloadMultipleFilesStateContext.Provider value={state}>
      <DownloadMultipleFilesDispatchContext.Provider value={dispatch}>
        {children}
      </DownloadMultipleFilesDispatchContext.Provider>
    </DownloadMultipleFilesStateContext.Provider>
  );
};

export const useDownloadMultipleFilesState = () => {
  const context = useContext(DownloadMultipleFilesStateContext);
  if (undefined === context) {
    throw new Error(
      "downloadMultipleFilesState must be used within a DownloadMultipleFilesProvider."
    );
  } else {
    return context;
  }
};

export const useDownloadMultipleFilesDispatch = () => {
  const context = useContext(DownloadMultipleFilesDispatchContext);
  if (undefined === context) {
    throw new Error(
      "useDownloadMultipleFilesDispatch must be used within a DownloadMultipleFilesProvider."
    );
  } else {
    return context;
  }
};
