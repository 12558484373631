import React, {useEffect} from "react";
import {useFormikContext} from "formik";
import LoadingButton from "@mui/lab/LoadingButton";
import AssignmentIcon from "@mui/icons-material/Assignment";
import {clientLog} from "../clientLog";

/**
 * Renders a FormikSubmitButton component.
 *
 * @param {Object} props - The properties for the component.
 * @param {boolean} props.disabled - Whether the button is disabled.
 * @param {ReactNode} props.children - The content of the button.
 * @param {...any} props.props - Additional properties to be spread on the button.
 * @return {JSX.Element} The rendered FormikSubmitButton component.
 */
export const FormikSubmitButton = ({disabled, children, ...props}) => {
    const {submitForm, isSubmitting, isValid, errors} = useFormikContext();

    /**
     * Hook used to expose the errors, when a form has invalid values.
     */
    useEffect(() => {
        if (!errors) {
            return;
        }
        clientLog('error', JSON.stringify(errors), 'formiksubmitbutton');
    }, [errors]);

    return (
        <LoadingButton
            sx={{marginBottom: 2, marginTop: 2, marginLeft: 1}}
            variant={"contained"}
            color={"primary"}
            onClick={submitForm}
            loadingPosition="start"
            startIcon={<AssignmentIcon/>}
            loading={isSubmitting}
            disabled={isSubmitting || !isValid || disabled}
            {...props}
        >
            {children}
        </LoadingButton>
    );
};
