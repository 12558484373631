/**
 * Returns the base config. object used when addressing the web worker.
 */
export const getWorkerConfigBase = (apiGateway) => {
    return Object.freeze({
        token: localStorage.getItem('kit.access_token'),
        idToken: localStorage.getItem('kit.id_token'),
        xQuoteMode: window._env_.REACT_APP_X_QUOTE_MODE,
        damsUrl: `${apiGateway}`,
    });
};