import React, {useEffect, useState} from "react";
import {FormikTextField} from "../form/FormikTextField";
import {InputProducer} from "../person/InputProducer";
import {InputPersons} from "../person/InputPersons";
import {InputPlaces} from "../place/InputPlaces";
import {InputSubjects} from "../subject/InputSubjects";
import {useFormikContext} from "formik";
import {InputLanguages} from "../language/InputLanguages";
import {FormikProductionDate} from "../form/FormikProductionDate";
import {InputDocumentationType} from "../project/archeology/InputDocumentationType";
import {FormikWGS84Field} from "../form/FormikWGS84Field";
import {useFileUploadState, useFileUploadTranslation} from "./fileUploadContext";
import Box from "@mui/material/Box";

export const StepEditMetadataProjectForm = () => {
    const t = useFileUploadTranslation();

    const {resetForm} = useFormikContext();

    const {collectionId, formData, selected} = useFileUploadState();

    const [disabledForm, setDisabledForm] = useState(true);

    useEffect(() => {
        const disabled = selected.length === 0;
        if (!disabled) {
            resetForm();
        }
        setDisabledForm(disabled);
    }, [selected, resetForm]);

    return (
        <Box sx={{
            overflowY: 'auto',
            padding: '16px'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                "& .MuiFormControl-root": {
                    marginTop: 0, marginBottom: '16px'
                }
            }}>
                <InputDocumentationType
                    formikKey={"documentationType"}
                    label={t('documentationType', 'Dokumentasjonstype')}
                    disabled={disabledForm}
                    fullWidth={true}
                    size={"small"}
                    collectionId={collectionId}
                />

                <FormikTextField
                    formikKey={"description"}
                    label={t("description", "Beskrivelse")}
                    helperText={t("descriptionHelp", "Omhandler motivet/opptaket")}
                    disabled={disabledForm}
                    fullWidth={true}
                    size={"small"}
                />

                <FormikTextField
                    formikKey={"customIdentifier"}
                    label={t("customIdentifier", "Museumsnummer")}
                    helperText={t(
                        "customIdentifierHelp",
                        "Unikt identifikasjonsnummer i samlingen"
                    )}
                    disabled={disabledForm}
                    fullWidth={true}
                    size={"small"}
                />

                <FormikProductionDate
                    formikKey={"productionDate"}
                    label={t("productionDate", "Produksjonsdato")}
                    disabled={disabledForm}
                    fullWidth={true}
                    size={"small"}
                    t={t}
                />

                <InputProducer
                    formikKey={"producer"}
                    disabled={disabledForm}
                    fullWidth={true}
                    label={t("producer", {context: formData.documentType})}
                    helperText={t("producerHelpTxt", "Søk, eller legg til ny")}
                    createLabel={t("addNewPersonHeader", "Legg til ny person")}
                    size={"small"}
                    projectCollectionId={collectionId}
                />

                <InputPersons
                    formikKey={"persons"}
                    disabled={disabledForm}
                    fullWidth={true}
                    label={t("persons", "Personer")}
                    createLabel={t("addNewPersonHeader", "Legg til ny person")}
                    helperText={t("personsHelp", "Som omtales/er avbildet")}
                    size={"small"}
                    projectCollectionId={collectionId}
                />

                <InputPlaces
                    formikKey={"places"}
                    disabled={disabledForm}
                    fullWidth={true}
                    label={t("places", "Steder")}
                    createLabel={t("addNewPlaceHeader", "Legg til nytt sted")}
                    size={"small"}
                    projectCollectionId={collectionId}
                />

                <FormikWGS84Field
                    formikKey={"coordinates"}
                    label={"Koordinater (WGS84)"}
                    disabled={disabledForm}
                    fullWidth={true}
                    helperText={"WGS84 - lat,lng"}
                    size={"small"}
                />

                <InputSubjects
                    t={t}
                    formikKey={"subjects"}
                    label={t("subjects", "Emneord")}
                    disabled={disabledForm}
                    fullWidth={true}
                    size={"small"}
                    projectCollectionId={collectionId}
                />

                <InputLanguages
                    formikKey={"languages"}
                    label={t("languages", "Språk")}
                    helperText={t("languagesHelp", "Som brukes i opptaket/teksten")}
                    disabled={disabledForm}
                    fullWidth={true}
                    size={"small"}
                />

                <FormikTextField
                    formikKey={"remarks"}
                    label={t("remarks", "Kommentar")}
                    helperText={t("remarksHelp", "Ytterligere informasjon")}
                    disabled={disabledForm}
                    fullWidth={true}
                    size={"small"}
                />
            </Box>
        </Box>
    );
};
