import React from "react";
import {styled} from "@mui/material/styles";
import {ProjectContentsSearchResults} from "../../ProjectContentsSearchResults";

import '../../projectview.css'
import {ProjectViewBase} from "../../ProjectViewBase";
import {ProjectViewHeader} from "../../ProjectViewHeader";

const PREFIX = "ArcheologyProjectView";

const classes = {
    museumSelector: `${PREFIX}-museumSelector`,
    searchAndType: `${PREFIX}-searchAndType`,
};

const Root = styled("div")(({theme}) => ({

    [`& .${classes.museumSelector}`]: {
        display: "flex",
        alignItems: "center",
    },

    [`& .${classes.searchAndType}`]: {
        [theme.breakpoints.up("xs")]: {
            flexGrow: 1,
        },
        [theme.breakpoints.up("md")]: {
            maxWidth: '60%'
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: '50%'
        },
        [theme.breakpoints.up("xl")]: {
            maxWidth: '38rem'
        },
    },
}));

/**
 * Archeology project view component.
 *
 * @return {JSX.Element} The project view base component
 */
export const ArcheologyProjectView = () => {
    return <ProjectViewBase Root={Root} classes={classes}>
        <ProjectViewHeader/>
        <ProjectContentsSearchResults/>
    </ProjectViewBase>;
};
