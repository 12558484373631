import React from "react";
import {styled} from "@mui/material/styles";
import {useFormikContext} from "formik";
import {useFolderTranslation} from "../MyFoldersContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";

const PREFIX = "ToolbarFolderAdmin";

const classes = {
    saveButton: `${PREFIX}-saveButton`,
};

const StyledToolbar = styled(Toolbar)(({theme}) => ({
    [`& .${classes.saveButton}`]: {
        marginLeft: theme.spacing(2),
    },
}));

/**
 * Renders a toolbar for the folder admin with a cancel button and a save button.
 *
 * @param {Object} props - The props object.
 * @param {Function} props.onHide - The function to be called when the cancel button is clicked.
 * @param {boolean} [props.disabled=false] - Whether the save button should be disabled.
 * @return {JSX.Element} The toolbar component.
 */
export const ToolbarFolderAdmin = ({onHide, disabled = false}) => {
    const t = useFolderTranslation();

    const {submitForm, isSubmitting, isValid, dirty} = useFormikContext();

    return (
        <StyledToolbar>
            <Box flexGrow={1}/>
            <Button onClick={onHide}>{t("cancel", "Avbryt")}</Button>
            <Button
                variant={"contained"}
                color={"secondary"}
                className={classes.saveButton}
                onClick={submitForm}
                disabled={isSubmitting || !isValid || disabled || !dirty}
            >
                {t("save", "Lagre")}
            </Button>
        </StyledToolbar>
    );
};
