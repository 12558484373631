import React, {useEffect, useRef, useState} from "react";
import {styled} from "@mui/material/styles";
import {UploadedFiles} from "./UploadedFiles";
import {AddToFolderToolbar} from "./AddToFolderToolbar";
import {useDocumentState} from "../documents/documentContext";
import {Scroller} from "../app/Scroller";
import {useMarkFiles} from "./useMarkFiles";
import {ViewTypeOption} from "./ViewTypeOption";
import {AvailableFolders} from "./AvailableFolders";
import {DraggableUploadedFilesTable} from "./DraggableUploadedFilesTable";
import {MyFoldersGrid} from "../folder/MyFoldersGrid";
import {DraggableFilesGrid} from "./DraggableFilesGrid";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import {FoldersProvider} from "../folder/FoldersContext";

const PREFIX = "AddToFolderStep";

const classes = {
    addToFolderGrid: `${PREFIX}-addToFolderGrid`,
    addToFolderGridItem: `${PREFIX}-addToFolderGridItem`,
    break: `${PREFIX}-break`,
};

const StyledGrid = styled(Grid)(() => ({
    [`&.${classes.addToFolderGrid}`]: {
        height: "100%",
    },

    [`& .${classes.addToFolderGridItem}`]: {
        height: "100%",
    },

    [`& .${classes.break}`]: {
        flexBasis: "100%",
        height: 0,
    },
}));

export const AddToFolderStep = () => {
    const [toolbarHeight, setToolbarHeight] = useState(0);
    const toolbarRef = useRef();
    const {files} = useDocumentState();
    const [viewType, setViewType] = useState("table");
    const [checked, checkFile, checkAll, clearAll, numberOfChecked] =
        useMarkFiles(files);

    useEffect(() => {
        if (toolbarRef.current) {
            setToolbarHeight(toolbarRef.current['clientHeight']);
        }
    }, []);

    const getViewBySelectedType = () => {
        if ("gridlist" === viewType) {
            return <DraggableFilesGrid checked={checked} checkFile={checkFile}/>;
        } else {
            return (
                <DraggableUploadedFilesTable checked={checked} checkFile={checkFile}/>
            );
        }
    };

    return (
        <StyledGrid
            container
            spacing={1}
            className={classes.addToFolderGrid}
            marginTop={2}
        >
            <Grid size={{xs: 12, md: 6}} className={classes.addToFolderGridItem}>
                <UploadedFiles>
                    <Box ref={toolbarRef} sx={{display: "flex"}}>
                        <AddToFolderToolbar
                            files={files}
                            checkAll={checkAll}
                            clearAll={clearAll}
                            numberOfChecked={numberOfChecked}
                        >
                            <ViewTypeOption viewType={viewType} setViewType={setViewType}/>
                        </AddToFolderToolbar>
                    </Box>
                    <Scroller subtractFromHeight={toolbarHeight}>
                        <Box sx={{marginLeft: 2, marginRight: 2}}>
                            {getViewBySelectedType()}
                        </Box>
                    </Scroller>
                </UploadedFiles>
            </Grid>
            <Grid size={{xs: 12, md: 6}} className={classes.addToFolderGridItem}>
                <FoldersProvider>
                    <AvailableFolders>
                        <MyFoldersGrid/>
                    </AvailableFolders>
                </FoldersProvider>
            </Grid>
        </StyledGrid>
    );
};
