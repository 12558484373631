import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React from "react";
import {useMediaQuery} from "@mui/material";
import PropTypes from "prop-types";
import {getMimeTypeFromModel} from "../utility";


/**
 * Component used to display the document type of the uploaded file.
 * @param file
 * @param iconCallback
 * @param openEditCallback
 * @returns {JSX.Element}
 * @constructor
 */
const FileObjectType = ({
                            file,
                            iconCallback,
                            openEditCallback,
                        }) => {

    const smallScreen = useMediaQuery("(max-width: 1024px)");
    const mimeType = getMimeTypeFromModel(file);

    const getDocumentType = () => {
        if (mimeType && mimeType.indexOf('pdf') > -1) {
            return 'PDF';
        } else if (file.documentType === 'StillImage') {
            return 'Fotografi';
        } else if (file.documentType === 'Misc') {
            return 'Dokument';
        } else {
            return file.documentType;
        }
    };

    const getFileSize = () => {
        const size = file.content.mediae[0].reference.fileSize;
        return (size / 1024 / 1024).toFixed(2);
    };

    return (
        <Stack
            direction={"row"}
            ml={'8px'}
            mt={smallScreen ? '0px' : '16px'}
            justifyContent={smallScreen ? 'start' : "space-evenly"}
            alignItems={smallScreen ? 'start' : "center"}
        >
            {iconCallback(getDocumentType())}
            <Typography
                variant={"caption"}
                component={"p"}
                marginLeft={'8px'}
                color={"secondary"}
            >
                {getDocumentType()}
            </Typography>
            <Typography variant={"caption"} color={"secondary"} ml={'8px'}>
                ({getFileSize()} MB)
            </Typography>
        </Stack>
    );
};

FileObjectType.propTypes = {
    file: PropTypes.object.isRequired,
    iconCallback: PropTypes.func.isRequired,
    openEditCallback: PropTypes.func
};

export {FileObjectType};