import React from "react";
import {styled} from "@mui/material/styles";
import {useDocumentState,} from "../documents/documentContext";
import {tooltipClasses} from "@mui/material";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import InfoIcon from "@mui/icons-material/Info";
import {UploadedFileInfoTooltipContent} from "./UploadedFileInfoTooltipContent";
import {useDecideThumbnailType} from "./useDecideThumbnailType";
import {getCheckIcon, getIconOrThumbnail} from "./filesHelper";

import './uploadedfilegriditem.css';

const HtmlTooltip = styled(({className, ...props}) => (
    <Tooltip {...props} classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: "1px solid #dadde9",
    },
}));

export const UploadedFileGridItem = ({file, checked, onCheck}) => {
    const {saved, filesFailedMapping, editedMetadata} = useDocumentState();
    const {showUnknownThumb, showAudioThumb} = useDecideThumbnailType(file);

    /**
     * Returns true if the specified file exists in the "editedMetadata" array.
     * When metadata for an uploaded file has been edited, it is pushed to the "editedMetadata".
     * @param uuid
     * @returns {boolean}
     */
    const isEdited = uuid => {
        return Boolean(editedMetadata.find(f => f.uniqueId === uuid));
    }

    const getInfoTooltip = ({f}, triggerElement) => {
        let content = <UploadedFileInfoTooltipContent file={f}/>;
        return <HtmlTooltip title={content}>{triggerElement}</HtmlTooltip>;
    };

    const error = filesFailedMapping.includes(file.dmsId);
    const success = saved.find(
        (document) => {
            return document.content.mediae[0].reference.title === file.title && isEdited(document.uniqueId);
        }
    );

    /**
     * Sets the color of the checkbox- and info icons, based on the current file's "save-state".
     * @returns {string}
     */
    function getIconColor(success, error) {
        let c = "rgba(255, 255, 255, 0.6)";
        if (success) {
            c = "rgba(60, 179, 113, 0.8)";
        } else if (!success && error) {
            c = "rgba(255,0,0, 0.8)";
        }
        return c;
    }

    const getTopmostOverlay = (success, error) => {
        const iconColor = getIconColor(success, error);
        return (
            <Box className={'infoContainer'}>
                {getCheckIcon(checked, onCheck, iconColor)}
                {getInfoTooltip(
                    file,
                    <InfoIcon
                        className={'infoIcon'}
                        sx={{
                            right: 3,
                            color: iconColor,
                        }}
                    />
                )}
            </Box>
        );
    };

    return (
        <Box key={file.title}>
            {getIconOrThumbnail(file, showAudioThumb, showUnknownThumb, getTopmostOverlay(success, error))}
            <ImageListItemBar
                position={"bottom"}
                title={file.title}
                subtitle={file.file.name}
                sx={{
                    maxWidth: "160px",
                    "& .MuiImageListItemBar-titleWrap": {
                        background:
                            "linear-gradient(0deg, rgba(51,51,51,0) 0%, rgba(255,255,255, 0.1) 100%)",
                        padding: 1,
                    },
                    "& .MuiImageListItemBar-title": {
                        fontSize: "12px",
                    },
                    "& .MuiImageListItemBar-subtitle": {
                        fontSize: "9px",
                    },
                }}
            />
        </Box>
    );
};
