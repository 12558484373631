import React, {createContext, useContext} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

const MetaMappingTranslationContext = createContext(undefined);

export const MetaMappingProvider = ({children}) => {
    const {t, ready} = useTranslation("dams.metamapping", {
        useSuspense: false,
    });

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <MetaMappingTranslationContext.Provider value={t}>
            {children}
        </MetaMappingTranslationContext.Provider>
    );
};

export const useMetaMappingTranslation = () => {
    const context = useContext(MetaMappingTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useMetaMappingTranslation must be used within a MetaMappingProvider"
        );
    } else {
        return context;
    }
};
