import Typography from "@mui/material/Typography";
import {DetailsRow} from "./DetailsRow";
import React from "react";
import {useProjectViewState} from "../archeology/projectview/projectViewContext";

export const DetailsDialogStats = ({tp}) => {
    const {facets} = useProjectViewState();

    const getDocumentTypeCount = documentType => {
        if (!facets.documentTypes) return 0;
        if (!facets.documentTypes[documentType]) return 0;
        return facets.documentTypes[documentType].count;
    };

    return <>
        <Typography variant={"caption"}>{tp('lblFileTypes', 'Filtyper')}</Typography>
        <DetailsRow label={tp('lblImage', 'Bilde') + ':'} value={getDocumentTypeCount('StillImage')}/>
        <DetailsRow label={tp('lblModel', 'Modell') + ':'} value={getDocumentTypeCount('Modell')}/>
        <DetailsRow label={tp('lblMisc', 'Dokument') + ':'} value={getDocumentTypeCount('Misc')}/>
        <DetailsRow label={tp('lblGeodata', 'Geodata') + ':'} value={getDocumentTypeCount('Geodata')}/>
        <DetailsRow label={tp('lblTable', 'Tabell') + ':'} value={getDocumentTypeCount('Tabell')}/>
        <DetailsRow label={tp('lblAudio', 'Lyd') + ':'} value={getDocumentTypeCount('Audio')}/>
        <DetailsRow label={tp('lblVideo', 'Film') + ':'} value={getDocumentTypeCount('Film')}/>
    </>;
}