import React from "react";

export const SHOW_ADD_LICENSE_FIELD = 'copyrightContext/showAddLicenseField';
export const HIDE_ADD_LICENSE_FIELD = "copyrightContext/hideAddLicenseField";
export const SHOW_ADD_COPYRIGHT_CLAUSE_FIELD = 'copyrightContext/showAddCopyrightClauseField';
export const HIDE_ADD_COPYRIGHT_CLAUSE_FIELD = 'copyrightContext/hideAddCopyrightClauseField';
export const ADD_COPYRIGHT_CLAUSE = 'copyrightContext/addCopyrightClause;'
export const ADD_COPYRIGHT_CLAUSE_DATA = 'copyrightContext/addCopyrightClauseData';
export const SET_COPYRIGHT_INFO = 'copyrightContext/setCopyrightInfo';
export const CLEAR_ALL = 'copyrightContext/clearAll';

const CopyrightStateContext = React.createContext(undefined);
const CopyrightDispatchContext = React.createContext(undefined);

const initialState = {
    copyrightInfo: [],
    showAddLicenseField: false,
    showAddCopyrightClause: false,
};

const mergeResponsible = (existingInfo, valuesObj) => {
    existingInfo.responsible = [...valuesObj.responsible];
    return existingInfo;
};

const copyrightReducer = (state, action) => {
    switch (action.type) {
        case SHOW_ADD_LICENSE_FIELD:
            return {
                ...state,
                showAddLicenseField: true
            };
        case HIDE_ADD_LICENSE_FIELD:
            return {
                ...state,
                showAddLicenseField: false
            };
        case SHOW_ADD_COPYRIGHT_CLAUSE_FIELD:
            return {
                ...state,
                showAddCopyrightClause: true
            };
        case HIDE_ADD_COPYRIGHT_CLAUSE_FIELD:
            return {
                ...state,
                showAddCopyrightClause: false
            };
        case ADD_COPYRIGHT_CLAUSE:
            return {
                ...state
            };
        case ADD_COPYRIGHT_CLAUSE_DATA:
            let ix = !action.data.ix ? 0 : action.data.ix;
            const valuesObj = action.data.values;
            const allExistingInfo = state.copyrightInfo;  // Data already stored in the current state.
            let existingInfo = allExistingInfo[ix];     // Reference to data stored under the specific index.
            let copyrightsObj;                          // New object used to store the copyright-data.

            if (!existingInfo) {
                copyrightsObj = valuesObj;
            } else {
                if (Object.keys(valuesObj).includes('responsible')) {
                    copyrightsObj = mergeResponsible(existingInfo, valuesObj);
                } else {
                    copyrightsObj = {...existingInfo, ...valuesObj};
                }
            }

            existingInfo = copyrightsObj;               // Overwrite existing data with the new values.
            allExistingInfo[ix] = existingInfo;         // Put the new data back into the "state-array".

            return {
                ...state,
                copyrightInfo: allExistingInfo,
                showAddCopyrightClause: false
            };
        case SET_COPYRIGHT_INFO:
            return {
                ...state,
                copyrightInfo: action.data
            };
        case CLEAR_ALL:
            return {
                ...initialState,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const CopyrightProvider = ({children}) => {
    const [state, dispatch] = React.useReducer(
        copyrightReducer,
        {...initialState},
        undefined
    );

    return (
        <CopyrightStateContext.Provider value={state}>
            <CopyrightDispatchContext.Provider value={dispatch}>
                {children}
            </CopyrightDispatchContext.Provider>
        </CopyrightStateContext.Provider>
    );
};

export const useCopyrightState = () => {
    const context = React.useContext(CopyrightStateContext);
    if (undefined === context) {
        throw new Error("useCopyrightState must be used within a CopyrightProvider");
    } else {
        return context;
    }
};

export const useCopyrightDispatch = () => {
    const context = React.useContext(CopyrightDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useCopyrightDispatch must be used within a CopyrightProvider"
        );
    } else {
        return context;
    }
};
