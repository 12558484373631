import React, {createContext, useContext, useReducer} from "react";

const FolderActionStateContext = createContext(undefined);
const FolderActionDispatchContext = createContext(undefined);

export const OPEN = "folderActionContext/open";
export const CLOSE = "folderActionContext/close";

const initialState = {
    anchorEl: null,
};

const folderActionReducer = (state, action) => {
    switch (action.type) {
        case OPEN:
            return {
                ...state,
                anchorEl: action.anchorEl,
            };
        case CLOSE:
            return {
                ...state,
                anchorEl: null,
            };
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};

export const FolderActionProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        folderActionReducer,
        initialState,
        undefined
    );

    return (
        <FolderActionStateContext.Provider value={state}>
            <FolderActionDispatchContext.Provider value={dispatch}>
                {children}
            </FolderActionDispatchContext.Provider>
        </FolderActionStateContext.Provider>
    );
};

export const useFolderActionState = () => {
    const context = useContext(FolderActionStateContext);
    if (undefined === context) {
        throw new Error(
            "useFolderActionState must be used within a FolderActionProvider"
        );
    } else {
        return context;
    }
};

export const useFolderActionDispatch = () => {
    const context = useContext(FolderActionDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useFolderActionDispatch must be used within a FolderActionProvider"
        );
    } else {
        return context;
    }
};
