import React, {useEffect, useState} from "react";
import {UploadedFilesToolbar} from "../../files/UploadedFilesToolbar";
import {DamsForm} from "../../form/DamsForm";
import {FormMetadata} from "../FormMetadata";
import {UploadedFilesGrid} from "../../files/UploadedFilesGrid";
import {useDocumentState} from "../../documents/documentContext";
import {FormikSubmitButton} from "../../form/FormikSubmitButton";
import {useNewFilesTranslation} from "../../files/NewFilesTranslationContext";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InfoIcon from "@mui/icons-material/Info";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {Dialog, DialogActions, DialogContent, DialogTitle,} from "@mui/material";
import {AddMetadataInfoText} from "./AddMetadataInfoText";
import {NumberEditedInfo} from "./NumberEditedInfo";
import {getNumMissingTitle} from "./metadatagridFunctions";
import {BatchEditProvider} from "../../documents/batchEditContext";

/**
 * Renders the small screen metadata grid component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.toolbarRef - The reference to the toolbar.
 * @param {Function} props.clearAllCallback - The callback function to clear all.
 * @param {Function} props.checkAllCallback - The callback function to check all.
 * @param {Object} props.checked - The object representing the checked state.
 * @param {Function} props.checkFileCallback - The callback function to check a file.
 * @param {Function} props.saveDocumentsCallback - The callback function to save documents.
 * @param {Object} props.validationSchema - The validation schema for the form.
 * @param {Function} props.numberOfCheckedCallback - The callback function to get the number of checked items.
 * @return {JSX.Element} The rendered component.
 */
export const MetadataGridSmallScreen = ({
                                            toolbarRef,
                                            clearAllCallback,
                                            checkAllCallback,
                                            checked,
                                            checkFileCallback,
                                            saveDocumentsCallback,
                                            validationSchema,
                                            numberOfCheckedCallback,
                                        }) => {
    const t = useNewFilesTranslation();

    const {formData, files, saved} = useDocumentState();

    const [numMissingTitle, setNumMissingTitle] = useState(0);

    const [showAddMetadataInfo, setShowAddMetadataInfo] = useState(false);

    /**
     * Hook used to determine whether to show the add metadata info.
     */
    useEffect(() => {
        const countMissingTitle = getNumMissingTitle(files, saved);
        setNumMissingTitle(countMissingTitle);
        if (0 === numberOfCheckedCallback() && numMissingTitle === files.length) {
            setShowAddMetadataInfo(true);
        }
    }, [files, saved]);

    return (
        <DamsForm
            onSubmit={saveDocumentsCallback}
            initialValues={formData}
            validationSchema={validationSchema}
        >
            <Paper elevation={3} sx={{paddingBottom: 2}}>
                <Box ref={toolbarRef} sx={{paddingLeft: 2}}>
                    <UploadedFilesToolbar
                        checkAllCallback={checkAllCallback}
                        clearAllCallback={clearAllCallback}
                        numberOfCheckedCallback={numberOfCheckedCallback}
                    />
                </Box>

                {/* Uploaded files list */}
                <UploadedFilesGrid
                    checked={checked}
                    checkFile={checkFileCallback}
                    smallscreen={true}
                />

                {showAddMetadataInfo && (
                    <Paper
                        elevation={3}
                        sx={{
                            marginLeft: 5,
                            marginRight: 5,
                            marginBottom: 2,
                            padding: 2,
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            zIndex: 900,
                            backgroundColor: "white",
                        }}
                    >
                        <AddMetadataInfoText/>
                    </Paper>
                )}
            </Paper>
            {0 < numberOfCheckedCallback() && (
                <Dialog
                    open={0 < numberOfCheckedCallback()}
                    fullScreen
                    PaperProps={{
                        sx: {
                            position: "absolute",
                            bottom: 0,
                            left: 0,
                            height: "50vh",
                        },
                    }}
                    scroll={"paper"}
                >
                    <DialogTitle
                        sx={{
                            display: "flex",
                        }}
                    >
                        <NumberEditedInfo
                            text={t(
                                "editMetadataNumberOfCheckedObjects",
                                "Rediger metadata for {{numChecked}} valgte bilder.",
                                {numChecked: numberOfCheckedCallback()}
                            )}
                            onClick={clearAllCallback}/>
                        <Box flexGrow={1}/>
                        <CloseIcon
                            onClick={clearAllCallback}
                            sx={{cursor: "pointer", color: "GrayText"}}
                        />
                    </DialogTitle>
                    <DialogContent>
                        <Box sx={{padding: 1}}>
                            <BatchEditProvider>
                                <FormMetadata disabled={0 === numberOfCheckedCallback()}/>
                            </BatchEditProvider>
                        </Box>
                        <Divider/>

                        <Box marginTop={2}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    marginBottom: 2,
                                }}
                            >
                                <InfoIcon sx={{marginRight: 1, color: "GrayText"}}/>
                                <Typography color={"GrayText"} variant={"caption"}>
                                    {t(
                                        "editMetadataMissingTitle",
                                        "{{numMissingTitle}} bilder mangler tittel",
                                        {numMissingTitle: numMissingTitle}
                                    )}
                                </Typography>
                            </Box>
                        </Box>
                        <DialogActions>
                            <Box>
                                <Button onClick={clearAllCallback} sx={{marginRight: 1}}>
                                    Avbryt
                                </Button>
                                <FormikSubmitButton
                                    variant={"contained"}
                                    color={"secondary"}
                                    disabled={
                                        Object.values(checked) &&
                                        Object.values(checked).filter((v) => v === true).length ===
                                        0
                                    }
                                >
                                    {t("btnSaveEditedMetadata", "Lagre metadata")}
                                </FormikSubmitButton>
                            </Box>
                        </DialogActions>
                    </DialogContent>
                </Dialog>
            )}
        </DamsForm>
    );
};
