import React, {useRef} from "react";
import {EditActions} from "./EditActions";
import {DialogContent} from "@mui/material";
import {FormMetadata} from "../metadata/FormMetadata";
import {BatchEditProvider} from "../documents/batchEditContext";

export const EditContentActionsBelow = ({isProjectObject, collectionId}) => {
    const cardContentRef = useRef(null);
    return (
        <>
            <DialogContent ref={cardContentRef} sx={{overflowY: "auto"}}>
                <BatchEditProvider>
                    <FormMetadata isProjectObject={isProjectObject} collectionId={collectionId}/>
                </BatchEditProvider>
            </DialogContent>
            <EditActions/>
        </>
    );
};
