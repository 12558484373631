import Typography from "@mui/material/Typography";
import React, {useEffect, useState} from "react";
import {DialogWrapper} from "../app/DialogWrapper";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {useGetFolder} from "../folder/useGetFolder";
import useDeepCompareEffect from "use-deep-compare-effect";
import {
    ADD_COLLECTION_ID,
    useFileUploadDispatch,
    useFileUploadState,
    useFileUploadTranslation
} from "./fileUploadContext";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {ListItemButton} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import {useNavigate} from "react-router-dom";

/**
 * Informal dialog, containing the project's name, displayed when attempting to upload files to a project.
 * @returns {Element}
 * @constructor
 */
export const ProjectDialog = () => {
    const t = useFileUploadTranslation();
    const navigate = useNavigate();

    const {project} = useFileUploadState();

    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(true);
    const [projectId, setProjectId] = useState();
    const [projectDetails, setProjectDetails] = useState();

    const fileUploadDispatch = useFileUploadDispatch();

    const projectReq = useGetFolder(projectId);

    /**
     * Handler triggered when the dialog closes.
     * @param event object  Event object.
     * @param reason string The reason for why the dialog was closed.
     */
    const dialogCloseHandler = (event, reason) => {
        if ('continue' === reason) {
            fileUploadDispatch({
                type: ADD_COLLECTION_ID,
                collectionId: projectDetails?.collectionId
            })
            setOpenDialog(false);
        }
    };

    /**
     * Handler triggered when the user clicks the Cancel button.
     * The dialog is closed, and the upload-wizard is "terminated", leading the user back to "home".
     */
    const dialogCancelHandler = () => {
        setProjectDetails(undefined);
        if (projectId === -1) {
            navigate('/');
        } else {
            navigate(`/project/${projectId}`);
        }
    };

    /**
     * Returns the DialogActions section, used to render the buttons in the dialog.
     * @returns {JSX.Element}
     */
    const getDialogActions = () => {
        let btnContinueDisabled = false;
        let onClickMethod = () => {
            dialogCloseHandler(null, 'continue');
        };

        return <DialogActions>
            <Button onClick={dialogCancelHandler}>Avbryt</Button>
            <Button variant={"contained"}
                    onClick={onClickMethod}
                    disabled={btnContinueDisabled}>
                {t('projectDialogBtnContinue', 'Fortsett')}
            </Button>
        </DialogActions>;
    };

    /**
     * Hook used to make sure the project data is loaded,
     * before setting the projectId variable's value.
     */
    useEffect(() => {
        if (!project) {
            return;
        }

        setProjectId(project.id);
    }, [project]);

    /**
     * Hook used to load project details, and open the dialog when data have been loaded.
     */
    useDeepCompareEffect(() => {
        if (!loading || !project) {
            return;
        }

        if (!projectReq.pending && projectReq.success && projectReq.data) {
            setProjectDetails(projectReq.data);
            setLoading(false);
            setOpenDialog(true);
        } else if (projectReq.error || !projectReq.data) {
            setProjectDetails(undefined);
            setLoading(false);
        }
    }, [projectReq]);

    return <DialogWrapper
        openDialog={openDialog}
        closeHandler={dialogCloseHandler}
        dialogActions={() => getDialogActions()}>
        <Typography>
            {t('projectDialogInfo', 'Du er i ferd med å laste opp filer til prosjektet')}
        </Typography>
        <List>
            <ListItem>
                <ListItemButton selected={true}>
                    <ListItemIcon>
                        <AccountTreeIcon/>
                    </ListItemIcon>
                    <ListItemText primary={projectDetails?.title}/>
                </ListItemButton>
            </ListItem>
        </List>
    </DialogWrapper>;
};