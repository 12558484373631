import React from "react";
import {Formik} from "formik";
import {DatePickerProvider} from "./DatePickerContext";

/**
 * Renders a Formik form with the provided initialValues, onSubmit, validationSchema, and children.
 *
 * @param {Object} props - The properties for the DamsForm component.
 * @param {Object} props.initialValues - The initial values for the form fields.
 * @param {Function} props.onSubmit - The function to be called when the form is submitted.
 * @param {Yup.Schema} [props.validationSchema] - The validation schema for the form fields.
 * @param {ReactNode} props.children - The child components to be rendered inside the form.
 * @return {JSX.Element} The rendered Formik form.
 */
export const DamsForm = ({
                             initialValues,
                             onSubmit,
                             validationSchema,
                             children,
                         }) => {
    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
        >
            <DatePickerProvider>{children}</DatePickerProvider>
        </Formik>
    );
};
