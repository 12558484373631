import {HIDE, MEDIA_PLAYING, useSearchResultDetailsDispatch} from "../search-result-details/SearchResultDetailsContext";
import {useState} from "react";
import {SHOW_INSTANTLY, useSearchDispatch} from "../search/SearchContext";

/**
 * Handlers used on the object view screen.
 * @param dialogOpen
 */
export const useObjectViewHandlers = ({dialogOpen}) => {
    const searchDispatch = useSearchDispatch();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();
    const [openDialog, setOpenDialog] = useState(dialogOpen);

    /**
     * Callback used to open/close the dialog.
     * @param value
     */
    const openDialogCallback = (value) => {
        setOpenDialog(value);
    };

    /**
     * Dialog close handler
     * @param _event
     * @param reason
     */
    const handleOnClose = (_event, reason) => {
        if (reason === "backdropClick") {
            return;
        }
        openDialogCallback(false);
        searchResultDetailsDispatch({type: HIDE});
        searchDispatch({type: SHOW_INSTANTLY, showInstantly: null});
    };

    /**
     * Media "Click to play" handler.
     */
    const handleMediaPlayClick = () => {
        searchResultDetailsDispatch({type: MEDIA_PLAYING, playing: true});
    };

    return [openDialogCallback, handleOnClose, handleMediaPlayClick, openDialog];
}