import {damsFetch} from "../app/damsFetch";
import {getFolderSearchParams} from "../getFolderSearchParams";

/**
 * Async method used to fetch the list of folders that represents a DAMS-project.
 * @param query
 * @param sort
 * @param fl
 * @param start
 * @param rows
 * @param fq
 * @param status
 * @param museums
 * @param expand
 * @param onlyMine
 * @param onlyOthers
 * @param folderType
 * @returns {Promise<*>}
 */
export const projectsSearch = async ({
                                         query,
                                         sort,
                                         fl,
                                         start = 0,
                                         rows = 100,
                                         fq = {},
                                         status = '(-trash)',
                                         museums = [],
                                         expand = false,
                                         onlyMine = false,
                                         onlyOthers = false,
                                         folderType = "",
                                     }) => {

    const params = {
        q: query,
        sort: sort,
        fl: fl,
        expand: expand,
        start: start,
        rows: rows,
        documentType: `(Folder)`,
        museums: museums,
        onlyMine: onlyMine,
        onlyOthers: onlyOthers,
        status: status,
    };
    
    const searchParams = getFolderSearchParams(folderType, params, fq);

    return await damsFetch(`/folders/search?${searchParams.toString()}`);
};