import React, {createContext, useContext, useReducer} from 'react';
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

const MuseumStateContext = createContext(undefined);
const MuseumDispatchContext = createContext(undefined);
const MuseumTranslationContext = createContext(undefined);

export const SELECT_MUSEUMS = 'museumContext/selectMuseums';
export const ALL_MUSEUMS = 'museumContext/allMuseums'

const initialState = {
    museums: [],
}

const museumReducer = (state, action) => {
    switch (action.type) {
        case SELECT_MUSEUMS:
            return {
                ...state,
                museums: action.museums
            }
        case ALL_MUSEUMS:
            return {
                ...state,
                museums: initialState.museums
            }
        default:
            throw new Error(`Unhandled action type ${action.type}`)
    }
};

export const MuseumProvider = ({children}) => {
    const [state, dispatch] = useReducer(museumReducer, initialState, undefined);
    const {t, ready} = useTranslation("dams.museum", {useSuspense: false})

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>
    }

    return (
        <MuseumTranslationContext.Provider value={t}>
            <MuseumStateContext.Provider value={state}>
                <MuseumDispatchContext.Provider value={dispatch}>
                    {children}
                </MuseumDispatchContext.Provider>
            </MuseumStateContext.Provider>
        </MuseumTranslationContext.Provider>
    )
}

export const useMuseumState = () => {
    const context = useContext(MuseumStateContext);

    if (undefined === context) {
        throw new Error("useMuseumState must be used within a MuseumProvider");
    } else {
        return context;
    }
}

export const useMuseumDispatch = () => {
    const context = useContext(MuseumDispatchContext);
    if (undefined === context) {
        throw new Error('useMuseumDispatch must be used within a MuseumProvider')
    } else {
        return context;
    }
}

export const useMuseumTranslation = () => {
    const context = useContext(MuseumTranslationContext);
    if (undefined === context) {
        throw new Error("useMuseumTranslation must be used within a MuseumProvider");
    } else {
        return context;
    }
}