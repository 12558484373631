import React from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import ThreeDRotationOutlinedIcon from "@mui/icons-material/ThreeDRotationOutlined";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import VolumeUpOutlined from "@mui/icons-material/VolumeUpOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

function withThumbIconSupport(WrappedComponent) {
    return function WithThumbIconSupport(props) {
        const {height} = props;
        const fontSize = height ? `${height}px` : '140px';
        return <WrappedComponent {...props} color={"secondary"} sx={{fontSize: fontSize, color: '#bbb'}}/>;
    };
};

export const GeodataThumbnail = withThumbIconSupport(LocationOnOutlinedIcon);
export const TableThumbnail = withThumbIconSupport(TableChartOutlinedIcon);
export const ModelThumbnail = withThumbIconSupport(ThreeDRotationOutlinedIcon);
export const PDFThumbnail = withThumbIconSupport(PictureAsPdfIcon);
export const AudioThumbnail = withThumbIconSupport(VolumeUpOutlined);
export const DocumentThumbnail = withThumbIconSupport(DescriptionOutlinedIcon);