import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {AccordionFilter} from "../AccordionFilter";
import {FilterCheckbox} from "./FilterCheckbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {getFileExtensionFromMimeType} from "../../damsFileObjectDefinitions";
import {AccordionFilterTitle} from "./AccordionFilterTitle";

export const FilterFileTypes = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const fileTypesKey = "mediae.reference.mime_type";

    // Value entered by the user in order to search for a filter-/facet value.
    const [searchValue, setSearchValue] = useState('');

    // Holds the list of options, searched for by the user.
    const [options, setOptions] = useState({});

    // Holds the selected filter-/facet values.
    const [selected, setSelected] = useState([]);

    const getHumanReadableFileType = (mimeType) => {
        const desc = mimeType.split("/")[0];
        const ext = getFileExtensionFromMimeType(mimeType);
        return `${ext} (${desc})`;
    };

    const changeHandler = (value) => () => {
        modifySelectedFilter(value);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === fileTypesKey
                    )
                ) {
                    selectedFilters.push({key: fileTypesKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find(
                        (s) => s.value === value && s.key === fileTypesKey
                    )
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === fileTypesKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: fileTypesKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(fileTypesKey, selectedFilters);
        },
        [callback, selected]
    );

    const renderCheckBoxes = () => {
        return Object.keys(options).map((value) => (
            <ListItem
                key={value}
                dense={true}
                disablegutters={"true"}
                onClick={changeHandler(value)}
            >
                <ListItemIcon>
                    <FilterCheckbox
                        selected={selected}
                        subjectsKey={fileTypesKey}
                        value={value}
                    />
                </ListItemIcon>
                <ListItemText id={`label-${value}`}>
                    {getHumanReadableFileType(value)} ({options[value]?.count})
                </ListItemText>
            </ListItem>
        ));
    };

    /**
     * Callback used when the user types the search criteria.
     * Used to filter the list of available filter-values/-options.
     * @param event
     */
    const searchForFilters = (event) => {
        const value = event.target.value;
        setSearchValue(value);
    };

    /**
     * Sets the list of options, based on the search-criteria entered by the user.
     * @type {(function(*): void)|*}
     */
    const getOptions = useCallback((data) => {
        const matchingKeys = (searchValue !== '' && searchValue.length >= 2)
            ? Object.keys(data)
                .filter(k => k.toLowerCase().indexOf(searchValue?.toLowerCase()) > -1)
            : Object.keys(data);

        // Get the file types that matches the search criteria.
        const filteredFileTypes = Object.fromEntries(matchingKeys.map(k => [k, data[k]]));

        // Add selected/checked filter values to the list.
        const selectedKeys = selected.map(s => (s.value));
        const selectedFileTypes = Object.fromEntries(selectedKeys.map(k => [k, data[k]]));

        const opts = {...filteredFileTypes, ...selectedFileTypes};
        setOptions(opts);
    }, [searchValue, selected]);

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(fileTypesKey, []);
        }
    }, [clearFilters, callback]);

    useEffect(() => {
        if (fq) {
            if (fq.length > 0) {
                // Add selected filters from existing query
                fq.filter((f) => f.key === fileTypesKey).forEach((f) => {
                    modifySelectedFilter(f.value.replaceAll('"', ""), true);
                });
                setSearchValue('');
            } else {
                // Make sure the selected value is erased, when no filter values are selected.
                setSelected([]);
            }
        }
    }, [fq]);

    const fileTypes = results.facets.mimeTypes;
    const count = fileTypes && Object.keys(fileTypes).length;

    /**
     * Hook used to display a list of subjects based on the search-criteria entered by the user.
     */
    useEffect(() => {
        getOptions(fileTypes);
    }, [getOptions, fileTypes]);

    if (!count) {
        return <></>;
    } else {
        return (
            <AccordionFilter
                title={
                    <AccordionFilterTitle
                        title={`${t("fileTypes", "Filtyper")} (${count})`}
                        value={searchValue}
                        label={t('fileTypesSearch', 'Søk...')}
                        onChangeCallback={searchForFilters}
                        setSearchValueCallback={() => {
                            setSearchValue('');
                        }}
                    />
                }
                count={count}
            >
                <List>
                    {renderCheckBoxes()}
                </List>
            </AccordionFilter>
        );
    }
};
