import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "./ProgressIndicator";
import {WithAuthentication} from "../auths/WithAuthentication";
import {AuthsProvider} from "../auths/authsContext";
import {HeaderProvider} from "@ekultur/header-microfrontend";
import {MuseumProvider} from "../museum/MuseumContext";
import {DmsStatusProvider} from "../dmsstatus/DmsStatusContext";

export const MUSEUMS_RECEIVED = "museumsReceived";
export const APPS_RECEIVED = "appsReceived";
export const RENDERED = "rendered";
export const MENU_OPENED = "menuOpened";
export const MENU_CLOSED = "menuClosed";
export const SET_EXTERNAL_USER = 'setExternalUser';
export const SET_MUSEUM_USER_OR_ADMIN = 'setMuseumUserOrAdmin';
export const ADMIN_LOCAL_ASSETS = 'adminLocalAssets';
export const RESET_ADMIN_LOCAL_ASSETS = 'resetAdminLocalAssets';

const AppStateContext = createContext(undefined);
const AppDispatchContext = createContext(undefined);
const AppTranslationContext = createContext(undefined);

const initialState = {
    museums: [],
    apps: [],
    menuOpen: false,
    rendered: false,
    externalUser: false,
    museumUserOrAdmin: false,
    adminLocalAssets: {
        museum: undefined,
        assetType: undefined
    }
};

const appReducer = (state, action) => {
    switch (action.type) {
        case RESET_ADMIN_LOCAL_ASSETS:
            return {
                ...state,
                adminLocalAssets: {
                    museum: undefined,
                    assetType: undefined
                }
            };
        case ADMIN_LOCAL_ASSETS:
            return {
                ...state,
                adminLocalAssets: {
                    museum: action.museum || state.adminLocalAssets.museum,
                    assetType: action.assetType || state.adminLocalAssets.assetType
                }
            };
        case SET_MUSEUM_USER_OR_ADMIN:
            return {
                ...state,
                museumUserOrAdmin: action.value
            };
        case SET_EXTERNAL_USER:
            return {
                ...state,
                externalUser: true
            };
        case MUSEUMS_RECEIVED:
            return {
                ...state,
                museums: action.museums,
            };
        case APPS_RECEIVED:
            return {
                ...state,
                apps: action.apps,
            };
        case RENDERED:
            return {
                ...state,
                rendered: true,
            };
        case MENU_OPENED:
            return {
                ...state,
                menuOpen: true,
            };
        case MENU_CLOSED:
            return {
                ...state,
                menuOpen: false,
            };
        default:
            throw new Error(`Unhandled action type ${action.type}`);
    }
};

export const AppProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        appReducer,
        {...initialState},
        undefined
    );
    const {t, ready} = useTranslation("damsApp", {
        useSuspense: false,
    });

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <AppStateContext.Provider value={state}>
            <AppDispatchContext.Provider value={dispatch}>
                <AppTranslationContext.Provider value={t}>
                    <HeaderProvider>
                        <AuthsProvider>
                            <WithAuthentication>
                                <DmsStatusProvider>
                                    <MuseumProvider>{children}</MuseumProvider>
                                </DmsStatusProvider>
                            </WithAuthentication>
                        </AuthsProvider>
                    </HeaderProvider>
                </AppTranslationContext.Provider>
            </AppDispatchContext.Provider>
        </AppStateContext.Provider>
    );
};

export const useAppState = () => {
    const context = useContext(AppStateContext);
    if (undefined === context) {
        throw new Error(`useAppState must be used witin an AppProvider`);
    } else {
        return context;
    }
};

export const useAppDispatch = () => {
    const context = useContext(AppDispatchContext);
    if (undefined === context) {
        throw new Error(`useAppDispatch must be used within an AppProvider`);
    } else {
        return context;
    }
};

export const useAppTranslation = () => {
    const context = useContext(AppTranslationContext);
    if (undefined === context) {
        throw new Error("useAppTranslation must be used within an AppProvider");
    } else {
        return context;
    }
};
