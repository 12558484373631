import React from "react";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import {useMuseumNameFromCollectionId} from "../../../../museum/useMuseumNameFromCollectionId";
import {PROJECT_CLOSE_DETAILS_OVERLAY, useProjectViewDispatch, useProjectViewState,} from "../projectViewContext";
import {useProjectTranslation} from "../../../projectContext";
import {getContentSelector, getHrDateTime, getHrLatLngStr, getProjectPeriod} from "../../../projectUtility";
import {DetailsRow} from "../../../smallscreen/DetailsRow";
import {DialogDetailsTitle} from "../../../smallscreen/DetailsDialogTitle";
import {DetailsDialogCloseButton} from "../../../smallscreen/DetailsDialogCloseButton";
import {DetailsDialogStats} from "../../../smallscreen/DetailsDialogStats";


export const ArcheologyProjectViewDetailsSmallScreen = ({show}) => {
    const language = "no";
    const tp = useProjectTranslation();
    const projectViewDispatch = useProjectViewDispatch();
    const [getMuseumName] = useMuseumNameFromCollectionId();
    const {project: model} = useProjectViewState();
    const museumName = getMuseumName(model.collectionId);

    const selector = getContentSelector(model);

    const {title, content, createdAt} = model;

    const {
        id: projectId,
        startedAt,
        endedAt,
        type: projectType,
        description: projectDescription,
        dataset: datasetNode,
        latLng,
        uuid
    } = content[selector];

    const projectPeriod = getProjectPeriod(startedAt, endedAt);

    const projectMonumentPeriod =
        content[selector].monumentPeriod || "<uspesifisert>";

    const projectMonumentType =
        content[selector].monumentType || "<uspesifisert>";

    let projectDataset;
    if (datasetNode && Object.keys(datasetNode)) {
        projectDataset = datasetNode[language] || "";
    }

    const projectLatLng = getHrLatLngStr(latLng?.lat, latLng?.lng);

    const openInNewTab = (_) => {
        window
            .open(
                `https://arkeologi.org/${uuid}`,
                "_blank"
            )
            .focus();
    };

    const handleClose = () => {
        projectViewDispatch({type: PROJECT_CLOSE_DETAILS_OVERLAY});
    };

    if (!museumName) {
        return <></>;
    }

    return (
        <Dialog fullScreen={true} open={show}>
            <DialogDetailsTitle title={title} openInNewTabCallback={openInNewTab} projectDataset={projectDataset}/>
            <DialogContent>
                <DetailsDialogCloseButton tp={tp} closeCallback={handleClose} iconButton={true}/>

                {/* Project metadata */}
                <DetailsRow label={tp('lblProjectId', 'Prosjekt ID') + ':'} value={projectId}/>
                <DetailsRow label={tp('lblMuseum', 'Museum') + ':'} value={museumName}/>
                <DetailsRow label={tp('lblLatLng', 'Lat/Lng') + ':'} value={projectLatLng}/>
                <DetailsRow
                    label={tp('lblCreated', 'Opprettet') + ':'}
                    value={createdAt && getHrDateTime(createdAt, language)}
                />

                <Divider sx={{marginTop: 1, marginBottom: 1}}/>

                {/* Project facts */}
                <DetailsRow label={tp('lblPeriod', 'Periode') + ':'} value={projectPeriod}/>
                <DetailsRow label={tp('lblProjectType', 'Prosjekttype') + ':'} value={projectType}/>
                <DetailsRow label={tp('lblMonumentType', 'Monumenttype') + ':'} value={projectMonumentType}/>
                <DetailsRow label={tp('lblMonumentPeriod', 'Monumentperiode') + ':'} value={projectMonumentPeriod}/>

                <Typography variant={"caption"}>{tp('lblDescription', 'Beskrivelse')}</Typography>
                <Typography variant={"caption"}>{projectDescription}</Typography>

                <Divider sx={{marginTop: 1, marginBottom: 1}}/>

                {/* Project stats */}
                <DetailsDialogStats tp={tp}/>
            </DialogContent>
            <DialogActions>
                <DetailsDialogCloseButton tp={tp} closeCallback={handleClose} iconButton={false}/>
            </DialogActions>
        </Dialog>
    );
};
