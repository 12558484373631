import DoneIcon from "@mui/icons-material/Done";

/**
 * A column for the "Source" field in the local assets table.
 *
 * Returns a "Done" icon if the value is true, otherwise returns nothing.
 * The "Done" icon represents an asset fetched from KulturNav.
 *
 * @param {{value: boolean}} props
 * @returns {JSX.Element}
 */
export const KulturNavCheckIcon = ({value}) => {
    if (value) {
        return <DoneIcon/>;
    } else {
        return <></>;
    }
};