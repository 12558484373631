import React, {useEffect, useRef, useState} from "react";
import {Box, Dialog, Slide, Toolbar, useMediaQuery,} from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as Yup from "yup";
import {DamsForm} from "../form/DamsForm";
import {FileGridItem} from "./FileGridItem";
import {
    ADD_SAVED,
    CLEAR_SELECTED,
    useFileUploadDispatch,
    useFileUploadState,
    useFileUploadTranslation
} from "./fileUploadContext";
import {StepEditMetadataForm} from "./StepEditMetadataForm";
import {useFormHelper} from "./useFormHelper";
import Button from "@mui/material/Button";
import {FormikSubmitButton} from "../form/FormikSubmitButton";
import Stack from "@mui/material/Stack";
import {CHOOSE_COLLECTIONS, UPDATE_FORM_DATA, useDocumentDispatch} from "../documents/documentContext";
import {usePostDocuments} from "../documents/usePostDocuments";
import {SelectedObjectsHeader} from "./SelectedObjectsHeader";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import {StepEditMetadataProjectForm} from "./StepEditMetadataProjectForm";
import {CopyrightProvider} from "../copyright/copyrightContext";
import {DialogWarnZipFiles} from "./DialogWarnZipFiles";
import {
    deleteMarkedClauses,
    deleteMarkedLicenses,
    mergeCopyrightDates,
    mergeCopyrightInfo,
    mergeLicenseInfo
} from "../app/metadataUtils";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


export const StepEditMetadata = () => {
    const t = useFileUploadTranslation();

    // eslint-disable-next-line no-unused-vars
    const [_, postDocuments] = usePostDocuments(); // NOSONAR

    // files - the list of uploaded files, as saved in DAMS, before metadata has been altered.
    const {saved, uploadedFiles, selected, collectionId, project} = useFileUploadState();
    const fileUploadDispatch = useFileUploadDispatch();

    // Required by the form fields
    const docDispatch = useDocumentDispatch();

    const [getInitialValues] = useFormHelper();

    const [show, setShow] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [includesZipFiles, setIncludesZipFiles] = useState(false);

    const [initialValues, setInitialValues] = useState({});

    const smallScreen = useMediaQuery("(max-width: 1024px)");

    const scrollableGridRef = useRef();

    /**
     * Validate WGS84-based data field value.
     *
     * Expected format: ddd.ddd, ddd.ddd
     * E.g. Lillehammer: 61.113332, 10.464403
     * NOTE:
     * - Accepts 10 decimals
     * - Values must be separated with comma
     * - Decimal-separator must be . (punctuation).
     * - Returns true if the provided value is not validated correctly.
     *
     * @param value str The string to evaluate
     * @returns {boolean}
     */
    const validateWGS84 = (value) => {
        if (!value || value === "") {
            return false;
        }

        // Removing degree-symbol before validating
        value = value.replaceAll("°", "");
        value = value.replaceAll(' ', '');

        // Figure out if the values are within the range of +/- 180 degrees and
        // with no more than 10 decimals.
        const mask = /(\+|-|\s*)(\d{1,3}\.\d{1,10})/gi; // NOSONAR

        // Test if to values are present
        const ex = new RegExp(mask, "gi"); // NOSONAR
        const tst = ex.test(value);

        let invalid = false;

        if (!tst) {
            return true;
        }
        const values = value.split(",");
        if (values.length < 2) {
            return true
        }

        for (let i = 0, max = values.length; i < max; i++) {
            if (values[i] === '') {
                invalid = true;
            } else {
                const v = Number(values[i]);
                if (isNaN(v)) {
                    invalid = true;
                    break;
                }
                if (v < -180 || v > 180) {
                    invalid = true;
                }
            }
        }
        return invalid;
    };

    /**
     * Yup schema, used when validating the form values.
     * NOTE:
     * The coordinates field is evaluated with the "optional" option, as this field may not be present
     * in some cases.
     */
    const validationSchema = Yup.object({
        title: Yup.string(),
        description: Yup.string(),
        documentationType: Yup.object(),
        customIdentifier: Yup.string(),
        productionDate: Yup.string(),
        producer: Yup.array(),
        persons: Yup.array(),
        places: Yup.array(),
        subjects: Yup.array(),
        licenses: Yup.array(),
        relations: Yup.array(),
        remarks: Yup.string(),
        languages: Yup.array(),
        coordinates: Yup.string().optional().test("has-digits", t('stepEditMetadataIncorrectSyntax', 'Feil syntaks'), (value, _context) => {
            return !validateWGS84(value);
        })
    });

    /**
     * Creates the document object, used when saving metadata.
     * @param values object The values to be saved
     * @param f object  The file object, representing an already saved DAMS-object.
     * @returns {{description: *, title: *, uniqueId, content: {persons: *, places: *, productionDate: *, languages: *, documentationType: ({}|*), subjects: *, customIdentifier: (*|string), coordinates: (*|string), producer: ([]|*), remarks: *, mediae: ([{reference: {mimeType: string, title: string}}]|[{reference: {mimeType: string, title: string}}]|[{reference: {mimeType: string, title: string}}]|[{reference: {mimeType: string, title: string}}]|[{reference: {mimeType: string, title: string}}]|[{reference: {mimeType: string, title: string}}]|[{reference: {mimeType: string, title: string}}]|[{reference: {mimeType: string, title: string}}]|[{reference: {title: string}}]|[{reference: {title: string}}]|[{reference: {sourceId: *, _action: string, source: string, title: *, locale: string, content: {fileExtension: *, filesize: *, mimeType: *}, status: string}, referenceType: string, _action: string}]|[{reference: {sourceId: *, _action: string, source: string, title: *, locale: string, content: {fileExtension: *, filesize: *, mimeType: *}, status: string}, referenceType: string, _action: string}]|*)}}}
     */
    const createDocumentObject = (values, f) => {
        deleteMarkedClauses(values, values);
        deleteMarkedLicenses(values, values);
        mergeCopyrightInfo(values);
        mergeCopyrightDates(values);
        mergeLicenseInfo(values);       // Adds information from license fields, stored in a separate structure.
        return {
            uniqueId: f.uniqueId,
            collectionId: collectionId,
            title: values.title || f.title,
            description: values.description || f.description,
            content: {
                customIdentifier: values.customIdentifier || f.customIdentifier,
                productionDate: values.productionDate || f.productionDate,
                producer: values.producer || f.producer,
                persons: values.persons || f.persons,
                places: values.places || f.places,
                coordinates: values.coordinates || f.coordinates,
                subjects: values.subjects || f.subjects,
                documentationType: values.documentationType || f.documentationType,
                languages: values.languages || f.languages,
                remarks: values.remarks || f.remarks,
                mediae: f.content.mediae,
                licenses: values.licenses || [],
                copyrightTerms: values.copyrightTerms,
                copyrightInfo: values.copyrightInfo,
                copyrightType: values.copyrightType,
                copyrightTypeDateUntil: values.copyrightTypeDateUntil,
                copyrightTypeOriginator: values.copyrightTypeOriginator,
                copyrightTypeResponsible: values.copyrightTypeResponsible
            }
        };
    };

    /**
     * Callback executed when saving updated metadata.
     * @param data
     * @param actions
     */
    const onSubmitCallback = (data, actions) => {

        // Get the complete object for the selected files:
        const fileObjs = saved.filter(f => selected.includes(f.uniqueId));

        // Merge edited data into existing fileObjs:
        const documents = fileObjs.map(f => {
            return createDocumentObject(data, f);
        });

        Promise.all(postDocuments(documents)).then(res => {
            // Tag documents as edited!
            res = res.map(r => {
                return {
                    ...r,
                    edited: true
                }
            });

            fileUploadDispatch({
                type: ADD_SAVED,
                documents: res
            });

            fileUploadDispatch({
                type: CLEAR_SELECTED
            });

            actions.setSubmitting();
            closeDialog();
        });
    };

    /**
     * Renders the metadata form.
     * @returns {JSX.Element}
     */
    const getMetadataForm = () => {
        let style = {
            minWidth: '30%',
            width: '30%',
            overflowY: 'auto',
        };

        if (smallScreen) {
            style = {
                ...style,
                width: '100%',
                overflowY: 'auto',
                height: '80vh',
                maxHeight: '80vh',
                padding: '16px'
            };
        }

        return <Stack direction={"column"}
                      sx={style}>
            {(!project || project.folderType !== 'ProjectArcheology') &&
                <CopyrightProvider><StepEditMetadataForm/></CopyrightProvider>}
            {project && project?.id && project?.folderType === 'ProjectArcheology' && <StepEditMetadataProjectForm/>}
            <Stack direction={"row"} justifyContent={'end'}>
                <Box>
                    <FormikSubmitButton disabled={selected.length === 0} variant={"contained"} color={"secondary"}>
                        {t('btnSaveMetadata', 'Lagre Metadata')}
                    </FormikSubmitButton>
                </Box>
            </Stack>
        </Stack>;
    };

    /**
     * Closes the edit dialog, displayed on smaller screens.
     */
    const closeDialog = () => {
        if (smallScreen) {
            setOpenDialog(false);
        }
    };

    /**
     * Handler triggered when the edit-dialog (small screen) is closed.
     */
    const handleDialogClose = () => {
        closeDialog();
    };

    /**
     * Handler triggered when the user clicks on the "rediger" button (small screen).
     */
    const handleEditButtonClick = () => {
        setOpenDialog(true);
    };

    const getZipFilesDialog = () => {
        // Uploaded files includes .zip files, warn user.
        return <DialogWarnZipFiles open={includesZipFiles}/>
    };

    useEffect(() => {
        if (uploadedFiles.length > 0) {
            setShow(true);
        }
    }, [uploadedFiles]);

    /**
     * Hook used to add the selected collection ID to the documentContext, used by the referenced form fields.
     */
    useEffect(() => {
        if (collectionId) {
            docDispatch({
                type: CHOOSE_COLLECTIONS,
                collectionIds: [collectionId]
            });
        }
    }, [collectionId, docDispatch]);

    useEffect(() => {
        const iv = getInitialValues(selected.length)
        docDispatch({type: UPDATE_FORM_DATA, formData: iv});
        setInitialValues(iv);
    }, [selected, docDispatch]); // NOSONAR

    /**
     * Hook used to determine if any ZIP-files have been uploaded, if so, inform the user.
     */
    useEffect(() => {
        const zipFiles = uploadedFiles.filter(f => (f.content.mediae[0].reference.fileExtension === 'zip'));
        if (zipFiles.length > 0) {
            setIncludesZipFiles(true);
        }
    }, [uploadedFiles]);

    if (!smallScreen) {
        return (
            show && <>
                {getZipFilesDialog()}
                <Stack direction={"row"}
                       sx={{
                           minWidth: '100%',
                           height: '100%'
                       }}>

                    {/* 1st column: Header + uploaded files */}
                    <Stack direction={"column"}
                           sx={{
                               minWidth: '70%',
                           }}>
                        <SelectedObjectsHeader/>
                        <Grid ref={scrollableGridRef}
                              direction={"row"}
                              container
                              sx={{
                                  overflowY: 'auto',
                                  alignContent: 'flex-start',
                              }}>
                            {uploadedFiles.map((f) => (
                                <Grid key={`grid-item-${f.uniqueId}`}>
                                    <FileGridItem model={f} key={`file-grid-item-${f.uniqueId}`}/>
                                </Grid>
                            ))}
                        </Grid>
                    </Stack>

                    {/* 2nd column: metadata form */}
                    <DamsForm validationSchema={validationSchema}
                              initialValues={initialValues}
                              onSubmit={onSubmitCallback}>
                        {getMetadataForm()}
                    </DamsForm>
                </Stack>
            </>
        );
    } else {
        return show && <>
            {getZipFilesDialog()}
            <DamsForm validationSchema={validationSchema}
                      initialValues={initialValues}
                      onSubmit={onSubmitCallback}>
                <SelectedObjectsHeader>
                    <Button
                        size={"small"}
                        color={"secondary"}
                        variant={"contained"}
                        onClick={handleEditButtonClick}
                        disabled={selected.length === 0}
                    >
                        {t('btnEdit', 'Rediger')}
                    </Button>
                </SelectedObjectsHeader>
                <Stack direction={"row"}>
                    <Grid
                        container
                        direction={"row"}
                        alignContent={"flex-start"}
                        justifyContent={"flex-start"}
                        sx={{
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            height: '50vh',
                            maxHeight: '50vh'
                        }}
                    >
                        <Grid size={{md: 8}}>
                            <Grid container spacing={1} direction={"column"}>
                                {uploadedFiles.map((f) => (
                                    <Grid key={f.uniqueId}>
                                        <FileGridItem model={f} thumbnailTextColor={'#888'}/>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    </Grid>
                </Stack>
                <Dialog
                    fullScreen
                    open={openDialog}
                    onClose={handleDialogClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{position: 'relative'}}>
                        <Toolbar>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleDialogClose}
                                aria-label={t('stepEditMetadataClose', 'lukk')}
                            >
                                <CloseIcon/>
                            </IconButton>
                            <Typography sx={{ml: 2, flex: 1}} variant="h6" component="div">
                                {t('btnEditMetadata', 'Rediger Metadata')}
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    {getMetadataForm()}
                </Dialog>
            </DamsForm>
        </>;
    }
};
