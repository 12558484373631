import React, {useCallback, useEffect, useMemo, useState} from "react";
import {MaterialReactTable, useMaterialReactTable} from "material-react-table";
import {getTableTranslations} from "../../table-base/getTableTranslations";
import PropTypes from "prop-types";
import {useFoldersState} from "../../folder/FoldersContext";
import {getTableColumnsBase} from "../../table-base/getTableColumnsBase";
import {getHrDateAndTime, getMuseumNameFromCollectionId} from "../../utility";
import {useMyMuseums} from "../../museum/useMyMuseums";
import {useAuthsState} from "../../auths/authsContext";
import {ColumnFolderNameWithIcon} from "./ColumnFolderNameWithIcon";
import Box from "@mui/material/Box";

/**
 * Component rendering a table of folders, used in the "Add to folder" component.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.t - The translation function.
 * @param {Function} props.setFolderCallback - The callback function to set the id of the selected folder.
 * @return {JSX.Element} The rendered component.
 */
const FoldersTable = ({t, setFolderCallback}) => {

    const {museumCollections} = useAuthsState();
    const {results} = useFoldersState();
    const myMuseums = useMyMuseums({});

    const [models, setModels] = useState([]);

    const [rowSelection, setRowSelection] = useState({});
    const [loading, setLoading] = useState(true);

    const getMuseumColumn = useCallback(() => {
        return {
            id: 'museum',
            header: t("columnMuseum", "Museum"),
            enableEditing: false,
            accessorFn: (row) => {
                return getMuseumNameFromCollectionId({
                    museums: myMuseums,
                    museumCollections: museumCollections,
                    collectionId: row.collectionId
                });
            }
        };
    }, [museumCollections, myMuseums, t]);

    const FolderNameCell = useCallback(({renderedCellValue}) => (
        <ColumnFolderNameWithIcon folderName={renderedCellValue}/>
    ), []);

    const columns = useMemo(() => {
        return [
            ...getTableColumnsBase(),
            {
                id: 'title',
                header: t('columnFolderName', 'Mappenavn'),
                accessorKey: 'title',
                Cell: FolderNameCell,
                grow: false
            },
            getMuseumColumn(),
            {
                id: 'createdAt',
                header: t('columnCreatedAt', 'Opprettet'),
                accessorKey: 'createdAt',
                enableSorting: true,
                enableHiding: false,
                accessorFn: row => getHrDateAndTime(row.createdAt),
                Cell: ({renderedCellValue}) => {
                    return renderedCellValue;
                },
            },
            {
                id: 'status',
                header: t('columnFolderType', 'Mappetype'),
                accessorKey: 'status',
                accessorFn: row => {
                    return row.status === 'private' ? t('columnPrivate', 'Privat') : ''
                },
                Cell: ({renderedCellValue}) => {
                    return renderedCellValue;
                },
            },
            {
                id: 'createdBy',
                header: t('columnCreatedBy', 'Opprettet av'),
                accessorKey: 'createdByName',
            }

        ];
    }, [getMuseumColumn, t, FolderNameCell]);

    const translations = useMemo(() => getTableTranslations(t), [t]);

    const tableConfig = {
        columns,
        data: models,
        defaultColumn: {
            minSize: '20px', //allow columns to get smaller than default
            maxSize: '300px', //allow columns to get larger than default
            size: '280px', //make columns wider by default
        },
        enableStickyHeader: true,
        enableDensityToggle: false,
        enableColumnFilters: false,
        enableFullScreenToggle: false,
        enableColumnActions: false,
        enableRowSelection: true,
        enableMultiRowSelection: false,
        enableSelectAll: false,
        enableHiding: false,
        localization: translations,
        globalFilterFn: 'contains',
        muiTablePaperProps: {
            sx: {
                'display': 'flex',
                'flexDirection': 'column',
            }
        },
        muiToolbarAlertBannerProps: {
            sx: {
                display: 'none'
            }
        },
        getRowId: row => row.id,
        onRowSelectionChange: setRowSelection,
        initialState: {
            density: 'compact',
            columnVisibility: {id: false, collectionId: false, uniqueId: false},
            columnOrder: ['id', 'collectionId', 'uniqueId', 'title', 'description', 'status', 'museum']
        },
        state: {
            rowSelection
        },
    };

    const table = useMaterialReactTable(tableConfig);

    /**
     * Hook used to make sure the list of the user's museums.
     */
    useEffect(() => {
        if (!myMuseums || myMuseums.length === 0) {
            return;
        }
        setLoading(false);
    }, [myMuseums]);

    /**
     * Hook used to make sure the list of folders are loaded.
     */
    useEffect(() => {
        if (!results) {
            return;
        }
        setModels(results.models);
    }, [results]);

    /**
     * Hook used to get information about the folder selected by the user.
     */
    useEffect(() => {
        const selectedRows = table.getSelectedRowModel().rows;
        if (!selectedRows || selectedRows?.length === 0) {
            return;
        }

        const selectedRow = selectedRows[0]?.original;
        if (!selectedRow) {
            return;
        }

        const {id: folderId} = selectedRow;
        setFolderCallback(folderId);
    }, [rowSelection, setFolderCallback, table]);

    return !loading && <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            overflow: 'auto'
        }}>
        <MaterialReactTable table={table}/>
    </Box>;
};

FoldersTable.propTypes = {
    t: PropTypes.func.isRequired,
};

export {FoldersTable};