import {InputPersons} from "../person/InputPersons";

export const FormikSelectCopyrightResponsible = ({t, ix, size}) => {
    const k = `copyrightResponsible${ix}`;

    return <InputPersons disabled={false}
                         label={t('lblPersonCompanyClauseAssociated', 'Person eller firma tilknyttet klausulen')}
                         formikKey={k}
                         createLabel={t('lblAddPersonCompany', 'Legg til person/firma')}
                         alwaysActive={true}
                         fullWidth={true}
                         size={size}/>;
};