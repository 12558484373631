import React from "react";
import {isDateInPast} from "../app/dateFormat";

import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import TextField from "@mui/material/TextField";
import UpdateIcon from "@mui/icons-material/Update";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";

export const PopoverDatePicker = ({date, changeHandler}) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onChange = (newDate) => {
        setAnchorEl(null);
        changeHandler(newDate);
    };

    const open = Boolean(anchorEl);
    const id = open ? "datepicker-popover" : undefined;

    return (
        <>
            <IconButton aria-label={"delete"} onClick={handleClick} size="large">
                <UpdateIcon color={isDateInPast(date) ? "error" : "inherit"}/>
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        color={"secondary"}
                        disableToolbar
                        variant="static"
                        inputFormat="dd/MM/yyyy"
                        margin="normal"
                        disablePast={true}
                        value={date}
                        onChange={onChange}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
            </Popover>
        </>
    );
};
