import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React, {useState} from "react";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import {FileObjectType} from "./FileObjectType";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import TableChartOutlinedIcon from "@mui/icons-material/TableChartOutlined";
import PhotoCameraOutlinedIcon from "@mui/icons-material/PhotoCameraOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ThreeDRotationOutlinedIcon from "@mui/icons-material/ThreeDRotationOutlined";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {VolumeUpOutlined} from "@mui/icons-material";
import {useGetDocumentTypeIcon} from "../useGetDocumentTypeIcon";
import {useMediaQuery} from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import {
    AudioThumbnail,
    DocumentThumbnail,
    GeodataThumbnail,
    ModelThumbnail,
    PDFThumbnail,
    TableThumbnail
} from "./FileThumbIcon";
import {useFileUploadState} from "./fileUploadContext";
import Tooltip, {tooltipClasses, TooltipProps} from '@mui/material/Tooltip';
import {styled} from "@mui/material/styles";
import ArticleIcon from '@mui/icons-material/Article';
import {EditObjectTypeDialog} from "./EditObjectTypeDialog";
import {getMimeTypeFromModel} from "../utility";


const HtmlTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} arrow classes={{popper: className}}/>
))(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));


/**
 * Base-object used to create the FileGrid items used when listing the uploaded files,
 * or the files to be dragged to a folder after upload is complete.
 *
 * model = {
 *         "uniqueId": "c1fc90d1-e7aa-4fee-b847-411c6b2e4303",
 *         "createdAt": "2023-06-22T12:01:44",
 *         "updatedAt": "2023-06-22T12:01:44",
 *         "id": 238,
 *         "schemaId": 1,
 *         "documentType": "StillImage",
 *         "title": "monkey.jpg (1) (2)_4227aa63-71eb-4522-8348-ef97e2502d75 (13).jpg",
 *         "locale": "no",
 *         "content": {
 *             "mediae": [
 *                 {
 *                     "reference": {
 *                         "id": 237,
 *                         "title": "monkey.jpg (1) (2)_4227aa63-71eb-4522-8348-ef97e2502d75 (13).jpg",
 *                         "locale": "no",
 *                         "source": "dms",
 *                         "status": "published",
 *                         "fileSize": 70588,
 *                         "mimeType": "image/jpeg",
 *                         "schemaId": 1,
 *                         "sourceId": "0136MRrbiy1r",
 *                         "uniqueId": "a1e39e7b-aabb-41b6-ba95-3dbb2392e8d7",
 *                         "createdAt": "2023-06-22T12:01:44",
 *                         "updatedAt": "2023-06-22T12:01:44",
 *                         "documentType": "media",
 *                         "fileExtension": "jpg"
 *                     },
 *                     "referenceType": "media"
 *                 }
 *             ],
 *             "remarks": "",
 *             "subjects": null,
 *             "languages": null
 *         },
 *         "status": "published",
 *         "collectionId": 3,
 *         "createdById": 2,
 *         "referenceCount": 0,
 *         "parent": null,
 *         "createdByName": null,
 *         "updatedByName": null,
 *         "thumbnail": "blob:http://localhost:5000/38660003-26da-43fa-a1f9-7fa0b79188c9"
 *     };
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const FileGridItemBase = ({...props}) => {
    const [showObjectEditTypeDialog, setShowObjectTypeDialog] = useState(false);

    const smallScreen = useMediaQuery("(max-width: 1024px)");

    const {saved} = useFileUploadState();

    const {
        model,
        onClick,
        draggable,
        onDragStart,
        raised,
        checkBox,
        thumbnailTextColor,
        isZipFile,
    } = props;

    const [currentModel, setCurrentModel] = useState(model);

    const {
        uniqueId,
        title,
        documentType,
        thumbnail,
        dmsId
    } = currentModel;

    const mimeType = getMimeTypeFromModel(model);
    const documentTypeIcon = useGetDocumentTypeIcon(mimeType || undefined);

    const imageUrl = thumbnail || 'no-thumbnail.jpg';

    /**
     * True if CardMedia object is to be displayed for an object type of image, PDF or video.
     * @returns {boolean}
     */
    const showCardMedia = () => {
        if (mimeType) {
            return mimeType.indexOf('image') > -1 || mimeType.indexOf('video') > -1;
        } else {
            return false;
        }
    };

    const getTextColor = () => {
        if (['StillImage', 'Video', 'Fotografi'].includes(documentType)) {
            return smallScreen ? thumbnailTextColor : '#888';
        } else {
            return thumbnailTextColor ? thumbnailTextColor : '#888';
        }
    };

    const getObjectTypeIcon = (objectType) => {
        switch (objectType) {
            case "PDF":
                return <PictureAsPdfIcon color={"secondary"}/>;
            case "Geodata":
                return <LocationOnOutlinedIcon color={"secondary"}/>;
            case "Tabell":
                return <TableChartOutlinedIcon color={"secondary"}/>;
            case "StillImage":
                return <PhotoCameraOutlinedIcon color={"secondary"}/>;
            case "Fotografi":
                return <PhotoCameraOutlinedIcon color={"secondary"}/>;
            case "Video":
                return <VideocamOutlinedIcon color={"secondary"}/>;
            case "Modell":
                return <ThreeDRotationOutlinedIcon color={"secondary"}/>;
            case "Audio":
                return <VolumeUpOutlined color={"secondary"}/>;
            case "Dokument":
                return <ArticleIcon color={"secondary"}/>;
            default:
                // Return DAMS default document type icon.
                return documentTypeIcon;
        }
    };

    /**
     * Returns the thumbnail that matches the specific document type, for object types other than image and video.
     * @param height int    The height of the thumbnail-area in pixels.
     * @returns {JSX.Element}
     */
    const getObjectTypeThumbnail = (height) => {
        let dt = documentType;
        if (mimeType && mimeType.indexOf('pdf') > -1) {
            // PDF is a special case, since it is stored as a regular document object.
            dt = 'PDF';
        }

        /*
        When displaying a thumbnail on larger screens, the thumbnail is part of a "Card",
        therefore, the height of the thumbnail is reduced to 70% of the specified height.
        On smaller screens, the thumbnail is displayed with the specified height.
         */
        let thumbnail;
        let thumbnailHeight = smallScreen ? height : 0.7 * height;
        switch (dt) {
            case 'Geodata':
                thumbnail = <GeodataThumbnail height={thumbnailHeight}/>;
                break;
            case 'Tabell':
                thumbnail = <TableThumbnail height={thumbnailHeight}/>;
                break;
            case 'Modell':
                thumbnail = <ModelThumbnail height={thumbnailHeight}/>;
                break;
            case 'Audio':
                thumbnail = <AudioThumbnail height={thumbnailHeight}/>;
                break;
            case 'PDF':
                thumbnail = <PDFThumbnail height={thumbnailHeight}/>;
                break;
            default:
                thumbnail = <DocumentThumbnail height={thumbnailHeight}/>;
                break;
        }

        return <Box sx={{
            height: `${height}px`,
            maxHeight: `${height}px`,
            minHeight: `${height}px`,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        }}>
            {thumbnail}
        </Box>;
    };

    /**
     * Returns the thumbnail to be displayed on the card.
     * @param   height    Height of the thumbnail in pixels.
     * @returns {JSX.Element}
     */
    const getThumbnail = (height) => {
        if (showCardMedia() && !smallScreen) {
            return <CardMedia
                component={"img"}
                image={imageUrl}
                title={title}
                alt={dmsId}
                sx={{
                    height: `${height}px`,
                    maxHeight: `${height}px`
                }}
            />;
        } else if (showCardMedia() && smallScreen) {
            return <img src={imageUrl}
                        style={{
                            objectFit: 'cover',
                            width: `${height}px`,
                            height: `${height}px`
                        }}
                        alt={title}/>;
        } else {
            return getObjectTypeThumbnail(height);
        }
    };

    /**
     * True if the current object has been edited.
     * @returns {boolean}
     */
    const isEdited = () => {
        return Boolean(saved.find(f => f.uniqueId === uniqueId && f.edited));
    }

    /**
     * Returns the border settings for an "edited" vs "unedited" object.
     * @returns {string}
     */
    const getBorderSettings = () => {
        if (isEdited()) {
            return 'solid 3px rgba(64, 165, 94, 0.70)';
        } else if (isZipFile) {
            return 'solid 3px rgb(245, 118, 34)';
        } else {
            return 'transparent';
        }
    };

    const getCursorSettings = () => {
        if (isZipFile) {
            return 'pointer';
        } else {
            return 'default';
        }
    };

    /**
     * Renders the content of the tooltip that appears when the user hovers over the filename.
     * @returns {JSX.Element}
     */
    const getTooltipContent = () => {
        const data = saved.find(f => f.uniqueId === uniqueId);
        if (!data) {
            return <></>;
        }

        const {createdAt, updatedAt, title, content} = data;
        const {mimeType: savedFileMimeType, fileExtension} = content.mediae[0].reference;
        const {remarks, subjects, languages} = content;

        const dataSimplified = {
            createdAt: createdAt,
            updatedAt: updatedAt,
            title: title,
            mimeType: savedFileMimeType,
            fileExtension: fileExtension,
            remarks: remarks,
            subjects: subjects,
            languages: languages
        };

        const labels = {
            createdAt: 'Lastet opp',
            updatedAt: 'Oppdatert',
            title: 'Tittel',
            mimeType: 'Mimetype',
            fileExtension: 'Filendelse',
            remarks: 'Merknader',
            subjects: 'Emneord',
            languages: 'Språk'
        };

        return <Stack direction={"column"} key={`tooltip-${uniqueId}`}>
            {
                Object.keys(dataSimplified).map((k) => {
                    return <Stack direction={"row"} key={`row-${uniqueId}-${crypto.randomUUID()}`}>
                        <Typography variant={"caption"}>{labels[k]}:</Typography>
                        <Typography variant={"caption"}
                                    sx={{marginLeft: '2px'}}>{dataSimplified[k]}</Typography>
                    </Stack>;
                })
            }
        </Stack>;
    };

    const closeEditObjectTypeDialog = () => {
        setShowObjectTypeDialog(false);
    };

    const saveSelectedObjectType = (updatedModel) => {
        setCurrentModel(updatedModel);
        closeEditObjectTypeDialog();
    };

    if (!smallScreen) {
        return <>
            {currentModel &&
                <EditObjectTypeDialog
                    open={showObjectEditTypeDialog}
                    model={currentModel}
                    closeCallback={closeEditObjectTypeDialog}
                    saveCallback={saveSelectedObjectType}/>
            }
            <Card
                key={'card' + uniqueId}
                sx={{
                    width: '230px',
                    minWidth: '230px',
                    maxWidth: '230px',
                    height: '250px',
                    minHeight: '250px',
                    maxHeight: '250px',
                    border: getBorderSettings(),
                    transform: 'scale(0.85)',
                }}
                raised={raised}
                draggable={draggable}
                onDragStart={onDragStart}
                onClick={onClick}
                data-testid={"file-grid-item-base-card"}
            >
                {getThumbnail(140)}
                <CardContent
                    sx={{
                        marginTop: '-16px',
                        color: 'white',
                    }}
                    data-testid={"file-grid-item-base-card-content"}
                >
                    <Box sx={{position: 'relative', top: '-136px', left: '-10px'}}>
                        {checkBox}
                    </Box>
                    <HtmlTooltip title={getTooltipContent()} data-testid={"file-grid-item-base-card-tooltip"}>
                        <Typography
                            variant="body2"
                            component={"p"}
                            mb={1}
                            mt={-3}
                            ml={1}
                            noWrap
                            sx={{
                                color: getTextColor(),
                            }}
                        >
                            {title}
                        </Typography>
                    </HtmlTooltip>
                    <Box sx={{cursor: getCursorSettings()}}
                         onClick={() => {
                             isZipFile && setShowObjectTypeDialog(true);
                         }}
                    >
                        <FileObjectType file={currentModel}
                                        iconCallback={getObjectTypeIcon}/>
                    </Box>
                </CardContent>
            </Card>
        </>;
    } else {
        {
            /*
                Render the view, adapted to small screens, by limiting the thumbnail size
                and the width of the text-container
            */
        }
        return <>
            {currentModel && <EditObjectTypeDialog open={showObjectEditTypeDialog}
                                                   model={currentModel}
                                                   closeCallback={closeEditObjectTypeDialog}
                                                   saveCallback={saveSelectedObjectType}/>}
            <Stack
                direction={"row"}
                sx={{
                    border: getBorderSettings(),
                    maxWidth: '330px',
                    overflow: 'hidden',
                }}
                key={`file-grid-item-${uniqueId}`}>
                {checkBox}
                <Box
                    sx={{
                        maxHeight: '50px',
                        maxWidth: '50px',
                        border: 'solid 1px #ddd',
                        borderRadius: '3px'
                    }}
                    onClick={() => {
                        isZipFile && setShowObjectTypeDialog(true);
                    }}>
                    {getThumbnail(50)}
                </Box>
                <Stack direction={"column"}>
                    <Typography variant={"caption"}
                                sx={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    paddingRight: '16px',
                                    paddingLeft: '16px',
                                    wordWrap: 'anywhere',
                                    wordBreak: 'break-all',
                                    maxWidth: '200px',
                                    color: getTextColor()
                                }}>
                        {title}
                    </Typography>
                    <FileObjectType file={currentModel} iconCallback={getObjectTypeIcon}/>
                </Stack>
            </Stack>
        </>;
    }
};
