import React, {useEffect, useState} from "react";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {useField, useFormikContext} from "formik";
import {useFolderSearch} from "../folder/useFolderSearch";
import {useFoldersState} from "../folder/FoldersContext";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";


const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
const checkedIcon = <CheckBoxIcon fontSize="small"/>;

/**
 * Autocomplete with multiple-select functionality, that displays a list of projects
 * that matches the specified project type.
 * @param formikKey
 * @param label
 * @param placeholder
 * @param museumId
 * @param projectType str   The type of project to include in the list, if not specified, all types are included.
 * @param projectId int     If specified, project matching this ID is removed from the autocomplete list.
 * @returns {Element}
 * @constructor
 */
export const FormikSelectProjects = ({
                                         formikKey,
                                         label,
                                         placeholder,
                                         museumId,
                                         projectType = null,
                                         projectId = null
                                     }) => {
    const [field] = useField(formikKey);

    const {setFieldValue} = useFormikContext();

    const {sort, results} = useFoldersState();

    const [options, setOptions] = useState([]);

    /**
     * Hook used to search for projects that matches the specified "folderType"/project types,
     * related to the specified museum.
     */
    useFolderSearch({
        query: "*",
        start: 0,
        rows: 1000,
        sort: sort,
        folderType: projectType !== null ? `(${projectType})` : "(ProjectArcheology ProjectGeneral)",
        museums: [museumId],
        searching: true
    });

    /**
     * Hook used to map the list of projects to input options used by the autocomplete control.
     */
    useEffect(() => {
        if (!museumId || !results) {
            return;
        }

        if (!results.models) {
            return;
        }

        let mappedResults = results['models'].map(r => {
            return {
                uniqueId: r.uniqueId,
                id: r.id,
                projectType: r.folderType,
                title: r.title
            };
        });

        if (projectId) {
            mappedResults = mappedResults.filter(mr => (mr.id !== projectId));
        }

        setOptions(mappedResults);
    }, [results, museumId, projectId]);

    return (
        <Autocomplete
            multiple
            id="project-responsible"
            options={options}
            defaultValue={field.value}
            disableCloseOnSelect
            onChange={(event, newOpts) => {
                setFieldValue(field.name, newOpts).then();
            }}
            getOptionLabel={(option) => option.title}
            renderOption={(props, option, {selected}) => {
                const liProps = {...props, key: option.id};
                return <li {...liProps} >
                    <Checkbox
                        key={option.id}
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{marginRight: 8}}
                        checked={selected}
                    />
                    {option.title}
                </li>;
            }}
            style={{width: 500}}
            renderInput={(params) => (
                <TextField {...params} label={label} placeholder={placeholder} variant={"standard"}/>
            )}
        />
    );
};