import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import Typography from "@mui/material/Typography";
import React from "react";
import {
    EDIT_MODE,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
    useSearchResultDetailsTranslation
} from "../search-result-details/SearchResultDetailsContext";
import {useAuthsState} from "../auths/authsContext";
import {useCanEditInCollections} from "../auths/useCanEditInCollections";

/**
 * Edit button, displayed on the object view page. Displays the edit object form.
 * @param showButtonLabel
 * @returns {JSX.Element}
 * @constructor
 */
export const EditButton = ({showButtonLabel}) => {
    const t = useSearchResultDetailsTranslation();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();

    const {model} = useSearchResultDetailsState();
    const {damsId} = useAuthsState();

    const adminOfDocument = useCanEditInCollections({
        collectionIds: [model.collectionId],
    });

    const isMine = model['createdById'] === damsId;

    const handleEditClick = () => {
        searchResultDetailsDispatch({type: EDIT_MODE});
    };

    return (
        <IconButton
            color={"secondary"}
            onClick={handleEditClick}
            disabled={!isMine && !adminOfDocument}
            size="small"
        >
            <EditIcon/>
            {showButtonLabel && (
                <Typography variant={"caption"} sx={{marginLeft: 1}}>
                    {t("bottomActionBarEdit", "Rediger")}
                </Typography>
            )}
        </IconButton>);
}