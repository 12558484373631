import camelcaseKeysDeep from "camelcase-keys-deep";
import {kitFetch} from "@ekultur/fetch";
import {clientLog} from "../clientLog";

const ekulturFetch = (url, options) => {
    const ekulturUrl = window._env_.REACT_APP_API_GATEWAY
    return kitFetch(`${ekulturUrl}${url}`, options)
        .then(json => Promise.resolve(camelcaseKeysDeep(json)))
};

export const getUserEmails = async (museumId) => {
    try {
        const url = `/auths/cura/museums/${museumId}/applications/${window._env_.REACT_APP_ID}/`;
        const users = await ekulturFetch(url);
        return await users.map(u => {
            return {name: u.name, email: u.username};
        });
    } catch (e) {
        clientLog('error', e, 'eKulturFetch');
        return [];
    }
};
