import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSearchState } from "../SearchContext";
import { FilterDate } from "./FilterDate";
import { AccordionFilter } from "../AccordionFilter";
import { dateToEndOfDay, dateToStartOfDay } from "../../utility";
import Box from "@mui/material/Box";

function getPropertyNameFromColumnName(columnName) {
    switch (columnName) {
        case "produced_at":
            return "productionDate";
        case "created_at":
            return "createdAt";
        case "updated_at":
            return "updatedAt";
        default:
            return "createdAt";
    }
}

export const AccordionFilterDate = ({
    columnName,
    accordionTitle,
    callback,
    outerLimits,
}) => {
    const { results, fq, clearFilters } = useSearchState();
    const key = columnName;

    const [selected, setSelected] = useState([]);

    const alreadyFiltered = fq.findIndex((f) => f.key === key) !== -1;

    const statsDate = results.stats[getPropertyNameFromColumnName(columnName)];

    let minDate = useRef(dateToStartOfDay(new Date(statsDate.min)));
    let maxDate = useRef(dateToEndOfDay(new Date(statsDate.max)));

    const storageKey = 'dams.filter.minMaxDate["' + columnName + '"]';

    const saveMinMax = (minD, maxD) => {
        if (clearFilters) {
            return;
        }
        localStorage.setItem(storageKey, JSON.stringify({ min: minD, max: maxD }));
    };

    useEffect(() => {
        saveMinMax(minDate, maxDate);
    }, []);

    const changeHandler = ({ from = "*", to = "*" }) => {
        modifySelectedFilter({ from: from, to: to });
    };

    const getFqValues = useCallback(() => {
        let fqIx = fq.findIndex((f) => f.key === key);
        let values = fq[fqIx].value;
        values = values.substr(1, values.length - 2).split(" TO ");
        return { from: values[0], to: values[1] };
    }, [fq, key]);

    const modifySelectedFilter = (value) => {
        let selectedFilters = selected;
        let fk = key === "produced_at" ? "production_date" : key;
        let ix = selectedFilters.findIndex((f) => f.key === fk);

        if (alreadyFiltered) {
            // Attempt to get filter value from previous search.
            ix = fq.findIndex((f) => f.key === fk);
        }

        if (ix === -1) {
            selectedFilters.push({ key: fk, value: value });
        } else {
            if (alreadyFiltered) {
                const fqValues = getFqValues();
                value.to = value.to === "*" ? fqValues.to : value.to;
                value.from = value.from === "*" ? fqValues.from : value.from;
            }
            selectedFilters[ix] = { key: fk, value: value };
        }
        callback(fk, selectedFilters);
    };

    const resetFilterValue = () => {
        let savedMinMax = localStorage.getItem(storageKey);
        if (savedMinMax && savedMinMax !== '') {
            savedMinMax = JSON.parse(savedMinMax);
            minDate.current = savedMinMax.min.current;
            maxDate.current = savedMinMax.max.current;
        }
    }

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(key, []);
        }
    }, [clearFilters, key]);

    useEffect(() => {
        if (alreadyFiltered && !clearFilters) {
            const fqValues = getFqValues();
            minDate.current = new Date(fqValues.from).getTime();
            maxDate.current = new Date(fqValues.to).getTime();
        } else if (clearFilters) {
            resetFilterValue();
        }
    }, [alreadyFiltered, getFqValues, clearFilters]);

    const count = Object.keys(statsDate).length;
    if (!count || !outerLimits) {
        return null;
    }

    return (
        <Box sx={{ marginBottom: '.5rem' }}>
            <AccordionFilter title={accordionTitle}>
                <FilterDate
                    min={minDate.current}
                    max={maxDate.current}
                    onFilter={changeHandler}
                />
            </AccordionFilter>
        </Box>
    );
};
