import {Box, Button} from "@mui/material";
import {CLEAR_ARCHEOLOGY_PROJECT_READ, useProjectDispatch, useProjectTranslation} from "../../projectContext";

/**
 * Renders a component with two buttons for action in an archaeology project wizard.
 *
 * @param {Object} props - The properties passed to the component.
 * @param {Function} props.continueCallback - The callback function to be called when the continue button is clicked.
 * @param {string} props.continueLabel - The label to be displayed on the continue button.
 * @return {JSX.Element} A Box component containing two Button components.
 */
export const ArcheologyProjectActionButtons = ({continueCallback, continueLabel}) => {
    const t = useProjectTranslation();
    const projectDispatch = useProjectDispatch();

    return <Box
        sx={{
            display: "flex",
            justifyContent: "right",
            marginTop: '32px',
        }}
    >
        <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={() => {
                projectDispatch({
                    type: CLEAR_ARCHEOLOGY_PROJECT_READ,
                });
            }}
        >
            {t('btnGoBack', 'Gå tilbake')}
        </Button>
        <Button
            variant={"contained"}
            color={"secondary"}
            sx={{marginLeft: '16px'}}
            onClick={continueCallback}
        >
            {continueLabel}
        </Button>
    </Box>
};