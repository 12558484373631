import React, {useEffect, useState} from "react";
import {ObjectMediaImage} from "./ObjectMediaImage";
import {PDFViewer} from "../pdf-viewer/PdfViewer";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";

export const ObjectMediaPdf = ({parentRef, smallScreen, pdfOpenedCallback}) => {
    const {model, fullscreen} = useSearchResultDetailsState();
    const [showPDF, setShowPDF] = useState(false);

    useEffect(() => {
        pdfOpenedCallback(showPDF);
    }, [showPDF])

    return (
        <>
            <ObjectMediaImage
                parentRef={parentRef}
                onClick={() => {
                    setShowPDF(true);
                }}
                smallScreen={smallScreen}
            />
            {(showPDF || (showPDF && fullscreen)) && (
                <PDFViewer
                    model={model}
                    fullscreen={true}
                    closeCallback={() => {
                        setShowPDF(false);
                    }}
                />
            )}
        </>
    );
};
