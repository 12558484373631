import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useMyMuseums} from "./useMyMuseums";
import {damsFetch} from "../app/damsFetch";
import {useMuseumTranslation} from "./MuseumContext";
import {useSharedFolderMuseums} from "./useSharedFolderMuseums";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import {useAuthsState} from "../auths/authsContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../snackbar/SnackbarContext";
import ReportIcon from "@mui/icons-material/Report";
import {useDmsStatusState} from "../dmsstatus/DmsStatusContext";
import useDeepCompareEffect from "use-deep-compare-effect";

const PREFIX = "CollectionSelector";

const classes = {
    root: `${PREFIX}-root`,
    paper: `${PREFIX}-paper`,
    collectionSelector: `${PREFIX}-collectionSelector`,
};

const Root = styled("div")(({theme}) => ({
    [`&.${classes.root}`]: {
        display: "flex",
        justifyContent: "center",
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(2),
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            minWidth: 400,
        },
    },

    [`& .${classes.collectionSelector}`]: {
        display: "flex",
        alignItems: "center",
    },
}));

export const CollectionSelector = ({
                                       onSelect,
                                       onlyAdministrativeMuseums = false,
                                       menuOpenCallback = null,
                                       fileUpload = false,
                                   }) => {
    const {userData} = useAuthsState();
    const {dmsStatus} = useDmsStatusState();

    const username = userData?.name;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [hideSelector, setHideSelector] = useState(true);
    const [collectionId, setCollectionId] = useState(0);
    const [museum, setMuseum] = useState(-1);
    const [dmsUnreachable, setDmsUnreachable] = useState(false);
    const myMuseums = useMyMuseums({onlyAdministrativeMuseums});
    const sharedFolderMuseums = useSharedFolderMuseums();
    const t = useMuseumTranslation();

    const [accessibleMuseums, setAccessibleMuseums] = useState([]);

    const snackbarDispatch = useSnackbarDispatch();

    const storageKey = "dams.upload.default.collectionId";

    const setDefault = (cId) => {
        window.localStorage.setItem(storageKey, cId);
    };

    const getDefault = () => {
        return parseInt(window.localStorage.getItem(storageKey));
    };

    const showDmsError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: "DAMS feil - kontakt support",
                body: "Filopplasting ikke tilgjengelig. Kan ikke nå lagringsområdet.",
                type: "error",
            },
        });
    };

    useEffect(() => {
        if (username) {
            damsFetch("/current-user/", {
                method: "POST",
                body: JSON.stringify({name: username}),
            }).then(null);
        }
    }, [username]);

    useEffect(() => {
        menuOpenCallback && menuOpenCallback(anchorEl !== null);
    }, [anchorEl, menuOpenCallback]);

    const invalidateAllMenuItems = (museumsList) => {
        return museumsList.map((m) => {
            return {...m, incorrectconfig: true};
        });
    };

    const invalidateMenuItemsWithInvalidConfig = (museumsList) => {
        return museumsList.map((am) => {
            const incorrect = dmsStatus['missingConfigs'].includes(am['ekultur_id']);
            return {
                ...am,
                incorrectConfig: incorrect,
            };
        });
    };

    useDeepCompareEffect(() => {
        if (!dmsStatus || !dmsStatus['missingConfigs']) {
            return;
        }

        let museumsList;
        if (myMuseums.length === 0 && sharedFolderMuseums.length > 0) {
            // Handle museums and collections which a user,
            // with no associated museum, is granted write access to.
            museumsList = sharedFolderMuseums;
        } else {
            museumsList = myMuseums;
        }

        if (dmsStatus.errorCount > 0) {
            museumsList = invalidateAllMenuItems(museumsList);
            setDmsUnreachable(true);
            showDmsError();
        } else {
            museumsList = invalidateMenuItemsWithInvalidConfig(museumsList);
        }
        setAccessibleMuseums(museumsList);
    }, [sharedFolderMuseums, myMuseums, dmsStatus]);

    const handleChoose = () => {
        onSelect(collectionId);
    };

    useDeepCompareEffect(() => {
        // Logged on user: museum => ID
        // Shared folder: museum => { name: ..., id: ..., collectionId:...}

        // Selects the 1st available museum:
        // - the 1st museum with valid config:
        const museumId =
            accessibleMuseums.filter((am) => !am.incorrectConfig)[0]?.id ||
            accessibleMuseums[0];

        if (accessibleMuseums.length > 1) {
            setHideSelector(false);
            const defaultId = getDefault();
            const m = accessibleMuseums.find(
                (a) => a.id === defaultId && !a.incorrectConfig
            );
            if (fileUpload && m) {
                setMuseum(defaultId);
            } else {
                setMuseum(museumId);
            }
        } else if (accessibleMuseums.length === 1) {
            setMuseum(museumId);
            setHideSelector(false);
        }
    }, [accessibleMuseums, fileUpload]);

    /**
     * Hook run if the file-upload area was accessed by a contributing user that has been granted
     * "upload access" to a specific folder within a specific museum.
     */
    useEffect(() => {
        if (museum) {
            setCollectionId(0);
            if (sharedFolderMuseums.length > 0) {
                setCollectionId(
                    sharedFolderMuseums.find((m) => m.id === museum).collectionId
                );
            } else {
                if (museum === -1) {
                    return;
                }

                const searchParams = new URLSearchParams({
                    id: museum,
                });

                damsFetch(`/collections/?${searchParams.toString()}`).then((json) => {
                    setCollectionId(json.length ? json[0].id : 0);
                });
            }
        }
    }, [museum, sharedFolderMuseums]);

    useDeepCompareEffect(() => {
        if (accessibleMuseums.length === 1 && 0 !== collectionId) {
            handleChoose();
        }
    }, [collectionId, accessibleMuseums]);

    if (hideSelector) {
        return null;
    }

    const handleClose = (_e) => {
        setAnchorEl(null);
    };

    const handleSelect = (value) => {
        setMuseum(value);
        setDefault(value);
        handleClose();
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const getCurrentMuseum = () => {
        return museum
            ? accessibleMuseums.find(
                (myMuseum) => myMuseum.id === museum?.id || myMuseum.id === museum
            ).name
            : "";
    };

    const renderMenuItems = () => {
        return accessibleMuseums.map((myMuseum) => {
            const {id, name} = myMuseum;
            return (
                <MenuItem
                    value={id}
                    key={id}
                    onClick={() => handleSelect(id)}
                    disabled={myMuseum?.incorrectConfig}
                >
                    {name}

                    {myMuseum?.incorrectConfig && (
                        <ReportIcon
                            sx={{
                                marginRight: 1,
                                color: "red",
                            }}
                        />
                    )}
                </MenuItem>
            );
        });
    };

    return (
        <Root className={classes.root}>
            <Paper className={classes.paper} elevation={3}>
                <div className={classes.collectionSelector}>
                    <Typography>{t("chooseMuseum", "Velg museum")}:</Typography>
                    <Button
                        aria-controls="museum-selector"
                        aria-haspopup="true"
                        onClick={handleClick}
                        color={"secondary"}
                    >
                        {getCurrentMuseum()}
                    </Button>
                    <Menu
                        id="museum-selector"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {renderMenuItems()}
                    </Menu>
                </div>
                <Box display={"flex"}>
                    <Box flexGrow={1}/>
                    <Button
                        color={"secondary"}
                        variant={"contained"}
                        disabled={0 === collectionId || dmsUnreachable}
                        onClick={handleChoose}
                    >
                        {t("chooseMuseumButton", "Velg")}
                    </Button>
                </Box>
            </Paper>
        </Root>
    );
};
