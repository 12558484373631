import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useField, useFormikContext} from "formik";
import {useDocumentTranslation} from "../documents/documentContext";
import {damsFetch} from "../app/damsFetch";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {getBrowserLocale} from "../utility";

const PREFIX = "InputLanguages";

const classes = {
    root: `${PREFIX}-root`,
    icon: `${PREFIX}-icon`,
};

const Root = styled("div")(({theme}) => ({
    [`& .${classes.root}`]: {},

    [`& .${classes.icon}`]: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export const InputLanguages = ({
                                   formikKey,
                                   label,
                                   disabled = false,
                                   size = null,
                                   helperText = null,
                               }) => {
    const t = useDocumentTranslation();

    const [field, meta] = useField(formikKey);
    const [options, setOptions] = useState([]);
    const [searching, setSearching] = useState();
    const [languages, setLanguages] = useState([]);

    const {setFieldValue} = useFormikContext();

    const locale = getBrowserLocale(true);

    useEffect(() => {
        damsFetch("/languages", {method: "GET"}).then((json) =>
            setLanguages(json)
        );
    }, []);

    const languagesSearchHandler = (params) => {
        function capitalize(name) {
            return name.charAt(0).toUpperCase() + name.slice(1);
        }

        const langs = [...languages]
            .filter(l => l.names[locale])
            .map(l => ({title: capitalize(l.names[locale]), value: l.uniqueId}));

        const matches = langs.filter(r => r.title.toLowerCase().includes(params.toLowerCase()));

        setOptions(matches);
        setSearching(false);
    };

    const changeHandler = (_event, values) => {
        setFieldValue(field.name, []).then();
        if (values.length > 0) {
            setFieldValue(field.name, values).then();
        }
    };

    if (undefined === meta.initialValue) {
        return null;
    } else {
        return (
            <Root>
                <Autocomplete
                    id="languages"
                    disabled={disabled}
                    multiple={true}
                    className={classes.root}
                    loading={Boolean(searching)}
                    loadingText={t("searchingPlaceholder", "Søker...")}
                    value={field.value}
                    getOptionLabel={(option) => {
                        return option.title || "";
                    }}
                    options={options}
                    noOptionsText={t("txtNoMatches", "Ingen treff")}
                    onChange={changeHandler}
                    onInputChange={(_event, newInputValue) => {
                        languagesSearchHandler(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            size={size}
                            helperText={helperText}
                        />
                    )}
                    renderOption={(props, option, _state) => {
                        return (
                            <Grid container alignItems="center" {...props} key={option.value}>
                                <Grid xs>
                                    <Typography variant="body2" color="textSecondary">
                                        {option.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        );
                    }}
                />
            </Root>
        );
    }
};
