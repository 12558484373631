import React, {useState} from "react";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsFetch} from "../app/damsFetch";
import {RelatedObjectCard} from "./RelatedObjectCard";
import {fetchRelationDocs} from "./FetchRelationDocs";
import {
    useSearchResultDetailsState,
    useSearchResultDetailsTranslation,
} from "../search-result-details/SearchResultDetailsContext";
import {LinearProgress, Stack} from "@mui/material";
import Typography from "@mui/material/Typography";
import useDeepCompareEffect from "use-deep-compare-effect";
import {clientLog} from "../clientLog";
import Box from "@mui/material/Box";

export const PreviewRelated = () => {
    const [relatedObjects, setRelatedObjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const t = useSearchResultDetailsTranslation();
    const {objectRelations} = useSearchResultDetailsState();

    useDeepCompareEffect(() => {
        const sourceIds = objectRelations.map((r) => r.reference.sourceId);

        if (sourceIds.length === 0) {
            setLoading(false);
            return;
        }

        const mappedObjectRelations = objectRelations.filter(
            (r) => typeof r.reference.uniqueId !== "undefined"
        );

        const relationIds = mappedObjectRelations.map((r) => r.reference.uniqueId);

        // Method used to fetch source documents (images) and determine possible parents.
        const sourcesCallback = (mappedRelations, sIds) => {
            const query = "unique_id: (" + sIds.join(" OR ") + ")";
            const sort = "updated_at desc";
            const searchParams = new URLSearchParams(
                decamelizeKeysDeep({
                    q: query,
                    sort: sort,
                    fl: "*",
                    fq: ['status:("published")'],
                    start: 0,
                    rows: 100,
                    documentType: `(StillImage OR Video OR Audio OR Misc)`,
                    museums: [],
                    onlyMine: false,
                    onlyOthers: false,
                })
            );

            damsFetch(`/search?${searchParams.toString()}`)
                .then((json) => {
                    function createParent(m) {
                        return {
                            ...m,
                            relationType: "Inngår i",
                            relationDescription: "",
                        };
                    }

                    const mappedModels = json.models.map((m) => {
                        if (mappedRelations) {
                            // Child relation
                            const childRelation = mappedRelations.find((r) => {
                                return (
                                    r.reference.sourceId === m.uniqueId &&
                                    r.relationType &&
                                    r.relationDescription
                                );
                            });

                            if (childRelation) {
                                return {
                                    ...m,
                                    relationType: childRelation.relationType,
                                    relationDescription: childRelation.relationDescription,
                                };
                            } else {
                                return createParent(m);
                            }
                        }
                        return createParent(m);
                    });

                    setRelatedObjects(mappedModels);
                    setLoading(false);
                })
                .catch((error) => {
                    clientLog('error', error, 'PreviewRelated');
                    setLoading(false);
                });
        };

        if (relationIds.length > 0) {
            // Fetching relation documents (Children)
            fetchRelationDocs(relationIds, sourcesCallback).then((relationDocs) => {
                const mappedRelations = objectRelations.map((r) => {
                    return {
                        ...r,
                        relationType: relationDocs.find((f) => {
                            return r.reference.uniqueId === f.uniqueId;
                        }).relationType,
                        relationDescription: relationDocs.find((f) => {
                            return r.reference.uniqueId === f.uniqueId;
                        }).relationDescription,
                    };
                });
                sourcesCallback(mappedRelations, sourceIds);
            });
        } else {
            // Only parents exists, fetch them.
            sourcesCallback(null, sourceIds);
        }
    }, [objectRelations]);

    const getRelatedObjectCard = () => {
        return relatedObjects.map((r) => (
            <RelatedObjectCard model={r} key={r.uniqueId}/>
        ));
    };

    return (
        <>
            {loading && (
                <Stack
                    direction={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    sx={{marginTop: "40px"}}
                >
                    <LinearProgress color={"secondary"} sx={{width: "100%"}}/>
                    <Typography mt={1}>
                        {t("txtLoadingRelatedObjects", "Henter relaterte objekter")}
                    </Typography>
                </Stack>
            )}

            {!loading && relatedObjects.length > 0 && getRelatedObjectCard()}
            {!loading && relatedObjects.length === 0 &&
                <Box sx={{width: '100%', textAlign: 'center'}}>
                    <Typography variant={"body2"}>
                        {t('noRelatedObjects', 'Ingen relaterte objekter')}
                    </Typography>
                </Box>
            }
        </>
    );
};
