import React from "react";
import { DocumentProvider } from "../documents/documentContext";
import { FileActions } from "./FileActions";
import { NewFilesView } from "./NewFilesView";
import { DmsProvider } from "../dms/dmsContext";
import { MyFoldersProvider } from "../folder/MyFoldersContext";

export const NewFilesPage = ({ children }) => {
  return (
    <NewFilesView>
      <MyFoldersProvider>
        <DocumentProvider>
          <DmsProvider>
            {children}
            <FileActions />
          </DmsProvider>
        </DocumentProvider>
      </MyFoldersProvider>
    </NewFilesView>
  );
};
