import React from "react";
import {styled} from "@mui/material/styles";
import Typography from "@mui/material/Typography";

const PREFIX = "ModalCreateResourceHeader";

const classes = {
    title: `${PREFIX}-title`,
};

const StyledTypography = styled(Typography)(({theme}) => ({
    [`&.${classes.title}`]: {
        marginBottom: theme.spacing(2),
    },
}));

export const ModalCreateResourceHeader = ({title}) => {
    return title && (
        <StyledTypography variant={"h6"} component={"h2"} className={classes.title}>
            {title}
        </StyledTypography>
    );
};
