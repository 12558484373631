import { Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import MovieIcon from "@mui/icons-material/Movie";
import ImageIcon from "@mui/icons-material/Image";
import { useSearchResultDetailsTranslation } from "../search-result-details/SearchResultDetailsContext";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  imageThumbnail: {
    height: "100px",
    width: "100px",
    maxWidth: "100px",
    maxHeight: "100px",
    background: "#eeeeee",
    objectFit: "cover",
  },
  objectTypeIcon: {
    position: "relative",
    bottom: 0,
    right: "24px",
    zIndex: 99,
    background: "#fff",
    color: "#666",
    opacity: 0.7,
    borderRadius: "3px 0 0 0",
  },
  iconThumbNail: {
    fontSize: 60,
    color: "#bbb",
    background: "#eee",
    width: "100px !important",
    minWidth: "100px",
    minHeight: "100px",
  },
}));

/**
 * Displays a thumbnail, based on the model.documentType in square,
 * with an overlay icon that symbolizes the type of document.
 * @param model object  The model
 * @returns {JSX.Element}
 * @constructor
 */
export const SquareThumbWithDocTypeOverlay = ({ model }) => {
  const classes = useStyles();
  const t = useSearchResultDetailsTranslation();

  const [imageUrl, setImageUrl] = useState();

  const documentType = model.documentType;
  const mimeType = model.mimeType;

  /**
   * Returns the object's thumbnail.
   * @returns {JSX.Element}
   */
  const getThumbnail = () => {
    if (documentType !== "Audio" && documentType !== "Misc") {
      return (
        <LazyLoadImage
          alt={model.title}
          title={model.title}
          effect={"blur"}
          src={imageUrl}
          className={classes.imageThumbnail}
        />
      );
    } else if (documentType === "Audio") {
      return <VolumeUpOutlinedIcon className={classes.iconThumbNail} />;
    } else if (documentType === "Misc" && mimeType !== "pdf") {
      return <DescriptionIcon className={classes.iconThumbNail} />;
    }
  };

  /**
   * Returns the objects types icons, laid on top of the object's thumbnail.
   * @returns {JSX.Element}
   */
  const getObjectTypeIcon = () => {
    if (documentType === "Audio") {
      return <VolumeUpOutlinedIcon className={classes.objectTypeIcon} />;
    } else if (documentType === "Misc" && mimeType !== "pdf") {
      return (
        <DescriptionIcon
          className={classes.objectTypeIcon}
          aria-label={t("txtDocument", "Dokument")}
        />
      );
    } else if (documentType === "Video") {
      return (
        <MovieIcon
          className={classes.objectTypeIcon}
          aria-label={t("txtVideo", "Video")}
        />
      );
    } else if (documentType === "StillImage") {
      return (
        <ImageIcon
          className={classes.objectTypeIcon}
          aria-label={t("txtImage", "Bilde")}
        />
      );
    }
  };

  /**
   * Hook used to load the thumbnail image for StillImage and Video objects.
   */
  useEffect(() => {
    if (model.imageUrl) {
      fetch(model.imageUrl + "&dimension=128x128", {
        headers: {
          "EKULTUR-TOKEN": localStorage.getItem("kit.access_token"),
        },
      })
        .then((res) => res.blob())
        .then((blob) => {
          setImageUrl(URL.createObjectURL(blob));
        })
        .catch(() => {
          setImageUrl("/no-thumbnail.jpg");
        });
    } else {
      setImageUrl("/no-thumbnail.jpg");
    }
  }, [model.imageUrl]);

  return (
    <Stack direction="row" sx={{ width: "100px" }}>
      {getThumbnail()}
      {getObjectTypeIcon()}
    </Stack>
  );
};
