import React from "react";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

export const VideoPlayOverlayIcon = ({size = 40, onClick = null}) => {
    return (
        <PlayCircleOutlineIcon
            style={{width: size, height: size, top: "50%"}}
            sx={{
                color: "white",
                position: "absolute",
                right: "50%",
                transform: "translate(50%, -50%)",
                cursor: "pointer",
            }}
            onClick={onClick}
        />
    );
};
