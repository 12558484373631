import {getUserEmails} from "../share-model/ekulturFetch";
import {useEffect, useState} from "react";
import {Box, Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText,} from "@mui/material";
import {PROJECT_ADD_ACLS, useProjectDispatch} from "./projectContext";
import {useAuthsState} from "../auths/authsContext";

/**
 * Renders a component for selecting users with checkboxes.
 *
 * @param {string} museumId - The ID of the museum for which users are being selected.
 * @return {JSX.Element} A component with a list of users and checkboxes for selection.
 */
export const SelectMuseumUsers = ({museumId}) => {
    const projectDispatch = useProjectDispatch();
    const {userData} = useAuthsState();
    const [selected, setSelected] = useState([]);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        if (!museumId) {
            return;
        }
        getUserEmails(museumId).then((data) => {
            setUsers(data.filter((u) => u.email !== userData.email));
        });
    }, []);

    const handleToggle = (value) => {
        const ix = selected.indexOf(value);
        const newSelected = [...selected];
        if (ix === -1) {
            newSelected.push(value);
        } else {
            newSelected.splice(ix, 1);
        }
        setSelected(newSelected);

        projectDispatch({
            type: PROJECT_ADD_ACLS,
            acls: newSelected,
        });
    };

    return (
        <Box
            sx={{
                height: "70vh",
                maxHeight: "70vh",
                overflowY: "auto",
                scrollbarWidth: "thin",
                // scrollbarColor: `${theme.palette.secondary.main} ${theme.palette.grey[300]}`,

                overflowX: "hidden",
                paddingRight: '24px',
                "&::-webkit-scrollbar": {
                    width: 8,
                },
                "&::-webkit-scrollbar-track": {
                    //  background: theme.palette.grey[300],
                },
                "&::-webkit-scrollbar-thumb": {
//                    background: theme.palette.secondary.main,
                    borderRadius: 20,
                },
                "&::-webkit-scrollbar-thumb:hover": {
//                    background: theme.palette.secondary.dark,
                },
            }}
        >
            <List>
                {users.map((u) => {
                    const {name, email} = u;
                    const labelId =
                        "checkbox-list-label-" + crypto.randomUUID();
                    return (
                        <ListItem
                            key={crypto.randomUUID()}
                            disablePadding={true}
                        >
                            <ListItemButton
                                role={undefined}
                                dense
                                onClick={() => handleToggle(email)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        edge="start"
                                        checked={selected.indexOf(email) !== -1}
                                        disableRipple
                                        tabIndex={-1}
                                        inputProps={{"aria-labelledby": labelId}}
                                    />
                                </ListItemIcon>
                                <ListItemText
                                    id={labelId}
                                    primary={name}
                                    secondary={email}
                                />
                            </ListItemButton>
                        </ListItem>
                    );
                })}
            </List>
        </Box>
    );
};
