import {useEffect, useState} from "react";
import {extractMediaMeta} from "./dmsSecurity";
import {dateFormatter} from "../utility";

export const useDmsFileMetadata = ({collectionId, dmsId, model = null, ready = true}) => {
    const [metadata, setMetadata] = useState({});

    useEffect(() => {
        let skip = false;
        if (model) {
            // Skip fetching metadata for .glb files.
            const f = model.content.mediae[0].reference.title;
            const fileType = f.substr(f.lastIndexOf(".") + 1);
            skip = fileType.toLowerCase() === 'glb';
        }

        if (collectionId && dmsId && ready && !skip) {
            extractMediaMeta(collectionId, dmsId)
                .then(json => {
                    const parsed = dateFormatter(json[0]);
                    setMetadata(parsed);
                })
                .catch(e => console.error(e));
        }
    }, [collectionId, dmsId, ready, model]);

    return metadata
}
