import React, {useEffect, useState} from "react";
import {dataArcheologyServerFetch,} from "../../app/kulturnavFetch";
import {useField, useFormikContext} from "formik";
import {useDocumentState,} from "../../documents/documentContext";
import {useAuthsState} from "../../auths/authsContext";
import {CircularProgress, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {useAddDocumentationType} from "./useAddDocumentationType";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {damsSearch} from "../../app/damsFetch";
import {inputWithCollectionIds,} from "../../metadata/inputMapper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {getBrowserLocale} from "../../utility";
import {clientLog} from "../../clientLog";

/**
 * This component renders a list of documentation types, based on data from the local DAMS database
 * and data fetched from data.arkeologi.org.
 * @param formikKey
 * @param label
 * @param disabled
 * @param size
 * @param collectionId
 * @returns {false|JSX.Element}
 * @constructor
 */
export const InputDocumentationType = ({
                                           formikKey,
                                           label,
                                           disabled = false,
                                           size = null,
                                           collectionId = undefined
                                       }) => {
    const [field] = useField(formikKey);
    const {setFieldValue} = useFormikContext();

    const {collectionIds, formData} = useDocumentState();
    const {museumCollections} = useAuthsState();

    const [options, setOptions] = useState([]);

    // eslint-disable-next-line no-unused-vars
    const [chosenDocumentType, setChosenDocumentType] = useState(); // NOSONAR
    const [addingOption, setAddingOption] = useState(false);

    const postDocumentationTypes = useAddDocumentationType();

    const locale = getBrowserLocale();

    const museums = museumCollections
        .filter((museumCollection) =>
            collectionIds.includes(museumCollection.collectionId)
        )
        .map((museumCollection) => museumCollection.museumId);

    /**
     * Hook used to fetch data from the DAMS database (local data) and from data.arkeologi.org
     * and create the list of options.
     */
    useEffect(() => {
        // Fetch already stored documentation types from the database
        const searchParams = new URLSearchParams(
            decamelizeKeysDeep({
                q: `*`,
                fl: "title,id",
                sort: "title asc",
                expand: true,
                documentType: `("documentationType")`,
                museums: museums.join(","),
            })
        );

        Promise.all([
            damsSearch(searchParams).then((json) => {
                const dbDocumentTypes = inputWithCollectionIds(json['models']);
                return Promise.resolve(dbDocumentTypes);
            }),

            // Fetch terms from data.arkeologi.org
            // https://data.arkeologi.org/api/core/entity.dataset:1d720383-20b0-403e-a948-fed51178961b/0/50?properties=concept.narrower&displayValues=true

            dataArcheologyServerFetch(
                `core/entity.dataset:1d720383-20b0-403e-a948-fed51178961b/0/50?properties=concept.narrower&displayValues=true`
            )
                .then((json) => (createDbOptions(json)))
                .catch((error) => {
                    clientLog('error', error, 'InputDocumentationType');
                    return Promise.resolve([]);
                }),
        ]).then(([db, server]) => {
            mergeDbAndServerOptions(db, server);
        });
    }, []);

    /**
     * Creates a list of option-objects based on the passed "data" argument.
     * @param data  Array   Array of objects fetched from data.arkeologi.org.
     * @returns {Promise<*[]>}
     */
    const createDbOptions = (data) => {
        const output = [];
        data.entities.forEach((e) => {
            const optTitle = e.name[locale] || e.name['*'];
            const sourceId = e.uuid;
            output.push({
                title: optTitle,
                sourceId: sourceId
            });
        });
        return Promise.resolve(output);
    };

    /**
     * Merges the results fetched from the database and from the archeology server at data.arkeologi.org
     * into a single list of options.
     * @param dbOpts    Array   DAMS results
     * @param server    Array   data.arkeologi.org results
     */
    const mergeDbAndServerOptions = (dbOpts, server) => {
        if (!dbOpts) {
            return;
        }
        dbOpts.forEach((d) => {
            const ix = server.findIndex((s) => s.sourceId === d.sourceId);
            if (ix > -1) {
                server[ix].uniqueId = d.uniqueId;
            }
        });
        setOptions(server);
    };

    const getSelectedDocumentationType = (id) => {
        return options.find((v) => v.sourceId === id);
    };

    const handleChange = (event) => {
        // Lookup documentation type by selected ID
        const documentationType = getSelectedDocumentationType(event.target.value);
        if (documentationType && !documentationType.uniqueId) {
            setAddingOption(true);

            // Add document types as references in the DB.
            documentationType.collectionId = collectionId ? collectionId : formData.collectionId;

            postDocumentationTypes({
                documentationTypes: [documentationType],
            }).then((json) => {
                const selectedOption = options?.find(
                    (o) => o.sourceId === json[0].sourceId
                );

                // Set the selected option (field value).
                selectedOption.uniqueId = json[0].uniqueId;
                setFieldValue(field.name, selectedOption);

                // Set the documentation type
                documentationType.uniqueId = json[0].uniqueId;
                setChosenDocumentType(documentationType);
                setAddingOption(false);
            });
        } else {
            setFieldValue(field.name, documentationType);
            setChosenDocumentType(documentationType);
        }
    };

    return (options.length > 0 &&
        <FormControl>
            <InputLabel htmlFor="grouped-documentation-types">{label}</InputLabel>
            <Box sx={{display: "flex", alignItems: "center"}}>
                <Select
                    disabled={disabled}
                    value={field.value?.sourceId ?? ""}
                    id="grouped-documentation-types"
                    label={label}
                    onChange={handleChange}
                    size={size}
                    sx={{
                        width: "100%",
                    }}
                >
                    {options?.map((dt) => [
                        <MenuItem value={dt.sourceId} key={dt.title}>
                            <Typography variant={"body2"}>{dt.title}</Typography>
                        </MenuItem>,
                    ])}
                </Select>
                {addingOption && (
                    <CircularProgress
                        size={30}
                        sx={{marginLeft: 2}}
                        color={"secondary"}
                    />
                )}
            </Box>
        </FormControl>
    );
};
