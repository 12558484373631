import React, {useEffect, useState} from "react";
import {useFolderSearch} from "./useFolderSearch";
import {FoldersTable} from "./FoldersTable";
import {SET_SEARCHING, useFoldersDispatch, useFoldersState} from "./FoldersContext";
import {ResultTableProvider} from "../search/ResultTableContext";
import {FolderSearchSettings} from "../search/FolderSearchSettings";
import {useMyMuseums} from "../museum/useMyMuseums";

/**
 * Component used to display the list of "All folders".
 * @returns {JSX.Element}
 * @constructor
 */
export const AllFolders = () => {
    const myMuseums = useMyMuseums({});

    const folderSearchSettings = FolderSearchSettings('dams.folderListSearchResults.config');

    const {query, sort, start, fq} = useFoldersState();

    const foldersDispatch = useFoldersDispatch();

    const [ready, setReady] = useState(false);

    useFolderSearch({
        query: query,
        sort: sort,
        start: start,
        rows: folderSearchSettings.getNumberOfRows(),
        documentType: '(Folder)',
        folderType: '(!ProjectArcheology !ProjectGeneral)',
        fq: fq,
        museums: myMuseums.map(m => m.id).join(','),
        status: '(-trash)'
    });

    /**
     * Await initial search until museums have been resolved.
     */
    useEffect(() => {
        if (myMuseums.length === 0 || ready) {
            return;
        }

        setReady(true);
        foldersDispatch({
            type: SET_SEARCHING
        });
    }, [myMuseums, ready, foldersDispatch]);

    return <ResultTableProvider><FoldersTable/></ResultTableProvider>;
};
