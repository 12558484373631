import Box from "@mui/material/Box";
import {Switch} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import {SET_PRIVATE_ONLY, useFoldersDispatch, useFoldersState} from "../FoldersContext";
import {useEffect, useState} from "react";

export const FilterPrivatePublic = ({accordionTitle, callback}) => {

    const foldersDispatch = useFoldersDispatch();

    const {privateOnly} = useFoldersState();

    const [checked, setChecked] = useState(false);

    const [selected, setSelected] = useState([]);

    const handleOnChange = event => {
        foldersDispatch({
            type: SET_PRIVATE_ONLY,
            value: event.target.checked
        });
        setFq(event);
    };


    const setFq = (event) => {
        const filterName = 'privateOnly';
        const selectedValue = event.target.value;
        const filterObj = {key: filterName, value: selectedValue};

        const alreadySelected = [...selected];

        const existsAtIndex = alreadySelected.findIndex(f => f.key === filterName && f.value === selectedValue);

        let selectedFilters;

        if (existsAtIndex > -1) {
            // If filter/value combination already exists - remove it (toggle).
            selectedFilters = alreadySelected.toSpliced(existsAtIndex, 1);
        } else {
            // If the filter/value combination does not exist, add it.
            selectedFilters = [...selected, filterObj];
        }

        setSelected(selectedFilters);
        callback(selectedFilters);
    };

    useEffect(() => {
        setChecked(privateOnly);
    }, [privateOnly]);

    return <Box sx={{marginBottom: '1.5rem', marginLeft: '1.0rem', marginTop: '1.5rem'}}>
        <FormControlLabel
            control={<Switch color="primary" checked={checked} onChange={handleOnChange}/>}
            label={accordionTitle}
            labelPlacement="end"
            disabled={privateOnly}
        />
    </Box>;
};