import React from "react";
import {PREVIEW_MODE, useSearchResultDetailsDispatch,} from "../search-result-details/SearchResultDetailsContext";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

/**
 * Component that renders the close-button in the upper right corner of the object view dialog.
 * @param editMode  bool    Is the dialog in edit-mode?
 * @param closeDialogCallback   function    Callback used when the dialog is not in edit mode and the user clicks this button.
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectViewCloseButton = ({editMode, closeDialogCallback}) => {
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();

    const handleCloseClick = () => {
        closeDialogCallback();
    };

    const handlePreviewClick = () => {
        searchResultDetailsDispatch({type: PREVIEW_MODE});
    };

    return (
        <IconButton
            sx={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: 99,
                backgroundColor: "rgba(0, 0, 0, .4)",
                color: "white",
                margin: 0.5,
            }}
            onClick={!editMode ? handleCloseClick : handlePreviewClick}
        >
            <CloseIcon/>
        </IconButton>
    );
};
