import React from "react";
import {useField} from "formik";
import TextField from "@mui/material/TextField";

export const FormikTextField = ({formikKey, ...props}) => {
    const [field, meta] = useField(formikKey);

    const getHelperText = () => {
        if (!props.tiptext) {
            if (meta.touched) {
                return meta.error;
            } else {
                return '';
            }
        } else if (props.tiptext) {
            if (meta.error) {
                return meta.error;
            } else {
                return props.tiptext;
            }
        }
    };

    if (undefined === meta.initialValue) {
        return null;
    } else {
        return (
            <TextField
                id={field.name}
                key={field.name}
                name={field.name}
                variant={"outlined"}
                helperText={getHelperText()}
                error={meta.touched && Boolean(meta.error)}
                value={field.value}
                onChange={field.onChange}
                onBlur={field.onBlur}
                {...props}
            />
        );
    }
};
