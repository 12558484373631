import {damsFetch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";

/**
 * Saves a row of data to the DAMS database.
 * @param {String} dataType - The type of data to save, e.g. 'person'.
 * @param {Object} data - The data to save.
 * @return {Promise} A promise that resolves when the data has been saved.
 */
export const saveRow = async (dataType, data) => {
    if (data === null || data === undefined) {
        throw new Error('data is null or undefined');
    }

    if (Object.keys(data).length === 0) {
        throw new Error('data is empty');
    }

    let obj;
    switch (dataType) {
        case 'person':
            obj = _createPersonObj(data);
            break;
        case 'subject':
            obj = _createSubjectObj(data);
            break;
        case 'place':
            obj = _createPlaceObj(data);
            break;
        default:
            break;
    }

    if (!obj) {
        return;
    }

    try {
        await damsFetch('/local-assets/update', {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep({
                assetType: dataType,
                data: obj
            }))
        });
    } catch (e) {
        throw new Error(`error in saveRow: ${e}`);
    }
};

/**
 * Creates a DAMS place object, used when saving to the local DAMS database.
 * @param {Object} data - The data to save.
 * @returns {Object} The created place object.
 * @private
 */
const _createPlaceObj = data => {
    const title = data.title;
    return {
        id: data.id,
        collectionId: data.collectionId[0],
        schemaId: 1,
        locale: "no",
        title: title,
        status: "published",
        documentType: "place",
        content: {}
    };
};

/**
 * Creates a DAMS subject object, used when saving to the local DAMS database.
 * @param {Object} data - The data to save.
 * @returns {Object} The created subject object.
 * @private
 */
const _createSubjectObj = (data) => {
    const {id, title, collectionId} = data;
    return {
        id: id,
        collectionId: collectionId,
        schemaId: 1,
        locale: "no",
        title: title,
        status: "published",
        documentType: "subject",
        content: {
            name: title,
        }
    };
};

/**
 * Creates a DAMS person object.
 * @param data
 * @returns {{documentType: string, schemaId: number, id: *, locale: string, title: *, collectionId: *, content: {name: *, yearOfDeath: *, yearOfBirth: *}, status: string}}
 * @private
 */
const _createPersonObj = data => {
    const name = data['title'];
    const yearOfBirth = data['yearOfBirth'] || '';
    const yearOfDeath = data['yearOfDeath'] || '';

    const pName = name;
    const personName = yearOfBirth || yearOfDeath ? `${pName} (${yearOfBirth} - ${yearOfDeath})` : pName;

    return {
        id: data.id,
        collectionId: data.collectionId[0],
        schemaId: 1,
        locale: "no",
        status: "published",
        documentType: "person",
        title: personName,
        content: {
            yearOfBirth: yearOfBirth,
            yearOfDeath: yearOfDeath,
            name: name,
            name_and_title: personName
        },
    };
}
