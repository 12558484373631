import React from "react";
import { useNewFilesTranslation } from "./NewFilesTranslationContext";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export const CancelDialog = ({ open, onNo, onYes }) => {
  const t = useNewFilesTranslation();

  return (
    <Dialog
      open={open}
      onClose={onNo}
      aria-labelledby="cancel-dialog-title"
      aria-describedby="cancel-dialog-description"
    >
      <DialogTitle id="cancel-dialog-title">
        {t("cancelDialogTitle", "Er du sikker på at du vil avbryte?")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="cancel-dialog-description">
          {t(
            "cancelDialogText",
            "Om du avbryter vil ikke endringene dine bli lagret"
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onNo} color="secondary" autoFocus>
          {t("no", "Nei")}
        </Button>
        <Button onClick={onYes} color="secondary">
          {t("yes", "Ja")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
