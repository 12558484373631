import {useCallback, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {codeToToken} from "@ekultur/authentication";
import {useAuthsDispatch, USER_LOGGED_IN} from "./authsContext";

export const Callback = () => {
    const location = useLocation();
    const dispatch = useAuthsDispatch();
    const navigate = useNavigate();
    const dispatchCallback = useCallback(dispatch, [dispatch]);

    useEffect(() => {
        if (location.pathname === "/oauth2/callback") {
            const runningLocal = window._env_.REACT_APP_X_QUOTE_MODE === 'local';
            const searchParams = new URLSearchParams(location.search);
            codeToToken({
                code: searchParams.get("code"),
                state: searchParams.get("state"),
                sameSite: !runningLocal,
                apiGateway: window._env_.REACT_APP_API_GATEWAY,
                onSuccess: (data) => {
                    dispatchCallback({type: USER_LOGGED_IN});
                    navigate(data.previousPath);
                },
            });
        }
    }, [location, navigate, dispatchCallback]);

    return <></>;
};
