import {useProjectTranslation} from "../../projectContext";

/**
 * List of available project types, for projects of type "general".
 * @type {[{name: string, id: number},{name: string, id: number},{name: string, id: number},{name: string, id: number},{name: string, id: number}]}
 */
export const ProjectTypes = () => {
    const tp = useProjectTranslation();

    const get = () => {
        return [
            {id: 0, name: tp('projectTypeNotSpecified', '-- Ikke oppgitt --')},
            {id: 1, name: tp('projectTypeDocumentation', 'Dokumentasjon')},
            {id: 2, name: tp('projectTypeExhibition', 'Utstilling')},
            {id: 3, name: tp('projectTypeDevelopment', 'Utvikling')},
            {id: 4, name: tp('projectTypeRegistration', 'Registrering')},
            {id: 5, name: tp('projectTypeCatalogizing', 'Katalogisering')},
            {id: 6, name: tp('projectTypeDigitizing', 'Digitalisering')}
        ];
    };

    return {get: get};
}



