import {useParams} from "react-router-dom";
import {useGetFolder} from "../../folder/useGetFolder";
import {useEffect, useState} from "react";
import {
    PROJECT_LOADED,
    useProjectViewDispatch,
    useProjectViewState
} from "../archeology/projectview/projectViewContext";
import {GeneralProjectForm} from "./GeneralProjectForm";
import useDeepCompareEffect from "use-deep-compare-effect";

export const AdminProjectView = () => {
    const {projectId} = useParams();
    const projectReq = useGetFolder(projectId);
    const {project} = useProjectViewState();

    const [failed, setFailed] = useState(false);
    const [projectLoading, setProjectLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        id: '',
        name: '',
        description: '',
        type: {id: '', name: ''},
        startedAt: '',
        endedAt: '',
        active: true,
        contributors: [],
        responsible: [],
        relatedProjects: [],
        remarks: '',
        extReferences: ''
    });

    const projectViewDispatch = useProjectViewDispatch();

    useDeepCompareEffect(() => {
        if (!projectLoading) {
            return;
        }

        const {
            pending,
            success,
            data,
            error
        } = projectReq;

        if (!pending && success && data) {
            // Project loaded!
            projectViewDispatch({type: PROJECT_LOADED, project: data})
            setProjectLoading(false);
        } else if (error || !data) {
            // Project failed to load - warn user!
            setProjectLoading(false);
            setFailed(true);
        }
    }, [projectReq]);

    useEffect(() => {
        function getInitialValues() {
            const {
                active,
                description,
                endedAt,
                extReferences,
                id,
                name,
                contributors,
                responsible,
                relatedProjects,
                remarks,
                startedAt,
                type
            } = project.content.generalprojectref;

            setInitialValues({
                id: id,
                name: name,
                description: description,
                type: type,
                startedAt: startedAt,
                endedAt: endedAt,
                active: Boolean(active),
                contributors: contributors,
                responsible: responsible,
                relatedProjects: relatedProjects,
                remarks: remarks,
                extReferences: extReferences
            });
        }

        if (projectLoading) {
            return;
        }

        if (failed) {
            console.error('Project failed loading!');
        }

        if (!projectLoading) {
            getInitialValues();
        }
    }, [projectLoading, failed, project?.content?.generalprojectref]);

    return <>{
        !projectLoading
        && !failed
        && initialValues.id !== ''
        && <GeneralProjectForm collectionId={project.collectionId} initialValues={initialValues}/>
    }</>;
};