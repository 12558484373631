import {useDocumentTranslation} from "../documents/documentContext";


/**
 * Used to describe the relation between two objects.
 * @returns {{getList: (function(): [{id: number, title: *},{id: number, title: *}])}}
 * @constructor
 */
export const RelationTypes = () => {
    const t = useDocumentTranslation();

    const getList = () => {
        return [
            {id: 1, title: t('relationType_1', 'Består av')},
            {id: 2, title: t('relationType_2', '"Inngår i')},
        ];
    };

    return {getList: getList};
};