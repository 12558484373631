import {getWorkerConfigBase} from "./getWorkerConfigBase";

/**
 * Method used to kick off the download web worker.
 */
export const kickOffDmsStatusWorker = () => {
    const apiGateway = window._env_.REACT_APP_DAMS_ADMIN_API;
    const workerConfig = getWorkerConfigBase(apiGateway);

    const job = {
        jobId: crypto.randomUUID(),
        name: 'DMS Status',
        active: true,
    };

    // Kick off web worker.
    window._dmsStatusWorkerInstance.postMessage(JSON.stringify({
        config: workerConfig,
        action: 'start',
        channel: 'worker',
        job: job
    }));
};
