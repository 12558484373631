import {styled} from "@mui/material/styles";
import React, {useState} from "react";
import {useSearchTranslation} from "../search/SearchContext";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import {useDownloadWorkerDispatch} from "../workerstatus/workerStatusContext";
import {SetImageSize} from "../multiple-files-download/SetImageSize";
import {kickOffDownloadWorker} from "../webworkers/kickOffDownloadWorker";
import {getWorkerConfigBase} from "../webworkers/getWorkerConfigBase";
import PropTypes from "prop-types";


const PREFIX = "DownloadMultipleFilesModal";

const classes = {
    dialogContent: `${PREFIX}-dialogContent`,
};

const StyledDialog = styled(Dialog)(({_}) => ({
    [`& .${classes.dialogContent}`]: {
        width: 600,
    },
}));

/**
 * Displays the modal used to download multiple documents/files.
 * @param documents Array   The documents selected for download.
 * @param openDialog    boolean True if the dialog should be displayed.
 * @param callback  method  The callback executed when the dialog closes.
 * @returns {JSX.Element}
 * @constructor
 */
const DownloadMultipleFilesModal = ({
                                        documents,
                                        openDialog,
                                        callback,
                                    }) => {
    const t = useSearchTranslation();
    const downloadWorkerDispatch = useDownloadWorkerDispatch();
    const [customImageSize, setCustomImageSize] = useState({
        width: 800,
        height: 600,
        selected: true
    });

    const handleClose = (_) => {
        callback();
    };

    const resetCustomImageSizeSettings = () => {
        setCustomImageSize({
            width: 800,
            height: 600,
            selected: true
        });
    };

    /**
     * Handles the file download.
     */
    const handleDownload = async () => {
        handleClose();
        const apiGateway = window._env_.REACT_APP_DAMS_ADMIN_API;
        kickOffDownloadWorker({
            workerConfig: getWorkerConfigBase(apiGateway),
            jobName: 'Filnedlasting',
            documents: documents,
            downloadWorkerDispatch: downloadWorkerDispatch,
            customImageSize: {...customImageSize, selected: true},
        });
        resetCustomImageSizeSettings();
    };

    /**
     * True if one or more images are present among the files selected for download.
     * @returns {boolean}
     */
    const imageFilesPresent = () => {
        return documents.filter(d => d.documentType === 'StillImage').length > 0;
    };

    /**
     * Callback receiving the preferred image size, if set by the user.
     * @param width int Pixels width
     * @param height int    Pixels height
     * @param selected  Boolean True, if the user has chosen to use a custom image size.
     */
    const sizeCallback = ({width, height, selected}) => {
        setCustomImageSize({
            width: width,
            height: height,
            selected: selected
        });
    };

    return (
        <StyledDialog open={openDialog}>
            <DialogTitle>{t("headingDownloading", "Nedlasting")}</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography>    {t(
                    "txtDownloadMultipleFiles",
                    "{{count}} filer er merket for nedlasting",
                    {count: documents.length}
                )}
                </Typography>
                {imageFilesPresent() && <SetImageSize multiple={true} sizeCallback={sizeCallback}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose(null)}>
                    {t("btnCancel", "Avbryt")}
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={async () => await handleDownload()}
                >
                    {t("btnOk", "Ok")}
                </Button>
            </DialogActions>
        </StyledDialog>
    );
};

DownloadMultipleFilesModal.propTypes = {
    "openDialog": PropTypes.bool.isRequired,
    "documents": PropTypes.array.isRequired,
    "callback": PropTypes.func.isRequired
};

export {DownloadMultipleFilesModal};