import React, {useEffect, useState} from "react";
import {MetadataTabs} from "./MetadataTabs";
import {BottomActionBar} from "./BottomActionBar";
import {
    MODEL_SAVED,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsState,
} from "../search-result-details/SearchResultDetailsContext";
import {ObjectViewRightColumnHeader} from "./ObjectViewRightColumnHeader";
import {EditMetadataForm} from "../search-result-details/EditMetadataForm";
import {EditContentActionsBelow} from "./EditContentActionsBelow";
import {MODELS_SAVED, SET_SEARCHING, useSearchDispatch} from "../search/SearchContext";
import {Scroller} from "../app/Scroller";
import {useObserveWindowResize} from "../app/useObserveWindowResize";
import useDeepCompareEffect from "use-deep-compare-effect";
import {CopyrightProvider} from "../copyright/copyrightContext";

/**
 * Component that holds the contents of the right column inside the object view dialog.
 * @param dialogRef   ref     A ref to the parent Dialog container
 * @param t object  Translation object
 * @param smallScreen bool  True if running on a small screen
 * @returns {JSX.Element}
 * @constructor
 */
export const ObjectViewMetaSection = ({dialogRef, t, smallScreen}) => {
    const observeWindowResize = useObserveWindowResize();
    const {model, editMode} = useSearchResultDetailsState();
    const searchDispatch = useSearchDispatch();
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();
    const [panelHeight, setPanelHeight] = useState(0); // Holds the height of the panel
    const [isPortrait, setIsPortrait] = useState(true);

    /**
     * Determine whether or not the current object is part of an archeology project.
     * @returns {boolean}
     */
    const isProjectObject = () => {
        const {folders} = model;
        if (!folders || folders.length === 0) {
            return false;
        } else {
            return folders.filter(f => f.type === "ProjectArcheology").length > 0;
        }
    };

    /**
     * Callback called when the user has saved the edited data,
     * used to update the model data and refresh the search-results.
     * @param updatedModels Array  The updated/saved model.
     */
    const onEdited = (updatedModels) => {
        searchDispatch({
            type: MODELS_SAVED,
            models: updatedModels,
        });
        searchResultDetailsDispatch({
            type: MODEL_SAVED,
            model: updatedModels[0],
        });
        searchDispatch({
            type: SET_SEARCHING
        });
    };

    /**
     * Hook used to see if the application is running in portrait mode or not.
     */
    useDeepCompareEffect(() => {
        setIsPortrait(observeWindowResize.width < observeWindowResize.height);
    }, [observeWindowResize]);

    /**
     * Hook used to calculate the available panel height.
     */
    useEffect(() => {
        if (!dialogRef.current) {
            return;
        }

        const headerHeight = 90;
        let observer = new ResizeObserver((elements) => {
            const observedEl = elements[0];
            let imageHeight = window.innerHeight * 0.3;
            if (!isPortrait && smallScreen) {
                imageHeight = 1;
            } else if (!smallScreen) {
                imageHeight = 0;
            }

            const height = observedEl.contentRect.height - headerHeight - imageHeight;
            setPanelHeight(height);
        });
        observer.observe(dialogRef.current);

        return () => {
            observer.disconnect();
            observer = null;
        };
    }, [isPortrait, dialogRef, smallScreen]);

    return (
        <>
            <ObjectViewRightColumnHeader/>
            {editMode && (
                <Scroller initialHeight={panelHeight + "px"} subtractFromHeight={0}>
                    <CopyrightProvider>
                        <EditMetadataForm model={model} onSuccess={onEdited}>
                            <EditContentActionsBelow isProjectObject={isProjectObject()}
                                                     collectionId={model.collectionId}/>
                        </EditMetadataForm>
                    </CopyrightProvider>
                </Scroller>
            )}
            {!editMode && (
                <>
                    <MetadataTabs
                        t={t}
                        panelHeight={panelHeight}
                        smallScreen={smallScreen}
                        isProjectObject={isProjectObject()}
                    />
                    <BottomActionBar smallScreen={smallScreen} t={t}/>
                </>
            )}
        </>
    );
};
