import React, { useEffect, useState } from "react";
import { useFoldersTranslation } from "./FoldersContext";
import { useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";

export const HeaderFolderList = () => {
    const location = useLocation();
    const [header, setHeader] = useState('');
    const t = useFoldersTranslation();

    useEffect(() => {
        switch (location.pathname) {
            case "/all-folders/":
                setHeader(t("allFolders", "Alle mapper"));
                break;
            case "/shared-folders/":
                setHeader(t("sharedFolders", "Delte mapper"));
                break;
            case "/my-folders/":
                setHeader(t("myFolders", "Mine mapper"));
                break;
            case "/all-projects/":
                setHeader(t("folderProject", "Prosjekt"));
                break;
            default:
                setHeader("");
                break;
        }
    }, [header, location.pathname, t]);

    return (
        <Typography variant="h5" component="h2" sx={{ paddingRight: .5, }}>
            {header}
        </Typography>
    );
};
