import React from "react";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import {ToggleButton, ToggleButtonGroup} from "@mui/material";

export const ViewTypeOption = ({viewType, setViewType}) => {
    const handleChange = (_event, nextView) => {
        setViewType(nextView);
    };
    return (
        <ToggleButtonGroup
            orientation={"horizontal"}
            value={viewType}
            exclusive={true}
            onChange={handleChange}
        >
            <ToggleButton value="table">
                <ViewListIcon/>
            </ToggleButton>
            <ToggleButton value="gridlist">
                <GridViewIcon/>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
