import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import React, {useState} from "react";
import useDeepCompareEffect from "use-deep-compare-effect";

/**
 * Component rendering the copyright icons that appears over the image in object view.
 * @param fullscreen
 * @param model
 * @returns {false|JSX.Element}
 * @constructor
 */
export const ObjectViewCopyrightIconsOverlay = ({fullscreen, model}) => {

    const [icons, setIcons] = useState([]);
    const [hasRestrictions, setHasRestrictions] = useState(false);

    const {content} = model;

    const {
        copyrightType,
        copyrightTypeDateUntil,
        copyrightTypeResponsible,
        copyrightTypeOriginator,
        copyrightInfo,
        licenses
    } = content;

    /**
     * Creates the list of icons to be displayed.
     */
    const createIconsList = () => {
        const copyrightIcons = [];
        if (copyrightType && copyrightType === 'copyright') {
            copyrightIcons.push("©");
        }

        if (copyrightInfo?.length > 0 && typeof (copyrightInfo[0]) !== 'undefined') {
            copyrightIcons.push("§");
        }

        if (licenses?.length > 0 && typeof (licenses[0]) !== 'undefined') {
            copyrightIcons.push("CC");
        }
        setIcons(copyrightIcons);
    }

    /**
     * Hook used to decide whether the current object has any copyright restrictions.
     */
    useDeepCompareEffect(() => {
        if (!content) {
            return;
        }
        const restrictions = !!(copyrightType
            || copyrightTypeDateUntil
            || copyrightTypeResponsible?.length > 0
            || copyrightTypeOriginator?.length > 0
            || copyrightInfo?.length > 0
            || licenses?.length > 0);

        setHasRestrictions(restrictions);
        createIconsList();
    }, [content])

    return !fullscreen && hasRestrictions && <Box sx={{
        backgroundColor: '#aaaaaaaa',
        position: 'absolute',
        top: 25,
        left: 45,
        zIndex: 100,
        borderRadius: 8
    }}>
        <Stack direction={"row"}>
            {icons.map(i => {
                return <Avatar key={`copyrightAvatar${i}`} sx={{margin: 0.5, backgroundColor: "#aaa"}}>{i}</Avatar>;
            })}
        </Stack>
    </Box>


};