import {LazyLoadImage} from "react-lazy-load-image-component";
import React from "react";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";

/**
 * Displays the thumbnail image for object types other than "StillImage".
 * @param image
 * @param imageHeight
 * @param onClickCallback
 * @returns {JSX.Element}
 * @constructor
 */
export const ThumbnailViewer = ({image, imageHeight, onClickCallback}) => {
    const {model} = useSearchResultDetailsState();
    return (
        <LazyLoadImage
            alt={model.title}
            title={model.title}
            effect="blur"
            src={image}
            style={{
                maxWidth: "100%",
                maxHeight: imageHeight + "px",
                objectFit: "scale-down",
                boxShadow: "3px 3px 15px -5px #000000",
                cursor: "pointer",
            }}
            onClick={onClickCallback}
        />
    );
};