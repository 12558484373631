import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {AccordionFilter} from "../AccordionFilter";
import {FilterCheckbox} from "./FilterCheckbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

export const FilterLanguages = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const languagesKey = "languages.title";

    const [selected, setSelected] = useState([]);

    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;

            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === languagesKey
                    )
                ) {
                    selectedFilters.push({key: languagesKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find(
                        (s) => s.value === value && s.key === languagesKey
                    )
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === languagesKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: languagesKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(languagesKey, selectedFilters);
        },
        [callback, selected]
    );

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(languagesKey, []);
        }
    }, [clearFilters]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === languagesKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
        } else {
            setSelected([]);
        }
    }, [fq]);

    const languages = results.facets.languages;
    const count = Object.keys(languages).length;
    if (!count) {
        return null;
    }

    const renderCheckboxes = (subjects) => {
        return Object.keys(subjects).map((value) => (
            <ListItem
                key={value}
                dense={true}
                disablegutters={"true"}
                onClick={changeHandler(value)}
            >
                <ListItemIcon>
                    <FilterCheckbox
                        selected={selected}
                        subjectsKey={languagesKey}
                        value={value}
                    />
                </ListItemIcon>
                <ListItemText id={`label-${value}`}>
                    {value} ({subjects[value]?.count})
                </ListItemText>
            </ListItem>
        ));
    };

    return (
        <AccordionFilter
            title={`${t("languages", "Språk")} (${count})`}
            count={count}
        >
            <List>{renderCheckboxes(languages)}</List>
        </AccordionFilter>
    );
};
