import React, {useCallback, useEffect, useState} from "react";
import {useSearchState, useSearchTranslation} from "../SearchContext";
import {FilterCheckbox} from "./FilterCheckbox";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

export const FilterCopyrightClause = ({callback}) => {
    const t = useSearchTranslation();
    const {fq, results, clearFilters} = useSearchState();
    const copyrightClauseKey = "copyright_info.title";
    const [selected, setSelected] = useState([]);

    const changeHandler = (value) => () => {
        modifySelectedFilter(value, false);
    };

    const modifySelectedFilter = useCallback(
        (value, fromFq) => {
            let selectedFilters = selected;
            if (fromFq) {
                if (
                    !selectedFilters.find(
                        (s) => s.value === value && s.key === copyrightClauseKey
                    )
                ) {
                    selectedFilters.push({key: copyrightClauseKey, value: value});
                    setSelected(selectedFilters);
                }
            } else {
                if (
                    selectedFilters.find(
                        (s) => s.value === value && s.key === copyrightClauseKey
                    )
                ) {
                    selectedFilters = selectedFilters.filter(
                        (s) => s.value !== value && s.key === copyrightClauseKey
                    );

                    setSelected(selectedFilters);
                } else {
                    selectedFilters.push({key: copyrightClauseKey, value: value});
                    setSelected(selectedFilters);
                }
            }
            callback(copyrightClauseKey, selectedFilters);
        },
        [callback, selected]
    );

    useEffect(() => {
        if (clearFilters) {
            setSelected([]);
            callback(copyrightClauseKey, []);
        }
    }, [clearFilters]);

    useEffect(() => {
        if (fq) {
            // Add selected filters from existing query
            fq.filter((f) => f.key === copyrightClauseKey).forEach((f) => {
                modifySelectedFilter(f.value.replaceAll('"', ""), true);
            });
        }
    }, [fq]);

    const copyrightClauses = results.facets.copyrightClauses;
    const count = Object.keys(copyrightClauses).length;
    if (!count) {
        return null;
    }

    const renderCheckboxes = (subjects) => {
        return Object.keys(subjects).map((value) => (
            <ListItem
                key={value}
                dense={true}
                disablegutters={"true"}
                onClick={changeHandler(value)}
            >
                <ListItemIcon>
                    <FilterCheckbox
                        selected={selected}
                        subjectsKey={copyrightClauseKey}
                        value={value}
                    />
                </ListItemIcon>
                <ListItemText id={`label-${value}`}>
                    {value} ({subjects[value].count})
                </ListItemText>
            </ListItem>
        ));
    };

    return (
        <>
            <Typography variant={"body2"}>{t('labelClauses', 'Klausuler')}</Typography>
            <List>{renderCheckboxes(copyrightClauses)}</List>
        </>
    );
};


