import React from "react";
import Dialog from "@mui/material/Dialog";

export const SearchResultDetailsModal = React.forwardRef((props, ref) => {
  return (
    <Dialog ref={ref} open={true} fullWidth={true} maxWidth={"lg"}>
      {props.children}
    </Dialog>
  );
});
