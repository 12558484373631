/**
 * Sets facet filter value, used when executing a new search.
 * @param filterName
 * @param event
 * @param selected
 */
export const setFq = (filterName, event, selected) => {
    let selectedValue = event.target.value;

    if (filterName === 'created_by_name') {
        selectedValue = `"${selectedValue}"`;
    }

    const filterObj = {key: filterName, value: selectedValue};

    const alreadySelected = [...selected];
    const existsAtIndex = alreadySelected.findIndex(f => f.key === filterName && f.value === selectedValue);

    let selectedFilters;

    if (existsAtIndex > -1) {
        // If filter/value combination already exists - remove it (toggle).
        selectedFilters = alreadySelected.toSpliced(existsAtIndex, 1);
    } else {
        // If the filter/value combination does not exist, add it.
        selectedFilters = [...selected, filterObj];
    }
    return selectedFilters;
};