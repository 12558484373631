import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";

/**
 * Component displaying the copyright originator(s).
 * @param t
 * @param models
 * @returns {JSX.Element|string}
 * @constructor
 */
export const CopyrightTypeOriginators = ({t, models}) => {

    if (!models || models.length === 0) {
        return '';
    }

    return <>
        <Typography variant={"caption"}
                    data-testid={'DAMSCopyrightTypeOriginators'}>{t('lblOriginatorPerson', 'Opphavsperson')}:</Typography>

        {models.map(o => (
            <Stack direction={"row"} key={o.reference.title}>
                <Chip label={o.reference.title}/>
            </Stack>
        ))}
    </>;
};