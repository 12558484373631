import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {UploadProgress} from "../UploadProgress";
import {useDmsMediaUrl} from "../../dms/useDmsMediaUrl";
import {LoadThumbProgress} from "../LoadThumbProgress";
import {useNewFilesTranslation} from "../NewFilesTranslationContext";
import {FileCardActionMenu} from "../FileCardActionMenu";
import {FileCardPreview} from "../FileCardPreview";
import {REMOVE_FILE, RETRY_FILE, useDocumentDispatch, useDocumentState,} from "../../documents/documentContext";
import {REMOVE_FILE_PROGRESS, useDmsDispatch} from "../../dms/dmsContext";
import {FileCardErrorIcon} from "../FileCardErrorIcon";
import {If} from "../../app/If";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import {getFileSizeInMB} from "../../utility";

const PREFIX = "FileCardSmallScreen";

const classes = {
    root: `${PREFIX}-root`,
    media: `${PREFIX}-media`,
    fileUploadProgress: `${PREFIX}-fileUploadProgress`,
    fileThumbContainer: `${PREFIX}-fileThumbContainer`,
    fileThumbIcon: `${PREFIX}-fileThumbIcon`,
};

const StyledCard = styled(Card)(({theme}) => ({
    [`&.${classes.root}`]: {
        backgroundColor: theme.palette.grey[100],
        minWidth: "35vw",
        maxWidth: "35vw",
        minHeight: "240px",
    },

    [`& .${classes.media}`]: {
        height: 140,
    },

    [`& .${classes.fileUploadProgress}`]: {
        position: "absolute",
        top: theme.spacing(1),
        right: theme.spacing(1),
    },

    [`& .${classes.fileThumbContainer}`]: {
        textAlign: "center",
    },

    [`& .${classes.fileThumbIcon}`]: {
        fontSize: 160,
        color: "#bbbbbb",
    },
}));

export const FileCardSmallScreen = ({file, progress}) => {
    const [imageLoaded, setImageLoaded] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [ready, setReady] = useState(false);
    const documentDispatch = useDocumentDispatch();
    const {formData} = useDocumentState();
    const dmsDispatch = useDmsDispatch();
    const t = useNewFilesTranslation();

    const [showMediaCard, setShowMediaCard] = useState(false);
    const [showAudioThumb, setShowAudioThumb] = useState(false);

    useEffect(() => {
        if (100 <= progress) {
            setTimeout(() => {
                setReady(true);
            }, 1000);
        }
    }, [file.dmsId, progress, setReady]);

    useEffect(() => {
        setShowAudioThumb(file.mimeType.indexOf("audio") > -1);

        if (
            file.mimeType.indexOf("image") > -1 ||
            file.mimeType.indexOf("pdf") > -1 ||
            file.mimeType.indexOf("video") > -1
        ) {
            setShowMediaCard(true);
        } else {
            setShowMediaCard(false);
            setImageLoaded(true);
        }
    }, [file.mimeType]);

    const imageUrl =
        useDmsMediaUrl({
            collectionId: formData.collectionId,
            ready: ready,
            dmsId: file.dmsId,
            mimeType: file.mimeType,
            retry: true,
        }) || "/no-thumbnail.jpg";

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleShowPreview = () => {
        handleClose();
        setShowPreview(true);
    };

    const handleRemove = () => {
        handleClose();
        documentDispatch({
            type: REMOVE_FILE,
            title: file.title,
        });
        dmsDispatch({
            type: REMOVE_FILE_PROGRESS,
            title: file.title,
            dmsId: file.dmsId,
        });
    };

    const handleRetry = () => {
        handleClose();
        documentDispatch({
            type: RETRY_FILE,
            title: file.title,
        });
    };

    const uploadFailed = () => -1 === progress;

    const previewOrRetry = () => {
        switch (progress) {
            case -1:
                return (
                    <MenuItem onClick={handleRetry}>
                        {t("retryUpload", "Last opp på ny")}
                    </MenuItem>
                );
            case 100:
                return (
                    <MenuItem onClick={handleShowPreview}>
                        {t("preview", "Forhåndsvisning")}
                    </MenuItem>
                );
        }
    };

    return (
        <StyledCard className={classes.root} elevation={3}>
            <CardActionArea onClick={handleClick}>
                <If boolean={showMediaCard}>
                    <CardMedia
                        component={"img"}
                        className={classes.media}
                        image={imageUrl}
                        title={file.title}
                        alt={file.dmsId}
                        onLoad={() => setImageLoaded(imageUrl.includes("http"))}
                    />
                </If>
                <If boolean={!showMediaCard && showAudioThumb}>
                    <div className={classes.fileThumbContainer}>
                        <VolumeUpOutlinedIcon className={classes.fileThumbIcon}/>
                    </div>
                </If>
                <If boolean={!showMediaCard && !showAudioThumb}>
                    <div className={classes.fileThumbContainer}>
                        <DescriptionOutlinedIcon className={classes.fileThumbIcon}/>
                    </div>
                </If>
                <LoadThumbProgress hide={imageLoaded || progress < 100}/>
                <UploadProgress progress={progress}/>
                <FileCardErrorIcon show={uploadFailed()}/>
                <CardContent>
                    <Typography gutterBottom variant="caption">
                        {file.title}
                    </Typography>
                    <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                        sx={{position: "relative", bottom: 0, left: 0}}
                    >
                        {getFileSizeInMB(file)} MB
                    </Typography>
                </CardContent>
            </CardActionArea>
            <FileCardActionMenu anchorEl={anchorEl} handleClose={handleClose}>
                {previewOrRetry()}
                <MenuItem onClick={handleRemove}>{t("remove", "Fjern")}</MenuItem>
            </FileCardActionMenu>
            <FileCardPreview
                showPreview={showPreview}
                setShowPreview={setShowPreview}
            >
                <img src={imageUrl} alt={file.title} className={classes.img}/>
            </FileCardPreview>
        </StyledCard>
    );
};
