import React from "react";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

export const LoadThumbProgress = ({hide}) => {
    if (hide) {
        return null;
    }

    return (
        <Box
            sx={{
                position: "absolute",
                left: "50%",
                transform: "translate(-50%, 0)",
                top: 50,
            }}
        >
            <Fade
                in={true}
                style={{
                    transitionDelay: "300ms",
                }}
                unmountOnExit={true}
            >
                <CircularProgress disableShrink={true}/>
            </Fade>
        </Box>
    );
};
