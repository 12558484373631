import DialogTitle from "@mui/material/DialogTitle";
import {Dialog, DialogActions, DialogContent} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {useProjectTranslation} from "./projectContext";

export const ProjectFailedLoading = () => {
    const tp = useProjectTranslation();
    const navigate = useNavigate();
    const handleClose = () => {
        navigate('/all-projects/')
    };

    return <Dialog open={true} onClose={handleClose}>
        <DialogTitle>
            {tp('projectTitle', 'Prosjekt')}
        </DialogTitle>
        <DialogContent>
            {tp('projectFailedLoadingContent', 'Det oppstod en ukjent feil. Vi klarte ikke hente inn valgte prosjekt.')}
        </DialogContent>
        <DialogActions>
            <Button
                variant={"contained"}
                color={"primary"}
                onClick={handleClose}>
                {tp('projectFailedLoadingBtnClose', 'Lukk')}
            </Button>
        </DialogActions>
    </Dialog>;
};