import React from "react";
import {useDocumentState} from "../documents/documentContext";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {CheckBoxOutlineBlankOutlined, CheckBoxOutlined, IndeterminateCheckBoxOutlined,} from "@mui/icons-material";
import {useTheme} from "@emotion/react";

const checkbox = {
    cursor: "pointer",
    marginRight: '2rem'
};

/**
 * Renders the toolbar for the uploaded files.
 *
 * @param {Object} props - The properties for the toolbar.
 * @param {ReactNode} props.children - The children elements to render.
 * @param {Function} props.clearAllCallback - The callback function to clear all files.
 * @param {Function} props.checkAllCallback - The callback function to check all files.
 * @param {Function} props.numberOfCheckedCallback - The callback function to get the number of checked files.
 * @return {JSX.Element} The rendered toolbar component.
 */
export const UploadedFilesToolbar = ({
                                         children,
                                         clearAllCallback,
                                         checkAllCallback,
                                         numberOfCheckedCallback,
                                     }) => {
    const theme = useTheme();
    const {files} = useDocumentState();
    const t = useNewFilesTranslation();

    const getCheckbox = () => {
        const checked = numberOfCheckedCallback();
        if (checked === 0) {
            return (
                <CheckBoxOutlineBlankOutlined
                    onClick={checkAllCallback}
                    sx={checkbox}
                />
            );
        } else if (checked > 0 && checked !== files.length) {
            return <IndeterminateCheckBoxOutlined
                onClick={clearAllCallback}
                sx={checkbox}/>;
        } else {
            return (
                <CheckBoxOutlined
                    onClick={clearAllCallback}
                    sx={checkbox}
                />
            );
        }
    };

    return (
        <Toolbar
            sx={{padding: 0, flexWrap: "wrap", marginBottom: theme.spacing(1)}}
        >
            {getCheckbox()}
            <Typography variant={"h6"} component={"h2"}>
                {t("uploaded", "Opplastet")} ({files.length})
            </Typography>
            {children}
        </Toolbar>
    );
};
