import Stack from "@mui/material/Stack";
import {WarningAmber} from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import {ToolbarContinueOnly} from "./ToolbarContinueOnly";
import React from "react";
import PropTypes from "prop-types";

const AllFailedContent = ({classes, t, clickCallback}) => {
    return <>
        <Stack direction={"row"} mt={2} mb={1}>
            <WarningAmber color={"error"}/>
            <Typography ml={2}>
                {t('metadataExtractionFailed', 'Uthenting av metadata feilet.')}
            </Typography>
        </Stack>
        <Typography mt={1}>
            {t('failedToExtractMetadata', 'Vi klarte ikke å hente ut metadata.')}
        </Typography>
        <Typography mb={2}>
            {t('failedToExtractMetadataHelp', 'Be din system administrator om å verifisere at metadata mapping for ' +
                'deres museum er korrekt konfigurert. Se igjennom EXIF-data for de opplastede filene, og se om disse ' +
                'passer med metadata mapping konfigurasjonen, og forsøk igjen.')}
        </Typography>
        <ToolbarContinueOnly
            classes={classes}
            t={t}
            clickCallback={clickCallback}/>
    </>
};

AllFailedContent.propTypes = {
    classes: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    clickCallback: PropTypes.func.isRequired
};


export {AllFailedContent};