import React from "react";
import {Dialog, DialogContent, Slide} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


/**
 * Dialog wrapper component, used to display the contents in a dialog with a transition of type "UP".
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const DialogWrapper = (props) => {
    const {children, dialogActions, closeHandler, openDialog, dialogTitle} = props;
    return <Dialog
        open={openDialog}
        disableEscapeKeyDown={true}
        onClose={closeHandler}
        TransitionComponent={Transition}
    >
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        {dialogActions()}
    </Dialog>;
};
