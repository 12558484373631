import React, {useCallback, useEffect} from "react";
import {useField, useFormikContext} from "formik";
import {damsFetch} from "../app/damsFetch";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";

export const FormikMuseumToCollectionSelect = ({
                                                   formikKey,
                                                   label,
                                                   fullWidth,
                                                   children,
                                                   ...props
                                               }) => {
    const [field, meta] = useField(formikKey);
    const {setFieldValue} = useFormikContext();

    const {name, value} = field;
    const {touched, error} = meta;

    const storageKey = "dams.folder.default.collectionId";

    const saveDefault = (collectionId) => {
        window.localStorage.setItem(storageKey, collectionId);
    };

    const getDefault = () => {
        return window.localStorage.getItem(storageKey);
    };

    const setCollectionFieldValue = useCallback(
        (museumId) => {
            if (!museumId) {
                return;
            }

            const searchParams = new URLSearchParams({
                id: museumId,
            });

            damsFetch(`/collections/?${searchParams.toString()}`).then((json) => {
                setFieldValue("collectionId", json[0].id).then();
            });
        },
        [setFieldValue]
    );

    const onChange = (event) => {
        const v = event.target.value;
        setCollectionFieldValue(v);
        field.onChange(event);
        saveDefault(v);
    };

    useEffect(() => {
        if (!value) {
            const id = getDefault();
            setFieldValue(name, id).then();
            setCollectionFieldValue(id);
        }
    }, [name, value, setCollectionFieldValue, setFieldValue]);

    return (
        <FormControl fullWidth={fullWidth}>
            <Box padding={1}>
                <InputLabel id={`${name}-select-label`}>{label}</InputLabel>
            </Box>
            <Select
                id={name}
                labelId={`${name}-select-label`}
                name={name}
                error={touched && Boolean(error)}
                value={value}
                onChange={onChange}
                onBlur={field.onBlur}
                {...props}
            >
                {children}
            </Select>
        </FormControl>
    );
};
