import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import {useFormikContext} from "formik";
import {BtnAdd} from "./BtnAdd";
import {BtnDelete} from "./BtnDelete";
import {BtnDeleteCopyrightType} from "./BtnDeleteCopyrightType";
import {SHOW_ADD_COPYRIGHT_CLAUSE_FIELD, SHOW_ADD_LICENSE_FIELD, useCopyrightState} from "../copyrightContext";
import {LicenseFieldsWrapper} from "../LicenseFieldsWrapper";
import {CopyrightClauseWrapper} from "../CopyrightClauseWrapper";
import {CopyrightTypeFieldsWrapper} from "../CopyrightTypeFieldsWrapper";
import {FormikTextField} from "../../form/FormikTextField";

/**
 * Wrapper that contains the fields that holds the copyright information.
 * @param t method  Translation method
 * @param collectionId str  The model's collection ID
 * @param fileUploadMode str
 * @param active boolean
 * @returns {JSX.Element}
 * @constructor
 */
export const CopyrightSectionWrapper = ({t, collectionId, fileUploadMode, active = true}) => {
    const {showAddLicenseField, showAddCopyrightClause} = useCopyrightState();
    const {values} = useFormikContext();

    const [data, setData] = useState({});

    useEffect(() => {
        if (!values) {
            return;
        }
        setData(values);
    }, [values]);

    /**
     * Displays the form fields related to the existing licenses.
     * @returns {*}
     */
    const getExistingLicenses = () => {
        return <><Typography variant={'h6'}>{t('headingLicenses', 'Lisenser')}</Typography>
            {data['licenses']?.map((_l, ix) => {
                const itemKey = _l.reference.id;
                return <Stack key={`lic-${itemKey}`} model
                              direction={"row"}
                              sx={{marginBottom: 1.5, width: '100%'}}
                              alignItems={'center'}>
                    <LicenseFieldsWrapper key={`licenseWrapper-${itemKey}`}
                                          t={t}
                                          ix={ix}/>
                    <BtnDelete objectType={'license'} object={_l} fileUploadMode={fileUploadMode} ix={ix}/>
                </Stack>
            })}
        </>
    };

    /**
     * Displays the form fields related to the existing copyright clauses.
     * @returns {JSX.Element}
     */
    function getExistingCopyrightClauses() {
        const copyrightInfo = data['copyrightInfo'];
        return copyrightInfo && <>
            <Typography variant={'h6'}>{t('headingCopyrightClause', 'Klausul')}</Typography>
            {copyrightInfo.length > 0 && (
                copyrightInfo?.filter(c => c && !c.deleted === true).map((_c, ix) => {
                    const itemKey = _c.sourceId;
                    return <Stack key={`copy-${itemKey}`} direction={"row"} sx={{marginBottom: 1.5, width: '100%'}}
                                  alignItems={'center'}>
                        <CopyrightClauseWrapper key={`copyrightClauseWrapper-${itemKey}`}
                                                t={t}
                                                ix={ix}
                                                collectionId={collectionId}
                        />

                        <BtnDelete objectType={'clause'} object={_c} fileUploadMode={fileUploadMode} ix={ix}
                                   values={values}/>
                    </Stack>
                }))
            }
        </>;
    }

    /**
     * Displays the copyright type.
     * @returns {JSX.Element}
     */
    function getCopyrightType() {
        return <>
            <Typography variant={'h6'} gutterBottom>{t('headingCopyrightType', 'Opphavsrett')}</Typography>
            <Stack key={`ctype`} direction={"row"} sx={{marginBottom: 1.5, width: '100%'}} alignItems={'center'}>
                <CopyrightTypeFieldsWrapper t={t}/>
                <BtnDeleteCopyrightType fileUploadMode={fileUploadMode}/>
            </Stack>
        </>;
    }

    return active && data && (<>
            <Stack alignItems={'flex-start'} sx={{width: '100%'}}>
                <Typography variant={'h5'}
                            sx={{marginBottom: 2}}>{t('headingRights', 'Rettigheter')}</Typography>

                {/* Copyright type */}
                {getCopyrightType()}

                {/* Licenses */}
                {getExistingLicenses()}
                {showAddLicenseField && <LicenseFieldsWrapper key={'noLicenses'}
                                                              t={t}
                                                              ix={values.licenses?.length || 0}/>
                }
                <BtnAdd label={t('btnAddNewLicense', 'Legg til nytt lisensfelt')}
                        dispatchValue={SHOW_ADD_LICENSE_FIELD}/>

                {/* Clauses */}
                {getExistingCopyrightClauses()}

                {showAddCopyrightClause &&
                    <CopyrightClauseWrapper key={'noCopyrightClauses'}
                                            t={t}
                                            ix={values.copyrightInfo?.length || 0}
                                            collectionId={collectionId}/>
                }

                <BtnAdd label={t('btnAddCopyrightClause', 'Legg til klausuler')}
                        dispatchValue={SHOW_ADD_COPYRIGHT_CLAUSE_FIELD}/>
            </Stack>

            <FormikTextField formikKey={'copyrightTerms'}
                             label={t('copyrightTerms', 'Rettighetsbetingelser')}
                             helperText={t('copyrightTermsHelpText', 'Betingelser som gjelder rettighetene for objektet')}
                             fullWidth={true}
                             multiline={true}
                             size={"small"}/>
        </>
    );
};