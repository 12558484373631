import React from "react";
import {useHeaderState} from "@ekultur/header-microfrontend";
import {ProgressIndicator} from "../app/ProgressIndicator";
import {useAppState} from "../app/AppContext";

export const WhenHeaderIsReady = ({children}) => {
    const {ready} = useHeaderState();
    const {rendered} = useAppState();

    if ((!ready || !rendered) && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return <>{children}</>;
};
