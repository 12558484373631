import React, {useEffect, useState} from "react";
import {useMyMuseums} from "../useMyMuseums";
import {damsFetch} from "../../app/damsFetch";
import {useAuthsState} from "../../auths/authsContext";
import {ADD_MESSAGE, useSnackbarDispatch} from "../../snackbar/SnackbarContext";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import {useDmsStatusState} from "../../dmsstatus/DmsStatusContext";
import {useNavigate} from "react-router-dom";
import {SingleMuseumDialog} from "./SingleMuseumDialog";
import {NoAccessDialog} from "./NoAccessDialog";
import {MultipleMuseumsDialog} from "./MultipleMuseumsDialog";

/**
 * Component that displays the select museum dialog that appears in the beginning of the different wizards.
 * @param t
 * @param selectedMuseumCallback
 * @param collectionId
 * @param dialogTitle
 * @param projectContext    bool    True if displayed in project context, filtering out museums without projects enabled.
 * @returns {JSX.Element}
 * @constructor
 */
export const SelectMuseumDialog = ({t, selectedMuseumCallback, collectionId, dialogTitle, projectContext = false}) => {
    const navigate = useNavigate();

    const {userData} = useAuthsState();
    const {dmsStatus} = useDmsStatusState();

    const username = userData?.name;

    const {museumCollections} = useAuthsState();

    const myMuseums = useMyMuseums({onlyAdministrativeMuseums: false});

    // Holds the list of museums the user has access to.
    const [accessibleMuseums, setAccessibleMuseums] = useState();

    const snackbarDispatch = useSnackbarDispatch();

    const [openDialog, setOpenDialog] = useState(false);

    const showDmsError = () => {
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t('selectMuseumDialogErrorTitle', 'DAMS feil - kontakt support'),
                body: t('selectMuseumDialogErrorInfo', 'Filopplasting ikke tilgjengelig. Kan ikke nå lagringsområdet.'),
                type: "error",
            },
        });
    };

    /**
     * Tags the user's museums that has an invalid DMS-config with "incorrectConfig"=true.
     * @param museumsList Array List of the user's museums.
     * @returns {*}
     */
    const invalidateMenuItemsWithInvalidConfig = (museumsList) => {
        return museumsList.map((am) => {
            const incorrect = dmsStatus['missingConfigs'].includes(am['ekultur_id']);
            return {...am, incorrectConfig: incorrect};
        });
    };

    /**
     * Tags all the user's museums with "incorrectConfig"=true.
     * @param museumsList   Array   List ov user's museums.
     * @returns {*}
     */
    const invalidateAllMenuItems = (museumsList) => {
        return museumsList.map((m) => {
            return {...m, incorrectconfig: true};
        });
    };

    /**
     * Handler triggered when the dialog closes.
     * @param event object  Event object.
     * @param reason string The reason for why the dialog was closed.
     */
    const dialogCloseHandler = (event, reason) => {
        if ('continue' === reason) {
            setOpenDialog(false);
        }
    };

    /**
     * Handler triggered when the user clicks the Cancel button.
     * The dialog is closed, and the upload-wizard is "terminated", leading the user back to "home".
     */
    const dialogCancelHandler = () => {
        setOpenDialog(false);
        navigate('/');
    };

    /**
     * Returns the DialogActions section, used to render the buttons in the dialog.
     * @param cId   int The collection ID of the selected museum, or null if the user has access to only one museum.
     * @returns {JSX.Element}
     */
    const getDialogActions = (cId) => {
        let btnContinueDisabled = false;
        let onClickMethod = () => {
            selectedMuseumCallback(cId);
            setTimeout(() => {
                dialogCloseHandler(null, 'continue');
            }, 200);
        };

        if (!cId) {
            btnContinueDisabled = collectionId === -1;
            onClickMethod = () => {
                dialogCloseHandler(null, 'continue');
            };
        }

        return <DialogActions>
            <Button onClick={dialogCancelHandler}>
                {t('selectMuseumDialogBtnCancel', 'Avbryt')}
            </Button>
            <Button variant={"contained"}
                    onClick={onClickMethod}
                    disabled={btnContinueDisabled}>
                {t('selectMuseumDialogBtnContinue', 'Fortsett')}
            </Button>
        </DialogActions>;
    };

    /**
     * Hook used to fetch the username for the current user.
     */
    useEffect(() => {
        if (username) {
            damsFetch("/current-user/", {
                method: "POST",
                body: JSON.stringify({name: username}),
            }).then(null);
        }
    }, [username]);

    /**
     * Hook used to "mark" museums that has an invalid config. for DMS, and is therefore unavailable
     * when attempting to upload files or save data.
     *
     * NOTE: quit hook if dmsStatus is not set or a museum (collectionId) has already been selected.
     */
    useEffect(() => {
        if (!dmsStatus
            || !dmsStatus['missingConfigs']
            || collectionId > -1
            || !museumCollections
            || museumCollections?.length === 0) {
            return;
        }

        let museumsList = myMuseums.map(m => {
            const collection = museumCollections.find(mc => mc.museumId === m.id);
            if (!collection) {
                return null;
            }
            const collectionId = collection.collectionId;
            return {
                ...m,
                collectionId: collectionId
            };
        });

        if (dmsStatus.errorCount > 0) {
            museumsList = invalidateAllMenuItems(museumsList);
            showDmsError();
        } else {
            museumsList = invalidateMenuItemsWithInvalidConfig(museumsList);
        }
        setAccessibleMuseums(museumsList);
    }, [myMuseums, dmsStatus, museumCollections]);

    /**
     * Await opening the dialog until the contents are ready.
     */
    useEffect(() => {
        if (collectionId > -1) {
            return;
        }

        setTimeout(() => {
            setOpenDialog(true);
        }, 300);
    }, [accessibleMuseums, collectionId]);

    // Render the dialog.
    if (!accessibleMuseums) {
        return <NoAccessDialog t={t} openDialog={openDialog}/>;
    } else if (accessibleMuseums?.length === 1) {
        return <SingleMuseumDialog t={t}
                                   userMuseum={accessibleMuseums[0]}
                                   closeDialogCallback={dialogCloseHandler}
                                   dialogActionsCallback={getDialogActions}
                                   openDialog={openDialog}/>;
    } else {
        return <MultipleMuseumsDialog title={dialogTitle}
                                      collectionId={collectionId}
                                      t={t}
                                      projectContext={projectContext}
                                      openDialog={openDialog}
                                      accessibleMuseums={accessibleMuseums}
                                      selectedMuseumCallback={selectedMuseumCallback}
                                      dialogActionsCallback={getDialogActions}
                                      closeDialogCallback={dialogCloseHandler}/>
    }
};
