import {useEffect, useState} from "react";
import {useAuthsState} from "../auths/authsContext";
import {kitFetch} from "@ekultur/fetch";
import {damsFetch} from "../app/damsFetch";
import {clientLog} from "../clientLog";

export const useSharedFolderMuseums = () => {
    const [museums, setMuseums] = useState([]);
    const {userData} = useAuthsState();

    const getGrantedMuseumIds = (json, data) => {
        return json.museums
            .filter((museum) =>
                data.museums.map((sm) => sm.museumId).includes(museum.id)
            )
            .map((g) => g.id);
    }

    const getMuseumName = (json, grantedMuseumIds, i) => {
        return json.museums.find(
            (m) => m.id === grantedMuseumIds[i]
        ).name;
    }

    const getCollectionId = (data, grantedMuseumIds, i) => {
        return data.museums.find(
            (d) => d.museumId === grantedMuseumIds[i]
        ).collectionId;
    }

    const successCallback = (json, data) => {
        const grantedMuseumIds = getGrantedMuseumIds(json, data);

        // Merge museum and collection data
        const merged = [];
        for (let i = 0, max = grantedMuseumIds.length; i < max; i++) {
            const id = grantedMuseumIds[i];
            const museumName = getMuseumName(json, grantedMuseumIds, i);
            const collectionId = getCollectionId(data, grantedMuseumIds, i);
            merged.push({
                id: id,
                name: museumName,
                collectionId: collectionId,
            });
        }
        setMuseums(merged);
    };

    const errorCallback = error => {
        clientLog('error', error, 'useSharedFolderMuseums');
    }


    useEffect(() => {
        kitFetch(`/museum-api/museums?fields[]=id,name`)
            .then((json) => {
                damsFetch("/collections/granted-museums/").then((data) => {
                    successCallback(json, data);
                });
            })
            .catch(errorCallback);
    }, [JSON.stringify(userData), setMuseums]);
    return museums;
};
