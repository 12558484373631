import React, {createContext, useContext, useReducer} from "react";

export const UPDATE_PROGRESS = 'dmsContext/updateProgress';
export const UPDATE_URLS = 'dmsContext/updateUrls';
export const REMOVE_FILE_PROGRESS = 'dmsContext/removeFileProgress';

const DmsStateContext = createContext(undefined);
const DmsDispatchContext = createContext(undefined);

const initialState = {
    progressByFile: {},
    urls: {}
}

const dmsReducer = (state, action) => {
    switch (action.type) {
        case UPDATE_PROGRESS:
            return {
                ...state,
                progressByFile: {
                    ...state.progressByFile,
                    [action.fileName]: action.progress
                }
            }
        case UPDATE_URLS:
            const previousDimensions = state.urls[action.dmsId] ? state.urls[action.dmsId].dimensions : {}
            return {
                ...state,
                urls: {
                    ...state.urls,
                    [action.dmsId]: {
                        ...state.urls[action.dmsId],
                        url: action.url,
                        dimensions: {
                            ...previousDimensions,
                            ...action.dimensions
                        },
                        expires: action.expires,
                        static: action.static,
                        failed: action.failed
                    }
                }
            }
        case REMOVE_FILE_PROGRESS:
            return {
                ...state,
                urls: Object.keys(state.urls)
                    .filter(dmsId => dmsId !== action.dmsId)
                    .reduce((object, dmsId) => ({
                        ...object,
                        [dmsId]: state.urls[dmsId]
                    }), {}),
                progressByFile: Object.keys(state.progressByFile)
                    .filter(fileName => fileName !== action.title)
                    .reduce((object, fileName) => ({
                        ...object,
                        [fileName]: state.progressByFile[fileName]
                    }), {})
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const DmsProvider = ({children}) => {
    const [state, dispatch] = useReducer(dmsReducer, initialState, undefined);

    return (
        <DmsStateContext.Provider value={state}>
            <DmsDispatchContext.Provider value={dispatch}>
                {children}
            </DmsDispatchContext.Provider>
        </DmsStateContext.Provider>
    )
}

export const useDmsState = () => {
    const context = useContext(DmsStateContext);
    if (undefined === context) {
        throw new Error('useDmsState must be used within a DmsProvider');
    } else {
        return context;
    }
}

export const useDmsDispatch = () => {
    const context = useContext(DmsDispatchContext);
    if (undefined === context) {
        throw new Error('useDmsDispatch must be used withing a DmsProvider')
    } else {
        return context;
    }
}
