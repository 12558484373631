import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import FilterListIcon from "@mui/icons-material/FilterList";
import React, {useEffect, useState} from "react";
import {
    CLEAR_FILTERS,
    SET_SEARCHING,
    useFoldersDispatch,
    useFoldersState,
    useFoldersTranslation
} from "../FoldersContext";
import {Toolbar} from "@mui/material";

export const FiltersHeader = () => {
    const t = useFoldersTranslation();

    const foldersDispatch = useFoldersDispatch();
    const {fq, privateOnly} = useFoldersState();

    const [disableFilterButtons, setDisableFilterButtons] = useState(false);

    // eslint-disable-next-line no-unused-vars
    const [privateOnlyInitial, setPrivateOnlyInitial] = useState(privateOnly); // NOSONAR


    const handleResetFilterClick = () => {
        foldersDispatch({type: CLEAR_FILTERS});
        foldersDispatch({type: SET_SEARCHING});
    };

    const handleFilterClick = () => {
        foldersDispatch({type: SET_SEARCHING});
    };

    /**
     * Hook used to disable the "filter"- and "reset filter" buttons,
     * when no filter values have been selected.
     */
    useEffect(() => {
        setDisableFilterButtons((!fq || fq?.length === 0) && privateOnly === privateOnlyInitial);
    }, [fq, privateOnly]);

    return <Toolbar sx={{
        "&.MuiToolbar-root": {
            paddingLeft: 2,
            paddingRight: 2,
        }
    }}>

        <Typography variant="h5" component="h2">
            {t("filter", "Filter")}
        </Typography>

        <Box flexGrow={1}/>

        <Button
            sx={{
                marginRight: '16px',
            }}
            disabled={disableFilterButtons}
            color={"secondary"}
            size={"small"}
            onClick={handleResetFilterClick}
            startIcon={<ClearAllIcon/>}
        >
            {t("clearFilter", "Tøm Filter")}
        </Button>

        <Button
            variant={"contained"}
            color={"primary"}
            size={"small"}
            onClick={handleFilterClick}
            disabled={disableFilterButtons}
            startIcon={<FilterListIcon/>}
        >
            {t("applyFilter", "Filtrer")}
        </Button>
    </Toolbar>;

};