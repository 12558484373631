import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Fade from "@mui/material/Fade";

const PREFIX = "WithSaveCircle";

const classes = {
    progressContainer: `${PREFIX}-progressContainer`,
    progress: `${PREFIX}-progress`,
};

const Root = styled("div")(() => ({
    [`&.${classes.progressContainer}`]: {
        position: "relative",
        height: "100%",
    },

    [`& .${classes.progress}`]: {
        padding: 0,
        margin: 0,
        position: "absolute",
        outline: "none",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
    },
}));

export const WithSaveCircle = ({saving, children}) => {
    const [status, setStatus] = useState("init");

    useEffect(() => {
        if (saving) {
            setStatus("timeout");
            window.setTimeout(() => {
                setStatus((s) => (s === "close" ? s : "open"));
            }, 300);
        } else {
            setStatus((s) => ("init" === s ? s : "close"));
        }
    }, [saving, setStatus]);

    return (
        <Root className={classes.progressContainer}>
            {children}
            <div className={classes.progress}>
                <Fade in={"open" === status} unmountOnExit={true}>
                    <CircularProgress size={160} disableShrink={true}/>
                </Fade>
            </div>
        </Root>
    );
};
