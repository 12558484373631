import React, {useEffect, useState} from "react";
import * as Yup from "yup";
import {useFolderTranslation} from "../MyFoldersContext";
import {FormikTextField} from "../../form/FormikTextField";
import {FormikRadioGroup} from "../../form/FormikRadioGroup";
import {styled} from "@mui/material/styles";
import {Formik} from "formik";
import {ToolbarFolderAdmin} from "./ToolbarFolderAdmin";
import {DamsModal} from "../../app/DamsModal";
import {FormikEmailAcl} from "../../form/FormikEmailAcl";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import {InputTags} from "../tags/InputTags";

const PREFIX = "FormFolderSettings";

const classes = {
    marginBottom: `${PREFIX}-marginBottom`,
    modal: `${PREFIX}-modal`,
    content: `${PREFIX}-content`,
    paper: `${PREFIX}-paper`,
};

const StyledDamsModal = styled(DamsModal)(({theme}) => ({
    [`& .${classes.marginBottom}`]: {
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.modal}`]: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    [`& .${classes.content}`]: {
        outline: "none",
        [theme.breakpoints.down("lg")]: {
            height: "100%",
            width: "100%",
        },
        [theme.breakpoints.up("sm")]: {
            height: (props) => (props['isFullscreen'] ? "100%" : "90%"),
            width: (props) => (props['isFullscreen'] ? "100%" : "70%"),
        },
    },

    [`& .${classes.paper}`]: {
        padding: theme.spacing(4),
        height: "100%",
    },
}));

const schema = (t) => {
    return Yup.object().shape({
        folderName: Yup.string().required(t("requiredFeedback", "Påkrevd")),
    });
};

export const FormFolderSettings = ({open, model, callback}) => {
    const t = useFolderTranslation();

    const {collectionId, content} = model;

    const [folderTags, setFolderTags] = useState([]);

    const onHide = () => {
        callback(null);
    };

    const unWrapAcls = (_acls, aclType) => {
        if (!model.acls) {
            return [];
        }
        return model.acls
            .filter((a) => a.access === aclType)
            .map((i) => {
                return {
                    ...i,
                    expiresAt: i.expires_at || i.expiresAt,
                };
            });
    };

    const getInputTags = () => {
        return <Box sx={{marginBottom: '32px'}}>
            <InputTags collectionId={collectionId} formikKey={'tags'} existingTags={folderTags}/>
        </Box>;
    };

    /**
     * Hook used to keep the folder tags in sync.
     */
    useEffect(() => {
        if (!content.tags) {
            return;
        }
        setFolderTags(content.tags);
    }, [content.tags]);

    return (
        <StyledDamsModal open={open}>
            <Typography variant={"h6"} component={"h3"} gutterBottom={true}>
                {t("folderAdminDialogTitle", "Rediger Mappe")}
            </Typography>

            <Formik
                initialValues={{
                    collectionId: collectionId,
                    folderName: model.title,
                    folderAvailability: model.status,
                    readShare: unWrapAcls(model.acls, "read"),
                    writeShare: unWrapAcls(model.acls, "write"),
                }}
                onSubmit={(values) => {
                    callback(values);
                }}
                validationSchema={schema(t)}
            >
                <div>
                    <FormikTextField
                        formikKey={"folderName"}
                        label={t("folderName", "Mappenavn")}
                        className={classes.marginBottom}
                        fullWidth={true}
                        required={true}
                    />

                    <Box sx={{pt: 4, pb: 2}}>
                        <FormikRadioGroup
                            formikKey={"folderAvailability"}
                            className={classes.marginBottom}
                            label={t("availability", "Tilgjengelighet")}
                        >
                            <FormControlLabel
                                value="private"
                                control={<Radio/>}
                                label={t("private", "Privat")}
                            />
                            <FormControlLabel
                                value="public"
                                control={<Radio/>}
                                label={t("public", "Offentlig")}
                            />
                        </FormikRadioGroup>
                    </Box>

                    {getInputTags()}

                    <Typography variant={"h6"} component={"h3"} gutterBottom={true}>
                        {t('headingSharingAlternatives', 'Delingsalternativer')}
                    </Typography>
                    <Box pb={2}>
                        <Typography variant={"subtitle1"} gutterBottom={true}>
                            {t('subHeadingSharingAlternatives',
                                'Merk: Brukere som gis "skrivetilgang" får automatisk "lesetilgang" i tillegg.')}
                        </Typography>
                    </Box>

                    <FormikEmailAcl
                        formikKey={"readShare"}
                        required={false}
                        className={classes.marginBottom}
                        label={t("readShare", "Gi lesetilgang")}
                    />
                    <FormikEmailAcl
                        formikKey={"writeShare"}
                        required={false}
                        className={classes.marginBottom}
                        label={t("writeShare", "Gi skrivetilgang")}
                    />

                    <ToolbarFolderAdmin onHide={onHide}/>
                </div>
            </Formik>
        </StyledDamsModal>
    );
};
