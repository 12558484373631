import {Dialog, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import React from "react";
import {useSnackbarTranslation} from "../snackbar/SnackbarContext";
import PropTypes from "prop-types";

/**
 * Dialog framework used when displaying the option dialog when downloading image-, audio- or video files.
 * @param title
 * @param content
 * @param open
 * @param cancelHandler
 * @param closeHandler
 * @returns {JSX.Element}
 * @constructor
 */
const DownloadOptionsDialog = ({title, content, open, cancelHandler, closeHandler}) => {
    const t = useSnackbarTranslation();

    return <Dialog open={open}>
        <DialogTitle>
            {title}
        </DialogTitle>
        <DialogContent>
            {content}
        </DialogContent>
        <DialogActions>
            <Button onClick={cancelHandler}>
                {t("btnCancel", "Avbryt")}
            </Button>
            <Button
                color="primary"
                variant="contained"
                onClick={closeHandler}
            >
                {t("btnOk", "Ok")}
            </Button>
        </DialogActions>
    </Dialog>;
};

DownloadOptionsDialog.propTypes = {
    "title": PropTypes.string.isRequired,
    "open": PropTypes.bool.isRequired,
    "cancelHandler": PropTypes.func.isRequired,
    "closeHandler": PropTypes.func.isRequired
};

export {DownloadOptionsDialog};