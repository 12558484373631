import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountTreeSharpIcon from "@mui/icons-material/AccountTreeSharp";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {If} from "../../../app/If";
import {useMuseumNameFromCollectionId} from "../../../museum/useMuseumNameFromCollectionId";
import {makeStyles} from "@mui/styles";
import {useProjectViewState} from "./projectViewContext";
import {useProjectTranslation} from "../../projectContext";
import {getHrDateTime, getHrLatLngStr, getProjectPeriod} from "../../projectUtility";
import {projectViewStyles} from "../../projectViewStyles";

const useStyles = makeStyles(() => projectViewStyles);

export const ArcheologyProjectViewDetails = ({
                                                 model,
                                                 onHide,
                                                 handleOpenProject,
                                             }) => {
    const language = "no";
    const tp = useProjectTranslation();
    const classes = useStyles();
    const [getMuseumName] = useMuseumNameFromCollectionId();
    const museumName = getMuseumName(model.collectionId);

    const {facets} = useProjectViewState();

    const {title, content, createdAt} = model;
    const {archeologyprojectref} = content;
    const {
        id,
        type,
        description,
        startedAt,
        endedAt,
        monumentPeriod,
        monumentType,
        dataset,
        latLng
    } = archeologyprojectref;

    const openInNewTab = (_) => {
        window
            .open(
                `https://arkeologi.org/${model.content.archeologyprojectref.uuid}`,
                "_blank"
            )
            .focus();
    };

    const getProjectId = () => {
        return id;
    }

    const getProjectType = () => {
        return type;
    };

    const getProjectDescription = () => {
        return description;
    };

    const getHrProjectPeriod = () => {
        return getProjectPeriod(startedAt, endedAt);
    };


    const getProjectMonumentPeriod = () => {
        return monumentPeriod || "<uspesifisert>";
    };

    const getProjectMonumentType = () => {
        return monumentType || "<uspesifisert>";
    };


    const getProjectDataset = () => {
        let ret = '';
        const datasetNode = dataset;
        if (datasetNode && Object.keys(datasetNode)) {
            ret = datasetNode[language] || "";
        }
        return ret;
    };

    const getProjectLatLng = () => {
        if (!latLng) {
            return '';
        }
        return getHrLatLngStr(latLng.lat, latLng.lng);
    };

    return (museumName && model && (
        <Paper
            sx={{
                outline: "none !important",
                width: "100%",
                backgroundColor: "#55524F",
                paddingBottom: 1,
            }}
        >
            {/*<ProjectImage />*/}

            {/* Main content */}
            <Box
                sx={{
                    paddingLeft: 4,
                    paddingRight: 4,
                }}
            >
                {/* Project icon */}
                <Box className={classes.boxProjectIcon}>
                    <AccountTreeSharpIcon
                        sx={{
                            width: 40,
                            height: 40,
                        }}
                        color={"secondary"}
                    />
                </Box>
                {/* Project facts */}
                <Box sx={{display: "flex"}}>
                    <Typography variant={"h5"} sx={{color: "white"}}>
                        {title}
                    </Typography>
                    <IconButton onClick={openInNewTab} sx={{color: "white"}}>
                        <OpenInNewIcon/>
                    </IconButton>
                </Box>
                <If boolean={getProjectDataset()}>
                    <Typography variant={"caption"} sx={{color: "#ddd"}}>
                        {getProjectDataset()}
                    </Typography>
                </If>
                <Grid
                    container
                    direction={"row"}
                    columnSpacing={4}
                    marginTop={1}
                    sx={{
                        paddingLeft: 1,
                        paddingRight: 2,
                    }}
                >
                    {/* 1st column */}
                    <Grid className={classes.gridContentColumnLeft}>
                        <Box>
                            <Typography variant={"caption"}>
                                {tp("lblProjectId", "Prosjekt ID")}: {getProjectId()}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={"caption"}>
                                {tp("lblMuseum", "Museum")}: {museumName}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={"caption"}>
                                {tp("lblLocation", "Lokasjon")}: {getProjectLatLng()}
                            </Typography>
                        </Box>
                        <Box>
                            <Typography variant={"caption"}>
                                {tp("lblPeriod", "Periode")}: {createdAt & getHrDateTime(createdAt, language)}
                            </Typography>
                        </Box>
                        {archeologyprojectref.systemName && <>
                            <Box>
                                <Typography variant={"caption"}>
                                    {tp("lblSystemNamne", "Systemnavn")}: {archeologyprojectref.systemName}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography variant={"caption"}>
                                    {tp("lblSystemId", "System ID")}: {archeologyprojectref.systemId}
                                </Typography>
                            </Box>
                        </>}
                    </Grid>
                    {/* 2nd column */}
                    <Grid className={classes.gridContentColumnCenter}>
                        <Grid container direction={"row"}>
                            <Grid className={classes.gridFactColumn}>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp("lblPeriod", "Periode")}: {getHrProjectPeriod()}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid className={classes.gridFactColumn}>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp("lblMonumentType", "Monumenttype")}: {getProjectMonumentType()}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                        <Grid container direction={"row"}>
                            <Grid className={classes.gridFactColumn}>
                                <Grid>
                                    <Box>
                                        <Typography variant={"caption"}>
                                            {tp("lblProjectType", "Prosjekttype")}: {getProjectType()}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid className={classes.gridFactColumn}>
                                <Grid>
                                    <Box>
                                        <Typography variant={"caption"}>
                                            {tp("lblMonumentPeriod", "Monumentperiode")}: {getProjectMonumentPeriod()}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Box><Typography variant={"caption"}>
                                    {tp("lblDescription", "Beskrivelse")}: {getProjectDescription()}</Typography></Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* 3rd column */}
                    <Grid className={classes.gridContentColumnRight} item>
                        <Grid container direction={"row"}>
                            <Grid sx={{width: "200px"}}>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp("lblImages", "Bilder")}: {facets.documentTypes?.StillImage?.count || 0}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp("lblModel", "Modell")}: {facets.documentTypes?.Modell?.count || 0}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"}>
                            <Grid sx={{width: "200px"}}>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp('lblDocument', 'Dokument')}: {facets.documentTypes?.Dokument?.count || 0}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp('lblGeodata', 'Geodata')}: {facets.documentTypes?.Geodata?.count || 0}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"}>
                            <Grid sx={{width: "200px"}}>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp('lblAudio', 'Lyd')}: {facets.documentTypes?.Audio?.count || 0}
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp('lblTable', 'Tabell')}: {facets.documentTypes?.Tabell?.count || 0}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Grid container direction={"row"}>
                            <Grid sx={{width: "200px"}}>
                                <Box>
                                    <Typography variant={"caption"}>
                                        {tp('lblVideo', 'Film')}: {facets.documentTypes?.Video?.count || 0}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid></Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {onHide && handleOpenProject && (
                    <Box className={classes.buttonToolbar}>
                        <Button color={"secondary"} variant={"text"} onClick={onHide}>
                            {tp('btnClose', 'Lukk')}
                        </Button>
                        <Button
                            onClick={handleOpenProject}
                            color={"secondary"}
                            variant={"contained"}
                            sx={{marginLeft: 2}}
                        >
                            {tp('btnOpenProject', 'Åpne prosjekt')}
                        </Button>
                    </Box>
                )}
            </Box>
        </Paper>
    ));
};
