import {
    CLEAR_FILTERS,
    CLEAR_FILTERS_DONE,
    SET_SEARCHING,
    useSearchDispatch,
    useSearchTranslation
} from "./SearchContext";
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";

/**
 * Component for displaying a message when no search results are found.
 * Handles button click, resetting search criterias and filters.
 */
export const NoHits = () => {
    const searchDispatch = useSearchDispatch();

    const t = useSearchTranslation();

    /**
     * Handles button click, resetting search criterias and filters.
     */
    const handleOnClick = () => {
        searchDispatch({type: CLEAR_FILTERS});
        setTimeout(() => {
            searchDispatch({type: CLEAR_FILTERS_DONE});
            searchDispatch({type: SET_SEARCHING});
        }, 500);
    };

    return <Box
        sx={{
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            paddingBottom: '1rem',
        }}>
        <Stack direction={"column"}>
            <Stack direction={"row"}
                   alignItems={"center"}>
                <ImageSearchIcon
                    sx={{
                        fontSize: '64px',
                        color: '#bbb'
                    }}/>
                <Typography ml={1}
                            variant={"h6"}>
                    {t('noResultsFound', 'Vi fant ikke det du søkte etter.')}
                </Typography>
            </Stack>

            <Typography mt={1}>
                {t('tryAnotherPhrase', 'Prøv med et annet søkeord eller andre søkefilter.')}
            </Typography>
            <Typography>
                {t('resetSearchInfo', 'Du kan nullstille søket ditt ved å trykke her:')}
            </Typography>

            <Button
                onClick={handleOnClick}>
                {t('btnResetSearchCriterias', 'Nullstill')}
            </Button>
        </Stack>
    </Box>;
};