import {useAuthsState} from "./authsContext";
import {useIsAppAdmin} from "./useIsAppAdmin";

export const useCanEditInCollections = ({collectionIds}) => {
    const {userData, museumCollections} = useAuthsState();
    const isAppAdmin = useIsAppAdmin();

    if (isAppAdmin) {
        return true;
    } else if (!collectionIds || collectionIds.length === 0) {
        return false;
    } else if (collectionIds.length) {
        const museumIds = museumCollections.filter(museumCollection => {
            return collectionIds.includes(museumCollection.collectionId)
        }).map(museum => museum.museumId);
        const isAdminFiltered = userData['appAccess'].museums.filter(museum =>
            museumIds.includes(museum.id)).filter(museum =>
            museum['applications'].find(app =>
                window._env_.REACT_APP_ID === app.id
                && ['Admin', 'Editor'].includes(app.role.name)));
        return museumIds.length > 0 && isAdminFiltered.length === museumIds.length
    } else {
        return false;
    }
}
