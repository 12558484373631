import {useCallback, useEffect, useRef, useState} from "react";
import {ADD_MESSAGE, useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {orderPlaybackLink} from "../dms/dmsSecurity";
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';
import './player.css';
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";
import {CONVERSION_ERROR, useObjectConversionDispatch} from "../objectview/ObjectConversionContext";
import {getDmsIdFromModel} from "../utility";
import {clientLog} from "../clientLog";

import PropTypes from 'prop-types';

/**
 * DAMS audio player, based on the React H5 Audio Player:
 * https://www.npmjs.com/package/react-h5-audio-player
 *
 * @param model
 * @returns {JSX.Element}
 * @constructor
 */
const DAMSAudioPlayer = ({model}) => {
    const {navigating} = useSearchResultDetailsState();
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();
    const objectConversionStatusDispatch = useObjectConversionDispatch();

    const playerRef = useRef();

    const [audioUrl, setAudioUrl] = useState("");
    const [readyToPlay, setReadyToPlay] = useState(false);

    const collectionId = model.collectionId;
    const sourceId = getDmsIdFromModel(model);
    const fileTitle = model.content.mediae[0].reference.title;

    const showError = useCallback(
        () => {
            snackbarDispatch({
                type: ADD_MESSAGE,
                message: {
                    title: t("audioPlaybackFailedTitle", "Lydavspilling feilet"),
                    body: t(
                        "audioPlaybackFailedMessage",
                        "Det oppstod en ukjent feil - forsøk igjen senere."
                    ),
                    type: "error",
                },
            });
        },
        [t, snackbarDispatch]
    );


    /**
     * Method used to get the playback link for the current sourceId.
     * @type {(function(*): void)|*}
     */
    const getPlaybackLink = useCallback(sourceId => {

        /**
         * Returns the file extension of the current audio file, used when determining which audio stream to play.
         * @returns {string}
         */
        const getAudioFileExtension = () => {
            const ext = fileTitle.substring(fileTitle.lastIndexOf('.') + 1);
            return ext !== 'mp3' ? 'mp3' : ext;
        };

        // Get the playback link from DMS.
        orderPlaybackLink(collectionId, sourceId).then((json) => {
            if (!json) {
                objectConversionStatusDispatch({
                    type: CONVERSION_ERROR
                });
            } else {
                const {urls} = json;
                setAudioUrl(urls[getAudioFileExtension()]);
            }
        });
    }, [collectionId, objectConversionStatusDispatch, fileTitle]);

    /**
     * Hook used to fetch the audio playback URL from DMS.
     */
    useEffect(() => {
        if (!sourceId) {
            return;
        }
        getPlaybackLink(sourceId);
    }, [sourceId, getPlaybackLink]);

    useEffect(() => {
        if (navigating) {
            playerRef.current['audio']?.current?.pause();
        }
    });

    /**
     * Hook used to hide the controls until the stream has completed buffering and is ready to be played.
     */
    useEffect(() => {
        const elements = document.getElementsByClassName('rhap_controls-section');
        if (elements.length > 0) {
            const el = elements[0];
            if (!readyToPlay) {
                el.style.opacity = 0;
            } else {
                el.animate({opacity: [0, 1]}, {
                    duration: 500
                });
                el.style.opacity = 1;
            }
        }
    }, [readyToPlay]);

    return <AudioPlayer
        showDownloadProgress={true}
        autoPlayAfterSrcChange={false}
        autoPlay={false}
        src={audioUrl}
        ref={playerRef}
        onCanPlay={_e => setReadyToPlay(true)}
        // onPlay={_e => console.debug('audio started')}
        // onPause={_e => console.debug('audio paused')}
        // onEnded={_e => console.debug('audio ended')}
        onPlayError={err => {
            clientLog('error', err, 'DAMSAudioPlayer');
            showError();
        }}
    />;
};

DAMSAudioPlayer.propTypes = {
    "model": PropTypes.object.isRequired
};

export {DAMSAudioPlayer};