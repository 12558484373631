import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDocumentState } from "../documents/documentContext";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { DraggableFileItem } from "./DraggableFileItem";

const PREFIX = "DraggableFilesGrid";

const classes = {
  gridList: `${PREFIX}-gridList`,
};

const StyledGridList = styled(ImageList)(() => ({
  [`&.${classes.gridList}`]: {},
}));

export const DraggableFilesGrid = ({ checked, checkFile }) => {
  const [dragImage, setDragImage] = useState();
  const { saved, files } = useDocumentState();

  useEffect(() => {
    let img = new Image();
    img.src = "/perm_media-24px.svg";
    img.onload = () => setDragImage(img);
  }, []);

  const fileNameToDocumentId = (fileNames) => {
    return saved
      .filter((document) =>
        fileNames.includes(document.content.mediae[0].reference.title)
      )
      .map((document) => document.id);
  };

  const drag = (fileTitle) => (event) => {
    const checkedFiles = Object.keys(checked).filter((file) => checked[file]);
    if (checkedFiles.length) {
      event.dataTransfer.setData(
        "application/json",
        JSON.stringify(fileNameToDocumentId(checkedFiles))
      );
      event.dataTransfer.setDragImage(dragImage, 24, 24);
    } else {
      event.dataTransfer.setData(
        "application/json",
        JSON.stringify(fileNameToDocumentId([fileTitle]))
      );
    }
    event.dataTransfer.effectAllowed = "link";
  };

  const dragEnabled = (fileTitle) => checked[fileTitle];

  return (
    <StyledGridList
      cellHeight={160}
      className={classes.gridList}
      cols={3}
      spacing={16}
    >
      {files.map((file) => (
        <ImageListItem
          key={file.title}
          cols={1}
          file={file}
          draggable={dragEnabled(file.title)}
          onDragStart={dragEnabled(file.title) ? drag(file.title) : () => null}
        >
          <DraggableFileItem
            file={file}
            checked={checked[file.title]}
            onCheck={checkFile(file.title)}
          />
        </ImageListItem>
      ))}
    </StyledGridList>
  );
};
