import React, {useCallback, useState} from "react";
import {makeStyles} from "@mui/styles";
import {deepOrange, orange} from "@mui/material/colors";
import Box from "@mui/material/Box";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";
import {clientLog} from "../clientLog";
import useDeepCompareEffect from "use-deep-compare-effect";
import {getObjectStatus} from "../getObjectStatus";

const useStyles = makeStyles({
    progressInfo: {
        position: "absolute",
        cursor: "pointer",
        left: 0,
        backgroundColor: orange.A400,
        color: "black",
        zIndex: 20,
    },

    progressFailed: {
        position: "absolute",
        cursor: "pointer",
        left: 0,
        backgroundColor: deepOrange.A700,
        color: "#eee",
    },
});


let intervalId = null;

export const ObjectConversionStatus = ({
                                           t,
                                           collectionId,
                                           smallScreen,
                                           callback,
                                       }) => {


    const componentName = 'ObjectConversionStatus';

    const {model} = useSearchResultDetailsState();
    const classes = useStyles();
    const [currentStatus, setCurrentStatus] = useState();

    const [dmsId, setDmsId] = useState(null);

    const log = message => {
        clientLog('debug', message, componentName);
    }

    /**
     * Resets/stops all requests.
     */
    const resetRequests = () => {
        if (intervalId) {
            log(`resetting requests - ${intervalId}`);
            window.clearInterval(intervalId);
            intervalId = null;
        }
    };

    /**
     * Returns the label to display if the conversion status is failed, convert or converting.
     * @type {(function(*, *): (*|string))|*}
     */
    const getLabel = useCallback(
        (status, p) => {
            switch (status) {
                case "failed":
                    return t('txtConvertNotSupported', 'Avspilling av dette formatet er ikke støttet');
                case "convert":
                    return t(
                        "txtRemainingConvertQueue",
                        "Filen er ikke klar til avspilling. Den ligger i konverteringskø."
                    );
                case "converting":
                    return t(
                        "txtRemainingConvert",
                        "Filen er ikke klar til avspilling. Det gjenstår {{remainingProgress}}% av konverteringen.",
                        {remainingProgress: 100 - p}
                    );
                default:
                    return "";
            }
        },
        [t]
    );

    const getStatusCallback = json => {
        const progress = parseInt(json['conversionJobProgress']);
        const label = getLabel(json.status, progress);
        const {status, conversionJobProgress} = json;

        const className =
            status === "failed"
                ? classes.progressFailed
                : classes.progressInfo;
        setCurrentStatus(
            label === "" ? null : {className: className, label: label}
        );

        if (
            status === "done" ||
            status === "failed" ||
            conversionJobProgress === "100"
        ) {
            log('done or failed, cancelling all requests');
            resetRequests();
            if (status === "done") {
                callback();
            }
        }

        if (!intervalId && !['failed', 'error', 'done'].includes(status)) {
            intervalId = window.setInterval(getStatus, 3000);
        }
    }


    /**
     * Gets the conversion status for the current object.
     */
    const getStatus = () => {
        getObjectStatus(collectionId,
            dmsId,
            getStatusCallback,
            (error) => {
                clientLog('error', error, componentName);
            });
    };


    /**
     * Hook run when component is initialized.
     */
    useDeepCompareEffect(() => {
        // Reset existing requests.
        resetRequests();

        // Get status for the current object.
        setDmsId(model.content.mediae[0].reference.sourceId);
        if (dmsId) {
            getStatus();
        }
    }, [model, dmsId]);


    /**
     * When element unmounts, cancel all ongoing requests.
     */
    useDeepCompareEffect(() => {
        return () => {
            log('resetting all requests');
            resetRequests();
        };
    }, [resetRequests]);

    if (!dmsId || !currentStatus) {
        return <></>;
    } else if (currentStatus && !smallScreen) {
        return (
            <Box
                sx={{
                    top: "50%",
                    width: "75%",
                    textAlign: "center",
                }}
                className={currentStatus['className']}
            >
                {currentStatus['label']}
            </Box>
        );
    } else if (currentStatus && smallScreen) {
        return (
            <Box
                sx={{
                    position: "absolute",
                    padding: 2,
                    top: "20%",
                    textAlign: "left",
                    textAlignLast: "left",
                }}
                className={currentStatus['className']}
            >
                {currentStatus['label']}
            </Box>
        );
    }
};
