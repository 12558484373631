import React, {useEffect, useState} from "react";
import {CHANGE_STEP, SHOW_PRE_CHANGE, useNewFilesDispatch, useNewFilesState,} from "./NewFilesContext";
import {useNewFilesTranslation} from "./NewFilesTranslationContext";
import {CancelDialog} from "./CancelDialog";
import {CLEAR, useDocumentDispatch, useDocumentState,} from "../documents/documentContext";
import {useDmsState} from "../dms/dmsContext";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Typography from "@mui/material/Typography";
import {damsFetch} from "../app/damsFetch";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material/styles";

const StyledBox = styled(Box)(({theme}) => ({
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    position: 'fixed',
    bottom: 8,
    right: 0,
    display: 'flex',
    width: '100%',
    zIndex: 300,
}));

const StyledButton = styled(Button)(({theme}) => ({
    marginLeft: theme.spacing(4),
}));


export const FileActions = () => {
    const newFilesDispatch = useNewFilesDispatch();
    const documentDispatch = useDocumentDispatch();
    const {files, unsaved, saved} = useDocumentState();
    const {activeStepIndex, stepsWithPreCheck, numSteps} = useNewFilesState();
    const [showCancelDialog, setShowCancelDialog] = useState(false);
    const t = useNewFilesTranslation();
    const navigate = useNavigate();
    const {progressByFile} = useDmsState();
    const {enableNextButton} = useDocumentState();

    const nextStep = () => {
        if (stepsWithPreCheck.includes(activeStepIndex)) {
            newFilesDispatch({
                type: SHOW_PRE_CHANGE,
            });
        } else {
            newFilesDispatch({
                type: CHANGE_STEP,
                stepIndex: 1 + activeStepIndex,
            });
        }
    };

    const disableContinue = () => {
        if (activeStepIndex === 0) {
            return 0 === files.length || pendingUploads;
        } else if (activeStepIndex === 1) {
            return (unsaved.length !== 0 || !enableNextButton) && pendingUploads;
        } else {
            return false;
        }
    };

    const [pendingUploads, setPendingUploads] = useState(true);

    useEffect(() => {
        if (files.length === 0 || Object.values(progressByFile).length !== files.length) {
            return;
        }
        const pu = Object.values(progressByFile).filter((value) => 100 === value).length <
            files.length
        setPendingUploads(pu);
    }, [progressByFile]);

    const continueOrFinishButton = () => {
        if (2 === activeStepIndex) {
            return (
                <StyledButton
                    color={"primary"}
                    onClick={() => navigate("/my-files/")}
                    endIcon={<NavigateNextIcon/>}
                >
                    {t("complete", "Fullfør steget")}
                </StyledButton>
            );
        } else {
            return (
                <StyledButton
                    color={"primary"}
                    onClick={nextStep}
                    disabled={disableContinue()}
                    endIcon={<NavigateNextIcon/>}
                >
                    {t("continue", "Fullfør steget")}
                </StyledButton>
            );
        }
    };

    const deleteDocument = (id) => {
        return damsFetch("/documents/" + id, {
            method: "DELETE",
        })
            .then((json) => {
                return Promise.resolve(json);
            })
            .catch((error) => {
                return Promise.reject(error);
            });
    };

    const cancelFileupload = () => {
        if (saved.length > 0) {
            Promise.allSettled(saved.map((d) => deleteDocument(d.uniqueId))).then(
                () => {
                    documentDispatch({type: CLEAR});
                    navigate("/");
                }
            );
        } else {
            documentDispatch({type: CLEAR});
            navigate("/");
        }
    };

    if (activeStepIndex === 0 || activeStepIndex === 1 || unsaved.length === 0) {
        return (
            <StyledBox>
                <StyledButton
                    color={"secondary"}
                    onClick={() => setShowCancelDialog(true)}
                >
                    {t("cancel", "Avbryt")}
                </StyledButton>
                <Box
                    flexGrow={1}
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        justifyItems: "center",
                        alignItems: "center",
                        alignContent: "center",
                    }}
                >
                    <Typography variant={"caption"}>
                        {t('headingStep', 'Steg')} {activeStepIndex + 1} / {numSteps}
                    </Typography>
                </Box>
                {continueOrFinishButton()}
                <CancelDialog
                    open={showCancelDialog}
                    onYes={cancelFileupload}
                    onNo={() => setShowCancelDialog(false)}
                />
            </StyledBox>
        );
    } else {
        return <></>;
    }
};
