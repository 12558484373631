import React from "react";
import {
    PREVIEW_MODE,
    useSearchResultDetailsDispatch,
    useSearchResultDetailsTranslation,
} from "../search-result-details/SearchResultDetailsContext";
import {FormikSubmitButton} from "../form/FormikSubmitButton";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {DialogActions} from "@mui/material";

export const EditActions = () => {
    const searchResultDetailsDispatch = useSearchResultDetailsDispatch();
    const t = useSearchResultDetailsTranslation();

    const handleCancel = () => {
        searchResultDetailsDispatch({type: PREVIEW_MODE});
    };

    return (
        <DialogActions>
            <Box flexGrow={1}/>
            <Button onClick={handleCancel}>{t("cancel", "Avbryt")}</Button>
            <FormikSubmitButton variant="contained" color={"secondary"}>
                {t("save", "Lagre")}
            </FormikSubmitButton>
        </DialogActions>
    );
};
