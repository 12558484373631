import Box from "@mui/material/Box";
import {LazyLoadImage} from "react-lazy-load-image-component";
import React from "react";
import {useSearchResultDetailsState} from "../../search-result-details/SearchResultDetailsContext";

/**
 * Displays an image in full screen on small screens.
 * @param cssClass
 * @param image
 * @param imageClickCallback
 * @returns {JSX.Element}
 * @constructor
 */
export const SmallScreenImageViewer = ({cssClass, image, imageClickCallback}) => {
    const {model} = useSearchResultDetailsState();

    return (
        <Box
            sx={{
                backgroundColor: "#ddd",
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
            }}
        >
            <LazyLoadImage
                alt={model.title}
                title={model.title}
                effect="blur"
                src={image}
                className={cssClass}
                onClick={imageClickCallback}
            />
        </Box>
    );
};