import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {ADD_MESSAGE, useSnackbarDispatch, useSnackbarTranslation,} from "../snackbar/SnackbarContext";
import {clientLog} from "../clientLog";

export const useAddPlace = () => {
    const snackbarDispatch = useSnackbarDispatch();
    const t = useSnackbarTranslation();

    const callback = json => {
        return Promise.resolve({
            referenceType: "place",
            collectionId: json['collectionId'],
            reference: {
                id: json['id'],
                uniqueId: json['uniqueId'],
                locale: json['locale'],
                _action: "put",
                title: json['title'],
                status: "published",
                source: json['source'],
                sourceId: json['sourceId'],
                content: {
                    name: json['content'].name,
                }
            },
        });
    };

    const errorCallback = error => {
        clientLog('error', error, 'useAddPlace');
        snackbarDispatch({
            type: ADD_MESSAGE,
            message: {
                title: t("savePlaceFailTitle", "Feil ved lagring av sted"),
                body: t("savePlaceFailBody", "Stedet ble ikke lagret."),
                type: "error",
            },
        });
        return Promise.reject(error);
    };

    return ({places, _locale = "no"}) => {
        return Promise.all(
            places.map((place) => {
                const newPlace = {
                    collectionId: place.collectionId,
                    schemaId: 1,
                    locale: "no",
                    _action: "put",
                    title: place.content?.name || place.name,
                    status: "published",
                    source: place.source,
                    sourceId: place.sourceId,
                    documentType: "place",
                    content: place.content ? {...place.content} : {}
                };
                return damsFetch(`/documents`, {
                    method: "POST",
                    body: JSON.stringify(decamelizeKeysDeep(newPlace)),
                })
                    .then(callback)
                    .catch(errorCallback);
            })
        );
    };
};
