import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import FolderIcon from "@mui/icons-material/Folder";
import Typography from "@mui/material/Typography";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid2";
import {useFileUploadState} from "../fileUploadContext";

/**
 * Renders a base folder card component.
 *
 * @param {Object} props - The properties object.
 * @param {Function} props.onClickHandler - Click handler for the entire card, if using the ClickableFolderCard.
 * @param {Function} props.onDroppedHandler - Drop handler for the entire card, if not using the ClickableFolderCard.
 * @param {Function} props.onDragOverHandler - Drag over handler for the entire card, if not using the ClickableFolderCard.
 * @param {Object} props.folder - The folder the objects are being added to.
 * @param {string} props.folder.name - The name of the folder.
 * @param {string} props.folder.museumName - The name of the museum associated with the folder.
 * @return {JSX.Element} The rendered folder card component.
 */
export const FolderCardBase = (props) => {

    /**
     * Props object:
     *   onClickHandler - Click handler for the entire card, if using the ClickableFolderCard.
     *   onDroppedHandler - Drop handler for the entire card, if not using the ClickableFolderCard.
     *   onDragOverHandler - Drag over handler for the entire card, if not using the ClickableFolderCard.
     *   folder - The follder the objects are being added to.
     */
    const {
        onClickHandler,
        onDroppedHandler,
        onDragOverHandler,
        folder
    } = props;

    const {addingToFolder, folderId} = useFileUploadState();

    return (
        <Card elevation={0}
              sx={{maxWidth: '200px', minWidth: '200px', width: '200px'}}>
            <CardActionArea
                onClick={onClickHandler ? onClickHandler : null}
                onDrop={onDroppedHandler ? onDroppedHandler : null}
                onDragOver={onDragOverHandler ? onDragOverHandler : null}
            >
                <CardContent sx={{padding: 0}}>
                    <Grid container
                          direction={"row"}
                          sx={{
                              backgroundColor: '#ddd',
                              padding: '12px',
                              borderRadius: '4px'
                          }}>

                        <Grid>
                            {!addingToFolder && <>
                                <FolderIcon sx={{fontSize: '3rem', color: 'gray'}}/>
                            </>}
                            {addingToFolder && folderId === folder.id && <CircularProgress disableShrink={true}/>}
                        </Grid>

                        <Grid>
                            <Grid container
                                  direction={"column"}
                                  sx={{marginLeft: '8px'}}>
                                <Grid>
                                    <Typography
                                        sx={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            wordWrap: 'anywhere',
                                            wordBreak: 'break-all',
                                            maxWidth: '95px',
                                        }}>
                                        {folder.name}
                                    </Typography>
                                </Grid>

                                <Grid>
                                    <Typography variant={"caption"} noWrap>
                                        {folder.museumName}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>

                </CardContent>
            </CardActionArea>
        </Card>
    );
};