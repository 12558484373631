import React from "react";
import Fade from "@mui/material/Fade";
import LinearProgress from "@mui/material/LinearProgress";

export const ProgressIndicator = () => {
  return (
    <div>
      <Fade
        in={true}
        style={{
          transitionDelay: "400ms",
        }}
        unmountOnExit={true}
      >
        <LinearProgress />
      </Fade>
    </div>
  );
};
