import {useState} from "react";
import {damsFetch} from "../app/damsFetch";
import decamelizeKeysDeep from 'decamelize-keys-deep'
import {clientLog} from "../clientLog";

export const usePostFolder = () => {
    const initialState = {
        error: false,
        pending: false,
        success: false,
        newFolder: {}
    };

    const [response, setResponse] = useState(initialState);

    const setPendingState = () => {
        setResponse({
            ...initialState,
            pending: true
        });
    };

    const successCallback = (json) => {
        let newFolder = {
            id: json.id,
            uniqueId: json.uniqueId,
            name: json.title,
        };

        if (json.content?.tags) {
            newFolder.tags = json.content?.tags;
        }

        setResponse({
            ...initialState,
            newFolder: newFolder,
            success: true
        });
    };

    const errorCallback = error => {
        clientLog('error', error, 'usePostFolder');
        setResponse({
            ...initialState,
            error: true
        });
    };

    const post = folder => {
        const options = {
            method: 'POST',
            body: JSON.stringify(decamelizeKeysDeep(folder))
        };

        setPendingState();

        damsFetch(`/folders`, options)
            .then(successCallback)
            .catch(errorCallback)
    };

    return [response, post]
}