import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

export const SET_FOLDERS = 'myFoldersContext/setFolders';
export const ADD_FOLDER = 'myFoldersContext/addFolder';

const MyFoldersStateContext = createContext(undefined);
const MyFoldersDispatchContext = createContext(undefined);
const FolderTranslationContext = createContext(undefined);

const initialState = {
    myFolders: []
}

const myFoldersReducer = (state, action) => {
    switch (action.type) {
        case SET_FOLDERS:
            return {
                ...state,
                myFolders: action.folders
            }
        case ADD_FOLDER:
            return {
                ...state,
                myFolders: [...state.myFolders, action.folder]
                    .sort((a, b) => a.name.localeCompare(b.name))
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`)
    }
}

export const MyFoldersProvider = ({children}) => {
    const [state, dispatch] = useReducer(myFoldersReducer, initialState, undefined);
    const {t, ready} = useTranslation('dams.folder', {useSuspense: false})

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <FolderTranslationContext.Provider value={t}>
            <MyFoldersStateContext.Provider value={state}>
                <MyFoldersDispatchContext.Provider value={dispatch}>
                    {children}
                </MyFoldersDispatchContext.Provider>
            </MyFoldersStateContext.Provider>
        </FolderTranslationContext.Provider>
    )
}

export const useMyFoldersState = () => {
    const context = useContext(MyFoldersStateContext);
    if (undefined === context) {
        throw new Error('useMyFoldersState must be used within an MyFoldersProvider')
    }

    return context
}

export const useMyFoldersDispatch = () => {
    const context = useContext(MyFoldersDispatchContext);
    if (undefined === context) {
        throw new Error('useMyFoldersDispatch must be used within an MyFoldersProvider')
    }

    return context
}

export const useFolderTranslation = () => {
    const context = useContext(FolderTranslationContext);
    if (undefined === context) {
        throw new Error('useFolderTranslation must be used within an FolderTranslationProvider.');
    }
    return context;
}