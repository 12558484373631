import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";

const SnackbarStateContext = createContext(undefined);
const SnackbarDispatchContext = createContext(undefined);
const SnackbarTranslationContext = createContext(undefined);

export const HIDE = 'snackbarContext/hide';
export const ADD_MESSAGE = 'snackbarContext/addMessage';
export const REMOVE_FIRST_MESSAGE = 'snackbarContext/removeFirstMessage';

const initialState = {
    open: false,
    messages: []
}

const snackbarReducer = (state, action) => {
    switch (action.type) {
        case HIDE:
            return {
                ...state,
                open: false,
                messages: []
            }
        case ADD_MESSAGE:
            return {
                ...state,
                open: true,
                messages: [...state.messages, action.message]
            }
        case REMOVE_FIRST_MESSAGE:
            return {
                ...state,
                messages: state.messages.slice(1),
                open: 1 < state.messages.length
            }
        default:
            throw new Error(`Unhandled actionType: ${action.type}`)
    }
}

export const SnackbarProvider = ({children}) => {
    const [state, dispatch] = useReducer(snackbarReducer, initialState, undefined);
    const {t, ready} = useTranslation('dams.feedback', {useSuspense: false})

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>
    }

    return (
        <SnackbarTranslationContext.Provider value={t}>
            <SnackbarStateContext.Provider value={state}>
                <SnackbarDispatchContext.Provider value={dispatch}>
                    {children}
                </SnackbarDispatchContext.Provider>
            </SnackbarStateContext.Provider>
        </SnackbarTranslationContext.Provider>
    )
}

export const useSnackbarState = () => {
    const context = useContext(SnackbarStateContext);
    if (undefined === context) {
        throw new Error("useSnackbarState must be used withing a SnackbarProvider");
    } else {
        return context;
    }
}

export const useSnackbarDispatch = () => {
    const context = useContext(SnackbarDispatchContext);

    if (undefined === context) {
        throw new Error("useSnackbarDispatch must be used within a SnackbarProvider");
    } else {
        return context;
    }
}

export const useSnackbarTranslation = () => {
    const context = useContext(SnackbarTranslationContext);

    if (undefined === context) {
        throw new Error("useSnackbarTranslation must be used within a SnackbarProvider");
    } else {
        return context;
    }
}