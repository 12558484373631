import {Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DialogContentText from "@mui/material/DialogContentText";

/**
 * A dialog for confirming deletion of a local asset.
 *
 * @param {Object} props Properties of the component.
 * @param {boolean} props.open Whether the dialog is open.
 * @param {Function} props.onClose Called when the dialog should close.
 * @param {Function} props.onConfirm Called when the dialog is confirmed.
 * @param {Function} props.t Translation function.
 * @returns {ReactElement} The JSX element.
 */
export const ConfirmDeleteDialog = ({open, onClose, onConfirm, t}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{t('confirmDeleteDialogTitle', 'Slett')}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {t('confirmDeleteDialogText', 'Slette valgte rad?')}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>{t('btnCancel', 'Avbryt')}</Button>
                <Button onClick={onConfirm} variant={"contained"} autoFocus>{t('btnOk', 'Ok')}</Button>
            </DialogActions>
        </Dialog>
    );
};