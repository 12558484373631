import React, {createContext, useContext, useReducer} from "react";
import {useTranslation} from "react-i18next";
import {ProgressIndicator} from "../app/ProgressIndicator";


export const CHECK = "documentTypeContext/check";
export const CHECK_ALL = "documentTypeContext/checkAll";
export const SET_DOCUMENT_TYPES = 'documentTypeContext/setDocumentTypes';

const DocumentTypeStateContext = createContext(undefined);
const DocumentTypeDispatchContext = createContext(undefined);
const DocumentTypeTranslationContext = createContext(undefined);

const initialState = {
    allChecked: true,
    itemsChecked: {
        all: false,
        images: true,
        audio: true,
        video: true,
        documents: true,
        model: true,
        projectGeodata: true,
        projectTable: true,
        projects: true,
    },
    mapping: {
        images: "StillImage",
        audio: "Audio",
        video: "Video",
        documents: "Misc OR Dokument", // Mapping to include project documents (Dokument)
        model: "Modell",
        projectTable: "Tabell",
        projectGeodata: "Geodata",
        projects: "Prosjekter",
    },
    documentTypes: ''
};


/**
 * New logic, introduced with the FilterDocumentTypes element,
 * may replace the CHECK and CHECK_ALL logic below.
 * @param state
 * @param items
 * @returns {(*&{documentTypes: string})|{mapping: {model: string, images: string, projectGeodata: string, projects: string, documents: string, projectTable: string, audio: string, video: string}, documentTypes: string, itemsChecked: {all: boolean, model: boolean, images: boolean, projectGeodata: boolean, projects: boolean, documents: boolean, projectTable: boolean, audio: boolean, video: boolean}, allChecked: boolean}}
 */
const getStateObjectFromItems = (state, items) => {
    if (items.length === 0) {
        return initialState;
    }

    let documentTypesCriteria = [];
    const s = {...state};

    items.forEach(i => {
        documentTypesCriteria.push(s['mapping'][i]);
    });

    documentTypesCriteria = documentTypesCriteria.sort((a, b) => {
        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
    });

    return {
        ...s,
        documentTypes: documentTypesCriteria.join(' OR ')
    };
};

/**
 * Returns the list of default document types, used when searching.
 * @returns {string}
 */
export const getDefaultDocumentTypesConfig = (removeProjectsOption) => {
    let excludeItems = ['all'];

    if (removeProjectsOption) {
        excludeItems.push('projects');
    }

    const items = Object.keys(initialState.itemsChecked)
        .filter(k => !excludeItems.includes(k));

    return getStateObjectFromItems(initialState, items).documentTypes
};

const documentTypeReducer = (state, action) => {
    let retValue;
    switch (action.type) {
        case SET_DOCUMENT_TYPES:
            retValue = getStateObjectFromItems(state, action.items);
            break;

        case CHECK:
            retValue = {
                ...state,
                allChecked: false,
                itemsChecked: {
                    ...state.itemsChecked,
                    [action.name]: action.value,
                },
            };
            break;
        case CHECK_ALL:
            retValue = {
                ...state,
                allChecked: action.value,
                itemsChecked: {
                    images: action.value,
                    audio: action.value,
                    video: action.value,
                    documents: action.value,
                    model: action.value,
                    projectTable: action.value,
                    projectGeodata: action.value
                },
            };
            break;
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }

    if (
        Object.entries(retValue.itemsChecked).filter((c) => c[1] === false).length ===
        Object.keys(retValue.itemsChecked).length &&
        !retValue.allChecked
    ) {
        retValue = {
            ...state,
            allChecked: true,
            itemsChecked: {images: true, audio: true, video: true, documents: true},
        };
    }
    return retValue;
};

export const DocumentTypeProvider = ({children}) => {
    const [state, dispatch] = useReducer(
        documentTypeReducer,
        initialState,
        undefined
    );
    const {t, ready} = useTranslation("dams.documentType", {
        useSuspense: false,
    });

    if (!ready && !process.env.JEST_WORKER_ID) {
        return <ProgressIndicator/>;
    }

    return (
        <DocumentTypeTranslationContext.Provider value={t}>
            <DocumentTypeStateContext.Provider value={state}>
                <DocumentTypeDispatchContext.Provider value={dispatch}>
                    {children}
                </DocumentTypeDispatchContext.Provider>
            </DocumentTypeStateContext.Provider>
        </DocumentTypeTranslationContext.Provider>
    );
};

export const useDocumentTypeState = () => {
    const context = useContext(DocumentTypeStateContext);
    if (undefined === context) {
        throw new Error(
            "useDocumentTypeState must be used within a DocumentTypeProvider"
        );
    }
    return context;
};

export const useDocumentTypeDispatch = () => {
    const context = useContext(DocumentTypeDispatchContext);
    if (undefined === context) {
        throw new Error(
            "useDocumentTypeDispatch must be used withing a DocumentTypeProvider"
        );
    }
    return context;
};

export const useDocumentTypeTranslation = () => {
    const context = useContext(DocumentTypeTranslationContext);
    if (undefined === context) {
        throw new Error(
            "useDocumentTypeTranslation must be used within an DocumentTypeTranslationProvider."
        );
    }
    return context;
};
