import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {MetadataAccordion} from "./MetadataAccordion";
import {SummaryAccordion} from "./SummaryAccordion";
import {useAuthsState} from "../auths/authsContext";
import {useMyMuseums} from "../museum/useMyMuseums";
import {ObjectConversionProvider} from "./ObjectConversionContext";
import {format} from "../app/dateFormat";
import {getMuseumNameFromCollectionId} from "../utility";
import {ChipList} from "./ChipList";
import React, {useEffect, useState} from "react";
import {MetadataRow} from "./MetadataRow";
import {LicenseItem} from "./LicenseItem";
import {CopyrightTypeItem} from "./copyrighttypeitem/CopyrightTypeItem";
import {CopyrightClausesItem} from "./CopyrightClausesItem";
import {ContentCopyOutlined} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useSearchResultDetailsState} from "../search-result-details/SearchResultDetailsContext";


/**
 * Component holding the accordions to the right of the object view.
 * @param model object  The model to display metadata for.
 * @param smallScreen bool
 * @param isProjectObject bool True if the current object belongs to an archeology project.
 * @param t object  Translation object
 * @returns {JSX.Element}
 * @constructor
 */
export const MetadataAccordions = ({smallScreen, isProjectObject, t}) => {
    const {model} = useSearchResultDetailsState();
    
    const {museumCollections} = useAuthsState();
    const myMuseums = useMyMuseums({});

    const [museumName, setMuseumName] = useState('');

    const {uniqueId, description, content} = model;
    const {
        productionDate,
        customIdentifier,
        producer,
        persons,
        languages,
        subjects,
        places,
        remarks,
        copyrightType,
        copyrightTypeOriginator,
        copyrightTypeResponsible,
        copyrightTypeDateUntil,
        licenses,
        copyrightInfo,
    } = content;


    /**
     * Renders the copyright type information.
     * @returns {JSX.Element}
     */
    const getCopyrightType = () => {
        return <CopyrightTypeItem copyrightType={copyrightType} copyrightTypeOriginator={copyrightTypeOriginator}
                                  copyrightTypeDateUntil={copyrightTypeDateUntil}
                                  copyrightTypeResponsible={copyrightTypeResponsible} t={t}/>
    };

    /**
     * Renders the licenses.
     */
    const getLicenses = () => {
        return licenses?.map(l => <LicenseItem license={l} key={l.reference.uniqueId}/>);
    };


    /**
     * Renders the copyright clauses.
     * @returns {JSX.Element}
     */
    const getCopyrightClauses = () => {
        return <CopyrightClausesItem t={t} models={copyrightInfo}/>
    }


    /**
     * Copies the uniqueId to the clipboard.
     * @param id
     */
    const handleCopyClick = (id) => {
        navigator.clipboard.writeText(id).then();
    };

    /**
     * Hook used to get the museum name.
     */
    useEffect(() => {
        if (!myMuseums || !museumCollections || !model) {
            return;
        }
        setMuseumName(getMuseumNameFromCollectionId({
            museums: myMuseums,
            museumCollections: museumCollections,
            collectionId: model.collectionId
        }));
    }, [myMuseums, museumCollections, model]);


    return (
        <Box>
            <ObjectConversionProvider>
                <SummaryAccordion isProjectObject={isProjectObject}/>
            </ObjectConversionProvider>
            <MetadataAccordion title={t('metadataAccordionMetadata', "Metadata")} defaultExpanded={true}
                               key={'accordionMeta'}>
                <MetadataRow label={"ID"}>
                    <Typography variant={"caption"}>{uniqueId}</Typography>
                    <IconButton onClick={() => handleCopyClick(uniqueId)}>
                        <ContentCopyOutlined/>
                    </IconButton>
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionProdDate', "Prod. dato")}>
                    <Typography variant={"caption"}>
                        {format(productionDate, "P")}
                    </Typography>
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionMuseum', "Museum")}>
                    <Typography variant={"caption"}>
                        {museumName}
                    </Typography>
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionMuseumNumber', "Museumsnr.")}>
                    <Typography variant={"caption"}>
                        {customIdentifier}
                    </Typography>
                </MetadataRow>
                <MetadataRow label={t('producer', "Produsent")}>
                    <ChipList
                        refTitle={"producer.reference.title"}
                        values={producer}
                        smallScreen={smallScreen}
                    />
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionPersons', "Personer")}>
                    <ChipList
                        refTitle={"persons.reference.title"}
                        values={persons}
                        smallScreen={smallScreen}
                    />
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionLanguage', "Språk")}>
                    <ChipList refTitle={null} values={languages} smallScreen={smallScreen}/>
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionSubjects', "Emneord")}>
                    <ChipList
                        refTitle={"subjects.reference.title"}
                        values={subjects}
                        smallScreen={smallScreen}
                    />
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionPlaces', "Steder")}>
                    <ChipList
                        refTitle={"places.reference.title"}
                        values={places}
                        smallScreen={smallScreen}
                    />
                </MetadataRow>
                <MetadataRow label={t('metadataAccordionDescription', "Beskrivelse")}>
                    <Typography variant={"caption"}>{description}</Typography>
                </MetadataRow>
                <MetadataRow label={t('fieldRemarks', "Kommentar")}>
                    <Typography variant={"caption"}>{remarks}</Typography>
                </MetadataRow>
            </MetadataAccordion>

            <MetadataAccordion title={t('metadataAccordionRights', "Rettigheter")} defaultExpanded={true}
                               key={'accordionRights'}>
                {copyrightType && getCopyrightType()}
                {getLicenses()}
                {getCopyrightClauses()}
                <MetadataRow label={t('lblCopyrightTerms', 'Betingelser')} key={"copyrightTerms"}>
                    <Typography variant={"caption"}>{content?.copyrightTerms}</Typography>
                </MetadataRow>
            </MetadataAccordion>
        </Box>
    );
};
