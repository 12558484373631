import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import {useFolderTranslation} from "./MyFoldersContext";
import {CreateFolderModal} from "./CreateFolderModal";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";

const PREFIX = "CreateFolderCard";

const classes = {
    createFolderActionArea: `${PREFIX}-createFolderActionArea`,
    addCircleIcon: `${PREFIX}-addCircleIcon`,
};

const Root = styled("div")(() => ({
    [`& .${classes.createFolderActionArea}`]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        height: 140,
    },

    [`& .${classes.addCircleIcon}`]: {
        fontSize: 60,
    },
}));

export const CreateFolderCard = () => {
    const t = useFolderTranslation();
    const [showCreateFolder, setShowCreateFolder] = useState(false);

    return (
        <Root>
            <Card elevation={0}>
                <CardActionArea
                    className={classes.createFolderActionArea}
                    onClick={() => setShowCreateFolder(true)}
                >
                    <AddCircleIcon
                        color={"secondary"}
                        className={classes.addCircleIcon}
                    />
                    <CardContent>
                        <Typography>{t("createFolder", "Opprett mappe")}</Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
            <CreateFolderModal
                show={showCreateFolder}
                onHide={() => setShowCreateFolder(false)}
            />
        </Root>
    );
};
